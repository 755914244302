var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.planet)?_c('div',{staticClass:"px-0 px-md-1",attrs:{"id":"related-links"}},[(!_vm.isNested && (_vm.isExalted || _vm.isDetriment))?_c('div',{staticClass:"fixing-planets"},[_c('p',{staticClass:"my-2 d-flex align-items-center"},[_vm._v(_vm._s(_vm.$t('hd.properties.fixingPlanet')))]),_c('div',{staticClass:"grid grid-cols-1 gap-5"},[(_vm.isExalted)?_c('related-link-toggle',{key:`planet-${_vm.getFixingPlanets.exalted}`,attrs:{"icon":_vm.planets.icons[_vm.getFixingPlanets.exalted],"type":"planet","is-transit":_vm.isTransitPlanet,"is-nested":true,"can-toggle":false,"is-reverse-background":_vm.isNested,"depth":_vm.depth,"start-open":"","render-props":{
                              id: _vm.getFixingPlanets.exalted.planet,
                              planet: _vm.getFixingPlanets.exalted.planetObject,
                              planetId: _vm.getFixingPlanets.exalted.planet,
                              activation: _vm.getFixingPlanets.exalted.activation,
                              chartId: _vm.getFixingPlanets.exalted.chartId,
                              customIconClasses: _vm.getPlanetColorClasses(_vm.getFixingPlanets.exalted.planetObject),
                              getIcon: () => {},
                              reverseBackground: !_vm.isNested
                           },"label":_vm.getPlanetText(_vm.getFixingPlanets.exalted.planetObject, true, true)}}):_vm._e(),(_vm.isDetriment)?_c('related-link-toggle',{key:`planet-detriment-${_vm.getFixingPlanets.detriment}`,attrs:{"icon":_vm.planets.icons[_vm.getFixingPlanets.detriment],"type":"planet","is-transit":_vm.isTransitPlanet,"is-nested":true,"can-toggle":false,"is-reverse-background":_vm.isNested,"depth":_vm.depth,"render-props":{
                                id: _vm.getFixingPlanets.detriment.planet,
                                planet: _vm.getFixingPlanets.detriment.planetObject,
                                planetId: _vm.getFixingPlanets.detriment.planet,
                                activation: _vm.getFixingPlanets.detriment.activation,
                                chartId: _vm.getFixingPlanets.detriment.chartId,
                               customIconClasses: _vm.getPlanetColorClasses(_vm.getFixingPlanets.detriment.planetObject),
                                getIcon: () => {},
                                reverseBackground: !_vm.isNested
                             },"label":_vm.getPlanetText(_vm.getFixingPlanets.detriment.planetObject, true, false)}}):_vm._e()],1)]):_vm._e(),(_vm.planet.gate && !_vm.isNested)?_c('div',{staticClass:"gates"},[_c('p',{staticClass:"my-2 d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('hd.gates.name.one')))]),_c('div',{staticClass:"grid grid-cols-1 gap-5"},_vm._l(([_vm.planet.gate]),function(gate){return _c('related-link-toggle',{key:`gate-${gate.id}`,attrs:{"type":"gate","render-props":{
                                id: gate,
                                gateId: gate,
                                reverseBackground: !_vm.isNested
                            },"is-nested":_vm.isNested,"is-reverse-background":_vm.isNested,"depth":_vm.depth,"label":_vm.getGateToggleLabel(gate)}})}),1)]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }