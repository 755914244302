<template>

  <div>
    <p v-if="coupons.length === 0">{{$t('billing.tabs.coupons.empty')}}</p>
    <div class="coupons grid grid-cols-md-2 gap-10">
      <b-card v-for="coupon in coupons" :key="coupon.id" :class="coupon.available_uses === 0 || isExpired(coupon) ? 'opacity-50': ''"
              class="position-relative overflow-hidden"
      >
        <div class="grid grid-cols-3 h-100">
          <div class="col-span-2 d-flex flex-column justify-content-between">
            <label>{{ $t('billing.tabs.coupons.code') }}</label>
            <div>
                    <span class="text-lg">
                       {{ coupon.code }}
                    </span>

              <b-button variant="link" class="copy-btn" @click="copy(coupon)">
                <svg v-if="!copied[coupon.id]" class="feather feather-copy" fill="none" height="12" stroke="currentColor"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     stroke-width="2" viewBox="0 0 24 24" width="12"
                     xmlns="http://www.w3.org/2000/svg">
                  <rect height="13" rx="2" ry="2" width="13" x="9" y="9"/>
                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/>
                </svg>
                <span v-else class="icon-check"/>
              </b-button>

            </div>
            <div class="mt-auto">
              <p class="text-sm text-primary font-weight-bold mt-3 mb-2">
                {{$t('billing.tabs.coupons.' + (coupon.checkout_type === 1 ? 'single' : 'subscription'))}}
              </p>
              <p v-if="coupon.metadata && coupon.metadata.firstTimePurchase" class="text-sm">
                You are receiving this coupon because you purchased:  {{getFirstTimeName(coupon)}}.
              </p>
            </div>

            <p v-if="coupon.duration" class="font-weight-bold text-sm mb-1">{{$t(`billing.tabs.coupons.duration.${coupon.duration}`)}}</p>
            <p v-if="coupon.expiration" class="text-sm mb-0">
              <b-button v-if="coupon.products && coupon.products.length > 1" variant="link" v-b-tooltip.hover
                        class="px-0"
                        style="padding:0!important"
                        :title="$t(`billing.tabs.coupons.appliesTo`) + ' ' + (getCouponProductNames(coupon) || []).join(', ')">

                <span class="icon-help"/>
              </b-button>
              <span v-else-if="coupon.products" class="d-block">
                {{$t(`billing.tabs.coupons.appliesTo`)}} {{getCouponProductNames(coupon)[0]}}
              </span>
              {{ $t('billing.tabs.coupons.expiration') }}: <strong>{{formatDate(new Date(coupon.expiration * 1000))}}</strong>
            </p>
          </div>
          <div class="text-right">

                    <span class="" style="font-size:45px;opacity:0.9">
                      {{ coupon.type === 0 ? '$' : '' }}{{ coupon.amount }}{{ coupon.type === 1 ? '%' : ''}}
                    </span>
            <span v-if="coupon.available_uses === 0" class="text-primary d-block">
                      {{ $t('billing.tabs.coupons.claimed') }}
              </span>
          </div>
        </div>


      </b-card>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import moment from "moment";
import Vue from "vue";

export default {


  data(){
    return {
      coupons: [],
      copied: {},
    }
  },
  computed: {
    ...mapState({
      products: state => state.checkout.products
    }),
  },
  created(){
    this.fetchCoupons();
  },

  methods: {
    isExpired(coupon){
      if (!coupon.expiration) return false;
      return +coupon.expiration < +moment().format("X")
    },
    getCouponProductNames(coupon) {
      if(!coupon.products || coupon.products.length === 0) return '';

      return this.products.subscriptions.filter(x => coupon.products.includes(x.key)).map(x => x.name)
    },
    getFirstTimeName(coupon){
      if (!coupon.metadata || !coupon.metadata.firstTimePurchase) return '';

      return this.products.singles.find(x => x.key === coupon.metadata.firstTimePurchase)?.name || '';
    },
    fetchCoupons() {
      this.axios.get('/users/coupons').then(res => {
        this.coupons = res.data.map(x => {
          x.isExpired = this.isExpired(x);
          return x;
        }).sort((a,b) => {
          // If 'a' is expired and 'b' is not, 'a' should go after 'b'
          if (a.isExpired && !b.isExpired) return 1;

          // If 'b' is expired and 'a' is not, 'b' should go after 'a'
          if (!a.isExpired && b.isExpired) return -1;

          // If both are either expired or both are non-expired, sort by available_uses
          return b.available_uses - a.available_uses;
        });
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    },
    copy(coupon){
      Vue.set(this.copied, coupon.id, true)
      navigator.clipboard.writeText(coupon.code);
      setTimeout(()=>{
        Vue.set(this.copied, coupon.id, false)
      }, 2000)
    }
  }
}
</script>
<style lang="scss">
.copy-btn{
  margin-top:-3px;
  padding-left:5px!important;
  padding-right:5px!important;
}
</style>