export default {
  0: 'Awakening (20-10)',
  1: 'Charisma (20-34)',
  2: 'Power (34-57)',
  3: 'Perfected Form (10-57)',
  4: 'Awareness (61-24)',
  5: 'Structuring (43-23)',
  6: 'Struggle (28-38)',
  7: 'The Brain Wave (57-20)',
  8: 'Emoting (39-55)',
  9: 'Openness (12-22)',
  10: 'Mutation (60-3)',
  11: 'Beat (2-14)',
  12: 'Inspiration (8-1)',
  13: 'Exploration (10-34)',
  14: 'Initiation (51-25)',
  15: 'Logic (63-4)',
  16: 'Acceptance (17-62)',
  17: 'Judgment (18-58)',
  18: 'The Wavelength (48-16)',
  19: 'Concentration (9-52)',
  20: 'Rhythm (5-15)',
  21: 'The Alpha (31-7)',
  22: 'Recognition (30-41)',
  23: 'Transistoriness (35-36)',
  24: 'Abstraction (47-64)',
  25: 'Curiosity (56-11)',
  26: 'Maturation (53-42)',
  27: 'Discovery (46-29)',
  28: 'The Prodigal (33-13)',
  29: 'Transformation (54-32)',
  30: 'Surrender (26-44)',
  31: 'Synthesis (49-19)',
  32: 'Community (37-40)',
  33: 'Money (45-21)',
  34: 'Mating (59-6)',
  35: 'Preservation (27-50)'
}
