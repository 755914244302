import { orientations } from '@/helpers/rave/constants/crosses'

export default {
  orientations: {
    [orientations.LEFT]: {
      title: 'Left Angle',
      desc: `People who come in on a left-angle Cross need to align with others to understand their life's process. This is a transpersonal Cross and requires the other to play a part in your life to have the necessary experiences that will, over time, reveal your purpose. This means your life can only be fulfilled through (karmic) interrelationship with others, that you need a support system through creating connections. `
    },
    [orientations.RIGHT]: {
      title: 'Right Angle',
      desc: `People who come in on a right-angle Cross are essentially self-absorbed and self-involved. You are primarily focused on your own process. Though you may have as much interaction with others as do the left-angle Crosses, your journey is your own and does not require others to play an important (karmic) part. You are not going to find clarity through the other but only through yourself, and your own journey through life.`
    },
    [orientations.JUXTAPOSED]: {
      title: 'Juxtaposition',
      desc: `People who come in on this Cross are dealing with a fixed fate. This means you have a very unique geometry or journey through life that is your own, and you will not let anything or anyone get in the way or interfere with this geometry. You are neither dependent on the other or not dependent, but simply moving through life aligned to and fixed on your very own geometry, determined to stay with your process.`
    }
  },

  0: {
    name: 'Juxtaposition Cross of Alertness',
    gates: '(44/24 | 7/13)',
    desc: `People with a memory of our genetic past and an innate sense of what is needed in terms of our immediate safety. They are attuned to where our direction lies, and are very influential through their capacity to lead and guide others. They have a keen alertness of what is going on and where we are heading, and are the first ones to read the signposts and pick up the messages, i.e. possible dangers. They are our modern “seers.”`
  },
  1: {
    name: 'Juxtaposition Cross of Ambition',
    gates: '(54/53 | 32/42)',
    desc: `These are powerful agents of transformation. Driven to succeed on the material plane, and/or with an underlying spiritual motivation, they need to wait for opportunities and depend on being recognized by those that can further their ambition. It is crucial to select among the opportunities that present themselves with care and which recognition to accept, as any endeavor they commit to will need to be lasting and provide continuity. This continuity provides the value of things, which is important for this Cross that is inherently conservative. With a fixed fate, these people need to be aware that they enter into things correctly and what they commit to as they fear breaking continuity. Unconsciously driven by a fear of failure, once they commit to something they need to carry it to completion.`
  },
  2: {
    name: 'Juxtaposition Cross of Articulation',
    gates: '(12/11 | 25/46)',
    desc: `People with a rare gift of articulation, the ability to convey meaning through their voice or vocal inflection. They are here to teach others, to pass on what they know and with it the capacity to influence others towards individuation. Exceptional spokespeople, open to stimulation, who in turn inspire and impact others with their individual knowing.`
  },
  3: {
    name: 'Juxtaposition Cross of Assimilation',
    gates: '(23/43 | 30/29)',
    desc: `These people have the difficult task of finding support for their unique insights and perspectives once they are able to explain them. They can experience deep loneliness, because their individual way of thinking first needs to be assimilated by those who are open to it, and those who will lend support. They are deeply committed to contributing perspectives that can have a revolutionary quality. They recognize that they need to contribute their perspectives to bring mutation, and their dependency on those who can assimilate their insights.`
  },
  4: {
    name: 'Juxtaposition Cross of Bargains',
    gates: '(37/40 | 5/35)',
    desc: `People with a fondness for the bargain. While the bargain plays a vital role for their own material process, they will naturally focus their attention on supporting the economical interest of the community they are part of to ensure its continued growth and progress. They are the ones to successfully negotiate and procure the best deals, advancing the interests of the community.`
  },
  5: {
    name: 'Juxtaposition Cross of Beginnings',
    gates: '(53/54 | 42/32)',
    desc: `People who bring the energy necessary to start a new cycle of transformation. They energize the beginning of a new cycle bringing the skill to recognize those who can contribute to the development of a process, and to get things done. They can easily assess the material value of a project and are focused on the continuity of a process, to take it through to its completion and thereby preserve continuity.`
  },
  6: {
    name: 'Juxtaposition Cross of Behavior',
    gates: '(10/15 | 18/17)',
    desc: `People fixated on correcting behavior. Their perspective is rooted in their own individual sense of behavior, with a critical capacity to recognize correct behavior based on their own opinions about what is correct. This in turn is rooted in their own taste, which is extremely fixed. Ultimately, their role is to point out and challenge incorrect behavior, which interferes with the ability to survive in the Now.`
  },
  7: {
    name: 'Juxtaposition Cross of Caring',
    gates: '(27/28 | 19/33)',
    desc: `People fixed on caring for others, and anything that appears to need their nourishing support. The need to care is integral to their personal struggle to find meaning, to define their life's purpose. While their intention to nurture, care and protect may reveal itself as their life's purpose, it can turn into a burden if done for the wrong reasons. Underlying this is also the pressure to find the right principles, and the memories of past experiences and revelations this can bring will further shape and define their need for meaning.`
  },
  8: {
    name: 'Juxtaposition Cross of Commitment',
    gates: '(29/30 | 20/34)',
    desc: `People who are fixed on commitment, who are driven into experiences to find commitment. They are charismatic and can be a source of inspiration to others. They serve as an example during times when commitment is rare, but necessary to do one's work and accomplish what one has set out to do.`
  },
  9: {
    name: 'Juxtaposition Cross of Completion',
    gates: '(42/32 | 60/56)',
    desc: `People with a deep and fixed need to complete cycles, who will hold onto and live through an experience until it is taken to completion. They will deal with the limitations that are part of any experience, while searching for stimulation to stay with the process.
  `
  },
  10: {
    name: 'Juxtaposition Cross of Conflict',
    gates: '(6/36 | 15/10)',
    desc: `People aware of the need for correct behaviour, that through the right behavior we not only survive but flourish. They have a gift for creating the grounds for companionship, and for forming friendships as a basis for intimacy. They need to understand that through friendship, they find the opportunities to live the life that is correct for them. They naturally break through auras to integrate with others, which may engender crisis. They need to move beyond the conflict of having to leave the ideal state to be “in the world.”`
  },
  11: {
    name: 'Juxtaposition Cross of Confusion',
    gates: '(64/63 | 45/26)',
    desc: `People who inspire others with their well of information about the past (history). They constantly reflect on their existence to make sense of past experiences. They are tribal leaders with the ability to manipulate memory and thereby exert influence for material gain, whether for their own interest or for the benefit of the tribe.`
  },
  12: {
    name: 'Juxtaposition Cross of Conservation',
    gates: '(32/42 | 56/60)',
    desc: `People with an innate sense of the need for duration and continuity. They are here to ensure that resources are conserved and to assure there are no risks. They focus on conservation and are traditional in their evaluation of things. Concerned about conserving resources as they understand the limitations placed on us, people with this Cross are true conservationists, determined to guarantee the continuity of our process.`
  },
  13: {
    name: 'Juxtaposition Cross of Contribution',
    gates: '(8/14 | 55/59)',
    desc: `People who are here to contribute to society in any way they can. Highly moody, creative and individual, they are here to set examples with their ability to make contributions. Motivated to find spirit and abundance through intimacy, their contributions will be built upon and revolve around intimate relationships, such as marriage, and the responsibilities this brings.`
  },
  14: {
    name: 'Juxtaposition Cross of Control',
    gates: '(21/48 | 54/53)',
    desc: `People fixed on being in control, waiting to be recognized to fulfill their ambitions and rise up to a position of control. Their focus lies in starting a new process, and they are looking to bring innovation. Their ambition needs to be rooted in depth to achieve the success they long for.`
  },
  15: {
    name: 'Juxtaposition Cross of Correction',
    gates: '(18/17 | 39/38)',
    desc: `People who are masters at recognizing a flaw in the pattern. They are able to offer direction and correction to others, drawn from a wealth of possible solutions. This is an extremely helpful and constructive skill that can be of immense value to the Collective. They need to wait to be asked to successfully offer their opinions for correction and improvement as their ability to see flaws and incompetence can be very challenging when not invited, and otherwise creates formidable resistance from others.`
  },
  16: {
    name: 'Juxtaposition Cross of Crisis',
    gates: '(36/6 | 10/15)',
    desc: `People who are deeply immersed in their search for sexual experience and fulfillment. At the same time they are keenly aware of their behavior, the need to behave in a way that is correct for them and that is often in conflict with their drive for experience. They are prone to experience crisis through their conflicting personality aspects. To reconcile these contrasting elements, they benefit from observing crisis behavior in others, and from the insights this yields. Underlying their behavior is a love for humanity, and the potential for self-acceptance and self-love.`
  },
  17: {
    name: 'Juxtaposition Cross of Denial',
    gates: '(40/37 | 35/5)',
    desc: `People who resist any kind of bargain. They are not open to progress or to engage in activities that lead to progress unless they are convinced of its value. This is an important aspect of the collective process, and these people are here to hold back on the ever-constant progress unless it offers worthwhile improvements.`
  },
  18: {
    name: 'Juxtaposition Cross of Depth',
    gates: '(48/21 | 53/54)',
    desc: `People with an innate depth focused on furthering their ambition. Always ready to start something new, they are motivated by a deep drive to advance in the world and to be noticed for their depth. There is an underlying tenacity at work that sustains their efforts but the focus on depth can cause them to get fixated and narrows the way they commune with others. Through opportunities brought to them through a circle of friends who recognize their depth, they can be very influential by way of helping others transform their inadequacies.`
  },
  19: {
    name: 'Juxtaposition Cross of Detail',
    gates: '(62/61 | 3/50)',
    desc: `People with a profound capacity for detail, a deep focus on using detail to get down to the fine nuances of things. They can substantiate their own inner truth with details. Their powerful logic and sense for detail enables them to focus on the law and law making. They have the skill to handle difficulties that are part of a new process, or law.`
  },
  20: {
    name: 'Juxtaposition Cross of Doubts',
    gates: '(63/64 | 26/45)',
    desc: `People with a distinct need to question and doubt the patterns that have been established. They look to find logical answers to alleviate the doubts they consistently experience. These are naturally skeptical people, adept at influencing others to question the validity of established patterns and to find logical answers that can balance experience. They can be fixated on doubting all and anything that life brings.`
  },
  21: {
    name: 'Juxtaposition Cross of The Driver',
    gates: '(2/1 | 49/4)',
    desc: `People who can inadvertently change the lives of others even through a momentary interaction which can point them in a different direction. They are entirely focused on following their direction in life, while at the same time trying to pull people in its wake as they are certain their direction will bring them truth. This is their entire focus, to penetrate to, and to know the truth of things. To have the certainty of knowing which becomes their direction.`
  },
  22: {
    name: 'Juxtaposition Cross of Empowering',
    gates: '(14/8 | 59/55)',
    desc: `People whose direction in life is tied to intimacy and abundance of spirit. There is an inherent melancholy looking to find buoyancy of spirit through emotional intimacy as well as material security. They can be very fixed in their need for intimacy and security, to find the spirit through romance and sexual intimacy, and to have the resources necessary to create a secure foundation. Once they are secure and uplifted in their spirit, they can empower others in finding their own direction in life.`
  },
  23: {
    name: 'Juxtaposition Cross of Experience',
    gates: '(35/5 | 22/47)',
    desc: `People fixed in their ways of looking for specific experiences, as experience is everything for them. They are driven by the expectation for experiences that will bring change, and the possibility to explore things in a new way that will ultimately lead to progress. They can impact others with their unique and special kind of experiences, and the possibility for transformation these experiences bring with them.`
  },
  24: {
    name: 'Juxtaposition Cross of Experimentation',
    gates: '(16/9 | 63/64)',
    desc: `People who look to apply their logical skills and talent to improve things, to correct a pattern through experimentation. They are deeply identified with and focused on actualizing their experiments to find solutions, and have the energy for detail work. While they are fixed in their process, they also deal with persistent doubts that the experiment can get done, and will use their skills to inspire others to find the necessary support within their network.`
  },
  25: {
    name: 'Juxtaposition Cross of Extremes',
    gates: '(15/10 | 17/18)',
    desc: `People fixed in their own, often unusual, rhythm that can be anywhere in the spectrum from regular to extremely irregular. How they use their energy and go about things can be seen as extreme by the outside, and they may appear to be out of tune with others. While they care for humanity and feel connected to the general flow, it is crucial that they stay with their own rhythm. They can have strong opinions about what is not perfect and needs correction, and how to organize things more efficiently.`
  },
  26: {
    name: 'Juxtaposition Cross of Fantasy',
    gates: '(41/31 | 44/24)',
    desc: `People attuned to the unexpected, who can take advantage of this by setting trends. They easily recognize the possibilities in any new trend, though are often subject to merely fantasizing about potential. They are adept at figuring out a whole spectrum of possible future trends as they have the imagination to see things, and can be very influential in this way. They need to take care not to become fixated on pure fantasy that has no real potential, with the ensuing expectations of being led back to the promised land that fantasy can create.`
  },
  27: {
    name: 'Juxtaposition Cross of Fates',
    gates: '(30/29 | 34/20)',
    desc: `People driven by their desire for experience, whose lives are journeys of discovery and happening upon events that seem to be out of their control. They are determined to commit themselves when engaging in a new experience or project that appears desirable, and promises to fulfill their longings and dreams, no matter what the consequences. Their perseverance and charismatic personality will see them through, even to the point of physical exhaustion or burnout.`
  },
  28: {
    name: 'Juxtaposition Cross of Focus',
    gates: '(9/16 | 64/63)',
    desc: `People who inspire others with their ability to bring into focus what is worthwhile to plan for, who are aware of collective needs and able to focus on what is relevant. Underlying this is a pressure that requires them to deal with doubt and uncertainty about whether necessary resources are available to actualize planning and whether things are worthwhile. To keep in mind that things will naturally come into focus rather than forcing this to happen. These people identify with and concentrate on the potential future of the collective, with a focus on what needs to be planned for.`
  },
  29: {
    name: 'Juxtaposition Cross of Formulization',
    gates: '(4/49 | 8/14)',
    desc: `People who have the ability to understand and see unique patterns. Based on these patterns, they can create and express formulas that can point in and empower a new direction. These formulas are not necessarily substantiated by facts, but are nevertheless inspiring.`
  },
  30: {
    name: 'Juxtaposition Cross of Grace',
    gates: '(22/47 | 11/12)',
    desc: `People with a capacity to be deeply attentive to those things that enthrall them. They have an extraordinary ability to take in and absorb what captivates them, whether art, music or people who inspire them. These are very social beings with an openness toward others, both friends and strangers, and a profound capacity to take others in due to their ability to truly listen. They are strong mutative forces who can profoundly impact others.`
  },
  31: {
    name: 'Juxtaposition Cross of Habits',
    gates: '(5/35 | 47/22)',
    desc: `These are habitual people, doing everything in the same pattern that they become fixed on. This is how they create a sense of order and establish consistency in their life. Being in the flow of the pattern gives them the sense that life is consistent, that the habitual patterns that are adopted are reliable. They are accustomed to their own flow and not easily influenced by others.`
  },
  32: {
    name: 'Juxtaposition Cross of Ideas',
    gates: '(11/12 | 46/25)',
    desc: `People fixated on trying to get their ideas out and thereby having the possibility to reach out and influence others with their philosophical concepts. These are teachers whose perspectives about the nature of our cosmos in general and our bodies in particular can be deeply influential, and even lead to a mutation in our current understanding and concepts about our physical forms.They are often fixed in a single direction.`
  },
  33: {
    name: 'Juxtaposition Cross of Influence',
    gates: '(31/41 | 24/44)',
    desc: `These are people who are by their very nature influential as they are consistent and stay with things, and can even be quite fixed in their process. They are always looking to influence others and have the ability to recognize on whom to exert their influence. Through insight and understanding of patterns, they are eventually able to impact and guide others.`
  },
  34: {
    name: 'Juxtaposition Cross of Innocence',
    gates: '(25/46 | 58/52)',
    desc: `People who love life and who potentially carry a great joy for life. Because they are fixed in their pursuit for happiness, this will be their focus in life even if they experience adversity.
  Theirs is a transpersonal, universal kind of love that recognizes the unity of all life. They are deeply influential in bringing those around them to feel good about life.`
  },
  35: {
    name: 'Juxtaposition Cross of Insight',
    gates: '(43/23 | 29/30)',
    desc: `People committed to externalizing their deep, unique insights. They need to work on the ability to articulate and communicate their insights effectively. It can be difficult to express and relay what is inherently known and these people's lives can be full of 'aha' moments that they are unable to share with others. The driving force here is a deep desire and commitment to express and share what they know. Once this can be expressed, their influence is substantial and potentially empowering for others.`
  },
  36: {
    name: 'Juxtaposition Cross of Interaction',
    gates: '(7/13 | 23/43)',
    desc: `People seeking to interact at the level of leadership where they find opportunities to lead. People who 'have a way' with others such as the politician in touch with the people, available to interact to live out their role as potential leaders. They lead through insight and the ability to accept diversity.`
  },
  37: {
    name: 'Juxtaposition Cross of Intuition',
    gates: '(57/51 | 62/61)',
    desc: `People who are profound and gifted with extraordinary clarity that is potentially very inspiring to others. They can go deeply into things and extract their truths, and substantiate their findings with a capacity for detail. They can intuit occult knowledge, those aspects of knowledge that are not commonly known, but are rarely heard as they tend to keep these things to themselves. They are prone to express themselves only if compelled, in which case they tend to shock others with their revelations.`
  },
  38: {
    name: 'Juxtaposition Cross of Limitation',
    gates: '(60/56 | 28/27)',
    desc: `People who find purpose in maximizing the potential of and preserving traditions, including religious and tribal customs. Their focus is on accepting limitation by adhering to and rigorously defending, what has been established as a tradition. They find purpose in taking risks and struggling to preserve the old order which has served as a cultural or religious tenet. They will fight against progressive laws and any attempts at modernization in their effort to care for others through safeguarding what is proven and established.`
  },
  39: {
    name: 'Juxtaposition Cross of Listening',
    gates: '(13/7 | 43/23)',
    desc: `People who are good and empathetic listeners, natural guardians of other people's secrets. Collecting information about experiences that others relay to them, they offer guidance by way of a passive, reflective process that transforms the information they take in and offers it as a direction to others simply by being in their aura. They are not adept at explaining their unique insights and need other venues to express what they know, such as through an art form.`
  },
  40: {
    name: 'Juxtaposition Cross of Moods',
    gates: '(55/59 | 9/16)',
    desc: `People looking to experience emotional abundance through romance and sexual intimacy. They can be deeply fixed on and preoccupied with their moods and are prone to identify with these, rather than simply experience the flow of their shifting moods, the source of their deeply creative potential. They can be very talented and creative forces with a focus on finding truth and depth.`
  },
  41: {
    name: 'Juxtaposition Cross of Mutation',
    gates: '(3/50 | 41/31)',
    desc: `People who are instrumental to the start of any new experience, and who carry within them the energy for mutation, or dynamic change, to take place.  They are looking to exert their influence in the mutative process, and use the opportunity to establish new rules that a new experience brings with it. This can be externalized in both positive and negative ways.`
  },
  42: {
    name: 'Juxtaposition Cross of Need',
    gates: '(19/33 | 1/2)',
    desc: `People with a deep need to express themselves creatively, to create beauty in their lives. They look for independence and the space to absorb themselves in their creative process. They need to be assured of sufficient resources such as food, shelter, and territory to immerse themselves in their creative process and follow their own direction in life. The aesthetics they create are not necessarily visible to others, but only to those who are among a close circle of friends.`
  },
  43: {
    name: 'Juxtaposition Cross of The Now',
    gates: '(20/34 | 37/40)',
    desc: `An unusual configuration, this is the most existential Cross. People who are deeply absorbed in the present moment, who are driven and industrious, involved in their own creative process.  At the same time, they need to be part of and serve their community or tribe, a need that challenges them to balance this aspect of life with their individual urge to lose themselves in their own existential reality.`
  },
  44: {
    name: 'Juxtaposition Cross of Opinions',
    gates: '(17/18 | 38/39)',
    desc: `People with strong individuality, ready to provoke with fixed opinions that may not always be backed up by consistent detail. Driven by a great deal of adrenal pressure, they are fixed in their way of expressing opinions and focus on what is not perfect and needs to be improved. They simply cannot be argued with, and when rightly placed, their provocations have the function to improve things.`
  },
  45: {
    name: 'Juxtaposition Cross of Opposition',
    gates: '(38/39 | 57/51)',
    desc: `People who need to be in opposition to things, who thrive on creating opposition and provoking confrontation to find out if something is of value. They will oppose on principle so they can get to the core of something, e.g. bluntly opposing someone's theory and demanding that it be substantiated. They can easily shock others with their blunt and outspoken way of opposing things, yet this is in fact their gift. They can successfully test the value or worth of anything and are very powerful forces in this way.`
  },
  46: {
    name: 'Juxtaposition Cross of Oppression',
    gates: '(47/22 | 12/11)',
    desc: `People who use the past, and who can use their understanding of the past to articulate and express new ideas and concepts. They are able to replace current concepts with new ideas, which nevertheless need to be substantiated to be of practical value to humanity. Ideas that are not practical can always lead to trouble. These people are set in their ways and can have very fixed concepts that when imposed on others can be experienced as rather oppressive.`
  },
  47: {
    name: 'Juxtaposition Cross of Possession',
    gates: '(45/26 | 36/6)',
    desc: `People fixed on maintaining power and authority within their territory. They have the authority and ego strength as well as the drive to lead and be in control, and will strongly defend what they consider to be their possessions. The experience of crisis is part of their process, and they are well equipped to take charge during times of crisis and conflict.`
  },
  48: {
    name: 'Juxtaposition Cross of Power',
    gates: '(34/20 | 40/37)',
    desc: `People who use their power and charisma in support of the community once they find the right bargain. They impact the tribe/community with their potential for mutation that they bring as individuals. Their display of power and busyness makes them immensely attractive to the community and they will offer their substantial (sacral) energy and power only if the deal/bargain is correct and works for them. Always ready to lend their energy they also ascertain that they are adequately rewarded for the work they contribute to the welfare of the community.`
  },
  49: {
    name: 'Juxtaposition Cross of Principles',
    gates: '(49/4 | 14/8)',
    desc: `People who are forerunners fixed to principles, revolutionaries ahead of their time who can see what principles need to be changed. They concern themselves with the protection of human rights, to ensure that people’s basic needs are met. They strongly guard and protect the principles they stand for, and hold to their convictions.`
  },
  50: {
    name: 'Juxtaposition Cross of Provocation',
    gates: '(39/38 | 51/57)',
    desc: `Highly individual people who need to accept their changing moods as fuel for creative expression. They can provoke at a deep level merely for the sake of provocation, or to call out the spirit in others. They are capable of shocking others as they intuitively know how to provoke, how to push the right button to bring out the spirit in others.`
  },
  51: {
    name: 'Juxtaposition Cross of Rationalization',
    gates: '(24/44 | 13/7)',
    desc: `People with strong mental and intellectual capacities, powerful intellectual forces who are at the same time extremely fixed in their process of rationalizing. Their gift is to translate their individual knowing and apply it to the collective mental process with its focus on logic and experience. They seek to grasp continuity through understanding both past and future.`
  },
  52: {
    name: 'Juxtaposition Cross of Retreat',
    gates: '(33/19 | 2/1)',
    desc: `The ability to retreat into one's own territory, one's own domain. Here we find the possibility to create an aesthetic and enhanced environment that expresses one's need for beauty in life. To be able to retreat to an aesthetic space provides one with a basic protection and integrity, and to be independent from outside forces. To live in peace, and share this peace with the planet.`
  },
  53: {
    name: 'Juxtaposition Cross of Risks',
    gates: '(28/27 | 33/19)',
    desc: `People who are natural risk takers, ready to tax the limits of an experience to get what they need, and to feel the satisfaction having confronted challenges. Their risk taking is rooted in getting the resources they need. They have a fixed geometry, a fixed route, which propels them to take risks to find the resources they need to fulfill their purpose. They are also prone to blind risk taking.`
  },
  54: {
    name: 'Juxtaposition Cross of Self Expression',
    gates: '(1/2 | 4/49)',
    desc: `People who are fixed in their own knowing and their way of self-expression. They can provide formulas and answers based on a creative and revolutionary approach to circumstances that call for change. They are powerful forces whose social aptitude needs to be integrated with their extreme focus on self expression, so they can contribute to social change without alienating those waiting to receive answers to their questions.`
  },
  55: {
    name: 'Juxtaposition Cross of Serendipity',
    gates: '(46/25 | 52/58)',
    desc: `People with a strong sense and appreciation for the physical world, who enjoy being alive and part of physical reality. They are focused on finding opportunities that will take them to the right place at the right time, a place of serendipity. Once they know what experience is correct for them, they need to be totally committed and focus their efforts on fully embodying and being present with whatever transpires in each moment. This potentially leads to good fortune.`
  },
  56: {
    name: 'Juxtaposition Cross of Shock',
    gates: '(51/57 | 61/62)',
    desc: `People who can shock others with their intuitive insights, and their own truth. They can also take others by surprise, revealing details that were formerly unknown. Whether they shock simply to get others’ attention or to point them toward the truth, their ability to shock is applied to lift us out of seriousness, and keep us from getting stuck in ignorance or complacency.`
  },
  57: {
    name: 'Juxtaposition Cross of Stillness',
    gates: '(52/58 | 21/48)',
    desc: `People centered in stillness, waiting for the opportunities to apply their logical skills and offer solutions to those within their network when asked. Their ability to focus and concentrate on what needs correction combined with a need for depth enables them to find solutions lying beneath the surface. This way, they unconsciously take control and have the possibility to extend a strong influence within their network.`
  },
  58: {
    name: 'Juxtaposition Cross of Stimulation',
    gates: '(56/60 | 27/28)',
    desc: `People with a distinct need for stimulation, who seek to share their experiences through storytelling or their own parables about life. They need to exteriorize their own truth, and stimulate and inspire others with their idealism as a way of caring. They will go to extremes and take risks to find stimulation and move beyond limitation.`
  },
  59: {
    name: 'Juxtaposition Cross of Strategy',
    gates: '(59/55 | 16/9)',
    desc: `People whose relationships are rooted mainly in friendship. A theme in life is to look for opportunities through relationships and to cross the barrier from friendship to intimate relationship. They are very adept at strategizing how to bring people together, with a talent for match-making.`
  },
  60: {
    name: 'Juxtaposition Cross of Thinking',
    gates: '(61/62 | 50/3)',
    desc: `People in pursuit of knowledge who carry the potential to change the field of knowing. They recognize that we, as a collective, need to move on, that our laws are subject to change. This Cross brings the capacity to examine what is known, and these people can use their personal insight to take this knowledge to a deeper level. Their insight is substantiated by detail and they have the ability to change what is generally known and accepted.`
  },
  61: {
    name: 'Juxtaposition Cross of The Trickster',
    gates: '(26/45 | 6/36)',
    desc: `People adept at performing the role of the Trickster. They are very skilled at marketing themselves to those who lack experience and can easily be influenced and even fooled. This fulfills the role of the archetypal trickster, at best here to help others to wake up to reality through being fooled and thereby recognizing the illusion. A powerful persona that brings with it crisis and conflict, experiences that ultimately lead to transformation.`
  },
  62: {
    name: 'Juxtaposition Cross of Values',
    gates: '(50/3 | 31/41)',
    desc: `People who are proponents of a moderate and considerate approach to upholding the law. They use their influence where a transgression of the law calls for lessening the severity of the law if circumstances justify this approach, such as when a hungry person is caught stealing food. In a case where values have been corrupted, these people ensure the consequences of these actions need to be in proportion to the offense.`
  },
  63: {
    name: 'Juxtaposition Cross of Vitality',
    gates: '(58/52 | 48/21)',
    desc: `People who will dedicate their energy to anything that requires their vitality and staying power but only when left in control of the situation. They can focus on solutions and have the creative depth to see what needs to be corrected to move securely forward into the future, but are very fixed in the way they exteriorize their own solutions to a situation at hand. They are unrelenting in their fixed ways and need to be in control. They are generally sought after for their particular life-affirming quality, the fundamentally joyous approach they bring to life.`
  },
  64: {
    name: 'Left Angle Cross of Alignment',
    gates: '(27/28 | 19/33)',
    desc: `People adept at dealing with the unexpected. They are able to handle a moment of transition (change) and give it a new direction, in that they are able to react spontaneously to the unexpected. They have the ability to take advantage of an unexpected event and put it to its proper use, e.g. align it and thereby bringing it back into its proper flow. Personally, they will align themselves to what is correct for them, supports their principles and furthers their interest, which may be perceived as treachery by others but is nevertheless correct for them.`
  },
  65: {
    name: 'Left Angle Cross of Alignment',
    gates: '(28/27 | 33/19)',
    desc: `People who can take advantage of the unexpected in their life, and who can align themselves to ride the wave of an unexpected event. They are ready to leave behind the old and established, and move on to something more purposeful. They have the capacity to take advantage of an expected occurrence and align themselves with it, and thereby can make the best out of any situation.`
  },
  66: {
    name: 'Left Angle Cross of The Alpha',
    gates: '(31/41 | 24/44)',
    desc: `Powerful leaders capable of reaching and influencing a large number of people. This Cross is about practical influence, and these are people who can resolve issues and offer practical solutions with universal application. As leaders, they have powerful impact as the formulas they can provide assure the collective of its survival and a secure future.`
  },
  67: {
    name: 'Left Angle Cross of The Alpha',
    gates: '(41/31 | 44/24)',
    desc: `People who can take advantage of the unexpected happening and thereby influence others with their expectations and fantasies. They can seduce others into sharing their fantasies, along with the expectations this gives rise to. They are adept at using the emotions of those around them to create expectations. They seek the opportunity to exert influence as they recognize the emotions engendered through something unexpected (such as a great discovery); when they offer their fantasies and expectations, others are prone to follow them. They can easily take advantage of a change brought by an unexpected event and the opportunities it offers.`
  },
  68: {
    name: 'Left Angle Cross of The Clarion',
    gates: '(51/57 | 61/62)',
    desc: `People who are here to herald what is new and potentially mutative information, substantiated by detail. They bring their intuitive knowing and penetrating insight to those who are ready to be awakened and receptive to the mutation this brings.
  `
  },
  69: {
    name: 'Left Angle Cross of The Clarion',
    gates: '(57/51 | 62/61)',
    desc: `People with exceptional intuitive clarity who are very inspiring, capable of profound and objective observations. They are sought out to share their observations and insights as others project on them that the information they carry is essential and that it can offer solutions. They operate purely out of their intuition; nevertheless, their observations are practical and substantiated by detail. They are generally reluctant to share their information and need to be pressured and called out to speak.`
  },
  70: {
    name: 'Left Angle Cross of Confrontation',
    gates: '(45/26 | 36/6)',
    desc: `People who are here to claim “rulership,” a position of authority and power. They are ready to challenge and confront those forces within their territory who try to be in charge. They need to be in control and confront others as they are here to make a claim, and they will not accept a position of service. They will oversee the management of resources within their community, and are potent forces during times of crisis and conflict.`
  },
  71: {
    name: 'Left Angle Cross of Confrontation',
    gates: '(26/45 | 6/36)',
    desc: `People who are keen to rule for the sake of ruling. These are powerful forces, always ready to challenge anyone in a position of power to secure a position of power and influence for themselves, and institute their own rulership. They challenge authority when they see injustice, pointing toward flaws and mistakes and thereby engendering crisis and conflict, so bringing about change. They have the capacity and skill to bring down those who are in power by pointing toward their flaws and mistakes, and turning the collective's attention toward these.`
  },
  72: {
    name: 'Left Angle Cross of Cycles',
    gates: '(53/54 | 42/32)',
    desc: `People who understand that in beginning a new cycle and taking it to completion lies the foundation for change and transformation, that the continuity of change is what drives evolution forward. “The only thing that endures is change.” People who have the ambition and persistence to succeed, who are looking to be recognized and to achieve on the material plane.`
  },
  73: {
    name: 'Left Angle Cross of Cycles',
    gates: '(54/53 | 32/42)',
    desc: `People with a strong ambition to succeed in the world. The theme here is to get involved in a process, to be recognized by those who bring the opportunities to move up and advance in the world, and then to complete the cycle.  Once they seize an opportunity, they need to be committed to the process and complete it. They seek to be recognized, to enter into a cycle, be transformed in the process, finish the cycle and move on to the next thing. They are not looking for consistency other than for the duration of any given cycle, whether a short- or long-term cycle. Their gift is that once they are recognized and commit, they will be dedicated to completing the process as it is the cycle itself that is important to them. They are not looking for consistent careers and it is never too late for them to add elements to their education to switch professional fields, e.g. from doctor to architect.`
  },
  74: {
    name: 'Left Angle Cross of Dedication',
    gates: '(23/43 | 30/29)',
    desc: `These people have the potential to be great teachers, dedicated to explaining their unique perspectives. They are committed to bringing their unique views to the Collective, ready to repeat a concept for as long as it takes to be grasped and assimilated by others. That is when their individual knowing can bring mutation. They are dedicated to this task of explaining things, and find their own fulfillment through impacting prevalent or standard concepts and affecting change to further education, for example. Their task also lies in supporting others in their ability to explain themselves, something they are deeply committed to.`
  },
  75: {
    name: 'Left Angle Cross of Dedication',
    gates: '(43/23 | 29/30)',
    desc: `People who are dedicated to their own unique insights and to impact others with their knowing. They will share an insight without any concern about whether they can actually explain it. They will express their 'know-isms' without delivering explanations, as they prefer to leave the explanation to others who are impacted by their insights. These people deeply affect society as they are dedicated to planting their insights in others in a subversive and very effective way that can lead to change and mutation.`
  },
  76: {
    name: 'Left Angle Cross of Defiance',
    gates: '(2/1 | 49/4)',
    desc: `People moving away from any set direction as they naturally question a given direction’s validity. They appear to defy the Collective's general direction as they move out of line, intentionally or not. They are the forces in charge of defying the standard direction the Collective is moving in, and they believe in their own answers and principles, which can possess a revolutionary quality. They can be very anarchistic forces, here to upset the status quo and move others away from it.`
  },
  77: {
    name: 'Left Angle Cross of Defiance',
    gates: '(1/2 | 4/49)',
    desc: `People who are fiercely protective of their individuality and who accept no interference of any kind. They need to protect their uniqueness and are determined to prevent conditioning from the outside world. They attract others (society) but are determined to protect difference, and defy any intrusion or interference to preserve their unique self expression. After all, this is their direction in life, and it is the individual who mutates and changes society and our collective values.`
  },
  78: {
    name: 'Left Angle Cross of Demands',
    gates: '(52/58 | 21/48)',
    desc: `People with an ability to concentrate and direct their focus on what needs correction. They have the depth to see where solutions are needed that will benefit the Collective. They unconsciously feel the need to take control and demand that solutions are enforced to improve things, and thereby to protect society.`
  },
  79: {
    name: 'Left Angle Cross of Demands',
    gates: '(58/52 | 48/21)',
    desc: `People who are ready to apply their energy to a project on the condition that certain expectations and demands are met first. They can provide the energy to challenge authority, improve and modify patterns that are no longer correct or do not serve adequately. They are also available to support those who need to improve or perfect their skills—but fundamentally they are only willing to offer access to this substantial source of energy they can provide if their needs are met in return, and if they can retain a certain control of the situation.
  They have corrective depth, but can be disappointed when their demands are refused, or when taken advantage of. For either, following inner authority is crucial for this process to unfold correctly.`
  },
  80: {
    name: 'Left Angle Cross of Distraction',
    gates: '(56/60 | 27/28)',
    desc: `People with an ability to attract attention by engaging others’ interest in a variety of stimulating ways. They are adept at distracting others and diverting the attention towards themselves and their own objectives. These are strongly stimulating forces ready to take risks to break others out of deterring conditions whether for good or bad, or to overcome their own limitations.`
  },
  81: {
    name: 'Left Angle Cross of Distraction',
    gates: '(60/56 | 28/27)',
    desc: `People adept at diverting the attention of others from what is progressive and modern. Their task is to preserve the old (order), to pull the attention back towards seeing the value in the traditional and established. These are people who value the original laws and principles. While there lies a purpose in struggling and fighting for traditional law, it can also lead to dogma, opposing anything progressive as a principle. The new is always a fusion of the old with new elements, but the new if followed blindly without assessment can be disastrous. In that sense, these people are here to distract others away from attempts at modernization that are of questionable value—their way of caring for the collective.`
  },
  82: {
    name: 'Left Angle Cross of Dominion',
    gates: '(63/64 | 26/45)',
    desc: `People with strong mental skills, capable of taking control by expressing doubts and questions that can deeply impact and influence others. Their intellect can be powerful enough to create doubt and confusion among others in a given circumstance, and through which they can take dominion. This is not a moral issue as it is a transpersonal Cross, and may or may not turn out to be an improvement.
  `
  },
  83: {
    name: 'Left Angle Cross of Dominion',
    gates: '(64/63 | 45/26)',
    desc: `People whose interpretation of past history can have a strong impact on society and puts them in positions of authority and power. They can influence others with their presentation of the past, which is subject to interpretation or even manipulation, to fortify their position.`
  },
  84: {
    name: 'Left Angle Cross of Duality',
    gates: '(20/34 | 37/40)',
    desc: `People absorbed in their process of busyness and industry, deeply immersed in their existential reality. While they are typically self-absorbed in their constant activities, they also find fulfillment through being part of their greater community where they can impact others by manifesting skills and creating beneficial associations. Through their individuality, they bring mutation to the tribe, and act as bridges between the individual and the community. This process of bringing together these two distinct aspects of their life can present a persistent challenge.`
  },
  85: {
    name: 'Left Angle Cross of Duality',
    gates: '(34/20 | 40/37)',
    desc: `People who are individual and self-sufficient, busy with their own agendas while at the same time part of a tribal community and its inherent support system. These people find the tribal aspects of life need to be integrated into their busy lives; while they unconsciously enjoy the community and friendship of the tribe, their identification lies with being an independent individual, set apart from the communal environment. They need to bridge the disconnection between their individual ways with those of the community, aligning their own concerns with those of the whole.`
  },
  86: {
    name: 'Left Angle Cross of Education',
    gates: '(12/11 | 25/46)',
    desc: `People who can lecture those who are open to receiving their individual insights, who can  influence and inspire others through their individual knowing. They deeply understand the need to commit resources to education, to provide widespread opportunities for education that lies at the foundation of global change. It is through education that we have the chance to participate in a growing and evolving society.`
  },
  87: {
    name: 'Left Angle Cross of Education',
    gates: '(11/12 | 46/25)',
    desc: `People who emphasize the fundamental need for education to be available to all of humanity. They understand the value of education for our evolution and the role it plays in society, in that until education is available to all and we have a common base, there cannot be true freedom. These people represent an active and transpersonal force, recognizing and promoting the fact that to understand our purpose on earth and to be free we need to be educated, and to have knowledge.`
  },
  88: {
    name: 'Left Angle Cross of Endeavor',
    gates: '(21/48 | 54/53)',
    desc: `People who are agents of change, on the lookout for something new they can be in charge of. These people focus their ambition, and are instrumental in, bringing together forces in a controlled environment to reach a common goal, such as scientific innovations and/or creating structures that improve communal business or enterprise.`
  },
  89: {
    name: 'Left Angle Cross of Endeavor',
    gates: '(48/21 | 53/54)',
    desc: `People driven by a strong desire to exteriorize their innate depth, and apply it to provide solutions to the world's problems. They are always ready to begin anew, focused on advancing in the world to fulfill their ambitions. They are able to offer a well of possible solutions and are deeply motivated to provide these, and will take control if their efforts are not supported or recognized.  Impelled by the need for expediency, they are ready to take things into their own hands to realize their ambitions and improve the inadequacies they see around them.`
  },
  90: {
    name: 'Left Angle Cross of Healing',
    gates: '(25/46 | 58/52)',
    desc: `People who carry a love for the body as well as a transpersonal love toward all life. They understand that to enjoy life, it is crucial to have a body that is in good health and they focus their efforts on correcting where the pattern is off and the body experiences dis-ease as a result. These are natural healers, here to heal others as well as being healed themselves.`
  },
  91: {
    name: 'Left Angle Cross of Healing',
    gates: '(46/25 | 52/58)',
    desc: `People who can spontaneously heal, and be healed, through the force of universal and impersonal love. This is a rare gift and encompasses the joy of being in a physical body and in the physical world. It asks them to be connected and committed to the other, to focus on sharing this universal force of love that is in essence a healing force with others. Naturally sensitive people who are themselves subject to and dependent on the healing force of love.`
  },
  92: {
    name: 'Left Angle Cross of Identification',
    gates: '(16/9 | 63/64)',
    desc: `People who are looking to apply their logical skills to improve and correct patterns that do not work, and who have the energy for detail work. They are looking to find solutions through experimentation, and are skilled at inspiring others to support their experiments. They gain support from others in getting them to identify with their plans and projected solutions to nurture their experiments.`
  },
  93: {
    name: 'Left Angle Cross of Identification',
    gates: '(9/16 | 64/63)',
    desc: `People who at an unconscious level deal with confusion and doubt by focusing on what is solid and steady, and what offers stability. They will identify with and be enthusiastic about what promises to be a stable foundation to assuage the mental pressure that creates the doubt. Once they are secure and necessary resources are available, they demonstrate and contribute their skills toward any endeavour that needs to be actualized.`
  },
  94: {
    name: 'Left Angle Cross of Incarnation',
    gates: '(24/44 | 13/7)',
    desc: `People with an intrinsic awareness of the process of incarnation, and geometries or meeting one another on fractal lines. They have a connection to both past and future, and recognize what the way can be and the continuity in the way. They can also recognize others in a deep way and strongly impact their direction if they are properly aligned to their own path in life.`
  },
  95: {
    name: 'Left Angle Cross of Incarnation',
    gates: '(44/24 | 7/13)',
    desc: `People who are attuned to and connected to the past, and who like to keep the past alive. They can connect us to the spirit of the past or a different time, and tend to bring in things from the past such as ancient languages, musical instruments, etc. They are aware that we keep returning into form (reincarnate) to learn and experience, live our purpose, and that there is a continuity to this process. A main concern for them is that sufficient resources are available for us as a species to survive.`
  },
  96: {
    name: 'Left Angle Cross of Individualism',
    gates: '(39/38 | 51/57)',
    desc: `People whose very individualism provokes and brings out opposition from others. They have their own unique and intuitive way of doing things, which in itself creates opposition, and they are generally oblivious and resistant to any influence from the outside. Strong mutative forces who can shock others with their unusual and individual behavior.`
  },
  97: {
    name: 'Left Angle Cross of Individualism',
    gates: '(38/39 | 57/51)',
    desc: `People who need to provoke others to get to the core of things. They are pure individuals, and their distinct individuality and very unique way of doing things often creates tension in others simply for this very fact. They can inadvertently shock others through their outspoken provocations, but this is simply a way of expressing their individuality and testing the value of things. Their purpose is to constantly refine their individual way of being in the world, to pursue this without allowing any interference or being compromised. To live out their individualism in spite of the opposition and tension they can create in others is a challenge they face. In that way, they can greatly impact others and bring change into their lives as well.`
  },
  98: {
    name: 'Left Angle Cross of Industry',
    gates: '(30/29 | 34/20)',
    desc: `Very busy and charismatic people, tirelessly on the move, with a zeal for being industrious. Driven by the desire for experience and ever ready to commit themselves to a new process that promises to fulfill their expectations and the emotional potential found in each experience, they persevere even to the point of complete exhaustion. Their busy-ness and need to be productive is ultimately motivated by humanity's need for progress.`
  },
  99: {
    name: 'Left Angle Cross of Industry',
    gates: '(29/30 | 20/34)',
    desc: `People with immense energy and drive upon whom others project that they can “do the job.” They possess a strong charisma that they are not aware of. Through the sheer life force within them, they are often driven to overextend themselves and to commit to all kinds of things, which leaves them burned out. Once they are clear what to commit to, they know how to persevere and can accomplish a huge amount of work.`
  },
  100: {
    name: 'Left Angle Cross of Informing',
    gates: '(22/47 | 11/12)',
    desc: `People with the ability to both listen to and inform others. Their gift of openness combined with their ability to articulate enables them to inform others in a variety of ways or mediums, whether through formal teaching or by simply spreading what is new and different. At the core of this is the need to make sense of things, to inform others of their findings and influence them, bringing change and mutation.`
  },
  101: {
    name: 'Left Angle Cross of Informing',
    gates: '(47/22 | 12/11)',
    desc: `People who show concern for those who are oppressed and who use the inspiration they derive from the past, and past mistakes, to improve conditions for others. They have the social skills to get people's attention and the capacity to articulate the need to bring about change. They inform for the benefit of the whole, and to protect the community. This informing can happen through art, music, poetry or any medium that serves this purpose to point out oppression and social injustice.`
  },
  102: {
    name: 'Left Angle Cross of Limitation',
    gates: '(42/32 | 60/56)',
    desc: `People who learn to master the Maya through accepting limitations. They understand that to complete a cycle, limitations are part of the process and in themselves ensure that a process can be completed, and with it the potential for material success. Limitations create the natural and necessary boundaries for any experience to be carried to completion. They will look for stimulation while dealing with limitation.`
  },
  103: {
    name: 'Left Angle Cross of Limitation',
    gates: '(32/42 | 56/60)',
    desc: `People focused on dealing with material reality, and the issues around expansion. They are proponents for properly evaluating and testing the practicality and durability of things to avoid failure. They deeply appreciate duration and conservation that guarantee the continuity of our process, and see impermanence as a limitation. Essential to this perspective is to acknowledge that we are dealing with limitation in life, as there naturally exists a limitation to growth and expansion.`
  },
  104: {
    name: 'Left Angle Cross of Masks',
    gates: '(13/7 | 43/23)',
    desc: `People with a unique direction in life, along with unique insights and personal reflections, who can offer others guidance. They may find themselves under pressure and obliged to offer others direction, and are best able to perform this role by wearing certain masks, costumes or titles that protect them and at the same time allow them to help others potentially find their direction in life.`
  },
  105: {
    name: 'Left Angle Cross of Masks',
    gates: '(7/13 | 23/43)',
    desc: `This Cross is about practical leadership. Others project upon these people that they can be authoritative and influential leaders, capable of offering practical solutions in times of need. They adopt an image or use a title to fulfill that role. If they are well prepared, they can use their authority and power to provide practical solutions.`
  },
  106: {
    name: 'Left Angle Cross of Migration',
    gates: '(37/40 | 5/35)',
    desc: `These people are looking to build communal structures that provide the setting for successful cohabitation; once these are completed, they move on. Always looking for change, once a structure or project is completed, they feel the need to take up the challenge and start anew elsewhere. At the root lies the need for progress; these people find contentment through moving forward in the hope of finding something better.`
  },
  107: {
    name: 'Left Angle Cross of Migration',
    gates: '(40/37 | 35/5)',
    desc: `People who migrate to move away from unacceptable and limiting conditions. They need to be part of communities focused on progress where they can apply their skills. When faced with stagnation, they move to communities that support their need for change and where they can contribute to progress.`
  },
  108: {
    name: 'Left Angle Cross of Obscuration',
    gates: '(62/61 | 3/50)',
    desc: `People with a skill to obscure or hide the truth of things through use of inessential or bizarre details. They have a sense for detail and statistics that do not seem relevant but may simply obscure things until a discovery is made. They will investigate the nuances of something that can potentially lead to discoveries of great value to the Collective.`
  },
  109: {
    name: 'Left Angle Cross of Obscuration',
    gates: '(61/62 | 50/3)',
    desc: `People who can deeply inspire others by pointing toward what is obscured. They can be very influential procuring details and laws about those aspects of universal principles that are not known to us. At the deepest level they can access inner truth and supply details about universal principles that may otherwise be overlooked or missed entirely. They need to take care not to squander energy by directing their focus toward what has no value.`
  },
  110: {
    name: 'Left Angle Cross of The Plane',
    gates: '(36/6 | 10/15)',
    desc: `People who need to balance their drive for immersing themselves in experiences that are exciting and satisfying, often resulting in crisis, with their innate need for correct behavior that keeps them attuned to the collective flow. These two aspects of their personality can be integrated if they enter into intimacy and relationship correctly through following strategy and authority. Only through the right partner(s) who bring them the right experiences can they reconcile these separate aspects of their nature and gain wisdom about the vagaries of life, and be catapulted beyond it.`
  },
  111: {
    name: 'Left Angle Cross of The Plane',
    gates: '(6/36 | 15/10)',
    desc: `People with a deep understanding of the material world, the material plane we live in and that we are inextricably part of. They love the work they do and can guide others in understanding and accepting material reality, that through living our true nature we are presented with all that we need. That way we can experience the grace of the mundane world.`
  },
  112: {
    name: 'Left Angle Cross of Prevention',
    gates: '(15/10 | 17/18)',
    desc: `People with a natural ability to see the imperfection of things, who understand and often have strong opinions about what needs to be corrected and improved, and what is potentially for the benefit of others. They routinely meet what does not work, and their gift is that their experience can prevent others from making the same mistakes. They can guide humanity towards a future with improved and more secure patterns.`
  },
  113: {
    name: 'Left Angle Cross of Prevention',
    gates: '(10/15 | 18/17)',
    desc: `People who are intent on pointing out behavior that could lead to danger, thereby trying to prevent harm. This is rooted in their understanding of individual survival, how survival is possible and what is required to guarantee a healthy survival. They have a judgmental perspective that is unconscious and that can be either beneficial to others or not. This correction prevention has a moral aspect regarding what is right or wrong (behavior), nevertheless with the intention to support others instead of simply judging their behavior. They need to be aware to hold to their own standards and not to be hypocritical.`
  },
  114: {
    name: 'Left Angle Cross of Refinement',
    gates: '(33/19 | 2/1)',
    desc: `The ability of the individual to grow within the privacy and protection of their own territory, their own realm. To be able to refine one's life, to create beauty within one's environment is the way to express essential aspects of oneself.  In a larger sense, this Cross is about the fulfillment through aesthetics and qualities of one's environment. On a mystical level, it is a refinement that takes place inside – to create a place of sanctity within, a place of beauty that heals.`
  },
  115: {
    name: 'Left Angle Cross of Refinement',
    gates: '(19/33 | 1/2)',
    desc: `People whose main need in life is to find their own direction and who, in following their proper direction, refine their experience of life and ensure their basic needs are met. They recognize a need to be aligned to a path of beauty and creativity to be able to fulfill their purpose, and that this beauty is available for others. They need to take care not to align with forces that are not correct for them, because of the pressure to have their needs met. Through refinement, they are able to procure what they need for their direction.`
  },
  116: {
    name: 'Left Angle Cross of Revolution',
    gates: '(49/4 | 14/8)',
    desc: `People who are potentially powerful revolutionaries,  proponents of equality and justice who are intent on protecting human rights. They carry strong principles that address our basic survival needs; they understand there is no peace on earth unless these needs are met. Here we find the revolutionary, expected to provide practical solutions and principles.`
  },
  117: {
    name: 'Left Angle Cross of Revolution',
    gates: '(4/49 | 8/14)',
    desc: `People who are expected to provide formulas that have practical applications and can bring about revolutions. They are here to point others in a new direction. They are also expected to contribute the resources that can bring about revolutions and empower changes in direction.`
  },
  118: {
    name: 'Left Angle Cross of Separation',
    gates: '(35/5 | 22/47)',
    desc: `People driven by the need for experience and progress, aware of the need for a diversity of experiences that underlie the structures of our societies and that form the potential for progress. An underlying and dynamic theme is the grace to be able to make room for, and embrace the need for diversity. This allows us to have distinct experiences that while they separate us, do not need to divide us. This potential for diversity is what drives the development of our civilizing process.`
  },
  119: {
    name: 'Left Angle Cross of Separation',
    gates: '(5/35 | 47/22)',
    desc: `People whose patterns diverge from the patterns of others. We need diversity in life and therefore different patterns are necessary. The separation from the general patterns of life that these people carry is a valuable contribution to the overall pattern. This separation results in the highly critical faculty of this Cross in that these people, at an unconscious level, can feel oppressed by patterns that are not working for them. They need to leave when the pattern is no longer correct or works for them, whether in a relationship or their workplace. So they look for experience of different patterns to find progress and look to the future to find security through what works.`
  },
  120: {
    name: 'Left Angle Cross of Spirit',
    gates: '(55/59 | 9/16)',
    desc: `People who seek to fulfill their need for pleasure through creativity, romance, and sexual companionship. They take great pleasure in life and through these experiences are looking to nourish their spirit. They need to feel the abundance of their spirit and thereby alleviate the strain of dealing with their changing moods. This is a very dynamic individual theme, and these people are skilled at seducing others.`
  },
  121: {
    name: 'Left Angle Cross of Spirit',
    gates: '(59/55 | 16/9)',
    desc: `Intimacy, reproduction, and romance play a major part in these people’s lives, with the underlying purpose to find spirit through emotional encounter. The theme is to transform sexual/emotional energy into mature and deep (self) love. The transformation of spirit takes place here.`
  },
  122: {
    name: 'Left Angle Cross of Uncertainty',
    gates: '(8/14 | 55/59)',
    desc: `People who use their unique creative abilities to make contributions to society. They are looking to find their spirit through (sexual) intimacy. Their changeable moods and individual nature drive them in search of fullness of spirit. They are seeking access to material resources, as an underlying theme is the insecurity around material wealth, as well as the uncertainty about whether the right spirit can ever be found.`
  },
  123: {
    name: 'Left Angle Cross of Uncertainty',
    gates: '(14/8 | 59/55)',
    desc: `People who are aware of the insecurities of the world, who can provide others with the physical resources needed to feel safe. They can take advantage of others' uncertainties and needs for security through offering their resources as well as their ability to access resources. In providing material support, they contribute to the welfare of others, but this also can assist them in finding emotional intimacy and abundance of spirit. In turn, they are deeply affected themselves if they do not have sufficient resources, which then becomes their own uncertainty.`
  },
  124: {
    name: 'Left Angle Cross of Upheaval',
    gates: '(17/18 | 38/39)',
    desc: `People who naturally provoke and disturb, who like to upset the status quo. They can easily shift things and thereby have great impact. Whether that provocation is rooted in the urge to reorganize and improve things that do not work correctly or perfectly or whether it is done simply for personal reasons and satisfaction depends on the awareness that is available to the person with this Cross.`
  },
  125: {
    name: 'Left Angle Cross of Upheaval',
    gates: '(18/17 | 39/38)',
    desc: `People who can easily detect or observe if something is not working correctly with a standard pattern, such as an archetypal pattern, ready to challenge any authority to improve things. They are not afraid of disturbing the establishment and ready to shake things up, and their heretical views tend to provoke and cause upheaval. They understand that solutions need to be practical to be effective, and generally offer practical advice, e.g. therapists who offer practical solutions and guidance to those in need.`
  },
  126: {
    name: 'Left Angle Cross of Wishes',
    gates: '(3/50 | 41/31)',
    desc: `People oriented toward the future, seeking to provide leadership into the future that serves to improve conditions. These people are driven by the expectation that they will find ways of improving the conditions of a given situation. They can have significant impact in being the driving force behind changes that ultimately affect existing patterns or inadequate rules, and that reorder things in a way that improves the lives of others. They challenge established rules and will not allow complacency to settle in.`
  },
  127: {
    name: 'Left Angle Cross of Wishes',
    gates: '(50/3 | 31/41)',
    desc: `These people are here to transcend traditional values and who are looking at larger, transcendent values.They are in tune with contemporary thought and movements, ready to advocate a change in traditional rules and customs. Underlying this approach is the utopian dream of a better world, and they use their influence to question the usefulness of traditional customs, and challenge existing cultural and religious standards.`
  },
  128: {
    name: 'Right Angle Cross of Consciousness',
    gates: '(63/64 | 5/35)',
    desc: `These people are here to ask questions. They feel the pressure to mentally organize the world and they understand that progress must be built on a secure foundation. They will question and test the established patterns to examine if they are indeed viable. Once we have built secure physical structures to guarantee our survival, the next step in our evolutionary process is to go beyond and find answers to why we are here, how things work and what our purpose is.`
  },
  129: {
    name: 'Right Angle Cross of Consciousness',
    gates: '(35/5 | 63/64)',
    desc: `People who look to have a wide spectrum of experiences. They are driven by expectations and an avid hunger for progress, to explore things in a new way. They experience a mental pressure to look for change, to move on and improve things and experiences along the way.
  They carry the perspective of ¨been there, done that¨ and with it the potential that the drive for constantly changing experiences diminishes over time.`
  },
  130: {
    name: 'Right Angle Cross of Consciousness',
    gates: '(64/63 | 35/5)',
    desc: `People looking to make sense of their past by testing it against accepted patterns. They can assist others in their process of working through confusion and understanding their experiences. They inspire others to think in new and improved ways to make sense of their life's process, and to progress.`
  },
  131: {
    name: 'Right Angle Cross of Consciousness',
    gates: '(5/35 | 64/63)',
    desc: `People who are here to understand the nature of pattern, to grasp the fundamental patterns that underlie all life forms. They may discover a deep mystical potential found in waiting, in letting things happen and adjusting to the flow of life—including their own life. Here the opportunity is to deal with the mental pressure they experience, the doubt and confusion they feel in watching and being alert to patterns that bring progress.`
  },
  132: {
    name: 'Right Angle Cross of Contagion',
    gates: '(30/29 | 14/8)',
    desc: `People driven to search for new experiences, with the expectation of having their emotional needs and fantasies fulfilled through these experiences and adventures. They are on a journey, always ready to commit themselves to a process of discovery. Fueled by desire, they are the ones ready to try out new things and share the results and their findings with others, in that way exercising their contagious influence.`
  },
  133: {
    name: 'Right Angle Cross of Contagion',
    gates: '(8/14 | 30/29)',
    desc: `People who desire to make contributions that will ensure future security and wealth. They are committed to making creative contributions that eventually bring mutation to the Collective. People with this Cross will inevitably impact and deeply influence others with their contributions. They are contagious in that their contributions set examples.`
  },
  134: {
    name: 'Right Angle Cross of Contagion',
    gates: '(29/30 | 8/14)',
    desc: `People who are quick to respond to any new experience, who like to leap into things. They seek fulfillment through commitment, whether relationships, work or any kind of project. These are individuals who are looking to bond, to find that deep place within through bonding and commitment that can empower a new direction.`
  },
  135: {
    name: 'Right Angle Cross of Contagion',
    gates: '(14/8 | 29/30)',
    desc: `People with abundant energy that manifests as either material wealth or power, or both. This is about accumulation of wealth, and the commitment to contribute to society by providing resources and power skills required to move us in the right direction. They are focused on finding the right direction, waiting for the moment to make the commitment to the right direction in life that brings them success and leads humanity to evolve and grow.  Concurrently, the expectation of others is for them to provide the energy that brings the benefits of this potential, and they need to protect themselves from burnout and to protect their assets as they can be driven by blind commitment. They are skilled at bringing people together to join their energy, to invest resources needed to take a new direction.`
  },
  136: {
    name: 'Right Angle Cross of Eden',
    gates: '(36/6 | 11/12)',
    desc: `People looking for new experience, and the excitement derived from these experiences. They are driven by their need to find the ideal, whether through sexual union or other experiences that fulfill their expectations. There is a sense of a loss of innocence, that one has been thrown into life, confronted with the reality and challenges of the mundane plane, which drives them toward a more idyllic and sublime reality. They can inspire and impact others as they learn to embody the wisdom gained along the way, which they are able to articulate.`
  },
  137: {
    name: 'Right Angle Cross of Eden',
    gates: '(12/11 | 36/6)',
    desc: `People here to learn about the mundane plane, or being in the world, and who have the gift to articulate their experiences of life through art. They are stimulated by and open to what life has to offer and have the ability to express their experiences of life, love, and crisis in creative ways. They impact and teach others, whether through poetry, music, or education.`
  },
  138: {
    name: 'Right Angle Cross of Eden',
    gates: '(6/36 | 12/11)',
    desc: `People looking to find the ideal, to find meaning and escape the dispiriting and taxing aspects of being in a physical body. Their way in life is to come to terms with reality as it is, to simply experience life and surrender to the beauty of their own nature. A main aspect of their process is to find ways to make intimacy work, to bond with others, which requires them to work through crisis and conflict. They are here for the experience, and ultimately need to find ways to integrate their ideals and concepts. They gain experience through the loss of innocence.`
  },
  139: {
    name: 'Right Angle Cross of Eden',
    gates: '(11/12 | 6/36)',
    desc: `People who need to come to terms with life in the physical body and living in the physical reality of our world. There is a yearning underneath to return to the ideal state of being. They are often educators who teach others about the nature of experience, physical as well as emotional. They have a philosophical perspective about life and their own ideas and concepts about the world. They tend to point toward the experience of crisis and hardship that comes with earthly existence, often using the arts to communicate their philosophical musings.`
  },
  140: {
    name: 'Right Angle Cross of Explanation',
    gates: '(49/4 | 43/23)',
    desc: `People who are sensitive to those in need and focused on establishing the principles necessary to guarantee our survival as a species, and how to satisfy our basic needs. They need to explain and justify the principles behind our activities, explanations that come through their individual and unique perceptions.`
  },
  141: {
    name: 'Right Angle Cross of Explanation',
    gates: '(23/43 | 49/4)',
    desc: `People who carry the theme of bringing their individual knowing to the Collective.
  They have to explain their unique insights and concepts repeatedly to have a mutative impact, which means their concepts can bring change to the Collective so that completely new and even radically different perspectives can take hold. They are the outsiders with the drive to insist and tirelessly repeat their unique views that can bring completely new answers to questions raised.`
  },
  142: {
    name: 'Right Angle Cross of Explanation',
    gates: '(4/49 | 23/43)',
    desc: `People who seek answers. Their job is to come up with revolutionary theories that they need to explain, but which they cannot always substantiate as they are not backed up by logic, but by individual insight. Through repetition, they learn to provide explanations that others can accept.`
  },
  143: {
    name: 'Right Angle Cross of Explanation',
    gates: '(43/23 | 4/49)',
    desc: `People with a deep sense of inner knowing, who have their own unique insights. They experience a sense of their own truth and of permanence as it relates to their knowing.
  They are aware of the need for change and mutation, and they can provide answers and formulas that have revolutionary quality. It can be challenging for them to explain their concepts in a logical and effective way to make themselves understood. Their knowing mainly consists of a deep sense of insight without necessarily being able to explain this, and often need to work on their capacity to formulate and articulate their insights for others to grasp. Ideally, children with this Cross are taught to express themselves from early on.`
  },
  144: {
    name: 'Right Angle Cross of The Four Ways',
    gates: '(24/44 | 19/33)',
    desc: `People preoccupied with mind and its workings, trying to come to terms with the mental plane, to resolve and grasp it. They are pressured to perpetually ask the same question(s) until they find a rational answer. They interminably revise the same concepts, turning them over until there is a resolution and clarity emerges. They are looking for direction through finding answers to the most imminent questions.`
  },
  145: {
    name: 'Right Angle Cross of The Four Ways',
    gates: '(33/19 | 24/44)',
    desc: `The need to withdraw into the privacy of one's own domain. These people need to have their own territory that offers them privacy and the possibility for retreat. Once that territory is established, they have the ability to enclose themselves within the sanctity of their own realm and reflect on the experiences they had before they can share their insights, principles and perspectives with others.`
  },
  146: {
    name: 'Right Angle Cross of The Four Ways',
    gates: '(44/24 | 33/19)',
    desc: `People with a deep instinctive memory of the past, genetically stored, which drives them to ensure that necessary resources for our survival are available. They are aware of the need to bond with forces that allow for expansion of our material direction, i.e. with others who know how to “move things.” They need to make sure that things are set in motion to guarantee our survival.`
  },
  147: {
    name: 'Right Angle Cross of The Four Ways',
    gates: '(19/33 | 44/24)',
    desc: `People who are extremely sensitive to the basic needs of the Tribe/Collective, ensuring resources are distributed evenly and needs are met. They are aware of the pressures that are part of the social environment, and when it takes a revolution to change things. They are motivated by social and spiritual forces that move us to provide for the basic needs of others, such as basic resources, education and the need for territory (shelter), and exchange of service.`
  },
  148: {
    name: 'Right Angle Cross of Laws',
    gates: '(3/50 | 60/56)',
    desc: `The structure of our societies requires laws, rules and tenets that are fundamental for a successful cohabitation, in that they create order and determine how we interact with each other. People with this Cross bring in laws that are mutative in bringing major dynamic changes to our societies and ways of communal living. They are motivated by idealism and instrumental in establishing values that preserve our tribes and communities.`
  },
  149: {
    name: 'Right Angle Cross of Laws',
    gates: '(56/60 | 3/50)',
    desc: `People with a gift for storytelling, who look to stimulate others with their ideas and idealistic concepts that may lead to the formation of new laws. They look to overcome limitation by promoting their idealistic beliefs and visions, which can bring change to current values and laws. They can successfully 'sell their dreams' to affect need changes, e.g. in the political landscape.`
  },
  150: {
    name: 'Right Angle Cross of Laws',
    gates: '(50/3 | 56/60)',
    desc: `Here rules are established to assist the well being and functionality of the Tribe, to support and protect its customs. People who control the tribal environment to ensure that rules and values are protected. They create, adapt and evaluate the laws that accommodate the evolution of our species. This is a creative process that deals with humanity's limitations, and our need to continuously change and evolve.`
  },
  151: {
    name: 'Right Angle Cross of Laws',
    gates: '(60/56 | 50/3)',
    desc: `People who identify with and look to protect traditional laws.They understand that certain rules and traditions need to be maintained, no matter how far civilization advances. They accept the limitations that old structures place on us us via genetic imprint, and are able to adapt to them. Unconsciously, there is an understanding that change and mutation are part of life and need to take place; still, their focus lies in protecting current values and expressing this through caring for others. They maintain traditional values that inform our laws.`
  },
  152: {
    name: 'Right Angle Cross of Maya',
    gates: '(42/32 | 61/62)',
    desc: `People who learn to master the Maya through living through complete cycles of experience. Any new beginning needs to be taken to a complete conclusion before the next cycle starts to ensure growth and fulfillment. They need to collect all available detail to be grounded in the experience, to understand their process and find inner truth.`
  },
  153: {
    name: 'Right Angle Cross of Maya',
    gates: '(62/61 | 42/32)',
    desc: `People who use logic and detail to create the language that defines and describes our world, or Maya. These can be powerful educators who know that through logic we can understand the Maya and operate safely within it, healing and transforming ourselves in the process. They have the ability to describe their inner truth, and to substantiate it with detail.`
  },
  154: {
    name: 'Right Angle Cross of Maya',
    gates: '(32/42 | 62/61)',
    desc: `People who can easily recognize the value of things, who can evaluate what is needed in terms of material expansion and development to secure the continuity of our human process, or world (Maya). They have an innate sense and instinct to recognize those with the potential to support this process of continuous development through the right values, and thereby avoid failure.`
  },
  155: {
    name: 'Right Angle Cross of Maya',
    gates: '(61/62 | 32/42)',
    desc: `People who experience a deep pressure to know, and to know that which is not commonly known or even knowable. They need to understand how the Maya works, how it functions and are pressured to figure out all the details of this process; they need to truly know, not merely believe. These are inspired thinkers who can in turn inspire others to investigate the Maya (our world) at a deeper level. They are forces pointing our awareness to the constantly changing reality we live in and the mutation(s) that take place. They are seeking universal principles in the details. Underlying this is a deep fear and the need to resolve questions around life and death. `
  },
  156: {
    name: 'Right Angle Cross of Penetration',
    gates: '(51/57 | 54/53)',
    desc: `People with the capacity to penetrate others’ aura through their intuitive insights, who can inadvertently shock others with their acuity. They are mutative agents, meaning they carry within them the energy to bring profound change. The depth of their penetrating insights, often unexpected, can be of enormous value to others. They are very dynamic forces, opening up what is new, and ushering in new beginnings.`
  },
  157: {
    name: 'Right Angle Cross of Penetration',
    gates: '(53/54 | 51/57)',
    desc: `People under pressure to start new things, to start new cycles. They have a strong influence on our evolutionary process as they influence the Collective toward development and growth. Driven by ambition to be recognized and approved for their ability to start new cycles, they know how to get others involved and influence them to join the process.`
  },
  158: {
    name: 'Right Angle Cross of Penetration',
    gates: '(57/51 | 53/54)',
    desc: `People who are highly intuitive and skilled at 'reading' others at a very deep level. Theirs is a penetrating awareness that can discern and intuit what is correct for them.  They follow their ambition to succeed on the material plane, guided by an extraordinary clarity which can apprehend when something is not healthy, or correct. Their competitive edge and readiness to take risks drives them to start new things to fulfill their ambitions and advance in the world. Here we also find the strong potential for spiritual transformation, to rise in the world to transform spiritually.`
  },
  159: {
    name: 'Right Angle Cross of Penetration',
    gates: '(54/53 | 57/51)',
    desc: `People who need to be recognized for their abilities to get ahead in the world. They are driven to move to the top and be successful, which depends on opportunities that present themselves. The way they seize opportunities to advance is through being recognized by those in a position to further their ambition. They have a gift of ingratiating themselves with others and can easily penetrate the aura of others, which gives them an advantage. They are always ready to begin a new process once they are recognized and if it advances them on their way up. The general theme here is being recognized, moving up, and being transformed in the process.`
  },
  160: { name: 'Right Angle Cross of Planning', gates: '(37/40 | 9/16)' },
  161: {
    name: 'Right Angle Cross of Planning',
    gates: '(16/9 | 37/40)',
    desc: `People with a capacity to focus on practical and detailed solutions to improve and correct things. Deeply identified with their work, they apply their skills to find the right pattern, the correct way of doing things so that progress and mastery can take place. Through experimentation, they look for ways that correction and improvement can be established to benefit the community.`
  },
  162: {
    name: 'Right Angle Cross of Planning',
    gates: '(40/37 | 16/9)',
    desc: `People with skills, a gift for logic and attention to detail. They need to be clear about the amount of energy and work that is expected of them, and that what they receive in exchange is correct. When they feel the bargain is right and the rewards are worthwhile, they are  available to contribute their skills to the greater good of the community.`
  },
  163: {
    name: 'Right Angle Cross of Planning',
    gates: '(9/16 | 40/37)',
    desc: `People who focus on the community's needs, and on the practical aspects required to support communal life. They can supply consistent energy to focus on detail work, and to finding the bargain that guarantees the resources needed to support the creation of social structures within the community. They are enthusiastic about planning, but also have the sensibility to focus on pertinent details once they have secured the resources necessary for this task.`
  },
  164: {
    name: 'Right Angle Cross of Rulership',
    gates: '(22/47 | 26/45)',
    desc: `People with a natural sense of position that others easily acknowledge. If they find themselves in charge of a territory assuming the role of leader, their responsibility is to assure that education is available. If not in an actual position of rulership, they will in some form rule the lives of others. They have a gift in being able to truly listen, and their openness enables them to do the work required of them.`
  },
  165: {
    name: 'Right Angle Cross of Rulership',
    gates: '(45/26 | 22/47)',
    desc: `People here to be offered power and the opportunities to exert influence within their territory, nowadays typically through a position within the corporate world. This rulership has a material quality and brings with it the responsibility to manage and organize resources in ways that benefit those within their community. There is a quality of openness and the ability to listen to the needs of others.`
  },
  166: {
    name: 'Right Angle Cross of Rulership',
    gates: '(47/22 | 45/26)',
    desc: `People who take their inspiration from the past, who borrow from the traditions of the past and the lessons learned, and bring these forward into the present. They reinterpret past concepts to adapt them to current circumstances, and in that way establish their right to rule. They are dedicated to education, to increase awareness of others. This rulership comes in many forms but is always based on learning from the past so as not to repeat the same mistakes.`
  },
  167: {
    name: 'Right Angle Cross of Rulership',
    gates: '(26/45 | 47/22)',
    desc: `People with a strong social appeal who display a powerful persona to engage the collective and in that way be lifted to a position of power. They appeal to the collective through being able to market themselves using a personal approach that others can identify with. The main intention here is to be in a position of power and to be a leader. This is a powerful ego that reaches a place of influence through making promises, such as the politician who promises a better future, and to lift others out of oppression.`
  },
  168: {
    name: 'Right Angle Cross of Service',
    gates: '(17/18 | 58/52)',
    desc: `People who perform services for service's sake. While they derive satisfaction from offering service to support to humanity, their greatest satisfaction comes from simply doing their work. The driving motivation is the dissatisfaction with things that are not perfect, and they will focus on what needs to be improved or corrected. People who have the ability to organize their environment.`
  },
  169: {
    name: 'Right Angle Cross of Service',
    gates: '(52/58 | 17/18)',
    desc: `People with a natural ability to concentrate and focus in stillness. They are easily dissatisfied with the imperfection of things, and their responsibility is to detect what needs to be corrected. They use their highly logical skills to focus on and point out patterns that need to be improved and corrected to lead us into a secure future. This way, they provide a service to society at large, and to all those looking for advice.`
  },
  170: {
    name: 'Right Angle Cross of Service',
    gates: '(18/17 | 52/58)',
    desc: `People focused on correcting the parental archetypes, on improving the archetype of both the male and female. They inherently understand that we need to consistently improve the ways we embody our male and female roles, and the archetype of the parent to perfect our way of being. This is a collective need, to evolve and consistently improve these main aspects of our lives, focused ultimately on creating the best possible ways to further the evolution of our species. This process involves dealing with a wide range of ideologies, intellectual concepts and opinions about how to correct and improve, and what is best.`
  },
  171: {
    name: 'Right Angle Cross of Service',
    gates: '(58/52 | 18/17)',
    desc: `People with a natural sense for service, ready to offer energy toward improvements that benefit society. They need to identify strongly with something before they will dedicate their energy to correcting a pattern. At the root of this Cross is a joy for life, but also a critical eye and judgment about what does not work, which patterns need to be corrected or improved to bring the collective forward into a secure future. They are dedicated to focusing their energy on what needs improvement and correction, but only if they identify with the issue/task at hand to keep their focus long enough to bring about improvement.`
  },
  172: {
    name: 'Right Angle Cross of The Sleeping Phoenix',
    gates: '(55/59 | 34/20)',
    desc: `People busy looking for love and intimacy to find their spirit through these experiences.Their search is for an ideal love, a quality of love leading to transformation that is yet to be realized and therefore elusive. Moody and creative, they search industriously for fulfillment through romance and sexual intimacy to alleviate their spirit. The 55th gate is where the mutation of the emotional system will eventually take place, and emotional awareness is realized. This will transform the way we experience love and sexuality.`
  },
  173: {
    name: 'Right Angle Cross of The Sleeping Phoenix',
    gates: '(20/34 | 55/59)',
    desc: `People who are completely absorbed in their process of being busy. Constantly on the move and occupied with being industrious, they are looking to find intimacy and emotional abundance, which is nevertheless superseded by their need to be absorbed in their own creative process. This Cross is in the process of waiting for the mutation of emotional awareness.`
  },
  174: {
    name: 'Right Angle Cross of The Sleeping Phoenix',
    gates: '(59/55 | 20/34)',
    desc: `These are people driven to seek intimacy, who are looking to find security through bonding. They are looking to procreate and their search will take them through numerous encounters with others. This Cross is about Initiation, or finding truth, which is the driving force to “wake up.”`
  },
  175: {
    name: 'Right Angle Cross of The Sleeping Phoenix',
    gates: '(34/20 | 59/55)',
    desc: `People with immense personal charisma whose power is expressed through being busy and constantly active. They have tremendous Sacral energy, which provides the fuel for their busyness, makes them extremely attractive and is a way to engender intimacy. Underneath lies a sexual strategy to find emotional and sexual intimacy as well as abundance of spirit. If they do express power directed by their spirit they can be of great service to humanity.`
  },
  176: {
    name: 'Right Angle Cross of The Sphinx',
    gates: '(13/7 | 1/2)',
    desc: `People with an empathetic nature, here to provide others with direction and guidance. This capacity to guide derives from a memory of all the experiences gathered, reflecting on past events and our history. People with this Cross represent important forces in our evolutionary movement as they carry within their field the direction humanity is taking. Others can be initiated simply by being in their aura. They themselves follow a unique direction, and can be extremely self-absorbed and focused on their own process.`
  },
  177: {
    name: 'Right Angle Cross of The Sphinx',
    gates: '(2/1 | 13/7)',
    desc: `People who are here to point out the direction the Collective is taking as they are inherently connected to our geometry, our movement in space. With this particular Sphinx Cross they are aware of the real theme of the entire direction of humanity which is the movement towards beauty. The nature of our basic direction is about beauty, whether that is natural beauty or beauty which needs to be created.  These people are very important forces as they can open up any possible direction for others. They can point out any possible way because of their ability to 'see' things, with an ability to look backwards as well as forwards, or being in the moment. Their perspective can therefore embrace many different possibilities for direction. It is not easy for them though to be specific or point in a particular way but they simply have a general knowing. Their alignment is always to beauty, and the direction leads to mutation.`
  },
  178: {
    name: 'Right Angle Cross of The Sphinx',
    gates: '(7/13 | 2/1)',
    desc: `People whose role is to provide direction to others. They are here to lead and have the authority to be very influential. People with logical skills who can offer a direction to humanity, based on experiences of the past, and with an orientation towards a secure future.`
  },
  179: {
    name: 'Right Angle Cross of The Sphinx',
    gates: '(1/2 | 7/13)',
    desc: `These are unique individuals with a determination to follow their own direction in life. They are not concerned about the other, but stay focused on their own track. In this way, this Cross differs from the other Sphinx Crosses that focus on offering advice to others to find direction. There is a strong self-interest when it comes to expressing their creative drive, and they consequently follow their own route. They lead by example rather than offering active advice, and in that way provide and maintain a mutative direction that can help others to actualize change.`
  },
  180: {
    name: 'Right Angle Cross of Tension',
    gates: '(21/48 | 38/39)',
    desc: `People who need to be in control. These are natural leaders who deal with the pressure of being in a place of authority, and confronted with the need to guard and maintain this authority so that the tribe/community will function properly. The control needs to be rooted in depth to support the position of authority within their environment. People who naturally provoke and cause opposition.`
  },
  181: {
    name: 'Right Angle Cross of Tension',
    gates: '(39/38 | 21/48)',
    desc: `People who create tension through provocation and opposition to tax and call out the spirit in others. They know how to push buttons and tease while they are looking for the right spirit that shows talent and purpose, and which will nourish their need for creative emotional expression. This way, they can release their own inner tension.`
  },
  182: {
    name: 'Right Angle Cross of Tension',
    gates: '(48/21 | 39/38)',
    desc: `People with an innate depth and a strong potential for clarity that can be applied to find workable solutions for problems. They are generally looking for the right skills to prove that their solutions are indeed workable but are in fact best at provoking and challenging patterns that need to be improved, and wait for the solutions to be applied and tested. The way to relieve the tension is to focus on their depth, and wait for those who supply the skills to provide effective solutions.`
  },
  183: {
    name: 'Right Angle Cross of Tension',
    gates: '(38/39 | 48/21)',
    desc: `People intent on finding their purpose in life. They understand that life is a struggle and that their spirit needs to be provoked to find what is worth fighting for. The process of individualization and realizing one's purpose requires struggle and confrontation, and the tension they experience during this process can often be uncomfortable for others but is natural for them. They experience deep melancholy at times, which can be a great source of creativity. They generally need to be in control of a situation to reach their creative depth.`
  },
  184: {
    name: 'Right Angle Cross of The Unexpected',
    gates: '(27/28 | 41/31)',
    desc: `People who are impacted by the unexpected, who encounter surprising events in their personal lives. While they desire new experiences, they learn that uncertainty is part of their experiential process, and that uncertain outcomes and even radical change are integral to their process. Through this they gain a unique perspective that teaches others that while we have rules and laws, there must be room for exceptions. Theirs is a nurturing quality that values and protects the personal interests of others over the rules that define communal living.`
  },
  185: {
    name: 'Right Angle Cross of The Unexpected',
    gates: '(31/41 | 27/28)',
    desc: `This Cross is about unexpected leadership, people who are discovered by the Collective and catapulted into a position of power to influence and guide others. This unexpected task of having to handle power and yield influence is often brought on through sudden circumstances, and turns people (who live seemingly ordinary lives) into leaders with sudden authority and influence. Coming into power unexpectedly and learning how to handle it is the theme of this Cross. Underlying it is deep drive for risk taking.`
  },
  186: {
    name: 'Right Angle Cross of The Unexpected',
    gates: '(28/27 | 31/41)',
    desc: `People whose main objective is that their life has value, that they get to live their purpose. They are ready to struggle for this, and if they surrender to struggle as an essential aspect of their process and as a means to find purpose, life often presents them with sudden and unexpected events, which is the magic of this Cross. They may be catapulted into a position of influence, or are called to take on responsibility to care for others, either of which can lead them to fulfill their purpose.`
  },
  187: {
    name: 'Right Angle Cross of The Unexpected',
    gates: '(41/31 | 28/27)',
    desc: `People who are forces that bring something new into the world. The 41 at the genetic level initiates the New. The theme here is how to deal with new and unexpected experience; they are likely to find and extract the new out of the old. They can see the advantages in unexpected experiences, such as a sudden discovery that seemingly happens by chance, and can deal with it accordingly by making the best use of it. They can be trendsetters in any field, such as politics, fashion, etc. with an underlying theme of caring for what is best, and what promises a better future. They are very influential in this way, and good at creating expectations in others, though they need to be aware not to get caught up in expectations aroused by new experience.`
  },
  188: {
    name: 'Right Angle Cross of The Vessel of Love',
    gates: '(25/46 | 10/15)',
    desc: `People who have the potential to express love in its pure, universal way. This is not a personal love but an unconditional love that flows out toward all life, based on the recognition of the unity of all life forms. These are very powerful forces that carry a balancing aspect to life on the mundane plane, wrought with all its inherent challenges for survival, and which can greatly benefit those around them.`
  },
  189: {
    name: 'Right Angle Cross of The Vessel of Love',
    gates: '(15/10 | 25/46)',
    desc: `People whose personal destiny is to care for humanity. They look to the future and are interested in how to organize things better and more efficiently to improve the flow of things. People who have their own distinct rhythm, and a natural understanding and acceptance of the broad spectrum of rhythms that make up the larger flow. At the foundation of this lies a love and with it a concern for humanity, to care about its organization, and bringing everyone into a healthy flow.`
  },
  190: {
    name: 'Right Angle Cross of The Vessel of Love',
    gates: '(46/25 | 15/10)',
    desc: `People who take great joy and pleasure in being in a physical body. They are acutely aware of its needs and determined to protect and care for it. Their attention to the physical encompasses all the nuances of being in a physical form and its need for comfort, ready to eliminate or avoid discomfort at any level. People who love being alive and in the physical world, and enjoy life for life’s sake. Their acceptance of physical reality brings them to a place of serendipity—being in the right place at the right time, and to the good fortune that emerges through this surrender.`
  },
  191: {
    name: 'Right Angle Cross of The Vessel of Love',
    gates: '(10/15 | 46/25)',
    desc: `People with this Cross have a very individual process of finding love and direction. This is the potential to love 'the Way', to love being in the world and being in the body. This Cross is about individuated behavior, living and enjoying one's difference and impacting others with this quality. These people primarily focus on their own life, and their behavior is pointed towards something very individual. They are searching for self love by trying to become a pure individual.`
  }
}
