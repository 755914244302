<template>
  <b-modal v-model="visible"
           :title="title"
           :ok-title="okTitle"
           :cancel-title="cancelTitle"
           @ok="confirm"
           @cancel="cancel"
  >
    <div v-if="visible" v-html="message"></div>
  </b-modal>
</template>
<script>

export default {
  props: {
    title: {
      type: String,
      default: "Are you sure you want to proceed?"
    },
    message:  {
      type: String,
      default: "<p>This action is irreversible. We will be unable to recover any lost data. Are you sure you want to proceed?</p>"
    },
    okTitle: {
      type: String,
      default: "Ok, proceed"
    },
    cancelTitle: {
      type: String,
      default: "Cancel"
    }
  },
  data() {
    return {
      visible: false,
      resolvePromise: null,
      rejectPromise: null,
    };
  },

  methods: {
    show() {
      this.visible = true;
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    confirm() {
      this.resolvePromise(true);
      this.visible = false;
    },
    cancel() {
      this.rejectPromise(false);
      this.visible = false;
    },
  }
}
</script>