import properties from "./properties";
import analysis from "./analysis";
import tooltips from "./tooltips"
import transit from "./transit";
import shared from "@/lang/en/charts/shared";
export default{
    properties,
    analysis,
    tooltips,
    transit,
    shared
}