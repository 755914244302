export default `<svg viewBox="0 0 612 612" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <path d="M38.25,344.25C38.25,492.113,158.117,612,306,612s267.75-119.887,267.75-267.75
    c0-92.749-47.178-174.479-118.821-222.515c22.001-10.066,37.279-32.236,37.279-57.992C492.208,28.538,463.67,0,428.445,0
    c-35.188,0-63.743,28.538-63.743,63.743c0,25.886,15.445,48.148,37.596,58.142c28.053,12.159,53.267,29.322,75.006,51.063
    c22.263,22.263,39.707,48.168,51.922,77.005c12.624,29.863,19.012,61.576,19.012,94.298c0,32.702-6.388,64.435-19.013,94.28
    c-12.215,28.855-29.659,54.761-51.922,77.004c-22.263,22.263-48.186,39.744-77.022,51.94c-29.846,12.626-61.559,19.013-94.28,19.013
    s-64.435-6.388-94.28-19.013c-28.837-12.196-54.761-29.678-77.023-51.94c-22.263-22.243-39.707-48.148-51.922-77.004
    c-12.626-29.846-19.013-61.578-19.013-94.28c0-32.722,6.388-64.435,19.013-94.3c12.215-28.837,29.659-54.741,51.922-77.004
    c21.74-21.74,46.953-38.941,74.986-51.081c22.133-10.011,37.56-32.254,37.56-58.122C247.242,28.538,218.685,0,183.479,0
    c-35.206,0-63.763,28.538-63.763,63.743c0,25.775,15.315,47.962,37.316,58.01C85.408,169.809,38.25,251.501,38.25,344.25z
     M390.194,63.743c0-21.086,17.164-38.25,38.25-38.25c21.104,0,38.25,17.164,38.25,38.25c0,21.087-17.146,38.25-38.25,38.25
    C407.359,101.993,390.194,84.83,390.194,63.743z M145.23,63.743c0-21.086,17.163-38.25,38.25-38.25s38.25,17.164,38.25,38.25
    c0,21.087-17.163,38.25-38.25,38.25S145.23,84.83,145.23,63.743z"/>
</svg>`