export default{

    title: 'The Owl',
    close: 'Close',
    knowledgeBase: {
        title: 'Knowledge Base'
    },
    notes:{
        title: 'Notes',
        add: 'Add note',
        noNotes: 'You do not have any notes yet',
        noResults: 'There are no notes found matching your search.',

        textAreaTitle: 'Write your note about {name}\'s chart',
        textAreaTitleNoChart: 'Note Text',
        clickOutside: 'Click outside the box to save',
    },
    dictionary:{
        title: 'Dictionary',
        searchPlaceholder: 'Start typing a term...',
        close: 'Close Dictionary',
        noResults: 'No terms found matching your search query',
        lastSearches: 'Last 20 searches on this computer'
    },
    iching: {
        title: 'Rave I’Ching',
        intro: 'What’s the Rave I’Ching?',
        data: '<p>As the oldest of the Chinese classic texts, the I’Ching or Book of Changes is an ancient divination tool used for over three thousand years. The roots of the I’Ching we see today can be dated back to the 8th century BC.</p>' +
            '<p>In 1989, after his encounter with the Voice, Ra Uru Hu wrote the Rave I’Ching—the foundation upon which Human Design rests and the key to unlocking the code of our genetics. There are 64 Gates (or Hexagrams) within the BodyGraph, and each Hexagram can be divided into 6 Lines. The Rave I’Ching is Ra Uru Hu\'s original descriptions of each of the 384 Lines, including Names, Keynotes, Exaltations and Detriments.</p>' +
            '<p>The Rave I’Ching can be unlocked for your chart through a single unlock (on chart properties), or for all charts through a subscription.</p>'
    },
    notifications: {
        title: 'News',
        updates: 'Updates',
        news: 'News',
    }


}