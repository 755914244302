import moment from "moment/moment";
import ChartCreationMixin from "./ChartCreationMixin";


export default{
    mixins: [ChartCreationMixin],

    methods: {
        createTransitWrapper({
            dateStamp,
            timeStamp,
            dateFormat = 'DD/MM/YYYY',
            timeFormat = 'HH:mm',
            chart,
            save = false,
            skipNavigation = false,
         isCustom = false
       }){

            const date = moment.utc(dateStamp, dateFormat).format(dateFormat); // fixes potential small issues
            const time = moment.utc(timeStamp, timeFormat).format(timeFormat); // fixes potential small issues


            const transitTime = moment.utc(date + ' ' + time, dateFormat + ' ' + timeFormat);
            const transitDateStr = this.formatDate(moment(transitTime).local().format('lll'), false, 'DD MMM yyyy @ HH:mm')

            const request = {
                autosave: save,
                time: transitTime.toISOString(),
                timeInUtc: true,
                timezone: this.CurrentTimeZoneName,
                docId: chart ? chart.id : null,
                name: `${chart ? chart.name + ' - ' : ''}${transitDateStr}`
            }

            if(this.name) request.name = this.name;

            return this.createTransitChart(request).then(newChart=>{
                this.$store.dispatch('charts/setChart', newChart);

                if(skipNavigation) return newChart;
                this.redirectTo(chart && chart.id ? 'temp-transit-connection-chart' : 'temp-transit-chart', null, {
                    transit: isCustom ? 'custom' : 'just-now',
                    transitChartId: chart ? chart.id : undefined,
                    chartId: 'temp-doc',
                }, {
                    pre_fetched: true,
                    // transit:isCustom ? 'custom' : 'just-now',
                })
            })
        }
    }
}