import centers from '@/helpers/rave/constants/centers'

export default {
  [centers.ROOT.id]: 'Root',
  [centers.SACRAL.id]: 'Sacral',
  [centers.SPLEEN.id]: 'Splenic',
  [centers.SOLAR_PLEXUS.id]: 'Solar Plexus',
  [centers.HEART.id]: 'Heart',
  [centers.G.id]: 'G',
  [centers.THROAT.id]: 'Throat',
  [centers.AJNA.id]: 'Ajna',
  [centers.HEAD.id]: 'Head'
}
