<template>
  <div v-if="planet" id="related-links" class="px-0 px-md-1">
    <div v-if="!isNested && (isExalted || isDetriment)" class="fixing-planets">
      <p class="my-2 d-flex align-items-center">{{ $t('hd.properties.fixingPlanet') }}</p>
      <div class="grid grid-cols-1 gap-5">
        <related-link-toggle
            v-if="isExalted"
            :key="`planet-${getFixingPlanets.exalted}`"
            :icon="planets.icons[getFixingPlanets.exalted]"
            type="planet"
            :is-transit="isTransitPlanet"
            :is-nested="true"
            :can-toggle="false"
            :is-reverse-background="isNested"
            :depth="depth"
            start-open
            :render-props="{
                                id: getFixingPlanets.exalted.planet,
                                planet: getFixingPlanets.exalted.planetObject,
                                planetId: getFixingPlanets.exalted.planet,
                                activation: getFixingPlanets.exalted.activation,
                                chartId: getFixingPlanets.exalted.chartId,
                                customIconClasses: getPlanetColorClasses(getFixingPlanets.exalted.planetObject),
                                getIcon: () => {},
                                reverseBackground: !isNested
                             }"
            :label="getPlanetText(getFixingPlanets.exalted.planetObject, true, true)"
        />
        <related-link-toggle
            v-if="isDetriment"
            :key="`planet-detriment-${getFixingPlanets.detriment}`"
            :icon="planets.icons[getFixingPlanets.detriment]"
            type="planet"
            :is-transit="isTransitPlanet"
            :is-nested="true"
            :can-toggle="false"
            :is-reverse-background="isNested"
            :depth="depth"
            :render-props="{
                                  id: getFixingPlanets.detriment.planet,
                                  planet: getFixingPlanets.detriment.planetObject,
                                  planetId: getFixingPlanets.detriment.planet,
                                  activation: getFixingPlanets.detriment.activation,
                                  chartId: getFixingPlanets.detriment.chartId,
                                 customIconClasses: getPlanetColorClasses(getFixingPlanets.detriment.planetObject),
                                  getIcon: () => {},
                                  reverseBackground: !isNested
                               }"
            :label="getPlanetText(getFixingPlanets.detriment.planetObject, true, false)"
        />
      </div>
    </div>
    <div v-if="planet.gate && !isNested" class="gates">
      <p class="my-2 d-flex align-items-center"> {{ $t('hd.gates.name.one') }}</p>
      <div class="grid grid-cols-1 gap-5">
        <related-link-toggle v-for="gate in [planet.gate]" :key="`gate-${gate.id}`"
                             type="gate"
                             :render-props="{
                                  id: gate,
                                  gateId: gate,
                                  reverseBackground: !isNested
                              }"
                             :is-nested="isNested"
                             :is-reverse-background="isNested"
                             :depth="depth"
                             :label="getGateToggleLabel(gate)"
        />
      </div>
    </div>
  </div>
</template>

<script>

import util from "../../../../mixins/util";
import {mapState} from "vuex";
import centers from "../../../../helpers/rave/constants/centers";
import fixings from "../../../../helpers/rave/constants/fixings";
import planets from "../../../../helpers/rave/constants/planets";
import ChartUtil from "../../../../mixins/ChartUtil";
import RelatedLinkToggle from "./partials/RelatedLinkToggle.vue";
import RelatedLinksMixin from "../../../../mixins/RelatedLinksMixin";

export default{
  components: {RelatedLinkToggle},
  mixins: [util, ChartUtil, RelatedLinksMixin],
  props:{
    planet: Object,
    selectedId: Number,
    isNested: Boolean,
    parentReverseBackground: Boolean,
    depth: Number,
  },
  computed:{
    ...mapState({
      chart: state => state.charts.chart,
      chart_data: state => state.charts.chart.chart,
      tabs: state => state.ui.tabsMapping
    }),
    planets(){
      return planets;
    },

    channel(){
      return centers.getById(this.selectedId);
    },

    getFixingPlanets(){
      const exalted = this.planet.fixing?.triggers?.find(x => x.state === fixings.EXALTED);
      const detriment = this.planet.fixing?.triggers?.find(x => x.state === fixings.DETRIMENT);

      return {
        exalted: exalted ? {
          ...exalted,
          id: exalted.planet,
          planetObject: this.chart_data.planets.find(x => x.id === exalted.planet && x.activation === exalted.activation && x.chartId === exalted.chartId)
        } : undefined,
        detriment: detriment ? {
          ...detriment,
          id: detriment.planet,
          planetObject: this.chart_data.planets.find(x => x.id === detriment.planet && x.activation === detriment.activation && x.chartId === detriment.chartId)
        } : undefined
      }
    },

    isExalted(){
      return this.planet.fixing.state === fixings.JUXTAPOSED || this.planet.fixing.state === fixings.EXALTED;
    },
    isDetriment(){
      return this.planet.fixing.state === fixings.JUXTAPOSED || this.planet.fixing.state === fixings.DETRIMENT;
    },
  },

  methods:{
    getExaltedOrDetrimentText(isExalted = false){
      const planet = isExalted ? this.getFixingPlanets.exalted.planetObject : this.getFixingPlanets.detriment.planetObject;

      const originText = planet.activation === 0 ?
          this.$t('hd.properties.design') :
          this.isTransitPlanet(planet) ? this.$t('pages.newChart.transit') : this.$t('hd.properties.personality');

      return `<p class="flex  mb-0 align-items-center gap-3">
                <span class='icon-${isExalted ? 'exalted' : 'detriment'} text-sm'/>
                   ${ originText + ' ' +
      this.$t('hd.planets.'+  planet.id + '.name')}</>`;
    },
    getPlanetIcon(planetId){
      return `<span class="text-md icon-${planets.icons[planetId]}"</span>`;
    },
  }
}
</script>