const MUTATION = {
    id: 'MUTATION',
    gates: [19, 41, 60, 61, 54, 38, 58, 10, 11, 26, 5, 9, 34, 14, 43, 1]
};
const DUALITY = {
    id: 'DUALITY',
    gates: [44, 28, 50, 32, 57, 48, 18, 46, 6, 47, 64, 40, 59, 29, 4, 7]
};
const CIVILIZATION = {
    id: 'CIVILIZATION',
    gates: [33, 31, 56, 62, 53, 39, 52, 15, 12, 45, 35, 16, 20, 8, 23, 2 ]
};
const INITIATION = {
    id: 'INITIATION',
    gates: [24, 27, 3, 42, 51, 21, 17, 25, 36, 22, 63, 37, 55, 30, 49, 13]
};


const quarters = [MUTATION, DUALITY, CIVILIZATION, INITIATION];

const findQuarterByGate = (gate) => {
    for(let quarter of quarters){
        if(quarter.gates.includes(gate)) return quarter.id
    }

}



module.exports = {
    MUTATION, DUALITY, CIVILIZATION, INITIATION, findQuarterByGate
}
