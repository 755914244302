<template>

  <div>
    <div v-for="id in centerIds" :key="`toggle-${id}`"
         class="mb-3 accordion-item casual-border-radius ease-in-out duration-300 "
         :class="active === id ? 'collapsed' : 'not-collapsed'">
      <b-button
          variant="link"
          size="xs"
          class="w-100 accordion-toggler text-left font-weight-bold"
          @click="toggle(id)"
      >
        <p class="mb-0 text-lg gap-5">
          <span :class="`mt-1 mr-1 icon-center-${centers.getById(id).icon}`"/> {{$t('hd.centers.'+ id + '.name')}}
          <span class="float-right">
                    <span class="icon-arrow-down"></span>
                </span>

        </p>
      </b-button>
      <div v-show="active === id"
           class="p-2"
           :key="id" :id="`item-${id}`">

        <div class="d-flex flex-wrap gap-10">

          <IchingGate  v-for="gate in centers.getById(id).gates" :key="gate" @gateClicked="$emit('gateClicked', getGate(gate))"
          :gate="gate" :gate-object="getGate(gate)" />
        </div>

      </div>
    </div>

  </div>
</template>

<script>


import centers from "../../../../helpers/rave/constants/centers";
import IchingGate from "./IchingGate.vue";
import {mapState} from "vuex";

export default{
  components: {IchingGate},
  props:{
    gates: Array,
  },
  computed: {
    ...mapState({
      active: state => state.ui.iching.centersActiveTab
    }),

    centers() {
      return centers
    }
  },

  data(){
    return {
      centerIds:[0,1,2,3,4,5,6,7,8].reverse()
    }
  },

  methods:{
    getGate(id){
      return this.gates.find(x => x.gate_id === id);
    },
    toggle(id){
      this.$store.commit('ui/SET_ICHING_ACTIVE_TABS', {
        centersActiveTab: this.active === id ? null : id
      })
    }
  }
}
</script>