<template>
  <div class="px-4">
    <inner-tabs-renderer :id="4"
                         type="foundational"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)">
      <template #unlock-prompt> <slot name="unlock-prompt"/> </template>
    </inner-tabs-renderer>
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import {mapState} from "vuex";
import Gates from "../../properties/gates";
import InnerTabsRenderer from "../../../renderer/InnerTabsRenderer.vue";

export default{
  mixins: [AnalysisQueryMixin],
  components: {InnerTabsRenderer},
  data(){
    return{
      audioKey: 0,
      tab: 0,
      gatesIntro: null,
      hasSelected:{
        design: false,
        personality: false
      }
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
    tabs(){
      return [
        {
          id: 0,
          title: this.gatesIntro ? this.gatesIntro.name : this.$t('hd.gates.name.many'),
          shouldRender: !!this.gatesIntro,
          component: DynamicZoneRenderer,
          props: {
            components: this.gatesIntro ? this.gatesIntro.components : [],
            addPaddings: false,
          }
        },
        {
          id: 1,
          title: this.$t('hd.properties.personality'),
          shouldRender: true,
          component: Gates,
          hideProgress: !!this.hasSelected.personality,
          props: {
            showAsCard: false,
            sort: 'planets',
            filterByPlanetaryType: 'personality',
            showPlanets: true,
            disableUrlNavigation: true,
            class: 'pb-4'
          },
          on: {
            'hasSelectedItem': (val) => this.hasSelected.personality = val
          }
        },
        {
          id: 2,
          title: this.$t('hd.properties.design'),
          shouldRender: true,
          component: Gates,
          hideProgress: !!this.hasSelected.design,
          props: {
            showAsCard: false,
            sort: 'planets',
            filterByPlanetaryType: 'design',
            showPlanets: true,
            disableUrlNavigation: true,
            class: 'pb-4'
          },
          on: {
            'hasSelectedItem': (val) => this.hasSelected.design = val
          }
        }
      ]
    }
  },
  created(){
    this.getGatesIntro();
  },

  methods:{
    getGatesIntro(){
      let url = '/analysis/introductions/introduction.gates';
      this.getContent('gatesIntro', url);
    },

  }
}
</script>