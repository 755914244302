<template>

  <div id="foundational-analysis-wrapper">
    <b-tabs v-model="activeTab" card class="vertical-side-tabs mobile-hidden" end lazy nav-wrapper-class="tabs-panel"
            pills vertical>

      <b-tab v-for="(tab,index) in tabs" :key="`foundation-tab-${tab.id}`">

        <template #title>
          <TabTitle :centered="true" :icon="tab.icon" :is-locked="!isUnlocked && index !==0" :title="tab.name"/>
        </template>

        <b-overlay :show="loading" rounded="sm">
          <b-card class="px-0 pt-4 analysis-card overflow-hidden">
            <component :is="tab.component" :id="tab.id" @loading="loadingChanged"></component>
          </b-card>


        </b-overlay>

      </b-tab>
    </b-tabs>
  </div>

</template>

<script>
import TabTitle from "../../../elements/TabTitle.vue";
import cycleSummary from "./cycle-summary.vue";

export default {

  components: {TabTitle},

  computed: {
    isUnlocked() {
      return this.$store.state.charts.chartStatus.foundational
    }
  },
  data() {
    return {
      activeTab: 0,
      loading: false,
      tabs: [{
        id: 0,
        icon: 'icon-introduction',
        component: cycleSummary,
        name: this.$t('charts.analysis.foundation.tabs.0')
      }],
    }
  },
  methods: {
    loadingChanged(val) {
      this.loading = val;
    }
  }
}
</script>

<style lang="scss">

#foundational-analysis-wrapper {

  .tabs {
    .nav-link {
      font-size: 0.9rem;
      font-weight: 300;

      &.active {
        font-weight: 600;
      }
    }
  }

  > .card-body {
    padding: 0;
  }
}

.analysis-card {
  > .card-body {
    padding: 0 !important;
  }
}
</style>