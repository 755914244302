<template>
  <div class="px-4">
    <b-tabs v-if="!isReflector" v-model="tab" class="tabs-primary-underlined light-tab-titles" fill lazy @input="$emit('innerTabChanged', tab)">

      <b-tab :title="$t('hd.properties.definition')">
        <Definition v-if="chart" :chart="chart"/>
        <slot name="unlock-prompt" />
        <ProgressChecker type="foundational" id="3.1" @prevSection="$emit('prevSection')" @nextSection="tab++"/>
      </b-tab>

      <b-tab :title="$t('hd.constants.centerDefinitionStates.2') + ' ' + $t('hd.properties.centers')">
        <dynamic-zone-renderer v-if="definedCentersIntro"  :title="definedCentersIntro.name" :components="definedCentersIntro.components" is-toggleable :add-paddings="false"></dynamic-zone-renderer>

        <centers :show-tab="false" :show-as-card="false" :type="'defined'" disable-url-navigation @hasSelectedItem="(val) => selectedItem.center = val" />
        <slot name="unlock-prompt" />
        <ProgressChecker v-if="!selectedItem.center" type="foundational" id="3.2" @prevSection="tab--" @nextSection="tab++"/>
      </b-tab>
      <b-tab :title="$t('hd.objects.channels')">

        <dynamic-zone-renderer v-if="channelsIntro" :title="channelsIntro.name" :components="channelsIntro.components" is-toggleable :add-paddings="false"/>

        <channels :show-as-card="false" disable-url-navigation  @hasSelectedItem="(val) => selectedItem.channel = val" :show-grouped="false"/>
        <slot name="unlock-prompt" />
        <ProgressChecker v-if="!selectedItem.channel" type="foundational" id="3.3" @prevSection="tab--" @nextSection="$emit('nextSection')"/>
      </b-tab>
    </b-tabs>

    <div v-else-if="reflectorsIntro">
      <dynamic-zone-renderer  class="pt-4" :components="reflectorsIntro.components" :add-paddings="true"/>
      <slot name="unlock-prompt" />
      <ProgressChecker type="foundational" id="3.1"/>
    </div>
  </div>

</template>

<script>

import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import {mapState} from "vuex";
import Centers from "../../properties/centers";
import Channels from "../../properties/channels";
import ProgressChecker from "../progress/ProgressChecker.vue";
import types from "@/helpers/rave/constants/types";
import Definition from "./sections/Definition.vue";

export default{
  mixins: [AnalysisQueryMixin],
  components: {Definition, ProgressChecker, Channels, Centers, DynamicZoneRenderer},
  computed: {
    ...mapState({
      chart: state => state.charts.chart
    }),
    isReflector() {
      return this.chart.chart.type === this.types.REFLECTOR
    },
  },
  data(){
    return{
      types,
      audioKey: 0,
      tab: 0,
      definedCentersIntro: null,
      definedCenters: [],

      channelsIntro: null,
      channels: [],
      reflectorsIntro: null,
      selectedItem:{
        center: null,
        channel: null
      }
    }
  },
  created(){
    if(this.isReflector) {
      this.getReflectorIntroduction();
    }else {
      this.getDefinedCentersIntroduction();
      this.getChannelsIntroduction();
    }

  },

  methods:{
    getDefinedCentersIntroduction(){
      let url = '/analysis/introductions/introduction.defined-centers';
      this.getContent('definedCentersIntro', url);
    },

    getChannelsIntroduction(){
      let url = '/analysis/introductions/introduction.channels';
      this.getContent('channelsIntro', url);
    },

    getReflectorIntroduction() {
      this.getContent('reflectorsIntro', `/analysis/introductions/reflector.life-force`);
    }
  }
}
</script>