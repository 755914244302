<template>

  <div>
    <dynamic-zone-renderer v-if="nodalPolaritiesIntro"
                           :toggled-when-rendered="true" :is-toggleable="true" :title="nodalPolaritiesIntro.name"
                           :components="nodalPolaritiesIntro.components" :add-paddings="false"/>

    <dynamic-zone-renderer  v-for="polarity in uniqueNodalPolarities" :key="polarity.id" :is-toggleable="true"
                            :title="polarity.name" :components="polarity.components" :add-paddings="false"/>
  </div>

</template>

<script>

import DynamicZoneRenderer from "../../../../renderer/DynamicZoneRenderer.vue";
import planetConstants from "../../../../../helpers/rave/constants/planets";
import activationModes from "../../../../../helpers/rave/constants/activationModes";
import AnalysisQueryMixin from "../../../../../mixins/AnalysisQueryMixin";

export default {
  props:{
    chart: Object
  },
  components: {DynamicZoneRenderer},
  mixins: [AnalysisQueryMixin],

  computed:{
    uniqueNodalPolarities(){
      const unique = new Map();
      this.nodalPolarities.forEach(polarity => unique.set(polarity.name, polarity));
      return unique.values();
    }
  },

  data(){
    return{
      nodalPolaritiesIntro: null,
      nodalPolarities: [],
    }
  },

  created(){
    this.getNodalPolaritiesIntroduction();
    this.getNodalPolarities();
  },

  methods:{
    getNodalPolaritiesIntroduction(){
      let url = '/analysis/introductions/introduction.nodal-polarities';
      this.getContent('nodalPolaritiesIntro', url);
    },
    getNodalPolarities(){

      this.nodalPolarities = [];
      let designNorthNodeGate = this.chart.chart.planets.find(x => x.id === planetConstants.NORTH_NODE && x.activation === activationModes.DESIGN).gate;
      let designSouthNodeGate = this.chart.chart.planets.find(x => x.id === planetConstants.SOUTH_NODE && x.activation === activationModes.DESIGN).gate;

      let personalityNorthNodeGate = this.chart.chart.planets.find(x => x.id === planetConstants.NORTH_NODE && x.activation === activationModes.PERSONALITY).gate;
      let personalitySouthNodeGate = this.chart.chart.planets.find(x => x.id === planetConstants.SOUTH_NODE && x.activation === activationModes.PERSONALITY).gate;

      const design = [designNorthNodeGate, designSouthNodeGate].sort((a,b) => b - a)
      const personality = [personalityNorthNodeGate, personalitySouthNodeGate].sort((a,b) => b-a);

      let url_design = '/nodal-polarities/' +  design.join('.');
      let url_personality =  '/nodal-polarities/' +  personality.join('.');

      this.getArrayContent('nodalPolarities', url_design);
      this.getArrayContent('nodalPolarities', url_personality);
    },
  }

}
</script>