<template>
  <div class="px-4">
    <dynamic-zone-renderer v-if="intro" :add-paddings="false" :components="intro.components"/>
    <dynamic-zone-renderer v-if="content" :title="content.name"  :components="content.components" :add-paddings="false"></dynamic-zone-renderer>

    <dynamic-zone-renderer v-for="item in openCenterContent" :key="item.id"
                           :title="item.name"
                           :components="item.components"
                           :add-paddings="false" is-toggleable />

    <slot name="unlock-prompt"/>
    <ProgressChecker class="pt-2" type="companionship" id="1" :has-prev="hasPrev" :has-next="hasNext"
                     @prevSection="$emit('prevSection')"
                     @nextSection="$emit('nextSection')"/>
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../mixins/AnalysisQueryMixin";
import {mapState} from "vuex";
import centerDefinitionStates from "../../../helpers/rave/constants/centerDefinitionStates";
import ProgressChecker from "@/components/charts/analysis/progress/ProgressChecker.vue";

export default{
  mixins: [AnalysisQueryMixin],
  components: {ProgressChecker, DynamicZoneRenderer},
  data(){
    return{
      audioKey: 0,
      tab: 0,
      content: null,
      intro: null,
      openCenterContent: [],
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    })
  },
  created(){
    this.getIntro();
    this.getConnectionTheme();
    this.getOpenCenterContent();
  },

  methods:{
    getIntro(){
      this.getContent('intro', '/analysis/introductions/introduction.connection-theme');
    },
    getOpenCenterContent() {
      let openCenterIds = [];
      for(const [index, value] of this.chart.chart.centers.entries()){
        if(value === centerDefinitionStates.UNDEFINED || value === centerDefinitionStates.EMPTY) openCenterIds.push(index);
      }
      openCenterIds.map(x => this.getArrayContent('openCenterContent', '/connection-open-centers/'+x))
    },
    getConnectionTheme(){
      let definedCentersCount = this.chart.chart.centers.filter(x => x === centerDefinitionStates.DEFINED).length;
      let undefinedCentersCount = this.chart.chart.centers.length - definedCentersCount;
      let url = '/connection-themes/' + (definedCentersCount + '.' + undefinedCentersCount);
      this.getContent('content', url);
    },
  }
}
</script>