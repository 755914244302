/* eslint-disable */
import * as R from 'ramda'
import objects from '../../../helpers/rave/constants/objectTypes'
import ViewMode from '../../../helpers/utils/ViewMode'
import { isCompositeChart } from '@/helpers/rave/queries'
import { DataSource, funcs } from './funcs'
import highlighting from './script.highlighting'
import utilMixin from "../../../mixins/util";
import {mapState} from "vuex";
import activationModes from "../../../helpers/rave/constants/activationModes";
import planetConstants from "../../../helpers/rave/constants/planets";
import ChartUtil from "../../../mixins/ChartUtil";
import HighlightingMixin from "../../../mixins/HighlightingMixin";

const isNotNullOrEmpty = (string) => {
  return !string || string !== '';
}

export default {
  mixins: [utilMixin, ChartUtil, HighlightingMixin],
  name: 'mbg-bodygraph',
  props: {
    chart: {
      type: Object,
      default: () => {return {}}
    },

    domRefId: String,
    interactive: {
      type: Boolean,
      default: false
    },
    title: {
      required: false,
      default: null
    }
  },
  watch:{
    chart(){
      this.emptyChartCentersAlignment();
    }
  },
  computed: {
    ...mapState({
      tabs: state => state.ui.tabsMapping,
      viewMode: state => state.ui.viewMode,
      viewModes: state => state.ui.viewModes
    }),
    offsets() {
      return [
        { value: 0, color: '#c25354' },
        { value: 10, color: '#c25354' },
        { value: 10, color: this.darkMode ? '#fff' : '#434343' },
        { value: 20, color: this.darkMode ? '#fff' : '#434343' },
        { value: 20, color: '#c25354' },
        { value: 30, color: '#c25354' },
        { value: 30, color: this.darkMode ? '#fff' : '#434343' },
        { value: 40, color: this.darkMode ? '#fff' : '#434343' },
        { value: 40, color: '#c25354' },
        { value: 50, color: '#c25354' },
        { value: 50, color: this.darkMode ? '#fff' : '#434343' },
        { value: 60, color: this.darkMode ? '#fff' : '#434343' },
        { value: 60, color: '#c25354' },
        { value: 70, color: '#c25354' },
        { value: 70, color: this.darkMode ? '#fff' : '#434343' },
        { value: 80, color: this.darkMode ? '#fff' : '#434343' },
        { value: 80, color: '#c25354' },
        { value: 90, color: '#c25354' },
        { value: 90, color: this.darkMode ? '#fff' : '#434343' },
        { value: 100, color: this.darkMode ? '#fff' : '#434343' }
      ]
    },
    isLeftChartOnly(){
      return this.isTransitChart(this.chart) && this.viewMode === this.viewModes.design;
    },
    planets () {
      if(!this.chart.chart || !this.chart.chart.planets) return [];

      if(this.viewMode === this.viewModes.personality){
        if(this.isConnectionChart(this.chart) || this.isTransitChart(this.chart) || this.isCycleChart(this.chart)) return this.chart.chart.planets.filter(x => x.chartId === 1);
        return this.chart.chart.planets.filter(x => x.activation === activationModes.PERSONALITY || x.activation === activationModes.MIXED)
      }
      if(this.viewMode === this.viewModes.design){
        if(this.isConnectionChart(this.chart) || this.isTransitChart(this.chart) || this.isCycleChart(this.chart)) return this.chart.chart.planets.filter(x => x.chartId === 0);
        return this.chart.chart.planets.filter(x => x.activation === activationModes.DESIGN || x.activation === activationModes.MIXED)
      }
      if(this.viewMode === this.viewModes.cross){
        return this.chart.chart.planets.filter(x => x.id === planetConstants.EARTH || x.id === planetConstants.SUN)
      }
      return this.chart.chart.planets
    },
    view () {
      return R.defaultTo(ViewMode.Normal, ViewMode.Normal)
    },
    dataSource () {
      return R.ifElse(
        R.isNil,
        R.always(DataSource.empty),
        DataSource.create(this.view)
      )(this.planets)
    },
    titleText () {
      return R.cond([
        [R.is(String), R.always(this.title)],
        [R.is(Object), R.prop('title')],
        [R.T, R.always(null)]
      ])(this.title)
    },
    titleCommentText () {
      return R.cond([
        [R.is(Object), R.prop('comment')],
        [R.T, R.always(null)]
      ])(this.title)
    },
    hasTitle () {
      return isNotNullOrEmpty(this.titleText);
      return !this.titleText || this.titleText === '';
    },
    hasTitleComment () {
      return isNotNullOrEmpty(this.titleCommentText)
    },
    senderId () {
      return this.$options.name
    },
    // we have composite if we have planets and the view mode is not design or personality (0,1)
    isComposite () {
      return isCompositeChart(this.planets) && (this.viewMode !== this.viewModes.design && this.viewMode !== this.viewModes.personality);
    },
    rollOversEnabled(){
      return true;
    }
    // ...mapState({
    //   rollOversEnabled: state => state.ui.chartRollOversEnabled
    // })
  },
  methods: {
    emptyChartCentersAlignment(){
      if(Object.keys(this.chart).length === 0) {
        this.$nextTick(()=>{
          document.querySelectorAll('#centers > g').forEach((elem) => {
            elem.classList.remove('muted');
            elem.classList.add('open');
          })
        })
      }
    },

    centerCssClasses (id) {
      return funcs.getCssClassesForCenter(this.dataSource, id)
    },
    channelCssClasses (id) {
      return funcs.getCssClassesForChannel(this.dataSource, id)
    },
    gateCssClasses (id) {
      return funcs.getCssClassesForGate(this.dataSource, id)
    },
    gateNumCssClasses (id) {
      return funcs.getCssClassesForGateNumbers(this.dataSource, id)
    },
    isGateDefined (id) {
      return funcs.isGateDefined(this.dataSource, id)
    },
    isChannelDefined (id) {
      return funcs.isChannelDefined(this.dataSource, id)
    },
    gateEvents (gateId) {
      if (this.interactive === false) return null

      const source = funcs.getGateEventSource(this.dataSource, gateId)
      return highlighting.getEvents(this, source)
    },
    centerEvents (id) {
      if (this.interactive === false) return null

      const source = { type: objects.CENTER, id }
      return highlighting.getEvents(this, source)
    },
    onMouseEnter (e, highlights) {
      highlighting.fromMouseEvents.apply(this, e, highlights)
    },
    onMouseLeave (e, src) {
      highlighting.fromMouseEvents.clear(this, e, src)
    },
    onElementClicked (src) {
      let tab = this.tabs[src.type];
      let id = src.id;

      // For cases where there's more than 1 element of that type
      // We don't want to open a specific element in the properties. We instead wanna just show
      // the properties panel on the appropriate tab.
      const shouldSelectOption = typeof id !== 'object' || id.length === 1;

      this.fixUrlQuery(`?tab=${tab}` + (shouldSelectOption ? `&selected=${id}` : ''));
      // add the URL params so that the properties panel show the correct content

      this.$nextTick(()=>{
        this.$store.dispatch('ui/increasePropertiesKey'); // increase key to update window
        this.$store.commit('ui/SET_SHOW_PROPERTIES', true); // show panel in case it's not showing
      });
    }
  },
  mounted () {
    this.emptyChartCentersAlignment();
    if (this.interactive === true) highlighting.init(this)

  }
}
