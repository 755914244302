/* eslint-disable */
import * as R from 'ramda'
import { isPropTrue, propIn } from '../utils/misc'
import types from './constants/types'
import authorities from './constants/authorities'
import centers from './constants/centers'
import centerDefinitions from './constants/centerDefinitionStates'
import channels from './constants/channels'
import definitions from './constants/definitions'
import gates from './constants/gates'
import planet from './constants/planets'
import variable from './constants/variables'
import baseOrientation from './constants/baseOrientations'
import determination from './constants/determinations'
import cognition from './constants/cognitions'
import environment from './constants/environments'
import motivation from './constants/motivations'
import sense from './constants/senses'
import view from './constants/views'
import { crosses, orientations } from './constants/crosses'
import activationModes from './constants/activationModes'
import channelCompositionStates from './constants/channelCompositionStates'
import transitChartIds from './constants/transitChartIds'
import Graph from './graph/Graph'

const throatConnectedToMotor = isPropTrue('throatConnectedToMotor')
const sacralDefined = isPropTrue('sacralDefined')
const hasNoActivations = R.propSatisfies(R.equals(0), 'regionCount')

const generators = R.ifElse(
    throatConnectedToMotor,
    R.always(types.MAN_GENERATOR),
    R.always(types.GENERATOR)
)

const getType = R.cond([
    [sacralDefined, generators],
    [throatConnectedToMotor, R.always(types.MANIFESTOR)],
    [hasNoActivations, R.always(types.REFLECTOR)],
    [R.T, R.always(types.PROJECTOR)]
])

const getProfile = R.pipe(
    R.filter(x => x.id === 0), // SUN
    R.sortBy(x => -x.activation), // Personality first
    R.pluck('line'),
    R.join(''),
    parseInt
)

const onlySunEarth = R.filter(propIn('id', [planet.SUN, planet.EARTH]))

const getChannelGates = R.pipe(
    R.nth(R.__, channels),
    R.prop('gates')
)

const getCenterGates = R.pipe(
    centers.getById,
    R.prop('gates')
)

const _when = (prop, result) => [isPropTrue(prop), R.always(result)]

const getAuthority = R.cond([
    _when('solarPlexDefined', authorities.SOLAR_PLEX),
    _when('sacralDefined', authorities.SACRAL),
    _when('spleenDefined', authorities.SPLENIC),
    _when('throatConnectedToMotor', authorities.EGO),
    _when('throatConnectedToG', authorities.G),
    _when('heartConnectedToG', authorities.EGO_PROJECTED),
    [R.T, R.always(authorities.NONE)]
])

const createActivationGraph = (definedChannels) => {
    const getCorrespondingCenters = R.map(
        R.pipe(
            R.dec, // gates array uses zero based index so decrement the gate id
            (id) => gates[id].center
        )
    )

    const reducer = (graph, channel) => {
        const addCenterAssociations = centers => graph.addAssociation(centers[0], centers[1])

        R.pipe(
            getChannelGates,
            getCorrespondingCenters,
            addCenterAssociations
        )(channel)

        return graph
    }

    return R.reduce(reducer, Graph(), definedChannels)
}

const getChannelCenterActivations = (definedChannels) => {
    const graph = createActivationGraph(definedChannels)

    const isDefined = graph.isNodeDefined

    const isConnectedToMotor = R.pipe(
        R.map(centers.getById),
        R.any(isPropTrue('isMotor'))
    )

    const throatConnections = graph.getAssociations(centers.THROAT.id)

    const heartConnections = graph.getAssociations(centers.HEART.id)

    return {
        throatDefined: isDefined(centers.THROAT.id),
        spleenDefined: isDefined(centers.SPLEEN.id),
        sacralDefined: isDefined(centers.SACRAL.id),
        solarPlexDefined: isDefined(centers.SOLAR_PLEXUS.id),
        gDefined: isDefined(centers.G.id),
        throatConnectedToMotor: isConnectedToMotor(throatConnections),
        throatConnectedToG: R.includes(centers.G.id, throatConnections),
        heartConnectedToG: R.includes(centers.G.id, heartConnections),
        regionCount: graph.getComponents().length
    }
}

const calculateDerivedProperties = (channels) => {
    const reflector = R.always({
        type: types.REFLECTOR,
        definition: definitions.NONE,
        authority: authorities.NONE
    })

    const getProperties = (activations) => ({
        type: getType(activations),
        authority: getAuthority(activations),
        definition: activations.regionCount
    })

    const calculate = R.pipe(getChannelCenterActivations, getProperties)

    return R.ifElse(
        R.isEmpty,
        reflector,
        calculate
    )(channels)
}

const getDefinedGates = R.pipe(
    R.pluck('gate'),
    R.uniq
)

const isCompositeChart = R.pipe(
    R.chain(R.prop('chartId')),
    R.uniq,
    R.length,
    R.gt(R.__, 1)
)

const getGateActivationModes = (planets) => {
    const isPropSameForAll = prop => (previous, current) =>
        R.or(
            R.isNil(previous),
            R.equals(previous, R.prop(prop, current)
            )
        )

    const extractValue = prop => (previous, current) => R.prop(prop, current)

    const isComposite = isCompositeChart(planets)

    const prop = isComposite ? 'chartId' : 'activation'

    const reducer = (acc, current) => R.ifElse(
        isPropSameForAll(prop),
        extractValue(prop),
        R.always(activationModes.MIXED)
    )(acc, current)

    const createRecord = (value, key, obj) => ({
        gate: parseInt(key),
        mode: value
    })

    return R.pipe(
        R.reduceBy(reducer, null, R.prop('gate')),
        R.mapObjIndexed(createRecord),
        R.values
    )(planets)
}

const getDefinedCenters = (definedChannels) => {
    return R.pipe(
        R.chain(x => channels[x].gates),
        R.map(x => gates[x - 1].center),
        R.uniq
    )(definedChannels)
}

const getCenterDefinitions = (definedGates, definedChannels) => {
    const definedCenters = getDefinedCenters(definedChannels)

    const whenDefined = R.always(centerDefinitions.DEFINED)

    const whenNotDefined = id => R.pipe(
        centers.getById,
        R.prop('gates'),
        R.intersection(definedGates),
        g => (g.length === 0 ? centerDefinitions.EMPTY : centerDefinitions.UNDEFINED)
    )(id)

    const getCenterState = R.ifElse(
        R.includes(R.__, definedCenters),
        whenDefined,
        whenNotDefined
    )

    return R.map(getCenterState, R.range(0, 9))
}

const isChannelDefined = (definedGates, channel) =>
    R.allPass(
        R.map(R.includes, channel.gates) // create predicate list
    )(definedGates)

const getDefinedChannels = (definedGates) => {
    const defined = R.partial(isChannelDefined, [definedGates])

    return R.pipe(
        R.chain(x => gates[x - 1].channels),
        R.uniq,
        R.map(x => channels[x]),
        R.filter(defined),
        R.pluck('id')
    )(definedGates)
}

const getCross = (planets) => {
    let pred = (activation) => R.both(R.propEq('id', planet.SUN), R.propEq('activation', activation))

    let pSun = getPlanet(pred(activationModes.PERSONALITY), planets)
    let dSun = getPlanet(pred(activationModes.DESIGN), planets)

    if (R.any(R.isNil, [pSun, dSun])) {
        return -1
    }

    let profile = pSun.line * 10 + dSun.line

    let orientation = R.cond([
        [R.either(R.lt(R.__, 41), R.equals(46)), R.always(orientations.RIGHT)],
        [R.gt(R.__, 46), R.always(orientations.LEFT)],
        [R.T, R.always(orientations.JUXTAPOSED)]
    ])(profile)

    return R.pipe(
        R.find(c => c.gates[0] === pSun.gate && c.orientation === orientation),
        R.prop('id')
    )(crosses)
}

const getAIprops = (planets) => {
    let pred = (id, activation) => R.both(R.propEq('id', id), R.propEq('activation', activation))

    let pSun = getPlanet(pred(planet.SUN, activationModes.PERSONALITY), planets)
    let pNode = getPlanet(pred(planet.SOUTH_NODE, activationModes.PERSONALITY), planets)
    let dSun = getPlanet(pred(planet.SUN, activationModes.DESIGN), planets)
    let dNode = getPlanet(pred(planet.SOUTH_NODE, activationModes.DESIGN), planets)

    if (R.any(R.isNil, [pSun, pNode, dSun, dNode])) {
        return {}
    }

    return {
        variable: variable.fromTones(pSun.tone, pNode.tone, dSun.tone, dNode.tone),
        designBaseOrientation: baseOrientation.fromDesignBase(dSun.base, dNode.base, pNode.base),
        determination: determination.fromToneAndColor(dSun.tone, dSun.color),
        cognition: cognition.fromTone(dSun.tone),
        environment: environment.fromColor(dNode.color),
        personalityBaseOrientation: baseOrientation.fromPersonalityBase(pSun.base, pNode.base, dNode.base),
        motivation: motivation.fromColor(pSun.color),
        transference: motivation.transferenceFromColor(pSun.color),
        sense: sense.fromTone(pSun.tone),
        view: view.fromColor(pNode.color)
    }
}

const getPlanetsOfCircuit = (circuit, planets) => {
    const circuitChannels = R.pipe(
        R.filter(R.propEq('circuit', circuit))
    )(channels)

    const circuitGates = R.pipe(
        R.chain(R.prop('gates')),
        R.uniq
    )(circuitChannels)

    const predicate = R.pipe(
        R.prop('gate'),
        R.flip(R.includes)(circuitGates)
    )

    return R.filter(predicate)(planets)
}

const getPlanetsOfActivationType = R.curry((activation, planets) => R.filter(R.propEq('activation', activation))(planets))

const getPlanetsOfChartId = (id, planets) => R.filter(R.propEq('chartId', id))(planets)

const getPlanetsOfCenter = (id, planets) => {
    const centerGates = getCenterGates(id)

    return R.pipe(
        R.filter(p => R.includes(p.gate, centerGates))
    )(planets)
}

const getCrossPlanets = onlySunEarth

const getPlanet = (predicate, planets) => R.find(predicate, planets)

const getGatesForPlanet = (id, planets) => {
    return R.pipe(
        R.filter(R.propEq('id', id)),
        R.map(x => x.gate),
        R.uniq
    )(planets)
}

const getPlanetsById = (id, planets) => {
    return R.filter(R.propEq('id', id), planets)
}

const getPlanetsOfGate = R.curry((planets, gate) => R.filter(R.propEq('gate', gate), planets))

const getPlanetsOfLine = R.curry((planets, line) => R.filter(R.propEq('line', line), planets))

const getPlanetsOfChannel = R.curry((channel, planets) => {
    const gates = getChannelGates(channel)
    const isOneOfGates = R.flip(R.includes)(gates)

    return R.filter(R.propSatisfies(isOneOfGates, 'gate'), planets)
})

const isGateDefined = R.curry((planets, gate) => R.any(R.propEq('gate', gate), planets))

const getChannelCompositeStates = (channelIds, planets) => {
    const PARTIAL = 1
    const FULL = 2
    const activationIs = (id, type) => R.propEq(id, type)
    const noGates = id => R.complement(R.has(id))

    const getChannelGateActivationCountByChartId = R.pipe(
        R.groupBy(R.prop('chartId')),
        R.map(R.pipe(
            R.pluck('gate'),
            R.uniq,
            R.length
        ))
    )

    const getCompositionState = R.cond([
        [R.both(activationIs(0, FULL), activationIs(1, FULL)), R.always({ state: channelCompositionStates.COMPANIONSHIP })],
        [R.both(activationIs(0, PARTIAL), activationIs(1, PARTIAL)), R.always({ state: channelCompositionStates.ELECTROMAGNETIC })],
        [R.both(activationIs(0, FULL), activationIs(1, PARTIAL)), R.always({ state: channelCompositionStates.COMPROMISE, dominantChartId: 0 })],
        [R.both(activationIs(0, PARTIAL), activationIs(1, FULL)), R.always({ state: channelCompositionStates.COMPROMISE, dominantChartId: 1 })],
        [R.both(activationIs(0, FULL), noGates(1)), R.always({ state: channelCompositionStates.DOMINANCE, dominantChartId: 0 })],
        [R.both(noGates(0), activationIs(1, FULL)), R.always({ state: channelCompositionStates.DOMINANCE, dominantChartId: 1 })]
    ])

    const getChannelCompositionState = R.pipe(
        getChannelGateActivationCountByChartId,
        getCompositionState
    )

    const processChannel = channelId => R.pipe(
        id => getPlanetsOfChannel(id, planets),
        getChannelCompositionState,
        R.assoc('id', channelId)
    )(channelId)

    return R.map(processChannel)(channelIds)
}

const mapChannelCompositionStateForTransits = channels => {
    const states = channelCompositionStates
    const ids = transitChartIds
    const stateIs = state => R.propEq('state', state)

    const mapState = R.cond([
        [stateIs(states.ELECTROMAGNETIC), R.assoc('state', states.PERSONAL)],
        [stateIs(states.COMPANIONSHIP), R.assoc('state', states.NATAL)],
        [R.both(stateIs(states.DOMINANCE), R.propEq('dominantChartId', ids.PERSON)), R.pipe(R.dissoc('dominantChartId'), R.assoc('state', states.NATAL))],
        [R.both(stateIs(states.COMPROMISE), R.propEq('dominantChartId', ids.PERSON)), R.pipe(R.dissoc('dominantChartId'), R.assoc('state', states.NATAL))],
        [R.both(stateIs(states.DOMINANCE), R.propEq('dominantChartId', ids.TRANSIT)), R.assoc('state', states.EDUCATIONAL)],
        [R.both(stateIs(states.COMPROMISE), R.propEq('dominantChartId', ids.TRANSIT)), R.identity]
    ])

    return R.map(mapState, channels)
}

export {
    getProfile,
    getChannelCenterActivations,
    calculateDerivedProperties,
    getDefinedGates,
    getGateActivationModes,
    getDefinedCenters,
    getCenterDefinitions,
    getDefinedChannels,
    getChannelGates,
    getCenterGates,
    getCross,
    getPlanet,
    getGatesForPlanet,
    getPlanetsOfGate,
    getPlanetsOfLine,
    getPlanetsOfChannel,
    getPlanetsOfCircuit,
    getPlanetsOfCenter,
    getPlanetsOfActivationType,
    getPlanetsOfChartId,
    getCrossPlanets,
    getPlanetsById,
    isCompositeChart,
    isGateDefined,
    getAIprops,
    onlySunEarth,
    getChannelCompositeStates,
    mapChannelCompositionStateForTransits
}
