<template>
  <svg width="75" height="58" viewBox="0 0 75 58" fill="none" xmlns="http://www.w3.org/2000/svg" class="planet-box advanced advanced-arrows">
    <rect width="74" height="57" x="0.5" y="0.5" rx="5" fill="white" stroke="#B046A5" class="advanced-arrows-bg"/>
    <svg :x="orientation === 1 ? 38 : 6" y="14.5" width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2408_34055)">
        <path d="M30.5111 28.36H1.49193C1.22755 28.36 0.96791 28.287 0.7396 28.1485C0.511291 28.0101 0.322509 27.811 0.192582 27.5719C0.0626553 27.3327 -0.00375031 27.062 0.000163524 26.7874C0.00407736 26.5128 0.07817 26.2442 0.214857 26.0091L14.7222 1.10724C14.8551 0.879167 15.0424 0.690565 15.266 0.559663C15.4895 0.42876 15.7419 0.359985 15.9985 0.359985C16.2552 0.359985 16.5075 0.42876 16.7311 0.559663C16.9546 0.690565 17.1419 0.879167 17.2748 1.10724L31.7851 26.0091C31.9217 26.244 31.9958 26.5123 31.9998 26.7866C32.0039 27.0609 31.9377 27.3314 31.8081 27.5705C31.6786 27.8096 31.4902 28.0087 31.2623 28.1474C31.0344 28.2861 30.7752 28.3594 30.5111 28.36Z"
              fill="#2764C4"/>
        
        <path v-if="!isLeft" d="M30.1451 25.6639L16.9378 2.55122C16.4243 1.65261 15.0513 2.03041 15.0697 3.06521L15.4825 26.1779C15.4922 26.7231 15.937 27.16 16.4823 27.16H29.2768C30.0446 27.16 30.526 26.3306 30.1451 25.6639Z"
              :class="orientation === 1 ? 'personality' : ''"
              class="arrow-box-fill"
              />
        <path v-else d="M1.85494 25.6639L15.0622 2.55122C15.5757 1.65261 16.9487 2.03041 16.9303 3.06521L16.5175 26.1779C16.5078 26.7231 16.063 27.16 15.5177 27.16H2.72318C1.95536 27.16 1.47399 26.3306 1.85494 25.6639Z"
              :class="orientation === 1 ? 'personality' : ''"
              class="arrow-box-fill"/>

        <text x="15.7" y="20.5" fill="#121212" stroke="white" text-anchor="middle"
              font-weight="800" stroke-width="0.8" font-size="15">
          {{ tone }}
        </text>
      </g>
    </svg>
    <svg :x="orientation === 1 ? 6 : 38" y="14.5" width="32" height="29" viewBox="0 0 32 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2409_34062)">
        <path d="M8.06053 28.36L23.9311 28.36C24.3395 28.3601 24.7398 28.246 25.0872 28.0306C25.4347 27.8152 25.7156 27.507 25.8984 27.1403L31.7683 15.3477C31.9207 15.0414 32 14.7038 32 14.3614C32 14.019 31.9207 13.6813 31.7683 13.3751L25.9096 1.58102C25.7269 1.21414 25.4461 0.905583 25.0986 0.689921C24.7512 0.47426 24.3507 0.360014 23.9423 0.359985L8.07027 0.359985C7.6621 0.359823 7.26195 0.473849 6.91471 0.689276C6.56747 0.904704 6.28686 1.21301 6.10436 1.57962L0.231665 13.3737C0.0793141 13.6799 2.08028e-06 14.0176 2.06318e-06 14.36C2.04607e-06 14.7024 0.079314 15.04 0.231665 15.3463L6.09322 27.1389C6.27564 27.506 6.55638 27.8148 6.90391 28.0305C7.25143 28.2462 7.65197 28.3603 8.06053 28.36Z"
              fill="#2764C4"/>
        <path v-if="isUp" d="M24.9577 26.437L30.3019 15.437C30.6245 14.7728 30.1408 14 29.4024 14L2.57587 14C1.84283 14 1.35891 14.7626 1.67107 15.4258L6.84819 26.4258C7.01314 26.7763 7.36565 27 7.75299 27L24.0583 27C24.4412 27 24.7904 26.7814 24.9577 26.437Z"
              class="arrow-box-fill"
              :class="orientation === 1 ? 'personality' : ''"
        />
        <path v-else d="M7.04226 1.923L1.69813 12.923C1.37546 13.5872 1.85919 14.36 2.5976 14.36H29.4241C30.1572 14.36 30.6411 13.5974 30.3289 12.9341L25.1518 1.93414C24.9869 1.58368 24.6344 1.35999 24.247 1.35999H7.94173C7.55885 1.35999 7.20958 1.57861 7.04226 1.923Z"
              class="arrow-box-fill"
              :class="orientation === 1 ? 'personality' : ''"
        />

        <text x="15.7" y="20.5" fill="#121212" stroke="white" text-anchor="middle"
              font-weight="800" stroke-width="0.8" font-size="15">
          {{ color }}
        </text>
      </g>
      <defs>
        <clipPath id="clip0_2409_34062">
          <rect width="32" height="28" fill="white" transform="translate(0 0.359985)"/>
        </clipPath>
      </defs>
    </svg>
  </svg>
</template>

<script>

export default {
  props: {
    isLeft: Boolean,
    isUp: Boolean,
    isDown: Boolean,
    tone: Number,
    color: Number,
    orientation: Number,
  }

}
</script>

<style lang="scss">

.advanced-arrows-bg{
  stroke: white;
  &:hover{
    stroke: $advanced-tables-purple
  }
}
.advanced-arrows:hover{
  .advanced-arrows-bg{
    stroke: $advanced-tables-purple;
  }
}
.arrow-box-fill {
  fill: #BE1211;
  &.personality {
    fill: $personality-bg;
  }
}
.theme--dark{
  .advanced-arrows-bg{
    fill: $personality-bg;
    stroke: $personality-bg;
    &:hover{
      stroke: $advanced-tables-purple;
    }
  }
  .advanced-arrows:hover{
    .advanced-arrows-bg{
      stroke: $advanced-tables-purple;
    }
  }
  .arrow-box-fill {
    &.personality {
      fill:white;
    }
  }
}
</style>