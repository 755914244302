<template>
    <side-extended-panel id="import-charts"
                         :close-btn-text="$t('owl.close')"
                         :visible="show"
                         size="x-small"
                         extra-container-classes="p-0"
                         @close="() => $store.commit('ui/CLOSE_SIDE_SHEET')">
      <template #header>
        <new-header :title="$t('library.tags.manage')" icon="icon-v2-tag" />
      </template>

      <div class="px-3 pb-4">

        <div v-if="!selectedTag">
          <p>
            {{ $t('library.tags.select') }}
          </p>
          <b-form-input v-model="search" placeholder="Search Tags" class="classic-form mt-2 mb-4" />

          <div v-if="!selectedTag" class="d-flex flex-wrap gap-10">
              <b-button v-for="tag in tagList" :key="tag.id" variant="outline-dark" size="sm" @click="selectTag(tag)"> {{tag.text}}</b-button>
          </div>
        </div>

        <div v-else>
          <div class="">
            <b-button variant="link" @click="selectedTag = null" class="d-flex align-items-center gap-5"> <span class="icon-arrow-left"/> Back</b-button>

          </div>

          <div class="">
            <b-form-input v-model="selectedTag.text" placeholder="Tag Name" class="my-3 classic-form" />
            <div class="d-flex justify-content-between">
              <b-button variant="primary" @click="updateTag" :disabled="isUpdating || !hasChanged" class="mr-3"> {{ $t('general.saveChanges') }} </b-button>
              <b-button variant="outline-primary" @click="deleteTag" :disabled="isUpdating"> Delete </b-button>

            </div>
           </div>

        </div>
      </div>
    </side-extended-panel>
</template>
<script>
import SideExtendedPanel from "@/components/structure/SideExtendedPanel.vue";
import NewHeader from "@/components/structure/chart-sidebars/partials/NewHeader.vue";
import {SidebarVariants} from "@/utils/localStorage";
import {mapState} from "vuex";

export default {
  components: {NewHeader, SideExtendedPanel},
  mixins:[],
  props: {
    chartIds: Array
  },
  data(){
    return {
      selectedTag: null,
      search: null,
      inititalText: null,
      isUpdating: false,
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.selectedTag = null;
        this.search = null;
      }
    }
  },
  computed: {
    ...mapState({
      tags: state => state.account.tags
    }),
    show() {
      return this.$store.state.ui.sideSheet.type === SidebarVariants.TagManager
    },
    hasChanged() {
      return this.selectedTag && this.selectedTag.text !== this.inititalText
    },
    tagList() {
      if (!this.search) return this.tags;
      return this.tags.filter(tag => tag.text.toLowerCase().includes(this.search.toLowerCase()))
    }
  },
  methods: {
    selectTag(tag) {
      this.selectedTag = tag;
      this.inititalText = tag.text;
    },
    updateTag() {
      if (!this.selectedTag) return;
      this.axios.put(`${this.globalConfig.chartsUrl}/api/tags/${this.selectedTag.id}`, {
        text: this.selectedTag.text
      }).then((res) => {
        this.isUpdating = false;
        this.inititalText = res.data.text;
        this.$store.commit('account/ADD_TAGS', [res.data]);

        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')})
      }).catch((e) => {
        console.log(e);
        this.isUpdating = false;
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      });
    },

    async deleteTag() {
      try {
        const result = await this.$confirmDialog({
          okTitle: 'Yes, delete tag'
        });

        if (!result) return;

        this.axios.post(`${this.globalConfig.chartsUrl}/api/tags/bulk-delete`, {
          ids: [this.selectedTag.id]
        }).then(() => {
          this.$store.commit('account/REMOVE_TAGS', [this.selectedTag.id]);
          this.selectedTag = null;
          this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.deleted')})
        }).catch(() => {
          this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
        });
      } catch {
        // rejected
      }
    }
  }
}
</script>