export default `<svg viewBox="0 0 511.713 432.621" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
 <path d="M508.688,170.638c-4.938-23.773-19.71-43.863-40.528-55.133c-16.187-8.737-31.888-10.573-46.691-5.392
    c-8.094,2.827-14.91,7.491-20.534,12.662c-0.327-38.103-31.181-66.771-72.068-66.771c-27.751,0-51.503,12.726-64.65,32.077
    c-12.345-19.5-34.937-32.077-61.97-32.077c-27.751,0-51.503,12.726-64.65,32.077c-12.345-19.5-34.937-32.077-61.97-32.077
    C33.217,56.004,0,85.602,0,123.376c0,5.836,4.716,10.552,10.552,10.552c5.835,0,10.552-4.716,10.552-10.552
    c0-28.964,27.719-46.269,54.521-46.269c29.556,0,50.997,19.457,50.997,46.269v200.483c0,5.835,4.717,10.552,10.552,10.552
    s10.552-4.717,10.552-10.552V123.376c0-28.964,27.719-46.269,54.521-46.269c29.556,0,50.997,19.457,50.997,46.269v200.483
    c0,5.835,4.717,10.552,10.552,10.552s10.552-4.717,10.552-10.552V123.376c0-28.964,27.72-46.269,54.521-46.269
    c29.556,0,50.997,19.457,50.997,46.269v31.603c0,0.011,0,0.021,0,0.043v168.584c-16.714,10.436-35.792,21.463-57.729,33.153
    c-5.139,2.744-7.091,9.128-4.348,14.287c1.91,3.546,5.561,5.571,9.328,5.571c1.667,0,3.365-0.4,4.959-1.234
    c19.521-10.403,36.889-20.354,52.537-29.925c8.272,18.318,26.633,31.159,48.01,31.159c5.836,0,10.552-4.716,10.552-10.552
    c0-5.835-4.716-10.552-10.552-10.552c-13.886,0-25.588-9.053-29.84-21.525C515.071,260.707,517.172,211.567,508.688,170.638z
     M400.966,309.856V157.458c2.658-5.275,12.134-22.053,27.477-27.413c9.074-3.187,18.792-1.856,29.682,4.031
    c15.11,8.167,26.284,23.445,29.894,40.846C493.409,200.921,501.692,241.45,400.966,309.856z"/>
</svg>`