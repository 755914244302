<template>
  <div id="add-new-transit">
    <mbg-chart-picker v-model="selectedChart" show-recents :title="`${$t('pages.newChart.selectChart')}`"/>


    <b-form-group class="custom-radios mt-4">
      <b-form-radio v-model="type"  name="some-radios" value="saturn"> {{ $t('hd.constants.cycles.saturn') }}</b-form-radio>
      <b-form-radio v-model="type"  name="some-radios" value="chiron">{{ $t('hd.constants.cycles.chiron') }}</b-form-radio>
      <b-form-radio v-model="type"  name="some-radios" value="uranus">{{ $t('hd.constants.cycles.uranus') }}</b-form-radio>
      <b-form-radio v-model="type"  name="some-radios" value="solar">{{ $t('hd.constants.cycles.solar') }}</b-form-radio>
      <b-form-radio v-model="type"  name="some-radios" value="lunar" :disabled="!selectedChartObject">{{ $t('hd.constants.cycles.lunar') }}</b-form-radio>
    </b-form-group>


    <div v-if="type === 'solar' || type === 'lunar'">
      <label class="d-block mb-2">Select year</label>
      <b-form-select v-model="selectedYear" :options="years" class="w-50"/>
    </div>

    <div v-if="type === 'lunar'" class="mt-3">
      <label class="d-block mb-2">Select month</label>
      <b-form-select v-model="selectedLunarStamp" :options="lunarMonths" class="w-50"/>
    </div>


    <div class="mt-4 w-xl-50">
      <b-button variant="primary" block @click="createChart" :disabled="!selectedChartObject
       || fetchingLunarData || (type === 'lunar' && !selectedLunarStamp)">{{ $t('pages.newChart.create') }}</b-button>

    </div>

  </div>
</template>

<script>
import MbgChartPicker from "../elements/MbgChartPicker";
import moment from "moment";
import TransitChartMixin from "../../mixins/TransitChartMixin";
import ChartPageMixin from "../../mixins/ChartPageMixin";
import Vue from "vue";

export default{

  mixins: [TransitChartMixin, ChartPageMixin],
  components:{MbgChartPicker},
  computed:{

    lunarMonths(){
      return this.lunarData[this.selectedYear] ? this.lunarData[this.selectedYear].map(x => ({
        text: moment.utc(x).format('MMMM Do YYYY, HH:mm:ss') + ' (UTC)',
        value: x
      })) : []
    }
  },
  watch: {
    selectedChart(val){
      if(val){
        this.fetchDoc(this.selectedChart.id).then(res => {
          this.selectedChartObject = res;
          this.$store.dispatch('charts/setChart', res);
        });
      }
    },
    type(val){
      if(val === 'lunar'){
        this.retrieveLunarData();
      }
    },
    selectedYear(){
      if(this.type === 'lunar') this.retrieveLunarData();
    },
  },
  data(){
    return{
      selectedChart: null,
      selectedChartObject: null,
      solarReturnTime: '',
      type: 'saturn',

      name: null,
      timeFormat: 'HH:mm',
      years: [],
      selectedYear: new Date().getFullYear(),
      lunarData: {},
      selectedLunarStamp: null,
      fetchingLunarData: false,
      updating: false,
    }
  },
  mounted(){
    let years = [];
    for(let i=1999;i<= 2117;i++) years.push(i);
    this.years = years;
  },
  methods:{

    async retrieveLunarData(){
      if(!this.selectedChartObject) return;
      if(!this.lunarData[this.selectedYear]){
        this.fetchingLunarData = true;
        const lunarData = await this.getLunarReturns({
          date: this.selectedChartObject.meta.birthData.time.utc,
          year: this.selectedYear,
          position: this.selectedChartObject.chart.planets[2].longitude
        });
        Vue.set(this.lunarData, this.selectedYear, lunarData);
        // select one randomly.
        this.selectedLunarStamp = lunarData[1];
        this.fetchingLunarData = false;
      }
    },

    async createChart(){
      this.$emit('updating', true);
      const data = {
        time: this.selectedChartObject.chart.cycles[this.type],
        timeInUtc: true,
        docId: this.selectedChart.id,
        name: `${this.selectedChart.name} - ${this.$t('hd.constants.cycles.'+this.type)} ${this.type === 'solar' ? this.selectedYear : ''}`,
        isCycleCalculation: true,
        autosave: false
      }

      if(this.type === 'solar') {
        const solarDate = await this.getSolarReturn({
          date: this.selectedChartObject.meta.birthData.time.utc,
          year: this.selectedYear,
          position: this.selectedChartObject.chart.planets[0].longitude
        });
        data.time = solarDate;
      }

      if(this.type === 'lunar'){
        data.time = this.selectedLunarStamp;
      }

      this.createTransitChart(data).then( chart =>{
        this.$store.dispatch('charts/setChart', chart);
        this.redirectTo('single-chart', null, {chartId: chart.id || 'temp-doc'})
      }).finally(()=>{
        this.$emit('updating', false);
      })

    }
  }


}
</script>