export default `<svg viewBox="0 0 511.713 432.621" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g>
   <path  d="M44.827,205.754c2.701,0,5.402-1.034,7.46-3.092l71.022-71.023c3.282-3.25,8.589-3.25,11.851,0
      l57.644,57.645c11.501,11.501,30.199,11.501,41.69,0l57.644-57.645c3.271-3.25,8.59-3.25,11.85,0l57.646,57.645
      c11.5,11.501,30.199,11.501,41.688,0l71.024-71.023c4.125-4.126,4.125-10.794,0-14.92c-4.127-4.125-10.795-4.125-14.922,0
      l-71.022,71.024c-3.271,3.271-8.578,3.26-11.851,0l-57.644-57.646c-11.502-11.501-30.198-11.501-41.69,0l-57.644,57.644
      c-3.271,3.271-8.577,3.261-11.849,0l-57.645-57.644c-11.501-11.501-30.199-11.501-41.689,0l-71.023,71.023
      c-4.125,4.126-4.125,10.794,0,14.92C39.424,204.72,42.126,205.754,44.827,205.754z"/>
    <path d="M459.436,229.948l-71.023,71.023c-3.271,3.271-8.578,3.271-11.85,0l-57.645-57.643
      c-11.502-11.502-30.199-11.502-41.689,0l-57.644,57.644c-3.271,3.271-8.579,3.271-11.851,0l-57.644-57.644
      c-11.501-11.502-30.199-11.502-41.69,0l-71.022,71.022c-4.126,4.126-4.126,10.794,0,14.92c2.047,2.068,4.748,3.103,7.449,3.103
      s5.402-1.033,7.46-3.092l71.022-71.022c3.282-3.261,8.589-3.25,11.851,0l57.644,57.644c11.501,11.502,30.199,11.502,41.69,0
      l57.644-57.644c3.271-3.261,8.59-3.25,11.85,0l57.646,57.644c11.5,11.502,30.199,11.502,41.688,0l71.024-71.023
      c4.125-4.127,4.125-10.795,0-14.92C470.219,225.833,463.562,225.823,459.436,229.948z"/>
  </g>
</svg>`