<template>


  <div id="transit-changes" :class="darkMode ? 'theme--dark' : 'theme--light'">



    <div class="">
      <b-form-checkbox size="xs" v-model="gates" name="check-button" switch>
        Gate/Lines
      </b-form-checkbox>

    </div>
    <div v-if="changes">
      <div class="changes-header">
        <div class="grid grid-cols-24 overflow-auto py-2 align-items-center">
          <div v-for="item in header" :key="item.name" :class="item.sizeClasses">
            {{item.name}}
          </div>
        </div>
      </div>
      <div v-for="(planet) in getPlanets" :key="`changes-${planet.id}`" class="planet-changes grid grid-cols-24 py-2 my-1 align-items-center">

          <div class="pl-2" :class="`${header[0].sizeClasses}`">
            <span :class="`icon-${planet.icon}`" ></span> {{planet.name}}
          </div>
<!--        <div class=""  :class="`${header[1].sizeClasses}`">-->
<!--          {{formatActivationGateLine(prevDataTransitObj(planet).nextActivation)}}-->
<!--        </div>-->
          <div class=""  :class="`${header[1].sizeClasses}`">
            {{formatActivationGateLine(baseTransitObj(planet).currentActivation)}}
          </div>
          <div class=""  :class="`${header[2].sizeClasses}`">
            {{formatActivationGateLine(baseTransitObj(planet).nextActivation)}}


          </div>

          <div  :class="`${header[3].sizeClasses}`">

            <div class="d-flex align-items-center">
              <time-clock :id="`f-c-${planet.id}`" class="mr-2" width="20px" height="20px" :reversePercentageNum="true"
                          :percentage="calculateTimeForActivation(planet.id, gates ? dataTypes.nextGate : dataTypes.nextLine).percentage"></time-clock>
              <div>
                {{baseTransitObj(planet).shiftingTime}}

                <p v-if="justNow" :id="`shifting-time-${planet.id}`" class="mb-0 text-xs font-weight-light">
                -
                </p>
                <div v-else class="mb-0 text-xs font-weight-light">
                  {{ renderShiftingTime(planet.id)}}
                </div>
              </div>

            </div>




          </div>
          <div :class="`${header[4].sizeClasses}`">


            <div v-if="baseTransitObj(planet)['retrograde']">
              {{baseTransitObj(planet).currentActivation.retrograde === 0 ? 'Starts' : 'Ends'}}<br>
              <p :id="`retrograde-time-${planet.id}`" class="mb-0 text-xs font-weight-light">
                {{ renderRetrogradeShiftingTime(planet.id)}}</p>
            </div>

            <div v-else>
              -
            </div>
<!--            {{baseTransitObj(planet).retrograde || '-'}}-->
          </div>
        </div>
    </div>

  </div>
</template>

<style lang="scss">
#transit-changes{

  &.theme--dark{
    .planet-changes{
      &:nth-child(even){
        background: $bg-contrast-dark;
      }
    }
  }
  &.theme--light{
    .planet-changes{
      &:nth-child(even){
        background: #f9f9f9;
      }
    }
  }

  .changes-header{
    font-size:12px;
    opacity:0.8;
    font-weight:300;
  }

  .planet-changes{
    font-size:11px;
    //padding: 5px 0px 5px 10px;
    border-radius: 5px 10px;

    &:nth-child(even){
      background: $bg-light;
    }
  }
}
</style>

<script>
import TransitToolMixin from "../../../mixins/TransitToolMixin";
import TimeClock from "./elements/time-clock";
export default{
  name:'changes-cmp',
  components: {TimeClock},
  mixins: [TransitToolMixin],
  mounted(){
    if(this.justNow) this.startTimerInterval();
  },
  watch:{
    justNow(val){
      this.$nextTick(()=>{
        if(val){
          this.renderShiftingTimesInterval();
          this.startTimerInterval();
        }
        else this.clearTimerInterval();
      })

    }
  },
  destroyed(){
    this.clearTimerInterval();
  },
  
  data(){
    return{
      header: [
        {name: 'Planet', sizeClasses: 'col-span-4'},
        // {name: 'Previous', sizeClasses: 'col-md-1'},
        {name: 'Current', sizeClasses: 'col-span-3 '},
        {name: 'Next', sizeClasses: 'col-span-3'},
        {name: 'Shifting Time', sizeClasses: 'col-span-7'},
        {name: 'Retrograde', sizeClasses: 'col-span-6'},
      ],
      interval: false,
      gates: false,
    }
  },
  methods:{


    renderShiftingTimesInterval(){

      for(let planet of this.getPlanets){
        document.getElementById(`shifting-time-${planet.id}`).innerHTML = this.calculateRealTimeForActivation(planet.id,
            this.gates ? this.dataTypes.nextGate : this.dataTypes.nextLine);

        if (document.getElementById(`retrograde-time-${planet.id}`)) {
          document.getElementById(`retrograde-time-${planet.id}`).innerHTML = this.calculateRealTimeForActivation(planet.id, 'retrograde')
        }
      }

    },

    renderRetrogradeShiftingTime(planetId){
      return this.calculateTimeForRetrogradionStatusChange(planetId).difference;
    },

    renderShiftingTime(planetId){
      return this.calculateTimeForActivation(planetId, this.gates ? this.dataTypes.nextGate : this.dataTypes.nextLine).difference
    },
    startTimerInterval(){

      if(this.justNow){
        this.interval = setInterval(this.renderShiftingTimesInterval, 1000);
      }
    },
    clearTimerInterval(){
      if(this.interval) window.clearInterval(this.interval);
    },
    baseTransitObj(planet) {
      return this.getNextData(planet.id, this.gates ? this.dataTypes.nextGate : this.dataTypes.nextLine)
    },

    prevDataTransitObj(planet){
      return this.getNextData(planet.id, this.gates ? this.dataTypes.prevGate : this.dataTypes.prevLine)
    }
  }
}
</script>