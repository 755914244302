export default `<svg viewBox="0 0 612 612" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
<path d="M186.777,0.429c-7.186,0.089-12.938,5.986-12.85,13.171c0.023,1.861,0.445,3.695,1.237,5.378
	c13.584,31.076,37.926,56.356,68.253,71.252C173.912,115.91,124.14,182.643,124.14,260.965c0,96.05,74.765,174.767,169.133,181.483
	v65.712h-65.051c-7.186-0.102-13.092,5.642-13.194,12.826c-0.102,7.186,5.641,13.093,12.827,13.194c0.122,0.003,0.245,0.003,0.368,0
	h65.051v65.052c-0.101,7.186,5.641,13.093,12.826,13.194s13.093-5.642,13.194-12.826c0.002-0.123,0.002-0.245,0-0.368v-65.052
	h65.052c7.186,0.103,13.093-5.641,13.194-12.826c0.101-7.186-5.642-13.093-12.826-13.194c-0.123-0.001-0.245-0.001-0.368,0h-65.052
	v-65.712c94.369-6.716,169.134-85.433,169.134-181.483c0-78.323-49.771-145.056-119.277-170.734
	c30.327-14.896,54.669-40.176,68.253-71.252c2.974-6.542,0.083-14.256-6.459-17.23c-1.706-0.776-3.559-1.173-5.434-1.167
	c-5.222,0.022-9.924,3.164-11.942,7.979c-18.06,41.315-59.167,70.261-107.284,70.261S217.06,49.876,199,8.561
	C196.99,3.591,192.138,0.363,186.777,0.429z M306.284,104.842c86.378,0,156.123,69.745,156.123,156.124
	c0,85.88-68.961,155.25-154.649,156.047c-0.557-0.07-1.117-0.104-1.678-0.102c-0.467,0.008-0.934,0.042-1.396,0.102
	c-85.629-0.866-154.523-70.21-154.523-156.047C150.161,174.586,219.905,104.842,306.284,104.842z"/>
</svg>`