import planets from "../helpers/rave/constants/planets";

export default {
    methods: {
        getGateToggleLabel(gateId){
            return `<p class='my-0 gap-3'>
                 <span class="font-500">
                ${this.$t('hd.gates.name.one')} ${gateId}:
                ${this.$t('hd.gates.' + gateId + '.name')} -</span> 
                <span class="mbg-text-muted">${this.$t('hd.gates.' + gateId + '.titleKeynote')}</span>
            </p>`
        },
        getPlanetColorClasses(planet) {
            if (planet.activation === 0) return 'color-design';
            if (this.isTransitPlanet(planet)) return 'color-transit';
            return 'color-personality';
        },
        getPlanetText(planet, showExaltedOrDetriment = false, isExalted = false){
            const activationText = planet.activation === 0 ? this.$t('hd.properties.design') :
                (this.isTransitPlanet(planet) ? this.$t('pages.newChart.transit') : this.$t('hd.properties.personality'));

            const exaltedDetrimentIcon = showExaltedOrDetriment ? `<span class='icon-${isExalted ? 'exalted' : 'detriment'} text-sm'></span>` : '';

            let chartName = '';
            if(this.isConnectionChart(this.chart)) chartName = ` (${this.getPersonName(planet.chartId, this.chart)})`
            if(this.isCycleChart(this.chart)) chartName = ` (${planet.chartId === 0 ? this.chart?.meta?.person?.name : 'Cycle Chart'})`;

            return `<p class="my-0 font-weight-normal ${showExaltedOrDetriment ? '': 'ml-2'}">
                <span class="font-500 ${this.getPlanetColorClasses(planet)}">
                ${exaltedDetrimentIcon}
                ${activationText} ${this.$t('hd.planets.'+ planet.id + '.name')} ${chartName}
                  </span>
                <span class="">in ${planet.gate}.${planet.line}</span>
              </p>`;
        },
        isTransitPlanet(planet){
            return this.isTransitChart(this.chart) && planet.chartId === 1;
        },
        getPlanetIcon(planetId){
            return `<span class="text-md icon-${planets.icons[planetId]}"</span>`;
        },
    }
}