import * as R from 'ramda'
import { isEq } from '@/helpers/utils/misc'

const TOP_LEFT = 0
const TOP_RIGHT = 1
const CENTER = 2
const BOTTOM_RIGHT = 3
const BOTTOM_LEFT = 4

const fromDesignBase = R.cond([
  [isEq(1), R.always(TOP_LEFT)],
  [isEq(2), R.always(TOP_RIGHT)],
  [isEq(3), R.always(CENTER)],
  [isEq(4), R.always(BOTTOM_RIGHT)],
  [isEq(5), R.always(BOTTOM_LEFT)]
])

const fromPersonalityBase = R.cond([
  [isEq(1), R.always(BOTTOM_LEFT)],
  [isEq(2), R.always(TOP_RIGHT)],
  [isEq(3), R.always(TOP_LEFT)],
  [isEq(4), R.always(BOTTOM_RIGHT)],
  [isEq(5), R.always(CENTER)]
])

export default { TOP_LEFT, TOP_RIGHT, CENTER, BOTTOM_RIGHT, BOTTOM_LEFT, fromDesignBase, fromPersonalityBase }
