/* eslint-disable */
import * as R from 'ramda'
import { isNullOrEmpty, hasPath, isPathTrue } from '../../../helpers/utils/misc'
import channels, { integrationChannelIds } from '../../../helpers/rave/constants/channels'
import circuits from '../../../helpers/rave/constants/circuits'
import gates from '../../../helpers/rave/constants/gates'
import objects from '../../../helpers/rave/constants/objectTypes'
import {
  getGateActivationModes,
  getDefinedChannels,
  getDefinedCenters,
  getChannelGates
} from '../../../helpers/rave/queries'

const emptyDataSource = {
  gates: {},
  centers: {},
  channels: []
}

const mapGateActivationToCssClass = R.cond([
  [R.equals(0), R.always('c1')],
  [R.equals(1), R.always('c2')],
  [R.T, R.always('mixed')]
])

const isIntegrationChannel = R.either(R.lt(36), R.includes(R.__, integrationChannelIds))

const getDefinedIntegrationChannels = R.filter(isIntegrationChannel)

const getDefinedIntegrationGates = channelIds => R.pipe(
  getDefinedIntegrationChannels,
  R.chain(getChannelGates),
  R.uniq,
  R.sortBy(R.identity)
)(channelIds)

const getRenderChannelIdStrategy = R.ifElse(
  R.pipe(R.length, R.gt(R.__, 2)),
  gates => id => isIntegrationChannel(id) ? R.join('', gates) : id,
  R.always(R.identity)
)

const mergeGateRecords = (l, r) => R.ifElse(
  R.isNil,
  R.always(r),
  _ => ({ cls: r, channel: l })
)(l)

const convertGateActivationsToCss = R.reduce((acc, { gate, mode }) => R.assoc(gate, mapGateActivationToCssClass(mode), acc), {})

const _createDataSource = planets => {
  if (isNullOrEmpty(planets)) return emptyDataSource

  const gateActivationModes = getGateActivationModes(planets)

  const definedChannels = R.pipe(R.pluck('gate'), getDefinedChannels)(gateActivationModes)

  const createCenterDefinitionPair = definedCenterIds => id => [id, R.includes(id, definedCenterIds)]

  const definedCenters = R.pipe(
    c => R.map(createCenterDefinitionPair(c), R.range(0, 9)),
    R.fromPairs
  )(getDefinedCenters(definedChannels))

  const getRenderChannelId = R.pipe(
    getDefinedIntegrationGates,
    getRenderChannelIdStrategy
  )(definedChannels)

  const getChannelGates = id => R.pipe(
    R.nth(R.__, channels),
    R.prop('gates'),
    R.map(x => ({ g: x, c: getRenderChannelId(id) })),
    R.indexBy(R.prop('g')),
    R.map(R.prop('c'))
  )(id)

  const getChannelsGates = R.reduce((acc, x) => R.mergeRight(getChannelGates(x), acc), {})

  const allTogetherNow = R.curry((dChannels, dGates) => R.pipe(
    getChannelsGates,
    R.mergeWith(mergeGateRecords, R.__, dGates),
    R.assoc('gates', R.__, emptyDataSource),
    R.assoc('channels', definedChannels, R.__),
    R.assoc('centers', definedCenters, R.__)
  )(definedChannels))

  const gateActivationCss = convertGateActivationsToCss(gateActivationModes)

  for (const [gate, state] of Object.entries(gateActivationCss)) {
    if (state === 'c1' || state === 'mixed') {
      const pl = planets.filter(x => +x.gate === +gate && x.chartId === 0);
      const activations = [...new Set(pl.map(x => x.activation))];

      gateActivationCss[gate] += ` chart-0-act-` + activations.sort().join('-')
    }
  }

  return allTogetherNow(definedChannels)(gateActivationCss)
}

const create = R.curry((view, planets) => R.pipe(
  _createDataSource,
  whenPartialCircuitry(view)
)(planets))

const whenPartialCircuitry = R.curry((viewMode, dataSource) => viewMode.cata({
  Normal: () => dataSource,
  Cross: () => dataSource,
  Circuit: (val) => trimToCircuit(val, dataSource),
  SpecificActivation: (val) => dataSource,
  SpecificChart: (val) => dataSource
}))

const trimToCircuit = (circuit, dataSource) => {
  const circuitChannels = R.pipe(
    R.filter(R.propEq('circuit', circuit))
  )(channels)

  const circuitry = R.ifElse(
    R.equals(circuits.INTEGRATION, R.__),
    R.always([10203457]),
    R.always(R.pluck('id', circuitChannels))
  )(circuit)

  const circuitCenters = R.pipe(
    R.chain(R.prop('gates')),
    R.map(g => gates[g - 1].center),
    R.uniq
  )(circuitChannels)

  // this is left over from when the actual filtering of the planets happened here
  // now that we are fed only relevant planets to begin with we dont need this

  /* const circuitGates = R.pipe(
    R.chain(R.prop('gates')),
    R.uniq
  )(circuitChannels) */

  const onlyIfOneOf = validValues => R.filter(R.includes(R.__, validValues))

  const keepOnly = R.pick

  const transformations = {
    // gates: keepOnly(circuitGates),
    centers: keepOnly(circuitCenters),
    channels: onlyIfOneOf(R.pluck('id', circuitChannels))
  }

  return R.pipe(
    R.evolve(transformations),
    R.assoc('selective_circuitry', circuitry)
  )(dataSource)
}

const isGateDefined = R.curry((dataSource, id) => hasPath(['gates', id], dataSource))

const isChannelDefined = R.curry((dataSource, id) => R.pipe(R.prop('channels'), R.includes(id))(dataSource))

const getCssClassesForCenter = R.curry((dataSource, id) => {
  const shouldRenderCenter = R.has(R.__, dataSource.centers)

  const addClassWhen = (predicate, cls) => R.ifElse(
    predicate,
    R.always([cls]),
    R.always([])
  )

  const whenDefined = addClassWhen(
    id => isPathTrue(['centers', id], dataSource),
    'defined'
  )

  const whenHighlighted = addClassWhen(
    id => hasPath(['highlights', 'centers', id], dataSource),
    'highlight'
  )

  return R.ifElse(
    shouldRenderCenter,
    R.converge(R.concat, [whenDefined, whenHighlighted]),
    R.always(['muted'])
  )(id)
})

const getCssClassesForGate = R.curry((dataSource, id) => {
  const activations = R.pipe(
    R.path(['gates', id]),
    R.ifElse(
      R.is(String),
      R.identity,
      R.prop('cls')
    ),
    Array.of,
    R.concat(['defined'])
  )

  const highlights = R.when(
    _ => hasPath(['highlights', 'gates', id], dataSource),
    R.concat(['highlight'])
  )

  const channelFlag = R.when(
    _ => isGateInDefinedChannel(dataSource, id),
    R.concat(['inChannel'])
  )

  return R.ifElse(
    R.flip(isGateDefined)(id),
    R.pipe(activations, highlights, channelFlag),
    R.always([])
  )(dataSource)
})

const getCssClassesForGateNumbers = R.curry((dataSource, id) => R.ifElse(
  hasPath(['gates', id]),
  R.always(['defined']),
  R.always([])
)(dataSource))

const getCssClassesForChannel = R.curry((dataSource, id) => {
  const addClassWhen = (predicate, cls) => R.ifElse(
    predicate,
    R.always([cls]),
    R.always([])
  )
  const whenDefined = addClassWhen(
    id => isChannelDefined(dataSource, id),
    'defined'
  )

  const whenRenderingSelectiveCircuits = R.ifElse(
    _ => isNullOrEmpty(dataSource.selective_circuitry),
    R.always([]),
    id => R.includes(id, dataSource.selective_circuitry) ? ['strong'] : ['muted']
  )

  return R.converge(R.concat, [whenRenderingSelectiveCircuits, whenDefined])(id)
})

const isGateInDefinedChannel = R.curry((dataSource, id) => R.pipe(
  R.path(['gates', id, 'channel']),
  R.complement(R.isNil)
)(dataSource))

const getGateEventSource = R.curry((dataSource, id) => R.pipe(
  R.path(['gates', id, 'channel']),
  R.ifElse(
    R.isNil,
    _ => ({ type: objects.GATE, id, renderElemId: id }),
    R.ifElse(
      isIntegrationChannel,
      x => ({ type: objects.CHANNEL, renderElemId: x, id: getDefinedIntegrationChannels(dataSource.channels) }),
      x => ({ type: objects.CHANNEL, renderElemId: x, id: x })
    )
  )
)(dataSource))

const DataSource = {
  empty: emptyDataSource,
  create
}

const funcs = {
  getCssClassesForCenter,
  getCssClassesForGate,
  getCssClassesForGateNumbers,
  getCssClassesForChannel,
  getGateEventSource,
  isIntegrationChannel,
  isGateDefined,
  isChannelDefined
}

export {
  DataSource,
  funcs,
  trimToCircuit,
  isIntegrationChannel
}
