import { isLt } from '@/helpers/utils/misc'

const PLL_DLL = 0
const PLL_DLR = 1
const PLL_DRL = 2
const PLL_DRR = 3
const PLR_DLL = 4
const PLR_DLR = 5
const PLR_DRL = 6
const PLR_DRR = 7
const PRL_DLL = 8
const PRL_DLR = 9
const PRL_DRL = 10
const PRL_DRR = 11
const PRR_DLL = 12
const PRR_DLR = 13
const PRR_DRL = 14
const PRR_DRR = 15

const isLeft = isLt(4)

const getLetter = tone => isLeft(tone) ? 'L' : 'R'

const fromTones = (personalityTone, personalityNodalTone, designTone, designNodalTone) => {
  const pTone = getLetter(personalityTone)
  const pNodalTone = getLetter(personalityNodalTone)
  const dTone = getLetter(designTone)
  const dNodalTone = getLetter(designNodalTone)

  const variableStr = 'P'.concat(pTone).concat(pNodalTone).concat('_D').concat(dTone).concat(dNodalTone)

  return variableStr
}

export default { PLL_DLL, PLL_DLR, PLL_DRL, PLL_DRR, PLR_DLL, PLR_DLR, PLR_DRL, PLR_DRR, PRL_DLL, PRL_DLR, PRL_DRL, PRL_DRR, PRR_DLL, PRR_DLR, PRR_DRL, PRR_DRR, fromTones }
