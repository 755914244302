<template>
  <div class="text-center">

    <span class="icon-cart" style="font-size:2rem;"></span>
    <p class="mt-2">
      <span class="font-500">{{title || 'Your cart is empty.'}}</span><br/>
      {{subtitle}}
    </p>
  </div>
</template>

<script>

export default{
  props:{
    title: String,
    subtitle: String,
  }
}
</script>