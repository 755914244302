import {setLocalStorageItem, getLocalStorageItem, localStorageKeys, SidebarVariants} from "@/utils/localStorage";

export const namespaced = true

const state = {
    globalLoading: true,
    title: {
        text: 'test'
    },
    search: {
        keyword: '',
        active: false
    },
    showProperties: false,
    lastVisitedChart: null,
    lastTransactionValue: null,
    singleChart:{
        aiEnabled: false,
    },
    showMandala: false,
    viewMode: 'normal',
    viewModes:{
        normal: 'normal',
        design: 'design', // design for rave chart, person 1 for connection charts
        personality: 'personality', // personality ofr rave chart, person 2 for connection charts.
        cross: 'cross',
    },
    sideSheet: {
        show: false,
        type: null
    },
    showOwl: false,
    startingOwlTab: null,
    showCheckoutSidebar: false,
    showTransitSidebar: false,
    sharedChartSidebarState: 0, // 0  = false, 1 =
    checkoutSidebarTab: 'foundational',
    proVersion: false,
    propertiesKey: 0,
    timelineViewMode: null,
    options:{
        showProfile: true,
        showType: true,
        showDefinition:true,
        showAuthority:true
    },
    iching: {
        centersActiveTab: null,
        circuitsActiveTab: null,
    },
    tabsMapping:{
        'gate': 'gates',
        'center': 'centers',
        'channel':'channels',
        'planet': 'planetary-activations'
    },
    analysis: {
        mainTab: 0,
        companionship_tab: 0,
        foundational_tab: 0,
        advanced_tab: 0,
        personal_success_tab: 0
    },
    lastInnerTab: false,
    isAdminImpersonation: false,
    impersonationData: null,
    enableAdminButtons: false,
    gift: getLocalStorageItem(localStorageKeys.GIFT) ?? null,
    freeChart: null,
    tripleActivation: getLocalStorageItem(localStorageKeys.TRIPLE_ACTIVATION) ?? false,
}

const mutations = {

    SET_TITLE(state, val){
        if (typeof val === 'object'){
            state.title = {...val}
        } else {
            state.title = {
                text: val,
                showCelebrity: false,
            };
        }
    },

    OPEN_SIDE_SHEET(state, val) {
        state.sideSheet.show = true;

        if (!Object.keys(SidebarVariants).includes(val)) {
            console.warn(`Unknown sidebar variant: ${val}`);
        }
        state.sideSheet.type = val;
    },

    CLOSE_SIDE_SHEET(state) {
        state.sideSheet.show = false;
        state.sideSheet.type = null;
    },

    SET_SEARCH(state, val) {
        state.search = {...state.search, ...val};
    },
    SET_OPTIONS(state, val){
        state.options = {...val}
        setLocalStorageItem('library_options', val, true);
    },
    SET_SHOW_PROPERTIES(state, val){
        state.showProperties = val;
    },
    SET_PROPERTIES_KEY(state, val){
        state.propertiesKey = val;
    },
    SET_SHOW_OWL(state, val){
        state.showOwl = val;
    },
    SET_STARING_OWL_TAB(state, val) {
        state.startingOwlTab = val;
    },
    SET_SHOW_MANDALA(state, val){
        state.showMandala = val;
    },
    SET_VIEW_MODE(state, val){
        state.viewMode = val;
    },
    SET_AI_ENABLED(state, val){
        state.singleChart.aiEnabled = val;
    },
    SET_LAST_VISITED_CHART(state, val) {
        state.lastVisitedChart = val;
    },
    SET_LAST_TRANSACTION_VALUE(state, val) {
        state.lastTransactionValue = val
    },
    SET_SHOW_CHECKOUT_SIDEBAR(state, val) {
        state.showCheckoutSidebar = val
    },
    SET_CHECKOUT_SIDEBAR_ACTIVE_TAB(state, val) {
        state.checkoutSidebarTab = val
    },
    SET_SHARED_CHART_SIDEBAR_STATE(state, val) {
        state.sharedChartSidebarState = val
    },
    SET_ICHING_ACTIVE_TABS(state, val) {
        state.iching = {...state.iching, ...val}
    },
    SET_ANALYSIS_TAB(state, val) {
        const keys = ['companionship_tab', 'mainTab', 'foundational_tab', 'advanced_tab', 'personal_success_tab'];
        for(let key of keys) {
            if (Object.hasOwn(val, key) && state.analysis[key] !== val[key]) {
                state.analysis[key] = val[key];
                if(Object.hasOwn(val, 'lastInnerTab')) state.lastInnerTab = val.lastInnerTab;
            }
        }
    },
    SET_SHOW_TRANSIT_SIDEBAR(state, val) {
        state.showTransitSidebar = val;
    },

    SET_TIMELINE_VIEW_MODE(state, val) {
        state.timelineViewMode = val;
    },

    SET_IS_ADMIN_IMPERSONATION(state, val) {
        state.isAdminImpersonation = val;
    },

    SET_IMPERSONATION_DATA(state, val) {
        state.impersonationData = val;
    },

    SET_ENABLE_ADMIN_BUTTONS(state, val) {
        state.enableAdminButtons = val
    },

    SET_GIFT(state, val) {
        state.gift = val;
        setLocalStorageItem(localStorageKeys.GIFT, val, true)
    },

    REMOVE_GIFT(state) {
        state.gift = null;
        localStorage.removeItem(localStorageKeys.GIFT)
    },

    SET_FREE_CHART(state, val) {
        state.freeChart = val;
    },

    SET_TRIPLE_ACTIVATION(state, val) {
        state.tripleActivation = val;
        setLocalStorageItem(localStorageKeys.TRIPLE_ACTIVATION, val, true);
    },

    RESET_VIEW(state){
        state.sideSheet.show = false;
        state.sideSheet.type = null;
        state.viewMode = 'normal';
        state.showMandala = false;
        state.singleChart.aiEnabled = false;
        state.timelineViewMode = null;

        for(let key of Object.keys(state.analysis)){
            state.analysis[key] = 0;
        }

    }
}

const getters = {}

const actions = {

    init(context){
        const libraryOptions = getLocalStorageItem('library_options')
        if(libraryOptions) context.commit('SET_OPTIONS', libraryOptions)

        setTimeout(()=>{
            context.state.globalLoading = false
        }, 1_000)
    },
    setTitle(context, data){
        context.commit('SET_TITLE', data);
    },

    setOption(context, data){
        const options = {...context.state.options}
        options[data.key] = data.value;
        context.commit('SET_OPTIONS', options);
    },

    increasePropertiesKey({commit, state}){
        commit('SET_PROPERTIES_KEY', state.propertiesKey + 1);
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}