<template>

  <div
      v-if="show && !$route.path.includes('private/')"
      id="site-wide-loader"
       class="position-fixed bg-light d-flex align-items-center justify-content-center"
       :class="loading ? 'visible' : 'hidden'"
       style="z-index:99999;">
    <div>
      <img :src="loadLocalIcon(`logo-${getDarkModeText}.svg`)" class=" animate-pulse"/>
    </div>
  </div>

</template>
<script>
import {mapState} from "vuex";

export default{

  computed:{
    ...mapState({
      loading: state => state.ui.globalLoading
    })
  },
  watch:{
    loading(val){
      if (!val) {
        setTimeout(()=>{
          this.show = false;
        }, 1000)
      } else {
        this.show = true;
      }
    }
  },
  data(){
    return {
      show: true,
    }
  }
}
</script>

<style lang="scss">
#site-wide-loader {
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  .animate-pulse{
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%, 100% {
        //opacity: 1;
        transform: scale(1)
      }
      50% {
        //opacity: .5;
        transform: scale(1.1)
      }
    }
  }
  &.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s .35s, opacity .35s linear;
  }
}
</style>