<template>
  <div class="planet">
    <b-overlay :show="loading" rounded="sm">
    <b-card v-if="!isRelatedToggle" class="bg-gray shadow-none text-center py-3 mt-3">
      <div class="row mb-3 prev-next">
        <div class="col-6">

          <div v-if="prev" class="d-flex align-items-center cursor-pointer" style="opacity:0.7;" @click="$emit('changePlanet', prev)">
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform:rotate(180deg)">
              <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <p class="ml-2 mb-0 d-flex align-items-center">
              {{prev.name}}  <span :class="`ml-2 icon-${getIcon($t('hd.planets.'+prev.id + '.name'))}`"></span>
        </p>
          </div>

        </div>
        <div class="col-6 text-right" @click="$emit('changePlanet', next)">
          <div v-if="next" class="d-flex align-items-center ml-auto justify-content-end cursor-pointer" style="opacity:0.7;">
            <p class="mr-2 mb-0 d-flex align-items-center">
              <span :class="`mr-2 icon-${getIcon($t('hd.planets.'+next.id + '.name'))}`"></span> {{next.name}}
              </p>
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>

        </div>
      </div>

      <span :class="`icon-${getIcon($t('hd.planets.'+planet.id + '.name'))} ${activation === 0 ? 'color-design' : 'color-personality'}`"
            style="font-size:2.5rem"></span>

      <h3 class="font-weight-bolder mt-3">{{$t('hd.planets.'+planet.id + '.name') }} in {{planet.gate}}.{{planet.line}}</h3>
      <h5 class="font-weight-normal"> {{$t('hd.planets.'+planet.id + '.keynote') }}</h5>
      <p v-if="planet.person" class="font-weight-light small">      {{planet.person}}</p>
    </b-card>
      <div class="grid grid-cols-1 gap-5">
        <line-content :gate-id="planet.gate"
                      :planet="planet" :line-id="planet.line" :get-icon="getIcon"/>

        <mbg-accordion-item :text="$t('charts.properties.planetaryInfo')"
                            :reverse-background="reverseBackground && !parentReverseBackground"
        >
            <p class="mb-0" style="white-space: pre-line">
              {{$t('hd.planets.' + planet.id + '.desc')}}
            </p>
        </mbg-accordion-item>

        <planet-related-links
            class="mt-2"
            :class="isRelatedToggle ? 'pb-0' : ''"
            :planet="planet"
            :depth="depth + 1"
            :is-nested="!!isRelatedToggle"
            :parent-reverse-background="parentReverseBackground"
        />
      </div>
    </b-overlay>
  </div>

  
</template>

<style lang="scss">
.planet .planetary-tab{
  font-size:1.1rem;
  margin-bottom:1rem;
}
</style>
<script>
import {mapState} from "vuex";
import LineContent from "../content/LineContent";
import PlanetRelatedLinks from "../related-links/PlanetRelatedLinks";
import MbgAccordionItem from "../../../elements/MbgAccordionItem.vue";


export default{
  components: {MbgAccordionItem, PlanetRelatedLinks, LineContent},
  props:{
    isRelatedToggle: {
      type: Boolean,
      default: false,
    },
    depth: Number,
    planet: Object,
    activation:Number,
    prev: Object,
    next: Object,
    getIcon: Function,
    reverseBackground: Boolean,
    parentReverseBackground: Boolean,
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
  },

  data(){
    return{
      content: null,
      loading: false,
      audioKey: 0,
    }
  }
}
</script>