<template>
  <svg v-if="isStar" width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="13" width="18" height="13" fill="#121212"/>
    <rect width="18" height="13" fill="#ACFF30"/>
    <path d="M4.4653 16L13.5338 16C13.6164 16 13.6975 16.0208 13.7689 16.0604C13.8402 16.1 13.8992 16.1568 13.9398 16.2252C13.9804 16.2935 14.0012 16.3709 13.9999 16.4493C13.9987 16.5278 13.9756 16.6045 13.9329 16.6717L9.39932 23.7865C9.35779 23.8517 9.29926 23.9055 9.22939 23.9429C9.15952 23.9803 9.08067 24 9.00047 24C8.92026 24 8.84141 23.9803 8.77154 23.9429C8.70167 23.9055 8.64314 23.8517 8.60161 23.7865L4.06714 16.6717C4.02447 16.6046 4.00132 16.5279 4.00006 16.4495C3.99879 16.3712 4.01946 16.2939 4.05995 16.2256C4.10045 16.1573 4.15931 16.1004 4.23052 16.0607C4.30174 16.0211 4.38276 16.0002 4.4653 16Z" fill="#ACFF30"/>
    <path d="M13.5347 10H4.46623C4.38361 10 4.30247 9.97915 4.23113 9.93959C4.15978 9.90002 4.10078 9.84316 4.06018 9.77482C4.01958 9.70649 3.99883 9.62913 4.00005 9.55068C4.00127 9.47222 4.02443 9.39549 4.06714 9.32832L8.60068 2.2135C8.64221 2.14834 8.70074 2.09445 8.77061 2.05705C8.84048 2.01965 8.91933 2 8.99953 2C9.07974 2 9.15859 2.01965 9.22846 2.05705C9.29833 2.09445 9.35686 2.14834 9.39839 2.2135L13.9329 9.32832C13.9755 9.39542 13.9987 9.47208 13.9999 9.55046C14.0012 9.62884 13.9805 9.70613 13.94 9.77444C13.8996 9.84275 13.8407 9.89962 13.7695 9.93925C13.6983 9.97888 13.6172 9.99984 13.5347 10Z" fill="#121212"/>
  </svg>
  <svg v-else-if="isDetriment" width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" fill="#121212"/>
    <path d="M4.4653 5.5L13.5338 5.5C13.6164 5.5 13.6975 5.52085 13.7689 5.56042C13.8402 5.59998 13.8992 5.65684 13.9398 5.72518C13.9804 5.79351 14.0012 5.87087 13.9999 5.94932C13.9987 6.02778 13.9756 6.10451 13.9329 6.17168L9.39932 13.2865C9.35779 13.3517 9.29926 13.4055 9.22939 13.4429C9.15952 13.4803 9.08067 13.5 9.00047 13.5C8.92026 13.5 8.84141 13.4803 8.77154 13.4429C8.70167 13.4055 8.64314 13.3517 8.60161 13.2865L4.06714 6.17168C4.02447 6.10458 4.00132 6.02792 4.00005 5.94954C3.99879 5.87116 4.01946 5.79387 4.05995 5.72556C4.10044 5.65725 4.15931 5.60038 4.23052 5.56075C4.30174 5.52112 4.38276 5.50016 4.4653 5.5Z" fill="#ACFF30"/>
  </svg>
  <svg v-else-if="isExalted" width="18" height="26" viewBox="0 0 18 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" fill="#ACFF30"/>
    <path d="M13.5347 12.5H4.46623C4.38361 12.5 4.30247 12.4792 4.23113 12.4396C4.15978 12.4 4.10078 12.3432 4.06018 12.2748C4.01958 12.2065 3.99883 12.1291 4.00005 12.0507C4.00127 11.9722 4.02443 11.8955 4.06714 11.8283L8.60068 4.7135C8.64221 4.64834 8.70074 4.59445 8.77061 4.55705C8.84048 4.51965 8.91933 4.5 8.99953 4.5C9.07974 4.5 9.15859 4.51965 9.22846 4.55705C9.29833 4.59445 9.35686 4.64834 9.39839 4.7135L13.9329 11.8283C13.9755 11.8954 13.9987 11.9721 13.9999 12.0505C14.0012 12.1288 13.9805 12.2061 13.94 12.2744C13.8996 12.3427 13.8407 12.3996 13.7695 12.4393C13.6983 12.4789 13.6172 12.4998 13.5347 12.5Z" fill="#121212"/>
  </svg>
</template>

<script>

export default {

  props: {
    isExalted: Boolean,
    isDetriment: Boolean,
    isStar: Boolean
  }
}
</script>