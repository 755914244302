export default {
  '93': 'Compromise Tension',
  '94': 'Office Stability',
  '95': 'Hierarchical Tension',
  '83': 'Seeking a Focus',
  '84': 'Taken For Granted',
  '85': 'Someone Is Missing',
  '73': 'Lack of Cooperation',
  '74': 'Office Problems',
  '75': 'Politics',
  '63': 'Lack of Direction',
  '64': 'Lack of Leadership'
}
