<template>

  <div :class="'mbg-text-input ' + (classes||'')">
    <label v-if="title" class="d-flex align-items-end gap-3">{{title}}
      <input v-if="!isSafariForBrowser" ref="native-picker" :type="inputType === 'date' ? 'date' : 'time'"
             tabindex="-1"
             class="native-datetime-picker py-0 my-0 bg-transparent"
             :maxlength="inputMaxLength"
             :value="nativePickerFormattedValue"
             :disabled="disabled"
             :placeholder="getPlaceholder"
             style="border:0!important;background-color:transparent!important;color:inherit!important;padding:0!important;margin:0!important"
             @input="updateNativePickerValue">
      <b-button v-if="!isSafariForBrowser" tabindex="-1" variant="link" @click="openNativePicker"
                class="btn-xs d-flex align-items-center gap-3 bg-primary text-white casual-border-radius-small px-3"
                style="margin:0!important;margin-left:5px;padding:4px 7px!important"
      :disabled="disabled">
        <svg v-if="inputType === 'date'" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-calendar"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>
<!--        <span class="icon-" />-->
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
      </b-button>
    </label>

      <b-form-input :type="type"
                    :maxlength="inputMaxLength"
                    :value="value" :disabled="disabled"
                    :placeholder="getPlaceholder"
                    blur="blur" @input="updateValue"
                    :state="hasError ? false : null" :class="inputClasses"
                    :formatter="clearValue"/>

    <p v-if="value && isValidValue"
       class="mbg-text-muted font-500 text-sm " style="margin-top:-20px;">
      {{ getFormatted }}
    </p>
    <p v-else-if="!isSafariForBrowser"
         class="mbg-text-muted font-500 text-sm " style="margin-top:-20px;">
      Type or click icon to select
    </p>

    <p class="form-error-indicator mb-0 mt-0" v-if="hasError">
      <span v-for="error in validation.$errors" :key="error.message" class="d-block">
        {{formatMessage(error.$message)}}
      </span>
    </p>
  </div>
</template>


<script>

import moment from "moment";

const timeFormat = 'HH:mm';

export default{

  props:{
    title: String,
    value: String,
    placeholder: String,
    icon: String,
    format: String,
    type: {
      type:String,
      default: 'text'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classes: String,
    inputClasses: String,
    validation:Object,
    inputType: {
      type: String,
      default: 'date'
    }
  },
  computed: {
    isSafariForBrowser(){
      const uA = navigator.userAgent;
      const vendor = navigator.vendor;
      return (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA));
    },
    nativePickerFormattedValue(){
      if (this.inputType === 'date') return moment(this.value, this.format).format('YYYY-MM-DD');
      return this.value;
    },
    getPlaceholder(){
      return this.placeholder || this.title;
    },
    hasError(){
      return this.validation && this.validation.$error;
    },
    getFormatted(){
      if(this.isDateInput) return moment(this.value, this.format).format('MMMM D yyyy')

      return moment(this.value, timeFormat).format('hh:mm A');
    },
    isValidValue(){

      if(this.isDateInput) return moment(this.value, this.format).isValid();

      return moment(this.value, timeFormat).isValid()
    },

    getInputGroupClasses(){
      let classes = '';
      if(this.disabled){
        classes += 'disabled '
      }
      if(this.hasError){
        classes += 'has-error '
      }

      return classes;
    },
    isDateInput(){
      return this.inputType === 'date'
    },

    inputMaxLength(){
      return this.isDateInput ? 10 : 5;
    }
  },
  methods:{

    openNativePicker(){
      this.$refs['native-picker'].showPicker();
      this.$refs['native-picker'].focus();
      this.$refs['native-picker'].click();
    },
    change(val){
      this.$emit('change', val);
    },
    blur(event){
      this.$emit('blur', event);
    },
    updateValue(value) {

      this.$emit('input', value);

    },
    updateNativePickerValue(ev){
      if (this.inputType !== 'date') return this.$emit('input', ev.target.value);

      const val = moment(ev.target.value, 'YYYY-MM-DD').format(this.format);
      this.$emit('input', val);
    },
    formatMessage(message){
      return message.includes('errors.') ? this.$t(message) : message;
    },
    clearValue(val, event){
      const isBackSpace = event.inputType === 'deleteContentBackward';
      if(isBackSpace) return val;

      if(this.inputType === 'date'){
        // add 0 if we insert something like : 1/ (we want 01/)

        if(val.length === 2 && val.includes('/')){val = '0' + val;}

        if(this.format === 'DD/MM/YYYY'){
          // if the val = 1 and we insert a "2", we want it to be -> 02 because there is no day that starts with 4 and has extra digit
          if(val.length === 1 && val > 3) val = '0' + val.substring(0);

          // maximum number for day is 31
          if(val.length === 2 && val > 31) val = '31'

          // fix month part
          // example: (01/2) --> (01/02/) or 01/3 -> 01/03/
          if(val.length === 4 && val.substring(3) > 2) val = val.substring(0,3) + '0' + val.substring(3) + '/';

          // if we have 01/13 it converts to 01/12 (max month is 12)
          if(val.length === 5 && val.substring(3,5) > 12) val = val.substring(0,3)+'12';

        }else if(this.format === 'MM/DD/YYYY'){
          // if the val = 1 and we insert a "2", we want it to be -> 02 because there is no month that starts with 2 and has anything else
          if(val.length === 1 && val >= 2) val = '0' + val.substring(0);

          // if the val >12 (like 13) we want it to be the max month that exists -> 12
          if(val.length === 2 && val > 12) val = '12';

          // if the day entered is greater than 4, we want it to become 04, because there can't be a day starting from 4 (like 40)
          if(val.length === 4 && val.substring(3) > 3) val = val.substring(0,3) + '0' + val.substring(3);

          // if day is > 31, we want it to be 31 (max days in a month)
          if(val.length === 5 && val.substring(3) > 31) val = val.substring(0,3)+'31';
        }

        // if the length of the displayModel === 2 and there are no '/', we want to add it automatically
        if(val.length === 2 && !val.match(/[/]/g)) val += '/';

        if(val.length ===5){
          // if we have two <//> in it and length == 5 -> for example 01/1/ -> 01/01/
          if(val.match(/[/]/g).length === 2) val = val.substring(0,3) + '0' + val.substring(3);

          // if we have only one / in it and length = 5, for example 01/01 we want the added slash -> 01/01/
          if(val.match(/[/]/g).length === 1) val += '/'
        }
      }else{
        // example: 5 -> 05:  (but 1, 2 remain as 2 because we might have [10...19] or [20..23]
        if(val.length < 2 && val > 2) val = '0' + val + ':';
        // we convert [24...29] to 23
        if(val.length === 2 && val > 23) val = '23';
        // we add the separator
        if(val.length === 2 && !val.includes(':')) val = val + ':';

        if(val.length === 3 && !val.includes(':')) val = val.substring(0,2) + ':' + val.substring(2);
      }
      return val;
    }
  },
}
</script>

<style lang="scss">
/* date styles */
.native-datetime-picker {
  position: relative;
  cursor: pointer;
  box-sizing: border-box;
  line-height: normal !important;
  width:0!important;
  border:0!important;
  outline: 0!important;
  padding:0!important;
  margin:0!important;
  opacity:0!important;
  height:0px!important;
  background:transparent!important;

  //box-shadow: 0!important;
}
.theme--dark{
  .native-datetime-picker{
    color-scheme: dark;
  }
}
.native-datetime-picker::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
  color:inherit;
}
</style>