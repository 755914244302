import * as R from 'ramda'
import { isLt, isEq } from '@/helpers/utils/misc'

const CONSECUTIVE = 0
const OPEN = 1
const HOT = 2
const CALM = 3
const HIGH = 4
const DIRECT = 5
const ALTERNATING = 6
const CLOSED = 7
const COLD = 8
const NERVOUS = 9
const LOW = 10
const INDIRECT = 11

const whenLeftTone = R.cond([
  [isEq(1), R.always(CONSECUTIVE)],
  [isEq(2), R.always(OPEN)],
  [isEq(3), R.always(HOT)],
  [isEq(4), R.always(CALM)],
  [isEq(5), R.always(HIGH)],
  [isEq(6), R.always(DIRECT)]
])

const whenRightTone = R.cond([
  [isEq(1), R.always(ALTERNATING)],
  [isEq(2), R.always(CLOSED)],
  [isEq(3), R.always(COLD)],
  [isEq(4), R.always(NERVOUS)],
  [isEq(5), R.always(LOW)],
  [isEq(6), R.always(INDIRECT)]
])

const fromToneAndColor = (tone, color) => R.cond([
  [isLt(4), () => whenLeftTone(color)],
  [R.T, () => whenRightTone(color)]
])(tone)

export default { CONSECUTIVE, OPEN, HOT, CALM, HIGH, DIRECT, ALTERNATING, CLOSED, COLD, NERVOUS, LOW, INDIRECT, fromToneAndColor }
