/* eslint-disable */
import * as R from 'ramda'
import Node from './Node'

const getOrAddNode = R.curry((graph, nodeId) => {
  let node = getNodeById(graph, nodeId)

  if (node == null) {
    node = Node(nodeId)
    graph.nodes.push(node)
  }

  return node
})

const addAssociation = R.curry((graph, node1Id, node2Id) => {
  const n1 = getOrAddNode(graph, node1Id)
  const n2 = getOrAddNode(graph, node2Id)
  n1.addAssociation(node2Id)
  n2.addAssociation(node1Id)

  const addNestedConnections = (node, id) => R.forEach(cid => {
    const tmp = getNodeById(graph, cid)
    tmp.addAssociation(id)
    
    for(const i of node.associations) {
      tmp.addAssociation(i)
    }

    for (const i of tmp.associations) {
      node.addAssociation(i)
    }
  }, node.associations)

  addNestedConnections(n1, node2Id)
  addNestedConnections(n2, node1Id)
})

const getComponents = (graph) => {
  let visited = []
  let components = []

  R.forEach(node => {
    if (R.includes(node.id, visited) === false) {
      let nodesInSet = R.concat([node.id], node.associations)
      components.push(nodesInSet)
      visited = R.concat(visited, nodesInSet)
    }
  })(graph.nodes)

  return components
}

const getNodeById = R.curry((graph, nodeId) => R.find(x => x.id === nodeId, graph.nodes))

const isNodeDefined = R.curry((graph, nodeId) => R.any(x => x.id === nodeId, graph.nodes))

const getAssociations = R.curry((graph, nodeId) => R.pipe(
  getNodeById(graph),
  R.ifElse(
    R.isNil,
    R.always([]),
    R.prop('associations')
  )
)(nodeId))

const Graph = () => {
  const graph = {
    nodes: []
  }

  graph.getAssociations = getAssociations(graph)
  graph.addAssociation = addAssociation(graph)
  graph.isNodeDefined = isNodeDefined(graph)
  graph.getComponents = _ => getComponents(graph)

  return graph
}

export default Graph
