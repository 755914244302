import * as R from 'ramda'
import { isEq } from '@/helpers/utils/misc'

const CAVES = 0
const MARKETS = 1
const KITCHENS = 2
const MOUNTAINS = 3
const VALLEYS = 4
const SHORES = 5

const fromColor = R.cond([
  [isEq(1), R.always(CAVES)],
  [isEq(2), R.always(MARKETS)],
  [isEq(3), R.always(KITCHENS)],
  [isEq(4), R.always(MOUNTAINS)],
  [isEq(5), R.always(VALLEYS)],
  [isEq(6), R.always(SHORES)]
])

export default { CAVES, MARKETS, KITCHENS, MOUNTAINS, VALLEYS, SHORES, fromColor }
