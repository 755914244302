import * as R from 'ramda'
import { mapState } from 'vuex'
import { DataSource, funcs } from './funcs'
import { isNotNullOrEmpty } from '../../../helpers/utils/misc'
import highlighting from './script.highlighting'
import svg from './svg'

const circles = {
  gates: {
    center: {
      x: 1000,
      y: 1000
    },
    radius: 780
  },
  hexagrams: {
    center: {
      x: 975,
      y: 970
    },
    radius: 900
  },
  planets: {
    center: {
      x: 978,
      y: 0
    },
    radius: [
      622,
      574,
      530,
      490,
      454,
      422
    ]
  }
}
import util from "../../../mixins/util";
import activationModes from "../../../helpers/rave/constants/activationModes";
import planetConstants from "../../../helpers/rave/constants/planets";
import ChartUtil from "@/mixins/ChartUtil";
import HighlightingMixin from "@/mixins/HighlightingMixin";

export default {
  name: 'hd-Mandala',
  mixins: [util, ChartUtil, HighlightingMixin],
  props: {
    chart: {
      type: Object,
      default: () => ({})
    },
    domRefId: String,
    interactive: {
      type: Boolean,
      default: false
    },
    title: {
      required: false,
      default: null
    }
  },
  data () {
    return {
      svgItems: svg
    }
  },
  computed: {
    ...mapState({
      tabs: state => state.ui.tabsMapping,
      viewModes: state => state.ui.viewModes,
      viewMode: state => state.ui.viewMode
    }),

    planetList(){
      return this.chart.chart.planets.map(x => {
        x.planet = x.id
        return x;
      });
    },

    planets(){
      if (this.viewMode === this.viewModes.personality) {
        if (this.isConnectionChart(this.chart) || this.isPersonTransitChart(this.chart) || this.isCycleChart(this.chart)) {
          return this.planetList.filter(x => x.chartId === 1);
        }

        return this.planetList.filter(x =>
            (x.activation === activationModes.PERSONALITY || x.activation === activationModes.MIXED)
        );
      }

      if (this.viewMode === this.viewModes.design) {
        if (this.isConnectionChart(this.chart) || this.isPersonTransitChart(this.chart) || this.isCycleChart(this.chart)) {
          return this.planetList.filter(x => x.chartId === 0 );
        }

        return this.planetList.filter(x =>
            (x.activation === activationModes.DESIGN || x.activation === activationModes.MIXED)
        );
      }

      if (this.viewMode === this.viewModes.cross) {
        return this.planetList.filter(x => x.id === planetConstants.EARTH || x.id === planetConstants.SUN)
      }

      return this.planetList
    },

    dataSource () {
      return R.ifElse(
        R.isNil,
        R.always(DataSource.empty),
        R.pipe(
          DataSource.create,
          R.assoc('highlights', R.defaultTo([], this.highlights))
        )
      )(this.planets)
    },
    titleText () {
      return R.cond([
        [R.is(String), R.always(this.title)],
        [R.is(Object), R.prop('title')],
        [R.T, R.always(null)]
      ])(this.title)
    },
    titleCommentText () {
      return R.cond([
        [R.is(Object), R.prop('comment')],
        [R.T, R.always(null)]
      ])(this.title)
    },
    hasTitle () {
      return isNotNullOrEmpty(this.titleText)
    },
    hasTitleComment () {
      return isNotNullOrEmpty(this.titleCommentText)
    },
    senderId () {
      return this.$options.name
    },
    definedGates () {
      return funcs.getDefinedGates(this.dataSource)
    },
    definedZodiacs () {
      return funcs.getDefinedZodiacs(this.definedGates)
    },
    sortedGates () {
      return funcs.getSortedGates(this.definedGates)
    },
    sortedZodiacs () {
      return funcs.getSortedZodiacs(this.definedZodiacs)
    },
    rollOversEnabled(){
      return true;
    }
    // ...mapState({
    //   rollOversEnabled: state => state.ui.chartRollOversEnabled
    // })
  },
  methods: {
    gateCssClasses (id) {
      return funcs.getCssClassesForGate(this.dataSource, id)
    },
    hexCssClasses (id) {
      return funcs.getCssClassesForHexagram(this.dataSource, id)
    },
    zodiacCssClass (id) {
      return funcs.getCssClassesForZodiac(this.dataSource, id)
    },
    gateDefined (id) {
      return funcs.isGateDefined(this.dataSource, id)
    },
    gatePlanetActivations (id) {
      return funcs.getGatePlanetActivations(this.dataSource, id)
    },
    gateNumRimCoords (index) {
      const { center, radius } = circles.gates

      return funcs.getRimCoordinates(center, radius, index)
    },
    planetRimCoords (gateIndex, planetIndex) {
      return funcs.getRimCoordinates(circles.planets.center, circles.planets.radius[planetIndex], gateIndex)
    },
    hexRimCoords (index) {
      const { center, radius } = circles.hexagrams
      return funcs.getRimCoordinates(center, radius, index)
    },
    gateEvents (id) {
      if (this.interactive === false) return null

      return highlighting.getEvents(this, id)
    },
    onMouseEnter (e, highlights) {
      highlighting.fromMouseEvents.apply(this, e, highlights)
    },
    onMouseLeave (e, src) {
      highlighting.fromMouseEvents.clear(this, e, src)
    },
    onElementClicked (src) {
      let tab = this.tabs[src.type];
      let id = src.id.toString();

      this.fixUrlQuery('?tab='+tab + '&selected='+id);
      // add the URL params so that the properties panel show the correct content

      this.$nextTick(()=>{
        this.$store.dispatch('ui/increasePropertiesKey'); // increase key to update window
        this.$store.commit('ui/SET_SHOW_PROPERTIES', true); // show panel in case it's not showing
      });
    }
  },
  mounted () {
    if (this.interactive === true) {
      highlighting.init(this)
    }
  }
}
