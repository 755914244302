export default `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="none" viewBox="0 0 18 18">
  <g clip-path="url(#a)" opacity=".8">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" d="M2.143 9a6.857 6.857 0 1 0 13.715 0A6.857 6.857 0 0 0 2.143 9v0ZM9 1v2.857m0 10.286V17m8-8h-2.857M3.857 9H1"/>
    <path fill="currentColor" d="M8.24 5.32C8.43 5.021 8.83 5 9 5c.169 0 .423 0 .698.32.4.47.126.96-.043 1.259-.17.32-.17.576-.042.746.211.256.443.299.74.342.147.021.21.085.253.192.142.346 1.077 2.56 1.352 3.264a.651.651 0 0 1 .042.213.32.32 0 0 1-.085.192C10.902 12.68 10.184 13 9 13s-1.901-.32-2.915-1.472A.315.315 0 0 1 6 11.336s0-.107.043-.213c.274-.704 1.102-2.679 1.351-3.264.043-.107.106-.171.254-.192.296-.043.528-.086.74-.342.084-.106.125-.297.106-.341-.024-.063-.068-.066-.191-.043-.064 0-.17-.021-.17-.128 0-.106 0-.341-.02-.362-.021-.043-.107-.043-.106-.107 0-.021.148-.299.148-.341 0-.043-.042-.107-.063-.128-.022-.022 0-.32.147-.555Z"/>
  </g>
  <defs>
    <clipPath id="a">
      <path fill="currentColor" d="M0 0h18v18H0z"/>
    </clipPath>
  </defs>
</svg>`