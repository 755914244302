import * as R from 'ramda'
import objects from '../rave/constants/objectTypes'
import { ensureArray } from '../utils/misc'
import {
  getChannelGates,
  getCenterGates,
  getPlanetsOfGate,
  getPlanetsOfLine,
  isGateDefined
} from './queries'

const messages = {
  SET: 'highlights.SET',
  CLEAR: 'highlights.CLEAR'
}

const getPlanetsForGates = R.curry((planets, gates) => {
  return R.pipe(
    ensureArray,
    R.chain(getPlanetsOfGate(planets)),
    R.map(R.pick(["id", "activation", "chartId"]))
  )(gates);
})

const getPlanetsForLines = R.curry((planets, lines) => {
  return R.pipe(
    ensureArray,
    R.chain(getPlanetsOfLine(planets)),
    R.map(R.pick(["id", "activation", "chartId", "gate"]))
  )(lines);
})

const forGateSources = (planets, { id: ids }) => {
  return R.pipe(
    getPlanetsForGates(planets),
    planets => R.assoc(objects.PLANET, planets, {
      [objects.GATE]: ensureArray(ids)
    })
  )(ids);
}

const forLineSources = (planets, { id: ids }) => {
  return R.pipe(
    getPlanetsForLines(planets),
    planets => R.assoc(objects.PLANET, planets, {
      [objects.GATE]: R.pluck("gate", planets)
    })
  )(ids);
}

const forCenterSources = (planets, { id: ids }) => {
  return R.pipe(
    ensureArray,
    R.chain(getCenterGates),
    R.filter(isGateDefined(planets)),
    gates => R.assoc(objects.HEXAGRAM, gates, { // we are using HEXAGRAM instead of gates because that is mandala specific and in channel mode we dont want individual gates
      [objects.CENTER]: ensureArray(ids),
      [objects.GATE]: []
    }),
    activations => R.assoc(objects.PLANET, getPlanetsForGates(planets, activations[objects.HEXAGRAM]), activations)
  )(ids);
}

const forChannelSources = (planets, { id: ids }) => {
  return R.pipe(
    ensureArray,
    R.chain(getChannelGates),
    gates => R.assoc(objects.HEXAGRAM, gates, { // we are using HEXAGRAM instead of gates because that is mandala specific and in channel mode we dont want individual gates
      [objects.CHANNEL]: ensureArray(ids),
      [objects.GATE]: []
    }),
    activations => R.assoc(objects.PLANET, getPlanetsForGates(planets, activations[objects.HEXAGRAM]), activations)
  )(ids);
}

const forPlanetSources = (planets, planetSources) => {
  return R.pipe(
    ensureArray,
    _planets => R.assoc(objects.GATE, R.pluck("gate", _planets), {
      [objects.PLANET]: R.map(R.omit(["type", "gate"]), _planets)
    }),
    activations => R.assoc(objects.PLANET, R.map(R.omit(["type", "gate"]), ensureArray(planetSources)), activations)
  )(planetSources);
}

const highlightsFromEventSource = R.curry((planets, source) => {
  const typeIs = R.propEq('type')
  const getOperation = R.cond([
    [typeIs(objects.GATE), R.always(forGateSources)],
    [typeIs(objects.LINE), R.always(forLineSources)],
    [typeIs(objects.CHANNEL), R.always(forChannelSources)],
    [typeIs(objects.CENTER), R.always(forCenterSources)],
    [typeIs(objects.PLANET), R.always(forPlanetSources)]
  ])

  return R.pipe(
    getOperation,
    op => op(planets, source)
  )(source)
})

const notify = () => {
  // const payload = { targets }

  // const msg = R.assoc('sender', vm.senderId, { type: messages.SET, ...payload });
  // // vm.bus$.send(msg)
}

const clear = vm => vm.bus$.send(R.assoc('sender', vm.senderId, { type: messages.CLEAR }))

// eslint-disable-next-line no-unused-vars
const registerHandlers = (vm, setHandler, clearHandler) => {
  // const ignoreEventsOriginatingHere = R.complement(R.propEq)('sender', vm.senderId)
  // console.log(ignoreEventsOriginatingHere);
  // vm.$subscribeTo(
  //   vm.bus$.createHandler(messages.SET).pipe(rx.filter(ignoreEventsOriginatingHere)),
  //   setHandler(vm)
  // )
  //
  // vm.$subscribeTo(
  //   vm.bus$.createHandler(messages.CLEAR).pipe(rx.filter(ignoreEventsOriginatingHere)),
  //   clearHandler(vm)
  // )
}

const addClassToElementsByClass = (elemClass, cssClass) => {
  const collection = document.getElementsByClassName(elemClass)
  for (let i = 0; i < collection.length; i++) {
    collection[i].classList.add(cssClass)
  }
}

const removeClassToElementsByClass = (elemClass, cssClass) => {
  const collection = document.getElementsByClassName(elemClass)
  for (let i = 0; i < collection.length; i++) {
    collection[i].classList.remove(cssClass)
  }
}

export default {
  getTargets: highlightsFromEventSource,
  registerHandlers,
  broadcast: {
    newTargets: notify,
    clear
  },
  addClassToElementsByClass,
  removeClassToElementsByClass
}
