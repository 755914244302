
import * as R from 'ramda'
import { ensureArray, dotPath, isNotNullOrEmpty } from '@/helpers/utils/misc'
import objects from '@/helpers/rave/constants/objectTypes'
import centers from '@/helpers/rave/constants/centers'
import CHANNELS from '@/helpers/rave/constants/channels'
import highlighting from '@/helpers/rave/highlighting'

const applyClass = R.curry((cls, vm, elemId) => R.pipe(
  id => vm.$el.getElementById(id),
  R.unless(
    R.isNil,
    R.tap(el => el.classList.add(cls))
  )
)(elemId))

const applyHighlightClass = applyClass('highlight')

const getHighlightTargetNodeClassList = (nativeEvent, src) => {
  const target = src.type === 'channel' ? nativeEvent.target.ownerSVGElement.getElementById(`c${src.renderElemId}`) : nativeEvent.target.parentNode;
  return target?.classList;
}

const applyForMouseEvents = (vm, nativeEvent, highlight) => {
  const targets = [getHighlightTargetNodeClassList(nativeEvent, highlight.source)];

  if (highlight.targets && highlight.targets.planet && highlight.targets.planet.length > 0) {
    highlight.targets.planet.forEach(x => {
      const planet = vm.chart.chart.planets.find(y => y.chartId === x.chartId && y.id === x.id && x.activation === y.activation);

      // special case connection/cycle charts, as the activation side does not matter, we only care about chartId
      // since we do not do specific targetting.
      const activation = vm.isConnectionChart(vm.chart) || vm.isCycleChart(vm.chart) || vm.isPersonTransitChart(vm.chart) ? x.chartId : x.activation;
      vm.planetHighlight(x.id, activation, [planet.gate], x.chartId)
    });
  }
  for (const target of targets) {
    target?.add('highlight')
  }
}

const clearForMouseEvents = (vm) => {
  vm.clearAllHighlights();
}

const highlightArbitraryGates = R.curry((vm, gates) => {
  return R.pipe(
    ensureArray,
    R.unless(
      R.isEmpty,
      R.forEach(id => applyHighlightClass(vm, `g${id}`))
    )
  )(gates)
})

const highlightArbitraryCenters = R.curry((vm, centerIds) => {
  return R.pipe(
    ensureArray,
    R.unless(
      R.isEmpty,
      R.pipe(
        R.map(id => centers.getById(id).name.toLowerCase()),
        R.forEach(id => applyHighlightClass(vm, id))
      )
    )
  )(centerIds)
})

const highlightArbitraryChannels = R.curry((vm, channels) => {
  const markHighlightedChannelGatesAsHighContrast = channelId => R.pipe(
    R.nth(R.__, CHANNELS),
    R.prop('gates'),
    R.forEach(id => {
      applyClass('high-contrast', vm, `g${id}`)
    })
  )(channelId)

  return R.pipe(
    ensureArray,
    R.unless(
      R.isEmpty,
      R.forEach(id => {
        applyHighlightClass(vm, `c${id}`)
        markHighlightedChannelGatesAsHighContrast(id)
      })
    )
  )(channels)
})

const applyArbitraryHighlights = vm => R.pipe(
  R.tap(
      () => vm.$refs.bodyGraph.classList.add('high-contrast-highlighting')
  ),
  R.tap(R.pipe(
    dotPath(`targets.${objects.GATE}`),
    R.when(
      isNotNullOrEmpty,
      highlightArbitraryGates(vm)
    )
  )),
  R.tap(R.pipe(
    dotPath(`targets.${objects.CENTER}`),
    R.when(
      isNotNullOrEmpty,
      highlightArbitraryCenters(vm)
    )
  )),
  R.tap(R.pipe(
    dotPath(`targets.${objects.CHANNEL}`),
    R.when(
      isNotNullOrEmpty,
      highlightArbitraryChannels(vm)
    )
  ))
)

const clearArbitraryHighlights = (vm) => () => {
  R.forEach(
    x => x.classList.remove('highlight')
  )(vm.$el.querySelectorAll('.highlight'))

  R.forEach(
    x => x.classList.remove('high-contrast')
  )(vm.$el.querySelectorAll('.high-contrast'))

  vm.$refs.bodyGraph.classList.remove('high-contrast-highlighting')
}

const getEvents = (vm, source) => {
  const clickEvent = {
    click: () => vm.onElementClicked(source)
  }

  // this function is lazy so we only calculate
  // the highlighting targets if roll over events are actually enabled
  const hoverEvents = () => {
    const evt = {
      source,
      targets: highlighting.getTargets(vm.planets, source)
    }

    return {
      mouseover: (e) => vm.onMouseEnter(e, evt),
      mouseout: (e) => vm.onMouseLeave(e, source)
    }
  }

  const events = R.ifElse(
    R.equals(true),
    R.converge(R.mergeRight, [R.always(clickEvent), hoverEvents]), // see comment above hover events
    R.always(clickEvent)
  )

  return events(vm.rollOversEnabled)
}

const registerHandlers = vm => highlighting.registerHandlers(vm, applyArbitraryHighlights, clearArbitraryHighlights)

/*
  We are handling mouse events differently from arbitrary highlight events
  becuase when dealing with mouse events we already have a DOM node reference and dont
  need to query the DOM the way we do with arbitrary requests, resulting in better perf
  for that code path
*/

export default {
  fromMouseEvents: {
    apply: applyForMouseEvents,
    clear: clearForMouseEvents
  },
  getEvents,
  init: registerHandlers
}
