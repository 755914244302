<template>
  <div>
    <div v-if="!selectedLine" class="d-flex align-items-center gap-10 mb-2">
      <b-button class="bg-transparent border-0 sidebar-toggle px-0 ease-in-out duration-300" variant="link"
                @click="$emit('close')"
      >
        <span class="icon-arrow-left"></span>
      </b-button>
    </div>

    <div v-if="!selectedLine" class="d-flex align-items-center gap-10 text-1xl mb-4">
      <div v-html="getIchingIcon(gate.gate_id)"/>
      <h3 class="mb-0">
        {{ gate.gate_id }}. {{ gate.name }} - {{ gate.title_keynote }}</h3>
    </div>

    <h1 class="mbg-text-muted text-2xl font-weight-light mb-4">
      {{ gate.keynote }}
    </h1>

    <div :class="selectedLine ? '' : 'px-3'">
      <div v-for="index in selectedLines" :key="index" class="mb-2">
        <h5 class="mb-3 line-title">{{ $t('hd.properties.lines.' + index) }} - {{ getLine(index).name }}</h5>

        <p v-if="getLine(index).state === 'LOCKED'" class="">
          <span class="icon-lock"/> {{$t('general.unlockIching')}}
        </p>

        <p v-if="getLine(index).description" class="font-500 iching-blue">
          {{getLine(index).description}}
        </p>

        <div v-if="getLine(index).exalted_description" class="d-flex gap-10"  :class="selectedLine && (!isExalted && isDetriment) ? 'opacity-50' : ''">

          <div class="d-flex gap-5 mt-1">
            <span :class="`icon-${getFixingPlanet(index, true)}`"/>
            <svg class="flex-shrink-0" fill="none" height="11" viewBox="0 0 14 11" width="14"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M1.83747 9.75L7 1.44693L12.1625 9.75L1.83747 9.75Z" fill="currentColor"/>
              <path d="M1.83747 9.75L7 1.44693L12.1625 9.75L1.83747 9.75Z" fill="currentColor"/>
              <path d="M1.83747 9.75L7 1.44693L12.1625 9.75L1.83747 9.75Z" fill="currentColor"/>
              <path d="M1.83747 9.75L7 1.44693L12.1625 9.75L1.83747 9.75Z" stroke="currentColor"/>
              <path d="M1.83747 9.75L7 1.44693L12.1625 9.75L1.83747 9.75Z" stroke="currentColor"/>
              <path d="M1.83747 9.75L7 1.44693L12.1625 9.75L1.83747 9.75Z" stroke="currentColor"/>
            </svg>
          </div>

          <div class="mb-0" v-html="getLine(index).exalted_description"/>
        </div>
        <div v-if="getLine(index).detriment_description  " class="d-flex gap-10"  :class="selectedLine && (!isDetriment && isExalted) ? 'opacity-50' : ''">
          <div class="d-flex gap-5 mt-1">
            <span :class="`icon-${getFixingPlanet(index, false)} mb-0`"/>
            <span class="icon-detriment"></span>
          </div>

          <div class="mb-0" v-html="getLine(index).detriment_description"/>
        </div>
      </div>
    </div>

    <div v-if="showPagination" class="text-right">
      <b-button v-if="gate?.gate_id > 0" @click="$emit('paginate', gate?.gate_id - 1)">Prev</b-button>
      <b-button v-if="gate?.gate_id < 64" @click="$emit('paginate', gate?.gate_id + 1)">Next <span class=""></span></b-button>
    </div>
  </div>
</template>

<script>
import {getIchingIcon} from "../../../../utils/iching";
import fixings from "../../../../helpers/rave/constants/fixings";
import planets from "../../../../helpers/rave/constants/planets";

export default {
  props: {
    gate: Object,
    lines: Object,
    selectedLine: Number,
    isExalted: Boolean,
    isDetriment: Boolean,
    showPagination: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    selectedLines() {
      return this.selectedLine ? [this.selectedLine] : this.lineSort
    }
  },
  data() {
    return {
      lineSort: [6, 5, 4, 3, 2, 1],
    }
  },
  methods: {
    getIchingIcon,

    getLine(index){
      try{
        return this.lines[`${this.gate.gate_id}.${index}`] || {}
      }catch(e){
        return {}
      }
    },

    getFixingPlanet(index, exalted = false) {
      return planets.icons[Number(fixings.getFixingPlanets(this.gate.gate_id, index)[exalted ? 'exalted' : 'detriment'])];
    },
  },
}
</script>
