// Mixin that works with `SubscriptionPurchase.vue` and `SinglePurchase.vue`
// to provide code re-usability across them & avoid mistakes as it's tied to
// pricing.
export default{

    computed:{
        cartDiscounts(){
            if(!this.couponObject) return 0;

            // fixed price
            if(this.couponObject.type === 0) {
                return (!this.couponObject.products || this.couponObject.products?.some(x => Object.values(this.cartVariations).map(x => x.key).includes(x))) ? this.couponObject.amount : 0;
            }

            return Object.values(this.cartVariations).map(x => +x.quantity * +x.discountAmount).reduce((partialSum, a) => partialSum + a, 0);
        },
        cartSubTotal() {
            return this.cart_items.map(x => x.price).reduce((partialSum, a) => partialSum + a, 0);
        },
        cartTotal(){
            const total = Object.values(this.cartVariations).map(x => +x.quantity * +x.discountedPrice).reduce((partialSum, a) => partialSum + a, 0)
            if(this.couponObject && this.couponObject.type === 0) {
                return total - this.cartDiscounts;
            }
            return total;
        },
    },

    methods: {
        getDiscountForProduct(item) {
            const discount = {
                discount: 0,
                discountAmount: 0,
                discountedPrice: item.price
            }

            const hasDiscount = this.couponObject && (!this.couponObject.products || this.couponObject.products.includes(item.key));
            if (!hasDiscount) return {...discount};

            if(this.couponObject.coupon?.percent_off) {
                discount.discount = this.couponObject.coupon.percent_off;
                discount.discountAmount = item.price * (discount.discount / 100)
                discount.discountedPrice = (item.price * ((100 - discount.discount) / 100)).toFixed(2);

                return {...discount};
            }
            // Special case for percentage discounts on single chart unlocks!
            if (this.couponObject.checkout_type === 1 && this.couponObject.type === 1) {
                discount.discount = this.couponObject.amount;
                discount.discountAmount = item.price * (discount.discount / 100)
                discount.discountedPrice = (item.price * ((100 - discount.discount) / 100)).toFixed(2);
            }
            return {...discount}
        },
    }
}