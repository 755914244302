<template>

  <div id="summary">

    <b-card>
      <b-tabs class="tabs-primary-underlined summary-tabs mb-0" lazy>
        <b-tab title="Summary" class="mb-0">
          <personal-chart-summary v-if="chart && isPersonalChart(chart)" :chart="chart" show-advanced/>

          <connection-chart-summary v-if="chart && isConnectionChart(chart)" :chart="chart" />

          <transit-chart-summary v-if="chart && isTransitChart(chart)" :chart="chart" />

          <cycle-chart-summary v-if="chart && isCycleChart(chart)" :chart="chart" />
        </b-tab>
        <b-tab title="Statistics">

          <statistics :chart="chart"/>

        </b-tab>

      </b-tabs>
    </b-card>



    <div v-if="!hideKeynotesAndQuestions" class="row px-lg-4 px-md-3 mt-4">

      <div class="col-12">
        <h4 class="mb-4">{{$t('charts.properties.notSelfKeynotes')}}</h4>

        <div v-for="keynote of keynotes" :key="keynote.keynote" class="d-flex align-items-center my-2">
          <div class="mr-3">
            <span :class="`icon-center-` + keynote.center.icon"></span>
          </div>
          <div>
            <label class="font-weight-light" style="opacity:0.7">{{ $t('hd.centers.'+ keynote.center.id + '.name') }}</label>
            <h6 class="font-500">{{keynote.keynote}}</h6>
          </div>
        </div>
      </div>

    </div>

    <div v-if="!hideKeynotesAndQuestions" class="row px-lg-4 px-md-3 mt-4 mb-2">

      <div class="col-12">
        <h4 class="mb-4">{{$t('charts.properties.notSelfQuestions')}}</h4>

        <div v-for="question of questions" :key="question.question" class="d-flex align-items-center my-2">
          <div class="mr-3">
            <span :class="`icon-center-` + question.center.icon"></span>
          </div>
          <div>
            <label class="font-weight-light" style="opacity:0.7">{{ $t('hd.centers.'+ question.center.id + '.name') }}</label>
            <h6 class="font-500">{{question.question}}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import centers from "../../../helpers/rave/constants/centers";
import ChartUtil from "../../../mixins/ChartUtil";
import PersonalChartSummary from "./summary/PersonalChartSummary.vue";
import ConnectionChartSummary from "./summary/ConnectionChartSummary.vue";
import TransitChartSummary from "./summary/TransitChartSummary.vue";
import CycleChartSummary from "./summary/CycleChartSummary.vue";
import Statistics from "./statistics.vue";

export default{
  components: {
    Statistics, CycleChartSummary, TransitChartSummary, ConnectionChartSummary, PersonalChartSummary},
  mixins: [ChartUtil],
  props:{
    hideKeynotesAndQuestions:{
      type: Boolean,
      default: true
    },
    chart: {
      type: Object,
      default: () => {}
    }
  },
  computed:{

    openOrCompletelyOpenCenters(){
      let nonDefinedCentersList = [];
      for(const [index, value] of this.chart.chart.centers.entries()){

        if(value !== centers.centerDefinitionStates.DEFINED) nonDefinedCentersList.push(index)

      }

      return nonDefinedCentersList;
    },
  },
  created(){
    this.prepareData();
  },
  data(){
    return{
      questions: [],
      keynotes: [],

    }
  },

  methods:{
    prepareData(){
      this.questions = [];
      this.keynotes = [];
      // if(this.hideKeynotesAndQuestions) return;
      //
      // this.axios.get('/content/centers/keynotes-questions', {params: {centers: JSON.stringify(this.openOrCompletelyOpenCenters)}}).then(res=>{
      //   for(let question of res.data){
      //     let center = centers.getById(question.id);
      //
      //     this.questions.push({center: center, question: question.question});
      //     this.keynotes.push({center: center, keynote: question.keynote});
      //   }
      // }).catch(e=>{
      //   console.log(e);
      // })
    },
  }

}
</script>
<style>
.summary-tabs .nav-tabs{
  margin-bottom:15px;
}
</style>