import * as R from 'ramda'
import { mapIndexed } from '../utils/misc'
import fixings from './constants/fixings'
import channels from './constants/channels'
import { getPlanetsOfGate } from './queries'
import { getWhereNestedCollectionIncludesAny } from '../utils/list'

let getHarmonicGates = R.pipe(
  getWhereNestedCollectionIncludesAny(channels, 'gates'),
  R.chain(R.prop('gates'))
)

const getFixingCandidatePlanets = R.curry((planets, gate) => {
  return R.pipe(
    getHarmonicGates,
    R.concat([gate]),
    R.uniq,
    R.chain(getPlanetsOfGate(planets))
  )(gate)
})

const createFixingRecord = (state, { activation, gate, line, chartId = 0, id: planet }) => ({
  state,
  planet,
  activation,
  gate,
  line,
  chartId
})

const getFixingTriggers = ({ gate, line }) => fixings.getFixingPlanets(gate, line)

const collectPlanetFixingEffects = R.curry((fixingSpecs, planet) => {
  const evaluatePlanet = (fixingState, planetId) => R.cond([
    [R.propEq('id', planetId), () => [createFixingRecord(fixingState, planet)]],
    [R.T, R.always([])]
  ])

  return R.converge(
    R.concat,
    [
      evaluatePlanet(fixings.EXALTED, fixingSpecs.exalted),
      evaluatePlanet(fixings.DETRIMENT, fixingSpecs.detriment)
    ]
  )(planet)
})

const getAggregateFixingAffects = R.curry((planets, planet) => {
  const collectFixingRecords = R.pipe(
    getFixingTriggers,
    collectPlanetFixingEffects
  )(planet)

  const candidatePlanets = R.pipe(
    R.prop('gate'),
    getFixingCandidatePlanets(planets)
  )(planet)

  return R.chain(collectFixingRecords, candidatePlanets)
})

const getFixingState = R.pipe(
  R.path(['fixing', 'triggers']),
  R.chain(R.prop('state')),
  R.uniq,
  R.cond([
    [x => R.gt(x.length, 1), R.always(fixings.JUXTAPOSED)],
    [R.isEmpty, R.always(fixings.NONE)],
    [R.T, R.head]
  ])
)

const isFixingConditioned = planet => {
  const getTriggerChartIds = R.pipe(
    R.path(['fixing', 'triggers']),
    R.chain(R.prop('chartId')),
    R.uniq
  )

  const hasMoreThanOneChartIdsInvolved = x => R.gt(x.length, 1)

  const isDifferentChartId = x => R.not(R.equals(R.head(x), planet.chartId))

  const isConditioned = R.cond([
    [R.isEmpty, R.always(false)],
    [hasMoreThanOneChartIdsInvolved, R.always(true)],
    [isDifferentChartId, R.always(true)],
    [R.T, R.always(false)]
  ])

  return R.pipe(
    getTriggerChartIds,
    isConditioned
  )(planet)
}

const setFixingTriggers = R.curry((planets, planet) => R.chain(R.assocPath(['fixing', 'triggers']), getAggregateFixingAffects(planets))(planet))

const setFixingState = R.chain(R.assocPath(['fixing', 'state']), getFixingState)

const setFixingConditioned = R.chain(R.assocPath(['fixing', 'conditioned']), isFixingConditioned)

const calcPlanetFixing = (planet, _, allPlanets) => R.pipe(
  setFixingTriggers(allPlanets),
  setFixingState,
  setFixingConditioned,
  R.dissoc('fx')
)(planet)

const calculateFixings = (planets) => mapIndexed(calcPlanetFixing, planets)

export {
  calculateFixings
}
