import * as R from 'ramda'
import { isEq } from '@/helpers/utils/misc'

const FEAR = 0
const HOPE = 1
const DESIRE = 2
const NEED = 3
const GUILT = 4
const INNOCENCE = 5

const fromColor = R.cond([
  [isEq(1), R.always(FEAR)],
  [isEq(2), R.always(HOPE)],
  [isEq(3), R.always(DESIRE)],
  [isEq(4), R.always(NEED)],
  [isEq(5), R.always(GUILT)],
  [isEq(6), R.always(INNOCENCE)]
])

const transferenceFromColor = R.cond([
  [isEq(1), R.always(NEED)],
  [isEq(2), R.always(GUILT)],
  [isEq(3), R.always(INNOCENCE)],
  [isEq(4), R.always(FEAR)],
  [isEq(5), R.always(HOPE)],
  [isEq(6), R.always(DESIRE)]
])

export default { FEAR, HOPE, DESIRE, NEED, GUILT, INNOCENCE, fromColor, transferenceFromColor }
