export default {
  changes: 'Changes:',
  scoring: {
    '100': {
      title: 'High',
      desc: 'This chart remains consistent even if the birth time is inaccurate by up to 4 hours.'
    },
    '80': {
      title: 'High',
      desc: 'This chart remains consistent even if the birth time is inaccurate by up to 2 hours.'
    },
    '60': {
      title: 'Medium',
      desc: 'This chart would change significantly if your birth time is inaccurate by more than an hour.'
    },
    '40': {
      title: 'Medium',
      desc: 'This chart would change significantly if your birth time is inaccurate by more than 30 minutes.'
    },
    '20': {
      title: 'Low',
      desc: 'This chart changes significantly within a very short period of time.'
    }
  }
}
