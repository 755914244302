<template>
  <div id="center-content" class="px-0" :class="showTitle ? 'mt-2' : 'mt-2'">
    <div v-if="content" class="mb-4">
      <div>
        <p v-if="content.themes" class="mbg-text-muted mb-0">{{$t('hd.properties.themes')}}</p>
        <p>{{content.themes}}</p>
      </div>
      <div v-if="content.not_self_theme && (isOpen || isCompletelyOpen)">
        <p class="mbg-text-muted mb-0">{{$t('hd.properties.notSelfTheme')}}</p>
        <p>{{content.not_self_theme}}</p>
      </div>

      <div v-if="content.not_self_question && (isOpen || isCompletelyOpen)">
        <p class="mbg-text-muted mb-0">{{$t('hd.properties.notSelfQuestion')}}</p>
        <p>{{content.not_self_question}}</p>
      </div>

      <div v-if="content.description" v-html="content.description" />

      <div v-if="content.audio" class="mb-4">
        <audio-player v-if="!loading" :key="centerId" :file-url="content.audio" :align-left="true" />
      </div>
      <div v-if="isPersonalChart(chart)" class="grid grid-cols-1 gap-5">
        <mbg-accordion-item v-if="content.content.not_self_talk"
                            :text="$t('charts.properties.notSelfTalk')"
                            :reverse-background="reverseBackground"
                            :content="content.content.not_self_talk"/>

        <mbg-accordion-item v-if="content.content.analysis" :text="$t('charts.properties.analysis')"
                            :reverse-background="reverseBackground"
                            :content="content.content.analysis"/>

        <mbg-accordion-item v-if="content.content.healthy_vs_unhealthy" :text="$t('charts.properties.healthyVsUnhealthy')"
                            :reverse-background="reverseBackground"
                            :content="content.content.healthy_vs_unhealthy"/>
        <mbg-accordion-item v-if="content.completely_open && isCompletelyOpen" :text="$t('charts.properties.completelyOpen')"
                            :reverse-background="reverseBackground" :is-locked="content.completely_open === 'LOCKED'">
          <dynamic-zone-renderer v-if="completelyOpenContent" :components="completelyOpenContent.components" :add-paddings="false"/>
          <div v-html="content.completely_open"></div>
        </mbg-accordion-item>
      </div>
      <div v-else>
        <mbg-accordion-item v-if="overviewContent" :text="$t('charts.properties.overview')"
                            :reverse-background="reverseBackground"
                            :content="`<p>${overviewContent}</p>`"/>
      </div>
    </div>

  </div>

</template>
<style lang="scss">
#center-content{
  .tabs-primary-underlined{
    .nav-item{
      width:auto!important;
    }
  }
}
</style>

<script>
import AudioPlayer from "../../../structure/AudioPlayer";
import centerConstants from "../../../../helpers/rave/constants/centers";
import {mapState} from "vuex";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer.vue";
import ChartUtil from "../../../../mixins/ChartUtil";
import MbgAccordionItem from "../../../elements/MbgAccordionItem.vue";
export default{

  mixins: [AnalysisQueryMixin, ChartUtil],
  props:{
    centerId: Number,
    state: Number,
    loading: Boolean,
    type: String,
    showTitle:{
      type: Boolean,
      default: false
    },
    reverseBackground: Boolean
  },
  components:{MbgAccordionItem, DynamicZoneRenderer, AudioPlayer},
  data(){
    return{
      content: null,
      completelyOpenContent: null,
      contentOverview: null,
    }
  },
  computed:{
    ...mapState({
      centers: state => state.charts.chart.chart.centers,
      chart: state => state.charts.chart
    }),
    isCompletelyOpen(){
      return this.centers[this.centerId] === centerConstants.centerDefinitionStates.EMPTY
    },
    isOpen(){
      return this.centers[this.centerId] === centerConstants.centerDefinitionStates.UNDEFINED
    },
    isDefined(){
      return this.centers[this.centerId] === centerConstants.centerDefinitionStates.DEFINED
    },

    slug(){
      return centerConstants.getById(this.centerId).slug
    },
    overviewContent(){
      return this.contentOverview?.find(x => x.slug === this.slug)?.content || '';
    }

  },
  watch:{
    centerId(val){
      if(val) this.init();
    }
  },

  created(){
    this.init();
  },
  methods:{
    init(){
      this.getBaseContent();
      if(this.isCompletelyOpen) this.getCompletelyOpenAnalysis();
      this.getOverviewCenterContent();
    },
    getBaseContent(){
      this.$emit('loading', true);
      this.getContent('content',`/centers/${this.centerId}.${this.isDefined ? 'defined' : 'open'}`);
    },
    getCompletelyOpenAnalysis(){
      this.getContent('completelyOpenContent', '/analysis/introductions/introduction.completely-open-centers');
    },

    // Only needed for connection/composite charts
    getOverviewCenterContent() {
      if (this.isPersonalChart(this.chart)) return;
      this.axios.get('/content/dictionary/centers').then(res => {
        this.contentOverview = res.data;
      }).catch(()=>{});
    }
  }

}
</script>