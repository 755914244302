export default {
  tag: 'Tag',
  tags: 'Tags',
  type: 'Type',
  types: 'Types',
  profile: 'Profile',
  profiles: 'Profiles',
  authority: 'Authority',
  authorities: 'Authorities',
  innerAuthority: 'Authority',
  aura: 'Aura+',
  extra: 'Extra',
  introduction: 'Introduction',
  definition: 'Definition',
  compositeDefinition: 'Composite Definition',
  definitions: 'Definitions',
  signature: 'Signature',
  strategy: 'Strategy',
  notSelfTheme: 'Not-Self Theme',
  notSelfQuestion: 'Not-Self Question',
  notSelfThemes: 'Not-Self Themes',
  notSelfQuestions: 'Not-Self Questions',
  theme: 'Theme',
  themes: 'Themes',
  biologicalAssociation: 'Biological association',
  fixing: 'Fixing',
  fixingPlanet: 'Fixing Planet',
  fixingPlanets: 'Fixing planets',
  conditionedBy: 'Conditioned by',
  fixingConditionedBy: 'Fixing conditioned by',
  digestion: 'Dietary Regimen',
  variable: 'Variable',
  perspective: 'Perspective',
  cross: 'Incarnation Cross',
  crosses: 'Incarnation Crosses',
  nodalPolarity: 'Nodal Polarity',
  nodalPolarities: 'Nodal Polarities',
  quarter: 'Quarter',
  determination: 'Determination',
  cognition: 'Cognition',
  environment: 'Environment',
  motivation: 'Motivation',
  sense: 'Sense',
  view: 'View',
  transference: 'Transference',
  defined: 'Defined',
  undefined: 'Undefined',
  centers: 'Centers',
  channels: 'Channels',
  gates: 'Gates',
  definedChannels: 'Defined Channels',
  compositeChannelActivation: '{{ state }} channel activation',
  baseOrientation: {
    design: 'Design Base Orientation',
    personality: 'Personality Base Orientation'
  },
  internal: 'Internal',
  external: 'External',
  personality: 'Personality',
  design: 'Design',
  internalTheme: 'Internal Theme',
  trajectory: 'Trajectory',
  binary: 'Binary',
  externalTheme: 'External Theme',
  nodalToneFixing: 'Nodal Tone Fixing',
  group: 'Group',
  colorTheme: 'Color Theme',
  strategic: 'Strategic',
  receptive: 'Receptive',
  active: 'Active',
  passive: 'Passive',
  focused: 'Focused',
  peripheral: 'Peripheral',
  observed: 'Observed',
  observer: 'Observer',
  landscape: 'Landscape',
  hardscape: 'Hardscape',
  rigid: 'Rigid',
  energetic: 'Energetic',
  sensitive: 'Sensitive',
  connections: {
    theme: 'Connection theme'
  },
  penta: {
    theme: 'Penta theme',
    numofmembers: 'Number of members',
    members: 'Members: ',
    editpentamembers: 'Change members of Penta',
    addmember: 'Add member',
    raveproperties: 'Rave properties',
    pentamemberage: 'Age',
    pentamemberyearsold: '{{age}} years old'

  },
  cycles: {
    date: {
      utc: 'Cycle date (UTC)',
      design: 'Cycle Design date (UTC)'
    },
    cross: 'Cycle cross',
    title: 'Cycle',
    theTitle: 'the Cycle'
  },
  transit: {
    date: {
      utc: 'Transit date (UTC)',
      local: 'Transit date (local)'
    },
    title: 'Transit',
    theTitle: 'the Transit'
  },
  lines: {
    '1': '1st Line',
    '2': '2nd Line',
    '3': '3rd Line',
    '4': '4th Line',
    '5': '5th Line',
    '6': '6th Line',
    activations: '{{num}} activations'
  },
  linesPlural: {
    '1': '1st lines',
    '2': '2nd lines',
    '3': '3rd lines',
    '4': '4th lines',
    '5': '5th lines',
    '6': '6th lines',
    in: 'in'
  },  lineActivationsCount: 'Activations by line',
  planetActivations: 'Planet activations',
  advancedImagingKeynotes: 'Advanced Imaging keynotes',
  integrationChannels: 'Integration Channels',
  advancedProperties: 'Advanced Properties',
  dietaryRegimen: 'Dietary Regimen',
  orientation: 'Orientation',
}
