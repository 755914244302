export default {
    connection:{
        title: 'Partnership Analysis | Understand Your Relationships',
        content: 'Combine any two charts in your library to create a Connection Chart and explore the Partnership Analysis content to gain deeper insights into your relationships.',
        button: 'Create Connection Chart'
    },
    transit: {
        title: 'Just Now  | Explore the Transits',
        content: 'The Just Now Chart serves as a tool for understanding the present moment in the context of Human Design—and by connecting it with your personal chart, you can gain insights into how the current planetary forces are interacting with your unique design.',
        button: 'Explore the Transits'
    },
    knowledge: {
        title: 'Knowledge',
        content: 'Our free knowledge base will enhance your understanding of the Human Design System; the BodyGraph, or Chart, and its components; Ra Uru Hu, the founder; and everything myBodyGraph has to offer.',
        button: 'Access Knowledge'
    },
    dictionary: {
        title: 'Dictionary',
        content: 'Presented in Ra Uru Hu\'s original phrasing, and delicately edited for enhanced comprehension, our Human Design dictionary houses comprehensive definitions for all Human Design terminologies.',
        button: 'Open Dictionary'
    },
    iching: {
        title: 'Rave I’Ching',
        content: 'In 1989, after his encounter with the Voice, Ra Uru Hu wrote the Rave I’Ching—the foundation upon which Human Design rests and the key to unlocking the code of our genetics.',
        button: 'Read Ra’s I’Ching'
    },
    understandCta: {
        title: 'Understand Your Human Design Charts',
        content: 'Learn about your personal and relationship charts directly from Ra Uru Hu\'s teachings, through recordings and text. Our interactive Human Design analysis service delivers source knowledge directly to you. Click below to learn more about the different types of analysis.',
        buttons: {
            personal:{
                subtitle: 'Personal Chart',
                title: 'Foundational Analysis'
            },
            advanced: {
                subtitle: 'Personal Chart',
                title: 'Advanced Analysis'
            },
            connection:{
                subtitle: 'Connection Chart',
                title: 'Partnership Analysis'
            }
        }
    }

}