<template>

  <div class="d-flex align-items-center flex-wrap text-center w-100 overflow-x-auto ">
    <div class="mr-3 font-500 ">
      <p class="text-4xl text-md-6xl mb-1">
        <span v-if="showGateAndLine">{{current['gate'] + '.' + current['line']}}</span>
        <span v-else>{{current[type]}}</span>
      </p>
      <p>{{ $t('charts.transit.current') }}</p>
    </div>
    <div class="mx-4 pr-3">
      <svg width="8" height="15" viewBox="0 0 8 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 13.5L7 7.5L0.999999 1.5" stroke="#191C23" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 13.5L7 7.5L0.999999 1.5" stroke="#2F2F2F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 13.5L7 7.5L0.999999 1.5" stroke="#434343" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="mr-5 opacity-50 cursor-pointer" @click="$emit('change', next.datetime)">
      <p class="text-5xl text-md-6xl mb-1">
        <span v-if="showGateAndLine">{{next['gate'] + '.' + next['line']}}</span>
        <span v-else>{{next[type]}}</span>
      </p>
      <p>{{ $t('charts.transit.next') }}</p>
    </div>
    <div class="mr-3 opacity-50 cursor-pointer"  @click="$emit('change', prev.datetime)">
      <p class="text-5xl text-md-6xl mb-1 ">
        <span v-if="showGateAndLine">{{prev['gate'] + '.' + prev['line']}}</span>
        <span v-else>{{prev[type]}}</span>
      </p>
      <p>{{ $t('charts.transit.previous') }}</p>
    </div>
  </div>

</template>

<script>
export default{
  props:{
    type: {
      type: String,
      default: 'gate'
    },
    current: Object,
    next: Object,
    prev: Object,
    showGateAndLine:{
      type: Boolean,
      default: false
    }
  }
}
</script>