<template>
  <g>
    <text v-if="index === 0" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9a6;</text>
    <text v-if="index === 1" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9b2;</text>
    <text v-if="index === 2" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9aa;</text>
    <text v-if="index === 3" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9ac;</text>
    <text v-if="index === 4" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9af;</text>
    <text v-if="index === 5" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9a9;</text>
    <text v-if="index === 6" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9b1;</text>
    <text v-if="index === 7" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9a8;</text>
    <text v-if="index === 8" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9a7;</text>
    <text v-if="index === 9" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9ae;</text>
    <text v-if="index === 10" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9b0;</text>
    <text v-if="index === 11" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9ab;</text>
    <text v-if="index === 12" :x="x" :y="y" :font-size="fontSize" class="planet-table-icon-renderer" font-family="MyBodyGraph" fill="currentColor">&#xe9ad;</text>
  </g>
</template>

<script>
export default{
  props:{
    index: Number,
    x: String,
    y: String,
    fontSize: String
  }
}
</script>