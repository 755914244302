import channels from "./channels";
import centers from "./centers";
import CenterDefinitionStates from "./centerDefinitionStates";
import {computeChart} from "../api";

export const TRANSCENDENT_GATES = [10, 15, 25, 46];
export const PERSONAL_GATES = [10, 28, 40, 41, 44, 55, 58];



const checkForElectromagnetic = (gateId, otherChart) => {
    let electromagneticGate = channels.find(x => x.gates.includes(gateId)).gates.filter(x => x !== gateId)[0];
    return !!otherChart.find(x => x.gate === electromagneticGate)
}

const chartHasChannelInLove = (gate, chart) => {
    let otherGate = channels.find(x => x.gates.includes(gate)).gates.filter(x => x !== gate)[0];
    return !!chart.find(x => x.gate === gate) && !!chart.find(x => x.gate === otherGate);
}

// check if the other chart shares the same love gate.
const checkForSameGate = (gateId, otherChart) => {
    return !!otherChart.find(x=> x.gate === gateId);
}

// for each of the love gates, we check if the gate's channel moved from open to defined.
// We also check the initial state, to display the appropriate result (defined / undefined)
const checkCenterStatus = (gateId, chart, compositeChart) => {
    let attachedCenterId = Object.values(centers.centersList).find(x => x.gates.includes(gateId)).id;

    // checks if the center was defined on the personal chart.
    const isDefinedOnPersonalChart = chart.centers[attachedCenterId] === CenterDefinitionStates.DEFINED;

    // checks if the center gets defined on the composite (connection) chart.
    const isDefinedOnCompositeChart = compositeChart.centers[attachedCenterId] === CenterDefinitionStates.DEFINED;

    return {
        isPersonalDefined: isDefinedOnPersonalChart,
        isDefinedOnCompositeChart: isDefinedOnCompositeChart,
        // A transition occurs when we go from open -> defined.
        transition: !isDefinedOnPersonalChart && isDefinedOnCompositeChart
    }
}

const parseGatesOfLove = (gates, planets, otherChartPlanets, compositeChart, isPersonal = true) => {
    const chart = computeChart(planets);
    const loveGates = [];
    for(let gate of gates){

        const hasChannelOnItsOwn = checkForElectromagnetic(gate, planets);
        const compositeCreatesChannel = checkForElectromagnetic(gate, otherChartPlanets);
        const bothChartsShareGatesOfLove = checkForSameGate(gate, otherChartPlanets);
        const centerStatuses = checkCenterStatus(gate, chart, compositeChart);

        // if(compositeCreatesChannel || bothChartsShareGatesOfLove || centerStatuses.transition) {
        // we maintain only electromagnetic channels for now
        if(compositeCreatesChannel && !bothChartsShareGatesOfLove && !chartHasChannelInLove(gate, planets)) {
            loveGates.push({
                isPersonal,
                gate,
                electromagnetic: compositeCreatesChannel,
                sharedLoveGate: bothChartsShareGatesOfLove,
                electromagneticGateOnChart: hasChannelOnItsOwn,
                gateDefinedOnChart: centerStatuses.isPersonalDefined,
                centerStatuses: centerStatuses
            })
        }
    }

    return loveGates;
}
export const getElectromagneticGates = (planets, otherChartPlanets, compositeChart) => {

    let personal = [];
    let transcendent = [];

    for(let planet of planets) {
        if(TRANSCENDENT_GATES.includes(planet.gate)) transcendent.push(planet.gate);
        if(PERSONAL_GATES.includes(planet.gate)) personal.push(planet.gate);
    }

    const chartPersonalGates = parseGatesOfLove([...new Set(personal)], planets, otherChartPlanets, compositeChart, true);
    const chartTranscendentGates = parseGatesOfLove([...new Set(transcendent)], planets, otherChartPlanets, compositeChart, false);

    return {
        personal: chartPersonalGates,
        transcendent: chartTranscendentGates
    }
}