import gemini from './gemini'
import cancer from './cancer'
import leo from './leo'
import virgo from './virgo'
import libra from './libra'
import scorpio from './scorpio'
import saggitarius from './saggitarius'
import capricorn from './capricorn'
import aquarius from './aquarius'
import pisecs from './pisecs'
import aries from './aries'
import taurus from './taurus'

export default [gemini, cancer, leo, virgo, libra, scorpio, saggitarius, capricorn, aquarius, pisecs, aries, taurus]
