<template>
  <div class="filter-button">
    <div class="d-flex align-items-center" @click.self="selectFilter">
      <div class=" icon mr-2" @click="selectFilter">
        <span :class="filter.box.icon" class="flex-shrink-0 text-lg"></span>
      </div>
      <div class="flex-shrink-0" @click.self="selectFilter">
        <span class="mbg-text-muted text-sm" @click="selectFilter">{{$t(`hd.properties.${filter.prop}`)}}</span><br>

        <p v-if="filter.selected.length === 0" class="mb-0 text-md font-500"
           @click.self="selectFilter">{{$t('library.all')}} {{$t('hd.properties.' + filter.i18nKey)}}</p>
        <p v-else class="mb-0 font-500 color-orange">
          {{ filter.selected.length }} {{ $t('library.selected') }}

          <b-button variant="link" class="btn-xs m-0 p-0" @click="emptyFilter"> {{ $t('library.clear') }} </b-button>

        </p>
      </div>
      <div class="ml-auto search-icon mr-3" @click="selectFilter">
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>

</template>

<script>

export default {
  props: {
    filter: Object,
  },
  methods:{
    selectFilter(){
      this.$emit('selectedFilter', this.filter);
    },
    emptyFilter() {
      this.$emit('emptyFilter', this.filter);
    }
  }

}
</script>