<template>

  <div id="account-settings" class="row">
    <div class="col-12 col-xl-6">
      <div v-if="!changeDetails" class="current-details">
        <mbg-text-input v-model="user.fullName" :title="$t('pages.account.settings.name')" icon="icon-user" disabled/>
        <mbg-text-input type="email" v-model="user.email" :title="$t('pages.account.settings.email')" icon="icon-email" disabled/>
        <b-button variant="link" class="btn-primary-link mb-3" @click="editDetails">Change</b-button>
      </div>

      <div v-else class="new-details">
        <mbg-text-input v-model="profile.newName"
                        :validation="v$.profile.newName"
                        :title="$t('pages.account.settings.name')" icon="icon-user"/>
        <mbg-text-input type="email" v-model="profile.newEmail"
                        :validation="v$.profile.newEmail"
                        :title="$t('pages.account.settings.email')" icon="icon-email"/>

        <b-button variant="link" class="btn-primary-link mb-3" @click="updateProfile" :disabled="updating || hasErrors">{{ $t('general.saveChanges') }}</b-button>
        <b-button variant="link" @click="cancelChangeDetails" :disabled="updating">{{ $t('general.cancel') }}</b-button>

      </div>


      <div v-if="!changePassword" class="current-password">
        <mbg-text-input type="password" :title="$t('pages.account.settings.password')" placeholder="••••••••••••••••••" icon="icon-lock" disabled/>
        <b-button variant="link" class="btn-primary-link" @click="editPassword">Change</b-button>
      </div>
      <div v-else class="new-password">
        <mbg-text-input type="password" v-model="passwordUpdate.new"
                        :validation="v$.passwordUpdate.new"
                        :title="$t('pages.account.settings.newPassword')" icon="icon-lock"/>
        <mbg-text-input type="password" v-model="passwordUpdate.repeat"
                        :title="$t('pages.account.settings.repeatNewPassword')"
                        :validation="v$.passwordUpdate.repeat"
                        icon="icon-lock"/>

        <b-button variant="link" class="btn-primary-link mb-3" @click="updatePassword" :disabled="updating || hasErrors">{{ $t('general.saveChanges') }}</b-button>
        <b-button variant="link" @click="cancelChangePassword" :disabled="updating">{{ $t('general.cancel') }}</b-button>
      </div>


    </div>
    <div class="col-12 col-xl-6 newsletter-container">
      <b-card>

        <b-card-body>
          <img :src="loadLocalIcon(`newsletter--${getDarkModeText}.svg`)" class="newsletter-img"/>
          <h5>{{$t('pages.account.settings.newsletter.title')}}</h5>

          <b-form-checkbox :checked="newsletter" name="check-button" @change="updateNewsletterOption" switch :disabled="updating">
             {{$t('pages.account.settings.newsletter.signUp')}}
          </b-form-checkbox>

          <p class="mbg-text-muted">
            {{$t('pages.account.settings.newsletter.paragraph')}}
          </p>
        </b-card-body>
      </b-card>

    </div>
    <div class="col-12 d-flex justify-content-end">
      <b-button size="sm" variant="primary" class="ml-auto" @click="deleteAccount">
        {{ $t('pages.account.settings.deleteAccount') }}
      </b-button>
    </div>
  </div>
</template>
<style lang="scss">
#account-settings{
  .newsletter-img{
    margin-bottom:1rem;
  }
  .col-12 > div{
    margin-bottom:1rem;
  }
}
</style>

<script>

import {mapState} from "vuex";
import MbgTextInput from "../../components/elements/MbgTextInput";
import useVuelidate from '@vuelidate/core'
import {minLength, email, required, helpers} from "@vuelidate/validators";

const checkEquality = (data, siblings) => {
  return data === siblings.new;
}
export default{
  setup () {
    return { v$: useVuelidate() }
  },
  components: {MbgTextInput},
  validations: {
    passwordUpdate:{
      new: {
        required,
        minLength: minLength(6),
        $autoDirty: false
      },
      repeat:{
        required,
        minLength: minLength(6),
        checkEquality: helpers.withMessage(()=> {return 'errors.passwordsMatch'}, checkEquality),
        $autoDirty: false
      }
    },
    profile:{
      newName: {
        required,
        $autoDirty: true
      },
      newEmail:{
        required,
        email,
        $autoDirty: false
      }
    }
  },
  computed:{

    ...mapState({
      user: state => state.account.user
    }),

    hasErrors(){
      return this.v$ ? this.v$.$errors.length > 0 : false
    }
  },
  data(){
    return{
      changePassword: false,
      changeDetails: false,

      passwordUpdate:{
        new: null,
        repeat: null
      },
      updating: false,
      newsletter: null,
      profile:{
        newName: null,
        newEmail: null,
      }
    }
  },

  created(){
    this.newsletter = this.user.newsletter
    this.profile.newName = this.user.fullName;
    this.profile.newEmail = this.user.email
  },
  methods:{

    updateNewsletterOption(val){
      this.updating = true;
      this.axios.put('/users/', {newsletter:val}).then((res)=>{
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')});
        this.$store.dispatch('account/updateUser', {newsletter: res.data.newsletter});
        this.newsletter = res.data.newsletter;
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(()=>{
        this.updating = false;
      })

    },
    updateProfile(){

      this.updating = true;

      const changes = {};
      if(this.profile.newName !== this.user.fullName) changes.fullName = this.profile.newName;
      if(this.profile.newEmail !== this.user.email) changes.email = this.profile.newEmail;

      this.axios.put('/users/', changes).then((res)=>{
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')});
        this.$store.dispatch('account/updateUser', {email: res.data.email, fullName: res.data.fullName});

        this.changeDetails = false;
        this.profile = {
          newName: res.data.fullName,
          newEmail: res.data.email,
        }
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(()=>{
        this.updating = false;
      })
    },

    editDetails(){
      this.resetValidations();
      this.changePassword = false;
      this.changeDetails = true;
    },
    editPassword(){
      this.resetValidations();
      this.changePassword = true;
      this.changeDetails = false;

    },
    cancelChangePassword(){
      this.changePassword = false;
      this.resetValidations();
    },

    cancelChangeDetails(){
      this.changeDetails = false;
      this.resetValidations();
    },

    resetValidations(){
      this.v$.$reset();
    },

    updatePassword(){

      this.updating = true;
      this.axios.put('/users/change-password', {password: this.passwordUpdate.new}).then(()=>{
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')});
        this.passwordUpdate = {
          new: null,
          repeat: null
        }
        this.changePassword = false;
        this.resetValidations();
      }).catch(()=>{
        this.$notify({
          type: 'error',
          title: this.$t('errors.general'),
          text: this.$t('errors.retry')
        });
      }).finally(()=>{
        this.updating = false;
      });
    },

    deleteAccount(){
      const password = prompt(this.$t('pages.account.settings.areYouSure') + ' ' + this.$t('pages.account.settings.typePassword'));
      if (!password) return;

      this.axios.post('/users/delete-user', { password }).then(() => {
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.deleted')});
        this.$store.dispatch("account/logout").then( () => {
          this.$router.push({name: 'login'})
        });
      }).catch( () => {
        this.$notify({
          type: 'error',
          title: this.$t('errors.general'),
          text: this.$t('errors.retry')
        });
      })
    }
  }
}
</script>