<template>
  <b-dropdown id="display-dd" variant="full-white" class="desktop-version text-sm" :text="$t('library.display')" toggle-class="custom-select">
    <b-dropdown-form>
        <b-form-checkbox id="type" :checked="type" name="type"  class="desktop-version" @input="val => changeOption('showType', val)">
          {{ $t('hd.properties.type') }}
        </b-form-checkbox>
        <b-form-checkbox id="authority" :checked="authority" name="authority" class="desktop-version"  @input="val => changeOption('showAuthority', val)">
          {{ $t('hd.properties.authority') }}
        </b-form-checkbox>
        <b-form-checkbox id="definition" :checked="definition" name="definition" class="desktop-version"  @input="val => changeOption('showDefinition', val)">
          {{ $t('hd.properties.definition') }}
        </b-form-checkbox>
        <b-form-checkbox id="profile" :checked="profile" name="profile" class="desktop-version" @input="val => changeOption('showProfile', val)">
          {{ $t('hd.properties.profile') }}
        </b-form-checkbox>
    </b-dropdown-form>
  </b-dropdown>
</template>

<style lang="scss">
.library-right-actions .custom-select{
  font-size:14px!important;
  &.dropdown-toggle::after{
    margin-top:0!important;
    vertical-align: 0.2em;
    margin-left:5px!important;
  }
}
.theme--dark {
  #display-dd {
    .dropdown-menu.show {
      background: $bg-dark!important;
      color:white;
    }
  }
}
</style>
<script>

import {mapState} from "vuex";

export default {
  computed:{
    ...mapState({
      options: state => state.ui.options
    }),

    profile(){
      return this.options.showProfile
    },

    type(){
      return this.options.showType
    },
    definition(){
      return this.options.showDefinition
    },
    authority(){
      return this.options.showAuthority;
    }
  },

  methods:{
    changeOption(key, value){
      this.$store.dispatch('ui/setOption', {key, value})
    },
  }
}
</script>