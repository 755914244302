export default `<svg viewBox="0 0 511.713 432.621" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g>
   <path d="M421.74,129.944c-6.142-9.106-14.182-16.809-23.467-22.696c-28.817-20.513-72.079-33.386-121.313-33.386
    c-78.863,0-189.931,26.189-189.931,84.413c0,5.836,4.727,10.552,10.552,10.552c5.824,0,10.552-4.716,10.552-10.552
    c0-36.234,89.13-63.31,168.827-63.31c17.737,0,34.768,1.973,50.49,5.444c-29.503,11.512-50.49,40.16-50.49,73.693
    c0,43.642,35.496,79.138,79.138,79.138c43.643,0,79.138-35.496,79.138-79.138c0-1.425-0.137-2.807-0.211-4.221
    c0.032-0.358,0.211-0.676,0.211-1.056C435.235,154.889,430.371,141.772,421.74,129.944z M356.098,232.138
    c-32.003,0-58.034-26.041-58.034-58.034s26.031-58.034,58.034-58.034c9.897,0,19.204,2.5,27.371,6.879
    c9.233,6.152,16.619,13.105,21.863,20.576c5.54,8.896,8.8,19.352,8.8,30.579C414.132,206.097,388.102,232.138,356.098,232.138z"/>
  <path d="M414.132,263.793c-5.824,0-10.551,4.717-10.551,10.552c0,36.234-89.131,63.311-168.828,63.311
    c-17.737,0-34.768-1.974-50.489-5.445c29.502-11.512,50.489-40.159,50.489-73.693c0-43.642-35.496-79.138-79.138-79.138
    s-79.138,35.496-79.138,79.138c0,1.425,0.137,2.807,0.211,4.221c-0.031,0.359-0.211,0.676-0.211,1.056
    c0,13.938,4.864,27.055,13.495,38.883c6.142,9.106,14.182,16.809,23.468,22.697c28.816,20.512,72.078,33.386,121.313,33.386
    c78.863,0,189.931-26.189,189.931-84.414C424.684,268.51,419.957,263.793,414.132,263.793z M155.615,200.482
    c32.004,0,58.034,26.042,58.034,58.034c0,31.993-26.03,58.035-58.034,58.035c-9.897,0-19.204-2.501-27.371-6.88
    c-9.232-6.151-16.619-13.105-21.863-20.576c-5.54-8.895-8.8-19.352-8.8-30.579C97.581,226.524,123.612,200.482,155.615,200.482z"/>
  </g>
</svg>`