import centers from '@/helpers/rave/constants/centers'
import definitions from '@/helpers/rave/constants/centerDefinitionStates'

export default {
  [centers.ROOT.id]: {
    name: 'Root',
    title: 'The Root Center',
    theme: 'Pressure, Stress',
    bio: 'Adrenal Glands, Kundalini',
    [definitions.DEFINED]: {
      title: 'The Defined Root Center',
      desc: `A consistent way of handling stress and pressure. A steady energetic drive and reliable pace.
            The ability to release and process stress in particular ways, using energy constructively as a fuel for creative processes and endeavours, and accomplishing daily tasks.
            Releasing pressure is essential to avoid health and emotional problems. Learning to use pressure without overloading, or causing others to experience stress.`
    },
    [definitions.UNDEFINED]: {
      title: 'The Open Root Center',
      theme: 'Always in a hurry to be free of the pressure',
      desc: `Naturally amplifies pressure and stress. Rushing through everything in a hurry to be free of pressure leads to hasty decisions, exhaustion and burnout. 
            Instead of running off others’ stress and adrenaline, the need to be at rest, discharge and unwind. Enjoying a natural state of stillness and regular withdrawals to recuperate. 
            When in the presence of others with defined Root, a tendency to get carried away by the rush, which can cause overload leading to physical distress. It is paramount not to succumb to others’ pace and be pressured into doing things quickly, which is unnatural for the open Root.`
    },
    [definitions.EMPTY]: {
      title: 'The Completely Open Root Center',
      theme: 'Not Knowing When to Hurry',
      desc: `A deeply powerful motor, the Root is the adrenal system - the power system that drives many functions. 
            An open Root Center with an activated Gate is always feeling the pressure, taking in and amplifying the stress and pressure of others, and the environment. A fundamental issue is the inability to hold on to pressure, to sustain it, which leads to constantly rushing (through) things. A classic experience for an open Root is feeling in a hurry, trying to get things done in a hurry, simply to get rid of the pressure. 
            A completely open Root does not understand and experience pressure the way others do. There is an inability to recognize pressure coming into the system, or how to use it. When under pressure, the inability to process this energy or use it productively can lead to difficulties when dealing with pressing matters and deadlines.
            Another way the completely open Root functions is to vacillate between hyperactivity and periods of complete stillness or inactivity, without understanding why. It is simply amplifying the pressure, or lack thereof, of the environment. 
            Once aware of this pattern, using Strategy and Authority can help establish a healthy and productive balance. Alternating between periods of moving with pressure that is needed to get things done, and periods of inactivity and stillness that are essential to disengage from the pressure and stress that is all around, and recuperate.`
    }
  },
  [centers.SACRAL.id]: {
    name: 'Sacral ',
    title: 'The Sacral Center',
    theme: 'Generator Motor, Response',
    bio: 'Ovaries, Testes',
    [definitions.DEFINED]: {
      title: 'The Defined Sacral  Center',
      desc: `A consistent and reliable access to energy when released through response. The Center of life force itself, it possesses tremendous power to see something through once it responds positively. However, the tendency to quit when jumping into experiences out of conditioned desire instead of sacral response. 
            Initiating anything can lead to failure with things not going as expected, often resulting in resistance, and feeling frustrated. Forcing an issue prematurely, likely ends in losing power and experiencing exhaustion. Correct actions arise out of response. `
    },
    [definitions.UNDEFINED]: {
      title: 'The Open Sacral  Center',
      theme: 'Not knowing when enough is enough',
      desc: `Open energetically and easily conditioned into being active, hence the importance to know when enough is enough. Susceptible to becoming a conditioned worker, constantly overworked, leading to burn out. When conditioned by others with defined Sacrals, the tendency to take on too much and over-commit, and feeling overwhelmed. 
            The open Sacral is not designed to handle great pressure or be a tireless worker. Instead, the correct path is to enjoy the buzz of others and be vitalized by it, ready to withdraw and recharge when the body needs rest. It is essential to learn how to use life force wisely. 
            As the source of sexual energy, the open Sacral is susceptible to sexual conditioning, either repressing or identifying with other’s sexual conditioning, leading to neediness and confusion. Becoming aware of this pattern is key to a healthy experience of Sacral energy.`
    },
    [definitions.EMPTY]: {
      title: 'The Completely Open Sacral Center',
      theme: 'Not Knowing what to use Energy For',
      desc: `The core of life on this planet, the Sacral is the great generative force and procreative center.  
            For an open Sacral Center with an activated gate, the Not-self strategy is not knowing when enough is enough, to just keep going. The dilemma for a completely open Sacral Center lies in knowing where to put energy. There is a sense of where energy should go, but a lack of understanding this type of energy and how to use it, can lead to being drawn to a multitude of things, depleting energy resources. 
            The tendency is to wait for exhaustion from dysfunctional activity, to literally be overwhelmed by it. Not knowing what to use energy for, the open Center is deeply conditioned to spend energy indiscriminately, which over time becomes very unhealthy. 
            Following the mechanics of Strategy and Authority, the potential is a profound understanding of energy as a ‘life force’ underlying our existence, and with it the wise and discerning use of this primal energy.`
    }
  },
  [centers.SPLEEN.id]: {
    name: 'Splenic ',
    title: 'The Splenic Center',
    theme: 'Immune System, Intuition',
    bio: 'Lymphatic System, Spleen, T-Cells, Immune System',
    [definitions.DEFINED]: {
      title: 'The Defined Splenic Center',
      desc: {
        forSplenicAuthority: `A consistently reliable immune system that maintains the body's equilibrium in illness and health. The Spleen governs the ability to fight off disease, eliminate toxins, and clear negative frequencies that impact the auric field. 
                The defined Spleen naturally processes fear and generates well-being, providing a deep inner sense of security. It represents the instinctive awareness that operates in the moment, instantly delivering information through an intuitive insight or simple hunch that can be trusted to make small and major life decisions. Letting the mind override these alerts that can lead to physical problems, danger, and disease`,
        normal: `A consistently reliable immune system that maintains the body's equilibrium in illness and health, as the defined Spleen governs the ability to fight off disease, eliminate toxins, and clear negative frequencies that impact the auric field. 
                The defined Spleen naturally processes fear and generates well-being, providing a deep inner sense of security.
                The Spleen is not the Inner Authority, it is therefore critical to be cautious when listening to intuitive insights or hunches, and not make important decisions based on these. While intuitive insights can support wellbeing and general health and safety, these should not be in charge of major life decisions. `
      }
    },
    [definitions.UNDEFINED]: {
      title: 'The Open Splenic Center',
      theme: 'Holding on to what isn\'t good',
      desc: `Physical wellbeing and general sense of feel-good is inconsistent and susceptible to others’ conditioning. At the same time, this enables high sensitivity and attunement to the body’s needs, as well as others’ needs. Among the gifts of the open splenic Center is the natural ability to assess the physical wellbeing of others. 
            Important is to carefully choose people and things that support well being, and letting go of things that are not.
            The Splenic Center holds primal fears for survival and wellbeing; with an undefined Spleen, fears are easily magnified, with the tendency to hold onto things and people that help dissipate them. It is vital to tackle and handle these fears to cultivate a sense of security and build physical resilience, and avoid dependencies.`
    },
    [definitions.EMPTY]: {
      title: 'The Completely Open Splenic Center',
      theme: 'Not Knowing what to Fear',
      desc: `The Splenic system is the immune system, the foundation of survival on this plane. The gates of the Splenic system represent primal basic fears.
            Within the context of awareness, the fears that emerge out of the Spleen are the roots of our intelligence. An activated gate in an open Splenic center, is a link to work intelligently with specific fears as they provoke an intelligent response to handling them.
            A totally open Splenic system means not quite knowing what to be afraid of. A standard metaphor is the child standing on the roof of a house with wings made of paper and glue, ready to fly, without any sense that those wings may not work.
            A lack of an innate and natural sense for what to be afraid of can lead to undertaking very foolish and risky things. The other extreme is immense insecurity and fearfulness, being fearful of just about everything. 
            The wisdom lies in recognizing those unhealthy fears that interfere with an innate instinct for survival and well-being. Remaining open to the wide range of intelligent and instinctual frequencies that pass through this Center, this openness can keep one alert, healthy and safe.`
    }
  },
  [centers.SOLAR_PLEXUS.id]: {
    name: 'Solar Plexus',
    title: 'The Solar Plexus Center',
    theme: 'Emotional Wave',
    bio: 'Kidneys, Pancreas, Prostate, Lungs',
    [definitions.DEFINED]: {
      title: 'The Defined Solar Plexus Center',
      desc: `With a defined Solar Plexus Center, emotions resemble a wave moving up and down, and sudden changes of mood. 
            Emotional definition requires to have awareness over time. Each experience and decision takes time to process, instead of jumping at things spontaneously. Things appear very different when on the high end of a wave than on the low end, and premature decisions are influenced by the emotional state of any given moment. 
            Patience is the first step to living a healthy life as an emotional person. Through patience comes clarity, and the ability to access emotional depth is. The highs and lows of the wave bring passion, excitement and even beauty in times of melancholy. `
    },
    [definitions.UNDEFINED]: {
      title: 'The Open Solar Plexus Center',
      theme: 'Avoiding confrontation and truth',
      desc: `An open Solar Plexus Center absorbs and amplifies the emotions of others. It is crucial not to identify with these energies and to learn living with others without being conditioned into emotionality, or emotional reactions. 
            The emotional environment can be overwhelming and confusing and leads to avoiding confrontation at all costs so not to “rock the boat.” The answer is not to be driven by others’ emotions and to take accept emotional conditioning without identifying. To remain emotionally calm and neutral is the way of the open Solar Plexus.`
    },
    [definitions.EMPTY]: {
      title: 'The Completely Open Solar Plexus Center',
      theme: 'Not Knowing What to Feel',
      desc: `The Solar Plexus holds enormous power and has a dominating influence globally. It greatly impacts a world that operates on a constantly rising and falling emotional wave.
            With a dormant gate in an open emotional system, the Not-self strategy is to avoid confrontation and truth. It is not about not knowing what to feel, because the dormant gate has a specific emotional theme to relate to, thereby providing a way to access emotional energy. 
            The dilemma for the completely open Solar Plexus Center is not knowing what to make of feelings, and an inability to interpret the wave itself as there is no theme or link to filter the incoming emotional energy. This can lead to feeling extremely vulnerable. 
            The potential wisdom of the completely open Solar Plexus lies in perceiving the frequency of emotional energy without judgment or interpretation. There is simply awareness of emotional conditioning, and how one is affected by it, without identifying with the incoming emotional energy. Over time, the potential is to recognize emotional (spirit) awareness in others, an awareness that is slowly emerging.`
    }
  },
  [centers.HEART.id]: {
    name: 'Heart',
    title: 'The Heart Center',
    theme: 'Ego, Willpower',
    bio: 'Stomach, Thymus Gland, Heart, Gallbladder',
    [definitions.DEFINED]: {
      title: 'The Defined Heart Center',
      desc: `A reliable and consistent willpower as well as a powerful ego. Equipped with confidence, a healthy sense of self-esteem, and thriving on being competitive, gives the potential to succeed on the material plane. 
            Making deals, bargains and promises is natural, as is committing willpower to goals and targets. once committed to something, it is essential to keep promises and deliver to ensure others’ trust.
            A powerful work ethic with the entrepreneurial skills to work independently and be successful on the material plane; a priority to be in control of one’s life and in charge of resources.
            A natural sense of pride and tendency to prove oneself, the challenge is not to be too forceful or overbearing which leads to resistance form the outside world.`
    },
    [definitions.UNDEFINED]: {
      title: 'The Open Heart Center',
      theme: 'Feeling unworthy and undervalued',
      desc: `Without reliable or consistent willpower, the need to develop a healthy sense of self-worth. Dealing with pressure to prove worthiness can lead to trying to compensate by demonstrating courage and willfulness. 
            Open Heart Centers are not designed to be competitive and willful, or to make promises. Realizing talents and gifts without the need to prove or compare to others can lead to contentment and success.`
    },
    [definitions.EMPTY]: {
      title: 'The Completely Open Heart Center',
      theme: 'Not Knowing What is Worthy',
      desc: `For an open Heart Center with activation, self-esteem can be low or at least inconsistent. Questioning self-worth leads to constantly having to prove oneself. A tendency to push things and over-achieve in order to compensate for a seeming lack of willpower. 
            For an open Heart Center without any activations, the theme is having to come to terms with the concept of worthiness - how to measure it, how to achieve it. The probability is to abdicate Authority by allowing others to determine who or what is worthy, thereby handing over control. 
            Accepting there is nothing to prove, and relying on Strategy and Authority to provide what is needed, allows the wisdom of this open Center to emerge. To be recognized as trustworthy, and relied upon to handle matters of the material plane and use of personal power with integrity. `
    }
  },
  [centers.G.id]: {
    name: 'G',
    title: 'The G Center',
    theme: 'Identity, Love and Direction',
    bio: 'Liver, Blood',
    [definitions.DEFINED]: {
      title: 'The Defined G Center',
      desc: `A reliable sense of self, of identity. Knowing who one is and what one is able to give. A defined way of offering love, without the need to cling to love coming from another. The ability to know where and how to make progress, and potentially a strong sense of mission in life. 
            The defined G is a source of love and direction that can powerfully affect others. Understanding the direction humanity needs to take to further evolution gives the ability to extend influence when invited to offer guidance. To ignore and compromise the internal sense of direction can lead to experience a loss of identity and futility regarding a mission in life.`
    },
    [definitions.UNDEFINED]: {
      title: 'The Open G Center',
      theme: 'Fixated on finding love and direction',
      desc: `Without a fixed identity or direction in life. The freedom and flexibility to express different identities and discover a path. Learning to appreciate and find comfort in not having a consistent or reliable sense of self leads to discover gifts of the open G.
            An inherent sensitivity to others’ identities, to take in and experience various different identities. The ability to identify with many different people and their way of expression, playing with and assuming various roles. 
            Instead of feeling vulnerable and pressured when in the company of others with a strong sense of self, the potential lies in appreciating the gift of freedom and flexibility in expression, moving along diverse trajectories to sample, ultimately leading to healthy self-expression.`
    },
    [definitions.EMPTY]: {
      title: 'The Completely Open G Center',
      theme: 'Not Knowing What to Be',
      desc: `For an open G Center with activations, the Not-self strategy is always looking for love and direction, because there is no real sense of identity.
            With an entirely open G center, the predominant theme is not having a sense of identity, not knowing what life is about, and what direction to take. This can be a real dilemma because the complete openness makes it easy to be manipulated and to be taken over by community, culture, religion, and so forth, and giving authority to others.
            Discovering identity through the magic of mechanics reveals inner essence and clarity of direction, the unfolding of the individual path to love.`
    }
  },
  [centers.THROAT.id]: {
    name: 'Throat',
    title: 'The Throat Center',
    theme: 'Manifestation, Communication',
    [definitions.DEFINED]: {
      title: 'The Defined Throat Center',
      desc: `A fixed way of expressing oneself, resulting in a consistent and reliable way of communication. As it is also the center of Manifestation, a certain way of acting, or doing things. The tendency to act too readily or inappropriately, talking too much, and consequently losing impact. `
    },
    [definitions.UNDEFINED]: {
      title: 'The Open Throat Center',
      theme: 'Trying to attract attention',
      desc: `A versatile, spontaneous voice and the capacity to speak is dependent on others and the environment. Communication works best when conversation is initiated by someone else which allows speaking out spontaneously, instead of getting caught up trying to repeat something that has been rehearsed. 
            Trusting that what needs to be said will reveal itself in the moment, without the need to get caught up in planning conversations. An open Throat carries the potential of speaking and doing things in a multitude of ways, not fixed in any one way. 
            Seeking attention is a hallmark of the open Throat; instead, learning to appreciate silence and waiting to be brought into the conversation are proper ways to express the open Throat potential.`
    },
    [definitions.EMPTY]: {
      title: 'The Completely Open Throat Center',
      theme: 'Not Knowing What to Say',
      desc: `With an activation, the Open Throat is all about attracting attention. This puts considerable pressure on the thyroid system because of the compulsion to say things simply to get attention. It ends up being a physical problem, aside from the fact that it often leads to its negative, alas not the kind of attention hoped for. 
            With a completely open Throat Center, the keynote is not knowing what to say—having really no idea what to say, or what action to take. This doesn't hinder self-expression as long as the decision making process is the guiding principle. If the Not-self dictates when and how to attract attention this leads to saying the wrong thing at the wrong time, repeating what has been said by others, with nobody paying attention. 
            Learning to trust inner Authority to guide this process, and being comfortable with the fact that there simply is no certainty or predictability when it comes to self-expression and the ability to speak. Staying with this process will reveal the potential for wisdom of this open center.`
    }
  },
  [centers.AJNA.id]: {
    name: 'Ajna',
    title: 'The Ajna Center',
    theme: 'Conceptualizing',
    [definitions.DEFINED]: {
      title: 'The Defined Ajna Center',
      desc: `The ability to conceptualize by way of a fixed and particular thought process. An inbuilt structure for processing or computing information in a certain way, whether logical, abstract, or individual. 
            To enjoy research, reviewing and organizing information in readiness for communication, feeling comfortable about concepts. A reliable and trustworthy way of thinking with the tendency to filter perception, limiting the thought process to follow particular ways.`
    },
    [definitions.UNDEFINED]: {
      title: 'The Open Ajna Center',
      theme: 'Pretending to be certain',
      desc: `The ability to conceptualize is not fixed, does not follow set patterns. The potential to be open minded without the need for certainty.
            A fluid and adaptable way of thinking, soaking up data without identifying with it. The undefined mind is not designed to be consistent but ranges from being completely empty to overflowing with ideas, concepts and opinions. 
            Having no fixed way of processing thoughts, the fluid nature of the undefined mind is the source of a dynamic and ever-changing intelligence, with a potential to be extremely creative and intellectual. The capacity to explore myriad thought processes and extract concepts of value.`
    },
    [definitions.EMPTY]: {
      title: 'The Completely Open Ajna Center',
      theme: 'Not Knowing What to Think',
      desc: `The ability to conceptualize is widely considered to be of major importance. But with a completely open Ajna Center, there is no mental theme to ground in, which can result in simply not knowing what to think. 
            Conceptualization through the logical process is rooted in opinion, through the abstract process in ideas, and through the individual process in insight. The openness leads to not knowing whether an idea, opinion or insight has any real value, or what to think about a given situation. This uncertainty can result in the abdication of Authority, letting others assess situations and decide what has value. 
            The potential with a completely open Ajna lies in simply enjoying the process of contemplating a wide range of theories, insights, and concepts without being bound to process or organize these in a set way, or identify with any of them. `
    }
  },
  [centers.HEAD.id]: {
    name: 'Head',
    title: 'The Head Center',
    theme: 'Inspiration',
    [definitions.DEFINED]: {
      title: 'The Defined Head Center',
      desc: `A fixed and consistent way of thinking and processing information. The defined Head Center is the source of inspiration and clarity as well as confusion and doubt, all natural processes with their own inner timing and resolution. The experience of consistent mental pressure, seeking answers to life’s fundamental questions in an effort to grasp and understand things. 
            Insights and inspirations that arise out of this process have the power to uplift and inspire others. Refrain from trying to resolve the pressure through outside action such as (making) hasty, inappropriate decisions.`
    },
    [definitions.UNDEFINED]: {
      title: 'The Open Head Center',
      theme: 'Thinking about things that don\'t matter',
      desc: `Open to new insights, questions, and those mental sparks that stimulate inspiration. A strong potential to provide others with inspiration. On the other side, trying to resolve questions that are of no concern and thinking about things that don't matter dilute the potential for wisdom.
            Letting go of the pressure to find answers to other people’s questions results in relaxation, new insights, mental sparks, and inspiration. Able to explore a wide range of stimulating, intellectual, and mystical topics without being overwhelmed or identified. The ability to discern what merits contemplation can eventually yield the answers sought.`
    },
    [definitions.EMPTY]: {
      title: 'The Completely Open Head Center',
      theme: 'Not Knowing What is Interesting',
      desc: `With activations, there is always a theme, and therefore something to think about. Without activations, there is no theme to hold on to, and information is not processed in any particular way. 
            The dilemma of an open mind often reflects in not knowing what to focus on. This can lead to indiscriminately thinking about a wide spectrum of things, and about things that don’t matter.
            Simultaneously, there is constant pressure to recognize what is inspiring, and being confused about what is truly interesting. This in itself can lead to a disconnect from intellectuality, such as the failure to recognize substance in a conversation with another. Not being able to find what is of real interest is, or knowing what matters and what does not, leads to problems in making a firm decision.
            Letting the mental pressure of this Center pass through without identifying with any part of it, or trying to find answers to life’s questions. Ultimately, there is a deep potential to explore the mysteries of life, and to recognize what is truly inspiring and merits attention.`
    }
  }
}
