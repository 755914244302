var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row",attrs:{"id":"account-settings"}},[_c('div',{staticClass:"col-12 col-md-6"},[_c('mbg-select',{attrs:{"title":_vm.$t('general.accountOptions.date_input'),"options":[
                  {
                    text: 'DD/MM/YYYY',
                    value: 0
                  },
                  {
                    text: 'MM/DD/YYYY',
                    value: 1
                  }
    ]},model:{value:(_vm.options.date_input),callback:function ($$v) {_vm.$set(_vm.options, "date_input", $$v)},expression:"options.date_input"}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('label',[_vm._v("Pro Mode")]),_c('b-form-checkbox',{staticClass:"cursor-pointer my-3",attrs:{"name":"pro-sidebar"},model:{value:(_vm.options.pro_sidebar_version),callback:function ($$v) {_vm.$set(_vm.options, "pro_sidebar_version", $$v)},expression:"options.pro_sidebar_version"}},[_vm._v(" "+_vm._s(_vm.$t('general.accountOptions.pro_sidebar_version'))+" ")])],1),_c('div',{staticClass:"col-12 mt-5"},[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.hasChanged},on:{"click":_vm.updateOptions}},[_vm._v(_vm._s(_vm.$t('general.saveChanges')))])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }