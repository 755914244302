export default `<svg viewBox="0 0 511.713 432.621" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <path d="M382.478,228.894c-20.661,0-39.337,8.547-52.759,22.274V102.273c0-38.409-31.001-67.373-72.101-67.373
    c-27.75,0-51.502,12.726-64.649,32.077c-12.346-19.5-34.938-32.077-61.971-32.077c-42.407,0-75.624,29.598-75.624,67.373
    c0,5.835,4.717,10.552,10.552,10.552s10.552-4.717,10.552-10.552c0-28.965,27.719-46.27,54.521-46.27
    c29.556,0,50.996,19.457,50.996,46.27v200.482c0,5.835,4.717,10.552,10.552,10.552c5.836,0,10.552-4.717,10.552-10.552V102.273
    c0-28.965,27.72-46.27,54.521-46.27c29.556,0,50.997,19.457,50.997,46.27v200.482c0,22.2-9.254,73.861-94.966,73.861
    c-5.835,0-10.552,4.717-10.552,10.552c0,5.836,4.717,10.552,10.552,10.552c60.019,0,92.866-24.152,107.174-54.394
    c13.232,20.027,35.908,33.29,61.654,33.29c40.719,0,73.861-33.143,73.861-73.861C456.339,262.036,423.196,228.894,382.478,228.894z
     M382.478,355.515c-29.092,0-52.759-23.668-52.759-52.759c0-29.092,23.667-52.759,52.759-52.759
    c29.091,0,52.758,23.667,52.758,52.759C435.235,331.847,411.568,355.515,382.478,355.515z"/>
</svg>`