import Vuex from "vuex";
import Vue from "vue";
import account from "./account/account";
import ui from "./ui/ui"
import charts from "./charts/charts";
import checkout from "./checkout/checkout";
import {localStorageKeys, setLocalStorageItem} from "@/utils/localStorage";
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        account,
        ui,
        charts,
        checkout
    },
    state: {
        darkMode: false,
    },
    mutations: {
        setDarkMode(state, darkMode){
            state.darkMode = darkMode;
            setLocalStorageItem(localStorageKeys.DARK_MODE, darkMode.toString());
        },

    },

    actions:{
        init(context){
            const localStorageValue = localStorage.getItem(localStorageKeys.DARK_MODE);
            context.dispatch('account/init'); // initialize account!
            context.dispatch('ui/init');
            context.dispatch('checkout/init');
            context.dispatch('setDarkMode', localStorageValue ? localStorageValue === 'true' : window.matchMedia("(prefers-color-scheme: dark)").matches);
        },
        setDarkMode(context, data){
            if(data){
                document.body.classList.remove('theme--light');
                document.body.classList.add('theme--dark');
            }else{
                document.body.classList.remove('theme--dark');
                document.body.classList.add('theme--light');
            }
            context.commit('setDarkMode', data);
        }
    }
});

export default store;