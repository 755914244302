import authority from '../../../../helpers/rave/constants/authorities'
import type from '../../../../helpers/rave/constants/types'

export default {
  [authority.SOLAR_PLEX]: 'Solar Plexus',
  [authority.SACRAL]: 'Sacral',
  [authority.SPLENIC]: 'Splenic',
  [authority.EGO]: 'Ego Manifested',
  [authority.G]: 'Self Projected',
  [authority.EGO_PROJECTED]: 'Ego Projected',
  [authority.NONE]: 'None',
  empty: {
    [type.PROJECTOR]: 'Sounding Board',
    [type.REFLECTOR]: 'Lunar Cycle'
  },
  forShortDesc: {
    [authority.SOLAR_PLEX]: 'Emotional',
    [authority.SACRAL]: 'Sacral',
    [authority.SPLENIC]: 'Splenic',
    [authority.EGO]: 'Ego',
    [authority.G]: 'Self Projected',
    [authority.EGO_PROJECTED]: 'Ego',
    [authority.NONE]: ''
  }
}
