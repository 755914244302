<template>
  <svg id="transit-planet-table" width="100%"
       :viewBox="`0 0 136 ${getPlanets.length * (heightPerPlanet + spacePerPlanet) + (nameHeight*1.5)}`"
       fill="none" xmlns="http://www.w3.org/2000/svg">
    <text fill="currentColor" :transform="`translate(0, 30)`" class="planet-table-title" x="84" y="0"
          font-size="12"
          text-anchor="middle"
    >
      Transit Chart
    </text>
    <line x1="35" y1="40" x2="132" y2="40" :stroke="orientation === 0 ? '#6a8bb5' : '#83cbb4'" stroke-width="2" />

    <g v-for="(planet,index) in getPlanets" :key="index"
       :id="`planet-${orientation}-${index}-${planet.chartId}`"
       :transform="`translate(0, ${((index) * (heightPerPlanet + spacePerPlanet)) + (nameHeight * 1.5)})`"
       class="planet-wrapper my-1 px-2 planet-box d-flex mx-auto align-items-center cursor-pointer"
       :class="`${getPlanetClasses} g${planet.gate} ${isTransitChart(chart) ? 'transit-planet-table' : ''} ${orientation === 1 ? 'flex-row-reverse' : ''}`"
       @click="onElementClicked(planet.planet, planet.chartId)"
       @mouseleave="clearAllHighlights"
       @mouseenter="planetHighlight(planet.planet, orientation, [planet.gate], planet.chartId)"
    >
      <path d="M42 5C42 2.23858 44.2386 0 47 0H122C127.523 0 132 4.47715 132 10V33C132 35.7614 129.761 38 127 38H52C46.4772 38 42 33.5228 42 28V5Z"
            fill="transparent" class="planet-bg"/>
      <g clip-path="url(#clip0_5162_70850)">
        <path v-if="planet.isDetriment" d="M58 22L53.6699 15.25L62.3301 15.25L58 22Z" fill="currentColor"/>
        <path v-if="planet.isExalted" d="M58 15L62.3301 21.75H53.6699L58 15Z" fill="currentColor"/>
        <path v-if="planet.isStar" d="M58 14L59.3905 16.9621L62.5 17.4401L60.25 19.7445L60.781 23L58 21.4621L55.219 23L55.75 19.7445L53.5 17.4401L56.6095 16.9621L58 14Z" fill="currentColor"/>
      </g>

      <text fill="currentColor" x="100" y="23.795" xml:space="preserve" style="white-space: pre" font-family="Rubik" font-size="14"
            text-anchor="end"
            letter-spacing="0em"
      >
          {{ planet.gate }}.<tspan font-size="12">{{planet.line}}</tspan>
        </text>

      <planet-icons :index="index" x="110" y="24" font-size="13" />
      </g>

    <defs>
      <clipPath id="clip0_5162_70850">
        <rect width="10" height="11" fill="white" transform="translate(53 13)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style lang="scss">
#transit-planet-table{
  .planet-wrapper {
    &:hover {
      > .planet-bg {
        fill: $transit-color;
      }
    }
  }
  svg {
    max-height:900px;
    @media(max-width:940px){
      max-height:75vh;
    }
  }
}
</style>

<script>

import planetConstants from "../../../helpers/rave/constants/planets";
import {mapState} from "vuex";
import util from "../../../mixins/util";
import fixings from "../../../helpers/rave/constants/fixings";
import HighlightingMixin from "../../../mixins/HighlightingMixin";
import ChartUtil from "../../../mixins/ChartUtil";
import PlanetIcons from "./partials/PlanetIcons.vue";

export default {
  components: {PlanetIcons},

  mixins: [util, HighlightingMixin, ChartUtil],

  props: {
    orientation: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 600
    },
    interactive: Boolean,
    chart: Object
  },
  computed: {
    ...mapState({
      viewMode: state => state.ui.viewMode,
      viewModes: state => state.ui.viewModes
    }),

    activation() {
      return this.orientation === 0 ? 'design' : 'personality'
    },
    getPlanetClasses() {
      let classes = this.activation === 'design' ? 'bg-hover-design' : 'bg-hover-transit';

      return classes;
    },
    getPlanets() {

      // careful, only use this on "transit planet table" getter.
      if (this.viewMode === this.viewModes.cross) {
        return this.planets.filter(x => x.activation === this.orientation && (x.planet === planetConstants.EARTH || x.planet === planetConstants.SUN)
        && (x.chartId === (this.viewMode === this.viewModes.design ? 0 : 1)))
      }

      // special case for connection charts single user rendering
      // we consider the "design" view mode to be "First chart Only" and the "personality" view mode to be "Second Chart Only"
      if (this.isConnectionChart(this.chart) || this.isCycleChart(this.chart)) {
        return this.planets.filter(x => x.activation === this.orientation && x.chartId === (this.viewMode === this.viewModes.design ? 0 : 1))
      }

      if (this.isPersonTransitChart(this.chart)) {
        return this.planets.filter(x => x.activation === this.orientation && x.chartId ===
            (this.viewMode === this.viewModes.design ? 0 : 1))
      }

      return this.planets.filter(x => x.activation === (this.activation === 'design' ? 0 : 1))
    }
  },
  data() {
    return {
      planets: [],
      heightPerPlanet: 38,
      spacePerPlanet: 5,
      nameHeight: 30,
    }
  },
  created() {
    this.setupPlanetContent();
  },
  methods: {
    setupPlanetContent() {
      let planets = [];
      for (let planet of this.chart.chart.planets) {
        const planetObject = {
          icon: planetConstants.icons[planet.id],
          activation: planet.activation,
          planet: planet.id,
          isExalted: planet.fixing.state === fixings.EXALTED,
          isDetriment: planet.fixing.state === fixings.DETRIMENT,
          isStar: planet.fixing.state === fixings.JUXTAPOSED,
          name: this.$t('hd.planets.' + planet.id + '.name'),
          gate: planet.gate,
          line: planet.line,
          tone: planet.tone,
          color: planet.color,
          chartId: planet.chartId
        }
        planets.push(planetObject)
      }
      this.planets = planets;
    },
    onElementClicked(planetId, chartId) {
      if (!this.interactive) return;
      let activation = this.activation === 'design' ? 0 : 1;

      this.fixUrlQuery('?tab=planetary-activations' + '&selected=' + planetId + '&activation=' + activation + '&chart=' + chartId);
      // add the URL params so that the properties panel show the correct content

      this.$nextTick(() => {
        this.$store.dispatch('ui/increasePropertiesKey'); // increase key to update window
        this.$store.commit('ui/SET_SHOW_PROPERTIES', true); // show panel in case it's not showing
      });
    }
  }
}
</script>