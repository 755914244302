<template>
  <div class="d-flex col-12 col-md-6 mb-3 mx-2 align-items-center">
    <div class="mr-3 mb-3"><span :class="icon" style="font-size:22px;opacity:0.5"></span></div>
    <div>
      <label class="font-weight-light" style="opacity:0.7">{{ title }}</label>
      <p class="font-500">  {{ value }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: String,
    icon: String,
    title: String
  }
}
</script>