<template>

  <div id="search-results">
    <div class="search-results-backdrop" @click="$emit('closeSearch')"></div>

    <div class="search-results-card" >

      <div class="large-container">
        <div class="row py-4 px-3">
          <div class=" col-md-3 col-xl-2 search-filters d-flex d-md-block overflow-auto">
            <div class="flex-shrink-0" :class="selectedDocType === -1 ? 'active' : ''" @click="selectedDocType = -1">{{$t('library.all')}} {{$t('general.account')}} {{$t('library.charts')}}</div>
            <div v-for="(type,index) of availableDocTypes" :key="type" class="flex-shrink-0" :class="index === selectedDocType ? 'active' : ''" @click="selectedDocType = index">
              {{ $t('documentTypes.plural.' + index)}}
            </div>
            <hr class="divider-dark-mode"/>
            <div class="flex-shrink-0" :class="selectedDocType === 'celebrity' ? 'active' : ''" @click="selectedDocType = 'celebrity'">
              <b-badge variant="warning">NEW</b-badge>
              {{$t('library.celebrity.title')}}
            </div>
          </div>
          <div class="col-12 col-md-9">
            <p v-if="chartsToDisplay.length > 0 && searchText.length > 0">{{$t('library.search.results')}}</p>


            <p v-if="searchText.length === 0 && selectedDocType !== 'celebrity'">{{ $t('library.search.recent') }}</p>
            <p v-if="searchText.length === 0 && selectedDocType === 'celebrity'">{{ $t('library.search.celebrity') }}</p>

            <chart-item v-for="(chart) in chartsToDisplay" :chart="chart" :key="chart.id" :is-search="true"
            @chartItemClicked="loadChart"/>

            <b-button v-if="chartsToDisplay.length > 0 && searchText.length > 0" variant="dark" class="my-3 btn-xs"
                      @click="searchInLibrary"
            >{{$t('library.search.showAll')}}</b-button>

            <p v-if="searchText.length > 0 && chartsToDisplay.length === 0 ">
              No search results found.
            </p>
          </div>
        </div>
      </div>




    </div>

  </div>

</template>

<style lang="scss">
#search-results{
  position:relative;

  .search-results-card{
    overflow-y:auto;
    position: fixed;
    overflow-x:hidden;
    left:0;
    top:67px;
    right:0;
    height:60vh;
    @media(max-width:767px){
      height:100vh;
      top: 47px;
    }
    border-radius: 0 0 30px 30px;
    z-index:3;
  }

  .search-results-backdrop{
    z-index:1;
    position:fixed;
    top:75px;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0,0,0,0.6);
  }
}
.theme--dark{

  .search-results-card{
    background:$bg-dark;
  }

  .search-filters{
    > div{
      &.active{
        background:$bg-contrast-dark-low-opacity

      }
    }
  }
  .divider-dark-mode{
    background: $bg-contrast-dark-low-opacity;
  }

}
.theme--light{
  .search-results-card{
    background:$bg-light;
  }

  .search-filters{
    > div{
      &.active{
        background:#ffffff;
      }
    }
  }
}

.search-filters{
  > div{
    padding:0.4rem 1rem;
    text-align: right;
    border-radius: 5px 10px;
    margin:.2rem 0;
    opacity:0.5;
    margin-left:auto;
    cursor:pointer;
    &.active{
      opacity:1;
    }
  }
}

</style>

<script>
import ChartLibraryMixin from "../../mixins/ChartLibraryMixin";
import ChartItem from "../library/ChartItem";
import documentTypes from "../../helpers/docTypes";
import RecentChartsListMixin from "../../mixins/RecentChartsListMixin";
import chart from "../../lang/en/shared/chart";
import {mapState} from "vuex";
export default{
  components:{ChartItem},
  props:{
    searchText: String,
    isVisible: Boolean
  },
  computed:{
    ...mapState({
      user: state => state.account.user
    }),
    chart() {
      return chart
    },

    chartsToDisplay(){
      if(this.search.length > 0) return this.charts;

      if(this.selectedDocType !== -1){
        return this.recentCharts.filter(x => x?.docType === this.selectedDocType);
      }

      return this.recentCharts;
    },

    availableDocTypes() {
      const types = structuredClone(documentTypes);

      if(!(this.user?.cycle_charts)) {
        delete types[documentTypes.CYCLE_CHART];
      }

      return types
    }
  },
  watch:{
    searchText(val){
      this.search = val;
    },

    selectedDocType(val){

      // empty val = clearing the list!
      if(val === -1) {
        this.searchFilters = [];
      }else if(documentTypes[val]){
        // if we have a chart type element.

        this.searchFilters = [
          {
            prop: 'docType',
            value: [val]
          }
        ]
      } else if (val === 'celebrity') {
        this.searchFilters = [
          {
            prop: 'celebrity',
            value: [true]
          }
        ]
      } else {
        this.searchFilters = [] // for now we just clear the results
      }
      this.fetchCharts()
    }

  },
  data(){
    return{
      isLibrary: false,
      documentTypes,
      selectedDocType: -1,
      searchFilters: [],
      isSearch: true // super important variable that dictates how filters behave on the MIXIN
    }
  },
  mixins:[ChartLibraryMixin, RecentChartsListMixin],

  mounted(){
    this.search = this.searchText;
    if(this.isLibrary) return;
    this.getRecentChartsList();
    if(this.searchText) this.fetchCharts()
  },
  methods:{
    loadChart(chart){
      this.redirectTo('single-chart', null, {chartId: chart.id})
    },
    searchInLibrary(){
      this.$router.push({
        name: 'library',
        query: {
          search: this.searchText,
          celebrity: this.selectedDocType === 'celebrity' ? true : undefined
        }
      })
    }

  }
}
</script>