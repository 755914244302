<template>
  <div>
    <b-card class="mb-4 reverse-bg-mode">
      <b-card-body>
        <div class="row">
          <properties-item :item="{
            label: $t('properties.chartName'),
            value: chart.meta.name,
          }"/>

          <properties-item v-for="(value, key) in getData"
                           :key="key + value"
                           :item="{
                              label: $t(`properties.${key}`),
                              value: value.data,
                              icon: value.icon,
                              iconFontSize: 18
                           }"/>
        </div>

      </b-card-body>
    </b-card>

    <b-tabs v-if="chart" class="tabs-primary-underlined">
      <b-tab :title="chart.meta.person1.name">
        <div class="row mt-3 px-3 px-md-2">
          <summary-box v-if="chart.meta.person1.birthData.time?.utc" icon="icon-time-ticking"
                       :title="$t('properties.birthDateUTC')"
                       :value="formatDate(chart.meta.person1.birthData.time.utc, true) || chart.meta.person1.birthData.time.timezone.id"/>
          <personal-chart-summary :chart="getChart(0)" show-advanced />
        </div>
      </b-tab>
      <b-tab :title="chart.meta.person2.name">
        <div class="row mt-3 px-3 px-md-2 ">
          <summary-box v-if="chart.meta.person2.birthData.time?.utc" icon="icon-time-ticking"
                       :title="$t('properties.birthDateUTC')"
                       :value="formatDate(chart.meta.person2.birthData.time.utc, true) || chart.meta.person2.birthData.time.timezone.id"/>
          <personal-chart-summary :chart="getChart(1)" show-advanced />
        </div>
      </b-tab>
    </b-tabs>
  </div>

</template>

<script>
import ChartUtil from "@/mixins/ChartUtil";
import {getConnectionPropertyData} from "@/helpers/charts";
import PropertiesItem from "./PropertiesItem.vue";
import ChannelsSetupMixin from "@/mixins/ChannelsSetupMixin";
import {computeChart} from "@/helpers/rave/api";
import PersonalChartSummary from "@/components/charts/properties/summary/PersonalChartSummary.vue";
import docTypes from "@/helpers/docTypes";
import SummaryBox from "@/components/charts/properties/summary/partials/summary-box.vue";

export default {
  components: {SummaryBox, PersonalChartSummary, PropertiesItem},

  mixins: [ChartUtil, ChannelsSetupMixin],
  props: {
    chart: Object,
  },
  created() {
    this.setupChannelContent(this.chart)
  },
  computed: {
    docTypes() {
      return docTypes
    },
    getData() {
      return getConnectionPropertyData(this.chart, this.channels)
    },
  },
  methods: {
    getChart(id){
      const chartInfo = this.chart.meta[`person${id+1}`];
      return { meta: {
          birthData: chartInfo?.birthData,
        type: docTypes.RAVE_CHART
        }, chart: computeChart(this.chart.chart.planets.filter(x => x.chartId === id)) }
    },
  }
}
</script>
