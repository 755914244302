<template>
  <svg id="regular-planet-table" width="100%"
       :class="`orientation-${orientation}`"
       :viewBox="`0 0 91 ${getPlanets.length * (heightPerPlanet + spacePerPlanet) + (nameHeight*1.5)}`"
       fill="none" xmlns="http://www.w3.org/2000/svg">

    <text fill="currentColor" :transform="`translate(0, 30)`" class="planet-table-title" x="50" y="0"
          font-size="12"
          text-anchor="middle"
    >
      {{
        $t('hd.properties.' + (orientation === 0 ? 'design' :
            isTransitChart(chart) && (viewMode === viewModes.normal || viewMode === viewModes.personality)  ? 'transit.title' : 'personality'))
      }}
    </text>
    <line x1="0" y1="40" x2="91" y2="40" :stroke="orientation === 0 ? '#c25354' : '#434343'" stroke-width="2" />

    <g v-for="(planet,index) in getPlanets" :key="index"
       :id="`planet-${orientation}-${index}-${planet.chartId}`"
       :transform="`translate(0, ${((index) * (heightPerPlanet + spacePerPlanet)) + (nameHeight * 1.5)})`"
       class="planet-wrapper my-1 px-2 planet-box d-flex mx-auto align-items-center cursor-pointer"
       :class="`${getPlanetClasses} g${planet.gate}`"
       @click="onElementClicked(planet.planet, planet.chartId)"
       @mouseleave="clearAllHighlights"
       @mouseenter="planetHighlight(planet.planet, orientation, [planet.gate], planet.chartId)"
    >

      <path d="M0.5 5C0.5 2.23858 2.73858 0 5.5 0H80.5C86.0229 0 90.5 4.47715 90.5 10V33C90.5 35.7614 88.2614 38 85.5 38H10.5C4.97715 38 0.5 33.5228 0.5 28V5Z"
            fill="transparent" class="planet-bg"/>

      <g :clip-path="orientation === 0 ? 'url(#clip0_5076_67232)' : 'url(#clip0_5076_67343)'">
        <path v-if="planet.isDetriment"
              :d="orientation === 0 ?
              'M75 22L70.6699 15.25L79.3301 15.25L75 22Z' :
              'M16.5 22L12.1699 15.25L20.8301 15.25L16.5 22Z'"
              fill="currentColor"/>
        <path v-if="planet.isExalted"
              :d="
              orientation === 0 ?
              'M75 15L79.3301 21.75H70.6699L75 15Z' :
              'M16.5 15L20.8301 21.75H12.1699L16.5 15Z'"
              fill="currentColor"/>

        <path v-if="planet.isStar"
              :d="orientation === 0 ?
              'M75 14L76.3905 16.9621L79.5 17.4401L77.25 19.7445L77.781 23L75 21.4621L72.219 23L72.75 19.7445L70.5 17.4401L73.6095 16.9621L75 14Z' :
              'M16.5 14L17.8905 16.9621L21 17.4401L18.75 19.7445L19.281 23L16.5 21.4621L13.719 23L14.25 19.7445L12 17.4401L15.1095 16.9621L16.5 14Z'"
              fill="currentColor"/>
      </g>

      <text fill="currentColor"
            :x="orientation === 0 ? 30 : 60"
            y="23.795"
            xml:space="preserve" style="white-space: pre" font-family="Rubik" font-size="14"
            :text-anchor="orientation === 0 ? 'start' : 'end'"
            letter-spacing="0em"
      >
          {{ planet.gate }}.<tspan font-size="12">{{planet.line}}</tspan>
      </text>
      <planet-icons :index="index" :x="orientation === 0 ? '10' : '70'" y="24" font-size="13" />
     </g>

    <defs>
      <clipPath id="clip0_5076_67343">
        <rect width="10" height="11" fill="white" transform="translate(11.5 13)"/>
      </clipPath>
      <clipPath id="clip0_5076_67232">
        <rect width="10" height="11" fill="white" transform="translate(70 13)"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style lang="scss">
#regular-planet-table.orientation-0 {
  .planet-wrapper {
    &:hover, , &.highlight {
      > .planet-bg {
        fill: $design-bg;
      }
    }
  }
}
#regular-planet-table.orientation-1 {
  .planet-wrapper, &.highlight {
    &:hover {
      > .planet-bg {
        fill: $personality-bg;
      }
    }
  }
}
#regular-planet-table{
  .planet-table-icon-renderer{
    pointer-events: none;
    font-family: 'MyBodyGraph'!important;
  }
  svg {
    max-height:900px;
    @media(max-width:940px){
      max-height:75vh;
    }
  }
}
</style>

<script>

import planetConstants from "@/helpers/rave/constants/planets";
import {mapState} from "vuex";
import util from "@/mixins/util";
import fixings from "@/helpers/rave/constants/fixings";
import HighlightingMixin from "@/mixins/HighlightingMixin";
import ChartUtil from "@/mixins/ChartUtil";
import PlanetIcons from "./partials/PlanetIcons.vue";

export default {
  components: {PlanetIcons},

  mixins: [util, HighlightingMixin, ChartUtil],

  props: {
    orientation: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 600
    },
    interactive: Boolean,
    chart: Object
  },
  computed: {
    ...mapState({
      viewMode: state => state.ui.viewMode,
      viewModes: state => state.ui.viewModes
    }),

    activation() {
      return this.orientation === 0 ? 'design' : 'personality'
    },
    getPlanetClasses() {
      let classes = this.activation === 'design' ? 'bg-hover-design' : 'bg-hover-personality';
      return classes;
    },
    getPlanets() {

      // special case for connection charts single user rendering
      // we consider the "design" view mode to be "First chart Only" and the "personality" view mode to be "Second Chart Only"
      if (this.isConnectionChart(this.chart) || this.isCycleChart(this.chart)) {
        return this.planets.filter(x => x.activation === this.orientation && x.chartId === (this.viewMode === this.viewModes.design ? 0 : 1))
      }

      if (this.isPersonTransitChart(this.chart)) {
        return this.planets.filter(x => x.activation === this.orientation && x.chartId ===
            (this.viewMode === this.viewModes.design ? 0 : 1))
      }

      if (this.viewMode === this.viewModes.cross) {
        return this.planets.filter(x => x.activation === this.orientation && (x.planet === planetConstants.EARTH || x.planet === planetConstants.SUN))
      }
      return this.planets.filter(x => x.activation === (this.activation === 'design' ? 0 : 1))
    }
  },
  data() {
    return {
      planets: [],
      heightPerPlanet: 38,
      spacePerPlanet: 5,
      nameHeight: 30,
    }
  },
  created() {
    this.setupPlanetContent();
  },
  methods: {
    setupPlanetContent() {
      let planets = [];
      for (let planet of this.chart.chart.planets) {
        const planetObject = {
          icon: planetConstants.icons[planet.id],
          activation: planet.activation,
          planet: planet.id,
          isExalted: planet.fixing.state === fixings.EXALTED,
          isDetriment: planet.fixing.state === fixings.DETRIMENT,
          isStar: planet.fixing.state === fixings.JUXTAPOSED,
          name: this.$t('hd.planets.' + planet.id + '.name'),
          gate: planet.gate,
          line: planet.line,
          tone: planet.tone,
          color: planet.color,
          chartId: planet.chartId
        }
        planets.push(planetObject)
      }
      this.planets = planets;
    },
    onElementClicked(planetId, chartId) {
      if (!this.interactive) return;
      let activation = this.activation === 'design' ? 0 : 1;

      this.fixUrlQuery('?tab=planetary-activations' + '&selected=' + planetId + '&activation=' + activation + '&chart=' + chartId);
      // add the URL params so that the properties panel show the correct content

      this.$nextTick(() => {
        this.$store.dispatch('ui/increasePropertiesKey'); // increase key to update window
        this.$store.commit('ui/SET_SHOW_PROPERTIES', true); // show panel in case it's not showing
      });
    }
  }
}
</script>