<template>

  <div>
    <div v-if="!showStrictDates" class="grid grid-cols-12 gap-12 mt-3 align-items-center">
      <div class="col-span-1">
        <span class="icon-hourglass"></span>

      </div>
      <div :key="refreshKey" class="col-span-10">
        <p class="watch-table-label" style="min-height:30px;">Time Left:
          <br> {{calculateRealTimeForActivation(planetId, type, changes)?.replace('in ', '')}}</p>
        <b-progress :id="`bar-progress-${planetId}`" variant="transit" label="%" :value="calculateTimeForActivation(planetId, type).percentage" style="height:6px;" animated> </b-progress>
        <b-tooltip :target="`bar-progress-${planetId}`" variant="primary">{{ getPercentageNum(calculateTimeForActivation(planetId, type).percentage) }}%</b-tooltip>

      </div>
    </div>
    <div v-if="showDuration" class="grid grid-cols-12 gap-12 mt-3 align-items-center">
      <div class="col-span-1">
        <span class="icon-time-ticking"></span>
      </div>
      <div class="col-span-10">
        <p class="watch-table-label">{{ $t('charts.transit.duration') }}</p>
        <p class="watch-table-value">
          <span :key="slowRefreshKey">
               {{calculateTimeDifference(changes[planetId]['currentData'][activatedType].datetime, changes[planetId][type].datetime, false)}}
          </span>
          <b-button variant="link" class="ml-2" style="padding:0!important" v-b-tooltip.hover
                    :title="formatDate(changes[planetId]['currentData'][activatedType].datetime) + ' - ' + formatDate(changes[planetId][type].datetime)">
            <span class="icon-help"></span>
          </b-button>
        </p>

      </div>

    </div>
    <div v-if="showActivated" class="grid grid-cols-12 gap-12 mt-3 align-items-center">
      <div class="col-span-1">

        <span class="icon-arrow-circle-diagonal"></span>
      </div>

      <div class="col-span-10">
        <p class="watch-table-label">{{ $t('charts.transit.activated') }}</p>

        <p class="watch-table-value">
          <span  :key="slowRefreshKey" v-if="showStrictDates">
            {{formatDate(changes[planetId]['currentData'][activatedType].datetime)}}
          </span>
          <span :key="slowRefreshKey"  v-else>
            {{calculateTimeDifference(changes[planetId]['currentData'][activatedType].datetime)}} ago
          </span>
          <b-button variant="link" class="ml-2" style="padding:0!important" v-b-tooltip.hover :title="formatDate(changes[planetId]['currentData'][activatedType].datetime)">
            <span class="icon-help"></span>
          </b-button>
          </p>
      </div>
    </div>
  </div>

  
</template>

<script>
export default{
  props:{
    refreshKey: Number,
    slowRefreshKey: Number,
    changes: Object,
    planetId: Number,
    type: String,
    showDuration: {
      type: Boolean,
      default: false
    },
    showActivated:{
      type: Boolean,
      default: false
    },
    showStrictDates : {
      type: Boolean,
      default: false,
    },
    calculateRealTimeForActivation: Function,
    calculateTimeForActivation: Function,
    calculateTimeDifference: Function,
  },

  computed:{
    activatedType(){
      return this.type.toLowerCase().includes('line') ? 'lineStartDate' : 'gateStartDate'
    }
  },
  methods:{

    getPercentageNum(num){
      try{
        return num.toFixed(1)
      }catch(e){
        return '-'
      }
    },
  }
}
</script>