
<template>

  <div id="add-new-transit">
    <mbg-chart-picker v-model="selectedChart" show-recents :title="`${$t('pages.newChart.selectChart')} (${$t('pages.newChart.optional')})`"/>

    <mbg-text-input v-model="name" :title="$t('properties.chartName')" :placeholder="$t('general.enterName')"
                    input-classes="new-chart-input" type="text" class="mt-4" />


    <b-form-group class="custom-radios">
      <b-form-radio v-model="selectedTime"  name="transit-radios" value="just-now"> {{ $t('pages.newChart.justNow') }}</b-form-radio>
      <b-form-radio v-model="selectedTime"  name="transit-radios" value="custom">{{ $t('pages.newChart.customDate') }}</b-form-radio>
    </b-form-group>

    <div v-if="selectedTime === 'custom'">
      <mbg-date-time-input v-model="date" type="text"
                           input-classes="new-chart-input"
                           :format="dateFormat"
                           :title="$t('pages.newChart.transitDate')"
                           :placeholder="dateFormat.toLowerCase().split('/').join(' / ')" :validation="v$.date"
      />

      <mbg-date-time-input v-model="time" type="text"
                           input-type="hour"
                           input-classes="new-chart-input"
                           :title="$t('pages.newChart.' + (useLocal ? 'transitTimeLocal' : 'transitTime'))" :placeholder="'hh : mm'" :validation="v$.time"/>

      <div class="">
        <b-form-checkbox v-model="useLocal" name="check-button" class="" switch @input="setCurrentTime">
          {{ $t('pages.newChart.useLocal')  }}
          <br>
          <span class="mbg-text-muted">
          {{$t('pages.newChart.useLocalExplain')}}
          </span>
        </b-form-checkbox>
      </div>

    </div>
    <div class="mt-4 w-xl-50">
      <b-button variant="primary" block @click="createChart(false)" :disabled="!fieldsFilled || hasErrors">{{ $t('pages.newChart.create') }}</b-button>
    </div>

  </div>
</template>


<script>

import MbgChartPicker from "../elements/MbgChartPicker";
import MbgTextInput from "../elements/MbgTextInput";
import MbgDateTimeInput from "../elements/MbgDateTimeInput";
import moment from "moment";
import useVuelidate from "@vuelidate/core";
import {helpers, required} from "@vuelidate/validators";
import TransitChartMixin from "../../mixins/TransitChartMixin";
import {mapState} from "vuex";
import {DATE_FORMATS} from "../../helpers/user_settings";
const checkDateValidity = (data, vm) => {
  const date = moment(data, vm.dateFormat);
  return date.isValid() && !date.isBefore('1800-02-01') && !date.isAfter('2099-12-31');
}

const checkTimeValidity = (data, vm) => {
  const time = moment(data, vm.timeFormat);
  return time.isValid();
}


export default{

  mixins: [TransitChartMixin],
  components:{MbgChartPicker, MbgTextInput, MbgDateTimeInput},
  computed:{
    ...mapState({
      dateInputSetting: state => state.account.settings.date_input
    }),

    dateFormat(){
      return DATE_FORMATS[this.dateInputSetting || 0]
    },
    hasErrors(){
      return this.v$ ? this.v$.$errors.length > 0 : false
    },

    fieldsFilled(){
      return this.date && this.time
    },
    CurrentTimeZoneName () {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    },
  },
  watch:{
    updating(val){
      this.$emit('updating', val);
    },
    date(){
      if(!this.hasErrors) this.createChart(true);
    },
    time(){
      if(!this.hasErrors) this.createChart(true);
    },
    selectedChart(){
      if(!this.hasErrors) this.createChart(true);
    }
  },

  setup () {
    return { v$: useVuelidate() }
  },
  validations: {
    date:{
      required,
      checkEquality: helpers.withMessage(() => {
        return 'errors.invalidDate'
      }, checkDateValidity),
      $autoDirty: true
    },
    time:{
      required,
      checkEquality: helpers.withMessage(() => {
        return 'errors.invalidTime'
      }, checkTimeValidity),
      $autoDirty: true
    }
  },
  data(){
    return{
      useLocal: true,
      selectedChart: null,
      selectedTime: 'just-now',
      date: null,
      name: null,
      time:  null,
      timeFormat: 'HH:mm',
      updating: false,
      fetching: false,
    }
  },
  mounted(){
   this.setCurrentTime();
  },
  methods:{
    setCurrentTime(){
      this.date = this.useLocal ? moment().format(this.dateFormat) : moment().utc().format(this.dateFormat)
      this.time = this.useLocal ? moment().format(this.timeFormat) : moment().utc().format(this.timeFormat)
      this.createChart(true);
    },
    createChart(skipNavigation = false){
      if (this.fetching) return;
      if(!skipNavigation) this.updating = true;
      this.fetching = true;

      const finalDate = this.useLocal ?
            moment(this.date + ' ' +  this.time, `${this.dateFormat} ${this.timeFormat}`).utc().format(`${this.dateFormat} ${this.timeFormat}`)
          : moment(this.date + ' ' +  this.time, `${this.dateFormat} ${this.timeFormat}`).format(`${this.dateFormat} ${this.timeFormat}`);

      const [date, time] = finalDate.split(' ');
      this.createTransitWrapper({
        dateFormat: this.dateFormat,
        timeFormat: this.timeFormat,
        dateStamp: date,
        timeStamp: time,
        chart: this.selectedChart,
        isCustom: this.selectedTime === 'custom',
        skipNavigation
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(()=>{
        this.updating = false;
        this.fetching = false;
      })
    }
  }


}
</script>