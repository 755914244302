import circuits from './circuits'

const channels = [
  { id: 0, gates: [10, 20], key: '20-10',circuit: circuits.INTEGRATION },
  { id: 1, gates: [34, 20], key: '20-34',circuit: circuits.INTEGRATION },
  { id: 2, gates: [57, 34], key: '34-57',circuit: circuits.INTEGRATION },
  { id: 3, gates: [10, 57], key: '10-57',circuit: circuits.INTEGRATION },
  { id: 4, gates: [61, 24], key: '61-24',circuit: circuits.KNOWING },
  { id: 5, gates: [43, 23], key: '43-23',circuit: circuits.KNOWING },
  { id: 6, gates: [28, 38], key: '28-38',circuit: circuits.KNOWING },
  { id: 7, gates: [20, 57], key: '57-20',circuit: circuits.KNOWING },
  { id: 8, gates: [55, 39], key: '39-55',circuit: circuits.KNOWING },
  { id: 9, gates: [12, 22], key: '12-22',circuit: circuits.KNOWING },
  { id: 10, gates: [3, 60], key: '60-3',circuit: circuits.KNOWING },
  { id: 11, gates: [2, 14], key: '2-14',circuit: circuits.KNOWING },
  { id: 12, gates: [8, 1], key: '8-1',circuit: circuits.KNOWING },
  { id: 13, gates: [10, 34], key: '10-34',circuit: circuits.CENTERING },
  { id: 14, gates: [51, 25], key: '51-25',circuit: circuits.CENTERING },
  { id: 15, gates: [63, 4], key: '63-4',circuit: circuits.LOGIC },
  { id: 16, gates: [17, 62], key: '17-62',circuit: circuits.LOGIC },
  { id: 17, gates: [18, 58], key: '18-58',circuit: circuits.LOGIC },
  { id: 18, gates: [16, 48], key: '48-16',circuit: circuits.LOGIC },
  { id: 19, gates: [9, 52], key: '9-52',circuit: circuits.LOGIC },
  { id: 20, gates: [15, 5], key: '5-15',circuit: circuits.LOGIC },
  { id: 21, gates: [31, 7], key: '31-7',circuit: circuits.LOGIC },
  { id: 22, gates: [30, 41], key: '30-41',circuit: circuits.ABSTRACT },
  { id: 23, gates: [35, 36], key: '35-36',circuit: circuits.ABSTRACT },
  { id: 24, gates: [64, 47], key: '47-64',circuit: circuits.ABSTRACT },
  { id: 25, gates: [11, 56], key: '56-11',circuit: circuits.ABSTRACT },
  { id: 26, gates: [42, 53], key: '53-42',circuit: circuits.ABSTRACT },
  { id: 27, gates: [46, 29], key: '46-29', circuit: circuits.ABSTRACT },
  { id: 28, gates: [33, 13], key: '33-13',circuit: circuits.ABSTRACT },
  { id: 29, gates: [32, 54], key: '54-32',circuit: circuits.EGO },
  { id: 30, gates: [26, 44], key: '26-44',circuit: circuits.EGO },
  { id: 31, gates: [19, 49], key: '49-19',circuit: circuits.EGO },
  { id: 32, gates: [40, 37], key: '37-40',circuit: circuits.EGO },
  { id: 33, gates: [45, 21], key: '45-21',circuit: circuits.EGO },
  { id: 34, gates: [59, 6], key: '59-6',circuit: circuits.DEFENSE },
  { id: 35, gates: [50, 27], key: '27-50',circuit: circuits.DEFENSE }
]

export const integrationChannelIds = [0, 1, 2, 3, 7, 13]

export default channels
