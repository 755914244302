<template>

  <div id="iching">
    <b-overlay :show="loading" rounded="sm">

      <div v-if="!selectedGate">
        <p v-if="!showMore">{{ $t('owl.iching.intro') }}</p>
        <div v-else v-html="$t('owl.iching.data')"/>
        <b-button class="btn-primary-link" variant="link" @click="showMore = !showMore">
          {{ showMore ? $t('general.close') : $t('general.showMore') }}
        </b-button>

        <b-tabs v-if="hasSubscription" v-model="activeTab" class="tabs-primary-underlined knowledge-base-tabs mt-2"
                :class="darkMode ? 'theme--dark' : 'theme--light'">

          <b-tab :title="tabs[0].title">
            <IchingGateList :gates="gates" @gateClicked="openIchingGateContent"/>
          </b-tab>
          <b-tab :title="tabs[1].title">
            <IchingCenters :gates="gates" @gateClicked="openIchingGateContent"></IchingCenters>
          </b-tab>
          <b-tab :title="tabs[2].title">
            <IchingCircuits :gates="gates" @gateClicked="openIchingGateContent"></IchingCircuits>
          </b-tab>
        </b-tabs>

        <locked-content v-else
                        title="Access Rave I'Ching"
                        subtitle="Subscribe to any of our plans to unlock the complete Rave I'Ching"
                        button-text="Subscribe now"
                        @clickedUnlock="subscribe"
        />
      </div>

      <IchingGateContent v-else show-pagination :gate="selectedGate" @close="openIchingGateContent(null)" :lines="lines" @paginate="openIchingGateContentById"/>

    </b-overlay>

  </div>


</template>

<script>
import IchingGateList from "./partials/IchingGateList.vue";
import IchingGateContent from "./partials/IchingGateContent.vue";
import centers from "../../../helpers/rave/constants/centers";
import IchingCenters from "./partials/IchingCenters.vue";
import IchingCircuits from "./partials/IchingCircuits.vue";
import LockedContent from "../../billing/LockedContent.vue";
import {mapState} from "vuex";
import {localStorageKeys} from "../../../utils/localStorage";
export default {
  computed: {
    ...mapState({
      subscriptions: state => state.account.subscriptions
    }),
    centers() {
      return centers
    }
  },
  components: {LockedContent, IchingCircuits, IchingCenters, IchingGateContent, IchingGateList},

  watch:{
    subscriptions(){
      this.fetchIchingData();
    }
  },
  data() {
    return {
      showDetails: false,
      selectedGate: null,
      gates: [],
      lines: [],
      activeTab: 0,
      hasSubscription: true,
      showMore: false,
      loading: false,
      search: '',
      tabs: [
        {
          title: this.$t('hd.gates.name.many'),
        },
        {
          title: this.$t('hd.properties.centers'),
        },
        {
          title: this.$t('charts.properties.circuits'),
        }
      ]
    }
  },

  created() {
    this.fetchIchingData();
  },
  methods:{
    openIchingGateContentById(gateId) {
      const gate = this.gates.find(x => x.gate_id === gateId);
      this.openIchingGateContent(gate);
    },

    openIchingGateContent(gate){
      this.selectedGate = gate;

      if(gate === null){
        const scrollPos = sessionStorage.getItem(localStorageKeys.ICHING_SCROLL_POSITION);
        sessionStorage.removeItem(localStorageKeys.ICHING_SCROLL_POSITION);
        if(scrollPos && !isNaN(parseInt(scrollPos))){
          this.$nextTick(()=>{
            document.getElementById('dictionary-side-panel-content').scrollTo(0, parseInt(scrollPos));
          });
          return;
        }
      }
      document.getElementById('dictionary-side-panel-content').scrollTo(0,0);
    },
    subscribe(){
      this.$store.commit('ui/SET_SHOW_OWL', false);
      this.$store.commit('checkout/setCheckoutType', this.CheckoutTypes.SUBSCRIPTION);
      this.$router.push('/checkout')
    },
    fetchIchingData(){

      this.loading = true;
      this.axios.get('/content/iching').then(res =>{
        if(res.data.requires_subscription){
          this.hasSubscription = false;
          return;
        }
        this.hasSubscription = true;
        this.gates = Object.values(res.data.gates).sort((a,b) => a.gate_id - b.gate_id);
        this.lines = res.data.lines;
      }).finally(()=>{
        this.loading= false;
      });

    },
  },

}
</script>