<template>
  <div id="related-links" class="px-0 px-md-1">
    <div class="center">
      <p class="my-2">{{ $t('hd.properties.centers') }}</p>
      <div class="grid grid-cols-1 gap-5">
        <related-link-toggle v-for="center in getCenters" :key="`center-${center.id}`"
                             type="center"
                             :render-props="{
                                  id: center.id,
                                  centerId: center.id,
                                  state: center.state,
                                  reverseBackground: !isNested
                             }"
                             :depth="depth"
                             :is-nested="isNested"
                             :is-reverse-background="isNested"
                             :label="`<span class='text-md icon-center-${center.icon}'></span>`"
                             :text="center.state + ' ' + $t('hd.centers.'+ center.id + '.name') + ' ' + $t('hd.objects.center')"
         />
      </div>
    </div>
    <div class="channel">
      <p class="my-2">{{ $t('hd.gates.name.many') }}</p>
      <div class="grid grid-cols-1 gap-5">
        <related-link-toggle v-for="gate in getGates" :key="`gate-${gate.id}`"
                             type="gate"
                             :render-props="{
                                  id: gate,
                                  gateId: gate,
                                  reverseBackground: !isNested
                              }"
                             :depth="depth"
                             :is-nested="isNested"
                             :is-reverse-background="isNested"
                             :label="getGateToggleLabel(gate)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import util from "../../../../mixins/util";
import {mapState} from "vuex";
import channels from "@/helpers/rave/constants/channels";
import centers from "../../../../helpers/rave/constants/centers";
import RelatedLinkToggle from "./partials/RelatedLinkToggle.vue";
import RelatedLinksMixin from "../../../../mixins/RelatedLinksMixin";

export default{
  components: {RelatedLinkToggle},
  mixins: [util, RelatedLinksMixin],
  props:{
    selectedId: Number,
    isNested: Boolean,
    depth: Number,
  },
  computed:{
    ...mapState({
      chart_data: state => state.charts.chart.chart,
      tabs: state => state.ui.tabsMapping
    }),
    getChannel(){
      return channels.find(x => x.id === this.selectedId)
    },
    getGates(){
      return this.getChannel.gates;
    },
    getCenters(){
      let gates = this.getGates;
      let centerList = [];
      for(let gate of gates){
        let center = centers.centersList.find(x => x.gates.includes(gate));
        center.state = this.chart_data.centers[center.id] === centers.centerDefinitionStates.DEFINED ?
            this.$t('hd.constants.centerDefinitionStates.2') : this.$t('hd.constants.centerDefinitionStates.1')
        centerList.push(center);
      }
      return centerList;

    },
  },
}
</script>