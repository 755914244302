<template>
  <div id="work_success-analysis-wrapper">
    <b-tabs :value="workSuccessTab"
            class="vertical-side-tabs mobile-hidden" pills card vertical end nav-wrapper-class="tabs-panel"
            lazy
            @input="(id) => setTab(tabId, id)" >
      <b-tab v-for="(tab,index) in tabs" :key="`${tabId}-${tab.id}`">

        <template #title>
          <TabTitle :title="tab.name" :icon="tab.icon" :centered="true"
                    :is-locked="!isUnlocked && index !==0"
                    :is-completed="isTabSectionCompleted('work_success', tab.id)"
          />
        </template>

        <b-overlay :show="loading" rounded="sm">
          <b-card class="px-0 pt-4 analysis-card overflow-hidden">

            <component :is="tab.component" :id="tab.id"
                       :has-prev="index > 0"
                       :has-next="index < tabs.length - 1"
                       :total-tabs="tabs.length"
                       @prevSection="setTab(tabId, index - 1, true)"
                       @nextSection="setTab(tabId, index + 1)"
                       @loading="loadingChanged"
                       @innerTabChanged="innerTabChanged">
              <template #unlock-prompt>
                <unlock-content-prompt v-if="!isUnlocked" category="work_success" :chart-id="$store.state.charts.chart.id" coming-soon-mode />
              </template>
            </component>


          </b-card>


        </b-overlay>

      </b-tab>
    </b-tabs>

    <mobile-bottom-tabs :items="tabs" :value="workSuccessTab" @input="(id) => setTab(tabId, id)" />
  </div>
</template>

<script>
import TabTitle from "../../../elements/TabTitle";
import introduction from "./introduction";
import MobileBottomTabs from "../../../structure/MobileBottomTabs";
import UnlockContentPrompt from "../../../billing/UnlockContentPrompt";
import AnalysisRouterMixin from "../../../../mixins/AnalysisRouterMixin";
import wcType from "@/components/charts/analysis/work_success/wc-type.vue";
import wcDefinition from "@/components/charts/analysis/work_success/wc-definition.vue";
import wcGates from "@/components/charts/analysis/work_success/wc-gates.vue";
import wcChannels from "@/components/charts/analysis/work_success/wc-channels.vue";
import wcProfile from "@/components/charts/analysis/work_success/wc-profile.vue";
import wcProfitCenters from "@/components/charts/analysis/work_success/wc-profit-centers.vue";
export default{

  mixins: [AnalysisRouterMixin],
  components:{UnlockContentPrompt, TabTitle, MobileBottomTabs},

  computed:{
    isUnlocked(){
      return this.$store.state.charts.chartStatus.work_success
    },
  },

  watch: {
    workSuccessTab(val){
      this.fixQuery(this.tabId, val)
    }
  },
  created(){
    this.setup(this.tabId)
  },
  data(){
    return {
      tabId: 'personal_success_tab',
      loading: false,
      tabs: [
        {id: 0, icon: 'icon-v2-work-success', component: introduction, name: this.$t('charts.analysis.work_success.tabs.0')},
        {id: 1, icon: 'icon-person-circle', component: wcType, name: this.$t('charts.analysis.work_success.tabs.1')},
        {id: 2, icon: 'icon-definition', component: wcDefinition, name: this.$t('charts.analysis.work_success.tabs.2')},
        {id: 3, icon: 'icon-v2-penta-gates', component: wcGates, name: this.$t('charts.analysis.work_success.tabs.3')},
        {id: 4, icon: 'icon-v2-penta-channels', component: wcChannels, name: this.$t('charts.analysis.work_success.tabs.4')},
        {id: 5, icon: 'icon-v2-profile', component: wcProfile, name: this.$t('charts.analysis.work_success.tabs.5')},
        {id: 6, icon: 'icon-v2-profit-centers', component: wcProfitCenters, name: this.$t('charts.analysis.work_success.tabs.6')},
      ]
    }
  },
  methods:{
    loadingChanged(val){
      this.loading = val;
    }
  }
}
</script>

<style lang="scss">
#work_success-analysis-wrapper {
  .tabs{
    .nav-link{
      font-size:0.9rem;
      font-weight:300;
      &.active{
        font-weight:600;
      }
    }
  }
  > .card-body{
    padding:0;
  }
}
.analysis-card{
  > .card-body{
    padding:0!important;
  }
}
</style>