import moment from 'moment';
import {config} from "@/utils/config";

export default {
    data() {
        return {
            chartsUrl: config.chartsUrl,
            planetaryUrl: config.planetaryUrl,
            endpoints: {
                FREE_CHART: (url) => `${url}/api/charts/free-chart`,
                RAVE_CHART: url => `${url}/api/charts/`,
                SAVE_CHART: url => `${url}/api/charts/single/save`,
                TRANSIT_CHART: (url) => `${url}/api/charts/transit-chart`,
                UPDATE_TRANSIT_CHART: (url) => `${url}/api/charts/transit-chart/update`,
                SINGLE_CHART: (url, chartId) => `${url}/api/charts/${chartId}`,
                CONNECTION_CHART: (url) => `${url}/api/charts/connection-chart`,
                SOLAR_RETURN: (url) => `${url}/api/planetary/sun-return`,
                LUNAR_RETURN: (url) => `${url}/api/planetary/moon-return`,
                IMPORT_CHART: (url) => `${url}/api/charts/import`,
                IMPORT_STATUS: (url,importId) => `${url}/api/charts/import-status/${importId}`,
            }
        }
    },

    methods: {
        // used to get sun returns for chart based on year.
        async getSolarReturn({
                                 year,
                                 position,
                                 date
                             }) {

            return this.axios.post(this.endpoints.SOLAR_RETURN(this.planetaryUrl), {
                year, position, date
            }).then((res) => {
                return res.data.timestamp;
            })
        },

        async getLunarReturns({
                                  year,
                                  position,
                                  date
                              }) {

            return this.axios.post(this.endpoints.LUNAR_RETURN(this.planetaryUrl), {
                year, position, date
            }).then((res) => {
                return res.data.map(x => x.timestamp);
            })
        },

        async createJustNowChart() {

            const now = moment.utc();

            const data = {
                timeInUtc: true,
                autosave: false,
                time: now.format(),
                name: `${this.$t('misc.justNow')} - ${now.format('LLL')} UTC`
            }

            return this.axios.post(this.endpoints.FREE_CHART(this.chartsUrl), data).then(res => {
                return {
                    chart: res.data,
                    now: now
                }
            }).catch(e => {
                return e;
            })

        },

        async createRaveChart(data) {
            return this.axios.post(this.endpoints.RAVE_CHART(this.chartsUrl), data).then(res => {
                return res.data;
            })
        },

        async createFreeChart(data) {
            return this.axios.post(this.endpoints.FREE_CHART(this.chartsUrl), data).then(res => {
                return res.data;
            })
        },

        async updateRaveChart(chartId, data) {

            return this.axios.put(this.endpoints.SINGLE_CHART(this.chartsUrl, chartId), data).then(res => {
                return res.data;
            })

        },

        async createConnectionChart(data) {
            return this.axios.post(this.endpoints.CONNECTION_CHART(this.chartsUrl), data).then(res => {
                return res.data;
            })
        },

        async createTransitChart(data) {
            return this.axios.post(this.endpoints.TRANSIT_CHART(this.chartsUrl), data).then(res => {
                return res.data;
            })
        },

        async updateTransitChart(data) {
            return this.axios.post(this.endpoints.UPDATE_TRANSIT_CHART(this.chartsUrl), data).then(res => {
                return res.data;
            })
        },

        async saveTempChart(doc) {
            return this.axios.put(this.endpoints.SAVE_CHART(this.chartsUrl), {chart: doc}).then(res => {
                return res.data
            })
        },
    }
}