import * as R from 'ramda'
import { ensureArray } from './misc'

const listIncludes = R.curry((partialMatch, list, prop, values) => {
  if (!prop || !values || values.length === 0) return []

  let matchPolicy = partialMatch === false ? R.allPass : R.anyPass

  const test = R.pipe(
    ensureArray,
    R.map(R.includes),
    matchPolicy
  )(values)

  const isMatching = R.pipe(
    R.prop(prop),
    test
  )

  const find = R.pipe(
    R.values,
    R.filter(isMatching)
  )
  return find(list)
})

export const getWhereNestedCollectionIncludesAll = listIncludes(false)

export const getWhereNestedCollectionIncludesAny = listIncludes(true)
