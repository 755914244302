import axios from "axios";
import moment from "moment";
import {setLocalStorageItem, getLocalStorageItem} from "@/utils/localStorage";
import localforage from "localforage";
import {config} from "@/utils/config";


const state = {
    chart: {},
    chartKey: 0,
    chartProgress: null,
    chartStatus: {
        foundational: false,
        advanced: false,
        companionship: false,
        work_success: false,
    },
    activeFilters: getLocalStorageItem('active_filters') || [],
    importId: getLocalStorageItem('import_id') || null,
    importCompleted: false,
    personalChart: {},
    personalChartProgress: null,
    transitNotifications: [],
    transit: {
        key: 0,
        changes: null,
        justNow: false,
        justNowTimer: null,
        activationSelectedPlanet: 2,
        transitsSelectedPlanet: 2,
        transitsSelectedPlanetTab: 0,
        transit_range_month_cached: [],
        transit_range_changes: {}
    },
    newChartAnimationClasses: [],
}

const mutations = {

    SET_NEW_CHART_ANIMATION_CLASSES(state, classes){
        state.newChartAnimationClasses = classes;
    },

    SET_CHART(state, chart){
        state.chart = {...chart};
        state.chartKey++;
    },

    SET_IMPORT_ID(state, id) {
        state.importId = id;
        setLocalStorageItem('import_id', id, false);
    },

    SET_IMPORT_COMPLETED(state, val) {
        state.importCompleted = val;
    },

    SET_CHART_META(state, data){
         if (data.meta) state.chart.meta = data.meta;
         if (data.tags) state.chart.tags = data.tags;
    },
    SET_CHART_STATUS(state, status) {
        state.chartStatus = status
    },
    SET_ACTIVE_FILTERS(state, filters){
        state.activeFilters = [...filters];
        setLocalStorageItem('active_filters', filters, true)
    },
    SET_PERSONAL_CHART(state, chart){
        state.personalChart = {...chart}
    },
    SET_PERSONAL_CHART_PROGRESS(state, progress) {
        state.personalChartProgress = progress
    },

    SET_TRANSIT_CHANGES(state, changes){
        state.transit.changes = changes.planetsInfo;
    },

    SET_CHART_PROGRESS(state, progress) {
        state.chartProgress = progress;
    },

    SET_JUST_NOW_TIMER(state, timer) {
        state.justNowTimer = timer;
    },

    ADD_TRANSIT_NOTIFICATION(state, notification) {
        const NOTIFICATIONS_LIMIT = 5;

        const existing = state.transitNotifications.findIndex(x => x.planet === notification.planet);
        // remove existing notification of that planet.
        if (existing > -1) {
            state.transitNotifications.splice(existing, 1);
        }

        if(state.transitNotifications.length >= NOTIFICATIONS_LIMIT){
            state.transitNotifications.pop();
        }
        state.transitNotifications.unshift(notification);
    },

    CLEAR_NOTIFICATION_BY_DATE_TIME(state, item) {
        state.transitNotifications = state.transitNotifications.filter(x => x.time !== item.time);
    },

    CLEAR_TRANSIT_NOTIFICATIONS(state) {
        state.transitNotifications = [];
    },

    SET_TRANSIT_RANGE_DATA(state, data){
        if(state.transit.transit_range_month_cached.includes(data.year_month)) return;
        // const MOON_KEY = "2";
        state.transit.transit_range_month_cached.push(data.year_month);

        // check next data for overlapping issues.
        // start with initial data.
        let previous = {
            month: parseInt(data.year_month.substring(5)),
            year: parseInt(data.year_month.substring(0,4)),
            value: null
        }
        let next = {
            month: parseInt(data.year_month.substring(5)),
            year: parseInt(data.year_month.substring(0,4)),
            value: null
        }
        if (previous.month > 0) {
            previous.month -= 1;
        } else {
            previous.month = 11;
            previous.year -= 1;
        }
        previous.value = previous.year + '_'+ previous.month;

        if (next.month < 11) {
            next.month += 1;
        } else {
            next.month = 0;
            next.year += 1;
        }
        next.value = next.year + '_'+next.month;

        try{
            for(let key of Object.keys(data.transit_range)) {
                data.transit_range[key].map(x => x.year_month = data.year_month);

                if (!state.transit.transit_range_changes[key]) {
                    state.transit.transit_range_changes[key] = {};
                }

                // check if we need to fix the ending of a phase or the starting of another phase.
                const previousValue = state.transit.transit_range_changes[key][previous.value];
                const nextValue = state.transit.transit_range_changes[key][next.value];

                if (previousValue && previousValue.length > 0){
                    const lastElem = previousValue[previousValue.length-1];
                    const firstNewElem = data.transit_range[key][0];

                    if (firstNewElem && lastElem &&
                        lastElem.gate === firstNewElem.gate &&
                        lastElem.line === firstNewElem.line &&
                        lastElem.retrograde === firstNewElem.retrograde
                    ) {
                        data.transit_range[key].splice(0, 1); // remove first elem!
                    }
                }

                if (nextValue && nextValue.length > 0) {
                    const firstElem = nextValue[0];
                    const lastNewElem = data.transit_range[key][data.transit_range[key].length -1];

                    if (firstElem && lastNewElem &&
                        firstElem.gate === lastNewElem.gate &&
                        firstElem.line === lastNewElem.line &&
                        firstElem.retrograde === lastNewElem.retrograde
                    ) {
                        nextValue.splice(0, 1); // remove first element of "next" array!
                    }
                }

                state.transit.transit_range_changes[key][data.year_month] = data.transit_range[key]
            }
        }catch(e){
            console.log(e);
        }
    },
    SET_TRANSIT_JUST_NOW(state, val){
        state.transit.justNow = val;
    },
    SET_TRANSIT_KEY(state, val){
        state.transit.key = val;
    },
    SET_TRANSIT_ACTIVATION_SELECTED_PLANET(state, val){
        state.transit.activationSelectedPlanet = val;
    },
    SET_TRANSIT_SELECTED_PLANET(state, val){
        state.transit.transitsSelectedPlanet = val;
    },
    SET_TRANSIT_SELECTED_PLANET_TAB(state, val){
        state.transit.transitsSelectedPlanetTab = val;
    },
    RESET_TRANSIT(state){
        state.transit.key = 0;
        state.transit.changes = null;
        state.transit.justNow = false;
        state.transit.activationSelectedPlanet = 2;
        state.transit.transitsSelectedPlanet = 2;
        state.transit.transitsSelectedPlanetTab = 0;
    },

    RESET_PROGRESS(state) {
        state.chartProgress = null;
    }
}

const getters = {

    transitUtcDate(state){
        try{
            return state.chart.meta.transit.time.utc
        }catch(e){
            return null;
        }
    },
}

const actions = {

    cleanUp(context){

        context.commit('SET_CHART', {});
        context.commit('SET_CHART_PROGRESS', {});
        context.commit('SET_PERSONAL_CHART', {});
        context.commit('SET_PERSONAL_CHART_PROGRESS', {});
        context.commit('SET_CHART_STATUS', {
            foundational: false,
            advanced: false,
            companionship:false,
            work_success: false
        });

        localforage.clear().then(()=>{}).catch(()=>{});
        localStorage.clear();
    },
    // init(context){
    //     context;
    //     // return context.dispatch('getChartsMicroserviceToken');
    // },

    setChart(context, chart){
        context.commit('SET_CHART', chart);
    },
    // filter = {prop: String, value: Number/String}
    updateFilter(context, filter){
        let filters = [...context.state.activeFilters];
        let existingFilter = filters.find(x=> x.prop === filter.prop);

        if (filter.value.length === 0){
            filters = filters.filter(x => x.prop !== filter.prop); // if we have empty array, rmeove the filter
        }else{
            // if we do not have a filter of this kind, we push it
            if(!existingFilter){
                filters.push({
                    prop: filter.prop,
                    value: filter.value
                });
            }else{
                // else we just push the new value to the array
                existingFilter.value = filter.value
            }
        }
        // save to state
        context.commit('SET_ACTIVE_FILTERS', filters);
    },

    getUserProfileChart({ commit, rootState}){
        axios.get(config.chartsUrl + '/api/charts/' + rootState.account.user.chart_id,
            {headers: {'x-charts-token': rootState.account.chartsToken}}).then(res=>{

            commit('SET_PERSONAL_CHART', res.data);

        }).catch(e=>{console.log(e);});

    },
    getEphemerisData({state},range) {
        let data = {};

        // const prepareData = (array, key, rangeMode) => {
        //
        //
        //     if(key !== 2 || rangeMode === 'day') return array;
        //
        //     // let fixedData = [];
        //     //
        //
        // }

        for(let key of Object.keys(state.transit.transit_range_changes)){

            if(!data[key]) data[key] = [];

            let intKey = parseInt(key);
            if(intKey < 8 && intKey !== 3 && intKey !== 4){
                if(state.transit.transit_range_changes[key][range.previous.value]){
                    data[key].push(...state.transit.transit_range_changes[key][range.previous.value])
                }

                if(state.transit.transit_range_changes[key][range.current]){
                    data[key].push(...state.transit.transit_range_changes[key][range.current])
                }
                if(state.transit.transit_range_changes[key][range.next.value]){
                    data[key].push(...state.transit.transit_range_changes[key][range.next.value])
                }
            }else{

                // sort by keys ot make sure we are going from the past to the future!
                const ordered = Object.keys(state.transit.transit_range_changes[key]).sort().reduce(
                    (obj, year_month) => {
                        obj[year_month] = state.transit.transit_range_changes[key][year_month];
                        return obj;
                    }, {}
                );

                for(let year_month of Object.keys(ordered)){
                    data[key].push(...ordered[year_month])
                }
            }


            // that means we are on a low changes planet. We need to make sure we have the 1st element
            // because of our duplicate deletions

            for(let [index, item] of data[key].entries()){
                item.start = moment(item.datetime).toDate()
                if(index === data[key].length -1){
                    // last item handling. We just add a super future date and it will get fixed on the next iteration.
                    item.end = moment(item.datetime).add(1, 'year').toDate();
                    continue;
                }

                let nextElem = data[key][index+1];
                item.end = moment(nextElem.datetime).subtract(10, 'seconds').toDate();
            }
        }


        return data;
    }


}
export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}