import Vue from 'vue'
import VueI18n from "vue-i18n"

import en from '@/lang/en'

const messages = {
    en
}

Vue.use(VueI18n)


const i18n = new VueI18n({
    locale: 'en',
    messages
});

// VueI18n instance
export default i18n;