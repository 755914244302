<template>

  <b-alert v-if="globalCouponData && !invalidCoupon" show variant="success" class="mb-4">
    <strong>
      {{ globalCouponData.title }}
    </strong>

    <br/>
    {{globalCouponData.content}}
  </b-alert>

</template>

<script>

export default{

  props:{
    type: String, // subscriptions || single_unlocks,
    invalidCoupon: Boolean
  },

  data(){
    return{
      globalCouponData: null
    }
  },

  created(){
    this.getGlobalCouponData()
  },
  methods:{
    getGlobalCouponData() {
      this.axios.get('/content/config?key=global_coupon').then(res =>{
        if(!res.data || !res.data.value || !res.data.value[this.type] || !res.data.value[this.type].enabled) return;
          this.globalCouponData = res.data.value[this.type];
          this.$emit('applyCoupon', res.data.value[this.type].coupon);


      }).catch((e) =>{
        console.log(e);
      });
    },
  }
}
</script>