import channelConstants from "../helpers/rave/constants/channels";

export default{


    watch: {
        chart() {
            this.setupChannelContent();
        }
    },
    data(){
        return{
            channels: []
        }
    },
    methods:{

        getPersonName(channel, type, chart) {

            // no dominations for state 0 charts.
            if(channel.state === 0) return null;
            try{

                if(type === 'dominant'){
                    return (chart || this.chart).meta['person' + (channel.dominantChartId+1)].name
                }
                return (chart || this.chart).meta['person' + ((!channel.dominantChartId) + 1)].name
            }catch(e){
                return null;
            }

        },

        setupChannelContent(chart){
            let channels = [];
            for(let channel of (chart || this.chart).chart.channels){
                let cObj = {};
                // handle differently the composite charts and the rave charts.
                if(Object.hasOwn(channel,'id')){
                    cObj = {
                        channel: channel.id,
                        name: this.$t('hd.channels.'+channel.id + '.name'),
                        keynote: this.$t('hd.channels.'+channel.id + '.keynote'),
                        key: channelConstants.find(x => x.id === channel.id).key,
                        gates: channelConstants.find(x => x.id === channel.id).gates,
                        circuit: channelConstants.find(x => x.id === channel.id).circuit,
                        state: channel.state,
                        dominantChartId: channel.dominantChartId,
                        dominant: this.getPersonName(channel, 'dominant', (chart || this.chart)),
                        dominee:  this.getPersonName(channel, 'dominee', (chart || this.chart))// INVERSING dominantChartId (if === 1 then we have 0, if ===0 then we have 1
                        // (and adding 1 because we have person1 & person2 in charts
                    }
                }else{
                    cObj = {
                        channel: channel,
                        name: this.$t('hd.channels.'+channel + '.name'),
                        keynote: this.$t('hd.channels.'+channel + '.keynote'),
                        gates: channelConstants.find(x => x.id === channel).gates,
                        key: channelConstants.find(x => x.id === channel).key,
                        circuit: channelConstants.find(x => x.id === channel).circuit,
                    }
                }
                channels.push(cObj);
            }
            this.channels = channels;

        },
    }
}