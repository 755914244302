<template>
  <div id="print-single-chart"
       v-if="!!chart.id"
       :class="`${chart && chart.meta ? chart.meta.type : ''}
  ${this.$store.state.ui.viewMode} ${this.$store.state.ui.showMandala ? 'print-mandala' : ''}
  ${this.isTransitOnlyChart(chart) ? 'transit-only' : ''}
  ${$store.state.ui.singleChart.aiEnabled ? 'ai-enabled': ''}
  ${this.$route.query.thumbnail ? 'thumbnail' : ''}
  `">
      <rave-chart v-if="!!chart.id" :key="$store.state.charts.chartKey" :chart="chart" :chart-loaded="!!chart.id" :hide-planet-tables="hidePlanetTables"/>
  </div>
</template>

<style lang="scss">
#print-single-chart{
  width:700px;
  padding: 1px 10px 10px;
  &.RAVE_CHART.design, &.RAVE_CHART.personality, &.TRANSIT_CHART{
    padding: 1px 10px 10px;
  }
  &.TRANSIT_CHART{
    &.transit-only{
      padding-left:initial;
    }
  }
  &.CONNECTION_CHART, &.CYCLE_CHART{
    padding-bottom:0;
  }
  &.ai-enabled{
    padding-left: 3px;
    padding-right: 3px;
    padding-top:3px;
  }
  //  //width:855px;
  //  padding-left:0;
  //  //#connection-planet-table.right{
  //    margin-left:0!important;
  //  }
  //}

  &.print-mandala{
    #rave-chart-view-wrapper{
      height:650px;
    }
  }
  .bodygraph-sizing-tool{
    max-height:95%;
  }
  #copyright-text{
    display:block;
  }
}
</style>
<script>
import RaveChart from "../components/charts/rendering/RaveChart.vue";
import ChartPageMixin from "../mixins/ChartPageMixin";
import ChartUtil from "../mixins/ChartUtil";

export default{

  components: {
    RaveChart,
  },

  mixins: [ChartPageMixin, ChartUtil],

  data(){
    return{
      hidePlanetTables: false,
      chart: {}
    }
  },

  async mounted(){
    document.getElementById("header").style.display = 'none';
    if(this.$route.query.chart){
      this.chart = (await this.fetchDoc(this.$route.query.chart, true));
    }

    this.switchDarkMode(!!this.$route.query.darkMode);

    if(this.$route.query.viewMode) {
      this.$store.commit('ui/SET_VIEW_MODE', this.$route.query.viewMode);
    }

    if(this.$route.query.thumbnail) {
      this.hidePlanetTables = true;
    }

    const hasMandala = !!this.$route.query.showMandala;
    const hasAI = !!this.$route.query.showAI;

    if(hasMandala){
      this.$store.commit('ui/SET_SHOW_MANDALA', true);
    }
    if(hasAI){
      this.$store.commit('ui/SET_AI_ENABLED', true);
    }

    if (this.$route.query.background) {
      document.getElementById('app').style.background = '#' + this.$route.query.background;
    }
  },
}
</script>