<template>

  <div v-if="cardLoaded">
    <div v-if="!hideOptions"  class="selector">
      <label class="mb-3">Choose a payment method</label>
      <checker-option v-for="item in cards" :key="item.id"
                      :is-selected="selectedCard === item.id"
                      @selected="selectedCard = item.id"
                      >
        **** **** **** {{item.card.last4}}

        <img alt="brand logo" :src="`${item.card.brand}.svg`" class="ml-3" height="22"/>
      </checker-option>
      <checker-option
          :is-selected="!selectedCard"
          text="Checkout with a new card"
          @selected="selectedCard = null"/>

      <b-button v-if="cards.length > 0" variant="primary" class="mt-3" @click="proceed" block>
        {{
        !selectedCard ? 'Continue' : `Pay Now \$${cartTotal.toFixed(2)}`
        }}
      </b-button>
    </div>

    <!--   Finalize Stripe Payment.     -->
    <div id="payment-element">
      <!-- Elements will create form elements here -->
    </div>

    <div v-if="stripe && hideOptions" class="my-3">
      <b-button variant="primary" id="submit-stripe-payment" class=" btn-block"
                @click="pay">Pay Now ${{cartTotal.toFixed(2)}}</b-button>

    </div>
    <div id="error-message">
      <!-- Display error message to your customers here -->
    </div>

  </div>
</template>

<script>
import {loadStripe} from '@stripe/stripe-js';
import CheckerOption from "./CheckerOption.vue";
export default{
  components: {CheckerOption},

  props:{
    cartTotal: Number,
    processPayment: Function
  },
  computed:{
    getStripeColors(){
      return {
        colorPrimary: '#D14B49',
        colorBackground: this.darkMode ? '#1D1C1C' : '#f3f3f3',
        colorText: this.darkMode ? '#ffffff' : '#000000',
        borderRadius: '8px'
      }
    }
  },

  watch:{
    darkMode(){
      if(this.hideOptions) this.setupStripe();
    }
  },
  data(){
    return{
      cardLoaded: false,
      selectedCard: null,
      hideOptions: false,
      setupNewCard: false,
      cards: [],
      stripe_data: {},
      stripe: null,
      stripe_options: {
        clientSecret: null,
        appearance: {
          theme: 'none'
        }
      },
      stripe_elements: null,
      paymentElement: null
    }
  },

  created(){
    this.getUserCard();
  },

  methods:{

    proceed(){
      if(!this.selectedCard){
        return this.payWithNewCard();
      }else{
        return this.pay()
      }
    },
    getUserCard(){
      this.axios.get('/billing/stripe/saved-card').then(res=>{
        this.cardLoaded = true;
        if(res.data.length === 0){
          this.setupNewCard = true;
          this.payWithNewCard();
          return;
        }
        this.cards = [...res.data];
        this.selectedCard = this.cards[0].id;
      }).catch(e=>{
        console.error(e);
      })
    },

    async payWithNewCard(){
      this.setupNewCard = true;
      this.hideOptions = true;
      this.$emit('purchasing', true);
      this.processPayment().then((res)=>{
        this.$emit('purchasing', false);

        if(res.data.succeeded){
          return this.$emit('handlePurchase', {completed: true})
        }
        this.stripe_data = res.data;
        this.setupStripe();
      }).catch(() => {
        this.$emit('purchasing', false);
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    },


    async setupStripe(){
      try{
        // eslint-disable-next-line no-undef
        this.stripe = await loadStripe(this.stripe_data.stripe_public_key);

        this.stripe_options.clientSecret = this.stripe_data.secret;

        this.stripe_options.appearance.variables = {
          ...this.getStripeColors,
          spacingUnit: '4px'
        }

        this.stripe_elements = this.stripe.elements(this.stripe_options);

        this.paymentElement = this.stripe_elements.create('payment');
        this.paymentElement.mount('#payment-element');

      }catch(e){
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }
    },

    async pay(){
      this.$emit('purchasing', true);

      if(this.selectedCard){
        // we use saved one.
        return this.processPayment(this.selectedCard).then(res => {

          if(!res.data.succeeded){
            this.$notify({type: 'error',
              title: 'Payment failed',
              text: res.data.error ?? 'Charging the card failed. Please try again, or choose a different card'
            });
            this.hideOptions = false;
            this.$emit('purchasing', false);
            return;
          }

          return this.$emit('handlePurchase', {completed: true})
        }).catch( () => {
          this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
        })
      }

      this.stripe.confirmPayment({
        elements: this.stripe_elements,
        confirmParams: {
          return_url: ''
        },
        redirect: 'if_required'
      }).then((res)=>{
        this.$emit('handlePurchase', res)
      }).catch( () => {
        this.$emit('purchasing', false);
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    },
  },

}
</script>