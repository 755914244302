<template>

  <div id="ephemeris-timeline" :class="`theme--${getDarkModeText}`">

    <b-overlay :show="lazyFetching || loadingItems" rounded="sm">
      <div class="grid grid-cols-md-2 mb-2">
        <transit-timeline-explanation :chart="chart"/>
        <div class="text-right">
          <b-button size="xs" :variant="rangeMode === rangeModes.day ? 'primary' : 'outline-primary'" class="mr-1" @click="changeTimeLineView(rangeModes.day)">Hourly</b-button>
          <b-button size="xs" :variant="rangeMode === rangeModes.week ? 'primary' : 'outline-primary'" class="mr-1" @click="changeTimeLineView(rangeModes.week)">Daily</b-button>
<!--          <b-button size="xs" :variant="rangeMode === rangeModes.month ? 'primary' : 'outline-primary'" @click="changeTimeLineView(rangeModes.month)">Month View</b-button>-->
        </div>
      </div>
      <div id="visualization"></div>
    </b-overlay>

    <transit-selected-planet v-if="transitState.planetId"
                             :planet-id="transitState.planetId"
                             :timestamp-utc="transitState.timestampUtc"
                             @selected="(val) => {
                               transitState = val
                             }"
    />

  </div>
</template>

<style lang="scss">
#ephemeris-timeline{

  &.theme--dark{
    .vis-item.blue, .vis-item.green {
      .vis-item-content{
        color:black;
      }
    }
  }
  #color-explanation-toast{
    z-index:20;
    border:0!important;
    overflow:hidden;
    border-radius:15px;
    position:absolute;
    box-shadow:0 5px 50px 5px rgba(0, 0, 0, 0.1)
  }
  #color-explanation-toast__toast_outer{
    margin:0!important;
    z-index:9;
  }

  $transit-blue: #7689a0;
  $transit-green: #95b2ad;
  .explanation-ball {
    display:inline-block;
    height:15px;
    width:15px;
    border-radius:50%;
    &.blue{
      background:$transit-blue!important;
      color:white;
    }
    &.green{
      background:$transit-green!important;
      color:white;
    }
    &.blue.green{
      background: linear-gradient(
              to top,
              $transit-green 0%,
              $transit-green 50%,
              $transit-blue 50%,
              $transit-blue 100%
      )!important;
    }
  }


  .b-overlay{
    >div{
      opacity:0.1!important;
    }

  }
  .vis-timeline{
    border:0!important;
  }
  .vis-panel{

      border:0;
  }
  .vis-label{
    display:flex;
    align-items: center;
    font-size:12px;
    .vis-inner{
      display:flex;
      align-items: center;
    }
  }
  .vis-labelset .vis-label, .vis-foreground .vis-group{
    border:0;
  }
  .vis-labelset{
    border-top-left-radius: 10px;
    border-bottom-left-radius:10px;
  }
  //.vis-background {
  //  display: none;
  //}

  .vis-foreground > .vis-group{
    //height:47px!important;
  }

  .vis-item{
    //height:100%;
    font-size:14px;
    margin:1px;
    height:50px;
    &.t-p-2{
      @media(min-width:1240px) and (max-width:1700px) {
        font-size:12px;
      }

    }
    .vis-item-content{
      display:flex;
      align-items:center;
      height:100%;
    }
    &.blue{
      background:$transit-blue!important;
      color:white;
    }
    &.green{
      background:$transit-green!important;
      color:white;
    }
    &.blue.green{
      background: linear-gradient(
              to top,
              $transit-green 0%,
              $transit-green 50%,
              $transit-blue 50%,
              $transit-blue 100%
      )!important;
    }
    cursor:pointer;
    &:hover{
      //border: 1px solid $primary!important;
      filter: brightness(0.8);
    }
  }
  .vis-text{
    font-size:12px;
  }
  //.vis-panel.vis-background.vis-vertical{
  //  display:none;
  //}
  .vis-panel.vis-background .vis-time-axis{
    display:none;
  }
  &.theme--dark{
    .vis-text, .vis-label{
      color: white;
    }
    .vis-label{
      border-top:3px solid #434343;
      &:nth-child(1){
        border-top:0;
      }
    }
    .vis-item{
      border:2px solid #1D1C1C;
      background:#434343;
      color:white;
    }
    .explanation-ball{
      background:#434343;
    }
    .vis-labelset{
      background:#1D1C1C;
      border:1px solid #1D1C1C;
    }
  }

  &.theme--light{
    .vis-text, .vis-label{
      color: black;
    }
    .vis-label{
      border-top:1px solid #f1efef;
      &:nth-child(1){
        border-top:0;
      }
    }
    .vis-item{
      border:2px solid white;
      background: #f1efef;
    }
    .explanation-ball{
      border:1px solid;
      background:#f1efef;
    }
    .vis-labelset{
      border:1px solid #f1efef ;
    }
  }

  .vis-custom-time.active-marker{
    background: $primary;
    pointer-events: none;
  }
}
</style>

<script>
import { Timeline, DataSet } from "vis-timeline/standalone";

import TransitToolMixin from "../../../mixins/TransitToolMixin";
import moment from "moment";

import {getPreviousYearMonth, getNextYearMonth} from "../../../store/helper";
import {mapState} from "vuex";
import channels from "../../../helpers/rave/constants/channels";
import TransitSelectedPlanet from "../../structure/chart-sidebars/TransitSelectedPlanet.vue";
import TransitTimelineExplanation from "./transit-timeline-explanation.vue";
export default{
  components: {TransitTimelineExplanation, TransitSelectedPlanet},

  mixins: [TransitToolMixin],

  computed:{
    ...mapState({
      chart: state => state.charts.chart,
      timelineViewMode: state => state.ui.timelineViewMode
    }),

    getUserActivatedGates(){

      if(this.chart.chart && this.chart.chart.planets.length > 14){
        return this.chart.chart.planets.filter(x => x.chartId === 0).map(x => x.gate);
      }
      return [];
    },

    getHarmonicGateGateActivation(){
      let gates = [];
      if(this.chart.chart && this.chart.chart.planets.length > 14){
        let chartGates = this.chart.chart.planets.filter(x => x.chartId === 0).map(x => x.gate);

        for(let gate of chartGates){
          let channelList = channels.filter(x => x.gates.includes(gate));

          for(let channel of channelList){
            let harmonicGate = channel.gates.find(x => x !== gate);
            gates.push(harmonicGate);
          }

        }

        // this.chart.chart.planets.filter(x => x.chartId === 0).map(x => x.gate);
      }
      return gates;
    },
    timelineOptions(){
      const dateIntervals = this.getStartAndEndDate;

      let defaults = {
        locale: 'en_US',
        stack: false,
        maxHeight:700,
        horizontalScroll: true,
        verticalScroll: true,
        zoomKey: "ctrlKey",
        moveable: true,
        zoomable: false,
        start: dateIntervals.start,
        timeAxis: dateIntervals.timeAxis,
        groupHeightMode: "fitItems",
        xss: {
          disabled: false,
          filterOptions: {
            whiteList: { span: ['class', 'style'], div: 'class' },
          },
        },
        format:{
          majorLabels:{
            hour: 'ddd D MMMM YYYY'
          }
        },
        margin : {
          item: {horizontal:0, vertical:0},
          axis: 5
        },
        orientation: {
          axis: "top",
          item: "top"
        },
      }

      if(dateIntervals.end) defaults.end = dateIntervals.end;

      return defaults;
    },
    getStartAndEndDate(){

      const dateIntervals = {}

      let date = this.justNow ? moment() : moment(this.currentTransitDate);

      if(this.rangeMode === this.rangeModes.day){
        dateIntervals.start = date.toDate().setUTCHours(0,0,0,0);

        dateIntervals.end = date.toDate().setUTCHours(this.isMobileMode ? 11 : 23,59,59,999);

        dateIntervals.timeAxis = {scale: 'hour', step: 2}
      }

      if(this.rangeMode === this.rangeModes.week){
        dateIntervals.start = date.startOf('week').toDate();
        dateIntervals.end = this.isMobileMode ? date.startOf('week').add('4', 'days').toDate() : date.endOf('week').toDate();


        dateIntervals.timeAxis = {scale: 'day', step: 1}
      }
      //
      // if(this.rangeMode === this.rangeModes.month){
      //   dateIntervals.start =  date.startOf('month').toDate();
      //   dateIntervals.end = date.startOf('month').add(this.isMobileMode ? '3' : '9', 'days').toDate();
      //   dateIntervals.timeAxis = {scale: 'day', step: 0.5}
      // }

      return dateIntervals;

    }
  },

  mounted(){
    this.isMobileMode = window.innerWidth < 1240;
    this.rangeMode = this.timelineViewMode || this.rangeModes.day;
    // DOM element where the Timeline will be attached
    let container = document.getElementById('visualization');
    // Create a Timeline
    this.timelineObj = new Timeline(container, null, {...this.timelineOptions});
    this.timelineObj.setGroups(this.prepareGroups());
    this.timelineObj.setItems(new DataSet());


    this.timelineObj.on('rangechange', this.loadData);

    this.timelineObj.addCustomTime(this.currentTransitDate, "active-marker")

    window.addEventListener("resize", this.handleZoomLevels); // extra bonus not needed for this question.

    this.timelineObj.on('click', this.handleClick);
    this.$nextTick(()=>{
      this.fetchMonthYearData();
    });

  },

  destroyed() {
    window.removeEventListener("resize", this.handleZoomLevels)
    this.timelineObj.destroy();
  },
  data(){
    return{
      isMobileMode: false,
      transitState: {
        planetId: null,
        timestampUtc: null
      },
      timelineObj: null,
      rangeMode: null,
      rangeModes: {day: 'day', week:'week',month:'month'},
      lazyFetching: false,
      activeView: {},
      loadingItems: false,
      keys: {
        MOON: "2",
        NORTH_NODE: "3",
        SOUTH_NODE: "4"
      }
    }
  },
  methods:{

    handleZoomLevels(){
      const previousView = this.isMobileMode;
      this.isMobileMode = window.innerWidth < 1240;

      if(this.isMobileMode !== previousView) {
        let intervals = this.getStartAndEndDate;
        this.timelineObj.setWindow(intervals.start, intervals.end || null);
        this.timelineObj.setOptions(this.timelineOptions);
      }
    },


    fetchByMonthYear(date, month, year) {
      const year_month = year + '_' + month;
      if(this.$store.state.charts.transit.transit_range_month_cached.includes(year_month)){
        this.lazyFetching = false;
        this.prepareItems(date);
        return true;
      }

      this.axios.get('/transit/transit-data/range', {params:{ month: month, year: year}}).then(res=>{
        this.$store.commit('charts/SET_TRANSIT_RANGE_DATA', res.data);
        this.lazyFetching = false;
        this.prepareItems(date);

      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      });
    },

    fetchMonthYearData(customDate){

      if(this.lazyFetching) return; // avoid duplicate fetching.
      this.lazyFetching = true;

      let date = customDate || this.currentTransitDate;
      let momentObj = moment(date);

      let month = momentObj.month();
      let year = momentObj.year();


      let nextMonth, prevMonth;
      let nextYear, prevYear;

      // Start/end of year are special cases.
      if (month === 0 || month === 1) {
        if (month === 0) {
          prevMonth = 11;
          prevYear = year - 1;
          nextMonth = month + 1;
          nextYear = year;
        } else {
          prevMonth = month - 1;
          prevYear = year;
          nextMonth = month + 1;
          nextYear = year;
        }
      } else {
        nextMonth = month + 1;
        nextYear = year;
        prevMonth = month - 1;
        prevYear = year;
      }

      return Promise.all(
          [
            this.fetchByMonthYear(date, month, year),
            this.fetchByMonthYear(date, prevMonth, prevYear),
            this.fetchByMonthYear(date, nextMonth, nextYear)
          ]
      )

    },

    prepareGroups(){
      let groups = new DataSet();
      for(let planet of this.getPlanets){
        groups.add({
          id: planet.id,
          content: '<span class="mr-1 icon-' + planet.icon +'" style="width:12px;text-align:center"></span> ' + '<span class="large-desktop-version">' + planet.name + '</span>',
          order: planet.id,
          className: 'icon-wrap-'+planet.icon
        });
      }
      return groups;
    },
    prepareItems(date){

      if(this.lazyFetching) return;
      let items = new DataSet();
      this.loadingItems = true;


      // console.log(this.getUserActivatedGates);
      let activatedGates = this.getUserActivatedGates;
      let channelActivationGates = this.getHarmonicGateGateActivation;
      // give it some time to render the loading bar!

        this.activeView = this.getPreviousAndNextYear(date);

        this.$store.dispatch('charts/getEphemerisData', {...this.activeView, rangeMode: this.rangeMode}).then(data => {
          let objects = [];

          for(let key of Object.keys(data)){

            let data_to_render = data[key];
            // moon special handling for week/month
            if(key === this.keys.MOON && this.rangeMode !== this.rangeModes.day){
              let keysToRemove = [];
              let lastKeptId = 0;

              for(let i=1;i<data_to_render.length;i++){
                if(data_to_render[lastKeptId].gate === data_to_render[i].gate){
                  data_to_render[lastKeptId].end = data_to_render[i].end;
                  keysToRemove.push(i);
                }else{
                  lastKeptId = i;
                }
              }

              for(let key of keysToRemove){
                data_to_render[key] = null;
              }
              data_to_render = data_to_render.filter(x => !!x);
            }
            for(let item of data_to_render){
              if(!item) continue;

              let title = ''+item.gate;
              if((key !== "2" || this.rangeMode === "day")) title += '.' + item.line

              if(key !== this.keys.NORTH_NODE && key !== this.keys.SOUTH_NODE) {
                if(item.retrograde === 1) title += ' <span class="icon-retrograde" style="font-size:12px;"/>'
                if(item.retrograde === 3) title += ' D';
              }

              let object = {
                id: item.planet + '_' + item.datetime + '_' + item.retrograde,
                group: item.planet,
                start: item.start,
                end: item.end,
                type: 'range',
                content: title,
                className: `${activatedGates.includes(item.gate) ? 'blue' : ''} ${channelActivationGates.includes(item.gate) ? 'green' : ''} t-p-${item.planet}`
              }

              objects.push(object);
            }
          }

          items.add(objects);

          this.timelineObj.setItems(items);
          this.loadingItems= false;
        });

    },

    getPreviousAndNextYear(date_input){

      let date = moment(date_input);

      let previous = getPreviousYearMonth(date.year(), date.month());
      let next = getNextYearMonth(date.year(), date.month());

      return {
        previous: previous,
        next: next,
        current: date.year() + '_' + date.month(),
      }

    },
    async loadData(e){
      if(this.lazyFetching) return;

      let start_year_month =  e.start.getFullYear() + '_' +  e.start.getMonth();
      let end_year_month =  e.end.getFullYear() + '_' +  e.end.getMonth();

      if (this.justNow && e.end.getMonth() !== moment().month()) {
        this.changeJustNow(false);
      }

      if(!this.$store.state.charts.transit.transit_range_month_cached.includes(start_year_month)) {
        return this.fetchMonthYearData(e.start);
      }

      if(!this.$store.state.charts.transit.transit_range_month_cached.includes(end_year_month)) {
        return this.fetchMonthYearData(e.end)
      }


      if(this.activeView.previous.value !== start_year_month
          && this.activeView.current !== start_year_month
          && this.activeView.next.value !== start_year_month){
        return this.fetchMonthYearData(e.start);
      }

      if(this.activeView.previous.value !== end_year_month
          && this.activeView.current !== end_year_month
          && this.activeView.next.value !== end_year_month) {
        return this.fetchMonthYearData(e.end);
      }
    },
    async handleClick(data){
      const parts = data.item.split('_');

      // eslint-disable-next-line no-unused-vars
      const _state = {
        planetId: parts[0],
        timestampUtc: parts[1],
        retrograde: parts[2]
      }

      this.transitState = _state;
      this.$store.commit('ui/SET_SHOW_TRANSIT_SIDEBAR', true);
    },

    changeTimeLineView(type){

      if(type === this.rangeMode) return;
      this.$store.commit('ui/SET_TIMELINE_VIEW_MODE', type);
      if(this.rangeMode === this.rangeModes.day || type === this.rangeModes.day){
        // this.prepareItems()
        // if we move from/to day, we make moon elements only show gate.
        this.prepareItems(this.timelineObj.getWindow().start);
        // we need to make sure Moon only has Gates now!
      }

      this.rangeMode = type;

      let intervals = this.getStartAndEndDate;
      this.timelineObj.setWindow(intervals.start, intervals.end || null);
      this.timelineObj.setOptions(this.timelineOptions)
    }
  }
}

</script>