<template>
  <div id="chart-tag-editor">
    <p class="mb-1 pl-2 caption grey--text text--lighten-1">
      {{ $t('tags.chartTags') }}
    </p>
    <multiselect v-model="chartTags"
                 :tag-placeholder="$t('tags.addPlaceholder')"
                 placeholder="Select from the list or type a new tag here..."
                 label="text"
                 track-by="id"
                 :options="tags"
                 :multiple="true" :taggable="true"
                 @tag="addTag"
                 @remove="saveDocumentTags"
                 @select="saveDocumentTags"
    ></multiselect>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ChartPageMixin from "../../../../mixins/ChartPageMixin";
import Multiselect from "vue-multiselect";

export default {
  mixins: [ChartPageMixin],
  components: { Multiselect },
  props:{
    chart: Object
  },
  computed:{
    ...mapState({
      tags: state => state.account.tags
    }),

    tagsChanged(){
      return this.initialTagList !== JSON.stringify(this.chartTags);
    }
  },
  methods: {
    updateTags(tags){
      this.tagsList = tags;
    },

    addTag(tag){
      this.updateChartField(this.chart.id,
          {tags: [...this.chartTags.map(x => x.text), tag]},
          true);
    },

    saveDocumentTags(){
      this.updateChartField(this.chart.id, {tags: this.chartTags.map(x => x.text)}, true);
    },

    setChartTags(){
      if(!this.chart.tags){
        this.chartTags = [];
        return;
      }
      this.chartTags = this.chart.tags.map(x => {
        if(this.tags.find(userTags => userTags.id === x)){
          x =  this.tags.find(y => y.id === x)
        }
        return x
      });
      this.initialTagList = JSON.stringify(this.chart.tags)
    }
  },
  mounted(){
    this.setChartTags();
  },

  data(){
    return{
      chartTags: [],
      tagsList: [],
      initialTagList: "[]"
    }
  },
}
</script>
<style>
#chart-tag-editor{
  .multiselect__input{
    background:transparent!important;
    padding:15px!important;
    outline:none!important;
    border:none!important;
  //opacity:0!important;
    height:0!important;
  //display:none!important;
    &::placeholder{
      font-size:0.8rem;
    }
  }
}
</style>
