
export default {
    day: "day",
    month: "month",
    year: "year",
    hour: "hour",
    minute: "minute",
    second: "second",
    days: "days",
    months: "months",
    years: "years",
    hours: "hours",
    minutes: "minutes",
    seconds: "seconds",
    and: 'and'
}