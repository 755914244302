<template>
  <div class="gate">
    <b-overlay :show="loading" rounded="sm">
    <b-card v-if="!isRelatedToggle" class="bg-gray shadow-none text-center py-3 mt-3">

      <div  class="row mb-3 prev-next">
        <div class="col-6" >

          <div v-if="prev" class="d-flex align-items-center cursor-pointer" style="opacity:0.7;" @click="$emit('changeGate', prev.gate)">
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg" style="transform:rotate(180deg)">
              <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="ml-2 text-md">
                  {{$t('hd.gates.name.one')}} {{prev.gate}}
        </span>

          </div>

        </div>
        <div class="col-6 text-right" @click="$emit('changeGate', next.gate)">

          <div v-if="next" class="d-flex align-items-center ml-auto justify-content-end cursor-pointer" style="opacity:0.7;">
          <span class="mr-2 text-md">
                  {{$t('hd.gates.name.one')}} {{next.gate}}
        </span>
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>

        </div>
      </div>
      <span class="icon-gate" style="font-size:2.5rem"></span>

      <h3 class="font-weight-bolder">{{ $t('hd.gates.name.one') }} {{gateId}}</h3>
      <h5 class="font-weight-normal">{{$t('hd.gates.'+gateId + '.name') }} - {{$t('hd.gates.'+gateId + '.titleKeynote') }}</h5>
    </b-card>
      <gate-content :gate-id="gateId" :loading="loading"
                    :reverse-background="reverseBackground"
                    :is-related-toggle="isRelatedToggle"
                    @loading="val => this.loading = val" />

      <gate-related-links
          v-if="showRelatedLinks"
          :is-nested="isRelatedToggle"
          :key="gateId"
          class="mt-4"
          :depth="depth"
          :selected-id="gateId"
          type="gate" />
    </b-overlay>
  </div>

  
</template>

<script>
import GateContent from "../content/GateContent";
import GateRelatedLinks from "../related-links/GateRelatedLinks";
export default{
  components: {GateRelatedLinks, GateContent},
  props:{
    isRelatedToggle: {
      type: Boolean,
      default: false,
    },
    depth: Number,
    gateId: Number,
    prev: Object,
    next: Object,
    showRelatedLinks:{
      type: Boolean,
      default: false
    },
    reverseBackground: Boolean
  },

  data(){
    return{
      loading: false
    }
  }
}
</script>