export default{

    title: 'Is this your BodyGraph?',
    text: `If you don't have a myBodyGraph account yet, sign up now with this BodyGraph and explore the free material tailored specifically to your human design.`,
    cta:'Already signed up?',
    ctaBtn: 'Log In',
    ctaAfterBtn: ' now to add this BodyGraph to your library!',
    save: 'Save to Library',
    loggedIn: {

    },
    new: 'Create Another Chart'
}