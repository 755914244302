import Vue from "vue";
import {mapState} from "vuex";
import {config} from "@/utils/config";

export default {

  data(){
    return {
      config,
      copied: {}
    }
  },
  computed: {
    ...mapState({
      isLoggedIn: state => state.account.isLoggedIn,
      gift: state => state.ui.gift,
    }),

    analysis() {
      if (this.gift.giftType === '0') return 'foundational';
      if (this.gift.giftType === '01') return 'advanced';
      if (this.gift.giftType === 'w2') return 'work_success';
      return 'partnership';
    }
  },
  methods: {
    getUnlockCode(productKey) {
      if (productKey === 'foundational') return '0';
      if (productKey === 'advanced') return '01';
      if (productKey === 'work_success') return 'w2';
      if (productKey === 'companionship') return '2';
    },
    copyLink(item, idx){
      // copy to clipboard using navigator api
      navigator
          .clipboard
          .writeText(`${this.config.clientUrl}/public/${item.chartId}?gift_id=${item.purchase.id}&gift_type=${item.unlockCode}`);

      Vue.set(this.copied, idx, true);
      setTimeout(() => {
        Vue.set(this.copied, idx, false);
      }, 2000);

      return idx;
    },

    saveGift() {
      const gift = this.gift;
      if (!gift || !(gift.hasGift && gift.isValid && gift.giftId && gift.giftType)) return false;
      return this.axios.post('/billing/gifts/claim', {
        giftId: gift.giftId,
        giftType: gift.giftType,
        chartId: gift.chartId
      }).then(async (res) => {
        this.$store.commit('ui/REMOVE_GIFT'); // clear gift.
        await this.$store.dispatch('account/getUserUnlockedCharts');
        await this.$router.push('/charts/'+ res.data.giftedChartId);
        return false;
      }).catch(e => {
        console.log(e);
        return false;
      });
    },
  }
}