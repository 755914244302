<template>
  <div>
    <b-card class="mb-4 reverse-bg-mode">
      <b-card-body>
        <div class="row">
          <properties-item :item="{
            label: $t('properties.chartName'),
            value: chart.meta.name,
            tripleSpan: true,
          }"/>
          <properties-item v-for="(value,key) in getData" :key="key" :item="{
            label: $t(`properties.${key}`),
            value,
            doubleSpan: ['cycleCross'].includes(key)
          }"/>
        </div>

      </b-card-body>
    </b-card>

<!--    <personal-chart-summary v-if="chart" :chart="{chart: getPerson}"/>-->
    <div v-if="chart.meta.person">
      <h5>{{chart.meta.person.name}} - {{$t('properties.foundational')}}
        <a :href="$router.resolve({name: 'single-chart', params: {
          chartId: chart.meta.person.id
        }}).href" target="_blank">{{ $t('properties.openInNewTab') }}</a>
      </h5>
      <div class="row mt-4 px-3 px-md-0">
        <summary-box v-if="chart.meta.person.birthData.time?.utc" icon="icon-time-ticking"
                     :title="$t('properties.birthDateUTC')"
                     :value="formatDate(chart.meta.person.birthData.time.utc, true) || chart.meta.person.birthData.time.timezone.id"/>
        <personal-chart-summary show-advanced :chart="getChart()" />
      </div>
    </div>

  </div>

</template>

<script>
import ChartUtil from "@/mixins/ChartUtil";
import {getCycleData} from "@/helpers/charts";
import PropertiesItem from "./PropertiesItem.vue";
import {computeChart} from "@/helpers/rave/api";
import docTypes from "@/helpers/docTypes";
import SummaryBox from "@/components/charts/properties/summary/partials/summary-box.vue";
import PersonalChartSummary from "@/components/charts/properties/summary/PersonalChartSummary.vue";

export default {
  components: {PersonalChartSummary, SummaryBox, PropertiesItem},

  mixins: [ChartUtil],
  props: {
    chart: Object,
  },
  computed: {
    getData() {
      return getCycleData(this.chart, this.formatDate)
    },
  },
  methods: {
    getChart(){
      return {
        meta: {
          type: docTypes.RAVE_CHART
        },
        chart: computeChart(this.chart.chart.planets.filter(x => x.chartId === 0))
      }
    },
  }
}
</script>
