<template>
  <side-extended-panel id="edit-chart"
                       :title="$t('billing.products.title')"
                       :close-btn-text="$t('owl.close')"
                       :visible="show"
                       @close="close">

    <div class="pb-5 pt-3 px-4">
      <b-alert variant="info" show class="casual-border-radius">
        <div v-html="$t('billing.products.long.comparison')"/>
      </b-alert>

      <b-tabs class="tabs-primary-underlined" :value="tabs[activeTab]" @change="(val) => $store.commit('ui/SET_CHECKOUT_SIDEBAR_ACTIVE_TAB', val)">
        <b-tab v-for="item in Object.keys(tabs)" :key="item" :title="$t('billing.products.names.'+item)">

          <div v-html="$t('billing.products.long.' + item)"/>
        </b-tab>
      </b-tabs>

      <hr/>

    </div>
  </side-extended-panel>
</template>

<script>
import SideExtendedPanel from "../SideExtendedPanel.vue";
import {mapState} from "vuex";

export default {
  components: { SideExtendedPanel },

  computed: {
    ...mapState({
      show: state => state.ui.showCheckoutSidebar,
      activeTab: state => state.ui.checkoutSidebarTab
    }),
  },
  data(){
    return {
      tabs: {
        foundational: 0,
        advanced: 1,
        companionship: 2,
        transit_tool: 3
      },
    }
  },
  methods: {
    close(){
      this.$store.commit('ui/SET_SHOW_CHECKOUT_SIDEBAR', false);
    }
  }

}
</script>