<template>
  <dynamic-zone-renderer v-if="content" :components="content.components" :add-paddings="false"/>
</template>

<script>


import DynamicZoneRenderer from "../../../../renderer/DynamicZoneRenderer";
import analysisQueryMixin from "../../../../../mixins/AnalysisQueryMixin";
export default{
  components: {DynamicZoneRenderer},
  mixins: [analysisQueryMixin],
  props:{
    activation: String,
    type: String,
    tone: String,
    color: Number
  },
  data(){
    return{
      content: null,
    }
  },
  created(){
    this.getColorData();
  },
  methods:{
    getColorData(){
      let url = `/advanced-colors/${this.activation}.${this.type}.${this.color}`;
      this.getContent('content', url);
    },
  }

}
</script>