import * as R from 'ramda'
import { applyAndConcat, onlyIf } from '../../../helpers/utils/misc'
import gates from '../../../helpers/rave/constants/gates'
import zodiacs from '../../../helpers/rave/constants/zodiacs'

const empty = {
  gates: [],
  highlights: []
}

const create = planets => {
  return R.pipe(
    R.groupBy(R.prop('gate')),
    R.mapObjIndexed((_, key, obj) => {
      return {
        id: parseInt(key),
        planets: R.map(R.pick(['id', 'activation', 'chartId', 'longitude']), obj[key])
      }
    }),
    R.values,
    R.assoc('gates', R.__, {})
  )(planets)
}

const toEvenPixel = x => 2 * Math.round(x / 2)

const getRimCoordinates = (center, radius, index) => {
  const angle = index * 5.625 - 2.8
  const theta = angle * Math.PI / 180

  // these seem to render better when placed on even pixels
  const x = toEvenPixel(Math.round(center.x + radius * Math.cos(theta)))
  const y = toEvenPixel(Math.round(center.y + radius * Math.sin(theta)))

  return `${x}, ${y}`
}

const getDefinedGates = (dataSource) => R.pipe(
  R.prop('gates'),
  R.pluck('id')
)(dataSource)

const getDefinedZodiacs = (definedGates) => R.pipe(
  R.chain(x => gates[x - 1].zodiac),
  R.uniq
)(definedGates)

const getSortedGates = (definedGates) => {
  
  const definedGatesLast = R.pipe(
    R.prop('id'),
    R.when(
      R.flip(R.includes)(definedGates),
      R.always(100)
    )
  )

  return R.sortBy(definedGatesLast, gates)
}

const getSortedZodiacs = (definedZodiacs) => {
  const definedZodiacLast = R.pipe(
    R.identity,
    R.when(
      R.flip(R.includes)(definedZodiacs),
      R.always(100)
    )
  )

  return R.sortBy(definedZodiacLast, R.range(0, 12))
}

const isGateDefined = R.curry((dataSource, id) => R.pipe(
  R.prop('gates'),
  R.pluck('id'),
  R.includes(id)
)(dataSource))

const getCssClassesForGate = R.curry((dataSource, id) => {

  const gateIfExists = dataSource.gates.find(x => x.id === id);
  const idClass = `g${id}`;
  const colorClass = gates.find(x => x.id === id).color;
  const definedClass = gateIfExists ? 'defined' : '';

  const planetClasses = gateIfExists ? gateIfExists.planets.map(x => `ma-p-${x.id} ma-ch-${x.chartId}`).join(' ') : '';

  return `${idClass} ${colorClass} ${definedClass} ma-g ${planetClasses}`
})

const extractGatesLongitudes = (dataSource) => R.pipe(
  R.prop('gates'),
  R.map(R.prop('planets')),
  R.flatten(),
  R.pluck('longitude')
)(dataSource)

const getCssClassesForZodiac = (dataSource, id) => {
  

  const startRange =  R.pipe(
    zodiacs.getById,
    R.prop('startArc')
  )

  const endRange =  R.pipe(
    zodiacs.getById,
    R.prop('endArc')
  )


  const colorClass = R.pipe(
    R.modulo(R.__, 4),
    R.always('orange')
  )

  const longitudeWithinZodiacRange =  R.curry((id, longitude) => R.not(R.or(
      R.gt(startRange(id),
      longitude
      ),
      R.lt(endRange(id),
      longitude
    )
  )));

    const anyGateFallsOnZodiac = (id) =>
    R.any(longitudeWithinZodiacRange(id),extractGatesLongitudes(dataSource))

  const whenDefined = onlyIf(
    anyGateFallsOnZodiac,
    'defined'
  )

  const zodiacName =  R.pipe(
    zodiacs.getById,
    R.prop('name'),
  )

  return applyAndConcat([zodiacName, colorClass, whenDefined], id)
}

const getCssClassesForHexagram = (dataSource, id) => {
  const gateIdClasses = `g${id}`

  const definitionClasses = dataSource.gates.find(x => x.id === id) ? 'defined' : 'undefined'

  return `${gateIdClasses} ${definitionClasses}`
  //
  // const definitionClasses = R.ifElse(
  //   isGateDefined(dataSource),
  //   R.always('defined'),
  //   R.always('undefined')
  // )
  // return applyAndConcat([gateIdClasses, definitionClasses], id)
}

const getGatePlanetActivations = (dataSource, id) => R.pipe(
  R.prop('gates'),
  R.find(R.propEq('id', id)),
  R.prop('planets'),
  R.addIndex(R.map)((val, idx) => R.assoc('index', idx, val))
)(dataSource)

const DataSource = {
  empty,
  create
}

const funcs = {
  getRimCoordinates,
  getDefinedGates,
  getDefinedZodiacs,
  getSortedGates,
  getSortedZodiacs,
  getCssClassesForGate,
  getCssClassesForZodiac,
  getCssClassesForHexagram,
  getGatePlanetActivations,
  isGateDefined
}

export {
  DataSource,
  funcs
}
