export default{
    methods:{
        async getChartProgress(chartId){
            if(chartId.includes('temp-doc')) return;
            return this.axios.get(`/charts/${chartId}/progress`).then(res =>{
                this.$store.commit('charts/SET_CHART_PROGRESS', res.data.progress);
            })
        },

        async updateProgress(type, key, value){
            if(this.$route.params.chartId.includes('temp-doc')) return;
            this.axios.put( `/charts/${this.$route.params.chartId}/progress`, {
                type: type,
                key: key,
                value: value
            }).then(res => {
                this.$store.commit('charts/SET_CHART_PROGRESS', res.data.progress);
                this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')});
            }).catch( () => {
                this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
            })
        }
    }
}