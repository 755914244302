import states from '@/helpers/rave/constants/channelCompositionStates'

export default {
  [states.ELECTROMAGNETIC]: 'Electromagnetic',
  [states.COMPANIONSHIP]: 'Companionship',
  [states.COMPROMISE]: 'Compromise',
  [states.DOMINANCE]: 'Dominance',
  [states.PERSONAL]: 'Personal',
  [states.EDUCATIONAL]: 'Educational',
  [states.NATAL]: 'Natal',

  transit: {
    [states.ELECTROMAGNETIC]: 'Personal Connection',
    [states.COMPANIONSHIP]: 'Companionship',
    [states.COMPROMISE]: 'Compromise',
    [states.DOMINANCE]: 'Dominance',
    [states.PERSONAL]: 'Personal Connection',
    [states.EDUCATIONAL]: 'Educational',
    [states.NATAL]: 'Natal',
  }
}
