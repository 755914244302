import {mapState} from "vuex";
import {getLocalStorageItem, localStorageKeys, setLocalStorageItem} from "../utils/localStorage";
import RecentChartsListMixin from "./RecentChartsListMixin";
import {config} from "../utils/config";

const sortOptions = [
  { text: 'library.orderings.nameASC', value: 'nameAsc' },
  { text: 'library.orderings.nameDESC', value: 'nameDesc' },
  { text: 'library.orderings.creationOrderAsc', value: 'creationDateAsc' },
  { text: 'library.orderings.creationOrderDesc',value: 'creationDateDesc' },
  { text: 'library.orderings.docType', value: 'docType'},
  { text: 'hd.properties.type', value: 'type' },
  { text: 'hd.properties.profile', value: 'profile' },
  { text: 'hd.properties.definition', value: 'definition' },
  { text: 'hd.properties.authority', value: 'authority' }
]

const paginationOptions = [
  {text: '5', value: 5},
  {text: '10', value: 10},
  {text: '25', value: 25},
  {text: '50', value: 50}
]

export default{

  mixins: [RecentChartsListMixin],
  watch:{
    ['tableOptions.page'](){
      this.fetchCharts();

    },
    ['tableOptions.limit'](val){
      if(!this.isSearch) setLocalStorageItem(localStorageKeys.LIBRARY_PER_PAGE, val, true);
      this.resetPaginationAndFetch();
    },
    ['tableOptions.order'](val){
      if(!this.isSearch) setLocalStorageItem(localStorageKeys.LIBRARY_SORT, val, true);
      this.resetPaginationAndFetch();
    },
    search(){
      this.fetchCharts();
    },

    query:{
      handler() {
        this.resetPaginationAndFetch();
      },
      deep: true
    }
  },
  computed:{
    ...mapState({
      activeFilters: state=> state.charts.activeFilters
    }),

    filters(){
      if(this.isSearch) return this.searchFilters
      return this.activeFilters;
    },
    countPages(){
      return Math.ceil(this.tableOptions.totalRows / this.tableOptions.limit)
    },
    sortOptions(){
      return sortOptions.map( x=> {
        x.note = this.$t(x.note);
        return x;
      });
    },
  },
  data(){
    return{
      paginationOptions,
      debounceTimeout: null,
      tableOptions: {
        page: 1,
        limit: this.isSearch ? 6 : getLocalStorageItem(localStorageKeys.LIBRARY_PER_PAGE) || 10,
        totalRows: 0,
        offset: 0,
        order: this.isSearch ? 'creationDateDesc' : getLocalStorageItem(localStorageKeys.LIBRARY_SORT) || 'creationDateDesc'
      },
      search: '',
      query:{
        type: null,
        docType: null,
        profile: null,
        authority: null,
        definition: null,
        tags: []
      },
      charts: [],
      libraryUrl: config.chartsUrl + '/api/charts/'
    }
  },
  methods:{
    resetPaginationAndFetch(){
      this.tableOptions.page = 1;
      this.fetchCharts();
    },

    fetchCharts(){
      this.loading = true;
      let params = {
        limit: this.tableOptions.limit,
        offset: (this.tableOptions.page -1) * this.tableOptions.limit,
        order: this.tableOptions.order
      }
      if(this.search) params.name = this.search;

      for(let filter of this.filters){
        params[filter.prop]  = filter.value.join(",");
      }

      this.axios.get(this.libraryUrl, {params: params}).then(res=>{
        if(!res || !res.data) return;
        this.tableOptions.totalRows = res.data.count;
        this.charts = res.data.rows;
      }).catch(()=>{
          this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(()=>{
        this.loading = false;
      });
    },
  }
}