<template>
  <div id="foundational-analysis-wrapper">
      <b-tabs :value="foundationalTab"
              class="vertical-side-tabs mobile-hidden" pills card vertical end nav-wrapper-class="tabs-panel"
              lazy
              @input="(id) => setTab('foundational_tab', id)" >
        <b-tab v-for="(tab,index) in tabs" :key="`foundation-tab-${tab.id}`">

          <template #title>
            <TabTitle :title="tab.name" :icon="tab.icon" :centered="true"
                      :is-locked="!isUnlocked && index !==0"
                      :is-completed="isTabSectionCompleted('foundational', tab.id)"
            />
          </template>

          <b-overlay :show="loading" rounded="sm">
          <b-card class="px-0 pt-4 analysis-card overflow-hidden">

            <component :is="tab.component" :id="tab.id"
                       :has-prev="index > 0"
                       :has-next="index < tabs.length - 1"
                       :total-tabs="tabs.length"
                       @prevSection="setTab('foundational_tab', index - 1, true)"
                       @nextSection="setTab('foundational_tab', index + 1)"
                       @loading="loadingChanged"
                       @innerTabChanged="innerTabChanged">
              <template #unlock-prompt>
                <unlock-content-prompt v-if="!isUnlocked"
                                       category="foundational" :chart-id="$store.state.charts.chart.id" />
              </template>
            </component>


          </b-card>


          </b-overlay>

        </b-tab>
      </b-tabs>

      <mobile-bottom-tabs :items="tabs" :value="foundationalTab" @input="(id) => setTab('foundational_tab', id)" />
  </div>
</template>

<script>
import TabTitle from "../../../elements/TabTitle";
import summary from "./summary";
import typeStrategyAuthority from "./type-strategy-authority";
import lifeForce from "./life-force";
import imprint from "./imprint";
import conditioning from "./conditioning";
import RolePurposeDirection from "./role-purpose-direction";
import MobileBottomTabs from "../../../structure/MobileBottomTabs";
import UnlockContentPrompt from "../../../billing/UnlockContentPrompt";
import AnalysisRouterMixin from "../../../../mixins/AnalysisRouterMixin";
export default{

  mixins: [AnalysisRouterMixin],
  components:{UnlockContentPrompt, TabTitle, MobileBottomTabs},

  computed:{
    isUnlocked(){
      return this.$store.state.charts.chartStatus.foundational
    },
  },

  watch: {
    foundationalTab(val){
      this.fixQuery('foundational_tab', val)
    }
  },
  created(){
    this.setup('foundational_tab')
  },
  data(){
    return {
      loading: false,
      tabs: [{id: 0, icon: 'icon-introduction', component: summary, name: this.$t('charts.analysis.foundation.tabs.0')},
      {id: 1, icon: 'icon-direction', component: typeStrategyAuthority, name: this.$t('charts.analysis.foundation.tabs.1')},
      {id: 2, icon: 'icon-conditioning', component: conditioning, name: this.$t('charts.analysis.foundation.tabs.2')},
      {id: 3, icon: 'icon-life-force', component: lifeForce, name: this.$t('charts.analysis.foundation.tabs.3')},
      {id: 4, icon: 'icon-imprint', component: imprint, name: this.$t('charts.analysis.foundation.tabs.4')},
      {id: 5, icon: 'icon-role-purpose', component: RolePurposeDirection, name: this.$t('charts.analysis.foundation.tabs.5')}],
    }
  },
  methods:{
    loadingChanged(val){
      this.loading = val;
    }
  }
}
</script>

<style lang="scss">

#foundational-analysis-wrapper{

  .tabs{
    .nav-link{
      font-size:0.9rem;
      font-weight:300;
      &.active{
        font-weight:600;
      }
    }
  }
  > .card-body{
    padding:0;
  }
}
.analysis-card{
  > .card-body{
    padding:0!important;
  }
}
</style>