export default {
    sidebar: {
        learnMore: `Learn more about your {type}`,
        signUp: 'Sign Up',
        'planetary-activations': `<p>Your BodyGraph consists of 26 planetary imprints. Each Planet activates one of 64 Gates, and each Gate can further be divided into 6 Lines. This data combines to describe unique qualities and characteristics of your being—they are the key ingredients for establishing Human differentiation.</p>
<p>The columns on either side of your BodyGraph indicate the planetary imprint at two key moments of your life:</p>
<ul>
    <li>On the right, the <strong>Personality</strong>. This is the planetary imprint at the moment of birth and represents what you have conscious access to. These are things you know about yourself and will recognize and identify with. The Personality is who you think you are.</li>
    <li>On the left, the <strong>Design</strong>. This is the planetary imprint 88° of the Sun (88-89 days) before birth and represents the nature of your unconscious.<i> While others will recognize these themes within you, you will learn to recognize your unconscious over time.</i>
    </li>
</ul>`,
        'gates': `<p>
    There are 64 Gates in the BodyGraph. Each Gate carries a theme or characteristic, they are a key ingredient for establishing Human differentiation. You can have up to 26 unique Gates activated in your Chart, each of which describes an aspect of your being.
</p>
<p>
    Alternatively, the Gates you don’t have activated are receptors to connect with others or the transit field.
</p>`,
        'channels': `<p>The Channels in your BodyGraph represent the flow of your life force. Through each Channel, the life force is expressed as a specific theme or energetic quality.</p><p> A Channel represents your unique gifts as well as potential limitations, and is something consistent throughout your life. Understanding these themes is essential to realizing your uniqueness, and the way in which you naturally interact with and impact others.</p>`,
        'defined-centers': `<p>
    There are 9 Centers, each of which has a specific quality and plays a different role when Defined (colored in) or Undefined/Open (white or black depending on light or dark mode).
</p>
<p>
    Your <strong>Defined Centers</strong> reveal those aspects of yourself that are fixed and reliable throughout your life—you can trust them. Metaphorically, you could say that what is defined is the ‘student’ and what is open is the ‘course’ of study.
</p>`,
        'open-centers': `
        <p>
    There are 9 Centers, each of which has a specific quality and plays a different role when Defined (colored in) or Undefined/Open (white or black depending on light or dark mode).
</p>
<p>
    Your <strong>Open Centers</strong> are neither empty or broken, nor do they need to be fixed. They are, however, vulnerable to being conditioned, and the conditioning can be overwhelming; distorting our perspective, and influencing how we make decisions.
</p>
<p>
    However, once we are aware of this dynamic, we can use our openness in specific areas to gain wisdom in life, rather than identifying with the energetic frequencies that are not part of who we are.
</p>`
    }
}