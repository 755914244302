<template>
  <div id="notifications">
    <div v-if="!activeNotification">
      <b-card v-for="notification in notifications.data" :key="notification.id"
              class="my-2 cursor-pointer border-primary bg-light mb-2 shadow-none hover-brightness notification-card"
              @click="activeNotificationId = notification.id"
      >
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center gap-10">
            <div>
              <div class="d-flex flex-wrap align-items-center gap-5">
                <span class="mbg-text-muted">{{formatDate(notification.updatedAt, false, 'DD MMMM yyyy') }}</span>
                •
                <span style="margin-top:-4px;">
                  <b-badge :variant="notification.type === 'news' ? 'warning' : 'success'" >
                  {{$t(`owl.notifications.${notification.type}`) }}
                </b-badge>
                </span>

              </div>
              <h6 class="my-1 ">{{ notification.title }}</h6>

              <label class="font-weight-light cursor-pointer"> {{ notification.preview }}</label>

            </div>
          </div>
          <div class="ml-auto">
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>
      </b-card>
    </div>
    <div v-else>
      <b-skeleton-wrapper :loading="!notification">
        <template #loading>
          <div class="mt-3">
            <b-skeleton width="85%" />
            <b-skeleton width="55%" />
            <b-skeleton width="70%" />
          </div>
        </template>
      <div class="d-flex align-items-center gap-5">
        <b-button @click="back" class="mb-0 d-flex align-items-center">
          <span class="icon-arrow-left"></span>
        </b-button>
        <h5 class="mb-0">{{activeNotification.title}}</h5>

      </div>

      <div class="mt-4">
        <dynamic-zone-renderer v-if="notification" :components="notification.components" />
      </div>
        <div class="grid grid-cols-1 gap-5 px-4">
          <p class="mb-0">
            <b-badge :variant="activeNotification.type === 'news' ? 'warning' : 'success'" >
              {{$t(`owl.notifications.${activeNotification.type}`) }}
            </b-badge>
          </p>
          <p>
            {{$t('general.date')}}: {{formatDate(activeNotification.updatedAt, false, 'DD MMMM yyyy') }}
          </p>


        </div>
      </b-skeleton-wrapper>

    </div>
  </div>

</template>

<script>

import {mapState} from "vuex";
import DynamicZoneRenderer from "../../renderer/DynamicZoneRenderer.vue";

export default {
  components: {DynamicZoneRenderer},

  watch: {
    activeNotificationId(){
      this.getNotification();
      this.updateLastRead();
    },
  },
  computed: {
    ...mapState({
      notifications: state => state.account.notifications,
      settings: state => state.account.settings
    }),

    activeNotification(){
      if (!this.activeNotificationId) return null;
      return this.notifications.data.find(notification => notification.id === this.activeNotificationId);
    }
  },
  data(){
    return {
      activeNotificationId: null,
      notification: null,
    }
  },
  methods:{
    updateLastRead(){
      if (!this.settings) return;
      if (this.notifications.data && this.settings.last_notification_read !== this.notifications.data[0].id) {
        this.$store.commit('account/SET_SETTINGS', {...this.settings, last_notification_read: this.notifications.data[0].id});
        this.axios.put('/users/settings', {
          last_notification_read: this.notifications.data[0].id
        }).then(() => {
        }).catch(()=>{});
      }
    },
    back(){
      this.activeNotificationId = null;
      this.notification = null;
    },
    getNotification(){
      if (!this.activeNotificationId) return;
      this.axios.get(`/content/notifications/${this.activeNotificationId}`).then((res) => {
        this.notification = res.data;
      }).catch(()=>{});
    }
  }
}
</script>

<style lang="scss">
.theme--dark{
  .notification-card{
    background: $bg-contrast-dark-low-opacity!important;
  }
}
</style>