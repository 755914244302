<template>
  <dynamic-zone-renderer v-if="authority" :components="authority.components" :add-paddings="false"/>
</template>

<script>

import AnalysisQueryMixin from "../../../../../mixins/AnalysisQueryMixin";
import DynamicZoneRenderer from "../../../../renderer/DynamicZoneRenderer.vue";
import authorities from "../../../../../helpers/rave/constants/authorities";

export default {
  components: {DynamicZoneRenderer},

  props:{
    chart: Object
  },

  mixins: [AnalysisQueryMixin],

  data(){
    return {
      authority: null,
    }
  },

  created(){
    this.getStrategyAuthorityCombo('authority', false);
  },

  methods:{
    getStrategyAuthorityCombo(key, isExtra, checkAuthority = true){
      let url;
      if(this.chart.chart.authority === authorities.NONE && checkAuthority){
        url = '/strategy-authority-combos/' +  this.chart.chart.type + '.' + this.chart.chart.authority + '.' + this.chart.chart.type + '.' + isExtra
      }else{
        url = '/strategy-authority-combos/' +  this.chart.chart.type + '.' + this.chart.chart.authority + '.' + isExtra
      }

      this.getContent(key, url);
    },
  }
}
</script>