export default {
  '0': {
    keynote: 'A Design of Commitment to Higher Principles',
    name: 'Channel of Awakening (20-10)',
    desc: `This is about being awake as yourself in the moment, and the ability to demonstrate this state of awareness through behavior. It is about self-expression, loving and accepting oneself and behaving authentically as oneself.
           In essence, this channel focuses on correct behavior—correct in the sense that it reflects who you are uniquely as yourself. While you can always speak for and express yourself, if you are to have an impact on those around you, they need to be able to recognize the clarity of your unique and authentic self-expression. The way you speak and express yourself is a key that lets others see whether you are clear enough. If you are clear, then you can influence them.
           You are able to speak for yourself and behave as yourself no matter what arises. People with this channel have a very profound capacity for survival. Irrespective of whether your experience is the most sublime or the most mundane, it is simply about the expression of your self-awareness. This is what the Buddha referred to as 'being awake’—essentially being an awakened self that fully expresses itself.`
  },
  '1': {
    keynote: 'A Design Where Awareness Must Become Deed',
    name: 'Channel of Charisma (20-34)',
    desc: `You feel the urge to be continually busy, and have the energy to maintain activities over long periods of time. You have consistent access to a potent source of energy, and by keeping busy you keep yourself comfortable, because that is your nature.
           Without waiting to be asked and responding to what is correct for you to busy yourself with, you may tend to be active simply for busyness' sake without necessarily getting things done. The key here is to do what you love, to apply your energy to things you enjoy doing, something that will only emerge out of response. This is when your formidable power to be active can be truly charismatic and inspiring to others.
           Once you wait to respond before you commit your energy, you can simply trust in the pure activity that naturally arises in the moment. This is your inner guide that determines your activities, and that ensures you immerse yourself in productive activities instead of wasting energy on inessentials. You can be busily self-absorbed in activities that are correct and satisfying, without being available to others; this is a natural and healthy process for you.
           In this channel we can see true manifesting power at work, and because it carries this sacral quality of vitality with it, it can have enormous impact on others. That is true charisma. `
  },
  '10': {
    keynote: 'A Design of Energy which Initiates and Fluctuates - Pulse',
    name: 'Channel of Mutation (60-3)',
    desc: `This channel carries the energy for bringing radical and revolutionary change into the world through one’s aura and physical presence. Here exists an element of unpredictability that impacts the environment, with the need to accept that this mutation happens of its own accord.
           This is an on/off process, also called a pulse energy, with energy available one moment, and gone the next. There is initial confusion, making it crucial to listen to one’s sacral response before committing. This mutative energy creates something of lasting value and impact only if the sacral energy is available through response.`
  },
  '11': {
    keynote: 'A Design of being a Keeper of Keys',
    name: 'Channel of Beat (2-14)',
    desc: `“Keeper of Keys” holds humanity to its unique path, and can point toward new directions and open new doors. People with this channel are highly individual and can move in some rather unusual directions. The direction is inherently known but to ensure it is pure and aligned with one’s individual destiny requires waiting to respond. This pure response overrides mental questions or doubts, such as "I can't go there" or "I have no reason to go there.” The 2/14 directs the body/mind without paying attention to these doubts and has access to essential resources to sustain one’s creative process and the ability to materially support others as they find their direction.
            Through contact, others might also find themselves moving in a new direction because the mechanics of this aura naturally empowers this in the other. In that way, humanity keeps evolving and expanding. `
  },
  '12': {
    name: 'Channel of Inspiration (8-1)',
    keynote: 'A Design of a Creative Role Model',
    desc: `Unique expression of creative skills, often accompanied by an unusual presence (like dressing in a radically different way) that may inspire and empower others in their own search for new directions and self-expression. 
           This channel represents the art of expression itself and requires an authenticity that is impervious to outside influences or conditioning. However, the 8-1 needs to be recognized, invited and appreciated for its unique impact and creative contributions in order to have impact.`
  },
  '13': {
    keynote: 'A Design of Following One\'s Convictions',
    name: 'Channel of Exploration (10-34)',
    desc: `A need to trust and follow one’s convictions without either imposing them or being deterred by others’ perceptions. Discovering what one truly loves to do and remaining centered in one’s convictions is crucial to access individual power and develop self-mastery. Doing what one loves, rooted in response is deeply authentic and by example influences and empowers others to find their own inner strength and self-reliance.
           Ultimately, perfecting personal behavior and living authentically as oneself leads to self-love, bringing empowerment and mutation into the world. `
  },
  '14': {
    keynote: 'A Design of Needing to be First',
    name: 'Channel of Initiation (51-25)',
    desc: `At its heart, this channel is the mystical process of Initiation. Through initiation or challenge, the depth and essence of one’s spirit is discovered, leading to the unhindered expression of uniqueness. Naturally competitive, always ready to express one’s fierce warrior spirit, or jump into the unknown to test limits and breakthrough to the next level. This process is part of one’s individual destiny and is uncontrollable. As such, life will bring the experiences needed to learn self-mastery.
           The innate courage and resilience exhibited in facing these challenges are enormously inspiring to others, even if they are shocked by one’s unexpected actions.`
  },
  '15': {
    keynote: 'A Design of Mental Ease Mixed with Doubt',
    name: 'Channel of Logic (63-4)',
    desc: `A logical mind, extremely well equipped for scientific research and for filtering patterns in order to verify their consistency. Naturally concerned about the future, looking for an answer or formula. The logical process demands that a theory be proven before put into practice and a tendency to doubt patterns until they are substantiated through facts. 
           Pressure builds when doubt can’t be resolved or there is no workable formula and can lead to anxiety and a restless mind. 
           Not designed to solve one’s own problems—strong capacity for clarity and precision when invited to share one’s mental process and help others find clarity about their own process.`
  },
  '16': {
    keynote: 'A Design of an Organizational Being',
    name: 'Channel of Acceptance (17-62)',
    desc: `An aptitude to understand the bigger picture and offer solutions based on detailed knowledge of any given subject - see patterns and understand how parts of a working structure function, and can be improved. A gift for organizing information conceptually based on and substantiated by facts. 
           Without the right timing, opinions may not have much value; others may simply feel bogged down by the myriad details underlying one’s perspective. Once recognized, can effectively organize projects and mental tasks that require detail work. `
  },
  '17': {
    keynote: 'A Design of Insatiability',
    name: 'Channel of Judgment (18-58)',
    desc: `A natural tendency to be highly critical, determined to improve things and conditions, always looking for perfection. A deep joy, vitality and insatiability for the good things in life, hampered only by a need for correction when things are out of balance. A keen ability to assess and judge, there is a sense that things can always be better. 
           The need to challenge existing patterns is inbuilt, and the desire to correct conditions - anything from social injustice to the mess in one’s backyard. When turned inward or directed toward others, this may result in pervasive, constant dissatisfaction that alienates others, and can make one’s life miserable.`
  },
  '18': {
    keynote: 'A Design of Talent',
    name: 'Channel of The Wavelength (48-16)',
    desc: `Achieving mastery of a skill that one is strongly identified with, and feels called to. Nurturing this talent involves dedication and commitment to bear fruit. Through consistent effort and practice one can achieve mastery that eventually allows one to break free of existing patterns and find a unique expression.
           Talent that is mastered in this way - through intuitive depth and committed practice of technique to perfect a skill - has the potential to change or improve other’s lives when shared.`
  },
  '19': {
    keynote: 'A Design of Determination',
    name: 'Channel of Concentration (9-52)',
    desc: `The ability to hold focus toward a particular object or process. It is natural to gather energy by stilling the physical body and senses until there is clarity about what to respond to. 
           Once focusing power is applied, there is pressure to remain still and focused. The perseverance to collect all pertinent details so as to perfect the pattern of any endeavor one concentrates on.`
  },
  '2': {
    keynote: 'A Design of an Archetype',
    name: 'Channel of Power (34-57)',
    desc: `You have the ability to respond intuitively to any situation, moment by moment, extremely alert and tuned into survival and wellbeing. Gate 57 has one purpose—to guide us towards survival and wellbeing. Combined with the 34th Gate of empowerment, it empowers our intuitive capacity to survive through response, and to remain healthy.
           People with the channel of Power have a potentially perfected capacity for survival as long as they are responding. The point here is that it is never what you think is good for you that truly is good for you. You are here to trust in your moment-to-moment response, and that is what protects you.
           This channel describes the power of spontaneous response, the ability to react quickly and with clarity and thereby ensure one’s well being. This is a consistently operating intuition, connected to your body consciousness and the experience of yourself as a unique and aware being, an archetype.
           Your self-empowered way of being in the world, though it is attuned to your own wellbeing and not concerned with the needs of others, is very inspiring to others as an example of how a self-empowered, vital and unique human being lives, perfectly attuned to survival.`
  },
  '20': {
    keynote: 'A Design of Being in the Flow',
    name: 'Channel of Rhythm (5-15)',
    desc: `It is crucial to follow one’s inner rhythm and not let anyone or anything interfere with what is a healthy and natural process. Responding to one's own timing and flow, letting things unfold effortlessly and with ease. If out of tune with one’s inner clock, this can also unintentionally create considerable chaos by disrupting others’ rhythm, especially those in the immediate environment. 
           A natural way of being in tune with nature’s rhythm and the unfolding patterns of life that keep humanity moving forward`
  },
  '21': {
    keynote: 'A Design of Leadership (for Good or Bad) ',
    name: 'Channel of The Alpha (31-7)',
    desc: `The potential and desire to be a leader - not necessarily formally, but to be a leading influence within a community, or chosen field. When in touch with society's needs, an inherent ability to understand trends, point out a direction and efficiently lead the collective into the future. 
           In order to exercise influence and be effective, it is necessary to wait to be recognized for one’s leadership qualities, rather than to impose authority`
  },
  '22': {
    keynote: 'A Design of Focused Energy',
    name: 'Channel of Recognition (30-41)',
    desc: `Driven by a powerful drive to explore and experience a wide range of feelings, one spends a lot of time dreaming and fantasizing—longing to learn through feeling. While driven by desire, it is not about fulfilling expectations, but enjoying the capacity to fantasize and the feelings generated through interactions with others.
           Substantial pressure felt as restlessness to constantly look for a new experience—using emotional Authority to clarify which to venture into, one at a time, so as to recognize the inherent value of each. This starts the process of the human experiential way, to learn about life through the experience of emotion. `
  },
  '23': {
    keynote: 'A Design of a “Jack of all Trades”',
    name: 'Channel of Transistoriness (35-36)',
    desc: `Moving from inexperience to experience, through a powerful emotional and sexual drive for a wide range of stimulating activities, hoping to find something better each time. This is not about outcomes or goals, as expectations often lead to disappointment and crisis, but to encounter life through its ups and downs. The sense that nothing lasts forever, that feelings are transitory, and to fully partake of each experience life brings. This multitude of experiences brings with it a whole array of talents—the ‘Jack of all trades.’ 
           Emotional clarity before jumping into experiences can lead to the gathering of wisdom, a sense of fulfillment and emotional depth. Otherwise, there will be crisis, emotional volatility, and a sense of futility. `
  },
  '24': {
    keynote: 'A Design of Mental Activity Mixed with Clarity',
    name: 'Channel of Abstraction (47-64)',
    desc: `A very active mind, constantly processing and sorting through a myriad of images and impressions. Experiences are examined from every possible angle, looking for creative ways to make sense of them. This can be likened to filling in the spaces between dots to reveal a new pattern, and can lead to a gift for clarity that can be shared with others in the form of inspiring stories or new perspectives.
           There can be a persistent mental pressure to sort through all the unordered information, yet one needs to wait for an unique sequence to emerge, which ultimately will. `
  },
  '25': {
    keynote: 'A Design of a Seeker/Searcher',
    name: 'Channel of Curiosity (56-11)',
    desc: `Continually seeking to collect stimulating experiences through which to explore new ideas, reflect on life and expand one’s horizons. Naturally curious, this is about searching rather than finding, and teaching and guiding others by sharing one’s discoveries and new-found wisdom through the art of storytelling. 
           When invited to speak, this may reveal a magical gift to weave insights and reflections about life experiences into stories that entertain and inspire. 
           Essential to encourage and allow children with this Channel to tell their stories, even if they are only half-truths, as storytelling is very important.`
  },
  '26': {
    keynote: 'A Design of Balanced Development',
    name: 'Channel of Maturation (53-42)',
    desc: `With this Channel defined, experiences need to complete an entire cycle from beginning, to middle, to end. Each cycle must be lived through before another experience can begin which requires a commitment. If entered into through one’s Authority, there is the energy and determination to complete this process—otherwise, experiences are repeated, without accomplishing anything. 
           This is not about personal goals, but about going through the experience, maturing, and discovering something that was not known before.`
  },
  '27': {
    keynote: 'A Design of Succeeding Where Others Fail',
    name: 'Channel of Discovery (46-29)',
    desc: `This process is about discovery, and determines both the viability of an experience and its value. To discover something of value is to enter into an experience correctly, trusting that one is in the right place at the right time, and fully surrender to it not knowing where it will lead. Past experience teaches one to be present without expectation, understanding that an experience won’t make sense until it has completed. 
           Waiting to be asked before responding guarantees clarity about saying ‘yes’ and ensures one is fully committed; unless there is total commitment, perseverance will be stressful and burdensome, and the experience can potentially lead to disappointment and upheaval. `
  },
  '28': {
    keynote: 'A Design of a Witness',
    name: 'Channel of The Prodigal (33-13)',
    desc: `Watching experiences come and go, without expected outcomes, often leads to a journey that breaks with one’s past and tradition. A gift to witness things in the moment and reflect on them later, knowing that each cycle of experience will make sense in the end. It is vital to take time to digest, reflect and assess—in that way, much wisdom will be gained that can be passed on to others.
           One of the most powerful memory Channels, it is crucial to keep recorded reflections of the past. `
  },
  '29': {
    keynote: 'A Design of Being Driven',
    name: 'Channel of Transformation (54-32)',
    desc: `Ambition to succeed, and the determination to advance in the material world. One instinctively knows what type of work will lead to fulfilling one’s potential and looks for recognition and support by those who can advance one’s interests. Recognition and a consistent effort to improve can lead to a step by step process of change and transformation.
           While driven to prove one’s worth, one is also motivated by the desire to play an integral role in the functioning of the interdependent tribal community, ensuring its success.
           This channel carries a mystical theme through Gate 54's line Enlightenment/ Endarkenment, which points to the potential for spiritual transformation.`
  },
  '3': {
    keynote: 'A Design of Survival',
    name: 'Channel of Perfected Form (10-57)',
    desc: `Being deeply connected to your intuitive awareness that cuts to the core of things, you are a master of the art of survival. Your behavior, if guided by your intuition and grounded deeply in existential certainty, demonstrates the kind of beauty that is impactful and attracts others’ attention. This certainty derives from an intrinsic trust in your ability to survive, guided by your instincts.
           This is a deep inner creative process that cannot be controlled or managed by the mind or the emotions, simply because it can only come out of our spontaneous intuitions.
           When you enter into situations correctly, guided by your intuition, this results in ‘perfected behavior’ as you are living life true to yourself, and as yourself.
           Being self-centered and self-absorbed is a natural part of your process, and is something that ensures your survival. Even though you attract attention and impact people around you, you are not interested in whether others see your abilities: You are simply immersed in your own process, aware of being different and interested only in perfecting your form. Enjoy your journey of creating and perfecting as this is how you are designed to express your uniqueness.
           Tomorrow holds no fear if you let yourself be guided by this deep intuition that is available moment by moment. There is great joy in this process, as the perfection of your form or behavior creates exceptional beauty in your life, and turns your life itself into a piece of art. People with this channel are often artists, architects or designers who work to perfect and beautify the form.`
  },
  '30': {
    keynote: 'A Design of a Transmitter',
    name: 'Channel of Surrender (26-44)',
    desc: `The art of creative enterprise—one is alert to changing markets and economic situations, able to assess others’ material needs and spontaneously adjust to them. A deep instinct for what is essential to keep one’s tribal community thriving, and the talent and Ego strength to relay anything from products to ideologies to the tribe, and see things through.
           Best when one’s skills are recognized by others, in order to gather the necessary support to establish a foundation from which to successfully launch operations. A healthy balance between work and rest is fundamental, as well as making sure that one’s service is fairly rewarded.`
  },
  '31': {
    keynote: 'A Design of Sensitivity',
    name: 'Channel of Synthesis (49-19)',
    desc: `The need to have an integral role within the tribal/communal environment, focused on the sensitive issues of protecting and fairly distributing resources, where one’s capacity to work within the community is recognized. Inherently sensitive to rejection or acceptance of one’s position in the family/tribal structure.
           Seeks traditional emotional familial relationships—courtship, marriage, and bonding. It is crucial to arrive at emotional clarity before committing to the responsibilities inherent in these bonds. One’s well being depends on supporting others, but it is essential that one’s needs are also met.`
  },
  '32': {
    keynote: 'A Design of a Part seeking the Whole',
    name: 'Channel of Community (37-40)',
    desc: `Seeking a community, small or large, where one fits in and belongs. It is essential to wait for Emotional clarity about whether to enter into a bond.
           Roles and dynamics will need to change and be adjusted so that one doesn’t feel trapped in roles that no longer serve. `
  },
  '33': {
    keynote: 'A Design of a Materialist',
    name: 'Channel of Money (45-21)',
    desc: `The drive to succeed powered by Ego strength and willpower to reach one’s goals. Willing to work hard so that over time one learns to master the material plane. While success is essential to fulfill one’s needs, this channel ultimately describes the underlying principles that ensure everyone is provided for. Therefore, one’s interests need to be aligned with the environment/community so that all may benefit. 
           It is natural, in fact imperative, whether a freelancer or head of a company, that others are not allowed to exert any control as one needs to work independently, and be in charge of one’s domain. 
           A manifesting channel, one needs to inform before taking action to avoid creating resistance.`
  },
  '34': {
    keynote: 'A Design Focused on Reproduction',
    name: 'Channel of Mating (59-6)',
    desc: `A powerful auric field that breaks down barriers easily and naturally establishes intimacy. A need for deep connection to others, whether in intimate relationships or friendships. Due to its intensity, this energy can easily be misread and cause confusion if others misinterpret one’s intentions
           This design describes the foundation for any creative endeavor to flourish —whether creating a new life, or birthing a new project. 
           Before committing to intimate relationships, whether personal or work related, it is vital to wait out one’s emotional wave. `
  },
  '35': {
    keynote: 'A Design of Custodianship',
    name: 'Channel of Preservation (27-50)',
    desc: `Nurturing and supportive, one naturally assumes responsibility to maintain the values and ethics within one’s family/community, preserve its infrastructure, and guarantee its survival and standard of living. One’s aura easily pulls others in, engenders trust, and can lead to being a community leader, teacher, or responsible for the care of those who require support. Ultimately, this is about the preservation of genetic lineage.
           Following one's Inner Authority ensures clarity about where to direct energy, and how to balance responsibility for others with one’s own needs.`
  },
  '4': {
    keynote: 'A Design of a Thinker',
    name: 'Channel of Awareness (61-24)',
    desc: `A mind under constant pressure, designed to think about life’s mysteries in unconventional ways, and spontaneously access what lies beyond the known. There may be sudden insights that break up established thinking patterns and even transform others’ perceptions of life and truth.
           This is not a conscious process but happens on its own - a mental pressure beyond the control of the rational mind. The same repeated thought patterns are experienced until there is a breakthrough - a sudden inspiration that leads to a knowing in the moment. Silence helps access the gap, that place where thinking stops and realization arrives. `
  },
  '5': {
    keynote: 'A Design of Individuality (from Genius to Freak)',
    name: 'Channel of Structuring (43-23)',
    desc: `Designed to conceptualize and express a unique perspective that is inspiring to others. The ability to think outside the box, and to express what one knows in unique and innovative ways in relatable language, radically changing others perceptions. This channel describes the kind of thought process that can lead to quantum leaps.
           Waiting to be invited to share one’s insights ensures that this innovative thinking can have true impact. Without correct timing, there may be resistance or even rejection. Others may not understand what is being communicated, which can lead to feeling alienated—a freak instead of a genius.`
  },
  '6': {
    keynote: 'A Design of Stubbornness',
    name: 'Channel of Struggle (28-38)',
    desc: `The fierce, independent spirit of a warrior, struggling to find value in life - to live life to its fullest. To remain true to oneself, capable of confronting adversity, and to stand up for others if correct. Knowing what to fight for which can only be productive when guided by strategy and authority. The perseverance to struggle until it is clear what gives meaning to life, ultimately finding one’s purpose. There is always a certain tension created by the stress from the adrenaline system.
           One can truly empower others through an exemplary way of struggling against all odds to stay with one’s unique path, thereby encouraging them to embark on their own process of individuation.`
  },
  '7': {
    keynote: 'A Design of Penetrating Awareness',
    name: 'Channel of The Brain Wave (57-20)',
    desc: `An extremely keen intuition that can easily penetrate to the core of things and act spontaneously in the moment. The ability to deeply impact those who are receptive to one’s mutative potential, through sharing existential knowing and the wisdom acquired over time.
           This is a deep-seated intelligence that works in the moment and enables one to intuitively assess any situation on the spot. It is an innate survival intelligence and as long as one trusts to be guided by this spontaneous knowing, can ensure one’s physical well-being and safety.`
  },
  '8': {
    keynote: 'A Design of Moodiness',
    name: 'Channel of Emoting (39-55)',
    desc: `The channel of fluctuating emotions and moods, and deeply creative potential with a tendency to be melancholic. The expression of the emotional spirit, and the ability to delve into deep recesses of one’s spirit and truth and find spirit awareness
           The need to recognize the fluctuating waves are simply a chemistry and that emotions such as joy, sadness, and melancholy, are to be experienced as a way to access true creativity. Learning how to recognize the nature of others' spirit and who has the right spirit. Learning how to provoke others to tease out their spirit, and turn pleasure to pain and joy to sadness, potentially releasing something new within them that profoundly impacts and even changes them.`
  },
  '9': {
    keynote: 'A Design of a Social Being',
    name: 'Channel of Openness (12-22)',
    desc: `This channel is about emotional expression, and finding opportunities to impact others through expressing oneself. Although an inherently social channel, potentially full of charm and grace, it is uninterested in bonding, mating or sharing and is somewhat of a stranger, remaining aloof. Its difference and individuality naturally draws attention, and there is an awareness of the impact one’s individual ways have on others.
           With the Solar plexus connected to the Throat, emotions can always be expressed, but if done when at the low end of an emotional wave when feeling melancholic, will lead to being misunderstood or unheard. One can be very sensitive and possibly afraid of rejection, and possibly express anger when being resisted.
           The potential for impact through charm and grace is only available when in the mood. Being in the right mood is when magic happens - then others are open to one’s unique expression which can lead to a profound impact on the social environment.`
  }
}



