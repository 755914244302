export default {
  'name': {
    one: 'Gate',
    many: 'Gates',
    activatedBy: 'Activated by:',
    noActivations: 'No activations in this Gate',
    atLine: ' at Line '
  },
  '1': {
    name: 'The Creative',
    titleKeynote: 'The Gate of Self-Expression',
    keynote: 'Creation as a primal force. The energy potential to manifest inspiration without limitation.',
    desc: `The drive to express oneself in a unique and creative way, with the ability to focus on the creative process without concern for outside opinion. A dominant theme is the deep need to express unique and authentic individuality, rooted in one’s own direction, unhindered by established rules of the Collective. These new ways of expression have great impact, and may even inspire others to take new directions.
           People with this gate need to interact with those who recognize their potential, and they may feel attracted to people with Gate 8 who can help them exteriorize their work. When collaborating in this way, they can become a role model and impact the collective with the manifestation of their creative work.`
  },
  '10': {
    name: 'Treading',
    titleKeynote: 'The Gate of the Behaviour of the Self',
    keynote: 'The underlying code of behaviour which ensures successful interaction despite circumstances.',
    desc: `A natural sense of how to behave as oneself, irrespective of one’s environment. A keen sense of how others behave, leading to deep discomfort if confronted with behaviour one considers incorrect. Developing self-love through accepting and loving one’s uniqueness, and in turn empowering others to find self-love.
           A particular role of behavior, which expresses authentically as oneself when surrendered to. This process is guided by intuition if connected to Gate 57, empowered by the Sacral if connected to Gate 34, or expressed as one’s unique voice through Gate 20.`
  },
  '11': {
    name: 'Peace',
    titleKeynote: 'The Gate of Ideas',
    keynote: 'A harmonic condition in the individual or society that permits assessment before renewed action.',
    desc: `Exploring ideas that can be shared with and stimulate others. Since ideas come and go, this process is mainly about reflection and the possibility to conceptualize what has been experienced. 
           One processes the past to make sense of it, assessing experiences based on what is remembered positively. This can result in formulating ideals and beliefs, and ultimately the formation of belief systems.
           This process is to inspire and serve others, and can lead to confusion and disappointment if applied to one’s own life, such as trying to resolve personal difficulties.`
  },
  '12': {
    name: 'Standstill',
    titleKeynote: 'The Gate of Caution',
    keynote: 'The quality of restraint and the importance of meditation and inaction in confronting temptation.',
    desc: `An ability to be very articulate in expressing unique perceptions, when in the mood. This is conveyed not only through words, but through inflection and frequency behind the words. An intention to be understood and a natural tendency to be cautious about how to express one’s views. A gate of silence, benefitting from stillness and contemplation to let one’s message mature.
           Art is a good medium to translate one’s perceptions, such as the use of poetry or music. With the right timing there can be a profound and transformative impact on cultural norms. If the mood is not there, most likely one will not be heard.`
  },
  '13': {
    name: 'The Fellowship of Man',
    titleKeynote: 'The Gate of the Listener',
    keynote: 'Universal ideas and values in an ordered framework which inspires humanistic cooperation.',
    desc: `A gate of memory which starts the reflective process—collecting and remembering things of personal value, that can also be of value to the community.
           Possessed with a natural gift for listening and open to hearing others’ experiences and adventures, one is seen as a confidant to share secrets with. It is essential to discern which of these can be shared and the right time to do so.`
  },
  '14': {
    name: 'Possession in great measure',
    titleKeynote: 'The Gate of Power Skills',
    keynote: 'The accumulation and retention of power through skilled interaction, coupling grace with control.',
    desc: `A deeply material gate that supplies the entire energy for the 'material way.' This is about having access to resources and material wealth, and the power and skill to distribute and direct resources in a way that supports humanity’s need for change and mutation in order to survive as a species. Inherent here is the power to follow one’s unique direction in life, which ultimately benefits the direction of the whole.
           This gate empowers oneself as well as others towards investing resources that keep humanity evolving and expanding. Having immense energy available for any endeavor and task that one enjoys, and if aligned with one’s path, capable of generating substantial resources not only for personal gain but to be distributed so that change is possible and humanity’s direction maintained.`
  },
  '15': {
    name: 'Modesty',
    titleKeynote: 'The Gate of Extremes',
    keynote: 'The quality of behaviour which expresses the proper balance between extremes.',
    desc: `A highly magnetic aura, and innate sense of individual rhythm that enables the acceptance of others' rhythms and timings, even if they are extreme. Underlying this capacity is a love for humanity and an understanding that diversity is needed in order for life to move forward.
           Not staying fixed but moving fluidly through a whole spectrum of rhythms which may appear extreme to others, but is perfect for one’s process. Mindful not to get lost in constantly changing rhythms as this can lead to a loss of focus or productivity.`
  },
  '16': {
    name: 'Enthusiasm',
    titleKeynote: 'The Gate of Skills',
    keynote: 'The great art of enriching life by the harmonic channelling of energy.',
    desc: `Possessing many skills—if insecure about lacking depth, dedication and consistent effort can be applied toward mastering a specific skill to bring it to flowering. A need to be strongly identified with the chosen skill in order to undertake this process of constant repetition, e.g. learning a piece of music. Through this process, skill can become art: the dancer becomes the dance.
           Without a specific skill this can also manifest as an art for living.`
  },
  '17': {
    name: 'Following',
    titleKeynote: 'The Gate of Opinion',
    keynote: 'The ancient law that those who wish to rule must know how to serve.',
    desc: `A need to understand how things work, and the ability to easily see into the functioning of particular patterns. Looking for answers to doubts and fears about the future, which still need to be substantiated by facts and details to no longer be just opinions. The fears that naturally come up can only be abated through patterns and solutions that have been tested over time and can be relied on.
           Without Gate 62, there is difficulty supporting concepts with facts and details - it is important not to get fixated on opinions that cannot be substantiated by facts.`
  },
  '18': {
    name: 'Work on What Has Been Spoilt',
    titleKeynote: 'The Gate of Correction',
    keynote: 'The vigilance and determination to uphold and defend basic and fundamental human rights.',
    desc: `Easily dissatisfied with imperfections and keen ability to spot flaws and weaknesses and correct them. This critical awareness can be focused on patterns at a personal or collective level, cleansing and restoring balance to what has been spoilt or corrupted.
           Underlying this is discomfort with authority and a strong drive to challenge anywhere it is perceived to be out of order or undermining personal or collective well being.`
  },
  '19': {
    name: 'Approach',
    titleKeynote: 'The Gate of Wanting',
    keynote: 'That all things are interrelated is apparent and manifested through the action of approach.',
    desc: `A need to access basic resources through successful interactions with others so that one’s material and emotional needs for food, shelter and protection of territory are met. Looking for those who share one’s principles to strike a bargain, bond and interact with in specific and regulated ways, thus creating the foundation for a functioning community.
           One is particularly sensitive and aware of these needs in oneself and others and has to balance one’s own neediness, which can be accentuated, with those of the tribe or larger community.`
  },
  '2': {
    name: 'The Receptive',
    titleKeynote: 'The Gate of Direction of the Self',
    keynote: 'Receptivity as the primal base through which any response is determined. the root of action.',
    desc: `An innate sense of direction, independent of will, that guides one towards beauty and love as part of a bigger unfolding. When the direction is in alignment with one’s path, this process becomes an example for the collective of an evolutionary trajectory, bringing necessary change.
           While knowing the way, Gate 2 needs access to resources to keep moving along the right track. The ability to empower others in their search for direction, and point them toward resources needed on their path.`
  },
  '20': {
    name: 'Contemplation',
    titleKeynote: 'The Gate of Direction of the Now',
    keynote: 'Recognition and awareness in the now, which transforms understanding into right action.',
    desc: `An existential gate, not concerned with the past or future but with contemplating and being absorbed and awake in the Now. The ability to articulate this awareness but not necessarily act on it (unless connected to a motor), and the capacity to survive authentically as oneself.
           Often surprising oneself and others with spontaneous expressions of intuitive knowing from within, not controlled by the mind. These expressions of individual knowing reveal the potential for evolutionary change and deeply impact and empower others.`
  },
  '21': {
    name: 'Biting Through',
    titleKeynote: 'The Gate of the Hunter/Huntress',
    keynote: 'The justified and necessary use of power in overcoming deliberate and persistent interference.',
    desc: `A strong force on the material plane and a need to be responsible and in control of one’s own resources, territory, and fundamentals like where to live, what to wear, and how to make a living.
           One resents being told what to do and will ‘bite through' conditioning forces to maintain independence and control. Ultimately, one’s purpose lies in serving the needs of others as much as one’s own, therefore, when operating correctly, what benefits oneself also benefits others.
           Essential to follow one’s Inner Authority and resist the impulse to control others, which only leads to encountering resistance.`
  },
  '22': {
    name: 'Grace',
    titleKeynote: 'The Gate of Openness',
    keynote: 'A quality of behaviour best suited in handling mundane and trivial situations.',
    desc: `When in the mood, the potential for emotional openness and graceful social interaction that makes one naturally attractive to others. Potential to transmit emotional spirit that impacts and empowers strangers as well as friends.The ability to listen, and be open and attentive to what is new and potentially mutative, thereby catalyzing change in the social environment with true grace.
           It is vital to honor mood swings and be alone at the low end of the emotional wave rather than exhibit anti-social behavior. Others can only be empowered with the depth of one’s emotional spirit when the mood is right.`
  },
  '23': {
    name: 'Splitting Apart',
    titleKeynote: 'The Gate of Assimilation',
    keynote: 'Amorality. The awareness and understanding that leads to acceptance of diversity.',
    desc: `Innovative thinking that can cut through standardized concepts only if the timing is right and the language used adequately expresses one’s inner knowing Insights can radically differ from the cultural norm, with an openness to diversity and a disregard for morality.
           The need to ascertain whether others are open to recognizing the insights offered, otherwise one may encounter resistance, and the insights can appear outlandish. If invited to share one’s unique perspectives, then there is an ability to truly impact and initiate new ways of thinking.`
  },
  '24': {
    name: 'The Return',
    titleKeynote: 'The Gate of Rationalization',
    keynote: 'The natural and spontaneous process of transformation and renewal.',
    desc: `An individual thinker, bound to contemplate until a concept is clear and has the potential to impact others - a pressure to resolve and filter spontaneous insights originating from Gate 61, Inner Truth - selecting mutative and inspiring content. The mind then goes over the same thoughts as long as it takes for a rational concept to emerge, which can eventually be communicated to others.
           This process is not based on experience or substantiated by facts but is a unique process of transformation based on individual knowing.
           While tremendously inspiring to others, this mental process does not support resolving one’s own issues. There is no certainty at the mental level and this may trigger anxiety, potentially leading to wrong decisions. Instead, use strategy and authority to make decisions.`
  },
  '25': {
    keynote: 'The perfection of action through uncontrived and spontaneous nature.',
    name: 'Innocence',
    titleKeynote: 'The Gate of the Spirit of the Self',
    desc: `A quality of impersonal or universal love that naturally and unconditionally flows toward all living things, expressing as surrender to one’s own Self. There is a mystical quality to this love that engages one in challenging experiences to test the innocence of one’s spirit.
           This is the spiritual warrior ready to take on challenges that come with Initiation, to stretch beyond the limits of the mundane world, shielded by innocence. This uncontrived nature is grounded in the spirit of Self, and with each initiation, the beauty of one’s spirit is further realized and enriched, along with deep wisdom that can empower others on their journey.`
  },
  '26': {
    name: 'The Taming Power of the Great',
    titleKeynote: 'The Gate of the Egoist',
    keynote: 'The maximization of the power of memory applied to the nurturing of continuity.',
    desc: `The Ego strength and survival skills to convince others of the need for goods, products and services essential for the material well being of the community. An ability to manipulate and turn almost any situation into something marketable, thereby having one’s material needs met — the prototype of the salesperson/politician.
           A potential to be very productive, but it is essential to keep a natural rhythm between work and rest since efforts are based on Ego power, which only goes so far. Once skills are recognized, one can offer services if able to maintain one’s own schedule and work independently.`
  },
  '27': {
    name: 'Nourishment',
    titleKeynote: 'The Gate of Caring',
    keynote: 'The enhancement of the quality and the substance of all activities through caring.',
    desc: `Innate compassion for, and the instinct to protect and nourish, those who need guidance, care and support. This applies as much to one’s own family as to the extended family of community.
           Without Gate 50, one may lack instinctual awareness to set natural boundaries or limits on where to place one’s attention—being guided by Inner Authority as to who or what to care for prevents overextension and losing sight of one’s own needs.`
  },
  '28': {
    name: 'Preponderance of the great',
    titleKeynote: 'The Gate of the Game Player',
    keynote: 'The transitoriness of power and influence.',
    desc: `The potential to know what is, and is not, worth fighting for guided by strategy and authority. Driven by the fear of death, the need to take risks to feel more alive and to discover whether life has value or meaning, and what brings purpose to one’s existence.
           Without Gate 38, one may yield to pressure and go from one thing to the next in search of that meaning, losing oneself in unnecessary struggles leading to exhaustion.`
  },
  '29': {
    name: 'The Abysmal',
    titleKeynote: 'The Gate of Perseverance',
    keynote: 'The deep within the deep. Persistence despite difficulties has its inevitable rewards.',
    desc: `Saying “yes” to an experience begins a new cycle. This requires commitment and perseverance that, when correct, see’s one through even the most challenging situations. This is not about fulfilling expectations, but about entering into new experiences without preconceptions, and seeing where they will lead.
           It is essential to wait for clarity about whether to dedicate time and energy to an experience, since a half-hearted commitment leads to uncomfortable situations without dedication, often forcing one to quit.`
  },
  '3': {
    name: 'Difficulty at the Beginning',
    titleKeynote: 'The Gate of Ordering',
    keynote: 'The fundamental challenge of initiation is to transcend confusion and establish order.',
    desc: `Having a strong unique and creative drive within and the energy to transcend the confusion and chaos prevalent at the beginning of each new process. The ability to establish order so that the mutative energy can bring something new into the world when there is a clear sacral response.
           It takes time to bring order out of chaos, hence the need to accept the natural limitations (arising out of Gate 60) that hold back energy until it is time to be released. This can feel very frustrating or even depressing as one is enthusiastic about change, and there may be bursts of energy followed by inertia that can be unsettling and destabilizing to others. Awareness of this dynamic helps surrender to the fact that the timing for change happens on its own and that the right structure needs to be in place to affect dynamic change.
           During times when the energy is dormant it is beneficial to connect with one’s own inner process and creative muse.`
  },
  '30': {
    name: 'The Clinging Fire',
    titleKeynote: 'The Gate of Feelings',
    keynote: 'Freedom recognized as an illusion and limitation accepted as a fate.',
    desc: `Driven by a powerful yearning to fulfill one’s dreams, and an often compelling need for change and new experiences generated by desire. This is a process of reaching for fulfillment through each interaction and is subject to the ups and downs of the emotional wave.
           To find balance, it is essential to surrender to what life brings and accept limitations. In this way, one can let go of expectations and recognize feelings that lead to the potential for change, whether they fulfill one’s dreams or not.
           By entering into experiences guided by Inner Authority, one can avoid the burnout and disappointment that follows from acting blindly.`
  },
  '31': {
    name: 'Influence',
    titleKeynote: 'The Gate of Influence',
    keynote: 'The law of friction, whether active or passive, that engenders transference and thus influence.',
    desc: `The ability to present new and improved societal patterns, and once recognized and invited, exert influence within one’s immediate environment or the public realm. Ultimately, this influence is used to verbally communicate a vision for a new direction and show others how to move toward it for a more secure future.
           Within the public realm, to successfully transfer this vision requires the backing of the collective, and being in tune with and addressing the needs of the whole.`
  },
  '32': {
    name: 'Duration',
    titleKeynote: 'The Gate of Continuity',
    keynote: 'All that endures is change.',
    desc: `An innate ability to recognize things that have lasting value for the material direction and survival of one’s tribal community, and those people who can make valuable contributions to maintaining the stability of its structure. This ensures tribal continuity through a process of consistent change and transformation, while maintaining a stable core.
           Potential for internal conflict regarding the need for progress and its inherent risks versus the need for the security of the status quo. Relying on Inner Authority and staying attuned to one’s instincts will point to what has lasting value, and identify that which hinders progress and can be safely discarded.`
  },
  '33': {
    name: 'Retreat',
    titleKeynote: 'The Gate of Privacy',
    keynote: 'Active withdrawal and the transformation of a weak position into a strength.',
    desc: `Teachers, writers, and storytellers, with a gift to express wisdom gathered from experience. Lessons are learned and insight derived from one’s own experiences as well as those of others. The ability to retreat in order to process and reflect before moving on. A strong need for privacy, enjoying time to contemplate, and ponder lessons learned.
           Reflection attunes one to the right time to reveal insights and lessons from any cycle of experience.`
  },
  '34': {
    name: 'The Power of the Great',
    titleKeynote: 'The Gate of Power',
    keynote: 'Power is only great when its display or use serves the common good.',
    desc: `A gate of empowerment – a potent source of pure, vital energy emerging through response, empowering individuation and the expression of uniqueness. An asexual energy that needs to remain a pure energy resource, not shared with others, in order to ascertain one’s independence and act on one’s convictions. Using energy to respond to any outside stimuli that leads to self-empowerment.
           Without a connection to splenic awareness (through Gate 57), there is inconsistent access to instinctive guidance that ensures one’s well being, and the possibility of getting lost in the momentum of this powerful force and carried away.`
  },
  '35': {
    name: 'Progress',
    titleKeynote: 'The Gate of Change',
    keynote: 'By design, progress cannot exist in a vacuum and is dependent on interaction.',
    desc: `Always ready to engage in a new experience without thinking of the consequences. A deep curiosity and desire for change driven by the need to experience depth of feeling. On the lookout for the next thing the moment an experience ends—this is not about finding lasting satisfaction, but collecting experiences from which to learn.
           This can also manifest as seeking one emotional rush after another simply to escape boredom, with the inevitable repercussions. If experiences are entered into correctly, then life’s many-faceted adventures will bring much wisdom.`
  },
  '36': {
    name: 'The Darkening of the Light',
    titleKeynote: 'The Gate of Crisis',
    keynote: 'The rule of cycles in which decline is a natural but not enduring stage.',
    desc: `Craving the release of a reservoir of deep feeling through profound and intense emotional/sexual experiences in order to discover one’s emotional depth and truth, leading to personal transformation and growth. This is about confronting inherent fears and vulnerabilities, moving through extreme highs and lows, and is a deeply challenging journey. One’s personal intensity can be stimulating but also easily overwhelm others.
           Surrender to the inevitable ups and downs develops maturity and the ability to learn from and handle crisis with emotional clarity.`
  },
  '37': {
    name: 'The Family',
    titleKeynote: 'The Gate of Friendship',
    keynote: 'The manifestation macro- and micro-cosmically of the organic nature of communities.',
    desc: `A strong sense of community, and an open and friendly disposition that easily engages with others and enables one to hold a family or community structure together. Oriented towards emotional connection with others, one’s sensitivity perceives if others are open, looking to ensure harmony and mutual support. Bonds with family or larger organizations are built on a bargain, as one’s role is to distribute assets or services.
           One needs to continually adjust to the changing dynamics and one’s role in interdependent relationships built on loyalty and support, to avoid feeling trapped.`
  },
  '38': {
    name: 'Opposition',
    titleKeynote: 'The Gate of the Fighter',
    keynote: 'The ability to preserve individual integrity through opposition to detrimental forces.',
    desc: `A natural warrior, always ready to stand up for one’s independence and fight against the odds to find value and purpose in life. Formidable courage and resilience in confronting adversity. Difficulties with, and a resistance to listening to others, so as not to compromise one’s search for individual purpose.
           Following strategy and authority to fight for the right things instead of wasting strength and health. Resistance from others and not knowing what is correct to fight for can lead to exhaustion and the experience of defeat.`
  },
  '39': {
    name: 'Obstruction',
    titleKeynote: 'The Gate of Provocation',
    keynote: 'The value of obstruction in provoking analysis, assessment and re-evaluation.',
    desc: `Pressure to provoke the emotions of others to discover the quality of their spirit, clearly showing who one resonates with. Looking to others (gate 55) to reflect back one’s own feelings.
           People with this gate are provocative by nature, and can easily and unintentionally trigger irritation just through the energetic imprint of the aura. The reactions of others called forth are often a direct reflection of one’s own state of being. Learning not to take others’ negative reactions personally while paying attention to one’s moods so as not to provoke when off balance just to engender confrontation. Over time learning how to evoke pleasure instead of pain, joy instead of sadness.
           At the root of this energy is the need for humanity to develop emotional awareness - the 39th gate helps bring about the mutation of emotional awareness through evoking the spirit consciousness of the Solar Plexus.`
  },
  '4': {
    name: 'Youthful Folly',
    titleKeynote: 'The Gate of Formulization',
    keynote: 'The energy to beguile and succeed despite ignorance. Freedom from retribution.',
    desc: `A need to understand things, looking to find answers to questions about life's processes based on doubts and anxiety about the future created by mental projections. An ability to come up with concepts that may or may not have any validity, that need to be tested and substantiated by facts. A capacity to formulate answers that is best applied to basic questions about life itself, and to share those with others so they can benefit and move toward a secure future.
           It is not necessarily helpful in finding answers to questions about one’s own life’s process.`
  },
  '40': {
    name: 'Deliverance',
    titleKeynote: 'The Gate of Aloneness',
    keynote: 'The point of transition between struggle and liberation.',
    desc: `A love of work, combined with the willpower to exert oneself, one naturally takes on the role of provider within a tribal or communal structure. To successfully sustain this role, and continue to look after the needs of others, it is vital that one’s work is successful and appreciated, and that one is part of a healthy, dynamic and functional structure. This is the basis of the bargain, as one’s willingness to provide depends on the loyalty, appreciation and support of those one cares for.
           The ability to work depends on willpower, which only takes one so far. It is crucial to maintain healthy boundaries and take time alone, away from interdependent dynamics, in order to recuperate.`
  },
  '41': {
    name: 'Decrease',
    titleKeynote: 'The Gate of Contraction',
    keynote: 'The limitation of resources which maximizes development of potential.',
    desc: `The drive to fantasize about having new experiences—the pressure of desire manifesting as sexual fantasy, daydreaming and the impulse to experience life and interact with others through feelings. 
           A consistent pressure for change drives one towards new experiences. To recognize the inherent value of each, it is crucial to limit experiences to one at a time. This is not about fulfilling expectations, but about enjoying the discovery of new feelings that await when one is guided by inner Authority. `
  },
  '42': {
    name: 'Increase',
    titleKeynote: 'The Gate of Growth',
    keynote: 'The expansion of the resources which maximizes the development of full potential.',
    desc: `Consistent pressure to finish what has been started, and the energy to see it through to completion if entered into the right way. Each cycle seen through to completion builds on prior lessons learned, and over time the collection of experiences creates a foundation that ensures growth.
           Waiting until experiences reach their natural conclusion rather than forcing premature endings, so as not to get stuck in situations and caught in old unresolved patterns`
  },
  '43': {
    name: 'Breakthrough',
    titleKeynote: 'The Gate of Insight',
    keynote: 'For achievement to be maintained, a new order must be fairly established.',
    desc: `Unique perception that is the result of sudden insights, and a constant process of structuring these insights so they can be formulated as concepts that bring radically new insights into the world. This is not a premeditated process but happens spontaneously. These insights are not necessarily backed up by facts and they can diverge considerably from conventional perception. Without Gate 23, the difficulty is in making them understandable and having the courage and resolve to stand behind one’s own knowing.
           There may be difficulties listening to others, which may be a genetic predisposition to protect oneself from unwanted outside influences. `
  },
  '44': {
    name: 'Coming to Meet',
    titleKeynote: 'The Gate of Alertness',
    keynote: 'The success of any interaction is based on the absence of any preconditions.',
    desc: `Naturally aware of others’ skills and proficiencies, one is able to instinctively select the right person for a job—the prototype of the personnel manager.
           A memory of the failure or success of past experiences contributes to an instinctive alertness concerning survival patterns. Alert to our survival needs, one instinctively remembers patterns for living that were successful in supporting our species’ physical and material well being, thereby alleviating others’ existential fears.
           While able to impact the material orientation of the community, one needs to avoid making promises that can’t be realized.`
  },
  '45': {
    name: 'Gathering Together',
    titleKeynote: 'The Gate of the Gatherer',
    keynote: 'The natural and generally beneficial attraction of like forces.',
    desc: `The archetypal leader, gathering resources and guiding others to manifest what is needed for survival and prosperity, without sacrificing one’s own interests. Sees education as the foundation for communal success; ensuring and monitoring the progress, health and prosperity of the community on multiple levels to lead it toward a safe future.
           Enjoys a position of authority, but best at delegating day-to-day affairs to others, such as the management of one’s estate, company, and so forth.`
  },
  '46': {
    name: 'Pushing Upward',
    titleKeynote: 'The Gate of the Determination of the Self',
    keynote: 'Good fortune that may be perceived as the result of serendipity but derives from effort and dedication.',
    desc: `Experiencing life through the physical body, and the physical quality of life itself, including love of the body and appreciation of the sensual nature of any experience. Honoring the body as a temple and trusting one’s natural responses synchronizes the right experiences at the right time.
           Inherent determination to let go of expectations and surrender to an experience offers the opportunity to fulfill one's potential, and brings valuable lessons and wisdom about life. The dedication to be fully absorbed and appreciate an experience for its own sake leads to serendipity, being in the right place at the right time.`
  },
  '47': {
    name: 'Oppression',
    titleKeynote: 'The Gate of Realization',
    keynote: 'A restrictive and adverse state as a result of internal weakness or external strength or both.',
    desc: `Consistently sorting through stored mental images to make sense of and find meaning in past experiences. This process works over time and involves different ways of perceiving and interpreting an event until a coherent picture emerges that brings clarity to what was experienced.
           Pressure to jump to premature conclusions. Trust in the gradual process leads to being able to share one’s realizations with others, and potentially help them resolve life issues.`
  },
  '48': {
    name: 'The Well',
    titleKeynote: 'The Gate of Depth',
    keynote: 'The necessary and qualitative foundation that is a prerequisite to establish the common good.',
    desc: `Deep rooted instinctual memory and an innate wisdom which can be used to solve problems., Bringing depth to whatever issues one focuses on, with the aim to offer workable solutions to improve conditions. Despite this depth, a tendency towards insecurity and feelings of inadequacy that can be overcome by waiting to be recognized and called out before sharing one’s insights.
           Likewise, one’s potential solutions can provide the foundation for others to perfect their skills`
  },
  '49': {
    name: 'Revolution',
    titleKeynote: 'The Gate of Principles',
    keynote: 'Ideally the transformation of forms based on the highest principles and not simply for power.',
    desc: `One actively engages in and supports the call for social change, feeling a need to correct the imbalanced or unjust distribution of resources such as food, shelter and other basic tribal needs.
           Interaction with others is determined by one’s principles. Rejects or accepts others depending on whether they agree with and are willing to offer support to uphold one’s principles. This is the central theme in all one’s relationships and strongly affects the power and influence one holds within the tribal environment.`
  },
  '5': {
    name: 'Waiting',
    titleKeynote: 'The Gate of Fixed Patterns',
    keynote: 'The fundamental attunement to natural rhythms. waiting as an active state of awareness.',
    desc: `A vital need for fixed rhythms and particular daily rituals–staying within one’s unique habits and patterns, such as when to schedule meal times, free time, exercise,and so forth, to ensure overall well-being.
           Others may consider these routines compulsive and try to change them, but any deviation from one’s own tempo and routines can be quite destabilizing and result in feeling disoriented, insecure and physically uncomfortable.`
  },
  '50': {
    name: 'The Cauldron',
    titleKeynote: 'The Gate of Values',
    keynote: 'The value of historical continuity whose traditional values serve and enrich the present and the future.',
    desc: `An instinctive awareness to protect values, uphold structures, and guard the laws guaranteeing the well being of the family/tribe. The ability to recognize outmoded laws or values, detect corruption compromising the community’s integrity, can lead one to take up responsibility and challenge those laws that no longer benefit the interests of the community, or larger society.
           The task to preserve values and structures can also manifest as a fear of responsibility, as well as a misdirected sense of taking on responsibility one can not handle.`
  },
  '51': {
    keynote: 'The ability to respond to disorder and shock through recognition and adaptation',
    name: 'The Arousing',
    titleKeynote: 'The Gate of Shock',
    desc: `A highly competitive energy, ranging from sheer foolhardiness to unusual courage. A warrior spirit, looking to move beyond ordinary circumstances and discover the truth and essence of life. Designed to withstand shock through the indomitable power of one’s will and brave heart, yet it is vital to follow strategy and authority to avoid getting lost in futile battles. Always a step ahead, embracing the challenges and risks in traversing uncharted territories. With each challenge one moves deeper into one’s own being – the essence of self.
           There is an unpredictability to one’s actions that is often shocking to others; likewise, one's own spirit, resilience and courage are tested by unexpected or shocking experiences. Recuperation is essential to protect the strength and health of one’s physical and spiritual heart.`
  },
  '52': {
    name: 'Keeping Still (Mountain)',
    titleKeynote: 'The Gate of Stillness',
    keynote: 'Temporary and self-imposed inaction for the benefit of assessment.',
    desc: `The ability to still the physical body and senses, and use the power to concentrate on what is worthwhile to dedicate energy toward. The need to balance a drive to move forward, fueled by the Root Center, with the ability to remain still and concentrate.
           Until there is something worth applying energy to, one can experience considerable tension as there is no physical outlet to channel the energy. This can lead to intense restlessness and frustration, and scattering of energy, requiring one to find the discipline to withdraw back into stillness.`
  },
  '53': {
    name: 'Development',
    titleKeynote: 'The Gate of Beginnings',
    keynote: 'Development as a structured progression that is both steadfast and enduring.',
    desc: `The energy and pressure to always start something new, whether a relationship, a work project, or a mission. This pressure is for development, and with it the potential for maturation through cyclic change.
           Prone to building up pressure, it is vital to begin new experiences so that pent-up energy doesn’t cause stress and depression. While primarily about the impetus to set new processes into motion, following one’s Authority is essential to avoid the frustration of getting stuck in projects that cannot be finished and inhibit development.`
  },
  '54': {
    name: 'The Marrying Maiden',
    titleKeynote: 'The Gate of Drive',
    keynote: 'Interaction in its mundane social context but also one\'s mystical and cosmic relationships.',
    desc: `A fundamental drive to advance in the world, and to be recognized for one's skills and talents. While this energy fuels the most mundane ambitions, it also serves spiritual ideals and aspirations as fulfilling one’s potential on the mundane plane leads to transformation. This drive to ‘rise up’ can also inspire others to transform.
           Dependent on recognition from those who can help one advance and succeed on the material plane. While mainly looking to channel ambition toward what has lasting value and consistency, if lacking direction, one may be blindly driven toward anything that promises success.`
  },
  '55': {
    name: 'Abundance',
    titleKeynote: 'The Gate of Spirit',
    keynote: 'Abundance is strictly a question of spirit.',
    desc: `The gate of spirit, and finding spirit awareness which is ultimately mutative and can deeply impact the world. The surrender to the momentary mood as one moves through the emotional wave from hope to pain without the need to find reasons; it is simply chemistry. The need to honor the mood as this determines what is correct in the moment, whether in regard to work, play, food, love, being sociable or alone. Through the experience of these emotional states, one can tap into a vast potential for creative energy and experience fullness of spirit, and passion for life.`
  },
  '56': {
    name: 'The Wanderer',
    titleKeynote: 'The Gate of Stimulation',
    keynote: 'Stability through movement. the perpetuation of continuity through the linking of short term activity.',
    desc: `The storyteller and philosopher, constantly on the lookout for stimulation and new ideas to be gathered and woven into stories. One’s perceptions and feelings about past experiences, not facts and proof, determine the particular quality of the stories, and are used to illustrate concepts. A talent to spin colorful tapestries with which to inspire and teach others.
           To have true impact, one needs to be invited to share one’s story.`
  },
  '57': {
    name: 'The Gentle',
    titleKeynote: 'The Gate of Intuitive Clarity',
    keynote: 'The extraordinary power of clarity.',
    desc: `The ability to penetrate to the core of any situation in the moment and find clarity.
           Instinctive awareness in the Now, acutely attuned to sounds and vibrations in the environment to protect one’s physical well being. Learning to pay attention to instinctual hunches to eliminate the fear of tomorrow.
           Selective hearing to resist being influenced, with a tendency to at times ignore essential information and guidance from within which can be detrimental to one’s health and safety. It is vital to stay focused and alert to access the information received through the Spleen, the center in charge of physical safety.`
  },
  '58': {
    name: 'The Joyous',
    titleKeynote: ' The Gate of Vitality',
    keynote: 'Stimulation is the key to joy.',
    desc: `A zest for life, deriving great joy and pleasure in being alive and in this world. An appreciation for the beauty and wonder of life - driven to challenge and correct any condition that keeps our society from experiencing well-being as we move into the future.
           When this energy is correctly focused and channeled, there is the fuel to ensure humanity’s safe progress, adjust imbalances, and make lasting contributions to the collective.`
  },
  '59': {
    name: 'After Dispersion',
    titleKeynote: 'The Gate of Sexuality',
    keynote: 'The ability to break down barriers to achieve union.',
    desc: `A gate of fertility, with a naturally intimate quality that breaks through auric barriers, looking for union. Intimacy is expressed through specific approaches to bonding, each described by the lines of this Gate.
           Understanding and honoring one’s genetic strategy and Inner Authority are essential in order to enter into intimate relationships and avoid superficial and dissatisfying relations.`
  },
  '6': {
    name: 'Conflict',
    titleKeynote: 'The Gate of Friction',
    keynote: 'The fundamental design component of progress. the law that growth cannot exist without friction.',
    desc: `One reflexively opens or closes when in another person’s aura, thereby controlling the emotional flow from the Solar Plexus, which determines whether there is the necessary friction for intimacy. Here we deal with moods, sensitivity, and feelings—emotional intimacy occurs only if there is resonance with others, naturally preserving a boundary between one’s body and the outside world.
           Fear of intimacy is also associated with this Gate, which can be eased or overcome when connecting to Gate 59 and its ability to break down barriers.`
  },
  '60': {
    name: 'Limitation',
    titleKeynote: 'The Gate of Acceptance',
    keynote: 'The acceptance of limitation is the first step in transcendence.',
    desc: `This gate carries the pressure for mutation that transcends old forms, values, and outdated order in an on/off pulse of energy. This allows it to build up with each potential for change and to be released when the time is right for mutation to occur. There is great pressure within to bring creative change into the world, and a need to accept that this happens on its own accord as this creative, mutative process is beyond one’s control. Experiencing a deep restlessness when faced with any kind of limitation or restraint can be part of this process - feeling the buildup to affect change without knowing when it will be released.
           This pulse takes place to guarantee that the mutation can be successful, and unless one learns to accept the inherent limitation can lead to considerable melancholy or even depression, feeling as if life is holding one back. Accepting the limitation and aligning with the pulse energy will enable one to be effective when change happens and to trust transformation takes place within during the quiet times.`
  },
  '61': {
    name: 'Inner Truth',
    titleKeynote: 'The Gate of Mystery',
    keynote: 'The awareness of universal underlying principles.',
    desc: `The pressure to know, to delve into the mysteries of life and the unknown. Sudden insights appear seemingly out of nowhere leading to profound inner knowing. The need to surrender to the fact that some things are unknowable and letting go of the pressure to try and resolve the mystery.
           This is not a rational thinking process but a mutative process, beyond the control of the conscious mind. Its purpose is to inspire others and potentially transform concepts about life.`
  },
  '62': {
    name: 'Preponderance of the Small',
    titleKeynote: 'The Gate of Details',
    keynote: 'Caution, patience and detail produce excellence out of limitation.',
    desc: `An ability to easily grasp patterns and a gift to patiently assemble, organize and express the details of any situation or structure in the form of logical answers and theories.
           Without the support from Gate 17 (Opinions) at the other end, there is difficulty putting theories into context, thereby overwhelming others with endless data and inessential details.`
  },
  '63': {
    name: 'After Completion',
    titleKeynote: 'The Gate of Doubt',
    keynote: 'In the spiral of life, all ends are beginnings.',
    desc: `A need to question things one does not feel secure about, from the mundane to life’s bigger questions. Needs logical answers that can be validated and relied upon in order to feel secure. Future focused, with an ability to ‘see’ patterns and question those that are inherently weak or inconsistent.
           Without logical answers, experiences doubt in the form of consistent pressure until there is an adequate solution, or looks for a new pattern.`
  },
  '64': {
    name: 'Before Completion',
    titleKeynote: 'The Gate of Confusion',
    keynote: 'The perfection of action through uncontrived and spontaneous nature.',
    desc: `Imprinted with a consistent flow of data and mental images generated through the memory of past experiences. Constant pressure to make sense of the past—there is no apparent order, and therefore confusion is a natural part of the process. The mind filters and sorts through these impressions in an attempt to connect the dots until an experience makes sense.
           It is important to let the process resolve in its own way and time, as any attempt to relieve the pressure may lead to increased anxiety and confusion.`
  },
  '7': {
    name: 'The Army',
    titleKeynote: 'The Role of the Self in Interaction',
    keynote: 'The point of convergence. by design, the need for leadership to guide and order society.',
    desc: `An orientation towards the future; the ability to see the direction humanity is taking, and being influential in pointing the collective towards a new way. Understanding the fundamental patterns of evolution, and seeing where and how to correct these. Influencing and persuading others to follow one’s leadership, and possibly influence those in positions of authority.
           This role is not necessarily public but is nevertheless influential, “the power behind the throne.”`
  },
  '8': {
    name: 'Holding Together',
    titleKeynote: 'The Gate of Contribution',
    keynote: 'The basic worth realized in contributing individual efforts to group goals.',
    desc: `The need to contribute something unique to the collective, thereby attracting attention and leading to the creation of an identity based on their contribution. This can manifest in various different creative ways, from promoting others who carry creative potential, (like people who are connected through Gate 1) demonstrating a truly individual lifestyle, or being involved in artistic projects.
           Drawn to what is new and innovative, one calls attention to and promotes that which carries new and mutative potential, thereby changing the direction of the collective.`
  },
  '9': {
    name: 'The Taming Power of the Small',
    titleKeynote: 'The Gate of Focus',
    keynote: 'Potential can be fulfilled through detailed attention to all pertinent aspects.',
    desc: `The determination to direct energy and focus on what is essential for one’s own process, and to persevere in putting energy towards all pertinent aspects of a given project. While sacral energy is available to focus, the challenge is to sustain extended periods of concentration that require stillness to succeed, supported through Gate 52.
           This sacral power to focus can also can assist those within one’s auric field to efficiently use energy, mentally and physically.`
  }
}
