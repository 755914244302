<template>
  <p class="text-sm mt-3 mb-0">
    © 2013-2024 Jovian Archive Media Pte. Ltd. All rights reserved.
    <br/>
    <a href="https://www.mybodygraph.com/terms-conditions" target="_blank" class="mx-0 px-0 text-sm px-1" style="font-size:1em">Terms & Conditions</a>
    |
    <a href="https://www.mybodygraph.com/cookie-policy" target="_blank" class="mx-0 px-0 text-sm px-1" style="font-size:1em">Cookie Policy</a>
  </p>
</template>
<script>
export default {

}
</script>