import * as R from 'ramda'
import { isEq } from '@/helpers/utils/misc'

const SMELL = 0
const TASTE = 1
const OUTER_VISION = 2
const INNER_VISION = 3
const FEELING = 4
const TOUCH = 5

const fromTone = R.cond([
  [isEq(1), R.always(SMELL)],
  [isEq(2), R.always(TASTE)],
  [isEq(3), R.always(OUTER_VISION)],
  [isEq(4), R.always(INNER_VISION)],
  [isEq(5), R.always(FEELING)],
  [isEq(6), R.always(TOUCH)]
])

export default { SMELL, TASTE, OUTER_VISION, INNER_VISION, FEELING, TOUCH, fromTone }
