
export default {

    methods: {
        facebookPageView() {
            if (window && window.fbq && typeof window.fbq === 'function') {
                window.fbq('init', '2258319874506819');
                window.fbq('track', 'PageView');
            }
        },

        facebookEvent(eventName, eventData) {
            if (window && window.fbq && typeof window.fbq === 'function') {
                window.fbq('init', '2258319874506819');
                if (eventData) {
                    window.fbq('track', eventName, eventData);
                } else {
                    window.fbq('track', eventName);
                }
            }
        }
    }
}