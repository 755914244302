<template>
  <div class="px-4">
    <inner-tabs-renderer :id="1"
                         type="work_success"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)">
      <template #unlock-prompt> <slot name="unlock-prompt"/> </template>
    </inner-tabs-renderer>
  </div>
</template>

<script>
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import {mapState} from "vuex";
import InnerTabsRenderer from "../../../renderer/InnerTabsRenderer.vue";
import DynamicZoneRenderer from "@/components/renderer/DynamicZoneRenderer.vue";
import WorkSuccessMixin from "@/mixins/WorkSuccessMixin";

export default{
  mixins: [AnalysisQueryMixin, WorkSuccessMixin],
  components: {InnerTabsRenderer},
  data(){
    return{
      tab: 0,
      intro: null,
      type: null,
    }
  },

  created(){
    this.getContent('intro', '/analysis/introductions/introduction.work-success-type')
    this.getType(this.chart, 'type');
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
    tabs(){
      return [
        {
          id: 0,
          title: this.$t('hd.properties.introduction'),
          shouldRender: !!this.intro,
          component: DynamicZoneRenderer,
          props: {
            components: this.intro ? this.intro.components : [],
            addPaddings: false,
          }
        },
        {
          id: 1,
          title: this.$t('charts.analysis.work_success.titles.11'),
          shouldRender: !!this.type,
          component: DynamicZoneRenderer,
          props: {
            components: this.type ? this.type.components : [],
            addPaddings: false,
          }
        },
      ]
    }
  },
}
</script>