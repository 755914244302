export default {

    data(){
        return {
            isMobile: false,
            isTablet: false,
            isDesktop: true,
            screenWidth: null
        }
    },

    mounted() {
        this.handleScreenSize();
        window.addEventListener("resize", this.handleScreenSize);
    },

    destroyed() {
        window.removeEventListener("resize", this.handleScreenSize);
    },

    methods: {
        handleScreenSize() {
            this.screenWidth = window.innerWidth;
            if(window.innerWidth < 540) {
                this.isMobile = true;
                this.isTablet = false;
                this.isDesktop = false;
            }else if(window.innerWidth < 1240){
                this.isMobile = false;
                this.isTablet = true;
                this.isDesktop = false;
            }else{
                this.isMobile = false;
                this.isTablet = false;
                this.isDesktop = true;
            }
        }
    }
}