<template>
  <div class="chart-autocomplete-item autocomplete-item row align-items-center px-3">
      <div class="col-2 col-xl-1 pl-0">
        <div class="icon-wrap d-flex align-items-center justify-content-center mx-0">
          <span :class="getIconClass"/>
        </div>
      </div>
      <div :class="getTextClasses">
        <div>
          <p class="mb-0 autocomplete-title">{{item.name}}</p>
          <p class="mbg-text-muted autocomplete-muted mb-0">{{createDesc(item)}}</p>
        </div>
      </div>

      <div v-if="hasDefaultSlot" class="ml-auto col-12 col-md-3 col-xl-3 pl-1">
        <slot/>
      </div>
  </div>
</template>

<style lang="scss">

.chart-autocomplete-item{

  &.selected-chart{

    @media(min-width:1240px){
      max-width:65%;
    }
    margin-top:1rem;
    border-radius: 15px 10px;
    padding:1rem 2rem!important;
  }
  .autocomplete-title{
    font-size:12px;
    font-weight:600!important;
  }
  .autocomplete-muted{
    font-size:11px;
    font-weight:300!important;
  }
  .icon-wrap{
    width:30px;
    height:30px;
    border-radius:100%;
    > span{
      line-height:1.8;
    }

  }
}
.theme--dark{
  .chart-autocomplete-item{
    .icon-wrap{
      background: #fff;
      color:#000;
    }
    &.selected-chart{
      background:$bg-dark;
    }
  }
}
.theme--light{

  .chart-autocomplete-item{

    &.selected-chart{
      background:#F1EFEF;
    }
    .icon-wrap{
      background: linear-gradient(0deg, #434343, #434343), linear-gradient(0deg, #2F2F2F, #2F2F2F), #191C23;
      color:#fff;
    }
  }
}
</style>

<script>
import docTypes from "../../../helpers/docTypes";
export default {
  props: {
    item: { required: true }
  },
  computed:{
    getIconClass(){
      if(this.item.docType === docTypes.RAVE_CHART) return 'icon-bodygraph'
      return 'icon-bodygraph';
    },
    hasDefaultSlot () {
      return !!this.$slots.default
    },
    getTextClasses(){
      if(this.hasDefaultSlot) return 'col-10 col-md-6 col-xl-8'

      return 'col-10 col-xl-11'
    },
  },
  methods:{
    createDesc (item) {
      if (item.docType === docTypes.RAVE_CHART) {
        const profile = this.$t(`hd.constants.profiles.${item.profile}`)
        const type = this.$t(`hd.constants.types.${item.type}`)

        return `${profile} ${type}`
      } else {
        return this.$t(`documentTypes.${item.docType}`)
      }
    },
  }
}
</script>