const map = {
  '1': {
    '1': {
      detriment: 10,
      exalted: 2
    },
    '2': {
      detriment: 7,
      exalted: 6
    },
    '3': {
      detriment: 1,
      exalted: 7
    },
    '4': {
      detriment: 8,
      exalted: 1
    },
    '5': {
      detriment: 10,
      exalted: 7
    },
    '6': {
      detriment: 12,
      exalted: 1
    }
  },
  '10': {
    '1': {
      detriment: 2,
      exalted: 0
    },
    '2': {
      detriment: 7,
      exalted: 5
    },
    '3': {
      detriment: 2,
      exalted: 1
    },
    '4': {
      detriment: 5,
      exalted: 10
    },
    '5': {
      detriment: 7,
      exalted: 8
    },
    '6': {
      detriment: 9,
      exalted: 12
    }
  },
  '11': {
    '1': {
      detriment: 7,
      exalted: 2
    },
    '2': {
      detriment: 7,
      exalted: 11
    },
    '3': {
      detriment: 6,
      exalted: 12
    },
    '4': {
      detriment: 0,
      exalted: 2
    },
    '5': {
      detriment: 5,
      exalted: 2
    },
    '6': {
      detriment: 8,
      exalted: 11
    }
  },
  '12': {
    '1': {
      detriment: 8,
      exalted: 6
    },
    '2': {
      detriment: 5,
      exalted: 9
    },
    '3': {
      detriment: 7,
      exalted: 11
    },
    '4': {
      detriment: 5,
      exalted: 1
    },
    '5': {
      detriment: 7,
      exalted: 0
    },
    '6': {
      detriment: 1,
      exalted: 0
    }
  },
  '13': {
    '1': {
      detriment: 2,
      exalted: 6
    },
    '2': {
      detriment: 0,
      exalted: 2
    },
    '3': {
      detriment: 6,
      exalted: 1
    },
    '4': {
      detriment: 6,
      exalted: 12
    },
    '5': {
      detriment: 8,
      exalted: 11
    },
    '6': {
      detriment: 5,
      exalted: 7
    }
  },
  '14': {
    '1': {
      detriment: 5,
      exalted: 8
    },
    '2': {
      detriment: 7,
      exalted: 8
    },
    '3': {
      detriment: 11,
      exalted: 1
    },
    '4': {
      detriment: 7,
      exalted: 2
    },
    '5': {
      detriment: 6,
      exalted: 0
    },
    '6': {
      detriment: 1,
      exalted: 0
    }
  },
  '15': {
    '1': {
      detriment: 7,
      exalted: 6
    },
    '2': {
      detriment: 1,
      exalted: 0
    },
    '3': {
      detriment: 5,
      exalted: 1
    },
    '4': {
      detriment: 9,
      exalted: 8
    },
    '5': {
      detriment: 12,
      exalted: 8
    },
    '6': {
      detriment: 6,
      exalted: 12
    }
  },
  '16': {
    '1': {
      detriment: 5,
      exalted: 1
    },
    '2': {
      detriment: 5,
      exalted: 0
    },
    '3': {
      detriment: 7,
      exalted: 2
    },
    '4': {
      detriment: 7,
      exalted: 8
    },
    '5': {
      detriment: 2,
      exalted: 12
    },
    '6': {
      detriment: 8,
      exalted: 11
    }
  },
  '17': {
    '1': {
      detriment: 6,
      exalted: 7
    },
    '2': {
      detriment: 2,
      exalted: 0
    },
    '3': {
      detriment: 1,
      exalted: 12
    },
    '4': {
      detriment: 8,
      exalted: 12
    },
    '5': {
      detriment: 7,
      exalted: 10
    },
    '6': {
      detriment: 8,
      exalted: 2
    }
  },
  '18': {
    '1': {
      detriment: 8,
      exalted: 1
    },
    '2': {
      detriment: 2,
      exalted: 12
    },
    '3': {
      detriment: 8,
      exalted: 11
    },
    '4': {
      detriment: 5,
      exalted: 1
    },
    '5': {
      detriment: 10,
      exalted: 9
    },
    '6': {
      detriment: 2,
      exalted: 7
    }
  },
  '19': {
    '1': {
      detriment: 2,
      exalted: 0
    },
    '2': {
      detriment: 5,
      exalted: 8
    },
    '3': {
      detriment: 2,
      exalted: 6
    },
    '4': {
      detriment: 6,
      exalted: 7
    },
    '5': {
      detriment: 8,
      exalted: 1
    },
    '6': {
      detriment: 7,
      exalted: 8
    }
  },
  '2': {
    '1': {
      detriment: 7,
      exalted: 6
    },
    '2': {
      detriment: 7,
      exalted: 9
    },
    '3': {
      detriment: 10,
      exalted: 8
    },
    '4': {
      detriment: 7,
      exalted: 6
    },
    '5': {
      detriment: 1,
      exalted: 5
    },
    '6': {
      detriment: 9,
      exalted: 5
    }
  },
  '20': {
    '1': {
      detriment: 2,
      exalted: 6
    },
    '2': {
      detriment: 2,
      exalted: 6
    },
    '3': {
      detriment: 1,
      exalted: 0
    },
    '4': {
      detriment: 5,
      exalted: 8
    },
    '5': {
      detriment: 10,
      exalted: 9
    },
    '6': {
      detriment: 5,
      exalted: 6
    }
  },
  '21': {
    '1': {
      detriment: 2,
      exalted: 7
    },
    '2': {
      detriment: 11,
      exalted: 7
    },
    '3': {
      detriment: 8,
      exalted: 11
    },
    '4': {
      detriment: 1,
      exalted: 8
    },
    '5': {
      detriment: 12,
      exalted: 8
    },
    '6': {
      detriment: 6,
      exalted: 12
    }
  },
  '22': {
    '1': {
      detriment: 7,
      exalted: 2
    },
    '2': {
      detriment: 8,
      exalted: 0
    },
    '3': {
      detriment: 7,
      exalted: 9
    },
    '4': {
      detriment: 7,
      exalted: 11
    },
    '5': {
      detriment: 7,
      exalted: 8
    },
    '6': {
      detriment: 7,
      exalted: 0
    }
  },
  '23': {
    '1': {
      detriment: 7,
      exalted: 8
    },
    '2': {
      detriment: 2,
      exalted: 8
    },
    '3': {
      detriment: 12,
      exalted: 0
    },
    '4': {
      detriment: 1,
      exalted: 0
    },
    '5': {
      detriment: 2,
      exalted: 8
    },
    '6': {
      detriment: 8,
      exalted: 7
    }
  },
  '24': {
    '1': {
      detriment: 11,
      exalted: 0
    },
    '2': {
      detriment: 7,
      exalted: 2
    },
    '3': {
      detriment: 8,
      exalted: 6
    },
    '4': {
      detriment: 11,
      exalted: 9
    },
    '5': {
      detriment: 7,
      exalted: 2
    },
    '6': {
      detriment: 12,
      exalted: 8
    }
  },
  '25': {
    '1': {
      detriment: 5,
      exalted: 11
    },
    '2': {
      detriment: 7,
      exalted: 5
    },
    '3': {
      detriment: 12,
      exalted: 7
    },
    '4': {
      detriment: 666,
      exalted: 6
    },
    '5': {
      detriment: 8,
      exalted: 6
    },
    '6': {
      detriment: 10,
      exalted: 1
    }
  },
  '26': {
    '1': {
      detriment: 7,
      exalted: 11
    },
    '2': {
      detriment: 12,
      exalted: 0
    },
    '3': {
      detriment: 9,
      exalted: 0
    },
    '4': {
      detriment: 9,
      exalted: 12
    },
    '5': {
      detriment: 6,
      exalted: 7
    },
    '6': {
      detriment: 2,
      exalted: 0
    }
  },
  '27': {
    '1': {
      detriment: 1,
      exalted: 0
    },
    '2': {
      detriment: 7,
      exalted: 2
    },
    '3': {
      detriment: 7,
      exalted: 12
    },
    '4': {
      detriment: 7,
      exalted: 8
    },
    '5': {
      detriment: 9,
      exalted: 8
    },
    '6': {
      detriment: 12,
      exalted: 2
    }
  },
  '28': {
    '1': {
      detriment: 6,
      exalted: 7
    },
    '2': {
      detriment: 8,
      exalted: 0
    },
    '3': {
      detriment: 8,
      exalted: 9
    },
    '4': {
      detriment: 5,
      exalted: 8
    },
    '5': {
      detriment: 0,
      exalted: 12
    },
    '6': {
      detriment: 11,
      exalted: 12
    }
  },
  '29': {
    '1': {
      detriment: 11,
      exalted: 7
    },
    '2': {
      detriment: 6,
      exalted: 0
    },
    '3': {
      detriment: 8,
      exalted: 7
    },
    '4': {
      detriment: 6,
      exalted: 9
    },
    '5': {
      detriment: 1,
      exalted: 0
    },
    '6': {
      detriment: 8,
      exalted: 7
    }
  },
  '3': {
    '1': {
      detriment: 5,
      exalted: 1
    },
    '2': {
      detriment: 10,
      exalted: 7
    },
    '3': {
      detriment: 12,
      exalted: 6
    },
    '4': {
      detriment: 7,
      exalted: 11
    },
    '5': {
      detriment: 1,
      exalted: 7
    },
    '6': {
      detriment: 12,
      exalted: 0
    }
  },
  '30': {
    '1': {
      detriment: 8,
      exalted: 0
    },
    '2': {
      detriment: 7,
      exalted: 0
    },
    '3': {
      detriment: 8,
      exalted: 12
    },
    '4': {
      detriment: 8,
      exalted: 12
    },
    '5': {
      detriment: 12,
      exalted: 8
    },
    '6': {
      detriment: 2,
      exalted: 7
    }
  },
  '31': {
    '1': {
      detriment: 1,
      exalted: 0
    },
    '2': {
      detriment: 5,
      exalted: 8
    },
    '3': {
      detriment: 8,
      exalted: 0
    },
    '4': {
      detriment: 7,
      exalted: 2
    },
    '5': {
      detriment: 2,
      exalted: 12
    },
    '6': {
      detriment: 2,
      exalted: 0
    }
  },
  '32': {
    '1': {
      detriment: 7,
      exalted: 0
    },
    '2': {
      detriment: 8,
      exalted: 6
    },
    '3': {
      detriment: 8,
      exalted: 5
    },
    '4': {
      detriment: 9,
      exalted: 8
    },
    '5': {
      detriment: 7,
      exalted: 2
    },
    '6': {
      detriment: 11,
      exalted: 12
    }
  },
  '33': {
    '1': {
      detriment: 7,
      exalted: 0
    },
    '2': {
      detriment: 11,
      exalted: 8
    },
    '3': {
      detriment: 7,
      exalted: 8
    },
    '4': {
      detriment: 11,
      exalted: 12
    },
    '5': {
      detriment: 8,
      exalted: 12
    },
    '6': {
      detriment: 8,
      exalted: 0
    }
  },
  '34': {
    '1': {
      detriment: 12,
      exalted: 9
    },
    '2': {
      detriment: 6,
      exalted: 7
    },
    '3': {
      detriment: 5,
      exalted: 9
    },
    '4': {
      detriment: 7,
      exalted: 12
    },
    '5': {
      detriment: 2,
      exalted: 7
    },
    '6': {
      detriment: 8,
      exalted: 1
    }
  },
  '35': {
    '1': {
      detriment: 11,
      exalted: 6
    },
    '2': {
      detriment: 2,
      exalted: 6
    },
    '3': {
      detriment: 0,
      exalted: 8
    },
    '4': {
      detriment: 7,
      exalted: 2
    },
    '5': {
      detriment: 8,
      exalted: 5
    },
    '6': {
      detriment: 7,
      exalted: 9
    }
  },
  '36': {
    '1': {
      detriment: 8,
      exalted: 7
    },
    '2': {
      detriment: 2,
      exalted: 11
    },
    '3': {
      detriment: 8,
      exalted: 12
    },
    '4': {
      detriment: 2,
      exalted: 12
    },
    '5': {
      detriment: 5,
      exalted: 12
    },
    '6': {
      detriment: 9,
      exalted: 8
    }
  },
  '37': {
    '1': {
      detriment: 666,
      exalted: 6
    },
    '2': {
      detriment: 5,
      exalted: 8
    },
    '3': {
      detriment: 7,
      exalted: 8
    },
    '4': {
      detriment: 9,
      exalted: 2
    },
    '5': {
      detriment: 7,
      exalted: 6
    },
    '6': {
      detriment: 5,
      exalted: 6
    }
  },
  '38': {
    '1': {
      detriment: 7,
      exalted: 11
    },
    '2': {
      detriment: 2,
      exalted: 12
    },
    '3': {
      detriment: 1,
      exalted: 0
    },
    '4': {
      detriment: 7,
      exalted: 12
    },
    '5': {
      detriment: 12,
      exalted: 9
    },
    '6': {
      detriment: 1,
      exalted: 9
    }
  },
  '39': {
    '1': {
      detriment: 5,
      exalted: 7
    },
    '2': {
      detriment: 8,
      exalted: 2
    },
    '3': {
      detriment: 1,
      exalted: 8
    },
    '4': {
      detriment: 0,
      exalted: 2
    },
    '5': {
      detriment: 7,
      exalted: 11
    },
    '6': {
      detriment: 7,
      exalted: 2
    }
  },
  '4': {
    '1': {
      detriment: 1,
      exalted: 2
    },
    '2': {
      detriment: 7,
      exalted: 2
    },
    '3': {
      detriment: 12,
      exalted: 6
    },
    '4': {
      detriment: 9,
      exalted: 0
    },
    '5': {
      detriment: 12,
      exalted: 8
    },
    '6': {
      detriment: 7,
      exalted: 5
    }
  },
  '40': {
    '1': {
      detriment: 2,
      exalted: 0
    },
    '2': {
      detriment: 2,
      exalted: 0
    },
    '3': {
      detriment: 7,
      exalted: 12
    },
    '4': {
      detriment: 7,
      exalted: 10
    },
    '5': {
      detriment: 1,
      exalted: 10
    },
    '6': {
      detriment: 1,
      exalted: 0
    }
  },
  '41': {
    '1': {
      detriment: 5,
      exalted: 11
    },
    '2': {
      detriment: 7,
      exalted: 9
    },
    '3': {
      detriment: 2,
      exalted: 9
    },
    '4': {
      detriment: 6,
      exalted: 1
    },
    '5': {
      detriment: 6,
      exalted: 7
    },
    '6': {
      detriment: 12,
      exalted: 9
    }
  },
  '42': {
    '1': {
      detriment: 6,
      exalted: 0
    },
    '2': {
      detriment: 6,
      exalted: 0
    },
    '3': {
      detriment: 2,
      exalted: 7
    },
    '4': {
      detriment: 6,
      exalted: 2
    },
    '5': {
      detriment: 6,
      exalted: 0
    },
    '6': {
      detriment: 9,
      exalted: 2
    }
  },
  '43': {
    '1': {
      detriment: 6,
      exalted: 12
    },
    '2': {
      detriment: 2,
      exalted: 12
    },
    '3': {
      detriment: 2,
      exalted: 12
    },
    '4': {
      detriment: 8,
      exalted: 5
    },
    '5': {
      detriment: 6,
      exalted: 2
    },
    '6': {
      detriment: 7,
      exalted: 0
    }
  },
  '44': {
    '1': {
      detriment: 6,
      exalted: 12
    },
    '2': {
      detriment: 7,
      exalted: 8
    },
    '3': {
      detriment: 11,
      exalted: 7
    },
    '4': {
      detriment: 0,
      exalted: 12
    },
    '5': {
      detriment: 7,
      exalted: 10
    },
    '6': {
      detriment: 1,
      exalted: 12
    }
  },
  '45': {
    '1': {
      detriment: 7,
      exalted: 8
    },
    '2': {
      detriment: 7,
      exalted: 10
    },
    '3': {
      detriment: 7,
      exalted: 11
    },
    '4': {
      detriment: 7,
      exalted: 8
    },
    '5': {
      detriment: 8,
      exalted: 10
    },
    '6': {
      detriment: 8,
      exalted: 10
    }
  },
  '46': {
    '1': {
      detriment: 8,
      exalted: 11
    },
    '2': {
      detriment: 7,
      exalted: 0
    },
    '3': {
      detriment: 7,
      exalted: 2
    },
    '4': {
      detriment: 12,
      exalted: 1
    },
    '5': {
      detriment: 11,
      exalted: 2
    },
    '6': {
      detriment: 11,
      exalted: 9
    }
  },
  '47': {
    '1': {
      detriment: 11,
      exalted: 9
    },
    '2': {
      detriment: 5,
      exalted: 9
    },
    '3': {
      detriment: 7,
      exalted: 8
    },
    '4': {
      detriment: 2,
      exalted: 9
    },
    '5': {
      detriment: 666,
      exalted: 6
    },
    '6': {
      detriment: 0,
      exalted: 666
    }
  },
  '48': {
    '1': {
      detriment: 7,
      exalted: 2
    },
    '2': {
      detriment: 6,
      exalted: 12
    },
    '3': {
      detriment: 5,
      exalted: 2
    },
    '4': {
      detriment: 1,
      exalted: 0
    },
    '5': {
      detriment: 2,
      exalted: 7
    },
    '6': {
      detriment: 2,
      exalted: 6
    }
  },
  '49': {
    '1': {
      detriment: 0,
      exalted: 8
    },
    '2': {
      detriment: 12,
      exalted: 1
    },
    '3': {
      detriment: 12,
      exalted: 11
    },
    '4': {
      detriment: 7,
      exalted: 8
    },
    '5': {
      detriment: 7,
      exalted: 2
    },
    '6': {
      detriment: 9,
      exalted: 11
    }
  },
  '5': {
    '1': {
      detriment: 1,
      exalted: 7
    },
    '2': {
      detriment: 12,
      exalted: 6
    },
    '3': {
      detriment: 2,
      exalted: 11
    },
    '4': {
      detriment: 0,
      exalted: 10
    },
    '5': {
      detriment: 12,
      exalted: 6
    },
    '6': {
      detriment: 666,
      exalted: 11
    }
  },
  '50': {
    '1': {
      detriment: 6,
      exalted: 7
    },
    '2': {
      detriment: 6,
      exalted: 0
    },
    '3': {
      detriment: 5,
      exalted: 2
    },
    '4': {
      detriment: 7,
      exalted: 9
    },
    '5': {
      detriment: 7,
      exalted: 9
    },
    '6': {
      detriment: 2,
      exalted: 6
    }
  },
  '51': {
    '1': {
      detriment: 6,
      exalted: 12
    },
    '2': {
      detriment: 5,
      exalted: 7
    },
    '3': {
      detriment: 8,
      exalted: 0
    },
    '4': {
      detriment: 5,
      exalted: 10
    },
    '5': {
      detriment: 7,
      exalted: 0
    },
    '6': {
      detriment: 12,
      exalted: 0
    }
  },
  '52': {
    '1': {
      detriment: 7,
      exalted: 1
    },
    '2': {
      detriment: 7,
      exalted: 6
    },
    '3': {
      detriment: 6,
      exalted: 9
    },
    '4': {
      detriment: 8,
      exalted: 9
    },
    '5': {
      detriment: 12,
      exalted: 1
    },
    '6': {
      detriment: 11,
      exalted: 6
    }
  },
  '53': {
    '1': {
      detriment: 6,
      exalted: 11
    },
    '2': {
      detriment: 7,
      exalted: 2
    },
    '3': {
      detriment: 7,
      exalted: 2
    },
    '4': {
      detriment: 6,
      exalted: 2
    },
    '5': {
      detriment: 1,
      exalted: 11
    },
    '6': {
      detriment: 12,
      exalted: 2
    }
  },
  '54': {
    '1': {
      detriment: 6,
      exalted: 12
    },
    '2': {
      detriment: 7,
      exalted: 9
    },
    '3': {
      detriment: 6,
      exalted: 12
    },
    '4': {
      detriment: 666,
      exalted: 666
    },
    '5': {
      detriment: 666,
      exalted: 0
    },
    '6': {
      detriment: 8,
      exalted: 9
    }
  },
  '55': {
    '1': {
      detriment: 6,
      exalted: 8
    },
    '2': {
      detriment: 1,
      exalted: 6
    },
    '3': {
      detriment: 7,
      exalted: 9
    },
    '4': {
      detriment: 7,
      exalted: 8
    },
    '5': {
      detriment: 0,
      exalted: 10
    },
    '6': {
      detriment: 2,
      exalted: 9
    }
  },
  '56': {
    '1': {
      detriment: 7,
      exalted: 2
    },
    '2': {
      detriment: 2,
      exalted: 10
    },
    '3': {
      detriment: 6,
      exalted: 0
    },
    '4': {
      detriment: 5,
      exalted: 2
    },
    '5': {
      detriment: 7,
      exalted: 10
    },
    '6': {
      detriment: 12,
      exalted: 0
    }
  },
  '57': {
    '1': {
      detriment: 2,
      exalted: 6
    },
    '2': {
      detriment: 2,
      exalted: 6
    },
    '3': {
      detriment: 666,
      exalted: 5
    },
    '4': {
      detriment: 7,
      exalted: 6
    },
    '5': {
      detriment: 2,
      exalted: 12
    },
    '6': {
      detriment: 7,
      exalted: 10
    }
  },
  '58': {
    '1': {
      detriment: 2,
      exalted: 6
    },
    '2': {
      detriment: 10,
      exalted: 666
    },
    '3': {
      detriment: 7,
      exalted: 10
    },
    '4': {
      detriment: 11,
      exalted: 12
    },
    '5': {
      detriment: 0,
      exalted: 2
    },
    '6': {
      detriment: 5,
      exalted: 2
    }
  },
  '59': {
    '1': {
      detriment: 5,
      exalted: 0
    },
    '2': {
      detriment: 12,
      exalted: 10
    },
    '3': {
      detriment: 7,
      exalted: 9
    },
    '4': {
      detriment: 5,
      exalted: 6
    },
    '5': {
      detriment: 10,
      exalted: 0
    },
    '6': {
      detriment: 5,
      exalted: 6
    }
  },
  '6': {
    '1': {
      detriment: 5,
      exalted: 12
    },
    '2': {
      detriment: 7,
      exalted: 6
    },
    '3': {
      detriment: 12,
      exalted: 11
    },
    '4': {
      detriment: 12,
      exalted: 0
    },
    '5': {
      detriment: 2,
      exalted: 6
    },
    '6': {
      detriment: 6,
      exalted: 5
    }
  },
  '60': {
    '1': {
      detriment: 5,
      exalted: 6
    },
    '2': {
      detriment: 1,
      exalted: 9
    },
    '3': {
      detriment: 7,
      exalted: 9
    },
    '4': {
      detriment: 6,
      exalted: 5
    },
    '5': {
      detriment: 8,
      exalted: 11
    },
    '6': {
      detriment: 5,
      exalted: 10
    }
  },
  '61': {
    '1': {
      detriment: 6,
      exalted: 11
    },
    '2': {
      detriment: 7,
      exalted: 2
    },
    '3': {
      detriment: 7,
      exalted: 2
    },
    '4': {
      detriment: 8,
      exalted: 9
    },
    '5': {
      detriment: 7,
      exalted: 9
    },
    '6': {
      detriment: 7,
      exalted: 12
    }
  },
  '62': {
    '1': {
      detriment: 7,
      exalted: 11
    },
    '2': {
      detriment: 5,
      exalted: 9
    },
    '3': {
      detriment: 6,
      exalted: 10
    },
    '4': {
      detriment: 12,
      exalted: 6
    },
    '5': {
      detriment: 11,
      exalted: 2
    },
    '6': {
      detriment: 5,
      exalted: 9
    }
  },
  '63': {
    '1': {
      detriment: 7,
      exalted: 0
    },
    '2': {
      detriment: 10,
      exalted: 8
    },
    '3': {
      detriment: 9,
      exalted: 8
    },
    '4': {
      detriment: 7,
      exalted: 5
    },
    '5': {
      detriment: 7,
      exalted: 0
    },
    '6': {
      detriment: 12,
      exalted: 8
    }
  },
  '64': {
    '1': {
      detriment: 7,
      exalted: 6
    },
    '2': {
      detriment: 2,
      exalted: 6
    },
    '3': {
      detriment: 2,
      exalted: 9
    },
    '4': {
      detriment: 7,
      exalted: 2
    },
    '5': {
      detriment: 8,
      exalted: 6
    },
    '6': {
      detriment: 6,
      exalted: 5
    }
  },
  '7': {
    '1': {
      detriment: 5,
      exalted: 6
    },
    '2': {
      detriment: 5,
      exalted: 11
    },
    '3': {
      detriment: 5,
      exalted: 2
    },
    '4': {
      detriment: 10,
      exalted: 0
    },
    '5': {
      detriment: 11,
      exalted: 6
    },
    '6': {
      detriment: 10,
      exalted: 5
    }
  },
  '8': {
    '1': {
      detriment: 5,
      exalted: 11
    },
    '2': {
      detriment: 1,
      exalted: 0
    },
    '3': {
      detriment: 9,
      exalted: 2
    },
    '4': {
      detriment: 5,
      exalted: 8
    },
    '5': {
      detriment: 0,
      exalted: 8
    },
    '6': {
      detriment: 12,
      exalted: 6
    }
  },
  '9': {
    '1': {
      detriment: 7,
      exalted: 12
    },
    '2': {
      detriment: 8,
      exalted: 12
    },
    '3': {
      detriment: 0,
      exalted: 1
    },
    '4': {
      detriment: 7,
      exalted: 2
    },
    '5': {
      detriment: 1,
      exalted: 8
    },
    '6': {
      detriment: 12,
      exalted: 2
    }
  }
}

const NONE = 0
const DETRIMENT = 1
const EXALTED = 2
const JUXTAPOSED = 3

const getFixingPlanets = (gate, line) => map[gate][line]

export default { getFixingPlanets, NONE, DETRIMENT, EXALTED, JUXTAPOSED }
