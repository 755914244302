import view from '@/helpers/rave/constants/views'

export default {
  [view.SURVIVAL]: 'Survival',
  [view.POSSIBILITY]: 'Possibility',
  [view.POWER]: 'Power',
  [view.WANTING]: 'Wanting',
  [view.PROBABILITY]: 'Probability',
  [view.PERSONAL]: 'Personal'
}
