<template>
  <div id="planetary-activations">
    <b-card>
      <div class="d-flex align-items-center py-2" style="overflow-x:auto">
        <div v-for="(tab,index) in getTabOptions" :key="`tabs-${index}`"
             :class="`${activeType === tab.type && activeChartId === tab.chartId ? 'current': ''}`"
             class="planetary-tab d-flex align-items-center mr-3 cursor-pointer flex-shrink-0"
             @click="selectTab(tab.type, tab.chartId)">
          <div :class="tab.class" class="small-circle mr-2 "></div>
          {{ $t(tab.title) }} <span v-if="tab.person" class="ml-1 font-weight-light">({{ tab.person }})</span>
        </div>


      </div>
      <div class="d-flex align-items-center">
        <b-button v-if="showSinglePlanet" class="m-0 mr-3 " size="xs" variant="primary" @click="goBack">
          <span class="icon-arrow-left"></span>
        </b-button>

        <mbg-text-input v-model="search" :show-label="false"
                        :title="$t('header.menu.search') + ' ' + $t('hd.properties.planetActivations')"
                        class="mb-0 w-100" icon="icon-search"/>

      </div>
      <!--  Renders gate itself  -->

      <div v-if="showSinglePlanet && !searching">
        <Planet
            :activation="activeType" :get-icon="getIconName" :next="getNextPlanet"
            :planet="selectedPlanet"
            :prev="getPrevPlanet"
            @changePlanet="planet => selectPlanet(planet.id, planet.activation, planet.chartId)"></Planet>

        <unlock-content-prompt v-if="!isPropertiesUnlocked" :chart-id="chart.id" :category="getUnlockCategory"/>
      </div>

      <!--   Renders list of planets! -->
      <div v-else>
        <div v-for="planet in getPlanets" :key="planet.chartId + '-' + planet.id"
             class="highlight-trigger"
             @click="selectPlanet(planet.id, planet.activation, planet.chartId)"
             @mouseleave="clearAllHighlights"
             @mouseover="planetHighlight(planet.id, shouldRelyOnActivation ? planet.activation : planet.chartId, [planet.gate, null], planet.chartId)"
        >
          <b-card class="my-2 cursor-pointer border-primary">
            <div class="d-flex align-items-center">
              <div>
                <div :class="`${getActiveClass}
                   ${getPlanets.length > 13 ? (planet.chartId === 0 ? 'bg-connection-left' : 'bg-connection-right') : ''}`"
                     class="num-box float-left text-center cursor-pointer">
                  <span :class="`icon-${getIconName(planet.name)}`"></span>
                </div>
              </div>
              <div>
                <label class="font-weight-light">{{ planet.keynote }}</label>
                <h6>
                  {{ activeType === 0 ? $t('hd.properties.design') : $t('hd.properties.personality') }}
                  {{ planet.name }}

                  <span class="font-weight-light">({{ planet.gate }}.{{ planet.line }})</span>

                  <span v-if="planet.person" class="font-weight-light"> ({{ planet.person }})</span>
                </h6>
              </div>
              <div class="ml-auto">
                <svg fill="none" height="14" viewBox="0 0 8 14" width="8" xmlns="http://www.w3.org/2000/svg">
                  <path :stroke="darkMode ? '#fff' : '#191c23'" d="M1 13L7 7L0.999999 1" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="1.5"/>
                </svg>
              </div>
            </div>

          </b-card>

        </div>


      </div>
    </b-card>

  </div>
</template>

<script>

import {mapState} from "vuex";
import MbgTextInput from "../../elements/MbgTextInput";
import Planet from "./singles/Planet";
import util from "../../../mixins/util";
import HighlightingMixin from "../../../mixins/HighlightingMixin";
import ChartUtil from "../../../mixins/ChartUtil";
import UnlockContentPrompt from "../../billing/UnlockContentPrompt.vue";

export default {

  components: {UnlockContentPrompt, Planet, MbgTextInput},
  mixins: [util, HighlightingMixin, ChartUtil],
  computed: {
    ...mapState({
      chart: state => state.charts.chart
    }),

    shouldRelyOnActivation() {
      return this.isPersonalChart(this.chart) || this.isTransitOnlyChart(this.chart);
    },

    getTabOptions() {
      const isPersonTransit = this.isPersonTransitChart(this.chart);
      const isPlainTransit = this.isTransitOnlyChart(this.chart);

      const tabs = [
        {
          title: 'hd.properties.design',
          class: 'bg-design',
          type: 0,
          chartId: 0,
          person: this.getPersonName(0, this.chart)
        },
        {
          title: isPlainTransit ? 'hd.properties.transit.title' : 'hd.properties.personality',
          class: isPlainTransit ? 'bg-transit' : 'bg-personality',
          type: 1,
          chartId: 0,
          person: this.getPersonName(0, this.chart)
        },
        {
          title: 'hd.properties.design',
          class: 'bg-design',
          type: 0,
          chartId: 1,
          person: this.getPersonName(1, this.chart)
        },
        {
          title: isPersonTransit ? 'hd.properties.transit.title' : 'hd.properties.personality',
          class: isPersonTransit ? 'bg-transit' : 'bg-personality',
          type: 1,
          chartId: 1,
          person: this.getPersonName(1, this.chart)
        },
      ]


      // show only tabs that have at least 1 planet matching.
      return tabs.filter(x => this.planets.filter(planet => planet.activation === x.type && planet.chartId === x.chartId).length > 0);
    },

    showSinglePlanet() {
      return (this.selectedPlanet && this.selectedPlanet.id !== null) && !this.searching;
    },

    getActiveClass() {
      return this.getTabOptions.find(x => x.type === this.activeType && x.chartId === this.activeChartId).class;
    },

    getPlanets() {

      let filteredPlanets = this.planets.filter(x => x.activation === this.activeType && x.chartId === this.activeChartId);

      if (this.search) {
        let search = this.search.toLowerCase();
        return filteredPlanets.filter(x => {
          let gateLine = x.gate + '.' + x.line;
          return (x.id.toString().toLowerCase().includes(search) ||
              x.name.toString().toLowerCase().includes(search) ||
              x.keynote.toString().toLowerCase().includes(search)
              || x.person?.toString().toLowerCase().includes(search)
              || gateLine.includes(search)
          )
        })
      }
      return filteredPlanets;
    },

    getPrevPlanet() {
      return this.planets.filter(x => x.activation === this.activeType && x.chartId === this.activeChartId)[this.planets.findIndex(x => x.id === this.selectedPlanet.id) - 1];
    },

    getNextPlanet() {
      return this.planets.filter(x => x.activation === this.activeType && x.chartId === this.activeChartId)[this.planets.findIndex(x => x.id === this.selectedPlanet.id) + 1];
    }
  },
  watch: {
    search(val) {
      if (val && val !== '') this.searching = true;
      else this.searching = false;
    },
    activeType(val) {
      this.urlQuery.set('activation', val);
      this.fixUrlQuery('?' + this.urlQuery.toString());
      if (this.selectedPlanet) this.setActivePlanet(this.getPlanet(this.selectedPlanet.id, this.activeType, this.activeChartId))
    },
    activeChartId(val) {
      this.urlQuery.set('chart', val);
      this.fixUrlQuery('?' + this.urlQuery.toString());
      if (this.selectedPlanet) this.setActivePlanet(this.getPlanet(this.selectedPlanet.id, this.activeType, this.activeChartId))
    },
    selectedPlanet(val) {
      if (val != null) {
        this.urlQuery.set('selected', val.id);
        if (val.chartId >= 0) this.urlQuery.set('chart', val.chartId)
      } else {
        this.urlQuery.delete('selected');
      }
      this.fixUrlQuery('?' + this.urlQuery.toString());
    }
  },


  data() {
    return {
      selectedPlanet: null,
      search: null,
      searching: false,
      planets: [],
      urlQuery: null,
      activeType: 0, // 0 = design, 1 = personality
      activeChartId: 0, // 0 = left side (or full chart if no composite), 1 = right side (or nothing if no composite)
    }
  },

  created() {
    this.urlQuery = this.getQuery();
    this.fixActivePlanet();
    this.setupPlanetContent();

  },

  methods: {

    selectTab(type, chartId) {
      this.activeType = type;
      this.activeChartId = chartId;
    },

    setupPlanetContent() {
      let planets = [];
      for (let planet of this.chart.chart.planets) {

        planets.push({
          activation: planet.activation,
          id: planet.id,
          name: this.$t('hd.planets.' + planet.id + '.name'),
          keynote: this.$t('hd.planets.' + planet.id + '.keynote'),
          gate: planet.gate,
          line: planet.line,
          person: this.getPersonName(planet.chartId, this.chart),
          chartId: planet.chartId
        })
      }
      this.planets = planets;
    },

    fixActivePlanet() {
      let selectedPlanetId = parseInt(this.urlQuery.get('selected'));
      let selectedChartId = parseInt(this.urlQuery.get('chart')) || 0;
      this.activeType = parseInt(this.urlQuery.get('activation')) || this.isTransitOnlyChart(this.chart) ? 1 : 0;
      this.activeChartId = selectedChartId;

      if (!isNaN(selectedPlanetId) && selectedPlanetId >= 0) {
        this.setActivePlanet(this.getPlanet(selectedPlanetId, this.activeType, this.activeChartId));
      }
    },

    setActivePlanet(planet, chartId) {
      this.selectedPlanet = {
        ...planet,
        person: this.getPersonName(chartId || this.activeChartId, this.chart)
      };
    },
    getPlanet(id, type, chartId) {
      if (chartId !== undefined) {
        return this.chart.chart.planets.find(x => x.id === id && x.chartId === chartId && x.activation === type)
      }
      return this.chart.chart.planets.find(x => x.id === id && x.activation === this.activeType);
    },
    selectPlanet(id, type, chartId) {
      this.searching = false;
      this.setActivePlanet(this.getPlanet(id, type, chartId), chartId);
      this.clearAllHighlights()
    },
    goBack() {
      this.selectedPlanet = null;
    },
    getIconName(name) {
      return name.replace(' ', '_').toLowerCase()
    }
  }
}
</script>
<style lang="scss">
#planetary-activations {
  .small-circle {
    border-radius: 100%;
    width: 9px;
    height: 9px;
  }

  .planetary-tab {
    padding: 5px;
    border-bottom: 3px solid;
    border-color: transparent;
    border-radius: 3px;
    margin-right: 5px;

    &.current {
      font-weight: bold;
      border-color: $primary;
    }
  }
}

</style>