import {mapState} from "vuex";
import localforage from "localforage";
import {config} from "@/utils/config";
export default{

    mixins: [],
    computed:{
        ...mapState({
            hasToken: state => !!state.account.chartsToken
        })
    },
    data(){
        return{
            recentCharts: [],
            chartsUrl: config.chartsUrl,
            recentChartListKey: 1,
            totalFetchAttempts: 0
        }
    },

    methods:{
        getRecentChartsList(){
            this.loading = true;

            // if not pairing is done, try again in 1000s 5 times
            if(!this.hasToken && this.totalFetchAttempts < 5){
                this.totalFetchAttempts++;
                setTimeout(this.getRecentChartsList, 1000);
                return;
            }

            this.axios.get(`${this.chartsUrl}/api/users/recent-charts`).then(res=> {
                this.recentCharts = res.data.charts.slice(0, 7);
                localforage.setItem('mbg.recent_charts', res.data.charts); // save recent charts to localForage
                this.recentChartListKey++;
            }).catch( () =>{
            }).finally(()=>{
                this.loading = false;
            });
        },

        clearRecentChartsList(){
            this.axios.post(`${this.chartsUrl}/api/users/recent-charts`).then( ()=>{
                localforage.setItem('mbg.recent_charts', []); // save recent charts to localForage
                this.recentCharts = [];
                this.recentChartListKey++;
            }).catch(() => {
                this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
            }).finally(()=>{
                this.loading = false;
            });
        }
    }
}