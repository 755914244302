<template>
  <div class="fixed-bottom bg-primary text-white text-center py-3">
    <p class="mb-0">
      You're logged in as an Admin impersonating a user. Please do not forget to sign out before leaving the website.
      <b-button variant="white" class="btn-link px-0 py-0 mx-0" @click="logout">logout</b-button>
    </p>
  </div>
</template>

<script>
import {localStorageKeys} from "../../utils/localStorage";

export default{


  methods:{
    logout(){
      sessionStorage.removeItem(localStorageKeys.IMPERSONATION);
      this.$store.dispatch("account/logout").then( () => {
        this.$store.commit('ui/SET_IS_ADMIN_IMPERSONATION', false);
        this.$router.push({name: 'login'})
      });
    },
  }
}
</script>