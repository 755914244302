
<template>

  <b-card>
    <div class="grid grid-cols-md-3 gap-12 align-items-center py-4">

      <div class="">
        <span class="icon-time-ticking h5"/>
        <p class="watch-table-label mt-2">{{ $t('charts.transit.duration') }}</p>
        <p class="watch-table-value">
          {{calculateTimeDifference(data['currentData'][activatedType].datetime, data[type].datetime, false)}}</p>
      </div>
      <div class="">
        <span class="icon-hourglass h5"/>
        <p class="watch-table-label mt-2">Next Shift</p>
        <p class="watch-table-value">
          {{ formatDate(data[type].datetime, false, 'D MMM YYYY HH:mm')}}
        </p>

        <planet-overview-timer v-if="false" :planet-id="planetId"
                               :type="type" :calculate-real-time-for-activation="calculateRealTimeForActivation"
                               :calculate-time-for-activation="calculateTimeForActivation"
                               :changes="changes"/>

      </div>
      <div class="">
        <span class="icon-arrow-circle-diagonal h5"/>
        <p class="watch-table-label mt-2">Activated</p>
        <p class="watch-table-value">{{formatDate(data['currentData'][activatedType].datetime, false, 'D MMM YYYY HH:mm')}}</p>
      </div>

    </div>
  </b-card>

</template>

<script>

import {defineComponent} from "vue";
import PlanetOverviewTimer from "../../../charts/transit-tool/elements/planet-overview-timer.vue";
import TransitToolMixin from "../../../../mixins/TransitToolMixin";

export default defineComponent({
  components: {PlanetOverviewTimer},
  mixins: [TransitToolMixin],
  props: {
    planetId: [Number, String],
    data: Object,
    type: String
  },
  computed:{
    activatedType(){
      return this.type.toLowerCase().includes('line') ? 'lineStartDate' : 'gateStartDate'
    }
  },
})
</script>