<template>
  <div v-if="!isProVersion || type === 'companionship'">
    <div v-if="isUnlocked" class="mt-3 px-xl-2 py-3 progress-checker-container casual-border-radius text-center" style="">
        <b-form-checkbox class="cursor-pointer col-span-2 mb-0 ml-4" :checked="checked" @change="val => updateProgress(type, id, val)" >
          <span class="cursor-pointer">{{ $t('charts.analysis.progressCheckbox') }}</span>
        </b-form-checkbox>
    </div>
    <div class="grid grid-cols-2 gap-5 py-3">
      <button variant="" class="d-flex align-items-center btn next-prev px-0 w-fit prev gap-5" :disabled="!hasPrev"
                :style="`${!hasPrev && 'opacity:0!important;'};padding:0!important;`"
                @click="$emit('prevSection')"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left"><polyline points="15 18 9 12 15 6"></polyline></svg>
        <span class="mobile-hidden">{{ $t('charts.analysis.prevSection') }} </span>
      </button>

      <button v-show="hasNext" class="d-flex align-items-center btn next-prev  next w-fit ml-auto justify-content-end gap-5"
                :disabled="!hasNext"
                :style="`${!hasNext && 'opacity:0!important;'};padding:0!important;`"
                @click="$emit('nextSection')"
      >
        <span class="mobile-hidden">{{ $t('charts.analysis.nextSection') }}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-right"><polyline points="9 18 15 12 9 6"></polyline></svg>
      </button>

    </div>
</div>


</template>

<style lang="scss">
.custom-checkbox{
  margin-right:1rem;
  flex-shrink:0;
}
.custom-control-label{
  padding-top:4px;
  //margin-top:-3px;
  font-weight:400;
}
.theme--dark{
  .progress-checker-container{
    background-color:$bg-contrast-dark;
  }
}
.theme--light{
  .progress-checker-container{
    background-color:$bg-light;
  }
}

.next-prev{
  background:transparent!important;
  font-weight:400!important;
  color:inherit;
  &:hover{
    opacity:0.6;
  }
}
.theme--dark{
  .next-prev{
    color:white!important;

  }
}
.theme--light {
  .next-prev {
    color:black!important;
  }
}


</style>
<script>

import ChartProgressMixin from "../../../../mixins/ChartProgressMixin";
import {mapState} from "vuex";

export default{

  mixins: [ChartProgressMixin],

  computed:{
    ...mapState({
      isProVersion: state => !!state.account.settings.pro_sidebar_version
    }),
    isUnlocked() {
      try{
        return this.$store.state.charts.chartStatus[this.type]
      }catch(e){
        return false;
      }
    },
    checked(){
      try{
        return this.$store.state.charts.chartProgress[this.type][this.id]
      }catch(e){
        return false;
      }
    }
  },
  props:{
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    hasNext: {
      type: Boolean,
      default: true,
    },
    hasPrev: {
      type: Boolean,
      default: true,
    },
  },
}
</script>