<template>
  <footer id="footer">
<!--    <div class="container">-->
<!--      <div class="row">-->
<!--        <div class="col-12 text-center">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </footer>

</template>

<style lang="scss">
#footer{
  //padding:2rem 0;
}
</style>
<script>
export default{

}
</script>