export default {
  13: '1/3',
  14: '1/4',
  24: '2/4',
  25: '2/5',
  35: '3/5',
  36: '3/6',
  46: '4/6',
  41: '4/1',
  51: '5/1',
  52: '5/2',
  62: '6/2',
  63: '6/3'
}
