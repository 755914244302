<template>

  <div v-if="planet" id="planet-overview">


    <div class="grid grid-cols-xl-12 gap-20 p-md-4">
      <div class="d-flex gap-5 d-xl-block col-span-xl-2">
        <div v-for="tab in tabs" :key="tab.id" class="tab" :class="activeTab === tab.id ? 'active' : ''" @click="changeTab(tab.id)">
          <span :class="`${tab.icon}`"></span>
          <p class="mb-1 text-md">{{tab.name}}</p>
        </div>


      </div>
      <div class="col-span-xl-10 pl-xl-4">
        <div v-if="getTabById(activeTab).type === 'Gate'">

          <planet-next-prev-box :current="changes[planet.id]['currentData']"
                                :next="changes[planet.id]['nextGate']"
                                :prev="changes[planet.id]['prevGate']"
                                @change="val => changeTransitTimestamp(val)"/>

          <gate-content :gate-id="changes[planet.id]['currentData'].gate" show-title hide-gate-num />

          <planet-overview-timer :planet-id="planet.id"
                                 :refresh-key="progressKey"
                                 :slow-refresh-key="slowProgressKey"
                                 :type="dataTypes.nextGate"
                                 :calculate-real-time-for-activation="calculateRealTimeForActivation"
                                 :calculate-time-for-activation="calculateTimeForActivation"
                                 :calculate-time-difference="calculateTimeDifference"
                                 :changes="changes"
                                 :show-activated="true"
                                 :show-duration="true"
                                 :show-strict-dates="!justNow"/>


        </div>

        <div v-else-if="getTabById(activeTab).type === 'Line'">
          <planet-next-prev-box type="line" :current="changes[planet.id]['currentData']" :next="changes[planet.id]['nextLine']" :prev="changes[planet.id]['prevLine']"
              @change="val => changeTransitTimestamp(val)" />

          <line-content :gate-id="changes[planet.id]['currentData'].gate" :line-id="changes[planet.id]['currentData'].line" :loading="loading"
                        @loading="val => this.loading = val"
                        :get-icon="()=>{}" />


          <planet-overview-timer :planet-id="planet.id"
                                 :type="dataTypes.nextLine"
                                 :refresh-key="progressKey"
                                 :slow-refresh-key="slowProgressKey"
                                 :calculate-real-time-for-activation="calculateRealTimeForActivation"
                                 :calculate-time-for-activation="calculateTimeForActivation"
                                 :calculate-time-difference="calculateTimeDifference"
                                 :changes="changes"
                                 :show-activated="true"
                                 :show-duration="true"
                                 :show-strict-dates="!justNow"/>

        </div>

        <div v-else>
          <planet-next-prev-box type="line" :current="changes[planet.id]['currentData']" :next="changes[planet.id]['nextLine']" :prev="changes[planet.id]['prevLine']"
                                :show-gate-and-line="true"
                                @change="val => changeTransitTimestamp(val)"/>

          <planet-overview-timer :planet-id="planet.id"
                                 :type="dataTypes.nextLine"
                                 :refresh-key="progressKey"
                                 :slow-refresh-key="slowProgressKey"
                                 :calculate-real-time-for-activation="calculateRealTimeForActivation"
                                 :calculate-time-for-activation="calculateTimeForActivation"
                                 :calculate-time-difference="calculateTimeDifference"
                                 :changes="changes"
                                 :show-activated="true"
                                 :show-duration="true"
                                 :show-strict-dates="!justNow"/>
        </div>

      </div>
    </div>
  </div>

</template>

<style lang="scss">

#planet-overview{
  .tab {
    cursor:pointer;
    text-align: center;
    opacity:0.4;
    padding: 12px 15px 12px 0;

    @media(max-width:1200px){
      padding: 12px 15px;
      border-bottom:3px solid transparent;
    }
    @media(min-width:1200px){
      padding: 12px 15px 12px 0;
      border-right:3px solid transparent;
    }

    &.active {
      opacity:1;
      border-color: $primary;
    }
  }
}
</style>

<script>
import TransitToolMixin from "../../../../mixins/TransitToolMixin";
import GateContent from "../../properties/content/GateContent";
import LineContent from "../../properties/content/LineContent";
import PlanetNextPrevBox from "./planet-next-prev-box";
import {mapState} from "vuex";
import PlanetOverviewTimer from "./planet-overview-timer";
export default{
  mixins: [TransitToolMixin],

  components: {PlanetOverviewTimer, PlanetNextPrevBox, GateContent, LineContent},
  props:{
    planet: Object
  },

  watch:{
    justNow(val){
      if(val){
        this.updateShiftingRealTime();
      } else this.clearInterval();
    }
  },
  computed:{
    ...mapState({
      activeTab: state => state.charts.transit.transitsSelectedPlanetTab
    }),
  },

  mounted(){
    if(this.justNow) this.updateShiftingRealTime();

  },

  data(){
    return{
      progressKey: 0,
      slowProgressKey: 0,
      interval: null,
      loading: false,
      tabs: [
        {id: 0, name: this.$t('hd.gates.name.many'), icon: 'icon-gate', type: 'Gate'},
        {id: 1, name: this.$t('hd.objects.lines') , icon: 'icon-lines', type: 'Line'},
        {id: 2, name: this.$t('charts.transit.tabs.changes') , icon: 'icon-changes'},
      ]
    }
  },

  destroyed() {
    this.clearInterval()
  },
  methods:{

    updateShiftingRealTime(){
      this.progressKey++;
      this.interval = setInterval(()=>{
        if(this.progressKey === 15) this.slowProgressKey++;

        if(this.progressKey > 15){
          this.progressKey = 0;
        }else{
          this.progressKey++;
        }
      }, 1000)
    },
    clearInterval(){
      if(this.interval) clearInterval(this.interval);
    },

    getTabById(id){
      return this.tabs.find(x => x.id === id);
    },
    changeTab(id){
      this.$store.commit('charts/SET_TRANSIT_SELECTED_PLANET_TAB', id);
    }

  }

}
</script>