<template>

  <div id="mobile-bottom-tabs">

    <div class="bottom-tabs-wrapper">
      <div v-for="item in items" :key="item.name" class="bottom-tab-item text-center" :class="item.id === value ? 'active' : ''"
           @click="changeTab(item.id)">
        <span v-if="item.icon" class="icon" :class="item.icon"/>
        <p class="bottom-tab-text mb-0 ">{{item.name}}</p>
      </div>
    </div>



  </div>

</template>


<script>
export default{

  props:{
    items: Array,
    value: Number,
  },

  methods:{
    changeTab(id){
      this.$emit('input', id);
      const analysis = document.getElementById('analysis-part');
      if(analysis){
        setTimeout(()=>{ analysis.scrollIntoView() }, 350);
      }
    }
  }

}
</script>
<style lang="scss">
#mobile-bottom-tabs{
  border-radius: 20px;
  position:fixed;
  bottom:0;
  left:0;
  right:0;
  overflow-x: auto;
  z-index:99;
  scroll-snap-type: x mandatory;
  @media(min-width:768px){
    display:none;
  }
  .bottom-tabs-wrapper{
    display: flex;
    align-items: center;
    justify-content: left;
    .bottom-tab-item{
      height:100%;
      flex-shrink:0;
      min-width:100px;
      padding:1rem;
      cursor:pointer;
      padding-bottom:0;
      flex-grow: 1;
      flex-basis: 0;
      opacity:0.6;
      .icon{
        font-size:1.3rem;
      }

      .bottom-tab-text{
        border-radius:5px;
        font-size:0.6rem;
        padding-top:0.1rem;
        padding-bottom:0.4rem;
        border-bottom:5px solid transparent;

      }
      &.active{
        opacity:1;
        .bottom-tab-text{
          border-bottom:5px solid $primary;
        }

      }

    }
  }

}
.theme--light{
  #mobile-bottom-tabs{
    background: #FFFFFF;
    box-shadow: 0px -10px 25px rgba(24, 28, 36, 0.15);
  }
}
.theme--dark{}
#mobile-bottom-tabs{
  background: linear-gradient(0deg, #434343, #434343), linear-gradient(0deg, #2F2F2F, #2F2F2F), #191C23;
  box-shadow: 0px -10px 25px rgba(24, 28, 36, 0.15);
}
</style>