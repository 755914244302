var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"mb-4 reverse-bg-mode"},[_c('b-card-body',[_c('div',{staticClass:"row"},[_c('properties-item',{attrs:{"item":{
            label: _vm.$t('properties.chartName'),
            value: _vm.chart.meta.name,
            tripleSpan: true,
          }}}),_vm._l((_vm.getData),function(value,key){return _c('properties-item',{key:key,attrs:{"item":{
            label: _vm.$t(`properties.${key}`),
            value,
            doubleSpan: ['cycleCross'].includes(key)
          }}})})],2)])],1),(_vm.chart.meta.person)?_c('div',[_c('h5',[_vm._v(_vm._s(_vm.chart.meta.person.name)+" - "+_vm._s(_vm.$t('properties.foundational'))+" "),_c('a',{attrs:{"href":_vm.$router.resolve({name: 'single-chart', params: {
          chartId: _vm.chart.meta.person.id
        }}).href,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('properties.openInNewTab')))])]),_c('div',{staticClass:"row mt-4 px-3 px-md-0"},[(_vm.chart.meta.person.birthData.time?.utc)?_c('summary-box',{attrs:{"icon":"icon-time-ticking","title":_vm.$t('properties.birthDateUTC'),"value":_vm.formatDate(_vm.chart.meta.person.birthData.time.utc, true) || _vm.chart.meta.person.birthData.time.timezone.id}}):_vm._e(),_c('personal-chart-summary',{attrs:{"show-advanced":"","chart":_vm.getChart()}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }