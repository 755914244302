<template>
  <div>
    <b-card v-if="chart.meta && chart.meta.name && chart.meta.birthData" class="mb-4 reverse-bg-mode">
      <b-card-body>
        <div v-if="chart.meta.name">
          <label class="font-weight-light" style="opacity:0.7">{{ $t('properties.chartName') }}</label>
          <h5 style="font-weight:500">{{chart.meta.name}}</h5>
        </div>

        <div v-if="chart.meta.birthData" class="row mt-4">
          <div v-if="chart.meta.birthData.time?.local" class="d-flex align-items-center col-12 col-md-6 ">
            <div class="mr-3 mb-3"><span class="icon-birthcake" style="font-size:22px;opacity:0.5"></span></div>
            <div>
              <label class="font-weight-light" style="opacity:0.7">{{ $t('properties.birthDateLocal') }}</label>
              <p class="font-500" style="font-weight:500">{{formatDate(chart.meta.birthData.time.local)}}</p>
            </div>
          </div>
          <div v-if="chart.meta.birthData.time?.design" class="d-flex align-items-center col-12 col-md-6">
            <div class="mr-3 mb-3"><span class="icon-bodygraph-outline" style="font-size:22px;opacity:0.5"></span></div>
            <div>
              <label class="font-weight-light" style="opacity:0.7">{{  $t('properties.designDate') }}</label>
              <p class="font-500">{{formatDate(chart.meta.birthData.time.design, true)}}</p>
            </div>
          </div>
          <div v-if="chart.meta.birthData.location && chart.meta.birthData.location.city" class="d-flex col-12 col-md-6 align-items-center">
            <div class="mr-3 mb-3"><span class="icon-globe" style="font-size:22px;opacity:0.5"></span></div>
            <div>
              <label class="font-weight-light" style="opacity:0.7">{{ $t('properties.city') }}/{{$t('properties.country')}}</label>
              <p class="font-500">{{ chart.meta.birthData.location.city}}, {{$t('countries.' + chart.meta.birthData.location.country)}}</p>
            </div>
          </div>
          <div v-if="chart.meta.birthData.time.utc" class="d-flex col-12 col-md-6 align-items-center">
            <div class="mr-3 mb-3"><span class="icon-time-ticking" style="font-size:22px;opacity:0.5"></span></div>
            <div>
              <label class="font-weight-light" style="opacity:0.7">{{ $t('properties.birthDateUTC') }}</label>
              <p class="font-500">  {{formatDate(chart.meta.birthData.time.utc, true) || chart.meta.birthData.time.timezone.id}}</p>
            </div>
          </div>
        </div>

      </b-card-body>

    </b-card>
    <div v-if="isPersonalChart(chart)" class="px-lg-4 px-md-3">
      <h5>{{$t('properties.foundational')}}</h5>
      <div class="row mt-3">
        <properties-item v-for="item in getPersonal.filter(x => !!x.value)" :key="item.label" :item="item"/>
      </div>
    </div>
    <div v-if="showAdvanced && (getUnlockStatus(chart)['advanced'] || getSubscriptionStatus.advanced)"  class="px-lg-4 px-md-3">
      <h5>{{$t('properties.advanced')}}</h5>
      <div class="row mt-3">
        <properties-item v-for="item in getAdvanced.filter(x => !!x.value)" :key="item.label" :item="item"/>
      </div>
    </div>
  </div>

</template>

<script>
import ChartUtil from "@/mixins/ChartUtil";
import {getFoundationalProperties, getAdvancedProperties} from "@/helpers/charts";
import PropertiesItem from "./PropertiesItem.vue";

export default{
  components: {PropertiesItem},

  mixins: [ChartUtil],
  props:{
    chart: Object,
    showAdvanced: Boolean,
    singleCard: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    getPersonal(){
      return getFoundationalProperties(this.chart);
    },
    getAdvanced(){
      return getAdvancedProperties(this.chart);
    }
  },
}
</script>
