// For all calls, we pass the "chart" and the "keyToSave" as arguments.
// The function will set the "keyToSave" to be the intended value, by doing this[keyToSave] = result.
// It'll be calling it using `Vue.set()` so we can generate the reactivity.
import Vue from "vue";
import channels from "@/helpers/rave/constants/channels";
import centerDefinitionStates from "@/helpers/rave/constants/centerDefinitionStates";
import centers from "@/helpers/rave/constants/centers";

const GATE_LIST = [15, 5, 46, 29, 14, 2, 7, 31, 13, 33, 1, 8];
const CHANNEL_LIST = ['5-15', '46-29', '2-14', '31-7', '33-13','8-1'];

const CENTER_ORDERING = [8, 4, 3, 7, 2, 5, 0, 1, 6];

export default {
    methods: {
        getProfile(chart, keyToSave = 'data') {
            const profile = chart.chart.profile;

            this.axios.get(`/content/${chart.id}/work-success-profiles/${profile}`).then(res => {
                Vue.set(this, keyToSave, res.data);
            });
        },
        getDefinition(chart, keyToSave = 'data') {
            this.axios.get(`/content/${chart.id}/work-success-definitions/${chart.chart.definition}`).then(res => {
                Vue.set(this, keyToSave, res.data);
            })
        },
        getType(chart, keyToSave = 'data') {

            // if we have Manifesting Generator and we also have channel 20-34, we need `1.1` instead of `1`.
            // check if we have channel 20-34
            const id2034 = channels.find(x => x.key === '20-34').id;
            const has2034 = chart.chart.channels.includes(id2034);
            const type = chart.chart.type + (has2034 ? '.1' : '');

            this.axios.get(`/content/${chart.id}/work-success-types/${type}`).then(res => {
                Vue.set(this, keyToSave, res.data);
            })
        },

        getPentaGates(chart, keyToSave = 'data') {
            if (!chart) return;

            if (!this.hasPentaGates(chart)) return;
            const gates = chart.chart.gates.filter(gate => GATE_LIST.includes(gate.gate));

            console.log(gates);

            if (gates.length > 0) {
                Promise.all(gates.map(gate => this.axios.get(`/content/${chart.id}/work-success-penta-gates/${gate.gate}`)))
                    .then((res) => {
                    Vue.set(this, keyToSave, res.map(r => r.data).sort((a, b) => {
                        return GATE_LIST.indexOf(+a.key) - GATE_LIST.indexOf(+b.key);
                    }));
                });
            }
        },

        getCenter(chart, centerId, keyToSave = 'data') {
          if (!chart) return;

          this.axios.get(`/content/${chart.id}/work-success-centers/${centerId}`).then(res => {
                Vue.set(this, keyToSave, res.data);
          });
        },

        getGeneralContent(chart, contentId, keyToSave = 'data') {
            if (!chart) return;
            this.axios.get(`/content/${chart.id}/work-success-general/${contentId}`).then(res => {
                Vue.set(this, keyToSave, res.data);
            });
        },

        getMoneyLine(chart, gateId, keyToSave = 'data') {
            if (!chart) return;

            this.axios.get(`/content/${chart.id}/money-lines/${gateId}`).then(res => {
                Vue.set(this, keyToSave, res.data);
            });
        },
        getTheme(chart, themeId, keyToSave = 'data') {
            if (!chart) return;

            this.axios.get(`/content/${chart.id}/work-success-themes/${themeId}`).then(res => {
                Vue.set(this, keyToSave, res.data);
            });
        },

        getPentaChannels(chart, keyToSave = 'data') {
            if (!chart) return;

            const channelKeys = this.pentaChannelsKeys(chart);

            if (!this.hasPentaGates(chart)) return;

            const channels = channelKeys.filter(key => CHANNEL_LIST.includes(key));

            if (channels.length > 0) {
                Promise.all(channels.map(key => this.axios.get(`/content/${chart.id}/work-success-penta-channels/${key}`)))
                    .then((res) => {
                        Vue.set(this, keyToSave, res.map(r => r.data).sort((a, b) => {
                            return CHANNEL_LIST.indexOf(+a.key) - CHANNEL_LIST.indexOf(+b.key);
                        }));
                    });
            }
        },

        getProfitCenterDetails(chart) {
            if (!chart) return null;
            const profile = chart.chart.profile;

            const hasThirdLine = profile.toString().includes('3');
            const openCenters = [];
            for (const [idx, kind] of chart.chart.centers.entries()) {
                if (kind === centerDefinitionStates.UNDEFINED || kind === centerDefinitionStates.EMPTY) {
                    openCenters.push(idx);
                }
            }

            const openCenterData = [];

            for (const center of openCenters) {
                const data = centers.centersList.find(x => x.id === center);
                const gateData = [];

                for (const gt of data.gates) {
                    if (!chart.chart.planets.find(x => x.gate === gt)) continue;
                    const innerGateList = chart.chart.planets.filter(x => x.gate === gt);

                    for (const gate of innerGateList) {
                        if (!hasThirdLine && +gate.line !== 3) continue;
                        if (gateData.find(x => x.gateId === gate.gate)) continue;
                        gateData.push({
                            gateId: gate.gate,
                            line: gate.line,
                            isThirdLine: +gate.line === 3
                        });
                    }
                }

                openCenterData.push({
                    centerId: center,
                    gates: gateData
                });
            }

            return {
                hasThirdLine,
                centers: openCenterData.sort((a, b) => CENTER_ORDERING.indexOf(a.centerId) - CENTER_ORDERING.indexOf(b.centerId))
            }
        },

        getProfitCenters(chart, keyToSave = 'data') {
            const details = this.getProfitCenterDetails(chart);
            if (!details) return;

            Promise.all(
                details.centers.map(center => {
                    return this.axios.get(`/content/${chart.id}/work-success-centers/${center.centerId}`);
                })
            ).then(res => {
                this.$set(this, keyToSave, res.map(x => x.data));
            })
        },

        pentaChannelsKeys(chart) {
            const channelIds = chart.chart.channels;
            return channelIds.map(chId => channels.find(x => x.id === chId).key);
        },

        hasPentaChannels(chart) {
            const channelKeys = this.pentaChannelsKeys(chart);

            return channelKeys.some(key => CHANNEL_LIST.includes(key));
        },
        hasPentaGates(chart)  {
            if (!chart) return false;
            return chart.chart.gates.some(gate => GATE_LIST.includes(gate.gate));
        }
    }
}