export default `<svg viewBox="0 0 612 612" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <path d="M599.234,0H484.488c-7.042,0-12.737,5.715-12.737,12.756c0,7.042,5.697,12.747,12.737,12.747
    h84.024L404.89,189.134c-46.055-41.526-104.174-62.294-162.314-62.294c-62.08,0-124.159,23.682-171.522,71.053
    c-94.726,94.726-94.726,248.318,0,343.044C118.417,588.3,180.515,612,242.576,612c62.099,0,124.159-23.682,171.521-71.063
    c91.701-91.7,94.633-238.551,8.815-333.771L586.498,43.59v83.912c0,7.042,5.696,12.747,12.736,12.747s12.756-5.705,12.756-12.747
    V12.756C611.99,5.715,606.275,0,599.234,0z M459.649,369.415c0,28.201-5.342,55.693-15.894,81.708
    c-10.907,26.894-26.95,51.062-47.681,71.792c-20.731,20.73-44.898,36.755-71.792,47.681c-26.016,10.552-53.488,15.894-81.708,15.894
    c-28.201,0-55.674-5.342-81.69-15.894c-26.913-10.926-51.079-26.95-71.792-47.681c-20.73-20.73-36.773-44.898-47.698-71.792
    c-10.534-26.017-15.894-53.508-15.894-81.708s5.36-55.692,15.894-81.699c10.926-26.913,26.969-51.061,47.698-71.792
    c20.712-20.73,44.879-36.773,71.792-47.689c25.997-10.542,53.489-15.894,81.69-15.894c28.219,0,55.692,5.352,81.708,15.894
    c26.912,10.917,51.061,26.959,71.792,47.689c20.73,20.73,36.773,44.879,47.681,71.792
    C454.308,313.723,459.649,341.195,459.649,369.415z"/>
</svg>`