export default {
    faq: {
        title: 'What does it all mean?',
        text: 'If you’re brand new to Human Design, this area will answer some of your questions. Once you sign up for a free account, you can learn more in the complimentary knowledge area.',
    },
    intro: '<p class="mb-0">Purchase a <strong>Single Chart Unlock Analysis</strong> for someone else’s chart, or for any connection chart, and gift the analysis to one other person while retaining access yourself. Qualifying purchases will be visible below, please follow the instructions to share your gift.</p>',
    noGifts: 'There are no available analysis to gift.',
    invalid: {
        title: 'Invalid or Claimed Gift Link',
        text: 'This gift link is invalid or has already been claimed. Please contact the person who sent you this link for more information.'
    },
    title: 'Gift Analysis',
    copyLink: 'Copy Gift Link',
    giftReceived: 'Gift Received',
    viewChart: 'View Chart',
    purchased: 'Purchased',
    received: {
        title: 'Gift Received',
        text: 'You have received this analysis as a gift and cannot gift it to anyone else.',
        thanks: '{name} has received your gift. Thanks for sharing such an incredible gift!',
    },
    copyLinkExtended: '<p>Click the <strong>Copy Gift</strong> Link button, then <strong>share this link with your recipient.</strong> They’ll have the option of logging in to their account or signing up for free to receive the analysis.</p>',
    personal: {
        paragraph: 'If you\'re going to unlock an analysis of someone else\'s chart, why not share it with them, too?',
        extended: `<p>
                        Here’s how to gift a Human Design analysis with myBodyGraph:
                    </p>
                    <ol>
                        <li>
                            Create your recipient’s Human Design Chart.
                        </li>
                        <li>
                            Purchase a <a href="/checkout">Single Chart Unlock</a> (Foundational, Advanced etc.) for their chart.
                        </li>
                        <li>
                            Go to <a href="/account?tab=billing&amp;category=gifts">Billing &gt; Gifts</a>
                        </li>
                        <li>
                            Locate the purchase, click the Gift Link button and share this link with your recipient&nbsp;
                        </li>
                    </ol>
                    <p>
                        They’ll have the option of logging in to their account or signing up for free to receive the analysis. Thanks for sharing such an incredible gift!
                    </p>
                    <p>
                        And just to be clear, you keep access to the analysis in your account too—both of you get to benefit from the knowledge. This also works for any Partnership Analysis!
                    </p>`,
        link: 'Click the Gift Link button to copy the link, and share this link with your recipient (they’ll have the option of logging in to their account or signing up to receive the analysis)',
    },
    connection: {
        paragraph: 'If you\'re going to unlock an analysis of your relationship chart, why not share it with your partner, too?',
        extended: `
        <p>
                Here’s how to gift a Partnership Analysis with myBodyGraph:
            </p>
            <ol style="list-style-type:decimal;">
                <li>
                    Create the Connection Chart of the two individuals in the relationship.
                </li>
                <li>
                    Purchase a <a href="/checkout">Partnership Analysis Single Chart Unlock</a> for the chart.
                </li>
                <li>
                    Go to <a href="/account?tab=billing&amp;category=gifts">Billing &gt; Gifts</a>
                </li>
                <li>
                    Locate the purchase, click the Gift Link button and share this link with your partner
                </li>
            </ol>
            <p>
                They’ll have the option of logging in to their account or signing up for free to receive the analysis. Thanks for sharing such an incredible gift!
            </p>
            <p>
                And just to be clear, you keep access to the analysis in your account too—both of you get to benefit from the knowledge. This also works when you unlock an analysis for someone’s personal chart!
            </p>`,
        link: 'Click the Gift Link button, then share this link with your partner (they’ll have the option of logging in to their account or signing up for free to receive the analysis)'
    },
    shared: {
        loggedOut: {
            title: '{name} sent you a gift',
            text: 'You have a wonderful friend! {name} has gifted you {analysis} for the Human Design Chart below.',
            loginCTA: 'Simply log in or sign up to add the chart and analysis to your account and start learning. No idea what any of this means?',
            loginHelpBtn: 'Click here',
            register: 'New? Create a free account',
            login: 'Already have an account? Log in',
        },
        loggedIn: {
            save: 'Add to your account'
        }
    }

}