<template>
  <div id="account" class="default-page-paddings">

    <h2 class="desktop-version">{{ title }}</h2>
    <div v-if="user" class="account-page">
        <div class="row pb-5 pb-md-0">
          <div v-if="isLoaded" class="col-12 col-lg-6 text-left">
            <b-alert v-if="!user.email_confirmed" class="mt-3" show variant="warning">
              The e-mail address is not confirmed.
              <span :class="submitting ? 'opacity-50': ''"
                    class="cursor-pointer font-weight-bold"
                    @click="resendActivation"> Re-send activation link</span>
            </b-alert>
            <b-tabs v-model="currentHash" class="tabs-full-white mobile-hidden" @input="fixTabId" justified fill lazy>
              <b-tab>
                <template #title>
                  <TabTitle :subtitle="$t('pages.account.settings.subtitle')"
                            :title="$t('pages.account.settings.title')" icon="icon-settings"/>
                </template>

                <SettingsPanel />
              </b-tab>
              <b-tab>
                <template #title>
                  <TabTitle :title="$t('pages.account.billing.title')" :subtitle="$t('pages.account.billing.subtitle')" icon="icon-cash"/>
                </template>

                <billing />

              </b-tab>
            </b-tabs>
          </div>
          <div class="col-12 col-lg-6 desktop-version">

            <rave-chart
                :key="$store.state.charts.personalChart?.id"
                        :interactive="false" :chart-loaded="!!$store.state.charts.personalChart.chart" :chart="$store.state.charts.personalChart"></rave-chart>
          </div>
        </div>

      <mobile-bottom-tabs v-model="currentHash" :items="bottomTabItems" />
    </div>
  </div>

</template>

<style lang="scss">
#account{
  @media(max-width:767px){
    margin-bottom:1.5rem;
  }
}
</style>
<script>
import TabTitle from "../../components/elements/TabTitle";
import {mapState} from "vuex"
import MobileBottomTabs from "../../components/structure/MobileBottomTabs";
import util from "../../mixins/util";
import RaveChart from "../../components/charts/rendering/RaveChart";
import Billing from "./Billing";
import SettingsPanel from "./SettingsPanel.vue";

export default{
  components:{
    SettingsPanel, RaveChart, TabTitle,Billing, MobileBottomTabs},

  mixins: [util],
  computed:{
    ...mapState({
      user: state => state.account.user,
      title: state => state.ui.title.text
    })
  },
  methods:{
    fixTabId(){
      let selectedTab = this.hashes[this.currentHash];
      this.$router.replace({query: {tab: selectedTab}});
    },
    applyTab(){
      if (this.$route.query.tab) this.currentHash = this.hashes.findIndex(x => x === this.$route.query.tab)
      this.isLoaded = true;
    },
    // update profile in state.
    fetchProfile() {
      this.axios.get('/users/profile').then(res => {
        if (res.data && res.data.id) this.$store.dispatch('account/setUser', res.data);
      }).catch(() => {
      })
    },

    resendActivation() {
      if (this.submitting) return;
      this.submitting = true;
      this.axios.post('/users/resend-activation').then(() => {
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.created')});
      }).catch(() => {
      }).finally(() => {
        this.submitting = false
      })
    },

    logout() {
      this.$store.dispatch("account/logout").then(() => {
        this.$router.push({name: 'home'})
      });
    },
  },
  data() {
    return {
      submitting: false,
      isLoaded: false,
      hashes: ['settings', 'billing', 'birthInfo'],
      bottomTabItems: [
        {
          name: this.$t('pages.account.settings.title'),
          icon: 'icon-settings',
          id: 0
        },
        {
          name: this.$t('pages.account.billing.title'),
          icon: 'icon-cash',
          id: 1
        },
      ],
      topTabItemsSettings: [
        {
          name: this.$t('general.account'),
          hash: 0
        },
        {
          name: this.$t('general.options'),
          hash: 1
        },
        {
          name: this.$t('general.chart'),
          hash: 2
        },
      ],
      currentHash: 0,
    }
  },
  mounted(){
    this.applyTab();
    this.fetchProfile();
    this.$store.dispatch('ui/setTitle', this.$t('pages.account.title')); // set mobile header title!
  },

}
</script>