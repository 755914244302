<template>
  <div>
    <div>
      <audio-player v-if="!loading && content.audio" :key="audioKey" :file-url="content.audio" />
      <div class="w-md-90">
        <b-skeleton-wrapper :loading="loading">
          <template #loading>
            <div class="mt-3">
              <b-skeleton width="85%" />
              <b-skeleton width="55%" />
              <b-skeleton width="70%" />
            </div>
          </template>
        <iching-gate-content v-if="!loading && content" :key="formatLine"
                             :gate="{gate_id: gateId}"
                             :is-detriment="isDetriment"
                             :is-exalted="isExalted"
                             :lines="{[formatLine]: content}"
                             :selected-line="lineId"
        />
        </b-skeleton-wrapper>
      </div>
    </div>
  </div>

</template>

<script>
import AudioPlayer from "../../../structure/AudioPlayer";
import fixings from "../../../../helpers/rave/constants/fixings"
import IchingGateContent from "../../../structure/owl/partials/IchingGateContent.vue";

export default {

  props: {
    planet: Object,
    gateId: Number,
    lineId: Number,
    getIcon: Function
  },
  components: {IchingGateContent, AudioPlayer},
  data() {
    return {
      loading: false,
      content: {},
      audioKey: 0
    }
  },
  watch: {
    gateId(val) {
      if (val) this.getContent();
    },
    lineId(val) {
      if (val) this.getContent();
    }
  },

  computed: {
    isExalted() {
      if (!this.planet) return false;
      return this.planet.fixing.state === 3 || this.planet.fixing.state === fixings.EXALTED;
    },
    isDetriment() {
      if (!this.planet) return false;
      return this.planet.fixing.state === 3 || this.planet.fixing.state === fixings.DETRIMENT;
    },
    formatLine() {
      return this.gateId + '.' + this.lineId
    }
  },
  created() {
    this.getContent();
  },
  methods: {
    getContent() {
      this.loading = true;
      this.axios.get(`/content/${this.$route.params.chartId}/lines/${this.formatLine}`).then(res => {
        this.content = res.data;
        this.audioKey++;
      }).catch(e => {
        console.log(e);
      }).finally(() => {
        this.loading = false;
      })
    },
    getTrigger(isExalted) {

      let state = fixings.EXALTED;
      if (!isExalted) state = fixings.DETRIMENT;

      let trigger;

      try {
        trigger = this.planet.fixing.triggers.find(x => x.state === state)
      } catch (e) {
        trigger = null;
      }


      if (!trigger) return {};
      return {
        id: trigger.planet,
        name: this.$t('hd.planets.' + trigger.planet + '.name'),
        icon: this.getIcon(this.$t('hd.planets.' + trigger.planet + '.name')),
        gate: trigger.gate,
        line: trigger.line,
        activation: 0
      };
    },
  }

}
</script>