<template>

  <div class="mbg-text-input">
    <label v-if="title" class="mb-3">{{title}}</label>

    <b-form-select :value="value" @input="updateValue" :class="getInputGroupClasses" :options="options"></b-form-select>
    <p class="form-error-indicator mb-0 mt-0" v-if="hasError">
      <span v-for="error in validation.$errors" :key="error.message" class="d-block">
        {{formatMessage(error.$message)}}
      </span>
    </p>

  </div>
</template>


<script>
export default{

  props:{
    options: Array,
    title: String,
    value: [Number, String],
    placeholder: String,
    icon: String,
    disabled: {
      type: Boolean,
      default: false
    },
    validation:Object
  },
  computed: {
    getPlaceholder(){
      return this.placeholder || this.title;
    },
    hasError(){
      return this.validation && this.validation.$error;
    },
    getInputGroupClasses(){
      let classes = '';
      if(this.disabled){
        classes += 'disabled '
      }
      if(this.hasError){
        classes += 'has-error '
      }

      return classes;
    }
  },

  methods:{
    change(val){
      this.$emit('change', val);
    },
    blur(event){
      this.$emit('blur', event);
    },
    updateValue(value) {
      this.$emit('input', value)
    },
    formatMessage(message){
      return message.includes('errors.') ? this.$t(message) : message;
    }
  },
}
</script>