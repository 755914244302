import cognition from '@/helpers/rave/constants/cognitions'

export default {
  [cognition.SMELL]: 'Smell',
  [cognition.TASTE]: 'Taste',
  [cognition.OUTER_VISION]: 'Outer Vision',
  [cognition.INNER_VISION]: 'Inner Vision',
  [cognition.FEELING]: 'Feeling',
  [cognition.TOUCH]: 'Touch'
}
