<template>
  <div class="filter-button">
    <div class="d-flex align-items-center" @click.self="selectFilter">
      <div class=" icon mr-2" @click="selectFilter">
        <span class="flex-shrink-0 text-lg icon-star"></span>
      </div>
      <div class="flex-shrink-0" @click.self="selectFilter">
        <span class="mbg-text-muted text-sm" @click="selectFilter">{{$t('hd.properties.view')}} {{$t('library.all')}}</span><br>

        <p  class="mb-0 text-md font-500"
           @click.self="selectFilter">{{$t('library.celebrity.title')}}</p>
      </div>
      <div class="ml-auto search-icon mr-3" @click="selectFilter">
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    isSelected: Boolean,
    filter: Object
  },
  methods:{
    selectFilter(){
      this.$emit('changed', !this.isSelected);
    },
  }

}
</script>