<template>
  <b-overlay rounded="sm" :show="showLoader">
    <b-card v-show="createNew" class="p-3">
      <global-coupon-display type="single_unlocks" :invalid-coupon="invalidCoupon" @applyCoupon="(val) => {
        coupon = val;
        applyCoupon();
      }"/>

      <div v-if="showLoader && step !== 1 " class="d-flex align-items-center justify-content-center"
           style="min-height:300px;">
        {{ $t('billing.processing') }}
      </div>
      <div v-else-if="createNew && $route.query.action !== 'execute-payment'">

       <!--   Step 0. Order summary.    -->
        <div v-show="step === 0">
          <div v-if="cart_items.length === 0">
            <div class="alert-primary-side" v-html="$t('billing.checkout.single.text')"/>
            <empty-cart class="mt-5" :subtitle="$t('billing.checkout.single.empty')"/>
          </div>
          <div v-else>

            <h6 class="mb-3"> {{$t('billing.checkout.products')}}</h6>

            <cart-item v-for="item in cart_items" :key="item.id" :item="item" @removeFromCart="removeFromCart" />

          </div>

          <div v-if="cart_items.length > 0" class="mt-5">
            <h6 class="mb-3">{{$t('billing.checkout.summary')}}</h6>


            <summary-item v-for="variation in cartVariations" :key="variation.key"
                          :name="$t('billing.unlockTypes.'+variation.key)"
                          :value="variation.quantity * variation.price"
                          :discount="variation.discount"
                          :quantity="variation.quantity"
                          :discounted-price="variation.quantity * variation.discountedPrice"
            />

            <summary-item v-if="cartDiscounts > 0"  class="mt-5" :name="$t('billing.checkout.subtotal')" :value="cartSubTotal"/>
            <summary-item v-if="cartDiscounts > 0" class="mt-2" :name="$t('billing.checkout.discounts')" :value="cartDiscounts"/>
            <summary-item class="mt-3 font-500" :name="$t('billing.checkout.total')" :value="cartTotal"></summary-item>


            <div>
              <div class="d-flex flex-wrap align-items-center mt-4 mb-1">
                <mbg-text-input v-model="coupon" placeholder="Your coupon..."
                                :disabled="!!couponObject"
                                :validation="invalidCoupon ? {
                                  $error: $t('billing.checkout.expiredCoupon')
                                }: undefined"
                                @input="x => coupon = x.toUpperCase()"
                                input-classes="mt-0 mb-0" class="my-0 flex-shrink-0 w-75 pr-3"/>
                <b-button v-if="!couponObject" size="" variant="primary" class=" w-25" @click="applyCoupon" :disabled="isApplying || !coupon || !!couponObject">
                  {{$t('billing.apply')}}
                </b-button>
                <b-button v-else variant="link" @click="removeCoupon">
                  {{$t('billing.remove')}}
                </b-button>
              </div>
              <p v-if="invalidCoupon" class="text-danger">{{$t('billing.checkout.expiredCoupon')}}</p>
            </div>


            <div class="mt-4 pay-method">
              <h6 class="mb-3">{{$t('billing.checkout.payMethod')}}</h6>
              <b-form-radio-group class="custom-radios" v-model="payment_provider" size="lg">
                <b-form-radio :value="1">Credit / Debit Card</b-form-radio>
                <b-form-radio :value="0">PayPal</b-form-radio>
              </b-form-radio-group>
            </div>

            <b-button variant="primary" class="btn-block mt-4" @click="completePayment" :disabled="!canInitiatePurchase">{{$t('billing.checkout.title')}}</b-button>

          </div>
       </div>


        <div v-if="addNew">
          <div>
            <mbg-chart-picker v-model="selectedChart" :title="`${$t('pages.newChart.selectChart')} (${$t('pages.newChart.optional')})`"/>
          </div>

        </div>



      </div>

      <finalize-stripe-payment
        v-if="step === 1"
        :cart-total="cartTotal"
        :process-payment="createOrder"
        @purchasing="val => this.isPurchasing = val"
        @handlePurchase="stripeAfterPurchase"
      />
    </b-card>
  </b-overlay>

</template>

<style lang="scss">
.pay-method .custom-radios .custom-control-label{
  padding-top:6px!important;
}
</style>
<script>

import MbgChartPicker from "../../elements/MbgChartPicker";

import {mapState} from "vuex";
import CartItem from "../cart/CartItem";
import SummaryItem from "../cart/SummaryItem";
import EmptyCart from "./EmptyCart.vue";
import FinalizeStripePayment from "../providers/FinalizeStripePayment.vue";
import MbgTextInput from "../../elements/MbgTextInput.vue";
import GlobalCouponDisplay from "../cart/GlobalCouponDisplay.vue";
import DiscountMixin from "../../../mixins/DiscountMixin";
export default{

  props:{
    createNew: Boolean
  },
  mixins: [DiscountMixin],
  components: {
    GlobalCouponDisplay,
    MbgTextInput, FinalizeStripePayment, EmptyCart, SummaryItem, CartItem, MbgChartPicker},

  computed:{
    ...mapState({
      cart_items: state => state.checkout.single_cart_items
    }),

    cartVariations(){
      let variations = {};

      for(let item of this.cart_items){

        if(!variations[item.key]){
          variations[item.key] = {
            name: item.product.name,
            key: item.product.key,
            quantity: 0,
            price: item.product.price,
          }
        }
        variations[item.key].quantity++;
      }
      for(const key of Object.keys(variations)){
        variations[key] = {
          ...variations[key],
          ...this.getDiscountForProduct(variations[key])
        }
      }
      return Object.values(variations);
    },
    showLoader(){
      return this.isPurchasing || this.$route.query.action === 'execute-payment'
    },
    canInitiatePurchase(){
      return this.cart_items.length > 0
    },
  },
  mounted(){
    this.step = 0;
    if(this.$route.query.action === 'execute-payment'){
      this.executePaypalPayment();
    }
  },

  data(){
    return{
      selectedChart: null,
      addNew: false,
      step: 0,
      payment_provider: 1,
      isPurchasing: false,

      // coupon related.
      couponObject: null,
      coupon: null,
      isApplying: false,
      invalidCoupon: false,
    }
  },
  methods:{

    removeFromCart(item){
      this.$store.commit('checkout/removeSingleFromCart', item);
    },

    createOrder(paymentMethod = undefined){
      const cartItems = this.cart_items.map(x => {
        if (!x.chart_id) throw new Error("Chart ID not found.");
        return {
          chart_id: x.chart_id,
          product_key: x.key
        }
      });
      return this.axios.post('/billing/create-single-purchase', {
        payment_provider: this.payment_provider,
        paymentMethod,
        cart: cartItems,
        coupon: this.couponObject ? this.couponObject.code : undefined
      })
    },

    completePayment(){
      if(this.payment_provider === 1) return this.step = 1;

      if(this.isPurchasing) return;
      this.isPurchasing = true;
      this.createOrder().then(res=>{
        // handle paypal response
        if(this.payment_provider === 0){
          if(res.data.succeeded) return this.afterPurchase();
          window.location.href = res.data;
        }
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
        this.isPurchasing = false;
      })
    },


    executePaypalPayment(){
      const token = this.$route.query.token;
      const payerId = this.$route.query.PayerID;
      this.axios.post('/billing/paypal/execute-payment', {
        order_id: token,
        payment_source: payerId
      }).then(()=>{
        this.afterPurchase();
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    },

    // This is the case where a new payment intent is finalized.
    stripeAfterPurchase(result){
      if(result.completed) return this.afterPurchase();
      this.axios.post('/billing/stripe/finalize-payment-intent', {
        payment_intent_id: result.paymentIntent.id
      }).then(()=>{
        this.afterPurchase();
      });
    },

    refreshStateAfterSuccessfulPayment(){
      this.$store.commit('checkout/clearSingleCart');
      this.$store.dispatch('account/getUserUnlockedCharts'); // re-fetch user unlocked charts!
    },

    afterPurchase(){
      // mark checkout completed as true (to show the checkout complete screen)
      this.$store.commit('checkout/SET_CHECKOUT_COMPLETED', true);
      this.$store.commit('ui/SET_LAST_TRANSACTION_VALUE', this.cartTotal);
      // emit an event just in case.
      this.$emit('purchaseCompleted');
      // refrehs state.
      this.refreshStateAfterSuccessfulPayment();
    },

    applyCoupon(){
      this.invalidCoupon = false;
      this.isApplying = true;
      this.coupon = this.coupon.trim();
      this.axios.get('/billing/coupons?coupon='+ this.coupon).then(res =>{
        if(!res.data.error) this.couponObject = res.data;
        else this.invalidCoupon = true;
      }).catch( () =>{
        this.invalidCoupon = true;
      }).finally(()=>{
        this.isApplying = false;
      });
    },
    removeCoupon(){
      this.couponObject = null;
    }
  }
}
</script>