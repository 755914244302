const SUN = 0
const EARTH = 1
const MOON = 2
const NORTH_NODE = 3
const SOUTH_NODE = 4
const MERCURY = 5
const VENUS = 6
const MARS = 7
const JUPITER = 8
const SATURN = 9
const URANUS = 10
const NEPTUNE = 11
const PLUTO = 12
const CHIRON = 13

const icons = {
    0: 'sun',
    1: 'earth',
    2: 'moon',
    3: 'north_node',
    4: 'south_node',
    5: 'mercury',
    6: 'venus',
    7: 'mars',
    8: 'jupiter',
    9: 'saturn',
    10: 'uranus',
    11: 'neptune',
    12: 'pluto'
}

export default { SUN, EARTH, MOON, NORTH_NODE, SOUTH_NODE, MERCURY, VENUS, MARS, JUPITER, SATURN, URANUS, NEPTUNE, PLUTO, CHIRON, icons }
