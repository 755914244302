import * as R from 'ramda'
import { isEq } from '@/helpers/utils/misc'

const SECURITY = 0
const UNCERTAINTY = 1
const ACTION = 2
const MEDITATION = 3
const JUDGMENT = 4
const ACCEPTANCE = 5

const fromTone = R.cond([
  [isEq(1), R.always(SECURITY)],
  [isEq(2), R.always(UNCERTAINTY)],
  [isEq(3), R.always(ACTION)],
  [isEq(4), R.always(MEDITATION)],
  [isEq(5), R.always(JUDGMENT)],
  [isEq(6), R.always(ACCEPTANCE)]
])

export default { SECURITY, UNCERTAINTY, ACTION, MEDITATION, JUDGMENT, ACCEPTANCE, fromTone }
