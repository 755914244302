<template>

  <div v-if="hasPentaChannels(chart)">
    <dynamic-zone-renderer v-for="gate in data"
                           :key="gate.key"
                           :title="gate.title"
                           :components="gate.components"
                           :add-paddings="false"
                           is-toggleable
    />
  </div>
  <div v-else>
    <dynamic-zone-renderer
                           :components="noContent ? noContent.components : []"
                           :add-paddings="false"
    />
  </div>
</template>

<script>

import WorkSuccessMixin from "@/mixins/WorkSuccessMixin";
import DynamicZoneRenderer from "@/components/renderer/DynamicZoneRenderer.vue";
import AnalysisQueryMixin from "@/mixins/AnalysisQueryMixin";

export default {
  components: {DynamicZoneRenderer},
  mixins: [WorkSuccessMixin, AnalysisQueryMixin],
  props: {
    chart: Object
  },

  data(){
    return {
      data: [],
      noContent: null,
    }
  },

  mounted() {
    if (this.hasPentaChannels(this.chart)) {
      this.getPentaChannels(this.chart)
    } else {
      this.getContent('noContent', '/analysis/introductions/introduction.no-penta-channel');
    }
  },
}
</script>