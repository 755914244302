<template>
  <header id="auth-header">

    <div class="large-container py-2 rounded-bottom px-4">
      <div class="row align-items-center">
        <div class="col-3 col-md-6">
          <span class="logo-block" @click="$router.push({name: 'home'})">
            <img :src="loadLocalIcon(`logo-${getDarkModeText}.svg`)" />
            <span class="desktop-version logo-text">myBodyGraph</span>
          </span>

        </div>
        <div class="col-9 col-md-6 text-right ">
          <div class="d-flex justify-content-end align-items-center">
            <div class="mb-0 mr-3 text-xl cursor-pointer auth-icon-btn" @click="openHelpWidget" style="font-size:20px;">
              <span class="icon-help"/>
            </div>
              <!--    This margin bottom is a hacky way to center the icon with the texts.        -->
            <div class="mb-0 text-xl cursor-pointer auth-icon-btn"
                 :class="isLoggedIn ? 'mr-3' : 'mr-1'"
                  @click="switchDarkMode">
              <span :class="darkMode ? 'icon-dark-mode' : 'icon-half-moon'" />
            </div>
            <b-button v-if="!isLoggedIn" variant="link" @click="$router.push({name:'login'})">Login</b-button>
            <b-button v-if="!isLoggedIn" variant="primary" class="ml-1" @click="$router.push({name:'register'})">Sign Up</b-button>
            <b-button v-if="isLoggedIn" variant="outline-white" @click="$router.push({name: 'account'})">My account</b-button>
          </div>

        </div>
      </div>
    </div>

  </header>
</template>

<style lang="scss">
// color modifications
.theme--dark{
  #auth-header{
  }
}
.theme--light{
  #auth-header{
    background:#fff;
  }
}

#auth-header{
  .logo-block{
    cursor:pointer;
  }
  .logo-text{
    margin-left:5px;
    font-weight:700;
  }

  .auth-icon-btn{
    margin-bottom:-5px!important;
  }

}
</style>

<script>

import {mapState} from "vuex";
import {openZendesk} from "../../helpers/zendesk";

export default{

  computed:{
    ...mapState({
      isLoggedIn: state => state.account.isLoggedIn
    })
  },
  methods:{
    openHelpWidget() {
      openZendesk();
    }
  }
}
</script>