<template>

  <div id="account-settings">

    <div class="row">

      <div v-if="loaded" class="col-12">
        <b-tabs v-model="activeTab" class="tabs-primary-underlined light-tab-titles" lazy @input="updateSubTab">


          <b-tab :title="$t('billing.tabs.subscriptions.title')">

            <div class="subscriptions">
              <p v-if="subscriptions.length === 0">
                {{ $t('billing.noSubscriptions') }}
              </p>

              <subscription-item v-for="sub in subscriptions" :key="sub.id" :item="sub"/>
            </div>

          </b-tab>
          <b-tab :title="$t('billing.tabs.purchases.title')">

            <div class="purchases">
              <purchase-item v-for="purchase in purchases" :key="purchase.id" :item="purchase"/>
              <p v-if="purchases.length === 0">
                {{ $t('billing.noPurchases') }}
              </p>
            </div>

          </b-tab>

          <b-tab :title="$t('billing.tabs.cards.title')">
            <user-card/>
          </b-tab>

          <b-tab :title="$t('billing.tabs.coupons.title')">
            <user-coupons/>
          </b-tab>

          <b-tab :title="$t('billing.tabs.invoiceOptions.title')">
            <user-invoice-options/>
          </b-tab>
          <b-tab :title="$t('billing.tabs.gifts.title')">
            <user-gifts />
          </b-tab>
        </b-tabs>

      </div>
    </div>

  </div>
</template>
<style lang="scss">
#account-settings {
  .newsletter-img {
    margin-bottom: 1rem;
  }

  .subscriptions, .purchases, .gifts {
    @media(min-width: 767px) {
      max-height: 450px;
      overflow-y: auto
    }
  }
}
</style>

<script>

import util from "../../mixins/util";
import {mapState} from "vuex";
import PurchaseItem from "@/components/billing/PurchaseItem";
import UserCard from "@/components/billing/UserCard";
import UserCoupons from "@/components/billing/UserCoupons.vue";
import UserInvoiceOptions from "@/components/billing/UserInvoiceOptions.vue";
import SubscriptionItem from "@/components/billing/SubscriptionItem.vue";
import UserGifts from "@/components/billing/UserGifts.vue";

export default {
  components: {SubscriptionItem, UserCard, PurchaseItem, UserCoupons, UserInvoiceOptions, UserGifts},

  mixins: [util],
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
  },
  data() {
    return {
      activeTab: 0,
      tabs: ['subscriptions', 'purchases', 'cards', 'coupons', 'invoice-options', 'gifts'],
      subscriptions: [],
      purchases: [],
      cards: [],
      loaded: false,
      purchaseKey: 0,
    }
  },
  created() {
    this.fetchSubscriptions();
    this.fetchPurchases();
    if (this.$route.query.category) {
      this.activeTab = this.tabs.findIndex(x => x === this.$route.query.category) || 0;
    } else {
      this.activeTab = 0;
    }
    this.loaded = true;
  },
  methods: {

    updateSubTab() {
      let query = this.getQuery();
      query.set('category', this.tabs[this.activeTab]);
      this.fixUrlQuery('?' + query)
    },

    fetchSubscriptions() {
      this.axios.get('/billing/subscriptions?include_cancelled=true').then(res => {
        this.subscriptions = res.data.sort((a, b) => {
          return a.status - b.status
        });
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    },

    fetchPurchases() {
      this.axios.get('/billing/purchases').then(res => {
        this.purchases = res.data;
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    }
  }
}
</script>