import * as R from 'ramda'
import { isEq, collectArguments, allSame, allDifferent } from '@/helpers/utils/misc'
import alignments from './binaryAlignments'

const SINGULAR = 0
const FOCUSED = 1
const BINARY = 2
const BINARY_SEPARATION = 3

const hasDiagonalOpposition = R.either(
  R.both(R.includes(alignments.TOP_LEFT), R.includes(alignments.BOTTOM_RIGHT)),
  R.both(R.includes(alignments.BOTTOM_LEFT), R.includes(alignments.TOP_RIGHT))
)

const binaryDifference = R.pipe(
  R.uniq,
  hasDiagonalOpposition,
  R.cond([
    [isEq(false), R.always(BINARY)],
    [R.T, R.always(BINARY_SEPARATION)]
  ])
)

const fromBase = alignmentConverter => R.pipe(
  collectArguments,
  R.map(alignmentConverter),
  R.cond([
    [allSame, R.always(SINGULAR)],
    [allDifferent, R.always(FOCUSED)],
    [R.T, binaryDifference]
  ])
)

// pSun, pNode, dNode
const fromPersonalityBase = fromBase(alignments.fromPersonalityBase)

// dSun, dNode, pNode
const fromDesignBase = fromBase(alignments.fromDesignBase)

export default { SINGULAR, FOCUSED, BINARY, BINARY_SEPARATION, fromPersonalityBase, fromDesignBase, fromBase }
