<template>
  <b-card class="cursor-pointer hover-brightness " @click="$emit('setModalNote', item)">

    <div class="d-flex flex-column justify-content-between h-100">

      <p class="note-preview-render font-500" style="font-size:1.1rem">
        {{ item.content }}
      </p>

      <div class="d-flex flex-wrap gap-5 mb-3">

        <div v-for="tag in item.note_tags" :key="'tag-'+item.id+ '-' + tag.id" class="badge-light px-1" style="border-radius:3px">
          <svg width="10" height="10" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.7267 8.94001L8.94671 13.72C8.82288 13.844 8.67582 13.9423 8.51396 14.0094C8.3521 14.0765 8.17859 14.1111 8.00337 14.1111C7.82815 14.1111 7.65465 14.0765 7.49279 14.0094C7.33092 13.9423 7.18387 13.844 7.06004 13.72L1.33337 8.00001V1.33334H8.00004L13.7267 7.06001C13.975 7.30983 14.1144 7.64776 14.1144 8.00001C14.1144 8.35226 13.975 8.69019 13.7267 8.94001Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.7267 8.94001L8.94671 13.72C8.82288 13.844 8.67582 13.9423 8.51396 14.0094C8.3521 14.0765 8.17859 14.1111 8.00337 14.1111C7.82815 14.1111 7.65465 14.0765 7.49279 14.0094C7.33092 13.9423 7.18387 13.844 7.06004 13.72L1.33337 8.00001V1.33334H8.00004L13.7267 7.06001C13.975 7.30983 14.1144 7.64776 14.1144 8.00001C14.1144 8.35226 13.975 8.69019 13.7267 8.94001Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M13.7267 8.94001L8.94671 13.72C8.82288 13.844 8.67582 13.9423 8.51396 14.0094C8.3521 14.0765 8.17859 14.1111 8.00337 14.1111C7.82815 14.1111 7.65465 14.0765 7.49279 14.0094C7.33092 13.9423 7.18387 13.844 7.06004 13.72L1.33337 8.00001V1.33334H8.00004L13.7267 7.06001C13.975 7.30983 14.1144 7.64776 14.1144 8.00001C14.1144 8.35226 13.975 8.69019 13.7267 8.94001Z" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.66663 4.66666H4.67329" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.66663 4.66666H4.67329" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M4.66663 4.66666H4.67329" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          {{tag.name}}
        </div>
      </div>

      <div class="mt-auto">
        <div>
          <p class="mb-0 text-xs mbg-text-muted">{{ $t('general.lastUpdate') }}</p>
          <p class="text-sm mb-0">{{ formatDate(item.updatedAt) }}</p>
        </div>

      </div>
      <div class="py-1 mt-1 w-fit mbg-text-muted" >
        <span class="icon-user"/>
        {{item.chart?.name ?? '-'}}
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    item: Object
  }
}
</script>