import motivation from '@/helpers/rave/constants/motivations'

export default {
  [motivation.FEAR]: 'Fear',
  [motivation.HOPE]: 'Hope',
  [motivation.DESIRE]: 'Desire',
  [motivation.NEED]: 'Need',
  [motivation.GUILT]: 'Guilt',
  [motivation.INNOCENCE]: 'Innocence'
}
