import daggy from 'daggy'
import activations from '../rave/constants/activationModes'
import circuits from '../rave/constants/circuits'

const ViewMode = daggy.taggedSum('ViewMode', {
  Normal: [],
  Cross: [],
  SpecificActivation: ['val'],
  SpecificChart: ['val'],
  Circuit: ['val']
})

export default ViewMode

export const modes = {
  Normal: ViewMode.Normal,
  Cross: ViewMode.Cross,
  activations: {
    Personality: ViewMode.SpecificActivation(activations.PERSONALITY),
    Design: ViewMode.SpecificActivation(activations.DESIGN)
  },
  composite: id => ViewMode.SpecificChart(id),
  circuits: {
    Logic: ViewMode.Circuit(circuits.LOGIC),
    Abstract: ViewMode.Circuit(circuits.ABSTRACT),
    Knowing: ViewMode.Circuit(circuits.KNOWING),
    Centering: ViewMode.Circuit(circuits.CENTERING),
    Integration: ViewMode.Circuit(circuits.INTEGRATION),
    Defense: ViewMode.Circuit(circuits.DEFENSE),
    Ego: ViewMode.Circuit(circuits.EGO)
  }
}
