<template>
  <div id="account-settings" class="row">
    <div class="col-12 col-md-6">
      <mbg-select v-model="options.date_input"
                  :title="$t('general.accountOptions.date_input')"
                  :options="[
                    {
                      text: 'DD/MM/YYYY',
                      value: 0
                    },
                    {
                      text: 'MM/DD/YYYY',
                      value: 1
                    }
      ]"/>
    </div>
    <div class="col-12 col-md-6">
      <label>Pro Mode</label>
      <b-form-checkbox v-model="options.pro_sidebar_version" name="pro-sidebar" class="cursor-pointer my-3">
        {{ $t('general.accountOptions.pro_sidebar_version')  }}
      </b-form-checkbox>

    </div>

    <div class="col-12 mt-5">

      <b-button variant="primary" @click="updateOptions" :disabled="!hasChanged">{{ $t('general.saveChanges') }}</b-button>

    </div>

  </div>
</template>
<style lang="scss">
</style>

<script>

import {mapState} from "vuex";
import MbgSelect from "../../components/elements/MbgSelect.vue";

const defaultOptions = {
  pro_sidebar_version: false,
  date_input: 0
}

export default{
  components: {MbgSelect},

  computed:{
    ...mapState({
      user: state => state.account.user,
      settings: state => state.account.settings
    }),

    hasChanged(){
      return JSON.stringify(this.options) !== this.initialOptions;
    }
  },
  watch:{
    user(){
      this.setupData();
    },
    settings(){
      this.setupData();
    }
  },
  data(){
    return{
      orderOptions: [
        { text: 'properties.name', value: 'nameAsc' },
        { text: 'properties.name', note: 'library.sort.name.desc', value: 'nameDesc' },
        { text: 'library.sort.created.title', note: 'library.sort.created.asc', value: 'creationDateDesc' },
        { text: 'library.sort.created.title', note: 'library.sort.created.desc', value: 'creationDateAsc' },
        {text: 'properties.docType', value: 'docType'},
        { text: 'hd.properties.type', value: { sortBy: 'type', descending: false } },
        { text: 'hd.properties.profile', value: 'profile' },
        { text: 'hd.properties.definition', value: 'definition' },
        { text: 'hd.properties.authority', value: 'definition' }
      ],
      options: defaultOptions,
      initialOptions: null,
      selected: 'createdAt'
    }
  },
  created(){
    this.setupData();
  },
  methods:{
    setupData(){
      if(this.settings.pro_sidebar_version) this.options.pro_sidebar_version = this.settings.pro_sidebar_version;
      if(this.settings.date_input) this.options.date_input = this.settings.date_input;
      this.initialOptions = JSON.stringify(this.options);
    },

    updateOptions(){
      this.axios.put('/users/settings', this.options).then(res => {
        if(res.data) this.$store.commit('account/SET_SETTINGS', res.data);
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')});
      }).catch( () => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      });
    }

  }
}
</script>