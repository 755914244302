<template>
  <div :id="id" class="side-panel-wrapper">
      <div v-if="!hideBackdrop" class="position-fixed side-panel-backdrop" :class="visible ? 'backdrop-visible' : ''" @click.self="close"></div>
    <div :id="id + '-side-panel-content'" class="side-panel-content-wrapper"
         :class="`${slideAnimationClasses} ${size} ${hasHeaderSlot ? 'p-0' : ''} ${fromLeft ? 'left' : 'right'} ${hideBackdrop ? 'hidden-backdrop' : ''}`">
      <div v-if="hasHeaderSlot"
           class="bg-dark casual-border-radius d-flex align-items-end p-4 text-white mb-4"
           style="min-height:125px;border-top-right-radius: 0!important;border-top-left-radius: 0!important">
        <slot name="header"/>
      </div>
      <b-button variant="link" style="top:12.5px;right:15px;"
                :class="hasHeaderSlot ? 'text-white' : ''"
                class="position-fixed z-5 animate-icon-on-hover" @click="close">
        {{closeBtnText || $t('general.close') }} <span class="icon-close ml-3"></span>
      </b-button>
      <div v-if="!hasHeaderSlot" class="side-panel-content-header px-md-2 row align-items-center">
        <div class="col-8 d-flex align-items-center gap-5">
          <span v-if="icon" :class="icon" class="text-1xl"></span>
          <h4 v-if="title" class="mb-0">
            {{ title }}
          </h4>
          <slot name="title"></slot>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
.side-panel-wrapper{
  position:fixed;
  z-index:1000;
  .side-panel-backdrop{
    z-index:3;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
    visibility: hidden;
    opacity:0;
    &.backdrop-visible{
      visibility: visible;
      opacity:1
    }
  }
}
.side-panel-content-wrapper{
  position:fixed;
  padding:15px;
  z-index:1000;
  box-shadow:none!important;

  &.hidden-backdrop{
    box-shadow: 32px 5px 36px 13px rgba(0,0,0,0.1)!important;
  }

  &.right{
    right:0;
    transform:translateX(100%);
  }
  &.left{
    left: 0;
    transform:translateX(-500%);
  }
  ol, ul {
    padding-left:20px;
  }
  li {
    margin-bottom:5px;
  }

  top:0;
  bottom:0;
  width:100vw;

  overflow-x:hidden;

  @media(min-width:767px){
    &.left{
      border-top-right-radius: 25px;
      border-bottom-right-radius:25px;
    }
    &.right{
      border-top-left-radius: 25px;
      border-bottom-left-radius:25px;
    }

    width:70vw;
    &.small{
      width: 50vw;
    }
    &.x-small{
      width: 35vw;
    }
  }
  @media(min-width:1240px){
    padding:20px;
    width:50vw;
    &.small{
      width: 35vw;
    }
    &.x-small{
      width: 25vw;
    }
  }
  @media(min-width:1920px){
    padding:20px;
    width:40vw;
    &.small{
      width: 27vw;
    }
    &.x-small{
      width: 20vw;
    }
  }

  overflow-y:auto;
  transition:1s;
  &.active{
    &.left {
      animation: .5s animate-side-left-menu ease-in-out forwards!important;
    }
    &.right {
      animation: .5s animate-side-menu ease-in-out forwards!important;
    }

  }
  &.closing{
    &.left {
      animation: .5s animate-out-side-left-menu ease-in-out forwards!important;
    }
    &.right {
      animation: .5s animate-out-side-menu ease-in-out forwards!important;
    }

  }

}

.theme--light{
  .side-panel-backdrop{
    background:rgba(0,0,0,0.25);
  }
  .side-panel-content-wrapper{

    background-color:white;
    box-shadow: 0 10px 25px rgba(24, 28, 36, 0.05);

  }
}
.theme--dark{
  .side-panel-backdrop{
    background:rgba(0,0,0,0.6);
  }
  .side-panel-content-wrapper{

    background:$bg-dark;
    box-shadow: 0 10px 25px rgba(24, 28, 36, 0.4);
  }
}
</style>
<script>
export default{
  props:{
    title: String,
    closeBtnText: {
      type: String,
    },
    id: {
      type: String,
      default: 'side-panel'
    },
    visible: {
      type: Boolean,
      default: false
    },
    icon: String,
    size: {
      default: 'regular',
      validator(value) {
        return ['x-small', 'small', 'regular'].includes(value)
      }
    },
    extraContainerClasses: String,
    fromLeft: {
      type: Boolean,
      default: false
    },
    hideBackdrop: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return{
      isClosing: false
    }
  },
  watch:{
    visible(val){

      if(this.hideBackdrop) return this.setBodyScrollBehavior(true);
      if(val) this.isClosing = false;
      if(!val) this.close();
      this.setBodyScrollBehavior(!val);
    },
  },
  computed:{
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    slideAnimationClasses(){

      if(this.visible) return 'active';

      if(this.isClosing) return 'closing';
      return '';
    }
  },

  methods:{
    close(){
      this.isClosing = true;
      this.$nextTick(()=>{
        this.$emit('close');
      });
    }
  }
}
</script>