<template>
  <div id="mobile-header" :class="fullScreenMenu ? 'show' :''">

    <div class="mobile-header-content">

      <div class="close-menu text-right">
        <span class="icon-close" @click="$emit('closeMenu')"/>
      </div>

      <div class="mobile-menu-wrapper text-right">
        <p class="mbg-text-muted font-weight-lighter">Your Menu</p>
        <div v-for="(item, index) in firstMenu" class="mobile-menu-button justify-content-end position-relative" :key="index"
             @click="item.url ? redirectTo(item.url.name, item.url.hash) : item.onClick()">
          <p class="mobile-menu-text mb-0">{{item.name}}</p>
          <div class="icon" :class="item.icon"/>
          <div v-if="item.hasBadge"
               class="position-absolute bg-primary  rounded-circle"
               style="top:0; right:-5px; height:5px; width: 5px"
          />
        </div>
      </div>

      <div class="mobile-menu-wrapper text-right">
        <p class="mbg-text-muted font-weight-lighter"> {{$t('pages.account.title')}}
        </p>
        <div v-for="(item, index) in accountMenu" class="mobile-menu-button justify-content-end" :key="index"
             @click="item.url ? redirectTo(item.url.name, null, {}, item.url.hash) : item.onClick()">
          <p class="mobile-menu-text mb-0">{{item.name}}</p>
          <div class="icon" :class="item.icon"/>
        </div>

        <div class="mobile-menu-button justify-content-end"  @click="openAffiliateMarketing">
          <p class="mobile-menu-text mb-0">{{ $t('pages.account.affiliate.title') }}</p>
          <div class="icon icon-affiliate"/>
        </div>
      </div>

      <div class="mobile-menu-wrapper text-right">
        <p class="mbg-text-muted font-weight-lighter"> {{ $t('header.appearance') }}</p>

        <div class="mobile-menu-button justify-content-end"  @click="switchDarkMode">
          <p class="mobile-menu-text mb-0">{{ getDarkTranslation + ' ' + $t('header.menu.mode') }}</p>
          <div class="icon" :class="darkMode ? 'icon-dark-mode' : 'icon-half-moon'"/>
        </div>

        <div class="mobile-menu-button justify-content-end"  @click="openZendesk">
          <p class="mobile-menu-text mb-0">{{ $t('header.menu.help') }}</p>
          <div class="icon icon-help"/>
        </div>
      </div>


      <div class="mobile-menu-wrapper text-right">
        <p class="mbg-text-muted font-weight-lighter" @click="$emit('logout')">Logout</p>
      </div>

      <Copyright  class="text-right"/>

    </div>
  </div>
</template>



<script>

import {mapState} from "vuex";
import Copyright from "../auth/Copyright.vue";

export default {
  components: {Copyright},
  props: {
    fullScreenMenu: Boolean,
    openZendesk: Function
  },

  computed: {
    ...mapState({
      darkMode: state => state.darkMode,
      user: state => state.account.user,
      notifications: state => state.account.notifications,
      settings: state => state.account.settings
    }),
    getDarkTranslation(){
      if(this.darkMode){
        return this.$t('header.menu.light');
      }
      return this.$t('header.menu.dark');
    },
    firstMenu() {
      return [
        {
          name: this.$t('header.menu.library'),
          icon: 'icon-summary',
          url: {
            name: 'library',
            hash: null
          }
        },
        {
          name: this.$t('header.menu.learn'),
          icon: 'icon-knowledge',
          onClick: () => {
            this.$emit('closeMenu');
            this.$store.commit('ui/SET_SHOW_OWL', true);
          },
          hasBadge: this.notifications.data.length > 0 && this.notifications.data[0].id !== this.settings.last_notification_read
        },
        {
          name: this.$t('billing.checkout.title'),
          icon: 'icon-cart',
          url: {
            name: 'checkout',
            hash: null
          }
        }
      ]
    }
  },
  methods: {
    openAffiliateMarketing() {
      window.open('https://mybodygraph.firstpromoter.com', '_blank');
    },
  },
  data() {
    return {

      accountMenu: [
        {
          name: this.$t('header.menu.chart'),
          icon: 'icon-view-mode-2',
          onClick: () => {
            this.redirectTo('single-chart', null, {chartId: this.user.chart_id})
          }
        },
        {
          name: this.$t('header.menu.justNow'),
          icon: 'icon-person-cross',
          onClick: () => {
            this.$router.push('/charts/transit/just-now/'+ this.user.chart_id)
          }
        },
        {
          name: this.$t('pages.account.settings.title'),
          icon: 'icon-settings',
          url: {
            name: 'account',
            hash: {
              tab: 'settings'
            }
          }
        },
        {
          name: this.$t('pages.account.billing.title'),
          icon: 'icon-cash',
          url: {
            name: 'account',
            hash: {
              tab: 'billing'
            }
          }
        },
      ]
    }
  },
}

</script>

<style lang="scss">
#mobile-header{
  position:fixed;
  top:0;

  left:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,0.6);
  z-index:9990;
  display:none;
  &.show{
    display:block;
  }
  .mobile-header-content{
    overflow-y:auto;
    position:fixed;
    left:10%;
    right:0;
    top:0;
    bottom:0;
    width:auto;
    padding:1rem 2rem;
    box-shadow: 0px 20px 40px rgba(24, 28, 36, 0.1);
    border-radius: 20px 0 0 50px;
  }
  .close-menu{
    margin-bottom:2rem;
  }
  .mobile-menu-wrapper{
    margin-top:3rem;
    .mobile-menu-button{

      display: flex;
      align-items: center;

      margin-bottom:1.5rem;
      .icon{
        margin-left:1rem;
        font-size:1.4rem;
      }
      .mobile-menu-text{
        font-size:1rem;
        font-weight:500;
        vertical-align: middle;
      }
    }
  }
}
.theme--dark{
  #mobile-header{
    .mobile-header-content{
      background: linear-gradient(0deg, #434343, #434343), linear-gradient(0deg, #2F2F2F, #2F2F2F), #191C23;
    }
  }
}
.theme--light{
  #mobile-header{
    .mobile-header-content{
      background:#fff;
    }
  }
}
</style>