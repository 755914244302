export default `<svg viewBox="0 0 511.713 432.621" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <path d="M382.478,10.552C382.478,4.717,377.75,0,371.926,0c-5.825,0-10.552,4.717-10.552,10.552
    c0,11.766-14.171,23.974-38.24,32.226l-7.059,2.174c-3.43,1.055-7.133,1.931-10.89,2.785c-1.604,0.349-3.208,0.707-4.864,1.023
    c-2.532,0.496-5.159,0.907-7.818,1.309c-2.807,0.422-5.677,0.791-8.621,1.129c-2.785,0.307-5.593,0.591-8.483,0.802
    c-1.71,0.127-3.43,0.243-5.171,0.338c-4.706,0.232-9.475,0.422-14.371,0.422s-9.666-0.189-14.361-0.433
    c-1.741-0.095-3.461-0.211-5.17-0.337c-2.892-0.212-5.698-0.496-8.483-0.803c-2.934-0.327-5.814-0.696-8.621-1.118
    c-2.659-0.401-5.286-0.823-7.819-1.309c-1.656-0.316-3.26-0.664-4.864-1.023c-3.767-0.844-7.471-1.73-10.899-2.785l-7.06-2.174
    c-24.068-8.252-38.239-20.46-38.239-32.226C150.339,4.717,145.611,0,139.787,0s-10.552,4.717-10.552,10.552
    c0,22.56,20.186,41.278,52.759,52.379v306.761c-32.573,11.1-52.759,29.818-52.759,52.378c0,5.836,4.728,10.552,10.552,10.552
    s10.552-4.716,10.552-10.552c0-11.765,14.171-23.974,38.239-32.225l7.06-2.174c3.429-1.055,7.133-1.941,10.899-2.785
    c1.594-0.349,3.197-0.696,4.844-1.014c2.543-0.495,5.191-0.918,7.861-1.318c2.796-0.422,5.655-0.792,8.589-1.119
    c2.785-0.306,5.603-0.591,8.483-0.802c1.709-0.126,3.43-0.242,5.17-0.338c4.706-0.242,9.476-0.433,14.372-0.433
    s9.665,0.19,14.361,0.433c1.74,0.096,3.471,0.212,5.17,0.338c2.892,0.211,5.698,0.496,8.483,0.802
    c2.934,0.327,5.793,0.697,8.589,1.119c2.67,0.4,5.308,0.823,7.851,1.318c1.657,0.317,3.25,0.665,4.854,1.014
    c3.767,0.844,7.471,1.73,10.899,2.785l7.06,2.174c24.079,8.251,38.25,20.46,38.25,32.225c0,5.835,4.727,10.552,10.552,10.552
    c5.824,0,10.552-4.717,10.552-10.552c0-22.56-20.186-41.278-52.759-52.379V62.931C362.292,51.83,382.478,33.111,382.478,10.552z
     M308.615,68.692V363.93c-0.19-0.042-0.391-0.063-0.58-0.105c-2.037-0.422-4.137-0.781-6.236-1.161
    c-2.269-0.411-4.526-0.833-6.869-1.182c-0.401-0.063-0.834-0.095-1.234-0.158c-7.725-1.098-15.796-1.878-24.28-2.258
    c-4.432-0.2-8.958-0.306-13.559-0.306s-9.128,0.105-13.56,0.316c-8.483,0.38-16.545,1.16-24.269,2.258
    c-0.411,0.053-0.844,0.095-1.245,0.158c-2.343,0.349-4.59,0.771-6.858,1.182c-2.1,0.369-4.2,0.738-6.236,1.161
    c-0.189,0.042-0.391,0.063-0.58,0.105V68.692c0.189,0.042,0.391,0.063,0.58,0.105c2.036,0.422,4.137,0.78,6.236,1.16
    c2.269,0.412,4.516,0.834,6.858,1.183c0.411,0.063,0.834,0.105,1.245,0.158c7.724,1.097,15.796,1.878,24.269,2.258
    c4.432,0.2,8.959,0.306,13.56,0.306s9.127-0.105,13.559-0.316c8.484-0.38,16.556-1.16,24.28-2.258
    c0.411-0.053,0.833-0.095,1.234-0.158c2.343-0.349,4.601-0.771,6.869-1.182c2.1-0.37,4.199-0.739,6.236-1.161
    C308.225,68.756,308.425,68.734,308.615,68.692z"/>
</svg>`