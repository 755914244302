export default `<svg viewBox="0 0 511.713 432.621" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <path d="M457.811,296.425c-3.492-4.675-10.119-5.614-14.762-2.11c-4.663,3.492-5.613,10.108-2.11,14.762
    l18.982,25.335h-10.552c-17.452,0-31.654-14.203-31.654-31.655V102.273c0-38.409-31.001-67.373-72.101-67.373
    c-27.751,0-51.503,12.726-64.65,32.077c-12.346-19.5-34.937-32.077-61.97-32.077c-27.751,0-51.503,12.726-64.65,32.077
    c-12.345-19.5-34.937-32.077-61.97-32.077c-42.407,0-75.624,29.598-75.624,67.373c0,5.835,4.716,10.551,10.552,10.551
    c5.835,0,10.552-4.716,10.552-10.551c0-28.965,27.719-46.27,54.521-46.27c29.556,0,50.997,19.457,50.997,46.27v200.482
    c0,5.835,4.717,10.552,10.552,10.552s10.552-4.717,10.552-10.552V102.273c0-28.965,27.719-46.27,54.521-46.27
    c29.556,0,50.997,19.457,50.997,46.27v200.482c0,5.835,4.717,10.552,10.552,10.552s10.552-4.717,10.552-10.552V102.273
    c0-28.965,27.72-46.27,54.521-46.27c29.556,0,50.997,19.457,50.997,46.27v200.482c0,29.091,23.667,52.758,52.758,52.758h6.184
    l-24.195,24.195c-4.125,4.126-4.125,10.795,0,14.92c2.058,2.058,4.759,3.092,7.46,3.092c2.702,0,5.403-1.034,7.461-3.092
    l42.196-42.196c0.011-0.01,0.021-0.021,0.031-0.021l6.458-6.458L1000,1000z"/>
</svg>`