import centerDefinitionStates from "./centerDefinitionStates";
const ROOT = {
  id: 0,
  isMotor: true,
  color: 'b',
  gates: [58, 38, 54, 53, 60, 52, 19, 39, 41],
  name: 'ROOT',
  icon:'root',
  slug: 'root-center'
}

const SACRAL = {
  id: 1,
  isMotor: true,
  color: 'r',
  gates: [42, 3, 9, 59, 29, 14, 34, 5, 27],
  name: 'SACRAL',
  icon: 'sacral',
  slug: 'sacral-center'
}

const SPLEEN = {
  id: 2,
  isMotor: false,
  color: 'b',
  gates: [18, 28, 32, 50, 44, 57, 48],
  name: 'SPLEEN',
  icon: 'spleen',
  slug: 'splenic-center'
}

const SOLAR_PLEXUS = {
  id: 3,
  isMotor: true,
  color: 'b',
  gates: [30, 55, 49, 6, 37, 22, 36],
  name: 'SOLAR_PLEXUS',
  icon: 'solar-plexus',
  slug: 'solar-plexus-center'
}

const HEART = {
  id: 4,
  isMotor: true,
  color: 'r',
  gates: [40, 26, 51, 21],
  name: 'HEART',
  icon: 'heart',
  slug: 'heart-center'
}

const G = {
  id: 5,
  isMotor: false,
  color: 'y',
  gates: [2, 46, 25, 13, 1, 7, 10, 15],
  name: 'G',
  icon: 'g',
  slug: 'g-center'
}

const THROAT = {
  id: 6,
  isMotor: false,
  color: 'b',
  gates: [45, 12, 35, 56, 23, 62, 16, 31, 20, 8, 33],
  name: 'THROAT',
  icon: 'throat',
  slug: 'throat-center'
}

const AJNA = {
  id: 7,
  isMotor: false,
  color: 'g',
  gates: [43, 11, 4, 24, 47, 17],
  name: 'AJNA',
  icon: 'ajna',
  slug: 'ajna-center'
}

const HEAD = {
  id: 8,
  isMotor: false,
  color: 'y',
  gates: [64, 61, 63],
  name: 'HEAD',
  icon: 'head',
  slug: 'head-center'
}

const getById = (id) => {
  switch (id) {
    case 0:
      return ROOT
    case 1:
      return SACRAL
    case 2:
      return SPLEEN
    case 3:
      return SOLAR_PLEXUS
    case 4:
      return HEART
    case 5:
      return G
    case 6:
      return THROAT
    case 7:
      return AJNA
    case 8:
      return HEAD
    default:
      return null
  }
}

const centersList = [ROOT, SACRAL, SPLEEN, SOLAR_PLEXUS, HEART, THROAT,G, AJNA, HEAD];
export default { getById, ROOT, SACRAL, SPLEEN, SOLAR_PLEXUS, HEART, G, THROAT, AJNA, HEAD,centersList, centerDefinitionStates }
