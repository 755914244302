<template>
  <div id="add-new-connection">
    <mbg-chart-picker v-model="chart1" show-recents :title="$t('pages.newChart.selectFirstChart')"/>

    <mbg-chart-picker v-model="chart2" :title="$t('pages.newChart.selectSecondChart')"
                      class="mt-4"
                      show-recents
                      :validation="v$.chart2"
    />

    <mbg-text-input v-model="name"
                    type="text"
                    :title="$t('properties.chartName')"
                    :placeholder="$t('general.enterName')"
                    input-classes="new-chart-input"
                    class="mt-4"
                    :validation="v$.name"/>

    <div class="mt-4 w-xl-50">
      <b-button variant="primary" block @click="createChart(true)" :disabled="hasErrors || !fieldsFilled">{{ $t('pages.newChart.create') }}</b-button>

    </div>

  </div>
</template>


<script>

import MbgChartPicker from "../elements/MbgChartPicker";
import MbgTextInput from "../elements/MbgTextInput";
import useVuelidate from "@vuelidate/core";
import {helpers} from "@vuelidate/validators";
import ChartCreationMixin from "@/mixins/ChartCreationMixin";
import ChartPageMixin from "@/mixins/ChartPageMixin";
const checkUnique = (data, siblings) => {
  return !data || data.id !== siblings.chart1.id;
}

export default{

  components:{MbgChartPicker, MbgTextInput},
  mixins: [ChartCreationMixin, ChartPageMixin],
  props:{
    cancelRedirect:{
      type: Boolean,
      default: false
    },
    hidePreviews: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  computed:{
    hasErrors(){
      return this.v$ ? this.v$.$errors.length > 0 : false
    },

    fieldsFilled(){
      return this.chart1 && this.chart2
    }
  },
  validations:{
    chart2: {
      checkUnique: helpers.withMessage(()=> {return 'errors.notSameChart'}, checkUnique),
      $autoDirty: true
    }
  },
  data(){
    return{
      chart1: null,
      chart2: null,
      name: null,
      updating: false
    }
  },
  watch:{

    updating(val){
      this.$emit('updating', val);
    },
    chart1(){
      this.handleChartSelection();
    },
    chart2(){
      this.handleChartSelection();
    }
  },

  methods:{
    handleChartSelection(){
      if (this.hidePreviews) return;
      if(this.chart1 && this.chart2 && this.chart1.id !== this.chart2.id) {
        this.name = `${this.chart1.name} & ${this.chart2.name}`;
        this.createChart(false);
      } else if (this.chart1 || this.chart2) {
        // fetch the chart that is selected so we can display it on the right.
        this.fetchDoc(this.chart1 ? this.chart1.id : this.chart2.id, false).then(chart=> {
          this.$store.dispatch('charts/setChart', chart);
        }).catch(()=>{});
      }
    },
    createChart(autosave = true) {
      const data = this.getData(autosave);
      if(autosave) this.updating = true;
      this.createConnectionChart(data).then((chart)=>{

        if(!this.cancelRedirect && autosave){
          this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.created')});
          this.redirectTo('single-chart', null, {chartId: chart.id});
        }
        if (!autosave) this.$store.dispatch('charts/setChart', chart);
        else this.$emit('chartCreated', chart);
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(()=>{
        this.updating = false;
      });

    },

    getData (autosave = true) {
      if (this.v$.$invalid) return null

      return {
        docIds: [this.chart1.id, this.chart2.id],
        name: this.name ? this.name : `${this.chart1.name} & ${this.chart2.name}`,
        autosave
      }
    }
  }

}
</script>