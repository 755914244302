<template>

  <div id="header-popup">

    <div class="popup-content" >

      <div class="chart-area">
        <h4>
          {{ $t('general.hi') }} {{user.fullName}} 👋
        </h4>

        <bodygraph
            class="mx-auto w-50 d-block py-3"
            id="bodygraph"
            ref="bodyGraph"
            :chart="$store.state.charts.personalChart"
            dom-ref-id="bodygraph"
            :interactive="false"
        />
        <b-button variant="primary" @click="redirectTo('single-chart', null, {chartId: $store.state.charts.personalChart.id})">{{ $t('pages.account.settings.openChart') }}</b-button>

      </div>

      <div class="menu-area mt-3">
        <p class="menu-title">Your Menu</p>
        <div class="row align-items-center">
          <div class="col-6 mt-2">

            <b-button variant="white" block @click="redirectTo('account', null, null, {tab: 'settings'})">
              <tab-title icon="icon-settings" :title="$t('pages.account.settings.title')" :subtitle="$t('pages.account.settings.subtitle')"/>
            </b-button>

          </div>
          <div class="col-6 mt-2">
            <b-button variant="white" block @click="redirectTo('account', null, null, {tab: 'billing'})">
              <tab-title icon="icon-cash" :title="$t('pages.account.billing.title')" :subtitle="$t('pages.account.billing.subtitle')"/>
            </b-button>
          </div>
          <div class="col-6 mt-2">
            <b-button variant="white" block @click="openHelpCenter">
              <tab-title icon="icon-help" :title="$t('pages.account.billing.help.title')" :subtitle="$t('pages.account.billing.help.subtitle')"/>
            </b-button>
          </div>
          <div class="col-6 mt-2">
            <b-button variant="white" block @click="openAffiliateMarketing">
              <tab-title icon="icon-affiliate" :title="$t('pages.account.affiliate.title')" :subtitle="$t('pages.account.affiliate.subtitle')"/>
            </b-button>
          </div>
        </div>
        <b-button variant="link" class="mt-4" @click="$emit('logout')">Logout</b-button>
        <Copyright />
      </div>
    </div>

    <div class="popup-backdrop" @click="$emit('closePopup')" />
  </div>
</template>

<style lang="scss">
.popup-backdrop{
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  background:rgba(0,0,0,0.2);
  z-index:1;
}
.theme--light{
  #header-popup .popup-content{
    background: #FFFFFF;
    box-shadow: 0px 20px 40px rgba(24, 28, 36, 0.1);
  }
}
.theme--dark{
  #header-popup .popup-content{
    background: linear-gradient(0deg, #434343, #434343), linear-gradient(0deg, #2F2F2F, #2F2F2F), #191C23;
    box-shadow: 0px 20px 40px rgba(24, 28, 36, 0.1);
  }
}
#header-popup{
  position:fixed;
  width:38vw;

  #bodygraph{
    max-height:250px;
  }

  max-width:550px;
  top:80px;
  right:30px;
  text-align:center;
  z-index:5;


  .popup-content{
    padding: 30px 25px;
    border-radius: 20px 10px;
    z-index:3;
    position:relative;
    max-height:90vh;
    overflow-y:auto;
  }

  .menu-title{
    font-weight:300;
  }
}
</style>
<script>
import TabTitle from "../elements/TabTitle";
import bodygraph from "../../components/charts/bodygraph"
import Copyright from "../auth/Copyright.vue";
export default{
  components:{Copyright, TabTitle, bodygraph},
  props:{
    user: Object,
  },
  methods:{
    openHelpCenter() {
      window.open('https://help.mybodygraph.com/hc/en-us', '_blank');
    },
    openAffiliateMarketing() {
      window.open('https://mybodygraph.firstpromoter.com', '_blank');
    },
  }
}
</script>