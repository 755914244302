export default `<svg viewBox="0 0 612 612" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <path d="M573.75,267.75C573.75,119.886,453.883,0,306,0S38.25,119.886,38.25,267.75
    c0,92.749,47.178,174.479,118.821,222.515c-22.001,10.066-37.279,32.236-37.279,57.992c0,35.205,28.538,63.743,63.763,63.743
    c35.188,0,63.744-28.538,63.744-63.743c0-25.886-15.446-48.148-37.596-58.142c-28.053-12.159-53.267-29.322-75.006-51.063
    c-22.263-22.263-39.707-48.168-51.922-77.005c-12.624-29.863-19.012-61.576-19.012-94.298c0-32.703,6.388-64.435,19.013-94.28
    c12.215-28.856,29.659-54.761,51.922-77.004c22.263-22.263,48.186-39.744,77.022-51.94c29.846-12.626,61.559-19.013,94.28-19.013
    s64.435,6.388,94.28,19.013c28.837,12.196,54.761,29.677,77.023,51.94c22.263,22.244,39.707,48.148,51.922,77.004
    c12.626,29.846,19.013,61.578,19.013,94.28c0,32.722-6.388,64.435-19.013,94.3c-12.215,28.837-29.659,54.741-51.922,77.004
    c-21.74,21.74-46.953,38.941-74.986,51.081c-22.133,10.011-37.56,32.254-37.56,58.122c0,35.205,28.558,63.743,63.763,63.743
    c35.206,0,63.763-28.538,63.763-63.743c0-25.775-15.315-47.962-37.316-58.01C526.592,442.191,573.75,360.499,573.75,267.75z
     M221.806,548.257c0,21.086-17.164,38.25-38.25,38.25c-21.104,0-38.25-17.164-38.25-38.25c0-21.087,17.146-38.25,38.25-38.25
    C204.641,510.007,221.806,527.17,221.806,548.257z M466.77,548.257c0,21.086-17.163,38.25-38.25,38.25s-38.25-17.164-38.25-38.25
    c0-21.087,17.163-38.25,38.25-38.25S466.77,527.17,466.77,548.257z"/>
</svg>`