export default{

    tabs:{
        transit: 'Transit',
        ephemeris: 'Ephemeris',
        activationWatch: 'Activation Watch',
        planetCycle: 'Planet Cycle',
        changes: 'Changes'
    },
    current: 'Current',
    next:'Next',
    previous: 'Previous',
    activated: 'Activated',
    nextShift: 'Next Shift',
    nextLineShift: 'Next Line Shift',
    nextGateShift: 'Next Gate Shift',
    duration: 'Duration of Activation'
}