<template>
  <svg width="220" height="293" viewBox="0 0 281 374" fill="none" xmlns="http://www.w3.org/2000/svg">
    <!-- The title -->
    <rect x="25" width="231" height="26" rx="5" class="casual-fill"/>
    <text
          :class="orientation === 0 ? 'fill-design' : 'fill-personality rev'"
          x="140" y="16.804" font-size="12" class="text-uppercase"
          text-anchor="middle" font-weight="900" letter-spacing="0em">
      {{ $t('hd.properties.' + (orientation === 0 ? 'design' : 'personality')) }}
    </text>

    <advanced-arrows-box :is-left="getColorToneData.sun.isLeft"
                         :tone="getColorToneData.sun.value"
                         :is-up="getColorToneData.earth.isUp"
                         :color="getColorToneData.earth.value"
                         :orientation="orientation"
                         :x="orientation === 0 ? 25 : 220 - 39"
                         y="36"
    />

    <advanced-arrows-box v-if="viewMode !== 'cross'" :is-left="getColorToneData.northNode.isLeft"
                         :tone="getColorToneData.northNode.value"
                         :is-up="getColorToneData.southNode.isUp"
                         :color="getColorToneData.southNode.value"
                         :orientation="orientation"
                         :x="orientation === 0 ? 25 : 220 - 39"
                         y="97"
    />



    <!--
      left box: x = 25 | middle box x = 103 | right box = 181

      first row y = 36 | second row y = 97
    -->
    <g v-for="(planet, index) in getPlanets"
         :id="`planet-${orientation}-${planet.planet}-${planet.chartId}`"
         :key="`planet-table-${orientation}-${planet.planet}`"
         class="px-1 py-2 planet-box advanced d-flex mx-auto align-items-center position-relative cursor-pointer"
         @click="onElementClicked(planet.planet)"
         @mouseover="planetHighlight(planet.planet, orientation, [planet.gate], planet.chartId)"
         @mouseleave="clearAllHighlights">

        <advanced-box
            :order="index"
            :x="planet.x"
            :y="planet.y"
            :planet="planet"
            :is-moon="planet.isMoon"
        />
    </g>


</svg>



</template>
<script>
import planetConstants from "@/helpers/rave/constants/planets";
import {mapState} from "vuex";
import util from "@/mixins/util";
import fixings from "@/helpers/rave/constants/fixings";
import { getBaseAlignmentSymbols } from "@/helpers/charts";
import HighlightingMixin from "@/mixins/HighlightingMixin";
import AdvancedBox from "./advanced/AdvancedBox.vue";
import AdvancedArrowsBox from "./advanced/AdvancedArrowsBox.vue";

export default{
  components: {AdvancedArrowsBox, AdvancedBox},

  mixins: [util, HighlightingMixin],

  props:{
    orientation: {
      type: Number,
      default: 0
    },
    height:{
      type: Number,
      default: 600
    },
    interactive: Boolean,
    chart: Object,
    getColorArrow: Function,
    getToneArrow: Function
  },
  computed:{
    ...mapState({
      viewMode: state => state.ui.viewMode,
      viewModes: state => state.ui.viewModes
    }),

    activation(){
      return this.orientation === 0 ? 'design' : 'personality'
    },

    getActivationColor(){
      return this.activation === 'design' ? '#c25354' : (this.darkMode ? '#ffffff' : '#434343')
    },

    getPlanetClasses(){
      let classes = this.activation === 'design' ? 'bg-design' : 'bg-personality';

      return classes;
    },

    getColorToneData() {
      const colorEarth = this.getPlanets.find(x => x.planet === planetConstants.EARTH)?.color;
      const colorSouthNode = this.getPlanets.find(x => x.planet === planetConstants.SOUTH_NODE)?.color;

      const toneSun = this.getPlanets.find(x => x.planet === planetConstants.SUN)?.tone;
      const toneNorthNode = this.getPlanets.find(x => x.planet === planetConstants.NORTH_NODE)?.tone;

      // < 4 ? 'up' : 'down'
      return {
        sun: {
          value: toneSun,
          isLeft: toneSun < 4
        },
        earth: {
          value: colorEarth,
          isUp: colorEarth < 4
        },
        northNode: {
          value: toneNorthNode,
          isLeft: toneNorthNode < 4
        },
        southNode: {
          value: colorSouthNode,
          isUp: colorSouthNode < 4
        }
      }
    },
    getPlanets(){
      const personalityIds = [
        planetConstants.EARTH, planetConstants.SUN,
        planetConstants.SOUTH_NODE, planetConstants.NORTH_NODE,
        planetConstants.MOON, planetConstants.MERCURY, planetConstants.URANUS,
        planetConstants.VENUS, planetConstants.MARS, planetConstants.NEPTUNE,
        planetConstants.SATURN, planetConstants.JUPITER, planetConstants.PLUTO];

      const designIds = [
        planetConstants.SUN, planetConstants.EARTH,
        planetConstants.NORTH_NODE, planetConstants.SOUTH_NODE,
        planetConstants.URANUS, planetConstants.MERCURY,planetConstants.MOON,
        planetConstants.NEPTUNE, planetConstants.MARS, planetConstants.VENUS,
        planetConstants.PLUTO, planetConstants.JUPITER, planetConstants.SATURN ];

      let ids = this.activation === 'design' ? designIds : personalityIds;

      if(this.viewMode === this.viewModes.cross) {
        ids = ids.filter(x => x === planetConstants.SUN || x === planetConstants.EARTH)
      }

      let tables = [];
      let totalRows = 0;
      for(const [idx, id] of ids.entries()){
        let index = tables.length + 1;
        const planet = this.planets.find(x => x.planet === id && x.activation === (this.activation === 'design' ? 0 : 1));
        let x;
        const y = 36 + (61 * (totalRows));

        if (planet.activation === 0) {
          if (idx === 0) x = 103;
          if (idx === 1){
            x = 181;
            totalRows++;
          }
          if (idx === 2) x = 103;
          if (idx === 3){
            x = 181;
            totalRows++;
          }
          if (idx === 4) x = 25;
          if (idx === 5) x = 103;
          if (idx === 6){
            x = 181;
            totalRows++;
          }
          if (idx === 7) x = 25;
          if (idx === 8) x = 103;
          if (idx === 9){
            x = 181;
            totalRows++;
          }
          if (idx === 10) x = 25;
          if (idx === 11) x = 103;
          if (idx === 12){
            x = 181;
            totalRows++;
          }
        } else {
          if (idx === 0) x = 25;
          if (idx === 1){
            x = 103;
            totalRows++;
          }
          if (idx === 2) x = 25;
          if (idx === 3){
            x = 103;
            totalRows++;
          }
          if (idx === 4) x = 25;
          if (idx === 5) x = 103;
          if (idx === 6){
            x = 181;
            totalRows++;
          }
          if (idx === 7) x = 25;
          if (idx === 8) x = 103;
          if (idx === 9){
            x = 181;
            totalRows++;
          }
          if (idx === 10) x = 25;
          if (idx === 11) x = 103;
          if (idx === 12){
            x = 181;
            totalRows++;
          }
        }

        tables.push({
          ...planet,
          index,
          isMoon: planet.planet === planetConstants.MOON,
          x,
          y,
          row: totalRows
        });
      }

      return tables;
    }
  },
  data(){
    return{
      planets: []
    }
  },
  created(){
    this.setupPlanetContent();
  },
  methods: {

    setupPlanetContent(){
      let planets = [];
      for(let planet of this.chart.chart.planets){

        let planetObject = {
          activation: planet.activation,
          planet: planet.id,
          isExalted: planet.fixing.state === 3 || planet.fixing.state === fixings.EXALTED,
          isDetriment: planet.fixing.state === 3 || planet.fixing.state === fixings.DETRIMENT,
          isStar: planet.fixing.state === fixings.JUXTAPOSED,
          name: this.$t('hd.planets.' + planet.id + '.name'),
          gate: planet.gate,
          line: planet.line,
          color: planet.color,
          tone: planet.tone,
          base: planet.base,
          baseAlignment: planet.baseAlignment,
          chartId: planet.chartId
        }
        planetObject.baseAlignmentSymbols = getBaseAlignmentSymbols(planetObject);

        planets.push(planetObject);
      }
      this.planets = planets;
    },


    onElementClicked (planetId) {
      if(!this.interactive) return;
      let activation = this.activation === 'design' ? 0 : 1;

      this.fixUrlQuery('?tab=planetary-activations' + '&selected='+planetId + '&activation=' + activation);
      // add the URL params so that the properties panel show the correct content

      this.$nextTick(()=>{
        this.$store.dispatch('ui/increasePropertiesKey'); // increase key to update window
        this.$store.commit('ui/SET_SHOW_PROPERTIES', true); // show panel in case it's not showing
      });
    }
  }
}
</script>