<template>
  <div class="tab-title align-items-center position-relative" :class="centered ? 'py-2 px-1': 'd-flex gap-10'">
    <span v-if="icon" :class="`icon ${icon} ${centered ? 'block mx-auto pb-3' : 'm-0'}`" class="tab-title-icon w-fit"/>
    <div>
      <div class="tab-title-content" :class="centered ? 'text-center pt-1' : ''">
        <h5 class="tab-title-heading mb-0">{{title}}</h5>
        <p class="tab-title-subtitle mb-0">{{subtitle}}</p>
      </div>
    </div>

    <div v-if="isLocked" style="position:absolute;top:-10px;right:2px;">
      <IconLock />
    </div>
    <div v-else-if="isCompleted" class="text-primary" style="position:absolute;top:-15px;right:4px;">
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor"
            viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
      </svg>
    </div>
  </div>

</template>

<script>
import IconLock from "../icons/IconLock.vue";

export default{
  components: {IconLock},

  props:{
    title: String,
    subtitle: String,
    icon: String,
    centered: {
      type: Boolean,
      default: false
    },
    isLocked:{
      type: Boolean,
      default: false
    },
    isCompleted: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss">
.tab-title{
  padding:0.2rem;
  position:relative;

  &.properties-tab-title{
    width:100px;
    @media(min-width:1240px){
      width:125px;
    }
    @media(min-width:1600px){
      width:150px;
    }
  }

  .tab-title-icon{
    font-size:15px;
  }
  .tab-title-content{
    text-align:left;
  }
  .tab-title-heading{
    font-size:10px;
  }
  .tab-title-subtitle{
    font-weight:300;
    font-size:0.7rem;
    opacity:0.6;
  }
}
</style>