/* eslint-disable */
import * as R from 'ramda'

const addAsociation = R.curry((src, targetId) => {
  const isSameNode = R.equals(src.id, targetId)

  const associationExists = R.includes(targetId, src.associations)

  const ok = !(isSameNode || associationExists)

  if (ok) {
    src.associations.push(targetId)
  }
})

const Node = id => {
  let node = {
    id,
    associations: []
  }

  return {
    id,
    associations: node.associations,
    addAssociation: addAsociation(node)
  }
}

export default Node
