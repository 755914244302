<template>

  <div id="transit-tool" class="tabs-primary-filled">
    <img :src="loadLocalIcon(`transit-tool--${getDarkModeText}.svg`)" class="w-100 transit-tool-core-image"/>

    <b-card>

      <div>
        <div v-if="hasTransitSubscription" class="row align-items-center mb-3 " style="margin-top:-30px;">
          <div class="col-6">
            <date-picker v-model="datePickerVal" :confirm="true" :format="'YYYY-MM-DD HH:mm'" :show-second="false"
                         type="datetime"
                         :clearable="false"
                         @confirm="getNewTransitChartWithDate"></date-picker>
          </div>
          <div class="col-6 text-right">
            <b-form-checkbox :checked="justNow" class="ml-auto justify-content-end pt-0" name="check-button" size="sm"
                             switch @input="val => changeJustNow(val)">
              Just Now Mode
            </b-form-checkbox>
          </div>
        </div>

        <div v-else class="px-3 mb-4">
          <p v-if="!showMore" v-html="$t('billing.products.subscriptions.transit_tool')"/>
          <div v-else v-html="$t('billing.products.long.transit_tool')"/>
          <b-button class="btn-primary-link" variant="link" @click="showMore = !showMore">
            {{ showMore ? $t('general.close') : $t('general.showMore') }}
          </b-button>
        </div>

        <b-tabs :key="$store.state.charts.transit.key" v-model="activeTab"
                class="transit-tool-base-tabs tabs-primary-underlined" fill justified lazy>

          <b-tab v-for="tab in tabs" :key="`transit-tab-${tab.id}`">

            <template #title>
              <TabTitle :centered="true" :icon="tab.icon" :title="tab.name"/>
            </template>

            <b-overlay :show="loading" rounded="sm">

              <component v-if="hasTransitSubscription" :is="tab.component" @loading="loadingChanged"/>

              <img v-else :src="`/${tab.promotion}--${darkMode ? 'dark' : 'light'}.gif`"/>
            </b-overlay>

          </b-tab>
        </b-tabs>
      </div>

      <locked-content v-if="!hasTransitSubscription"
                      class="pt-5 pb-4 px-3 px-md-5"
                      title="Access Transit Tools"
                      :subtitle="`Unlocks Ephemeris, Activation Watch, Changes and more ` +
                          (DISCOUNTS_ENABLED.transit ? $t('billing.unlockChartDiscount', { percentage: DISCOUNT_PERCENTAGE.transit}) : '')"
                      button-text="Unlock now"
                      @clickedUnlock="subscribe"
      />


<!--      <mobile-bottom-tabs :items="tabs" v-model="activeTab"></mobile-bottom-tabs>-->

    </b-card>


  </div>

</template>

<script>
import TabTitle from "../../elements/TabTitle";
import MobileBottomTabs from "../../structure/MobileBottomTabs";
import TransitToolMixin from "../../../mixins/TransitToolMixin";
import activationWatch from "./activation-watch";
// import planetCycle from "./planet-cycle";
import changes from "./changes";
import transits from "./transits";
import DatePicker from 'vue2-datepicker';
import moment from "moment";
import {mapState} from "vuex";

import timeline from "./timeline";
import LockedContent from "../../billing/LockedContent.vue";
import {DISCOUNT_PERCENTAGE, DISCOUNTS_ENABLED} from "../../../helpers/discounts";

export default {

  mixins: [TransitToolMixin],
  components: {LockedContent, TabTitle, MobileBottomTabs, DatePicker},

  computed: {
    DISCOUNT_PERCENTAGE() {
      return DISCOUNT_PERCENTAGE
    },
    DISCOUNTS_ENABLED() {
      return DISCOUNTS_ENABLED
    },
    ...mapState({
      key: state => state.charts.transit.key,
      subscriptions: state => state.account.subscriptions,
    }),

    hasTransitSubscription() {
      return !!this.subscriptions.find(x => x.products.find(y => y.key === 'transit_tool'));
    },
  },
  watch: {
    key() {
      this.initializeDatePicker();
    }
  },
  data() {
    return {
      showMore: false,
      activeTab: 0,
      datePickerVal: null,
      loading: false,
      tabs: [
        {
          id: 0,
          icon: 'icon-transit',
          promotion: 'transit-transit',
          component: transits,
          name: this.$t('charts.transit.tabs.transit')
        },
        {
          id: 1,
          icon: 'icon-ephemeris',
          promotion: 'transit-ephemeris',
          component: timeline,
          name: this.$t('charts.transit.tabs.ephemeris')
        },
        {
          id: 2,
          icon: 'icon-activation-watch',
          promotion: 'transit-activation-watch',
          component: activationWatch,
          name: this.$t('charts.transit.tabs.activationWatch')
        },
        // {id: 3, icon: 'icon-globe', component: planetCycle, name: this.$t('charts.transit.tabs.planetCycle')},
        {
          id: 4,
          icon: 'icon-changes',
          promotion: 'transit-changes',
          component: changes,
          name: this.$t('charts.transit.tabs.changes')
        }
      ]
    }
  },
  created() {
    if (!this.hasTransitSubscription) return;
    this.getChanges();

    this.initializeDatePicker();
    // this.datePickerVal = this.$store.getters['charts/transitUtcDate'];
  },
  methods: {
    subscribe(){
      this.$store.commit('checkout/setCheckoutType', this.CheckoutTypes.SUBSCRIPTION);
      this.$store.commit('checkout/addSubscriptionCartItem',
          this.$store.state.checkout.products.subscriptions.find(x => x.key === 'transit_tool'));
      this.$router.push('/checkout')
    },
    initializeDatePicker() {
      this.datePickerVal = moment(this.$store.getters['charts/transitUtcDate']).toDate()
    },
    loadingChanged(val) {
      this.loading = val;
    },

    getNewTransitChartWithDate() {
      let dateObj = moment(this.datePickerVal).utc();
      this.changeTransitTimestamp(dateObj);
    }
  }
}
</script>

<style lang="scss">


.mx-icon-calendar, .mx-icon-clear {
  margin-top: 3px;
}

.theme--dark {
  .mx-icon-calendar, .mx-icon-clear {
    color: white;
  }
}

#transit-tool {

  .transit-tool-core-image {
    margin-top: -50px;
    margin-bottom: -70px;
    @media(max-width: 767px) {
      margin-bottom: -15px;
    }
  }

  .transit-tool-base-tabs {
    .nav-item {
      .tab-title-heading {
        font-size: 0.75rem !important;
        font-weight: 400;
      }

      .nav-link {
        opacity: 30%;

        &.active {
          opacity: 100%;
        }
      }


      //min-width:100px;
    }
  }

}
</style>