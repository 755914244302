<template>
  <b-button :variant="variant" class="text-left"
            @click="$emit('click')">
    <div class="d-flex align-items-center gap-5 w-100">
      <div class="mr-2">
        <span class="text-1xl" :class="`${icon}`" />
      </div>
      <div>
        <span class="mbg-text-muted text-sm d-block">
          {{subtitle}}</span>
        <span class="font-500">
          {{title}}
                  </span>
      </div>
    </div>
  </b-button>
</template>

<script>

export default{

  props:{
    callback: Function,
    title: String,
    subtitle: String,
    icon: String,
    variant: String
  }
}
</script>