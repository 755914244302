<template>
  <div>
    <dynamic-zone-renderer v-if="crossIntro"  :is-toggleable="true" :title="crossIntro.name" :components="crossIntro.components" :add-paddings="false"></dynamic-zone-renderer>
    <dynamic-zone-renderer v-if="angleContent" :title="angleContent.name" :components="angleContent.components" :add-paddings="false" is-toggleable/>

    <dynamic-zone-renderer v-if="cross" :type="$t('hd.properties.cross')" :title="cross.name" :components="cross.components" :add-paddings="false"/>

    <dynamic-zone-renderer v-if="quarter"  :is-toggleable="true" :type="$t('hd.properties.quarter')" :title="quarter.name" :components="quarter.components"
                           :add-paddings="false"></dynamic-zone-renderer>
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../../../renderer/DynamicZoneRenderer.vue";
import {findQuarterByGate} from "../../../../../helpers/rave/constants/quarters";
import planetConstants from "../../../../../helpers/rave/constants/planets";
import activationModes from "../../../../../helpers/rave/constants/activationModes";
import AnalysisQueryMixin from "../../../../../mixins/AnalysisQueryMixin";
import {crosses} from "../../../../../helpers/rave/constants/crosses";

export default {
  mixins: [AnalysisQueryMixin],
  components: {DynamicZoneRenderer},

  props:{
    chart: Object
  },

  computed:{
    angle(){
      if(!this.chart || !this.chart.chart) return;
      return crosses.find(x => x.id === this.chart.chart.cross);
    }
  },

  data(){
    return {
      cross: null,
      crossIntro: null,
      quarterId: null,
      quarter: null,
      angleContent: null,
    }
  },

  created(){
    // we find the Quarter of a chart by the PERSONALITY SUN gate.
    this.quarterId = findQuarterByGate(this.chart.chart.planets.find(x => x.id === planetConstants.SUN && x.activation === activationModes.PERSONALITY).gate);
    this.getCross();
    this.getCrossIntroduction();
    this.getAngle();
    this.getQuarter();
  },

  methods:{
    getCross(){
      let url = '/crosses/' +  this.chart.chart.cross;
      this.getContent('cross', url).then(() => {
        if(this.cross.small_description){
          this.cross.components.push({
            __component: 'dropzone.dynamic-text',
            content: `<p>${this.cross.small_description}</p>`
          })
        }

      });

    },
    getCrossIntroduction(){
      let url = '/analysis/introductions/introduction.crosses';
      this.getContent('crossIntro', url);
    },
    getAngle(){
      let angles = {
        0: 'introduction.left-angle',
        1: 'introduction.right-angle',
        2: 'introduction.juxtaposition'
      }
      let url = `/analysis/introductions/${angles[this.angle.orientation]}`;
      this.getContent('angleContent', url);
    },
    getQuarter(){
      let url = '/quarters/' +  this.quarterId
      this.getContent('quarter', url);
    },
  }
}
</script>