<template>
  <div id="advanced-analysis-wrapper">
      <b-tabs :value="advancedTab"
              @input="(id) => setTab('advanced_tab', id)"
              class="vertical-side-tabs mobile-hidden" pills card vertical end nav-wrapper-class="tabs-panel" lazy>

        <b-tab v-for="(tab, index) in tabs" :key="`advanced-tab-${tab.id}`">

          <template #title>
            <TabTitle :title="tab.name" :icon="tab.icon" :centered="true"
                      :is-locked="!isUnlocked && index !== 0"
                      :is-completed="isTabSectionCompleted('advanced', tab.id)"
            />
          </template>

          <b-overlay :show="loading" rounded="sm">
          <b-card class="px-0 pt-4 analysis-card">
            <component :is="tab.component"
                       :has-prev="index > 0"
                       :has-next="index < tabs.length - 1"
                       :total-tabs="tabs.length"
                       @prevSection="setTab('advanced_tab', index-1, true)"
                       @nextSection="setTab('advanced_tab', index+1)"
                       @loading="loadingChanged"
                       @innerTabChanged="innerTabChanged">
              <template v-if="!isUnlocked" #unlock-prompt>
                <unlock-content-prompt v-if="!isUnlocked" category="advanced" :chart-id="$store.state.charts.chart.id"/>
              </template>
            </component>


          </b-card>
          </b-overlay>

        </b-tab>
      </b-tabs>
    <mobile-bottom-tabs :items="tabs" :value="advancedTab"
                        @input="(id) => setTab('advanced_tab', id)" />
  </div>
</template>

<script>
import TabTitle from "../../../elements/TabTitle";
import reachPotential from "./reach-potential";
import nourish from "./nourish";
import path from "./path";
import vision from "./vision";
import awareness from "./awareness";
import MobileBottomTabs from "../../../structure/MobileBottomTabs";
import variable from "./variable";
import UnlockContentPrompt from "../../../billing/UnlockContentPrompt";
import AnalysisRouterMixin from "../../../../mixins/AnalysisRouterMixin";
export default{

  mixins: [AnalysisRouterMixin],

  components:{UnlockContentPrompt, TabTitle, MobileBottomTabs},
  watch: {
    advancedTab(val){
      this.fixQuery('advanced_tab', val)
    }
  },
  created(){
    this.setup('advanced_tab')
  },

  computed:{
    isUnlocked(){
      return this.$store.state.charts.chartStatus.advanced
    }
  },
  data(){
    return{
      loading: false,
      tabs: [
        {id: 0, icon: 'icon-power-user', component: reachPotential, name: this.$t('charts.analysis.advanced.tabs.0')},
        {id: 1, icon: 'icon-brain', component: nourish, name: this.$t('charts.analysis.advanced.tabs.1')},
        {id: 2, icon: 'icon-path', component: path, name: this.$t('charts.analysis.advanced.tabs.2')},
        {id: 3, icon: 'icon-vision', component: vision, name: this.$t('charts.analysis.advanced.tabs.3')},
        {id: 4, icon: 'icon-awareness', component: awareness, name: this.$t('charts.analysis.advanced.tabs.4')},
        {id: 5, icon: 'icon-variable', component: variable, name: this.$t('charts.analysis.advanced.tabs.5')}]
    }
  },
  methods:{
    loadingChanged(val){
      this.loading = val;
    }
  }
}
</script>

<style lang="scss">
</style>