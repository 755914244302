<template>

  <div class="row mb-4 align-items-center">
    <div class="col-12 mt-0">

      <mbg-text-input v-model="search" :debounce="500"
                      :placeholder="$t('owl.dictionary.searchPlaceholder')"
                      :show-label="true"
                      icon="icon-search"
                      name="search-dictionary" type="search"></mbg-text-input>
      <!--      <b-form-input type="text" v-model="search" name="search-dictionary"-->
      <!--                    debounce="500" :placeholder="$t('owl.dictionary.searchPlaceholder')" :show-label="true"></b-form-input>-->
    </div>
    <div class="col-12 mt-0 mt-md-1">

      <div v-show="search && search !== '' && results.length === 0 && !loading" class="mb-4">
        {{ $t('owl.dictionary.noResults') }}
      </div>

      <div v-show="results.length > 0" id="dictionary-results-wrapper" class="col-12 dictionary-results-wrapper">

        <b-card v-for="result in results" :key="result.slug" class="my-3">
          <h5>{{ result.title }}</h5>
          <div class="dictionary-result-content pt-3" v-html="result.content"></div>
        </b-card>


      </div>

      <div class="mt-3 text-right">
        <b-pagination v-if="!search || search === ''" v-model="page" :total-rows="totalRows" align="right"/>
      </div>

      <div v-if="lastSearches.length > 0" class="mt-3">
        <label>{{ $t('owl.dictionary.lastSearches') }}</label>

        <div class="mt-3 d-flex flex-wrap">
          <span v-for="item in lastSearches" :key="item" class="pr-3 cursor-pointer text-primary"
                @click="search = item">{{ item }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import util from "../../../mixins/util";
import MbgTextInput from "../../elements/MbgTextInput.vue";

export default {
  components: {MbgTextInput},

  mixins: [util],
  watch: {
    search(val) {
      this.clearClickEventListeners();
      if (!val || val === '') {
        this.fixUrlQuery('');
        this.results = [];
        this.searchDictionary();
      } else {
        this.page = 1;
        this.saveToRecentSearches(val);
        this.fixUrlQuery('?d=' + val)
        this.searchDictionary();
      }
      this.isExact = false; // initialize is exact. We only use it if we are clicking a link!
      // this.getDictionaryContent();
    },
    page() {
      this.searchDictionary();
    }
  },

  data() {
    return {
      loaded: false,
      loading: false,
      activeTerm: null,
      search: null,
      lastSearches: [],
      textLinksFound: [],
      results: [],
      isExact: false,
      page: 1,
      totalRows: 0,
    }
  },
  mounted() {
    if (localStorage.getItem('dictionary.lastSearches')) {
      this.lastSearches = JSON.parse(localStorage.getItem('dictionary.lastSearches'));
    }
    this.searchDictionary();

  },

  methods: {

    saveToRecentSearches(val) {

      this.lastSearches = this.lastSearches.filter(x => x !== val)
      this.lastSearches.unshift(val);
      this.lastSearches = this.lastSearches.slice(0, 20);
      localStorage.setItem('dictionary.lastSearches', JSON.stringify(this.lastSearches))
    },


    // add link event listeners to prevent navigation!
    addLinkHandlers() {
      let links = document.querySelectorAll('.dictionary-result-content a');

      for (let link of links) {
        if (link.search.includes('?d=')) {
          this.textLinksFound.push(link);
          link.addEventListener('click', this.handleLinkClick)
        }
      }
    },

    handleLinkClick(e) {
      e.preventDefault();

      let query = e.target && e.target.search ? e.target.search.replace('?d=', '') : null;
      if (query) {
        this.search = query;
        this.isExact = true;
      }
    },

    // clears event listeners for links
    clearClickEventListeners() {
      for (let link of this.textLinksFound) {
        link.removeEventListener('click', this.handleLinkClick);
      }
    },

    searchDictionary() {
      this.loading = true;
      this.results = [];

      this.axios.get('/content/dictionary/search', {
        params: {
          search: this.search,
          is_exact: this.isExact || undefined,
          page: this.page
        }
      }).then(res => {
        this.results = res.data.rows;
        this.totalRows = res.data.count;
        this.$nextTick(() => {
          this.addLinkHandlers();
          document.getElementById('dictionary-results-wrapper').scroll({
            top: 0
          })
        })
      }).catch((e) => {
        console.error(e);
        this.$notify({
          type: 'error',
          title: 'Something went wrong',
          text: 'Something went wrong with the request. Please try again later.'
        });
      }).finally(() => {
        this.loading = false;
      })
    }
  }
}
</script>