<template>
  <div id="checkout" class="below-header">
    <div v-if="!checkoutCompleted" class="container mt-4 mb-5">
      <h2>{{$t('billing.checkout.title')}}
        <b-button v-b-tooltip.hover
                  :title="$t('billing.checkout.info')">
          <span class="icon-info-outline"/>
        </b-button>
      </h2>

      <b-tabs v-if="loaded" v-model="tabId" class="tabs-primary-underlined light-tab-titles mt-4" lazy>
        <b-tab :title="`${$t('billing.checkout.tabs.unlock')} (${single_cart_items.length})`">
          <single-unlock />
        </b-tab>
        <b-tab :title="`${$t('billing.checkout.tabs.subscription')} (${subscription_cart_items.length})`">
          <subscription-unlock />
        </b-tab>

      </b-tabs>
    </div>
    <ThankYouPage v-else/>
    <checkout-sidebar/>
  </div>

</template>

<script>
import {mapState} from "vuex";
import SingleUnlock from "../../components/billing/cart/SingleUnlock";
import ThankYouPage from "../../components/billing/ThankYouPage.vue";
import SubscriptionUnlock from "../../components/billing/cart/SubscriptionUnlock.vue";
import CheckoutSidebar from "../../components/structure/checkout/CheckoutSidebar.vue";
import AnalyticsMixin from "@/mixins/AnalyticsMixin";

export default{
  mixins: [AnalyticsMixin],
  components: {CheckoutSidebar, SubscriptionUnlock, ThankYouPage, SingleUnlock},

  computed:{
    ...mapState({
      checkout_type: state => state.checkout.checkout_type,
      products: state => state.checkout.products,
      single_cart_items: state => state.checkout.single_cart_items,
      subscription_cart_items: state => state.checkout.subscription_cart_items,
      lastVisitedChart: state => state.ui.lastVisitedChart,
      checkoutCompleted: state => state.checkout.checkoutCompleted
    })
  },
  beforeDestroy() {
    this.$store.commit('checkout/SET_CHECKOUT_COMPLETED', false);
  },
  watch:{
    tabId(val){
      if(!this.loaded) return;
      this.$store.commit('checkout/setCheckoutType', val);
    },
  },
  data(){
    return{
      loaded: false,
      thankYouPage: false,
      tabId: this.checkout_type
    }
  },

  // On creation, we want to reload all checkout-specific data.
  created(){
    this.$store.dispatch('checkout/loadProducts');
    this.$store.dispatch('account/getUserSubscriptions');
    this.$store.dispatch('account/getUserUnlockedCharts');
    this.facebookEvent('InitiateCheckout');
  },
  mounted(){
    this.tabId = this.checkout_type;
    if(this.$route.query.tab === 'single'){
      this.tabId = 0;
    }
    if (this.$route.query.tab === 'subscription') {
      this.tabId = 1;
    }
    this.loaded = true;
  }
}
</script>