<template>

  <div id="chart-properties-wrapper" class="unselectable">

<!--    <div class="position-fixed chart-properties-backdrop" :class="showProperties ? 'backdrop-visible' : 'backdrop-hidden'" @click.self="closeProperties"></div>-->

    <div id="chart-properties" :class="slideAnimationClasses">
      <div class="row mb-2 align-items-center">
        <div class="col-6">
          <h4>{{$t('charts.properties.title')}}</h4>
        </div>
        <div class="col-6 text-right">
          <b-button variant="link" class="d-flex align-items-center ml-auto animate-icon-on-hover"
                    @click="closeProperties">
            {{$t('charts.properties.close')}} <span class="icon-close ml-3"></span>
          </b-button>
        </div>
      </div>
      <b-tabs :key="propertiesKey" v-if="loaded" v-model="currentHash" class="chart-property-tabs tabs-primary-underlined" fill @input="changedTab" lazy>
        <b-tab>
          <template #title>
            <TabTitle :title="$t('charts.properties.summary')" :centered="true" class="justify-content-center properties-tab-title" icon="icon-summary"/>
          </template>

          <Summary :chart="chart" class="chart-property-tab" :class="isUnlocked ? 'unlocked' : ''"></Summary>
        </b-tab>
        <b-tab>
          <template #title>
            <TabTitle :title="$t('charts.properties.planetary')" :centered="true" class="justify-content-center properties-tab-title" icon="icon-planetary-activations"/>
          </template>

          <planetary-activations class="chart-property-tab" :class="isUnlocked ? 'unlocked' : ''"/>
        </b-tab>
        <b-tab>
          <template #title>
            <TabTitle :title="$t('hd.properties.centers')" :centered="true" class="justify-content-center properties-tab-title" icon="icon-centers"/>
          </template>
          <centers class="chart-property-tab" :class="isUnlocked ? 'unlocked' : ''"/>
        </b-tab>
        <b-tab>
          <template #title>
            <TabTitle :title="$t('hd.gates.name.many')" :centered="true" class="justify-content-center properties-tab-title" icon="icon-gate"/>
          </template>
          <gates class="chart-property-tab" :class="isUnlocked ? 'unlocked' : ''"/>
        </b-tab>
        <b-tab>
          <template #title>
            <TabTitle :title="$t('hd.properties.channels')" :centered="true" class="justify-content-center properties-tab-title" icon="icon-channels"/>
          </template>
          <channels class="chart-property-tab" :class="isUnlocked ? 'unlocked' : ''" show-grouped />
        </b-tab>
      </b-tabs>
    </div>
  </div>

</template>
<style lang="scss">
#chart-properties-wrapper{
  .chart-properties-backdrop{
    z-index:3;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0,0,0,0.6);
    transition:visibility 0.3s linear,opacity 0.3s linear;
    visibility: hidden;
    opacity:0;
    &.backdrop-visible{
      visibility: visible;
      opacity:1
    }
  }
}
#chart-properties{
  position:fixed;
  padding:15px;
  z-index:1000;
  right:0;
  top:0;
  bottom:0;
  width:100vw;
  transform:translateX(110%);
  overflow-x:hidden;

  @media(min-width:767px){

    border-top-left-radius: 35px;
    border-bottom-left-radius:35px;
    width:70vw;
  }
  @media(min-width:1240px){
    padding:20px;
    width:50vw;
  }
  @media(min-width:1920px){
    padding:20px;
    width:40vw;
  }

  overflow-y:auto;
  transition:1s;
  &.active{
    animation: .5s animate-side-menu ease-in-out forwards;
  }
  &.closing{
    animation: .5s animate-out-side-menu ease-in-out forwards;
  }
  .chart-property-tab{
    overflow-y:auto;
    overflow-x:hidden;
    height:70vh;
    border-radius:10px 20px!important;

    @media(min-width:1240px){
      height:74vh;
    }
    @media(min-width:1600px){
      height:80vh;
    }
  }

  //
  //@media(min-width:1240px){
  //  .chart-property-tab{
  //    max-height:60vh;
  //    overflow-y:auto;
  //  }
  //}



  .chart-property-tabs{
    .nav-tabs{
      flex-wrap:nowrap;
      overflow-x:auto;
    }
    > .nav-item{

      outline:none!important;
      a{
        height:100%;
      }
      width:20%;
      min-width:100px;
      display:flex;
      flex-shrink:0;
      //min-width:100px;
    }
  }

}
.theme--light{
  #chart-properties{

    background-color:$bg-light;
    box-shadow: 0px 10px 25px rgba(24, 28, 36, 0.3);

  }
}
.theme--dark{
  #chart-properties{

    background:$bg-contrast-dark;
    box-shadow: 0px 10px 25px rgba(24, 28, 36, 0.9);
  }
}
</style>

<script>

// import mobileTopTabs from "../../structure/MobileTopTabs";

import TabTitle from "../../elements/TabTitle";
import util from "../../../mixins/util";
import Summary from "./summary";
import Gates from "./gates";
import Channels from "./channels";
import Centers from "./centers";
import PlanetaryActivations from "./planetary-activations";
import {mapState} from "vuex";
import ChartUtil from "../../../mixins/ChartUtil";
export default{
  mixins: [util, ChartUtil],
  components: {PlanetaryActivations, Centers, Channels, Gates, TabTitle, Summary},

  computed:{
    ...mapState({
      showProperties: state => state.ui.showProperties,
      propertiesKey: state => state.ui.propertiesKey,
      chart: state => state.charts.chart,
    }),

    isUnlocked(){
      return this.getSubscriptionStatus.foundational || this.getUnlockStatus(this.chart).foundational
    },

    slideAnimationClasses(){

      if(this.showProperties) return 'active';

      if(this.isClosing) return 'closing';
      return '';
    }
  },
  watch:{

    showProperties(val){
      if(val) this.isClosing =  false;
      if(!val) this.closeProperties();
      this.setBodyScrollBehavior(!val);
    },
    propertiesKey(){
      this.loaded = false;
      this.fixActiveTab();

      // when we see a properties key increase (update in general) that means that something was clicked on the chart
      // so we have to fix the active tab in case there was another call clicked, and also re-render tabs to render the correct content.
    }
  },
  data(){
    return{
      hashes: ['summary', 'planetary-activations', 'centers', 'gates', 'channels'],
      currentHash: 0,
      loaded: false,
      isClosing: false
    }
  },

  mounted(){
    this.fixActiveTab();
  },
  methods:{

    fixActiveTab(){
      this.currentHash = this.hashes.findIndex(x => x === this.getQueryById('tab'));
      if (this.currentHash < 0) this.currentHash = 0;
      this.$nextTick(()=>{
        this.loaded = true; // we want to render tabs only after we have applied the correct active tab
        // that way we avoid double renders.
      });
    },

    changedTab(tab){
      this.fixUrlQuery('?tab='+this.hashes[tab]);
      // this.$router.replace({...this.$route, query: {...this.$route.query, tab: this.hashes[tab]}})
    },
    closeProperties(){
      this.fixUrlQuery('');
      this.isClosing = true;
      this.$store.commit('ui/SET_SHOW_PROPERTIES', false);

    }

  }
}
</script>