export const getIchingIcon = (gate) => {
    const hexagrams = {
        '61': [1, 1, 0, 0, 1, 1],
        '64': [1, 0, 1, 0, 1, 0],
        '63': [0, 1, 0, 1, 0, 1],
        '24': [0, 0, 0, 0, 0, 1],
        '47': [0, 1, 1, 0, 1, 0],
        '4': [1, 0, 0, 0, 1, 0],
        '17': [0, 1, 1, 0, 0, 1],
        '43': [0, 1, 1, 1, 1, 1],
        '11': [0, 0, 0, 1, 1, 1],
        '62': [0, 0, 1, 1, 0, 0],
        '23': [1, 0, 0, 0, 0, 0],
        '56': [1, 0, 1, 1, 0, 0],
        '8': [0, 1, 0, 0, 0, 0],
        '33': [1, 1, 1, 1, 0, 0],
        '31': [0, 1, 1, 1, 0, 0],
        '45': [0, 1, 1, 0, 0, 0],
        '20': [1, 1, 0, 0, 0, 0],
        '16': [0, 0, 1, 0, 0, 0],
        '35': [1, 0, 1, 0, 0, 0],
        '12': [1, 1, 1, 0, 0, 0],
        '10': [1, 1, 1, 0, 1, 1],
        '7': [0, 0, 0, 0, 1, 0],
        '1': [1, 1, 1, 1, 1, 1],
        '13': [1, 1, 1, 1, 0, 1],
        '25': [1, 1, 1, 0, 0, 1],
        '15': [0, 0, 0, 1, 0, 0],
        '2': [0, 0, 0, 0, 0, 0],
        '46': [0, 0, 0, 1, 1, 0],
        '21': [1, 0, 1, 0, 0, 1],
        '40': [0, 0, 1, 0, 1, 0],
        '51': [0, 0, 1, 0, 0, 1],
        '26': [1, 0, 0, 1, 1, 1],
        '48': [0, 1, 0, 1, 1, 0],
        '57': [1, 1, 0, 1, 1, 0],
        '44': [1, 1, 1, 1, 1, 0],
        '50': [1, 0, 1, 1, 1, 0],
        '32': [0, 0, 1, 1, 1, 0],
        '28': [0, 1, 1, 1, 1, 0],
        '18': [1, 0, 0, 1, 1, 0],
        '34': [0, 0, 1, 1, 1, 1],
        '27': [1, 0, 0, 0, 0, 1],
        '59': [1, 1, 0, 0, 1, 0],
        '5': [0, 1, 0, 1, 1, 1],
        '14': [1, 0, 1, 1, 1, 1],
        '29': [0, 1, 0, 0, 1, 0],
        '42': [1, 1, 0, 0, 0, 1],
        '3': [0, 1, 0, 0, 0, 1],
        '9': [1, 1, 0, 1, 1, 1],
        '36': [0, 0, 0, 1, 0, 1],
        '22': [1, 0, 0, 1, 0, 1],
        '37': [1, 1, 0, 1, 0, 1],
        '6': [1, 1, 1, 0, 1, 0],
        '49': [0, 1, 1, 1, 0, 1],
        '55': [0, 0, 1, 1, 0, 1],
        '30': [1, 0, 1, 1, 0, 1],
        '53': [1, 1, 0, 1, 0, 0],
        '60': [0, 1, 0, 0, 1, 1],
        '52': [1, 0, 0, 1, 0, 0],
        '54': [0, 0, 1, 0, 1, 1],
        '38': [1, 0, 1, 0, 1, 1],
        '58': [0, 1, 1, 0, 1, 1],
        '19': [0, 0, 0, 0, 1, 1],
        '39': [0, 1, 0, 1, 0, 0],
        '41': [1, 0, 0, 0, 1, 1]
    }
    let b1 = 'b1-', b2 = 'b2-', b3 = 'b3-'
        , hexagram = hexagrams[gate].reverse().slice(); // Reversed here to fix flipped issue.
    for (let i = 0; i < 2; i++) {
        b1 += hexagram[i] === 0 ? 'i' : 'a'
    }
    for (let i = 2; i < 4; i++) {
        b2 += hexagram[i] === 0 ? 'i' : 'a'
    }
    for (let i = 4; i < 6; i++) {
        b3 += hexagram[i] === 0 ? 'i' : 'a'
    }
    let result = '<span class="stacked-icon" style="top:1px;">'
    result += '<span class="iching-icon-' + b1 + '"></span>'
    result += '<span class="iching-icon-' + b2 + '"></span>'
    result += '<span class="iching-icon-' + b3 + '"></span>'
    result += '</span>'
    return result
}
