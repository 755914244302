<template>

  <div id="notes">

    <b-overlay :show="loading" rounded="sm">
      <div v-if="!createEditMode">
        <b-tabs v-model="tab" class="tabs-primary-underlined no-space-tabs no-space-tabs mt-0">
          <b-tab v-if="$route.params.chartId"
                 :title="`${$store.state.charts.chart?.meta?.name}'s Notes`">
            <div class="grid grid-cols-md-3 gap-10">
              <create-note-button @createEditMode="createEditMode = true"/>
              <note-item v-for="item in chartNotes" :key="item.id" :item="item" @setModalNote="setModalNote(item)"/>
            </div>
          </b-tab>

          <b-tab title="All Notes">
            <mbg-text-input v-model="query.search" :debounce="500"
                            :placeholder="$t('owl.dictionary.searchPlaceholder')"
                            :show-label="true"
                            icon="icon-search"
                            name="search-notes" type="search" />

            <div v-if="!loading && notes.length === 0" class="text-center">
              <p v-if="!query.search"> {{ $t('owl.notes.noNotes') }} </p>
              <p v-else> {{ $t('owl.notes.noResults') }} </p>
            </div>
            <div class="grid grid-cols-md-3 gap-10">
              <b-card v-if="!query.search" class="cursor-pointer hover-brightness" @click="createEditMode = true">
                <div class="d-flex align-items-center text-center justify-content-center w-100 h-100">
                  <div>
                    <b-button size="sm" variant="link"><span class="icon-plus"/></b-button>
                    <p class="mb-0">
                      {{ $t('owl.notes.add') }}
                    </p>
                  </div>
                </div>
              </b-card>

              <note-item v-for="item in notes" :key="item.id" :item="item" @setModalNote="setModalNote(item)"/>
            </div>
            <b-pagination
                v-if="Math.ceil(total / query.limit) > 1"
                v-model="page"
                :per-page="query.limit"
                :total-rows="total"
                align="right" class="my-4" hide-goto-end-buttons pills>

            </b-pagination>
          </b-tab>
        </b-tabs>
      </div>

      <div v-else>
        <div class="mb-3">

          <div class="grid grid-cols-2">
            <div>
              <b-button class="back-btn w-auto m-0 mr-3 px-0 no-gutters" style="width:fit-content"
                        variant="link"
                        @click="resetNote">
                <span class="icon-arrow-left"></span>
              </b-button>
            </div>
            <div class="text-right">
              <b-button v-if="modalNote.id" variant="link" @click="openDeleteNoteModal">
                <svg class="ml-auto" fill="none" height="24" viewBox="0 0 24 24" width="24"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 7.30469H6.9H18.1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.2"/>
                  <path d="M5.5 7.30469H6.9H18.1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.2"/>
                  <path d="M5.5 7.30469H6.9H18.1" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="1.2"/>
                  <path
                      d="M16.7001 7.3V17.1C16.7001 17.4713 16.5526 17.8274 16.2901 18.0899C16.0275 18.3525 15.6714 18.5 15.3001 18.5H8.30015C7.92884 18.5 7.57275 18.3525 7.3102 18.0899C7.04765 17.8274 6.90015 17.4713 6.90015 17.1V7.3M9.00015 7.3V5.9C9.00015 5.5287 9.14765 5.1726 9.4102 4.91005C9.67275 4.6475 10.0288 4.5 10.4001 4.5H13.2001C13.5714 4.5 13.9275 4.6475 14.1901 4.91005C14.4526 5.1726 14.6001 5.5287 14.6001 5.9V7.3"
                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
                  <path
                      d="M16.7001 7.3V17.1C16.7001 17.4713 16.5526 17.8274 16.2901 18.0899C16.0275 18.3525 15.6714 18.5 15.3001 18.5H8.30015C7.92884 18.5 7.57275 18.3525 7.3102 18.0899C7.04765 17.8274 6.90015 17.4713 6.90015 17.1V7.3M9.00015 7.3V5.9C9.00015 5.5287 9.14765 5.1726 9.4102 4.91005C9.67275 4.6475 10.0288 4.5 10.4001 4.5H13.2001C13.5714 4.5 13.9275 4.6475 14.1901 4.91005C14.4526 5.1726 14.6001 5.5287 14.6001 5.9V7.3"
                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
                  <path
                      d="M16.7001 7.3V17.1C16.7001 17.4713 16.5526 17.8274 16.2901 18.0899C16.0275 18.3525 15.6714 18.5 15.3001 18.5H8.30015C7.92884 18.5 7.57275 18.3525 7.3102 18.0899C7.04765 17.8274 6.90015 17.4713 6.90015 17.1V7.3M9.00015 7.3V5.9C9.00015 5.5287 9.14765 5.1726 9.4102 4.91005C9.67275 4.6475 10.0288 4.5 10.4001 4.5H13.2001C13.5714 4.5 13.9275 4.6475 14.1901 4.91005C14.4526 5.1726 14.6001 5.5287 14.6001 5.9V7.3"
                      stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"/>
                </svg>
              </b-button>
            </div>


          </div>

        </div>
        <div>
          <label>
            {{
            $route.params.chartId ? $t('owl.notes.textAreaTitle', {name: $store.state.charts.chart?.meta?.name }) : $t('owl.notes.textAreaTitleNoChart')
            }}
          </label>
          <label class="mbg-text-muted font-weight-normal  d-block">
            {{
              $t('owl.notes.clickOutside')
            }}
          </label>

          <b-form-textarea v-model="modalNote.content"
                           :placeholder="`Start typing a note...`" class="notes-area mt-3" rows="8"
                           style="white-space: pre-wrap"
                           @blur="updateNote"
                           type="textarea">
          </b-form-textarea>
        </div>


        <div v-if="modalNote.id" :key="associatedChart?.id" class="mt-auto text-left my-4">
          <p class="mb-2 text-xs mbg-text-muted ">{{ $t('general.associatedChart') }}</p>
          <mbg-chart-picker
              v-if="!associatedChart"
              :value="associatedChartFormatted"
              title=""
              all-types
              @input="val => val && updateAssociatedChart({
                    chartId: val?.id,
                    chart: val,
                    nullify: false
              })"/>

          <chart-preview v-if="associatedChart" :chart="associatedChart">
            <b-button v-if="$route.params.chartId && $route.params.chartId !== associatedChart.id"
                      variant="outline-white"
                      size="xs"
                      class="mt-2 mr-2"
                      @click="updateAssociatedChart({})"
            >
              Change to current
            </b-button>
            <b-button v-if="associatedChart"
                      variant="outline-danger"
                      size="xs"
                      class="mt-2"
                      @click="updateAssociatedChart({
                        nullify: true,
                      })"
            >
              Remove Association
            </b-button>
          </chart-preview>


          <b-button v-else-if="$route.params.chartId"
                    variant="outline-white"
                    size="xs"
                    class="mt-2"
                    @click="updateAssociatedChart({
                         chart: $store.state.charts.chart
                    })"
          >
            Associate with current chart
          </b-button>
          <div v-else>

            -
          </div>
        </div>
        <div v-if="modalNote.id">
          <label>Note Tags</label>

          <multiselect v-model="modalNote.note_tags"
                       placeholder="Select from the list or type a new tag here..."
                       tag-placeholder="Add this as new tag"
                       label="name"

                       :close-on-select="false"
                       track-by="id" :options="tags" :multiple="true" :taggable="true"
                       @tag="addTag"
                       @remove="updateNote"
                       @select="updateNote"
          ></multiselect>
        </div>
        <div class="text-right meta mt-3">
          <div v-if="modalNote.id" class="mt-auto">
            <p class="mb-0 text-xs mbg-text-muted ">{{ $t('general.lastUpdate') }}</p>
            <p>{{ formatDate(modalNote.updatedAt) }}</p>
          </div>
          <div v-if="modalNote.id" class="mt-auto">
            <p class="mb-0 text-xs mbg-text-muted ">{{ $t('general.createdAt') }}</p>
            <p>{{ formatDate(modalNote.createdAt) }}</p>
          </div>
        </div>

        <b-modal ref="delete-card" title="Are you sure you want to delete this note?"
                 @ok="deleteNote"
                 ok-title="Yes, delete">
          <p> If you delete this note, there is no way to recover it. </p>
          <p>
            <strong>
              Are you sure you want to proceed?
            </strong>
          </p>
        </b-modal>
      </div>
    </b-overlay>

  </div>

</template>

<style lang="scss">

.theme--dark {
  .notes-area {
    background: $bg-contrast-dark-low-opacity!important;
    color: white!important;
    &:focus{
      color:white!important;
    }
  }
  .multiselect__content-wrapper, .multiselect__element{
    background: $bg-contrast-dark!important;
    color:white;
  }
  .multiselect__option--selected{
    background: $bg-dark!important;
    color:white!important;
  }
  .multiselect__tags{
    background:transparent;

    color:white!important;
    .multiselect__tag{
      background: $bg-contrast-dark;
    }
    .multiselect__tag-icon:after{
      color: white!important;
    }
    .multiselect__tag-icon:focus, .multiselect__tag-icon:hover{
      background: $bg-contrast-dark-low-opacity
    }

  }
}
.theme--light{
  .notes-area {
    background: $bg-light!important;
    color: black;
    &:focus{
      color:black!important;
    }
  }
  .multiselect__content-wrapper, .multiselect__element, .multiselect__input{
    color:black!important;
  }
  .multiselect__tags{
    color:black!important;
    .multiselect__tag{
      background: $bg-light;
      color: $bg-dark;
    }
    .multiselect__tag-icon:after{
      color: black!important;
    }
    .multiselect__tag-icon:focus, .multiselect__tag-icon:hover{
      background: $bg-contrast-dark-low-opacity
    }


  }
}


#notes{
  .multiselect__content{
    padding-left:0!important;
  }

  .multiselect__input{
    padding:0!important;
    outline:none!important;
    border:none!important;
    //opacity:0!important;
    height:0!important;
    //display:none!important;
    &::placeholder{
      font-size:0.8rem;
    }
  }


  .notes-area {
    //background: transparent !important;
    box-shadow: none !important;
    border: 1.5px solid transparent !important;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom:15px;
    &:focus{
      border-color: $primary!important;
    }
  }

  .note-preview-render {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

</style>

<script>
import MbgTextInput from "../../elements/MbgTextInput.vue";
import localforage from "localforage";
import Multiselect from 'vue-multiselect'
import ChartPageMixin from "@/mixins/ChartPageMixin";
import ChartPreview from "@/components/library/ChartPreview.vue";
import NoteItem from "@/components/structure/owl/elements/NoteItem.vue";
import CreateNoteButton from "@/components/structure/owl/elements/CreateNoteButton.vue";
import MbgChartPicker from "@/components/elements/MbgChartPicker.vue";
import {config} from "@/utils/config";

export default {

  mixins: [
      ChartPageMixin
  ],
  components: {MbgChartPicker, CreateNoteButton, NoteItem, ChartPreview, MbgTextInput, Multiselect,},


  data() {
    return {
      config,
      tab: 0,
      chartNotes: [],
      selectedTags: [],
      loading: false,
      notes: [],
      tags: [],
      modalNote: {
        id: null,
        content: '',
        chart_id: null
      },
      createEditMode: false,
      activeNote: null,
      total: 0,
      page: 1,
      query: {
        search: '',
        limit: 9,
      },
      associatedChart: null,
    }
  },
  watch: {
    page(){
      this.getNotes()
    },
    'query.search'(){
      this.getNotes()
    },
    '$route.params.chartId'(){
      this.getChartNotes();
      this.$nextTick(()=> {
        this.resetNote();
        this.tab = 0;
      });
    }
  },
  computed: {
    noUpdates() {
      const note = this.notes.find(x => x.id === this.modalNote.id);
      return this.modalNote.id &&
          this.modalNote.content === note.content
          &&
          JSON.stringify(this.modalNote.note_tags.map(x => x.id).sort()) === JSON.stringify(note.note_tags.map(x => x.id).sort());
    },
    associatedChartFormatted() {
      if (!this.associatedChart) return null;

      return {
        id: this.associatedChart.id,
        name: this.associatedChart.meta.name,
        docType: this.associatedChart.meta.docType,
        isCelebrity: this.associatedChart.isCelebrity,
      }
    }
  },
  created() {
    this.getNotes();
    this.getNoteTags();
    this.getChartNotes();
  },

  methods: {

    openDeleteNoteModal(){
      this.$refs['delete-card'].show()
    },
    resetNote() {
      this.modalNote.id = null;
      this.modalNote.content = null;
      this.createEditMode = false;
      this.associatedChart = null;
    },

    setModalNote(note) {
      this.modalNote = structuredClone(note);
      this.fetchAssociatedChart();
      this.createEditMode = true;
    },

    fetchAssociatedChart() {
      if (this.modalNote.chart_id) {
        this.fetchDocNames([this.modalNote.chart_id]).then(res => {
          this.associatedChart = res[0];
        });
      } else {
        this.associatedChart = null;
      }
    },

    async getNoteTags(){
      this.tags = await localforage.getItem('note-tags');
      let nextUpdate = new Date((await localforage.getItem('note-tags-last-update')));
      // we re-cache tags after a day.
      nextUpdate.setHours(nextUpdate.getHours() + 1);
      const cacheValid = new Date() < nextUpdate;
      if(cacheValid) return;

      this.axios.get('/notes/tags').then(res => {
        localforage.setItem('note-tags', res.data);
        localforage.setItem('note-tags-last-update', new Date());
        this.tags = res.data;
      }).catch( () =>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.failedToFetch')});
      })
    },

    fetchChartNames(collection) {
      const chartIds = collection.map(x => x.chart_id).filter(x => !!x);
      return this.axios.get(`${this.config.chartsUrl}/api/charts/public/names`, {
        params: { chartIds }
      }).then(res => {
        for (const item of collection) {
          item.chart = res.data.find(x => x.id === item.chart_id)
        }
        return collection;
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(()=>{
        return collection
      });
    },

    getChartNotes() {
      const chartId = this.$route.params.chartId;
      if (!chartId){
        this.chartNotes = [];
        return;
      }
      this.loading = true;
      this.axios.get('/notes/charts/' + chartId).then(async res => {
        this.chartNotes = await this.fetchChartNames(res.data);
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(() => {
        this.loading = false;
      })
    },

    getNotes() {

      this.loading = true;
      this.axios.get('/notes', {
        params: {
          search: this.query.search,
          limit: this.query.limit,
          offset: (this.page - 1) * this.query.limit,
        }
      }).then(async res => {
        this.notes = await this.fetchChartNames(res.data.rows);
        this.total = res.data.count;
      }).finally(() => {
        this.loading = false;
      })

    },

    updateAssociatedChart({
        chartId = this.$route.params.chartId,
        nullify = false,
        chart = null
    }){
      if(!this.modalNote.id) return;
      this.axios.put('/notes/' + this.modalNote.id, {
        ...this.modalNote,
        chart_id: nullify ? null : (chartId ?? this.$route.params.chartId),
        update_chart: true
      }).then((res) => {
        this.postUpdateActions(res, chart);
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    },

    postUpdateActions(res, chart) {
      this.modalNote.note_tags = [...res.data.note_tags];

      let noteIndex = this.notes.findIndex(x => x.id === res.data.id);
      let note = structuredClone(this.notes[noteIndex])
      note.content = res.data.content;
      if (res.data.chart_id !== note.chart_id) {
        this.modalNote.chart_id =  res.data.chart_id;
        note.chart_id = res.data.chart_id;
        if (chart && chart.id === res.data.chart_id) {
          note.chart =  {
            id: chart.id,
            name: chart.meta?.name ?? chart.name,
          };
        } else {
          note.chart = null;
        }
        this.fetchAssociatedChart();
      }
      note.note_tags = structuredClone(res.data.note_tags);
      this.notes.splice(noteIndex, 1);
      this.notes.unshift(note);
      this.modalNote.note_tags = structuredClone(res.data.note_tags);
      this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')});
      this.getChartNotes();
    },

    updateNote() {
      if (!this.modalNote.content) return;
      if(this.noUpdates) return;

      if (this.modalNote.id) {
        this.axios.put('/notes/' + this.modalNote.id, this.modalNote).then((res) => {
          this.postUpdateActions(res);
        }).catch(() => {
          this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
        })
      } else {
        this.axios.post('/notes', {...this.modalNote,
          chart_id: this.$route.params.chartId && this.tab === 0 ? this.$route.params.chartId : undefined
        }).then(async (res) => {

          const data = {
            ...res.data,
            note_tags: []
          }
          this.notes.unshift(data);
          this.setModalNote(data);
          this.getChartNotes();
          this.notes = await this.fetchChartNames(this.notes);
          this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.created')});
        }).catch(() => {
          this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
        })
      }
    },

    deleteNote() {
      this.axios.delete('/notes/' + this.modalNote.id).then(() => {
        this.getNotes();
        this.resetNote();
        this.getChartNotes();
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.deleted')});
        this.$refs['delete-card'].hide();
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    },

    addTagAndSave(data){
      this.modalNote.note_tags.push(data);
      this.updateNote();
    },

    async addTag(data){
      this.axios.post('/notes/tags/', {name: data}).then(res => {
        this.tags.push(res.data);
        localforage.setItem('note-tags', this.tags); // save to indexedDB.
        this.addTagAndSave(res.data);
      }).catch( () => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    }
  }

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>