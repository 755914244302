<template>
  <div class="px-4 ">
    <inner-tabs-renderer :id="1"
                         type="foundational"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)" >
      <template #unlock-prompt> <slot name="unlock-prompt"/> </template>
    </inner-tabs-renderer>
  </div>
</template>

<script>
import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import authorities from "../../../../helpers/rave/constants/authorities";
import {mapState} from "vuex";
import Authority from "./sections/Authority.vue";
import InnerTabsRenderer from "../../../renderer/InnerTabsRenderer.vue";
export default{
  props:{
    hideAuthority: {
      type: Boolean,
      default: false
    }
  },
  mixins: [AnalysisQueryMixin],
  components: {InnerTabsRenderer},
  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
    tabs(){
      return [
        {
          id: 0,
          title: this.$t('hd.properties.type'),
          component: DynamicZoneRenderer,
          shouldRender: !!this.type,
          props: {
            components: this.type ? this.type.components : [],
            addPaddings: false
          }
        },
        {
          id: 1,
          title: this.$t('hd.properties.strategy'),
          component: DynamicZoneRenderer,
          shouldRender: !!this.strategy,
          props: {
            components: this.strategy ? this.strategy.components : [],
            addPaddings: false
          }
        },
        {
          id: 2,
          title: this.$t('hd.properties.innerAuthority'),
          component: Authority,
          shouldRender: !!this.chart,
          hideTab: this.hideAuthority,
          props: {
            chart: this.chart
          }
        },
        {
          id: 3,
          title: this.$t('hd.properties.aura'),
          component: DynamicZoneRenderer,
          shouldRender: !!this.strategyAuthorityCombo,
          props: {
            components: this.strategyAuthorityCombo ? this.strategyAuthorityCombo.components : [],
            addPaddings: false
          }
        }
      ]
    }
  },
  data(){
    return{
      audioKey: 0,
      tab: 0,
      content: null,
      type: null,
      strategy: null,
      strategyAuthorityCombo: null
    }
  },

  created(){
    this.getType();
    this.getStrategy();
    this.getStrategyAuthorityCombo('strategyAuthorityCombo', true)
  },

  methods:{

    getType(){
      let url = '/types/' +  this.chart.chart.type
      this.getContent('type', url);
    },
    getStrategy(){
      let url = '/strategies/' +  this.chart.chart.type
      this.getContent('strategy', url);
    },

    getStrategyAuthorityCombo(key, isExtra, checkAuthority = true){
      let url;
      if(this.chart.chart.authority === authorities.NONE && checkAuthority){
        url = '/strategy-authority-combos/' +  this.chart.chart.type + '.' + this.chart.chart.authority + '.' + this.chart.chart.type + '.' + isExtra
      }else{
        url = '/strategy-authority-combos/' +  this.chart.chart.type + '.' + this.chart.chart.authority + '.' + isExtra
      }

      this.getContent(key, url);
    },
  }
}
</script>