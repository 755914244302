import * as R from 'ramda'
import moment from 'moment'

export const isFunction = val => Object.prototype.toString.call(val) === '[object Function]'

export const notEquals = R.compose(R.not, R.equals)

export const propNotEq = R.complement(R.propEq)

export const propsDifferent = R.complement(R.eqProps)

export const isNotNil = R.compose(R.not, R.isNil)

export const isNotEmpty = R.compose(R.not, R.isEmpty)

export const isNotNullOrEmpty = R.ifElse(isNotNil, isNotEmpty, R.always(false))

export const isNullOrEmpty = R.either(R.isNil, R.isEmpty)

export const hasFunction = R.curry((name, obj) => R.both(isNotNil, R.propSatisfies(isFunction, name))(obj))

export const ensureArray = (x) => R.ifElse(
  R.isNil,
  R.always([]),
  x => Array.isArray(x) ? x : [x]
)(x)

export const wrapInArray = x => [x]

export const onlyIf = (pred, res) => R.ifElse(
  pred,
  R.when(R.complement(isFunction), R.always)(res),
  R.always(null)
)

export const applyAndConcat = R.curry((funcs, val) => {
  const applyFunc = val => f => R.pipe(
    f,
    R.ifElse(
      R.isNil,
      R.always([]),
      ensureArray
    )
  )(val)

  return R.chain(applyFunc(val), funcs)
})

export const between = R.curry((min, max, val) => R.both(R.lte(R.__, max), R.gte(R.__, min))(val))

export const mapIndexed = R.addIndex(R.map)

export const reduceIndexed = R.addIndex(R.reduce)

export const isPropTrue = R.propEq(R.__, true)

export const isPropFalse = R.propEq(R.__, false)

export const hasProp = R.curry((prop, obj) => R.compose(isNotNil, R.prop(prop))(obj))

export const hasPath = R.curry((path, obj) => R.compose(isNotNil, R.path(path))(obj))

export const isPathTrue = R.pathEq(R.__, true)

export const isPathFalse = R.pathEq(R.__, false)

export const dotPathEq = R.curry(
  (value, path) => R.pipe(
    R.split('.'),
    R.flip(R.pathEq)(value)
  )(path)
)

export const dotPathNotEq = R.curry((value, path) => R.pipe(R.split('.'), R.flip(R.complement(R.pathEq))(value))(path))

export const dotPath = R.pipe(R.split('.'), R.path)

export const dotPathOr = R.curry((defaultValue, path) => R.pipe(R.split('.'), R.pathOr(R.defaultTo(null, defaultValue)))(path))

export const isSubList = R.compose(R.isEmpty, R.without)

export const mutatePath = R.curry((path, transformation, obj) => {
  const [h, ...t] = R.split('.', path)
  obj[h] = R.over(R.lensPath(t), transformation, obj[h])
})

export const mutatePathTo = R.curry((path, value, obj) => {
  const [h, ...t] = R.split('.', path)
  obj[h] = R.set(R.lensPath(t), value, obj[h])
})

export const isEq = val => R.equals(R.__, val)

export const isGt = val => R.gt(R.__, val)

export const isGte = val => R.gte(R.__, val)

export const isLt = val => R.lt(R.__, val)

export const isLte = val => R.lte(R.__, val)

export const anyTrue = R.any(R.equals(true))

export const propIn = R.curry((prop, values) => R.pipe(R.prop(prop), R.includes(R.__, values)))

export const capitalize = R.compose(
  R.join(''),
  R.juxt([R.compose(R.toUpper, R.head), R.tail])
)

export const expandCamelCaseToWords = R.replace(/([a-z])([A-Z])/g, '$1 $2')

const is65OrMore = R.flip(R.gte)(65)
const is90OrLess = R.flip(R.lte)(90)
const isUpperCaseLetter = R.both(is65OrMore, is90OrLess)

const getCharCode = x => x.charCodeAt(0)

const prefixUpperCaseChars = R.when(
  R.pipe(getCharCode, isUpperCaseLetter),
  R.concat('_')
)

export const camelCaseToUpperCaseUnderscored = R.pipe(
  R.map(prefixUpperCaseChars),
  R.join(''),
  R.toUpper
)

export const allSame = R.pipe(
  R.uniq,
  R.length,
  isEq(1)
)

export const normalizeStr = R.pipe(
  R.trim,
  R.toLower
)

export const allDifferent = arr => R.pipe(
  R.uniq,
  R.length,
  isEq(arr.length)
)(arr)

export const collectArguments = (...args) => args

export const dissocMultiple = (...props) => {
  let statements = R.map(x => R.dissoc(x))(props)
  return R.pipe(...statements)
}

export const throwErr = function throwErr (msg) {
  throw new Error(msg)
}

export const clampedProp = (prop, min, max, defaultVal) => R.pipe(
  R.propOr(R.defaultTo(min, defaultVal), prop),
  R.clamp(min, max)
)

const epochTicks = 621355968000000000
const ticksPerMillisecond = 10000

export const dateFromTicks = (ticks) => {
  const ticksSinceEpoch = ticks - epochTicks
  const millisecondsSinceEpoch = ticksSinceEpoch / ticksPerMillisecond

  return moment.utc(millisecondsSinceEpoch)
}

export const logoWithText = () => {return `/assets/images/maia-mechanics-logo-with-text.svg`}

export const logo = () => {return `/assets/images/maia-mechanics-logo.svg`}

