<template>
  <div v-if="content">
    <dynamic-zone-renderer :components="content.components" />

    <slot name="unlock-prompt" />
    <div class="px-4">
      <ProgressChecker type="work_success" id="0" :has-prev="hasPrev" :has-next="hasNext" @nextSection="$emit('nextSection')"/>
    </div>
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import ProgressChecker from "../progress/ProgressChecker.vue";

export default{
  components: {ProgressChecker, DynamicZoneRenderer},
  mixins: [AnalysisQueryMixin],
  props:{
    id: [String, Number],
  },
  data(){
    return{
      audioKey: 0,
      content: null
    }
  },

   created(){
    this.getIntro();
   },

  methods:{
    getIntro(){
      this.getContent('content', '/analysis/introductions/introduction.work-success-analysis');
    }
  }
}
</script>