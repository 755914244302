export default{
    button: 'Properties',
    title: 'Chart Properties',
    close: 'Close Properties',
    summary: 'Summary',
    planetary: 'Planetary Activations',
    planetaryInfo: 'Planetary Information',
    circuits: 'Circuits',
    overview: 'Overview',
    analysis: 'Analysis',
    healthyVsUnhealthy: 'Healthy vs Unhealthy',
    notSelfTalk: 'Not-Self Talk',
    completelyOpen: 'Completely Open Analysis',
    relevantLinks: 'Relevant Links',
    harmonicGate: 'Harmonic Gate',
    harmonicGates: 'Harmonic Gates',
    activations: 'Activations',
    notSelfKeynotes: 'Not-Self Keynotes',
    notSelfQuestions: 'Not-Self Questions',
    otherOptions: 'Other Options',
    share: 'Share',
    edit: 'Edit',
    copy: 'Copy to new',
    notes: 'Notes',
    uniqueUrl: 'Or use your unique URL',
    viewJustNowConnection: 'Just Now Connection',
    statistics: 'Statistics',
    image: 'Download Image',
    copyChart: 'Save to library',
    tripleActivation: 'Triple Activation',
    imageName: 'Image Name',
    imageBackground: 'Image Background',
    transparent: 'Transparent',
    regular: 'Regular',
}