<template>

  <side-extended-panel id="dictionary"
                       icon="icon-knowledge"
                       :close-btn-text="$t('owl.close')"
                       :visible="showOwl"
                       @close="closeDictionary">

    <b-tabs v-model="activeTab" class="tabs-primary-underlined knowledge-base-tabs mt-2 no-space-tabs"
            :class="darkMode ? 'theme--dark' : 'theme--light'">
      <b-tab v-for="item in tabs" :key="item.title">
        <template #title>
          <div class="position-relative">
            <span>{{item.title}}</span>
            <div v-if="item.hasBadge"
                 class="position-absolute bg-primary  rounded-circle"
                 style="top:3px; right:-7.5px; height:5px; width: 5px;"
            />
          </div>

          <b-badge v-if="item.hasBadge" variant="danger" class="ml-2"></b-badge>
        </template>
        <component :is="item.component"></component>
      </b-tab>


    </b-tabs>
  </side-extended-panel>

</template>
<style lang="scss">
.dictionary-results-wrapper{
  max-height:60vh;
  overflow-y:auto;
}

.knowledge-base-tabs{
  padding-bottom:0!important;
  &.theme--dark{
    .nav-tabs{
      border-color: $bg-contrast-dark!important;
    }

  }
  &.theme--light{
    .nav-tabs{
      border-color: #d3d3d3!important;
    }
  }
  .nav-tabs{
    padding-bottom:0!important;
    border-bottom:1.3px solid;
  }
}
</style>

<script>
import util from "../../../mixins/util";

import {mapState} from "vuex";
import SideExtendedPanel from "../SideExtendedPanel";
import Dictionary from "./Dictionary.vue";
import KnowledgeBase from "./KnowledgeBase.vue";
import IChing from "./IChing.vue";
import Notes from "./Notes.vue";
import UnlockContentPrompt from "../../billing/UnlockContentPrompt.vue";
import Notifications from "./Notifications.vue";
export default{
  mixins: [util],
  components: {UnlockContentPrompt, SideExtendedPanel},

  computed:{
    ...mapState({
      showOwl: state => state.ui.showOwl,
      startingOwlTab: state => state.ui.startingOwlTab,
      notifications: state => state.account.notifications,
      settings: state => state.account.settings
    }),

    tabs() {
      return [
        {
          key: 'knowledge-base',
          title: this.$t('owl.knowledgeBase.title'),
          component: KnowledgeBase
        },
        {
          key: 'dictionary',
          title: this.$t('owl.dictionary.title'),
          component: Dictionary
        },
        {
          key: 'iching',
          title: this.$t('owl.iching.title'),
          component: IChing
        },
        {
          key: 'notes',
          title: this.$t('owl.notes.title'),
          component: Notes
        },
        {
          key: 'news',
          title: this.$t('owl.notifications.title'),
          component: Notifications,
          hasBadge: this.notifications.data.length > 0 && this.notifications.data[0].id !== this.settings.last_notification_read
        }

      ]
    }
  },

  watch:{
    showOwl(){
      this.$nextTick(()=>{
        if(this.$route.query.tab){
          this.activeTab = this.tabs.findIndex(x => x.key === this.$route.query.tab) || 0
        }else if(this.startingOwlTab !== null) {
          this.activeTab = this.tabs.findIndex(x => x.key === this.startingOwlTab);
          this.$store.commit('ui/SET_STARING_OWL_TAB', null); // reset
        }
      })
    }
  },
  data(){
    return{
      activeTab:0,
    }
  },

  mounted(){
    this.$nextTick(()=>{

      if(this.getQueryById('owl')){
        this.$store.commit('ui/SET_SHOW_OWL', true);
      }

      if(this.getQueryById('tab')){
        this.activeTab = this.tabs.findIndex(x => x.key === this.getQueryById('tab')) || 0
      }
    })
  },
  methods:{

    closeDictionary(){
      this.fixUrlQuery('');
      this.$store.commit('ui/SET_SHOW_OWL', false);
    },
  }
}
</script>