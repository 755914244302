export default{
    props:{
        hasNext: {
            type: Boolean,
            default: true,
        },
        hasPrev: {
            type: Boolean,
            default: true
        },
        totalTabs: {
            type: Number,
            default: 1
        }
    },

    data(){
        return{
            fetching: false
        }
    },

    beforeDestroy() {
        this.$emit('loading', false);
    },

    methods:{
        getContent(key, url, twoLevelDepth = false){
            this.$emit('loading', true);
            this.fetching = true;

            return this.axios.get('/content/' + this.$route.params.chartId + url).then(res=>{

                // if we have depth (for example trying to assign value to channelContent.1 = x instead of channelContent = x, we are passing two level depth)
                if(twoLevelDepth){
                    let parts = key.split('.');
                    this[parts[0]][parts[1]] = res.data;
                }else{
                    this[key] = res.data;
                }

                this.audioKey++;
            }).catch(e=>{
                console.log(e);
            }).finally(()=>{
                this.fetching = false;
                this.$emit('loading', false);
            });
        },

        getArrayContent(key, url){
            this.$emit('loading', true);
            this.fetching = true;

            return this.axios.get('/content/' + this.$route.params.chartId + url).then(res=>{
                this[key].push(res.data);
                this.audioKey++;
            }).catch(e=>{
                console.log(e);
            }).finally(()=>{
                this.fetching = false;
                this.$emit('loading', false);
            });
        }
    }
}