<template>
  <b-tabs v-model="activeTab" class="tabs-primary-underlined" lazy @input="updateSubTab">
    <b-tab :title="$t('general.account')">
      <account-settings/>
    </b-tab>
    <b-tab :title="$t('general.options')">
      <account-advanced-settings/>
    </b-tab>
    <b-tab :title="$t('general.chart')">
      <account-chart/>
    </b-tab>
  </b-tabs>
</template>

<script>

import AccountAdvancedSettings from "./AccountAdvancedSettings.vue";
import AccountChart from "./AccountChart.vue";
import AccountSettings from "./AccountSettings.vue";
import util from "../../mixins/util";

export default {
  components: {AccountSettings, AccountChart, AccountAdvancedSettings},
  mixins: [util],
  data() {
    return {
      activeTab: 0,
      tabs: ['account', 'options', 'main-chart'],
    }
  },
  created(){
    if (this.$route.query.category) {
      this.activeTab = this.tabs.findIndex(x => x === this.$route.query.category) || 0;
    } else {
      this.activeTab = 0;
    }
  },

  methods:{
    updateSubTab() {
      let query = this.getQuery();
      query.set('category', this.tabs[this.activeTab]);
      this.fixUrlQuery('?' + query)
    },

  }

}
</script>