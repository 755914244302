<template>
  <div id="auth-layout">

    <auth-header/>
    <div class="router-view-area">
      <slot></slot>
    </div>

  </div>
</template>

<script>
import AuthHeader from "../components/structure/AuthHeader";
export default{
  components: {AuthHeader},

  created(){
    this.setBodyScrollBehavior(true);
  }
}
</script>