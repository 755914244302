<template>
  <div id="awareness" class="px-4">
    <inner-tabs-renderer :id="4"
                         type="advanced"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)">
      <template #unlock-prompt> <slot name="unlock-prompt"/> </template>
    </inner-tabs-renderer>
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer";
import {mapState} from "vuex";
import activationModes from "../../../../helpers/rave/constants/activationModes";
import planetConstants from "../../../../helpers/rave/constants/planets";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import ColorToneComboDataRenderer from "./elements/ColorToneComboDataRenderer.vue";
import ColorDataRenderer from "./elements/ColorDataRenderer.vue";
import InnerTabsRenderer from "../../../renderer/InnerTabsRenderer.vue";
export default{
  components: {
    InnerTabsRenderer
  },
  mixins: [AnalysisQueryMixin],
  data(){
    return{
      tab: 0,
      audioKey: 0,
      intro: null,
      activation: 'personality',
      type: 'internal',
      color: null,
      tone: null
    }
  },
  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
    tabs(){
      return [
        {
          id: 0,
          title: this.$t('hd.properties.introduction'),
          shouldRender: !!this.intro,
          component: DynamicZoneRenderer,
          props: {
            components: this.intro ? this.intro.components : [],
            addPaddings: false
          }
        },
        {
          id: 1,
          title: this.$t('hd.properties.motivation'),
          shouldRender: true,
          component: ColorDataRenderer,
          props: {
            activation: this.activation,
            type: this.type,
            tone: this.tone,
            color: this.color,
          }
        },
        {
          id: 2,
          title: this.$t('hd.properties.orientation'),
          shouldRender: true,
          component: ColorToneComboDataRenderer,
          props: {
            activation: this.activation,
            type: this.type,
            tone: this.tone,
            color: this.color,
          }
        }
      ]
    }
  },
   created(){
    this.getIntro();
    let planet = this.chart.chart.planets.find(x => x.activation === activationModes.PERSONALITY &&
        (x.id === planetConstants.SUN || x.id === planetConstants.EARTH));
    this.tone = planet.tone <= 3 ? 'low' : 'high';
    this.color = planet.color;
   },

  methods:{
    getIntro(){
      let url = '/analysis/introductions/introduction.advanced.5';
      this.getContent('intro', url);
    },


  }
}
</script>
