<template>

  <div id="planet-watch">


    <svg width="100%" height="427" viewBox="0 0 452 427" fill="none" xmlns="http://www.w3.org/2000/svg" class="mx-auto">

<!--      <defs>-->
<!--        <linearGradient id="progress" x1="0" y1="1" x2="0" y2="0">-->
<!--          <stop id="stop1" offset="25" stop-color="blue"/>-->
<!--          <stop id="stop2" offset="15" stop-color="lightblue"/>-->
<!--        </linearGradient>-->
<!--      </defs>-->
      <path id="lb-5" d="M225.81 264.999L332.522 66.9146C364.591 84.1909 391.986 108.995 412.353 139.196L225.81 264.999Z"
            class="line-activation-bg lb-5"  stroke-width="2"/>

      <path id="lb-4" d="M222.405 264.269L228.963 39.3642C265.374 40.426 300.983 50.3123 332.73 68.1735L222.405 264.269Z"
            class="line-activation-bg lb-4"  stroke-width="2"/>

      <path id="lb-6" d="M225.809 264.999L412.353 139.196C428.984 163.856 440.522 191.512 446.373 220.541C448.556 231.369 440.549 241.28 429.57 242.493L225.809 264.999Z"
            class="line-activation-bg lb-6"  stroke-width="2"/>

      <path id="lb-1" d="M225.81 264.999L22.5835 238.089C11.6334 236.639 3.84288 226.557 6.25921 215.779C12.7373 186.883 24.8717 159.483 42.0321 135.188L225.81 264.999Z"
            class="line-activation-bg lb-1"
             stroke-width="2"/>

      <path id="lb-2" d="M225.809 264.999L42.0315 135.188C63.0473 105.435 90.9727 81.2299 123.408 64.6517L225.809 264.999Z"
            class="line-activation-bg lb-2"
             stroke-width="2"/>

      <path id="lb-3" d="M225.809 264.999L123.409 64.6515C155.844 48.0732 191.82 39.6179 228.244 40.0121L225.809 264.999Z"
            class="line-activation-bg lb-3"
             stroke-width="2"/>


      <text id="l-1" x="40" y="200" class="line-activation l-1">1</text>
      <text id="l-2" x="90" y="125" class="line-activation l-2">2</text>
      <text id="l-3" x="170" y="85" class="line-activation l-3">3</text>
      <text id="l-4" x="270" y="89" class="line-activation l-4">4</text>
      <text id="l-5" x="350" y="129" class="line-activation l-5">5</text>
      <text id="l-6" x="400" y="200" class="line-activation l-6">6</text>


      <circle id="circle" cx="225.259" cy="263.999" r="160" transform="rotate(0.62 225.259 263.999)"/>

      <foreignObject x="150" y="180" width="150" height="200" class="text-center">
        <span :class="`icon-${planet.icon}`" class="text-lg mb-2"></span>
        <p xmlns="http://www.w3.org/1999/xhtml" class="text-sm">{{planet.name}}</p>
        <p xmlns="http://www.w3.org/1999/xhtml" class="text-sm font-500 mb-1">Current Activation</p>
        <p xmlns="http://www.w3.org/1999/xhtml" class="text-3xl font-500">{{formatActivationGateLine(changes[planet.id].currentData)}}</p>
      </foreignObject>


      <foreignObject x="10" y="260" width="150" height="100">
        <p xmlns="http://www.w3.org/1999/xhtml" class="text-sm font-weight-light mb-0">Previous Gate</p>
        <p xmlns="http://www.w3.org/1999/xhtml" class="text-xl font-500">        {{changes[planet.id]['prevGate'].gate}}</p>
      </foreignObject>


      <foreignObject x="295" y="260" width="150" height="100" class="text-right">
        <p xmlns="http://www.w3.org/1999/xhtml" class="text-sm font-weight-light mb-0">Next Gate</p>
        <p xmlns="http://www.w3.org/1999/xhtml" class="text-xl font-500 mb-0">
          {{changes[planet.id]['nextGate'].gate}}
        </p>
        <p v-if="justNow" xmlns="http://www.w3.org/1999/xhtml" class="text-sm font-weight-light mb-0">
          {{calculateTimeForActivation(planet.id, dataTypes.nextGate).difference}}
        </p>
      </foreignObject>

      <foreignObject id="arrow-left" v-if="changes[planet.id]['currentData'].retrograde"
                     :x="arrow_coordinates[changes[planet.id]['currentData'].line].x" :y="arrow_coordinates[changes[planet.id]['currentData'].line].y"
                     :transform="`${arrow_coordinates[changes[planet.id]['currentData'].line].rotationLeft}`"
                     width="60" height="30">
        <svg width="55" height="17" viewBox="0 0 55 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M2.53122 9.90613C2.11256 10.4446 2.34389 11.2349 2.98681 11.4626L9.47401 13.7602C10.2428 14.0325 10.9967 13.3194 10.7676 12.5366L8.50485 4.80613C8.27573 4.02337 7.25634 3.82934 6.75569 4.47321L2.53122 9.90613Z" fill="#17C293"/>
          <path d="M2.53122 9.90613C2.11256 10.4446 2.34389 11.2349 2.98681 11.4626L9.47401 13.7602C10.2428 14.0325 10.9967 13.3194 10.7676 12.5366L8.50485 4.80613C8.27573 4.02337 7.25634 3.82934 6.75569 4.47321L2.53122 9.90613Z" fill="#95B2AD"/>
          <path d="M6.91753 9.52338C6.64394 8.70396 7.0862 7.81712 7.90832 7.55174C22.3288 2.89684 37.3493 0.362043 52.4988 0.0268279C53.3625 0.00771721 54.0713 0.700309 54.0818 1.56413V1.56413C54.0923 2.42796 53.4004 3.13589 52.5368 3.15518C37.7113 3.48625 23.0122 5.96681 8.89912 10.5193C8.07694 10.7845 7.19111 10.3428 6.91753 9.52338V9.52338Z" fill="#17C293"/>
          <path d="M6.91753 9.52338C6.64394 8.70396 7.0862 7.81712 7.90832 7.55174C22.3288 2.89684 37.3493 0.362043 52.4988 0.0268279C53.3625 0.00771721 54.0713 0.700309 54.0818 1.56413V1.56413C54.0923 2.42796 53.4004 3.13589 52.5368 3.15518C37.7113 3.48625 23.0122 5.96681 8.89912 10.5193C8.07694 10.7845 7.19111 10.3428 6.91753 9.52338V9.52338Z" fill="#95B2AD"/>
        </svg>

      </foreignObject>

      <foreignObject id="arrow-right" v-else class="arrow-right"
                     :x="arrow_coordinates[changes[planet.id]['currentData'].line].x" :y="arrow_coordinates[changes[planet.id]['currentData'].line].y"
                     :transform="`${arrow_coordinates[changes[planet.id]['currentData'].line].rotationRight}`"
                     width="60" height="30">
        <svg width="55" height="17" viewBox="0 0 55 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M51.6494 9.90613C52.0681 10.4446 51.8368 11.2349 51.1939 11.4626L44.7066 13.7602C43.9378 14.0325 43.1839 13.3194 43.4131 12.5366L45.6758 4.80613C45.9049 4.02337 46.9243 3.82934 47.425 4.47321L51.6494 9.90613Z" fill="#17C293"/>
          <path d="M51.6494 9.90613C52.0681 10.4446 51.8368 11.2349 51.1939 11.4626L44.7066 13.7602C43.9378 14.0325 43.1839 13.3194 43.4131 12.5366L45.6758 4.80613C45.9049 4.02337 46.9243 3.82934 47.425 4.47321L51.6494 9.90613Z" fill="#95B2AD"/>
          <path d="M47.2631 9.52338C47.5367 8.70396 47.0945 7.81712 46.2723 7.55174C31.8518 2.89684 16.8313 0.362043 1.68184 0.0268279C0.818162 0.00771721 0.109365 0.700309 0.0988881 1.56413V1.56413C0.088411 2.42796 0.780237 3.13589 1.64391 3.15518C16.4694 3.48625 31.1685 5.96681 45.2815 10.5193C46.1037 10.7845 46.9896 10.3428 47.2631 9.52338V9.52338Z" fill="#17C293"/>
          <path d="M47.2631 9.52338C47.5367 8.70396 47.0945 7.81712 46.2723 7.55174C31.8518 2.89684 16.8313 0.362043 1.68184 0.0268279C0.818162 0.00771721 0.109365 0.700309 0.0988881 1.56413V1.56413C0.088411 2.42796 0.780237 3.13589 1.64391 3.15518C16.4694 3.48625 31.1685 5.96681 45.2815 10.5193C46.1037 10.7845 46.9896 10.3428 47.2631 9.52338V9.52338Z" fill="#95B2AD"/>
        </svg>

      </foreignObject>


   </svg>

    <div class="planet-watch-tables grid grid-cols-md-24 gap-12 align-items-center pb-5" style="margin-top:-65px;">
      <div class="col-span-md-7">
        <p class="watch-table-label">Activated</p>

        <p  v-if="justNow" class="watch-table-value">{{calculateTimeDifference(changes[planet.id]['currentData']['lineStartDate'].datetime)}} ago</p>
        <p v-else>{{formatDate(changes[planet.id]['currentData']['lineStartDate'].datetime,false, 'D MMM YYYY HH:mm')}}</p>
      </div>
      <div class="col-span-md-10 next-shift-box" @click="changeTransitTimestamp(changes[planet.id][dataTypes.nextLine].datetime)">
        <p class="watch-table-label">Next Shift</p>
        <p class="watch-table-value">
          {{ formatDate(changes[planet.id][dataTypes.nextLine].datetime, false, 'D MMM YYYY HH:mm')}}
        </p>

        <planet-overview-timer v-if="justNow" :key="progressKey" :planet-id="planet.id" :type="dataTypes.nextLine" :calculate-real-time-for-activation="calculateRealTimeForActivation"
                               :calculate-time-for-activation="calculateTimeForActivation"></planet-overview-timer>

      </div>
      <div class="col-span-md-7">
        <p class="watch-table-label">{{ $t('charts.transit.duration') }}</p>
        <p class="watch-table-value">
          {{calculateTimeDifference(changes[planet.id]['currentData']['lineStartDate'].datetime,
            changes[planet.id][dataTypes.nextLine].datetime, false)}}</p>
      </div>
    </div>
  </div>


</template>
<style lang="scss">
.theme--light{
  #planet-watch{
    .line-activation-bg{
      fill:#f8f7f7;
      stroke: white;
    }
    #circle{
      fill:white;
    }
    .planet-watch-tables{
      > div{
        background: #f8f7f7;
      }
    }
  }

}
.theme--dark{
  #planet-watch{
    .line-activation-bg{
      fill:$bg-dark;
      stroke: #282727;
    }
    #circle{
      fill:#282727;
    }
    .line-activation{
      fill:white;
    }

    .planet-watch-tables{
      > div{
        background:$bg-dark;
      }
    }
  }
}
#planet-watch{

  .planet-watch-tables{
    .next-shift-box {
      cursor: pointer;

      &:hover {
        background: #95B2AD20;

      }
    }

    > div{
      border-radius:20px;
      padding:25px 15px;
      &:nth-child(2){
        padding:50px 15px;
      }
    }
  }
  svg{
    path{
      &:hover{
        fill:$transit-color;
      }
    }
  }
  .line-activation{
    font-size:20px;
    font-weight:600;
    fill:black;
    &.low-opacity{
      opacity:20%;
    }
    &.active{
      fill:white;
    }
  }

  .line-activation-bg{
    &.active{
      fill:$transit-color;
    }
  }
}
</style>

<script>
import TransitToolMixin from "../../../../mixins/TransitToolMixin";
import PlanetOverviewTimer from "./planet-overview-timer";
export default{
  components: {PlanetOverviewTimer},
  mixins: [TransitToolMixin],

  props:{
    planet: Object
  },

  watch:{
    'planet.id'(){
      this.prepareActivationStatuses()
    },
    justNow(val){
      if(val){
        this.updateShiftingRealTime();
      } else this.clearInterval();
    }
  },
  computed:{
  },

  mounted(){
    this.prepareActivationStatuses();
    if(this.justNow) this.updateShiftingRealTime();
  },

  data(){
    return{
      progressKey: 0,
      interval: null,
      arrow_coordinates: {
        1: {x: 120, y: 200, rotationLeft:'rotate(-55 125 250)', rotationRight: 'rotate(-80 125 247)'},
        2: {x: 173, y: 195, rotationLeft:'rotate(-25 55 315)', rotationRight: 'rotate(-55 125 250)'},
        3: {x: 175, y: 115, rotationLeft:'rotate(0 95 305)', rotationRight: 'rotate(-25 205 160)'},
        4: {x: 230, y: 120, rotationLeft:'rotate(25 250 150)', rotationRight: 'rotate(0 125 250)'},
        5: {x: 300, y: 135, rotationLeft:'rotate(55 305 150)', rotationRight: 'rotate(29 283, 128)'},
        6: {x: 385, y: 0, rotationLeft:'rotate(80 255 85)', rotationRight: 'rotate(55 185 55)'},
      }
    }
  },

  destroyed() {
    this.clearInterval()
  },
  methods:{

    updateShiftingRealTime(){

      this.interval = setInterval(()=>{
        if(this.progressKey > 8){
          this.progressKey = 0;
        }else{
          this.progressKey++;
        }
      }, 1000)
    },
    clearInterval(){
      if(this.interval) clearInterval(this.interval);
    },
    getPercentageNum(num){
      try{
        return num.toFixed(1)
      }catch(e){
        return '-'
      }
    },
    prepareActivationStatuses(){

      try{
        // loop through those.
        for(let i=0;i<6;i++){
          let num = i+1;
          let elem = document.getElementById('l-'+num);
          let bg =  document.getElementById('lb-'+num);

          if(num === this.changes[this.planet.id].currentData.line){
            elem.classList.add('active');
            bg.classList.add('active');
          }else{
            elem.classList.remove('active');
            bg.classList.remove('active');
          }

          if(num > this.changes[this.planet.id].currentData.line){
            elem.classList.add('low-opacity')
          }else{
            elem.classList.remove('low-opacity')
          }
        }

      }catch(e){
        console.log(e);
      }

    }

  }

}
</script>