import activationModes from './activationModes'
import authorities from './authorities'
import baseOrientations from './baseOrientations'
import centers from './centers'
import centerDefinitionStates from './centerDefinitionStates'
import channels from './channels'
import channelCompositionStates from './channelCompositionStates'
import cognitions from './cognitions'
import connectionThemes from './connectionThemes'
import pentaThemes from './pentaThemes'
import pentaKeynotes from './pentaKeynotes'
import crosses from './crosses'
import cycles from './cycles'
import definitions from './definitions'
import determinations from './determinations'
import environments from './environments'
import fixings from './fixings'
import motivations from './motivations'
import planets from './planets'
import profiles from './profiles'
import senses from './senses'
import signatures from './signatures'
import strategies from './strategies'
import notSelfThemes from './notSelfThemes'
import tonalFixings from './tonalFixings'
import tonalBinaries from './tonalBinaries'
import types from './types'
import variables from './variables'
import views from './views'
import zodiacs from './zodiacs'

export default {
  activationModes,
  authorities,
  baseOrientations,
  centers,
  centerDefinitionStates,
  channels,
  channelCompositionStates,
  cognitions,
  connectionThemes,
  pentaThemes,
  pentaKeynotes,
  crosses,
  cycles,
  definitions,
  determinations,
  environments,
  fixings,
  motivations,
  planets,
  profiles,
  senses,
  signatures,
  strategies,
  notSelfThemes,
  tonalFixings,
  tonalBinaries,
  types,
  variables,
  views,
  zodiacs,
  isLocked: {
    true: 'Analysis Unlocked',
    false: 'Locked Charts'
  },
  celebrity: {
    true: 'Enable Celebrity Charts'
  },
  circuits: {
    0: 'Collective Logic',
    1: 'Collective Abstract',
    2: 'Individual Knowing',
    3: 'Individual Centering',
    4: 'Integration Channels',
    5: 'Tribal Defence',
    6: 'Tribal Ego'
  }
}
