<template>
  <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.61902 3.52C7.93102 3.03467 8.58969 3 8.86702 3C9.14435 3 9.56169 3 10.0124 3.52C10.6697 4.28267 10.219 5.08 9.9417 5.56533C9.66437 6.08533 9.66437 6.50133 9.87237 6.77867C10.219 7.19467 10.6004 7.264 11.0857 7.33333C11.3284 7.368 11.4324 7.472 11.5017 7.64533C11.7346 8.2083 13.2697 11.8053 13.7204 12.9493C13.7897 13.1227 13.7897 13.296 13.7897 13.296C13.7897 13.4 13.7204 13.5387 13.651 13.608C11.987 15.48 10.8084 16 8.86702 16C6.92569 16 5.74704 15.48 4.08302 13.608C4.01369 13.5387 3.94304 13.4 3.94435 13.296C3.94435 13.296 3.94435 13.1227 4.01502 12.9493C4.46435 11.8053 5.8231 8.59721 6.23235 7.64533C6.30169 7.472 6.40569 7.368 6.64835 7.33333C7.13369 7.264 7.51502 7.19467 7.86169 6.77867C8.00035 6.60533 8.06723 6.29614 8.03634 6.224C7.99697 6.12217 7.92442 6.11738 7.723 6.15467C7.619 6.15467 7.44567 6.12 7.44567 5.94667C7.44567 5.77333 7.44567 5.392 7.411 5.35733C7.37634 5.288 7.23632 5.288 7.23767 5.184C7.23767 5.14933 7.48034 4.69867 7.48034 4.62933C7.48034 4.56 7.411 4.456 7.37634 4.42133C7.34167 4.38667 7.37635 3.90133 7.61902 3.52Z"
          fill="#191C23"/>
    <path d="M7.61902 3.52C7.93102 3.03467 8.58969 3 8.86702 3C9.14435 3 9.56169 3 10.0124 3.52C10.6697 4.28267 10.219 5.08 9.9417 5.56533C9.66437 6.08533 9.66437 6.50133 9.87237 6.77867C10.219 7.19467 10.6004 7.264 11.0857 7.33333C11.3284 7.368 11.4324 7.472 11.5017 7.64533C11.7346 8.2083 13.2697 11.8053 13.7204 12.9493C13.7897 13.1227 13.7897 13.296 13.7897 13.296C13.7897 13.4 13.7204 13.5387 13.651 13.608C11.987 15.48 10.8084 16 8.86702 16C6.92569 16 5.74704 15.48 4.08302 13.608C4.01369 13.5387 3.94304 13.4 3.94435 13.296C3.94435 13.296 3.94435 13.1227 4.01502 12.9493C4.46435 11.8053 5.8231 8.59721 6.23235 7.64533C6.30169 7.472 6.40569 7.368 6.64835 7.33333C7.13369 7.264 7.51502 7.19467 7.86169 6.77867C8.00035 6.60533 8.06723 6.29614 8.03634 6.224C7.99697 6.12217 7.92442 6.11738 7.723 6.15467C7.619 6.15467 7.44567 6.12 7.44567 5.94667C7.44567 5.77333 7.44567 5.392 7.411 5.35733C7.37634 5.288 7.23632 5.288 7.23767 5.184C7.23767 5.14933 7.48034 4.69867 7.48034 4.62933C7.48034 4.56 7.411 4.456 7.37634 4.42133C7.34167 4.38667 7.37635 3.90133 7.61902 3.52Z"
          fill="#2F2F2F"/>
    <path d="M7.61902 3.52C7.93102 3.03467 8.58969 3 8.86702 3C9.14435 3 9.56169 3 10.0124 3.52C10.6697 4.28267 10.219 5.08 9.9417 5.56533C9.66437 6.08533 9.66437 6.50133 9.87237 6.77867C10.219 7.19467 10.6004 7.264 11.0857 7.33333C11.3284 7.368 11.4324 7.472 11.5017 7.64533C11.7346 8.2083 13.2697 11.8053 13.7204 12.9493C13.7897 13.1227 13.7897 13.296 13.7897 13.296C13.7897 13.4 13.7204 13.5387 13.651 13.608C11.987 15.48 10.8084 16 8.86702 16C6.92569 16 5.74704 15.48 4.08302 13.608C4.01369 13.5387 3.94304 13.4 3.94435 13.296C3.94435 13.296 3.94435 13.1227 4.01502 12.9493C4.46435 11.8053 5.8231 8.59721 6.23235 7.64533C6.30169 7.472 6.40569 7.368 6.64835 7.33333C7.13369 7.264 7.51502 7.19467 7.86169 6.77867C8.00035 6.60533 8.06723 6.29614 8.03634 6.224C7.99697 6.12217 7.92442 6.11738 7.723 6.15467C7.619 6.15467 7.44567 6.12 7.44567 5.94667C7.44567 5.77333 7.44567 5.392 7.411 5.35733C7.37634 5.288 7.23632 5.288 7.23767 5.184C7.23767 5.14933 7.48034 4.69867 7.48034 4.62933C7.48034 4.56 7.411 4.456 7.37634 4.42133C7.34167 4.38667 7.37635 3.90133 7.61902 3.52Z"
          :fill="active ? '#ffffff' : `#434343`"/>
    <rect x="16" y="8" width="2" height="3" rx="1" fill="#60AA90"/>
    <rect y="6" width="2" height="3" rx="1"
          :fill="active ? '#ffffff' : `#C25354`"/>
    <rect y="10" width="2" height="3" rx="1" fill="#434343"/>
  </svg>
</template>

<script>
export default {
  props: {
    active: Boolean
  }
}
</script>