export default {
  'g5header': 'Home',
  'g15header': 'Inner Flow',
  'g14header': 'Demands',
  'g2header': 'Material',
  'g29header': 'World',
  'g46header': 'Discovery',
  'g7header': 'Future',
  'g31header': 'Discipline',
  'g1header': 'Present',
  'g8header': 'Demonstration',
  'g13header': 'Past',
  'g33header': 'Tradition',
  'g5keynote': 'Culture',
  'g15keynote': 'Reliable',
  'g14keynote': 'Capacity',
  'g2keynote': 'Vision',
  'g29keynote': 'Commitment',
  'g46keynote': 'Coordination',
  'g7keynote': 'Planning',
  'g31keynote': 'Administration',
  'g1keynote': 'Implementation',
  'g8keynote': 'Public Relations',
  'g13keynote': 'Accounting',
  'g33keynote': 'Oversight',
  'line6': 'Leader',
  'line5': 'Messenger',
  'line4': 'Influencer',
  'line3': 'Pioneer',
  'line2': 'Natural',
  'line1': 'Authority'
}