<template>

  <div>
    <div v-for="circuit in circuits" :key="`toggle-c-${circuit.id}`"
         class="mb-3 accordion-item casual-border-radius ease-in-out duration-300"
         :class="active === circuit.id ? 'collapsed' : 'not-collapsed'">
      <b-button
          variant="link"
          size="xs"
          class="w-100 accordion-toggler text-left font-weight-bold"
          @click="toggle(circuit.id)"
      >
        <p class="mb-0 text-lg gap-5">
          {{$t(`hd.circuits.${circuit.id}.name`)}}
          <span class="float-right">
                    <span class="icon-arrow-down"></span>
                </span>

        </p>
      </b-button>
      <div v-show="active === circuit.id"
           class="p-2"
           :key="'c-' + circuit.id" :id="`item-${circuit.id}`">

        <div v-if="gates && gates.length > 0" class="d-flex flex-wrap gap-10">
          <IchingGate v-for="(gate,index) in circuit.gates" :key="`${index}-c-g-${gate}-${circuit.id}`" @gateClicked="$emit('gateClicked', getGate(gate))"
                       :gate="gate" :gate-object="getGate(gate)" />
        </div>

      </div>
    </div>

  </div>
</template>

<script>


import channels from "../../../../helpers/rave/constants/channels";
import IchingGate from "./IchingGate.vue";
import {mapState} from "vuex";

export default{
  components: {IchingGate},
  props:{
    gates: Array,
  },


  computed:{

    ...mapState({
      active: state => state.ui.iching.circuitsActiveTab
    }),

    circuits(){
      let circuits = {};

      for(let channel of channels){
        if(!circuits[channel.circuit]) circuits[channel.circuit] = {
          id: channel.circuit,
          gates: new Set()
        };

        circuits[channel.circuit].gates.add(channel.gates[0]);
        circuits[channel.circuit].gates.add(channel.gates[1]);
      }

      for(let value of Object.values(circuits)){
        [...value.gates].sort(
            (a,b) => Number(a) - Number(b)
        )
      }
      return circuits;
    },

  },


  data(){
    return {
      centerIds:[0,1,2,3,4,5,6,7,8].reverse(),
    }
  },

  methods:{
    getGate(id){
      return this.gates.find(x => x.gate_id === id);
    },
    toggle(id){

      this.$store.commit('ui/SET_ICHING_ACTIVE_TABS', {
        circuitsActiveTab: this.active === id ? null : id
      })
    }
  }
}
</script>