/* eslint-disable */
import * as R from 'ramda'
import ViewMode from '../../helpers/utils/ViewMode'
import { calculateFixings } from './fixing'
import { calculateBaseAlignments } from './baseAlignments'
import {
  getCrossPlanets,
  getDefinedGates,
  getGateActivationModes,
  getDefinedChannels,
  getCenterDefinitions,
  getPlanetsOfActivationType,
  getPlanetsOfChartId,
  getPlanetsOfCircuit,
  getProfile,
  calculateDerivedProperties,
  getCross,
  getAIprops,
  getChannelCompositeStates
} from './queries'

const preparePlanets = R.pipe(
  calculateFixings,
  calculateBaseAlignments
)

const planetsForViewMode = R.curry((viewMode, planets) => viewMode.cata({
  Normal: () => planets,
  Cross: () => getCrossPlanets(planets),
  Circuit: (val) => getPlanetsOfCircuit(val, planets),
  SpecificActivation: (val) => getPlanetsOfActivationType(val, planets),
  SpecificChart: (val) => getPlanetsOfChartId(val, planets)
}))

export const computeChart = srcPlanets => {
  let planets = preparePlanets(srcPlanets)
  let gates = getDefinedGates(planets)
  let channels = getDefinedChannels(gates)

  return {
    planets,
    gates: getGateActivationModes(planets),
    channels,
    centers: getCenterDefinitions(gates, channels),
    profile: getProfile(planets),
    cross: getCross(planets),
    ...getAIprops(planets),
    ...calculateDerivedProperties(channels)
  }
}


export const adaptChartForViewMode = R.curry((viewMode, chart) => R.ifElse(
  R.either(R.isNil, v => ViewMode.Normal.is(v)),
  R.always(chart),
  _ => R.pipe(
    planetsForViewMode(viewMode),
    computeChart
  )(chart.planets)
)(viewMode))
