<template>
  <div id="path" class="px-4">
    <inner-tabs-renderer :id="2"
                         type="advanced"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)">
      <template #unlock-prompt> <slot name="unlock-prompt"/> </template>
    </inner-tabs-renderer>
  </div>
</template>

<script>
import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer";
import {mapState} from "vuex";
import activationModes from "../../../../helpers/rave/constants/activationModes";
import planetConstants from "../../../../helpers/rave/constants/planets";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import ColorDataRenderer from "./elements/ColorDataRenderer.vue";
import ColorToneComboDataRenderer from "./elements/ColorToneComboDataRenderer.vue";
import InnerTabsRenderer from "../../../renderer/InnerTabsRenderer.vue";

export default{
  components: {
    InnerTabsRenderer,
  },
  mixins: [AnalysisQueryMixin],
  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
    tabs() {
      return [
        {
          id: 0,
          title: this.$t('hd.properties.introduction'),
          component: DynamicZoneRenderer,
          shouldRender: !!this.intro,
          props: {
            components: this.intro ? this.intro.components : [],
            addPaddings: false
          },
        },
        {
          id: 1,
          title: 'Environment',
          component: ColorDataRenderer,
          shouldRender: true,
          props: {
            activation: this.activation,
            type: this.type,
            tone: this.tone,
            color: this.color,
          }
        },
        {
          id: 2,
          title: 'Orientation',
          component: ColorToneComboDataRenderer,
          shouldRender: true,
          props: {
            activation: this.activation,
            color: this.color,
            tone: this.tone,
            type: this.type
          }
        }
      ]
    }
  },
  data(){
    return{
      tab: 0,
      audioKey: 0,
      intro: null,
      activation: 'design',
      type: 'external',
      color: null,
      tone: null
    }
  },
   created(){

    this.getContent('intro', '/analysis/introductions/introduction.advanced.3');

    let planet = this.chart.chart.planets.find(x => x.activation === activationModes.DESIGN && (x.id === planetConstants.SOUTH_NODE || x.id === planetConstants.NORTH_NODE));
    this.tone = planet.tone <= 3 ? 'low' : 'high';
    this.color = planet.color;
   },
}
</script>