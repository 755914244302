export default {
    all: 'All',
    selected: 'Selected',
    clear: 'Clear',
    display: 'Display',
    select: 'Select',
    charts: 'Charts',
    orderings: {
        creationOrderDesc: 'Date (newest first)',
        creationOrderAsc: 'Date (oldest first)',
        nameASC: 'Name (A-Z)',
        nameDESC: 'Name (Z-A)',
        docType: 'Chart Type'
    },
    filters: {
        search: 'Search filter'
    },
    show: {
        title: 'Show',
        all: 'All Charts',
        personal: 'Personal Charts',
        connection: 'Connection Charts',
        cycle: 'Cycle Charts',
        transit: 'Transit Charts',
        transitConnection: 'Transit Connection Charts'
    },
    lockedStatus: {
        title: 'Chart Analysis'
    },
    celebrity: {
        one: 'Celebrity',
        title: 'Celebrity charts',
        more: 'More Charts',
    },
    search: {
        recent: 'Recent Charts',
        results: 'Search results',
        knowledge: 'Knowledge Base',
        notes: 'Notes',
        showAll: 'Show all results',
        celebrity: 'Start typing to search for a celebrity chart...'
    },
    attributes: {
        title: 'Chart Attributes',
    },
    tags: {
        title: 'Tags',
        manage: 'Manage Tags',
        select: "Select a tag from the list to edit it..."
    },
    more: {
        title: 'More Options',
    },
    bulkTags: {
        title: 'Bulk edit tags',
        add: 'Add tags',
        remove: 'Remove tags',

    }
}