import variable from '@/helpers/rave/constants/variables'

export default {
  [variable.PLL_DLL]: 'PLL DLL',
  [variable.PLL_DLR]: 'PLL DLR',
  [variable.PLL_DRL]: 'PLL DRL',
  [variable.PLL_DRR]: 'PLL DRR',
  [variable.PLR_DLL]: 'PLR DLL',
  [variable.PLR_DLR]: 'PLR DLR',
  [variable.PLR_DRL]: 'PLR DRL',
  [variable.PLR_DRR]: 'PLR DRR',
  [variable.PRL_DLL]: 'PRL DLL',
  [variable.PRL_DLR]: 'PRL DLR',
  [variable.PRL_DRL]: 'PRL DRL',
  [variable.PRL_DRR]: 'PRL DRR',
  [variable.PRR_DLL]: 'PRR DLL',
  [variable.PRR_DLR]: 'PRR DLR',
  [variable.PRR_DRL]: 'PRR DRL',
  [variable.PRR_DRR]: 'PRR DRR'
}
