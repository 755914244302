<template>

  <div id="planet-a-w">

    <div class="d-flex flex-shrink-0 planet-selector-background overflow-auto pb-3">
      <div v-for="planet of getPlanets" :key="planet.id" @click="selectPlanet(planet.id)"
           class="mx-1 flex-shrink-0 px-2 py-2 text-center planet-selection-box" style="min-width:50px;"
      :class="planet.id === selectedPlanet ? 'active' : ''">
        <span :class="`icon-${planet.icon}`"></span><p class="mb-0 text-sm">{{planet.name}}</p>
      </div>
    </div>



    <div>
      <planet-activation-watch v-if="changes && getPlanetById(selectedPlanet)" :planet="getPlanetById(selectedPlanet)"></planet-activation-watch>
    </div>

  </div>

</template>

<style lang="scss">

#planet-a-w{

}
</style>

<script>
import TransitToolMixin from "../../../mixins/TransitToolMixin";
import PlanetActivationWatch from "./elements/planet-activation-watch";
import {mapState} from "vuex";
export default{

  name: 'planet-a-w',
  components: {PlanetActivationWatch},
  mixins: [TransitToolMixin],

  computed:{

    ...mapState({
      selectedPlanet: state => state.charts.transit.activationSelectedPlanet
    })
  },

  mounted(){

  },

  data(){
    return{
    }
  },

  methods:{

    getPlanetById(id){
      return this.getPlanets.find(x => x.id === id);
    },
    selectPlanet(id){
      this.$store.commit('charts/SET_TRANSIT_ACTIVATION_SELECTED_PLANET', id);
    }

  }

}
</script>