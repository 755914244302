<template>
  <div class="center">
    <b-overlay :show="loading" rounded="sm">
      <b-card v-if="!isRelatedToggle" class="bg-gray shadow-none text-center py-3 mt-3 mb-4">
        <div class="row mb-3 prev-next">
          <div class="col-6">
            <div v-if="prev" class="d-flex align-items-center cursor-pointer " style="opacity:0.7;"
                 @click="$emit('changeCenter', prev.id)">
              <svg fill="none" height="14" style="transform:rotate(180deg)" viewBox="0 0 8 14" width="8"
                   xmlns="http://www.w3.org/2000/svg">
                <path :stroke="darkMode ? '#fff' : '#191c23'" d="M1 13L7 7L0.999999 1" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1.5"/>
              </svg>
              <p class="ml-2 mb-0 d-flex align-items-center text-md">

                {{ prev.name }}
                <span :class="`icon-center-${prev.icon}`" class="ml-2"></span>
              </p>

            </div>

          </div>
          <div class="col-6 text-right" @click="$emit('changeCenter', next.id)">

            <div v-if="next" class="d-flex align-items-center ml-auto justify-content-end cursor-pointer"
                 style="opacity:0.7;">
              <p class="mr-2 mb-0 d-flex align-items-center text-md">
                <span :class="`icon-center-${next.icon}`" class="mr-2"></span>
                {{ next.name }}
              </p>
              <svg fill="none" height="14" viewBox="0 0 8 14" width="8" xmlns="http://www.w3.org/2000/svg">
                <path :stroke="darkMode ? '#fff' : '#191c23'" d="M1 13L7 7L0.999999 1" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="1.5"/>
              </svg>
            </div>

          </div>
        </div>

        <span v-if="center" :class="`icon-center-` + center.icon" style="font-size:2.5rem"></span>
        <h3 class="font-weight-bolder mt-3">{{
            $t('hd.centers.' + centerId + '.name') + ' ' + $t('hd.objects.center')
          }}</h3>
        <h6 class="font-weight-normal"> {{ subtitle }}</h6>
      </b-card>

      <center-content :center-id="centerId" :loading="loading" :state="state" :type="type"
                        @changeSubtitle="sub => this.subtitle = sub"
                        :reverse-background="reverseBackground"
                        @loading="val => loading = val"/>
      <center-related-links
          v-if="showRelatedLinks"
          :selected-id="centerId"
          :depth="depth"
          :is-nested="isRelatedToggle"
      />
    </b-overlay>
  </div>


</template>

<style lang="scss">
.center .centerary-tab {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}
</style>
<script>
import {mapState} from "vuex";
import CenterContent from "../content/CenterContent";
import CenterRelatedLinks from "../related-links/CenterRelatedLinks";

export default {
  components: {CenterRelatedLinks, CenterContent},
  props: {
    isRelatedToggle: {
      type: Boolean,
      default: false,
    },
    showRelatedLinks: {
      type: Boolean,
      default: true
    },
    depth: Number,
    centerId: Number,
    state: Number,
    type: String,
    center: Object,
    prev: Object,
    next: Object,
    reverseBackground: Boolean
  },

  computed: {
    ...mapState({
      chart: state => state.charts.chart
    }),
  },
  data() {
    return {
      loading: false,
      subtitle: ''
    }
  },

}
</script>