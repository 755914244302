import planet from '@/helpers/rave/constants/planets'

export default {
  [planet.SUN]: {
    name: 'Sun',
    title: 'The Sun',
    keynote: 'Personality Expression & Life Force',
    desc: `The Sun represents our core energy, our primal life force. Our Personality Sun shows how we express our Self (essence) in the world, while the Design Sun denotes the genetic themes inherited from our father, themes lived out unconsciously. 
          
          The Sun is our closest star and we receive 70% of the neutrinos from it. Therefore, it has the strongest influence in our life. The Sun represents the theme of our incarnation, how we express our “light” in the world. It is the primary Yang force.`

  },
  [planet.EARTH]: {
    name: 'Earth',
    title: 'The Earth',
    keynote: 'Grounding, Stability and Balance',
    desc: `The Earth represents how we find grounding, how we ground the energy of the Sun in our form, our body. The Sun and Earth always operate together and are located opposite each other in the Rave Mandala. 
          
          It is through the Earth that we can find balance when dealing with the world of form and build stability if we are able to integrate the Personality correctly in our life. At an unconscious level, through the Earth we derive our capacity to come to terms with the world of form. The Earth is the primary Yin force.`

  },
  [planet.MOON]: {
    name: 'Moon',
    title: 'The Moon',
    keynote: 'The Driving Force',
    desc: `The Moon represents the force that moves us—the driving force in our Design focused through the lens of the gate it activates. Lunar energy is experienced through our form as we experience daily life through the body's instinctive mechanisms. 
          
          The Moon travels through all the gates of the bodygraph over the course of a month (approximately) and therefore is a potent force in our lives.The energy and thematic of the Sun inform the Moon and are reflected by it, thereby giving it expression.`
  },
  [planet.NORTH_NODE]: {
    name: 'North Node',
    title: 'The North Node',
    keynote: 'Future Direction and Environment',
    desc: `In Design Analysis the Nodes of the Moon divide life into two basic dynamics, the South and North Node phases. The Nodes are not planets, but positions—points where the path of the Moon crosses the ecliptic—and constitute an essential aspect of our Design. 
          
          The Personality North Node reveals the perspectives we develop about the world in our second half of life while the Design Node delineates how we experience and relate to our environment. 
          
          The theme of our North Nodes sets the stage for the mature way of experiencing life after our Uranus Opposition (38-43 years), when we shift from the South to the North node and move toward a place of accomplishment and fulfillment. During the North Node phase we integrate our learning experience from the first half of life. `
  },
  [planet.SOUTH_NODE]: {
    name: 'South Node',
    title: 'The South Node',
    keynote: 'Past Direction and Environment',
    desc: `In Design Analysis the Nodes of the Moon divide life into two basic dynamics: the South and North Node phases. The Nodes are not planets, but positions—points where the path of the Moon crosses the ecliptic—and constitute an essential aspect of our Design.
    
          The South Node phase is the developmental stage of experiencing early life until we reach our Uranus Opposition at around 38 - 43 years. The transition from the South Node to the North Node symbolizes that point when we have reached a place of maturity. 
          
          The Personality South Node, through the gate/line it activates, illustrates how we perceive the world around us and the perspectives we develop about it during the first half of life. On the Design side, it describes the early environments that form the background within which we experience life.`
  },
  [planet.MERCURY]: {
    name: 'Mercury',
    keynote: 'Communication and Thinking',
    desc: `Mercury is associated with Mind and mental functions. Mercury activations in the chart point to essential concepts that need to be expressed. 
          
          On the Personality side, the theme of the gate it activates shows what we need to communicate and how we will express it. On the Design Side, it denotes a theme that is largely unconscious but equally important, and therefore builds pressure until it finds a way to express itself.`
  },
  [planet.VENUS]: {
    name: 'Venus',
    keynote: 'Values and Relating',
    desc: `Venus establishes our values, and affects how we interact and relate with each other, and what we deem valuable. The qualities of Venus are associated with beauty, grace, and aesthetics. 
          
          Venus represents our personal morals, which determine how we relate on an individual and communal/collective level. The Venus theme in our life is described by the gate, and more specifically by the line that is activated.`
  },
  [planet.MARS]: {
    name: 'Mars',
    keynote: 'Energy Dynamics',
    desc: `Mars symbolizes the use of energy, from the early immature way of using this primal force, completely free of responsibility and often uncontrollably, to a mature use later in life. It represents the fundamental dynamic drive to use energy that is available for our life process and personal transformation. 
          
          Over time, we learn to use this energy in a wiser and more discerning way. The gate that Mars activates shows a specific area/theme through which this dynamic force is focused to teach us about correct use of energy.`
  },
  [planet.JUPITER]: {
    name: 'Jupiter',
    keynote: 'Law and Protection',
    desc: `Jupiter is a massive planet which, in astronomical and physical terms, exerts great influence in our solar system. Jupiter represents the law and the underlying principles that create, reinforce and uphold the law(s). 
          
          It symbolizes the expansion of consciousness and broadens our perspectives so we can understand our relationship to the whole. It is an extremely benevolent force and can lead to growth if we observe the ethics and morals that are intrinsic to our process.`
  },
  [planet.SATURN]: {
    name: 'Saturn',
    keynote: 'Discipline and Limitation',
    desc: `At around age 29 - 31, we complete our first Saturn cycle, signifying the beginning of adulthood. Saturn is best described as the task-master, the discipline that allows us to follow through with our tasks in this lifetime. Whatever is left unattended to will show up as limitations or burdens that hinder our progress until we accept our obligations. These limitations are primarily focused in the gates activated by Saturn. 
          
          Saturn builds structures, and once we attend to and fulfill our responsibilities, we experience the positive side of Saturn—the support it provides us so that we may accomplish our purpose in life and live out our destiny.`
  },
  [planet.URANUS]: {
    name: 'Uranus',
    keynote: 'Unusualness, Chaos, and Innovation',
    desc: `Uranus in your chart describes where you need to follow your own track, where you can express yourself in unique and different ways. Uranus breaks up the old order once limitations need to be transcended so the new may emerge. This is often precipitated by instability and chaos out of which new forms can be created. 
          
          The gate it activates denotes an area where you are subject to possibly sudden and unexpected experiences typical of this Uranian dynamic, with a break up of the old forms around midlife and the subsequent renewal in the second half of life, after the Uranus opposition. `
  },
  [planet.NEPTUNE]: {
    name: 'Neptune',
    keynote: ' Illusion and Spirituality (Transcendence)',
    desc: `Neptune in your chart reveals the area where you are most susceptible to illusion and misperception. Neptune hides or veils the truth until we can perceive a deeper underlying reality. It is an unyielding and powerful force, persistently propelling us to surrender our separateness and break through the veil of Maya.* It rules both the world of glamour and of deep mystical truth. 
          
          Neptune veils the potential of the gates it activates, with the potential of magic emerging from behind the veil once we surrender.
          
          *The illusion or appearance of the phenomenal world.`
  },
  [planet.PLUTO]: {
    name: 'Pluto',
    keynote: 'Truth, Transformation and Psychology',
    desc: `A main keyword for Pluto is Transformation. The gate that Pluto activates in your chart is an area where you directly experience transformation and renewal to find truth. Pluto is an extremely powerful force that brings to the surface anything hidden that needs to be brought into the realm of consciousness. Finding those deep aspects of your own truth is a lifelong process of exploration to come to terms with that essential truth within yourself. 
          
          Ra Uru Hu on Pluto:
         
          Grandma. Grandma is delicious. When you are a kid and ask your mother, "How are babies made?" she coughs and goes to the refrigerator and changes the subject. You walk out of the house knowing you will not get a straight answer and then, next time you visit your grandma and ask her the same question, she scares the hell out of you by telling you the truth! This is the whole thing to understand about the nature of Pluto. Pluto will tell you the TRUTH. Wherever you see Pluto in your chart, this really is your truth. `
  },
  [planet.CHIRON]: {
    name: 'Chiron'
  }
}
