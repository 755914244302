<template>
  <b-card
      v-if="gateObject"
          class="cursor-pointer"
          style="width:155px;"
          @click="$emit('gateClicked', gateObject)"
  >

    <div class="d-flex gap-5">
      <div v-html="getIchingIcon(gate)" class="flex-shrink-0 h5"/>
      <h2>
        {{gate}}
      </h2>

    </div>

    <h5>{{gateObject.name}}</h5>
    {{gateObject.title_keynote}}
  </b-card>
</template>
<script>
import {getIchingIcon} from "../../../../utils/iching";

export default{
  methods: {getIchingIcon},
  props:{
    gate: Number,
    gateObject: Object
  },
}
</script>