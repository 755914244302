<template>

  <div class="mbg-country-selector w-md-75">
    <label v-if="title">{{title}}</label>
    <autocomplete ref="country-selector"
                  :items="countryItems"
                  :value="value"
                  :get-label="getLabel"
                  :component-item="template"
                  :auto-select-one-item="false"
                  :input-class="hasCountryError ? 'form-control is-invalid new-chart-input' : 'new-chart-input' "
                  @item-selected="countrySelected"
                  :placeholder="$t('general.country')"
                  :disabled="disabled"
                  @change="updateCountries">
    </autocomplete>

    <p class="form-error-indicator mb-3 mt-0" v-if="hasCountryError">
      <span>Select (by clicking) a country from the list</span>
    </p>
  </div>
</template>

<script>
import countries from "../../assets/json/countries.json";
import CountryAutocompleteItem from "./partials/CountryAutocompleteItem"
import Autocomplete from "../structure/Autocomplete/Autocomplete.vue";

export default{

  props:{
    value: [Object, String],
    title: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components:{Autocomplete},
  computed:{
    hasCountryError(){
      return this.textState.length > 0 && !this.value
    }
  },

  data(){
    return{
      textState: '',
      countries: countries,
      countryItems: [],
      template: CountryAutocompleteItem
    }
  },

  created(){
    this.prepareCountryList();
  },

  methods:{
    prepareCountryList(){
      for(let country of countries){
        country.name = this.$t('countries.' + country.id);
      }
    },
    getLabel(item){
      return item ? item.name: '';
    },

    countrySelected(country){
      this.$emit('input', country);
      let hasMultiTimezones = !countries.find(x => x.id === country.id).tz;
      this.$emit('countrySelected', hasMultiTimezones);

    },
    updateCountries (text) {
      this.textState = text;
      if(!text || text === ''){
        this.$emit('input', null);
        this.countryItems = [...this.countries];
        return;
      }

      this.countryItems = this.countries.filter(x => {
        return x.name.toLowerCase().includes(text.toLowerCase());
      })

    },
  }
}
</script>