<template>

  <b-card class="purchase-item mb-2 ease-in-out duration-300 ">
    <span class="float-right mr-4 mt-3 cursor-pointer"  @click="toggleItem">
      {{isToggled ? '-': '+'}}
    </span>

    <div class="row px-3 py-2 cursor-pointer"  @click="toggleItem">
      <div class="col-6 col-md-2">
        <label class="text-xs">{{ $t('billing.orderId') }}</label>
        <p class="text-sm mbg-text-muted"># {{item.id}} ({{$t('billing.providers.'+item.provider)}})</p>
      </div>
      <div class="col-6 col-md-5">
        <label class="text-xs">Amount</label>
        <p class="text-sm">
          {{item.amount.toFixed(2)}}$
        </p>
      </div>
      <div class="col-6 col-md-3">
        <label class="text-xs">{{ $t('general.date') }}</label>
        <p class="text-sm">
          {{getDate}}
        </p>

      </div>
      <div class="col-6 col-md-2 align-self-center"></div>
    </div>

    <div v-if="isToggled" class="py-3 px-4 text-left">

      <div>
        <label>{{$t('billing.summary')}}</label>
      </div>
      <div  class="p-0 d-block my-1" v-for="(item,index) in item.metadata.cart" :key="index +item.chart_id">

        <span class="font-weight-bold">{{getChartMeta(item.chart_id).meta?.name}}</span> —
        {{$t('billing.unlockTypes.'+item.product_key)}}
        <router-link :to="`/charts/${item.chart_id}`" class="font-weight-bold ml-2">{{
            $t('general.load')
          }}
        </router-link>
        <hr class="my-2"/>
      </div>

      <div class="text-right">
        <a v-if="invoiceUrl" :href="invoiceUrl" class="font-weight-bold text-right" target="_blank">Download Invoice</a>

      </div>
     </div>
  </b-card>
</template>

<style lang="scss" scoped>
.purchase-item{
  border:1px none;
  label{
    margin-bottom:4px;
  }
  .card-body{
    padding:0!important;
    p{
      margin-bottom:0!important;
    }
  }
}
</style>
<script>
import moment from "moment";
import {config} from "../../utils/config";

export default{
  props:{
    item: Object
  },
  computed:{
    getDate(){
      return moment(this.item.updatedAt).format(this.$store.state.account.generalOptions.dateFormatDisplay)
    },

    invoiceUrl() {
      return this.item.invoice && this.item.invoice.id ? `${config.apiUrl}/billing/invoice?token=${this.item.invoice.token}` : null
    }
  },
  created() {
    this.getChartNames();
  },
  data(){
    return{
      isToggled: false,
      chartInfo: [],
      url: config.chartsUrl  + '/api/charts/names'
    }
  },
  methods:{

    getChartMeta(id){
      return this.chartInfo.find(x => x.id === id)
    },
    getChartNames(){
      if(!this.isToggled) return;
      if(this.chartInfo.length > 0) return;

      this.axios.get(this.url, { params: {
        chartIds: this.item.metadata.cart.map(x => x.chart_id)
        }}).then(res =>{
          this.chartInfo = res.data;
      }).catch(e=>{
        console.log(e);
      })

    },
    toggleItem(){
      this.isToggled = !this.isToggled;
      if(this.isToggled) this.getChartNames();
    }
  }
}
</script>