<template>
  <div id="name-linking-dz-renderer" :class="`channel-linking-${channel.channel}`">
    <dynamic-zone-renderer :add-paddings="false"
                           :components="components"
                           :is-toggleable="true"
                           :title="title"
                           :type="type"
                           @opened="resetListeners"
    />
  </div>
</template>

<script>

import DynamicZoneRenderer from "./DynamicZoneRenderer.vue";
import HighlightingMixin from "../../mixins/HighlightingMixin";
import util from "../../mixins/util";
import {mapState} from "vuex";

export default {
  props: {
    data: Object,
    title: String,
    components: Array,
    person: Object,
    type: String,
    channel: Object,
    planets: Array,
    names: Array,
    isCompromise: Boolean
  },
  components: {DynamicZoneRenderer},
  mixins: [util, HighlightingMixin],

  computed: {
    ...mapState({
      tabs: state => state.ui.tabsMapping
    })
  },
  watch: {
    components() {
      this.fixLinks()
    }
  },

  mounted() {
    this.fixLinks();
  },

  methods: {

    hoverListenerFunctionality(e) {
      if (e.target.dataset.gateHighlight) {
        this.highlightElement({
          type: this.types.gate,
          id: +e.target.dataset.gateHighlight,
          includePlanets: true,
          maintainPlanetOrientation: true,
          orientation: +e.target.dataset.orientation === 0 ? 'left': 'right'
        });
      } else {
        this.highlightElement({
          type: this.types.channel,
          id: this.channel.channel,
          extras: {
            channelGates: this.channel.gates
          },
          includePlanets: true,
        })
      }
    },

    clickListenerFunctionality(e) {
      if (e.target.dataset.gateHighlight) {
        this.clickElement({
          type: 'gate',
          id: e.target.dataset.gateHighlight
        });
      } else {
        this.clickElement({
          type: 'channel',
          id: this.channel.channel
        });
      }
    },


    prepareEventListeners() {
      const docs = document.querySelectorAll(`.channel-linking-${this.channel.channel} .name-linking`);

      for (const doc of docs) {
        doc.addEventListener("mouseover", this.hoverListenerFunctionality);
        doc.addEventListener("mouseleave", this.clearAllHighlights);
        doc.addEventListener("click", this.clickListenerFunctionality)
      }
    },

    cleanUpEventListeners() {
      for (const doc of document.querySelectorAll(`.channel-linking-${this.channel.channel} .name-linking`)) {
        doc.removeEventListener("mouseover", this.hoverListenerFunctionality)
        doc.removeEventListener("mouseleave", this.clearAllHighlights);
        doc.removeEventListener("click", this.clickListenerFunctionality)
      }
    },

    resetListeners() {
      this.fixLinks();
    },

    fixChannelRelatedLinks() {
      const channelCombos = [this.channel.gates.join('/'), [...this.channel.gates].reverse().join('/')];

      for (let component of this.components) {
        let content = component.content;
        if (!content || content.includes('data-channel-highlight')) continue;
        for (const combo of channelCombos) {

          const fixedContent = `<span href="#${this.channel.dominantChartId}" data-name=""
                    data-channel-highlight="${this.channel.gates}"
                    data-orientation="${this.channel.dominantChartId}"
                    class="cursor-pointer name-linking active-${this.channel.dominantChartId}">
                  ${combo} <span class="chart-name">(${this.channel.dominant})
                  </span></span></span>`;

          content = content.replaceAll(combo, fixedContent);
        }
        component.content = content;
      }
    },

    fixGateRelatedLinks() {
      if (!this.planets) return;
      for (let component of this.components) {
        let content = component.content;
        if (!content || content.includes('data-gate-highlight')) continue;

        for (const gate of this.channel.gates) {
          let variation = `Gate ${gate}`;

          const planets = this.planets.filter(x => x.gate === gate);
          const planet = planets.length === 1 ? planets[0] : planets.find(x => x.chartId !== this.channel.dominantChartId) || planets[0];

          const fixedGate = `<span href="#${this.channel.dominantChartId}"
                    data-gate-highlight="${gate}"
                    class="cursor-pointer name-linking active-${planet.chartId}">
                  ${variation}
                  <span class="chart-name" data-gate-highlight="${gate}" data-orientation="${planet.chartId}">(${this.names[planet.chartId]})</span>
              </span>`;

          content = content.replaceAll(variation, fixedGate)
        }
        component.content = content;
      }

    },

    fixLinks() {
      if (!this.components) return;
      this.cleanUpEventListeners();
      this.fixChannelRelatedLinks();
      this.fixGateRelatedLinks();


      this.$nextTick(() => {
        this.prepareEventListeners()
      });
    },


    clickElement({type, id}) {
      this.fixUrlQuery(`?tab=${this.tabs[type]}&selected=${id}`);

      this.$nextTick(() => {
        this.$store.dispatch('ui/increasePropertiesKey'); // increase key to update window
        this.$store.commit('ui/SET_SHOW_PROPERTIES', true); // show panel in case it's not showing
      });
    },
  },
  destroyed() {
    this.cleanUpEventListeners();
  }
}
</script>

<style lang="scss">
#name-linking-dz-renderer {
  .name-linking {
    display: inline-flex;
    align-items: center;
    gap: 3px;
    //margin-right:5px;
    //padding-left:2.5px;
    font-weight: bold;
    //border-bottom: 3px dotted;
    &.active-0 {
      color: $connection-chart-1;
    }

    &.active-1 {
      color: $connection-chart-2;
    }

    .chart-name {
      //font-weight:400;
    }
  }

}

</style>