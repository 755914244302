<template>
  <div id="default-layout">
    <Header/>
    <div class="router-view-area">
      <slot v-if="canRender" ></slot>
    </div>

    <Footer/>
  </div>
</template>
<script>
import Header from "../components/structure/Header";
import Footer from "../components/structure/Footer";

export default{
  components: {Header, Footer},
  computed:{
    hasMicroServiceToken(){
      return !!this.$store.state.account.chartsToken
    },

    // we do not render a page that requires a chart microservice token without first
    // checking that it has it. That way we make sure we don't run into 401s when token is not fetched!
    // After the computed hasMicroServiceToken resolves, we render the page that require an active token
    // IF for some reason we do not have an active token, we dispatch an attempt to regather this token.
    // that way, we make sure that if the chart microservice is up, we can get a token and render the page!
    canRender(){
      if(this.$route.meta.requiresChartToken && !this.hasMicroServiceToken) this.$store.dispatch('account/getChartsMicroserviceToken');
      return this.$route.meta.requiresChartToken ? this.hasMicroServiceToken : true
    }
  },
  created(){
    this.setBodyScrollBehavior(true);
  }
}
</script>

<style lang="scss">
</style>