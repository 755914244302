import * as R from 'ramda'
import { funcs } from './funcs'
import objects from '../../../helpers/rave/constants/objectTypes'
import highlighting from '../../../helpers/rave/highlighting'
import zodiacs from '../../../helpers/rave/constants/zodiacs'

const getHighlightTargetNodes = (e, src) => e.target.ownerSVGElement.getElementsByClassName(`g${src.id}`)

const applyForMouseEvents = (vm, nativeEvent, highlight) => {

  if (highlight.source.type === 'gate') {
    vm.highlightElement({
      type: 'g',
      id: highlight?.source.id,
      includePlanets: false,
    });

    const hidden = document.querySelectorAll('.mandala-gates-wrapper > g');

    hidden.forEach(x => {
      if (x.classList.length === 1) x.classList.remove('highlight-low');
      if (x.classList.contains(`gs_${highlight?.source?.id}`)) x.classList.remove('highlight-low');
    });
  }

  R.pipe(
    R.prop('source'),
    src => getHighlightTargetNodes(nativeEvent, src),
    Array.from,
    R.forEach(x => x.classList.add('highlight')),
    R.head(),
    x => x.classList,
    R.head(),
    R.split('g'),
    R.tail(),
    x => [parseInt(x)],
    filterDataSourceGateByGateIds(vm),
    getLongitudesOfGates,
    filterZodiacsByLongitude,
    highlightZodiacs(vm),
  )(highlight)
}

const clearForMouseEvents = (vm) => vm.clearAllHighlights();

// note that we will consider both *gate* and *hexagram* targets
// as hex targets are mandala specific and gate targets affect bodygraph as well
const applyArbitraryHighlights = vm => R.pipe(
  R.prop('targets'),
  R.pick([objects.GATE, objects.HEXAGRAM]),
  R.values,
  R.reduce(R.concat, []),
  R.uniq,
  R.unless(
    R.isEmpty,
    R.pipe(
      R.chain(id => vm.$el.getElementsByClassName(`g${id}`)),
      Array.from,
          R.forEach(x => x.classList.add('highlight'))
    )
  )
)

const gateIdsThatHighlight = () => R.pipe(
  R.prop('targets'),
  R.pick([objects.GATE, objects.HEXAGRAM]),
  R.values,
  R.reduce(R.concat, [])
) 

const filterDataSourceGateByGateIds = (vm) => R.pipe(
  R.map(
    (gate) => R.filter((g) => g.id === gate, R.prop("gates", vm.dataSource)),
  )
)

const getLongitudesOfGates = R.pipe(
  R.flatten,
  R.map(
    R.prop("planets"),
    ),
    R.flatten,
    R.map(R.prop("longitude")),
)

const filterZodiacsByLongitude = R.pipe(
  R.map(
    (longitude) => R.filter((z) => (z.startArc <= longitude) && (z.endArc > longitude), zodiacs.getAll)
  ),
  R.uniq()
)

const highlightZodiacs =  vm =>  R.pipe(
  R.map(R.pluck('name')),
  R.map(R.values()),
  R.pipe(
    R.chain(id => vm.$el.getElementsByClassName(`${id}`)),
    Array.from,
    R.forEach(x => x.classList.add('highlight'))
  )
)

const applyArbitraryZodiacHighlights =  vm => R.pipe(
  gateIdsThatHighlight(vm),
  filterDataSourceGateByGateIds(vm),
  getLongitudesOfGates,
  filterZodiacsByLongitude,
  highlightZodiacs(vm)
)

const clearArbitraryHighlights = vm => R.pipe(
  () => vm.$el.getElementsByClassName('highlight'),
  Array.from,
  R.forEach(x => x.classList.remove('highlight'))
)

const getEvents = (vm, gateId) => {

  if (funcs.isGateDefined(vm.dataSource, gateId) === false) return null
  const source = { type: objects.GATE, id: gateId }

  const clickEvent = {
    click: () => vm.onElementClicked(source)
  }

  // this function is lazy so we only calculate
  // the highlighting targets if roll over events are actually enabled
  const hoverEvents = () => {
    const evt = {
      source,
      targets: highlighting.getTargets(vm.planets, { type: objects.GATE, id: gateId })
    }

    return {
      mouseover: (e) => vm.onMouseEnter(e, evt),
      mouseout: (e) => vm.onMouseLeave(e, source)
    }
  }

  const events = R.ifElse(
    R.equals(true),
    R.converge(R.mergeLeft(), [R.always(clickEvent), hoverEvents]), // see comment above hover events
    R.always(clickEvent)
  )

  return events(vm.rollOversEnabled)
}

const initActions = vm => {
highlighting.registerHandlers(vm, applyArbitraryHighlights, clearArbitraryHighlights)
highlighting.registerHandlers(vm, applyArbitraryZodiacHighlights, clearArbitraryHighlights)
}

export default {
  fromMouseEvents: {
    apply: applyForMouseEvents,
    clear: clearForMouseEvents
  },
  getEvents,
  init: initActions
}
