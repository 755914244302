<template>
  <b-card class="cursor-pointer hover-brightness"
          style="min-height:125px;"
          @click="$emit('createEditMode')">
    <div class="d-flex align-items-center text-center justify-content-center w-100 h-100">
      <div>
        <b-button size="sm" variant="link"><span class="icon-plus"/></b-button>
        <p class="mb-0">
          {{ $t('owl.notes.add') }}
        </p>
      </div>
    </div>
  </b-card>
</template>
<script>
export default {

}
</script>