<template>

  <div id="foundational-analysis-wrapper" class="tabs-primary-filled">
      <b-tabs :value="companionshipTab"
              @input="(id) => setTab('companionship_tab', id)"
              class="vertical-side-tabs mobile-hidden" pills card vertical end nav-wrapper-class="tabs-panel" lazy>

        <b-tab v-for="(tab,index) in tabs" :key="`companionship_tab-tab-${tab.id}`">
          <template #title>
            <TabTitle :title="tab.name" :icon="tab.icon" :centered="true"
                      :is-locked="!isUnlocked && index!==0"

                                     :is-completed="isTabSectionCompleted('companionship', index)"/></template>

          <b-overlay :show="loading" rounded="sm">
          <b-card class="px-0 analysis-card py-3">

            <component :is="tab.component" :is-unlocked="isUnlocked"
                       :has-prev="index > 0"
                       :has-next="index < tabs.length - 1"
                       :total-tabs="tabs.length"
                       @prevSection="setTab('companionship_tab', index-1, true)"
                       @nextSection="setTab('companionship_tab', index+1)"
                       @loading="loadingChanged"
                       @innerTabChanged="innerTabChanged"
             >
              <template v-if="!isUnlocked" #unlock-prompt>
                <unlock-content-prompt v-if="!isUnlocked" class="mt-4" category="companionship" :chart-id="$store.state.charts.chart.id" />
              </template>
            </component>
          </b-card>
          </b-overlay>

        </b-tab>
      </b-tabs>

      <mobile-bottom-tabs :items="tabs" :value="companionshipTab"
                          @input="(id) => setTab('companionship_tab', id)"></mobile-bottom-tabs>

  </div>

</template>

<script>
import TabTitle from "../../elements/TabTitle";
import MobileBottomTabs from "../../structure/MobileBottomTabs";
import ConnectionTheme from "./connection-theme";
import GetStarted from "./get-started";
import Electromagnetic from "./Electromagnetic";
import Dominance from "./Dominance";
import Companionship from "./Companionship";
import Compromise from "./Compromise";
import UnlockContentPrompt from "../../billing/UnlockContentPrompt";
import GatesOfLove from "./gates-of-love.vue";
import AnalysisRouterMixin from "../../../mixins/AnalysisRouterMixin";

export default{

  mixins: [AnalysisRouterMixin],
  components:{UnlockContentPrompt, TabTitle, MobileBottomTabs},
  computed:{
    isUnlocked(){
      return this.$store.state.charts.chartStatus.is_unlocked
    }
  },
  watch: {
    companionshipTab(val){
      this.fixQuery('companionship_tab', val)
    }
  },
  created(){
    this.setup('companionship_tab')
  },
  data(){
    return{
      loading: false,
      tabs: [{id: 0, icon: 'icon-get-started', component: GetStarted, name: this.$t('charts.analysis.connection.tabs.0')},
        {id: 1, icon: 'icon-connection-theme', component: ConnectionTheme, name: this.$t('charts.analysis.connection.tabs.1')},
        {id: 2, icon: 'icon-electromagnetic-connection', component: Electromagnetic, name: this.$t('charts.analysis.connection.tabs.2')},
        {id: 3, icon: 'icon-love-dominance', component: Dominance, name: this.$t('charts.analysis.connection.tabs.3')},
        {id: 4, icon: 'icon-compromise', component: Compromise, name: this.$t('charts.analysis.connection.tabs.4')},
        {id: 5, icon: 'icon-companionship', component: Companionship, name: this.$t('charts.analysis.connection.tabs.5')},
        {id: 6, icon: 'icon-love', component: GatesOfLove, name: this.$t('charts.analysis.connection.tabs.6')}
      ]
    }
  },
  methods:{
    loadingChanged(val){
      this.loading = val;
    }
  }
}
</script>

<style lang="scss">

#foundational-analysis-wrapper{

  .tabs{
    .nav-link{
      font-size:0.9rem;
      font-weight:300;
      &.active{
        font-weight:600;
      }
    }
  }
  > .card-body{
    padding:0;
  }
}
.analysis-card{
  > .card-body{
    padding:0!important;
  }
}
</style>