<template>
    <side-extended-panel id="import-charts"
                         :close-btn-text="$t('owl.close')"
                         :visible="show"
                         size="x-small"
                         extra-container-classes="p-0"
                         @close="() => $store.commit('ui/CLOSE_SIDE_SHEET')">
      <template #header>
        <new-header :title="$t('library.bulkTags.title')" />
      </template>

      <div class="">

        <b-tabs class="tabs-primary-underlined" lazy fill>
          <b-tab :title="$t('library.bulkTags.add')" class="d-flex flex-column gap-10 px-4">
            <multiselect v-model="tagsToAdd"
                         id="import-tags-selector"
                         :tag-placeholder="$t('tags.addPlaceholder')"
                         placeholder="Start typing a tag..."
                         :options="tags"
                         label="text"
                         track-by="id"
                         :multiple="true" :taggable="true"
                         @tag="addTag"
            />
            <b-button variant="primary" :disabled="isSaving || tagsToAdd.length === 0" class="mt-3" @click="save('add')">
              Add tags
            </b-button>
          </b-tab>
          <b-tab :title="$t('library.bulkTags.remove')" class="d-flex flex-column gap-10 px-4">
            <multiselect v-model="tagsToRemove"
                         id="import-tags-selector"
                         :tag-placeholder="$t('tags.addPlaceholder')"
                         placeholder="Start typing a tag..."
                         :options="tags"
                         label="text"
                         track-by="id"
                         :multiple="true" :taggable="false"/>
            <b-button variant="primary" :disabled="isSaving || tagsToRemove.length === 0" class="mt-3" @click="save('remove')">
              Bulk Remove tags
            </b-button>
          </b-tab>
        </b-tabs>

      </div>
    </side-extended-panel>
</template>
<script>
import SideExtendedPanel from "@/components/structure/SideExtendedPanel.vue";
import NewHeader from "@/components/structure/chart-sidebars/partials/NewHeader.vue";
// import Multiselect from "vue-multiselect";
import {SidebarVariants} from "@/utils/localStorage";
import Multiselect from "vue-multiselect";
import {mapState} from "vuex";

export default {
  components: {Multiselect, NewHeader, SideExtendedPanel},
  mixins:[],
  props: {
    chartIds: Array
  },
  data(){
    return {
      tagsToAdd: [],
      tagsToRemove: [],
      isSaving: false,
    }
  },
  computed: {
    ...mapState({
      tags: state => state.account.tags
    }),
    show() {
      return this.$store.state.ui.sideSheet.type === SidebarVariants.BulkTagSidebar
    }
  },
  methods: {
    addTag(tag) {
      this.axios.post(`${this.globalConfig.chartsUrl}/api/tags`, {
        text: tag
      }).then(res => {
        this.$store.commit('account/ADD_TAGS', [res.data]);
        this.tagsToAdd.push(res.data);
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      });
    },

    save(operation) {
      if (this.isSaving) return;
      const body = {
        operation,
        tagIds: operation === 'add' ? this.tagsToAdd.map(x => x.id) : this.tagsToRemove.map(x => x.id),
        chartIds: this.chartIds.map(x => x.id)
      }
      this.isSaving = true;
      this.axios.post(`${this.globalConfig.chartsUrl}/api/tags/bulk`, body).then(() => {
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')})
        if (operation === 'add') { this.tagsToAdd = []; } else { this.tagsToRemove = []; }
        this.$emit('updated');
        this.$store.commit('ui/CLOSE_SIDE_SHEET');
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      }).finally(() => {
        this.isSaving = false;
      });
    },
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>