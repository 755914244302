<template>
  <div class="mb-1 d-flex text-sm align-items-baseline text-lg">
    <p class="pr-1 mb-0 text-normal "> <span v-if="quantity" class="font-500">{{quantity}} x </span>{{ name }}</p>
    <span class="dots-area"></span>

    <p class="pl-1 mb-0 text-normal">
      <span :class="discount ? 'strikethrough' : ''">
         {{value.toFixed(2)}}
      </span>
      <span v-if="discount">
        {{discountedPrice}}
      </span>
      $
    </p>
  </div>
</template>

<style lang="scss">
.theme--light{
  .dots-area{
    border-bottom: 1px dashed black;
  }
}
.theme--dark{
  .dots-area{
    border-bottom: 1px dashed white;
  }
}
.dots-area{
  flex: 1;

  overflow:hidden;
}
</style>
<script>


export default{

  props:{
    name: String,
    value: Number,
    quantity: Number,
    discount: Number,
    discountedPrice: [String, Number]
  },
  computed:{
    // discountedPrice() {
    //   return (this.value * ((100 - this.discount) / 100)).toFixed(2)
    // }
  }
}
</script>