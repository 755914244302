import sense from '@/helpers/rave/constants/senses'

export default {
  [sense.SECURITY]: 'Security',
  [sense.UNCERTAINTY]: 'Uncertainty',
  [sense.ACTION]: 'Action',
  [sense.MEDITATION]: 'Meditation',
  [sense.JUDGMENT]: 'Judgment',
  [sense.ACCEPTANCE]: 'Acceptance'
}
