export default{
    0: {
        name: 'Understanding'
    },
    1: {
        name: 'Sensing'
    },
    2: {
        name: 'Knowing'
    },
    3: {
        name: 'Centering'
    },
    4: {
        name: 'Integration'
    },
    5: {
        name: 'Defense'
    },
    6: {
        name: 'Ego'
    }
}