<template>
  <p class="big-number text-center position-relative">
    <span class="font-500 d-block number large-number mx-auto">{{ number}}</span>

    <label class="number-label font-weight-normal text-center">
      {{description}}
    </label>
 </p>

</template>

<style scoped>

.number{
  font-size:30px;
  @media(min-width: 1240px){
    font-size:45px;
  }
}
.number-label{
  font-size:12px;
}
.large-number{
  //width:fit-content;
}
</style>

<script>

export default{
  props:{
    number: [String, Number],
    description: [String, Number],
    isLast: {
      type: Boolean,
      default: false
    }
  }
}
</script>