import {mapState} from "vuex";
import util from "./util";

export default {
    mixins: [util],

    computed: {
        ...mapState({
            mainTab: state => state.ui.analysis.mainTab,
            foundationalTab: state => state.ui.analysis.foundational_tab,
            advancedTab: state => state.ui.analysis.advanced_tab,
            companionshipTab: state => state.ui.analysis.companionship_tab,
            workSuccessTab: state => state.ui.analysis.personal_success_tab,
            progress: state => state.charts.chartProgress,
        })
    },

    methods: {
        fixQuery(tab, val) {
            let urlQuery = this.getQuery();
            if (val !== undefined) urlQuery.set(tab, val);
            else urlQuery.delete(tab);
            this.fixUrlQuery('?' + urlQuery.toString());
        },

        setup(tab) {
            if(this.getQuery().has(tab)) {
                let activeId = parseInt(this.getQuery().get(tab));
                this.setTab(tab, activeId);
            }
        },

        setTab(tab, id, lastInnerTab = false) {
            this.$store.commit('ui/SET_ANALYSIS_TAB', {
                [tab]: id,
                lastInnerTab
            })
        },

        innerTabChanged(){
            const analysis = document.getElementById('analysis-part');
            if (!analysis) return;

            setTimeout(()=>{ analysis.scrollIntoView() }, 350);
        },


        isTabSectionCompleted(type, idx) {
            let foundKey = false;
            const keys = Object.keys(this.progress ? (this.progress[type] ?? {}) : {});
            for (const key of keys) {
                if (key.startsWith(idx.toString())) {
                    foundKey = true;
                    if (!this.progress[type]?.[key]) return false;
                }
            }
            return foundKey;
        },
    }
}