<template>
  <div id="nourish" class="px-4">
    <inner-tabs-renderer :id="1"
                         type="advanced"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)">
      <template #unlock-prompt> <slot name="unlock-prompt"/> </template>
    </inner-tabs-renderer>
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer";
import {mapState} from "vuex";
import activationModes from "../../../../helpers/rave/constants/activationModes";
import planetConstants from "../../../../helpers/rave/constants/planets";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import ColorToneComboDataRenderer from "./elements/ColorToneComboDataRenderer.vue";
import ColorDataRenderer from "./elements/ColorDataRenderer.vue";
import InnerTabsRenderer from "../../../renderer/InnerTabsRenderer.vue";
export default{
  components: {InnerTabsRenderer},
  mixins: [AnalysisQueryMixin],
  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
    tabs() {
      return [
        {
          id: 0,
          title: this.$t('hd.properties.introduction'),
          shouldRender: !!this.content,
          component: DynamicZoneRenderer,
          props: {
            components: this.content ? this.content.components : [],
            addPaddings: false,
          }
        },
        {
          id: 1,
          title: this.$t('hd.properties.dietaryRegimen'),
          shouldRender: true,
          component: ColorDataRenderer,
          props: {
            activation: this.activation,
            color: this.color,
            tone: this.tone,
            type: this.type
          }
        },
        {
          id: 2,
          title: this.$t('hd.properties.orientation'),
          shouldRender: true,
          component: ColorToneComboDataRenderer,
          props: {
            activation: this.activation,
            color: this.color,
            tone: this.tone,
            type: this.type
          }
        }
      ]
    }
  },
  data(){
    return{
      tab: 0,
      audioKey: 0,
      content: null,
      activation: 'design',
      type: 'internal',
      color: null,
      tone: null,
      colorData: null,
      colorToneComboData: null,
    }
  },
   created(){
     this.getContent('content', '/analysis/introductions/introduction.advanced.2');
      let planet = this.chart.chart.planets.find(x => x.activation === activationModes.DESIGN && (x.id === planetConstants.EARTH || x.id === planetConstants.SUN));
      this.tone = planet.tone <= 3 ? 'low' : 'high';
      this.color = planet.color;
   },

}
</script>