import planetConstants from "../helpers/rave/constants/planets";
import moment from "moment";
import {mapState} from "vuex";
import ChartCreationMixin from "./ChartCreationMixin";


export default {

    mixins: [ChartCreationMixin],

    data() {
        return {
            planets: [],
            dataTypes: {
                prevGate: 'prevGate',
                prevLine: 'prevLine',
                nextGate: 'nextGate',
                nextLine: 'nextLine'
            },
            changesRendererKey: 0,
            justNowTimer: null
        }
    },
    computed: {
        ...mapState({
            changes: state => state.charts.transit.changes,
            justNow: state => state.charts.transit.justNow,
        }),
        getPlanets() {
            return this.planets;
        },
        CurrentTimeZoneName() {
            return Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        currentTransitDate() {
            return this.$store.state.charts.chart.meta.transit.time.utc;
        }
    },

    mounted() {

        for (let i = 0; i < 13; i++) {
            this.planets.push({
                icon: planetConstants.icons[i],
                name: this.$t('hd.planets.' + i + '.name'),
                id: i
            });
        }
    },
    destroyed() {
        if (this.justNowTimer) {
            clearTimeout(this.justNowTimer);
        }
    },
    methods: {

        changeTransitTimestamp(newDate, isJustNow = false) {
            // the moment we change a timestamp, the is just now toggle is setting off!
            if (!isJustNow) this.$store.commit('charts/SET_TRANSIT_JUST_NOW', false);
            let selectedChartMeta = this.$store.state.charts.chart.meta;
            let user = null, utcTime = null;

            if (selectedChartMeta.person) {
                user = selectedChartMeta.person;
            }

            // get UTC time but first remove Z so we can work with our transformation1
            let date = new Date(newDate);

            utcTime = moment(date);

            // adjust time minute sto match the direction we are going to make sure charts are updated!
            if (utcTime.isAfter(moment(selectedChartMeta.transit.time.utc))) {
                utcTime.add('1', 'minute').utc();
            } else {
                utcTime.subtract('1', 'minute').utc();
            }

            const transitDateStr = this.formatDate(moment(utcTime).local().format('lll'), false, 'DD MMM yyyy @ HH:mm')

            const request = {
                autosave: false,
                time: utcTime.format(),
                timeInUtc: true,
                docId: user ? user.id : null,
                timezone: this.CurrentTimeZoneName,
                name: `${user ? user.name + ' - ' : ''}${transitDateStr}`
            }

            if (this.name) request.name = this.name;

            return this.createTransitChart(request).then(chart => {
                this.$store.dispatch('charts/setChart', chart).then(() => {
                    this.getChanges(true);
                });
                // this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.created')});
                // this.redirectTo('single-chart', null, {chartId: chart.id || 'temp-doc'},{transit:'just-now'})

            }).catch(() => {
                this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
            }).finally(() => {
                this.updating = false;
            })
        },
        changeJustNow(val) {
            this.$store.commit('charts/SET_TRANSIT_JUST_NOW', val);
            if (val) {
                this.changeTransitTimestamp(moment().format('YYYY-MM-DD HH:mmZ'), true)
            }
        },
        getChanges(reRender = false) {
            this.$emit('loading', true);
            let transitDate = this.$store.state.charts.chart.meta.transit.time.utc;
            this.axios.get('/transit/transit-data', {params: {transit_date: transitDate}}).then(res => {
                this.$store.commit('charts/SET_TRANSIT_CHANGES', res.data);
                this.$emit('loading', false);
                if (reRender) this.$store.commit('charts/SET_TRANSIT_KEY', this.$store.state.charts.transit.key + 1);

                if (!this.justNowTimer && this.justNow) {
                    // Gets all the planets, sorted by the closest up-coming change!
                    const sortedDates = Object.values(res.data.planetsInfo).map(x => x.nextLine)
                        .sort((a, b) => moment.utc(a.datetime).valueOf() - moment.utc(b.datetime).valueOf());

                    const nextChange = moment.utc(sortedDates[0].datetime);
                    const nextChangeInMs = nextChange.valueOf() - moment().valueOf();
                    // const nextChangeInMs = 1_000;


                    this.justNowTimer = setTimeout(()=> {
                        this.changeTransitTimestamp(nextChange, true);

                        this.$store.commit('charts/ADD_TRANSIT_NOTIFICATION', {
                            planet: sortedDates[0].planet,
                            meta: sortedDates[0],
                            time: new Date()
                        });

                        this.justNowTimer = null;
                    }, nextChangeInMs);
                }

            }).catch(() => {
                this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
            }).finally(() => {
                this.$emit('loading', false);
            })
        },

        calculateTimeForRetrogradionStatusChange(planetId) {

            if (!this.changes[planetId]['retrograde']) return '-'; // make sure that planet retrogrades
            let from = moment(this.changes[planetId].currentData.datetime);
            let to = moment(this.changes[planetId]['retrograde'].datetime);

            return this.executeCalculation(from, to)
        },

        calculateTimeForActivation(planetId, type, changes = this.changes) {

            let from;

            // check we are doing a line or gate check of moment start Date.
            if (type.includes('Line')) {
                from = moment(changes[planetId].currentData.lineStartDate.datetime);
            } else {
                from = moment(changes[planetId].currentData.gateStartDate.datetime);
            }

            let to = moment(changes[planetId][type].datetime);

            return this.executeCalculation(from, to);
        },

        // A function that calculates the real time time difference from "now" to our goal!
        calculateRealTimeForActivation(planetId, type) {
            if (!this.changes[planetId][type]) return '-'; // make sure that change scontain this object.
            let to = moment(this.changes[planetId][type].datetime);


            return this.formatDateDifference(null, to, moment().isAfter(to)); // caculate time different from NOW until the shifting
        },


        executeCalculation(from, to) {

            let totalDiff = to.diff(from, 'seconds', true);
            let dayDiff = to.diff(moment(this.currentTransitDate), 'seconds', true);

            const isBefore = to.isBefore(moment());

            let progressPerc, difference;
            if (dayDiff <= totalDiff) {
                progressPerc = (totalDiff - dayDiff) / totalDiff * 100;
                // eslint-disable-next-line no-undef
                difference = this.formatDateDifference(null, to, isBefore); // calculate time different from NOW until the shifting
            } else {
                progressPerc = 100;
                difference = 'Change already happened.'
            }

            if (isBefore) progressPerc = 100;

            return {
                percentage: progressPerc,
                difference: difference
            };
        },
        formatDuration(duration, precision = undefined) {
            const singularLabels = ['year', 'month', 'day', 'hour', 'minute', 'second'];
            const labels = [ 'years', 'months', 'days', 'hours', 'minutes', 'seconds'];
            const durations
                = [duration.years(), duration.months(), duration.days(), duration.hours(), duration.minutes(), duration.seconds()];

            let data = durations.map((x, index) => {
                const val = Math.abs(x);
                if (val === 0) return '';
                if (val === 1) return `${Math.abs(x)} ${this.$t('dates.'+singularLabels[index])}`;
                return `${Math.abs(x)} ${this.$t('dates.' + labels[index])}`
            }).filter(x => !!x);

            if(precision) data = data.slice(0, precision);
            if (data.length > 1) return data.slice(0, data.length - 1).join(', ') + ` ${this.$t('dates.and')} ` + data[data.length - 1];
            return data.join(', ');
        },
        calculateTimeDifference(from, to) {

            let momFrom = moment(from);
            let momTo = to ? moment(to) : moment();

            // eslint-disable-next-line no-undef
            // return countdown(momFrom, momTo, countdown.DEFAULTS, 2).toString() + (showIsBeforeTag ? (momTo.isAfter(momFrom) ? ' ago' : '') : '')

            const duration = moment.duration(momFrom.diff(momTo, "seconds"), "seconds");
            return this.formatDuration(duration, 3);
        },

        // formats difference based on from - to and shows in depth of 3
        formatDateDifference(from, to, isBefore) {
            // eslint-disable-next-line no-undef
            return (isBefore ? 'was ' : 'in ') + countdown(from, to, countdown.DEFAULTS, 3).toString() + (isBefore ? ' ago' : '');
        },


        formatActivationGateLine(activation) {
            let retrograde = '';

            if (activation.retrograde === 1) retrograde = ' R';
            if (activation.retrograde === 3) retrograde = ' D';
            return activation.gate + '.' + activation.line + retrograde
        },
        getNextData(planetId, type) {
            // types = 'nextGate', 'nextLine', 'prevGate', 'prevLine'
            try {
                let data = this.changes[planetId][type];
                return {
                    currentActivation: this.changes[planetId].currentData,
                    startingTime: moment(this.changes[planetId].currentData.lineStartDate.datetime).format('DD-MM-YYYY HH:mm'),
                    nextActivation: data,
                    shiftingTime: moment(data.datetime).format('DD-MM-YYYY HH:mm'),
                    retrograde: !!this.changes[planetId].retrograde
                }
            } catch (e) {
                return {};
            }
        }
    }
}