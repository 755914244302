import fixing from '@/helpers/rave/constants/toneFixings'

export default {
  [fixing.COMMUNALIST]: 'Communalist',
  [fixing.THEIST]: 'Theist',
  [fixing.LEADER]: 'Leader',
  [fixing.MASTER]: 'Master',
  [fixing.CONDITIONER]: 'Conditioner',
  [fixing.OBSERVER]: 'Observer',
  [fixing.SEPARATIST]: 'Separatist',
  [fixing.ANTI_THEIST]: 'Anti Theist',
  [fixing.FOLLOWER]: 'Follower',
  [fixing.NOVICE]: 'Novice',
  [fixing.CONDITIONED]: 'Conditioned',
  [fixing.OBSERVED]: 'Observed',
  [fixing.SELECTIVE]: 'Selective',
  [fixing.INTERNAL]: 'internal',
  [fixing.WET]: 'Wet',
  [fixing.ACTIVE]: 'Active',
  [fixing.NARROW]: 'Narrow',
  [fixing.NATURAL]: 'Natural',
  [fixing.BLENDING]: 'Blending',
  [fixing.EXTERNAL]: 'External',
  [fixing.DRY]: 'Dry',
  [fixing.PASSIVE]: 'Passive',
  [fixing.WIDE]: 'Wide',
  [fixing.ARTIFICIAL]: 'Artificial'
}
