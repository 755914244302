<template>
  <div class="px-4">
    <inner-tabs-renderer :id="5"
                         type="foundational"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)">
      <template #unlock-prompt> <slot name="unlock-prompt"/> </template>
    </inner-tabs-renderer>
  </div>
</template>

<script>
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import {mapState} from "vuex";
import NodalPolarities from "./sections/NodalPolarities.vue";
import IncarnationCross from "./sections/IncarnationCross.vue";
import Profile from "./sections/Profile.vue";
import InnerTabsRenderer from "../../../renderer/InnerTabsRenderer.vue";

export default{
  mixins: [AnalysisQueryMixin],
  components: {InnerTabsRenderer},
  data(){
    return{
      tab: 0,
    }
  },

  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
    tabs(){
      return [
        {
          id: 0,
          title: this.$t('hd.properties.profile'),
          component: Profile,
          shouldRender: !!this.chart,
          props: {
            chart: this.chart
          }
        },
        {
          id: 1,
          title: this.$t('hd.properties.cross'),
          component: IncarnationCross,
          shouldRender: !!this.chart,
          props: {
            chart: this.chart
          }
        },
        {
          id: 2,
          title: this.$t('hd.properties.nodalPolarities'),
          component: NodalPolarities,
          shouldRender: !!this.chart,
          props: {
            chart: this.chart
          }
        }
      ]
    }
  },
}
</script>