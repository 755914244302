<template>
  <div class="dynamic-zone-renderer unselectable">
    <h5 v-if="title" class="font-bold mb-4 w-full"
        @click="isToggleable ? toggle() : ''" :class="isToggleable ? 'cursor-pointer' :''"
        @mouseover="$emit('titleMouseover')"
        @mouseleave="$emit('titleMouseleave')"
    >
      <p v-if="title" class="text-sm font-weight-light mb-1">{{type ? type : ''}}</p> {{title}}
      <svg v-if="isToggleable && hasData" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#D14B49"
           stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="section-toggler float-right" :class="toggled ? 'toggled' : ''">
        <polyline points="6 9 12 15 18 9"></polyline>
      </svg>
      <svg v-else-if="isToggleable && !hasData" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none"
           stroke="#D14B49" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class=" float-right"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
    </h5>
    <div v-if="!isToggleable || toggled">

      <slot></slot>
      <div v-for="(component,index) in components" :key="component.__component + '-'+index" class="pb-3">

        <img v-if="getType(component) === 'image'" :src="component.image" loading="lazy" class="casual-border-radius"
            :class="component.full_width ? '' : 'px-4'">
        <h3 v-if="getType(component) === 'title'" class="text-lg" :class="addPaddings ? 'px-4' : ''">{{component.title}}</h3>
        <audio-player v-if="getType(component) === 'audio-player'"
                      :id="component.id + index"
                      :title="component.title"
                      :file-url="component.audio" :align-left="true" class="mb-3" :class="addPaddings ? 'px-4' : ''"></audio-player>


        <div class="regular-html" v-if="getType(component) === 'html'" v-html="component.content" :class="addPaddings ? 'px-4' : ''"></div>

        <video v-if="getType(component) === 'video' && component.video" controls width="100%" class="casual-border-radius">
          test
          <source :src="component.video" />
        </video>
      </div>


      <slot name="after-content"/>
    </div>

  </div>

</template>
<style lang="scss">
.dynamic-zone-renderer{
  .section-toggler{
    transition: all .2s ease-in-out;
    transform:rotate(-90deg);
    &.toggled{

      transform:rotate(0deg);
     }
  }
}
.regular-html{
  p:last-child, ul:last-child {
    margin-bottom:0!important;
  }
}
</style>


<script>
import AudioPlayer from "../structure/AudioPlayer";
export default{
  components: {AudioPlayer},
  props:{
    title: String,
    components: Array,
    addPaddings: {
      type: Boolean,
      default: true
    },
    type: String,
    isToggleable: {
      type: Boolean,
      default: false
    },
    toggledWhenRendered:{
      type: Boolean,
      default: false
    },
  },

  computed:{
    hasData(){
      return (this.components && this.components.length > 0) || this.$slots.default
    }
  },
  data(){
    return{
      toggled: false,
      componentTypes: {
        'dropzone.dynamic-image': 'image',
        'dropzone.dynamic-title': 'title',
        'dropzone.dynamic-audio': 'audio-player',
        'dropzone.dynamic-text': 'html',
        'dropzone.dynamic-video': 'video',
      }
    }
  },
  mounted(){
    this.toggled = this.toggledWhenRendered;
  },
  methods:{

    toggle(){
      this.toggled = !this.toggled;
      if(this.toggled) this.$emit('opened');
      else this.$emit('closed');
    },

    getType(component){
      return this.componentTypes[component.__component];
    }

  }
}
</script>