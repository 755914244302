import * as R from 'ramda'
import { isLt } from '@/helpers/utils/misc'

const SPLENIC = 0
const AJNA = 1
const SOLAR_PLEX = 2

const fromTone = R.cond([
  [isLt(3), R.always(SPLENIC)],
  [isLt(5), R.always(AJNA)],
  [R.T, R.always(SOLAR_PLEX)]
])

export default { SPLENIC, AJNA, SOLAR_PLEX, fromTone }
