import ConfirmationDialog from '../components/structure/ConfirmationDialog.vue';

export default {
    install(Vue) {
        // Create a new instance of the confirmation dialog component
        const ConfirmationDialogConstructor = Vue.extend(ConfirmationDialog);
        const instance = new ConfirmationDialogConstructor();

        // Mount the component manually to the DOM
        instance.$mount();
        document.body.appendChild(instance.$el);

        // Add a global `$confirmDialog` method to the Vue prototype
        Vue.prototype.$confirmDialog = (options = {}) => {
            // Update component props dynamically
            if(options.title) instance.title = options.title;
            if (options.message) instance.message = options.message;
            if (options.okTitle) instance.okTitle = options.okTitle ;
            if (options.cancelTitle) instance.cancelTitle = options.cancelTitle;

            // Show the dialog and return the result
            return instance.show();
        };
    },
};