<template>
  <div id="knowledge-base" :class="getDarkModeClass">

    <div class="d-flex gap-5">
      <div class="kb-sidebar ease-in-out duration-300"
           :class="toggledSidebar ? 'toggled' : ''"
           style="min-height:80vh">


        <b-button variant="link" @click="toggledSidebar = !toggledSidebar"
                class="bg-transparent border-0 sidebar-toggle px-0 ease-in-out duration-300"
                :style="toggledSidebar ? '' : 'transform:rotate(180deg)'"
        >
          <span class="icon-arrow-left"></span>
        </b-button>
        <div v-show="toggledSidebar">
          <b-button variant="link" v-for="(item, index) in data" :key="item.id"
                  class="cursor-pointer kb-category px-0 rounded-0 text-left w-100 py-2"
                  :class="selectedIndex === index ? 'selected-kb-category' : ''"
                  @click="selectedIndex = index">
            {{item.title}}
          </b-button>
        </div>


      </div>
      <div v-if="data && data[selectedIndex]" class="pl-4 w-100" style="max-height:80vh;overflow-y:auto;">

        <div v-for="category in data[selectedIndex].subcategories" :key="category.id" class="mb-4">
          <h5 class="mb-3">{{category.title}}</h5>


          <div v-for="(item) in category.items" :key="`toggle-${item.id}`"
               class="mb-3 accordion-item casual-border-radius ease-in-out duration-300"
               :class="activeCategories[selectedIndex] === item.id ? 'collapsed' : 'not-collapsed'"
         >
            <b-button
                variant="link"
                size="xs"
                      class="w-100 accordion-toggler text-left font-weight-bold"
                @click="toggleItem(selectedIndex, item.id)"
                     >
              <p class="mb-0">
                {{item.title}}
                <span class="float-right">
                    <span class="icon-arrow-down"></span>
                </span>

              </p>
            </b-button>
            <div v-show="activeCategories[selectedIndex] === item.id"
                        class="p-2"
                        :key="item.id" :id="`item-${item.id}`">

              <div v-html="item.content" class="pt-2"/>
            </div>
          </div>

        </div>
      </div>


    </div>

  </div>

</template>

<style lang="scss" scoped>
.kb-category{
  font-size:12px!important;
  padding:0.5rem 0.5rem 0.5rem 0.2rem!important;
  background: transparent;
  border:none;
  &.selected-kb-category{
    border-right:3px solid $primary!important;
  }
}

.theme--dark{
  .kb-sidebar{
    border-right: 1px solid $bg-contrast-dark;
  }
}
.sidebar-toggle{
  padding:0.2rem!important;
}
.theme--light{
  .kb-sidebar{
    border-right:1px solid #d3d3d3;
  }
 }
.kb-sidebar{
  border-right:1px solid;
  width:6%;
  &.toggled{
    width:30%;
  }
}

</style>

<script>
import localforage from "localforage";
import Vue from "vue";

export default{


  data(){
    return{
      data: [],
      selectedIndex: 0,
      toggledSidebar: true,
      activeCategories: {}
    }
  },
  mounted(){
    this.fetchData();
  },

  methods:{

    toggleItem(category, id) {
      if(this.activeCategories[category] === id){
        Vue.set(this.activeCategories, category, null);
        return;
      }
      Vue.set(this.activeCategories, category, id);
    },
    async fetchData(){

      this.data = await localforage.getItem('mbg-kb');
      let nextUpdate = new Date((await localforage.getItem('mbg-kb-last-update')));
      nextUpdate.setHours(nextUpdate.getHours() + 1);

      const cacheValid = new Date() < nextUpdate;

      if(cacheValid) return;
      // check if we should update basde on time. We update once every 1 hour.

      this.axios.get('/content/kb/knowledge-base').then(res => {

        if(!res.data) return;
        this.data = res.data;

        localforage.setItem('mbg-kb', res.data);
        localforage.setItem('mbg-kb-last-update', new Date());
      }).catch(e =>{
        console.log(e);
      })
    }
  }

}
</script>