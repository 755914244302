<template>
  <b-card id="invoice-options">

    <b-card-body>

      <div class="grid grid-cols-md-2 gap-5">
        <mbg-text-input v-model="invoiceData.company" :title="$t('billing.tabs.invoiceOptions.company')"/>
        <mbg-text-input v-model="invoiceData.vat" :title="$t('billing.tabs.invoiceOptions.vat')"/>

        <mbg-text-input v-model="invoiceData.address" :title="$t('billing.tabs.invoiceOptions.address')"/>
        <mbg-text-input v-model="invoiceData.city" :title="$t('billing.tabs.invoiceOptions.city')"/>
        <mbg-text-input v-model="invoiceData.state" :title="$t('billing.tabs.invoiceOptions.state')"/>
        <mbg-text-input v-model="invoiceData.zip_code" :title="$t('billing.tabs.invoiceOptions.zip_code')"/>
        <mbg-text-input v-model="invoiceData.country" :title="$t('billing.tabs.invoiceOptions.country')"/>



      </div>

      <div class="text-right">
        <b-button variant="primary" :disabled="!hasChanged" @click="updateInvoiceData">{{$t('general.saveChanges')}}</b-button>
      </div>

      <p class="mt-3 text-right">
        {{$t('billing.tabs.invoiceOptions.optional')}}
      </p>
    </b-card-body>

  </b-card>
</template>

<script>

import MbgTextInput from "../elements/MbgTextInput.vue";

export default {
  components: {MbgTextInput},

  computed:{
    hasChanged(){
      return JSON.stringify(this.invoiceData) !== this.initialData
    }
  },
  data(){
    return {
      invoiceData: {
        id: null,
        company: '',
        address: '',
        vat: '',
        zip_code: '',
        city: '',
        country: '',
        state: ''
      },
      initialData: null,
    }
  },
  created(){
    this.fetchInvoiceData();
  },

  methods: {
    fetchInvoiceData() {
      this.axios.get('/users/invoice-data').then(res => {

        if(res.data){
          this.invoiceData = res.data;
          this.initialData = JSON.stringify(res.data);
        }else{
          this.initialData = JSON.stringify(this.invoiceData);
        }
      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    },

    updateInvoiceData(){
      this.axios.put('/users/invoice-data', {...this.invoiceData}).then(res => {
        this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.updated')})
        if(res.data){
          this.invoiceData = res.data;
          this.initialData = JSON.stringify(res.data);
        }

      }).catch(() => {
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      })
    }
  }
}
</script>