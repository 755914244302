import environment from '@/helpers/rave/constants/environments'

export default {
  [environment.CAVES]: 'Caves',
  [environment.MARKETS]: 'Markets',
  [environment.KITCHENS]: 'Kitchens',
  [environment.MOUNTAINS]: 'Mountains',
  [environment.VALLEYS]: 'Valleys',
  [environment.SHORES]: 'Shores'
}
