<template>
  <div :class="'mbg-text-input ' + (classes|| '')">
    <label v-if="title && showLabel">{{title}}</label>
    <b-input-group v-if="icon && icon.length > 0 " :class="getInputGroupClasses + ' ' +(inputClasses||'')">
      <b-input-group-prepend>
        <span :class="icon"/>
      </b-input-group-prepend>

      <b-form-input :type="type" :value="value" :disabled="disabled"
                    :placeholder="getPlaceholder" @blur="blur" @change="change" @update="updateValue"
                    :state="hasError ? false : null" :debounce="debounce"></b-form-input>
    </b-input-group>
    <b-form-input v-else :type="type" :value="value" :disabled="disabled"
        :placeholder="getPlaceholder" @blur="blur" @change="change" @update="updateValue"
        :state="hasError ? false : null" :class="inputClasses" :debounce="debounce"></b-form-input>
    <p class="form-error-indicator mb-0 mt-0" v-if="hasError">
      <span v-for="error in validation.$errors" :key="error.message" class="d-block">
        {{formatMessage(error.$message)}}
      </span>
    </p>
  </div>
</template>


<script>
export default{

  props:{
    title: String,
    value: String,
    placeholder: String,
    icon: String,
    type: {
      type:String,
      default: 'text'
    },
    showLabel:{
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    classes: String,
    inputClasses: String,
    validation:Object,
    debounce: {
      type: Number,
      default: 0
    }
  },
  computed: {
    getPlaceholder(){
      return this.placeholder || this.title;
    },
    hasError(){
      return this.validation && this.validation.$error;
    },
    getInputGroupClasses(){
      let classes = '';
      if(this.disabled){
        classes += 'disabled '
      }
      if(this.hasError){
        classes += 'has-error '
      }

      return classes;
    }
  },

  methods:{
    change(val){
      this.$emit('input', val);
    },
    blur(event){
      if(this.validation) this.validation.$touch();
      this.$emit('blur', event);

    },
    updateValue(value) {
      this.$emit('input', value)
    },
    formatMessage(message){
      return message.includes('errors.') ? this.$t(message) : message;
    }
  },
}
</script>