import i18n from "./i18n";
import {getAIprops} from "./rave/queries";
import channelCompositionStates from "./rave/constants/channelCompositionStates";
import centerDefinitionStates from "./rave/constants/centerDefinitionStates";
import planetConstants from "../helpers/rave/constants/planets";
import activationModes from "./rave/constants/activationModes";
import binaryAlignments from "./rave/constants/binaryAlignments";
import Authorities from "./rave/constants/authorities";

const getValue = (elem, constant) => {
    if (elem !== null && elem !== undefined) return i18n.t(`hd.constants.${constant}.${elem}`)
    return null;
};

// Returns the list of properties for personal chart.
// Re-used in home and in summary of properties sidebar.
export const getFoundationalProperties = (chart) => {

    if(!chart || !chart.chart) return;
    return [
        {
            label: 'hd.properties.type',
            value: getValue(chart.chart.type, 'types'),
            icon: 'icon-person-circle'
        },
        {
            label: 'hd.properties.strategy',
            value: getValue(chart.chart.type, 'strategies'),
            icon: 'icon-direction'
        },
        {
            label: 'hd.properties.notSelfTheme',
            value: getValue(chart.chart.type, 'notSelfThemes'),
            icon: 'icon-not-self-theme'
        },
        {
            label: 'hd.properties.signature',
            value: getValue(chart.chart.type, 'signatures'),
            icon: 'icon-strategy'
        },
        {
            label: 'hd.properties.definition',
            value: getValue(chart.chart.definition, 'definitions'),
            icon: 'icon-electromagnetic-connections'
        },
        {
            label: 'hd.properties.authority',
            value: +chart.chart.authority === Authorities.NONE ?
                i18n.t(`hd.constants.authorities.empty.${chart.chart.type}`)
                :getValue(chart.chart.authority, 'authorities'),
            icon: 'icon-authority'
        },
        {
            label: 'hd.properties.profile',
            value: getValue(chart.chart.profile, 'profiles'),
            icon: 'icon-circles'
        },
        {
            label: 'hd.properties.cross',
            value: getValue(chart.chart.cross, 'crosses'),
            icon: 'icon-cross',
            doubleSpan: true
        },
    ]
}


/// type -> color -> tone category
const ADVANCED_MAPPINGS = {
    digestion: {
        1: {
            1_3: 'Consecutive Appetite',
            4_6: 'Alternating Appetite'
        },
        2: {
            1_3: 'Open Taste',
            4_6: 'Closed Taste'
        },
        3: {
            1_3: 'Hot Thirst',
            4_6: 'Cold Thirst'
        },
        4: {
            1_3: 'Calm Touch',
            4_6: 'Nervous Touch'
        },
        5: {
            1_3: 'High Sound',
            4_6: 'Low Sound'
        },
        6: {
            1_3: 'Direct Light',
            4_6: 'Indirect Light'
        },
    },
    environment: {
        1: {
            1_3: 'Selective Caves',
            4_6: 'Blending Caves'
        },
        2: {
            1_3: 'Internal Markets',
            4_6: 'External Markets'
        },
        3: {
            1_3: 'Wet Kitchens',
            4_6: 'Dry Kitchens'
        },
        4: {
            1_3: 'Active Mountains',
            4_6: 'Passive Mountains'
        },
        5: {
            1_3: 'Narrow Valleys',
            4_6: 'Wide Valleys'
        },
        6: {
            1_3: 'Natural Shores',
            4_6: 'Artificial Shores'
        },
    },
    motivation: {
        1: {
            1_3: 'Strategic Fear',
            4_6: 'Receptive Fear'
        },
        2: {
            1_3: 'Strategic Hope',
            4_6: 'Receptive Hope'
        },
        3: {
            1_3: 'Strategic Desire',
            4_6: 'Receptive Desire'
        },
        4: {
            1_3: 'Strategic Need',
            4_6: 'Receptive Need'
        },
        5: {
            1_3: 'Strategic Guilt',
            4_6: 'Receptive Guilt'
        },
        6: {
            1_3: 'Strategic Innocence',
            4_6: 'Receptive Innocence'
        },
    },
    perspective: {
        1: {
            1_3: 'Focused Survival',
            4_6: 'Peripheral Survival'
        },
        2: {
            1_3: 'Focused Possibility',
            4_6: 'Peripheral Possibility'
        },
        3: {
            1_3: 'Focused Power',
            4_6: 'Peripheral Power'
        },
        4: {
            1_3: 'Focused Wanting',
            4_6: 'Peripheral Wanting'
        },
        5: {
            1_3: 'Focused Probability',
            4_6: 'Peripheral Probability'
        },
        6: {
            1_3: 'Focused Personal',
            4_6: 'Peripheral Personal'
        },
    },

}
export const getAdvancedProperties = (chart) => {

    const advancedProps = getAIprops(chart.chart.planets);

    const digestionPlanet = chart.chart.planets.find(x => x.id === planetConstants.SUN && x.activation === activationModes.DESIGN);
    const environmentPlanet = chart.chart.planets.find(x => x.id === planetConstants.SOUTH_NODE && x.activation === activationModes.DESIGN);

    const motivationPlanet = chart.chart.planets.find(x => x.id === planetConstants.SUN && x.activation === activationModes.PERSONALITY);
    const perspectivePlanet  = chart.chart.planets.find(x => x.id === planetConstants.SOUTH_NODE && x.activation === activationModes.PERSONALITY);

    return [
        {
            label: 'hd.properties.variable',
            value: advancedProps.variable,
            icon: 'icon-variable'
        },
        {
            label: 'hd.properties.digestion',
            value: ADVANCED_MAPPINGS.digestion[digestionPlanet.color][digestionPlanet.tone < 4 ? '13' : '46'],
            icon: 'icon-digestion'
        },
        {
            label: 'hd.properties.environment',
            value: ADVANCED_MAPPINGS.environment[environmentPlanet.color][environmentPlanet.tone < 4 ? '13' : '46'],
            icon: 'icon-environment'
        },
        {
            label: 'hd.properties.perspective',
            value: ADVANCED_MAPPINGS.perspective[perspectivePlanet.color][perspectivePlanet.tone < 4 ? '13' : '46'],
            icon: 'icon-vision'
        },
        {
            label: 'hd.properties.motivation',
            value: ADVANCED_MAPPINGS.motivation[motivationPlanet.color][motivationPlanet.tone < 4 ? '13' : '46'],
            icon: 'icon-awareness'
        },
    ]
}

export const getConnectionPropertyData = (chart, channels) => {
    let undefinedCentersCount = chart.chart.centers.filter(x => x !== centerDefinitionStates.DEFINED).length;
    let definedCentersCount = chart.chart.centers.length - undefinedCentersCount;

    return {
        connectionTheme: {
            data: definedCentersCount  + ' and ' + undefinedCentersCount,
            icon: 'icon-connection-theme'
        },
        electromagneticCount: {
            data: channels.filter(x => x.state === channelCompositionStates.ELECTROMAGNETIC).length,
            icon: 'icon-electromagnetic-connection'
        },
        dominanceCount: {
            data: channels.filter(x => x.state === channelCompositionStates.DOMINANCE).length,
            icon: 'icon-love-dominance'
        },
        compromiseCount: {
            data: channels.filter(x => x.state === channelCompositionStates.COMPROMISE).length,
            icon: 'icon-compromise'
        },
        companionshipCount: {
            data: channels.filter(x => x.state === channelCompositionStates.COMPANIONSHIP).length,
            icon: 'icon-companionship'
        },
    }
}

export const getTransitData = (chart, formatDate) => {
    return {
        transitLocal: formatDate(chart.meta.transit.time.utc, false),
        transitUtc: formatDate(chart.meta.transit.time.utc, true),
        transitCross: getValue(chart.meta.transit.cross, 'crosses'),
    }
}

export const getCycleData = (chart, formatDate) => {
    return {
        cycleLocal: formatDate(chart.meta.transit.time.utc, false),
        cycleUtc: formatDate(chart.meta.transit.time.utc, true),
        cycleDesign: formatDate(chart.meta.transit.time.design, true),
        cycleCross: getValue(chart.meta.transit.cross, 'crosses'),
        age: chart.meta.transit.age,
    }
}

export function getBaseAlignmentSymbols(planet){

    if(![planetConstants.SUN, planetConstants.EARTH, planetConstants.NORTH_NODE, planetConstants.SOUTH_NODE].includes(planet.planet)) return {};
    // USe case of personality south node
    if(planet.activation === 1 && planet.planet === 4){
        if(planet.base === 1) return {topLeft: true};
        if(planet.base === 2) return {topRight: true};
        if(planet.base === 3) return {topLeft: true, topRight: true, bottomLeft: true, bottomRight: true};
        if(planet.base === 4) return {bottomRight: true};
        if(planet.base === 5) return {bottomLeft: true};

        return {};
    }

    // Use case of design north node
    if(planet.activation === 0 && planet.planet === 3){
        if(planet.base === 1) return {bottomLeft: true};
        if(planet.base === 2) return {topRight: true};
        if(planet.base === 3) return {topLeft: true}
        if(planet.base === 4) return {bottomRight: true};
        if(planet.base === 5) return {topLeft: true, topRight: true, bottomLeft: true, bottomRight: true};
        return {};
    }

    if(planet.baseAlignment === binaryAlignments.TOP_LEFT) return {topLeft: true}
    if(planet.baseAlignment === binaryAlignments.TOP_RIGHT) return {topRight: true}
    if(planet.baseAlignment === binaryAlignments.BOTTOM_LEFT) return {bottomLeft: true}
    if(planet.baseAlignment === binaryAlignments.BOTTOM_RIGHT) return {bottomRight: true};

    return {topLeft: true, topRight: true, bottomLeft: true, bottomRight: true};
}