const LEFT = 0
const RIGHT = 1
const JUXTAPOSED = 2

export const orientations = {
  LEFT,
  RIGHT,
  JUXTAPOSED
}

export const crosses = [
  { id: 0, gates: [44, 24, 7, 13], orientation: JUXTAPOSED },
  { id: 1, gates: [54, 53, 32, 42], orientation: JUXTAPOSED },
  { id: 2, gates: [12, 11, 25, 46], orientation: JUXTAPOSED },
  { id: 3, gates: [23, 43, 30, 29], orientation: JUXTAPOSED },
  { id: 4, gates: [37, 40, 5, 35], orientation: JUXTAPOSED },
  { id: 5, gates: [53, 54, 42, 32], orientation: JUXTAPOSED },
  { id: 6, gates: [10, 15, 18, 17], orientation: JUXTAPOSED },
  { id: 7, gates: [27, 28, 19, 33], orientation: JUXTAPOSED },
  { id: 8, gates: [29, 30, 20, 34], orientation: JUXTAPOSED },
  { id: 9, gates: [42, 32, 60, 56], orientation: JUXTAPOSED },
  { id: 10, gates: [6, 36, 15, 10], orientation: JUXTAPOSED },
  { id: 11, gates: [64, 63, 45, 26], orientation: JUXTAPOSED },
  { id: 12, gates: [32, 42, 56, 60], orientation: JUXTAPOSED },
  { id: 13, gates: [8, 14, 55, 59], orientation: JUXTAPOSED },
  { id: 14, gates: [21, 48, 54, 53], orientation: JUXTAPOSED },
  { id: 15, gates: [18, 17, 39, 38], orientation: JUXTAPOSED },
  { id: 16, gates: [36, 6, 10, 15], orientation: JUXTAPOSED },
  { id: 17, gates: [40, 37, 35, 5], orientation: JUXTAPOSED },
  { id: 18, gates: [48, 21, 53, 54], orientation: JUXTAPOSED },
  { id: 19, gates: [62, 61, 3, 50], orientation: JUXTAPOSED },
  { id: 20, gates: [63, 64, 26, 45], orientation: JUXTAPOSED },
  { id: 21, gates: [2, 1, 49, 4], orientation: JUXTAPOSED },
  { id: 22, gates: [14, 8, 59, 55], orientation: JUXTAPOSED },
  { id: 23, gates: [35, 5, 22, 47], orientation: JUXTAPOSED },
  { id: 24, gates: [16, 9, 63, 64], orientation: JUXTAPOSED },
  { id: 25, gates: [15, 10, 17, 18], orientation: JUXTAPOSED },
  { id: 26, gates: [41, 31, 44, 24], orientation: JUXTAPOSED },
  { id: 27, gates: [30, 29, 34, 20], orientation: JUXTAPOSED },
  { id: 28, gates: [9, 16, 64, 63], orientation: JUXTAPOSED },
  { id: 29, gates: [4, 49, 8, 14], orientation: JUXTAPOSED },
  { id: 30, gates: [22, 47, 11, 12], orientation: JUXTAPOSED },
  { id: 31, gates: [5, 35, 47, 22], orientation: JUXTAPOSED },
  { id: 32, gates: [11, 12, 46, 25], orientation: JUXTAPOSED },
  { id: 33, gates: [31, 41, 24, 44], orientation: JUXTAPOSED },
  { id: 34, gates: [25, 46, 58, 52], orientation: JUXTAPOSED },
  { id: 35, gates: [43, 23, 29, 30], orientation: JUXTAPOSED },
  { id: 36, gates: [7, 13, 23, 43], orientation: JUXTAPOSED },
  { id: 37, gates: [57, 51, 62, 61], orientation: JUXTAPOSED },
  { id: 38, gates: [60, 56, 28, 27], orientation: JUXTAPOSED },
  { id: 39, gates: [13, 7, 43, 23], orientation: JUXTAPOSED },
  { id: 40, gates: [55, 59, 9, 16], orientation: JUXTAPOSED },
  { id: 41, gates: [3, 50, 41, 31], orientation: JUXTAPOSED },
  { id: 42, gates: [19, 33, 1, 2], orientation: JUXTAPOSED },
  { id: 43, gates: [20, 34, 37, 40], orientation: JUXTAPOSED },
  { id: 44, gates: [17, 18, 38, 39], orientation: JUXTAPOSED },
  { id: 45, gates: [38, 39, 57, 51], orientation: JUXTAPOSED },
  { id: 46, gates: [47, 22, 12, 11], orientation: JUXTAPOSED },
  { id: 47, gates: [45, 26, 36, 6], orientation: JUXTAPOSED },
  { id: 48, gates: [34, 20, 40, 37], orientation: JUXTAPOSED },
  { id: 49, gates: [49, 4, 14, 8], orientation: JUXTAPOSED },
  { id: 50, gates: [39, 38, 51, 57], orientation: JUXTAPOSED },
  { id: 51, gates: [24, 44, 13, 7], orientation: JUXTAPOSED },
  { id: 52, gates: [33, 19, 2, 1], orientation: JUXTAPOSED },
  { id: 53, gates: [28, 27, 33, 19], orientation: JUXTAPOSED },
  { id: 54, gates: [1, 2, 4, 49], orientation: JUXTAPOSED },
  { id: 55, gates: [46, 25, 52, 58], orientation: JUXTAPOSED },
  { id: 56, gates: [51, 57, 61, 62], orientation: JUXTAPOSED },
  { id: 57, gates: [52, 58, 21, 48], orientation: JUXTAPOSED },
  { id: 58, gates: [56, 60, 27, 28], orientation: JUXTAPOSED },
  { id: 59, gates: [59, 55, 16, 9], orientation: JUXTAPOSED },
  { id: 60, gates: [61, 62, 50, 3], orientation: JUXTAPOSED },
  { id: 61, gates: [26, 45, 6, 36], orientation: JUXTAPOSED },
  { id: 62, gates: [50, 3, 31, 41], orientation: JUXTAPOSED },
  { id: 63, gates: [58, 52, 48, 21], orientation: JUXTAPOSED },
  { id: 64, gates: [27, 28, 19, 33], orientation: LEFT },
  { id: 65, gates: [28, 27, 33, 19], orientation: LEFT },
  { id: 66, gates: [31, 41, 24, 44], orientation: LEFT },
  { id: 67, gates: [41, 31, 44, 24], orientation: LEFT },
  { id: 68, gates: [51, 57, 61, 62], orientation: LEFT },
  { id: 69, gates: [57, 51, 62, 61], orientation: LEFT },
  { id: 70, gates: [45, 26, 36, 6], orientation: LEFT },
  { id: 71, gates: [26, 45, 6, 36], orientation: LEFT },
  { id: 72, gates: [53, 54, 42, 32], orientation: LEFT },
  { id: 73, gates: [54, 53, 32, 42], orientation: LEFT },
  { id: 74, gates: [23, 43, 30, 29], orientation: LEFT },
  { id: 75, gates: [43, 23, 29, 30], orientation: LEFT },
  { id: 76, gates: [2, 1, 49, 4], orientation: LEFT },
  { id: 77, gates: [1, 2, 4, 49], orientation: LEFT },
  { id: 78, gates: [52, 58, 21, 48], orientation: LEFT },
  { id: 79, gates: [58, 52, 48, 21], orientation: LEFT },
  { id: 80, gates: [56, 60, 27, 28], orientation: LEFT },
  { id: 81, gates: [60, 56, 28, 27], orientation: LEFT },
  { id: 82, gates: [63, 64, 26, 45], orientation: LEFT },
  { id: 83, gates: [64, 63, 45, 26], orientation: LEFT },
  { id: 84, gates: [20, 34, 37, 40], orientation: LEFT },
  { id: 85, gates: [34, 20, 40, 37], orientation: LEFT },
  { id: 86, gates: [12, 11, 25, 46], orientation: LEFT },
  { id: 87, gates: [11, 12, 46, 25], orientation: LEFT },
  { id: 88, gates: [21, 48, 54, 53], orientation: LEFT },
  { id: 89, gates: [48, 21, 53, 54], orientation: LEFT },
  { id: 90, gates: [25, 46, 58, 52], orientation: LEFT },
  { id: 91, gates: [46, 25, 52, 58], orientation: LEFT },
  { id: 92, gates: [16, 9, 63, 64], orientation: LEFT },
  { id: 93, gates: [9, 16, 64, 63], orientation: LEFT },
  { id: 94, gates: [24, 44, 13, 7], orientation: LEFT },
  { id: 95, gates: [44, 24, 7, 13], orientation: LEFT },
  { id: 96, gates: [39, 38, 51, 57], orientation: LEFT },
  { id: 97, gates: [38, 39, 57, 51], orientation: LEFT },
  { id: 98, gates: [30, 29, 34, 20], orientation: LEFT },
  { id: 99, gates: [29, 30, 20, 34], orientation: LEFT },
  { id: 100, gates: [22, 47, 11, 12], orientation: LEFT },
  { id: 101, gates: [47, 22, 12, 11], orientation: LEFT },
  { id: 102, gates: [42, 32, 60, 56], orientation: LEFT },
  { id: 103, gates: [32, 42, 56, 60], orientation: LEFT },
  { id: 104, gates: [13, 7, 43, 23], orientation: LEFT },
  { id: 105, gates: [7, 13, 23, 43], orientation: LEFT },
  { id: 106, gates: [37, 40, 5, 35], orientation: LEFT },
  { id: 107, gates: [40, 37, 35, 5], orientation: LEFT },
  { id: 108, gates: [62, 61, 3, 50], orientation: LEFT },
  { id: 109, gates: [61, 62, 50, 3], orientation: LEFT },
  { id: 110, gates: [36, 6, 10, 15], orientation: LEFT },
  { id: 111, gates: [6, 36, 15, 10], orientation: LEFT },
  { id: 112, gates: [15, 10, 17, 18], orientation: LEFT },
  { id: 113, gates: [10, 15, 18, 17], orientation: LEFT },
  { id: 114, gates: [33, 19, 2, 1], orientation: LEFT },
  { id: 115, gates: [19, 33, 1, 2], orientation: LEFT },
  { id: 116, gates: [49, 4, 14, 8], orientation: LEFT },
  { id: 117, gates: [4, 49, 8, 14], orientation: LEFT },
  { id: 118, gates: [35, 5, 22, 47], orientation: LEFT },
  { id: 119, gates: [5, 35, 47, 22], orientation: LEFT },
  { id: 120, gates: [55, 59, 9, 16], orientation: LEFT },
  { id: 121, gates: [59, 55, 16, 9], orientation: LEFT },
  { id: 122, gates: [8, 14, 55, 59], orientation: LEFT },
  { id: 123, gates: [14, 8, 59, 55], orientation: LEFT },
  { id: 124, gates: [17, 18, 38, 39], orientation: LEFT },
  { id: 125, gates: [18, 17, 39, 38], orientation: LEFT },
  { id: 126, gates: [3, 50, 41, 31], orientation: LEFT },
  { id: 127, gates: [50, 3, 31, 41], orientation: LEFT },
  { id: 128, gates: [63, 64, 5, 35], orientation: RIGHT },
  { id: 129, gates: [35, 5, 63, 64], orientation: RIGHT },
  { id: 130, gates: [64, 63, 35, 5], orientation: RIGHT },
  { id: 131, gates: [5, 35, 64, 63], orientation: RIGHT },
  { id: 132, gates: [30, 29, 14, 8], orientation: RIGHT },
  { id: 133, gates: [8, 14, 30, 29], orientation: RIGHT },
  { id: 134, gates: [29, 30, 8, 14], orientation: RIGHT },
  { id: 135, gates: [14, 8, 29, 30], orientation: RIGHT },
  { id: 136, gates: [36, 6, 11, 12], orientation: RIGHT },
  { id: 137, gates: [12, 11, 36, 6], orientation: RIGHT },
  { id: 138, gates: [6, 36, 12, 11], orientation: RIGHT },
  { id: 139, gates: [11, 12, 6, 36], orientation: RIGHT },
  { id: 140, gates: [49, 4, 43, 23], orientation: RIGHT },
  { id: 141, gates: [23, 43, 49, 4], orientation: RIGHT },
  { id: 142, gates: [4, 49, 23, 43], orientation: RIGHT },
  { id: 143, gates: [43, 23, 4, 49], orientation: RIGHT },
  { id: 144, gates: [24, 44, 19, 33], orientation: RIGHT },
  { id: 145, gates: [33, 19, 24, 44], orientation: RIGHT },
  { id: 146, gates: [44, 24, 33, 19], orientation: RIGHT },
  { id: 147, gates: [19, 33, 44, 24], orientation: RIGHT },
  { id: 148, gates: [3, 50, 60, 56], orientation: RIGHT },
  { id: 149, gates: [56, 60, 3, 50], orientation: RIGHT },
  { id: 150, gates: [50, 3, 56, 60], orientation: RIGHT },
  { id: 151, gates: [60, 56, 50, 3], orientation: RIGHT },
  { id: 152, gates: [42, 32, 61, 62], orientation: RIGHT },
  { id: 153, gates: [62, 61, 42, 32], orientation: RIGHT },
  { id: 154, gates: [32, 42, 62, 61], orientation: RIGHT },
  { id: 155, gates: [61, 62, 32, 42], orientation: RIGHT },
  { id: 156, gates: [51, 57, 54, 53], orientation: RIGHT },
  { id: 157, gates: [53, 54, 51, 57], orientation: RIGHT },
  { id: 158, gates: [57, 51, 53, 54], orientation: RIGHT },
  { id: 159, gates: [54, 53, 57, 51], orientation: RIGHT },
  { id: 160, gates: [37, 40, 9, 16], orientation: RIGHT },
  { id: 161, gates: [16, 9, 37, 40], orientation: RIGHT },
  { id: 162, gates: [40, 37, 16, 9], orientation: RIGHT },
  { id: 163, gates: [9, 16, 40, 37], orientation: RIGHT },
  { id: 164, gates: [22, 47, 26, 45], orientation: RIGHT },
  { id: 165, gates: [45, 26, 22, 47], orientation: RIGHT },
  { id: 166, gates: [47, 22, 45, 26], orientation: RIGHT },
  { id: 167, gates: [26, 45, 47, 22], orientation: RIGHT },
  { id: 168, gates: [17, 18, 58, 52], orientation: RIGHT },
  { id: 169, gates: [52, 58, 17, 18], orientation: RIGHT },
  { id: 170, gates: [18, 17, 52, 58], orientation: RIGHT },
  { id: 171, gates: [58, 52, 18, 17], orientation: RIGHT },
  { id: 172, gates: [55, 59, 34, 20], orientation: RIGHT },
  { id: 173, gates: [20, 34, 55, 59], orientation: RIGHT },
  { id: 174, gates: [59, 55, 20, 34], orientation: RIGHT },
  { id: 175, gates: [34, 20, 59, 55], orientation: RIGHT },
  { id: 176, gates: [13, 7, 1, 2], orientation: RIGHT },
  { id: 177, gates: [2, 1, 13, 7], orientation: RIGHT },
  { id: 178, gates: [7, 13, 2, 1], orientation: RIGHT },
  { id: 179, gates: [1, 2, 7, 13], orientation: RIGHT },
  { id: 180, gates: [21, 48, 38, 39], orientation: RIGHT },
  { id: 181, gates: [39, 38, 21, 48], orientation: RIGHT },
  { id: 182, gates: [48, 21, 39, 38], orientation: RIGHT },
  { id: 183, gates: [38, 39, 48, 21], orientation: RIGHT },
  { id: 184, gates: [27, 28, 41, 31], orientation: RIGHT },
  { id: 185, gates: [31, 41, 27, 28], orientation: RIGHT },
  { id: 186, gates: [28, 27, 31, 41], orientation: RIGHT },
  { id: 187, gates: [41, 31, 28, 27], orientation: RIGHT },
  { id: 188, gates: [25, 46, 10, 15], orientation: RIGHT },
  { id: 189, gates: [15, 10, 25, 46], orientation: RIGHT },
  { id: 190, gates: [46, 25, 15, 10], orientation: RIGHT },
  { id: 191, gates: [10, 15, 46, 25], orientation: RIGHT }
]
