<template>
  <side-extended-panel id="share-chart"
                       :title="`${$t('charts.properties.share')} ${chartName}`"
                       :close-btn-text="$t('owl.close')"
                       :visible="show"
                       size="small"
                       @close="() => $store.commit('ui/CLOSE_SIDE_SHEET')">
    <div class="py-5">
      <div class="qr-wrapper text-center mx-auto py-3 mx-4">
        <canvas ref="qr-render" id="qr-render" class="mx-auto bg-light rounded"/>
      </div>

      <div class="text-center">
        <p class="mbg-text-muted">
          {{ $t('charts.properties.uniqueUrl')}}
        </p>
        <b-button variant="primary" @click="copy">{{ $t(`general.${copied ? 'copied': 'copy'}`) }}</b-button>
        <mbg-text-input id="share-input" :value="url" disabled class="my-0 mx-auto text-center" style="width:85%"></mbg-text-input>

        <b-button variant="mmi" block class="mx-auto mt-4 d-flex align-items-center justify-content-center gap-10" style="width:85%" @click="copyMmi">
          <img v-if="!mmiCopied" src="https://app.maiamechanics.com/assets/images/maia-mechanics-logo.svg" width="24" />
          <span v-else class="icon-check" />
          <span v-if="mmiCopied">{{ $t('general.copied')}}</span>
          <span v-else>
            Share to MaiaMechanics.com
          </span>
          </b-button>
      </div>
    </div>
  </side-extended-panel>

</template>

<style lang="scss">

#share-input{
  input{
    opacity:1!important;
  }
}
.btn-mmi {
  border: 1px solid transparent;
  &:hover {
    font-weight: 400!important;
  }
}
.theme--light{
  .btn-mmi{
    background-color: #f3f3f3!important;
    &:hover{
      border-color: $bg-dark!important;
    }
  }
}
.theme--dark{
  .btn-mmi{
    background-color: $bg-contrast-dark-low-opacity!important;
    color:white;
    &:hover{
      color:white;
      border-color:white;
    }
}

}
</style>

<script>
import SideExtendedPanel from "../SideExtendedPanel.vue";

import qrCode from "qrcode";
import MbgTextInput from "../../elements/MbgTextInput.vue";
import {config} from "@/utils/config";
import {SidebarVariants} from "@/utils/localStorage";

const renderQr = (element, url) => {
  qrCode.toCanvas(element, url);
}
export default{
  components: {MbgTextInput, SideExtendedPanel},
  props:{
    chartId: String,
    chartName: String,
  },
  computed:{
    show(){
      return this.$store.state.ui.sideSheet.type === SidebarVariants.ShareChart
    },
    url(){
      return `${config.clientUrl}/public/${this.chartId}`
    },
    mmiUrl() {
      return `${config.clientUrl.replace('mybodygraph', 'maiamechanics')}/#/share/${this.chartId}`;
    }
  },

  watch:{
    chartId(){
      if(this.$refs['qr-render']) renderQr(this.$refs['qr-render'], this.url);
    }
  },
  data(){
    return{
      config,
      copied: false,
      mmiCopied: false
    }
  },
  mounted(){
    renderQr(this.$refs['qr-render'], `${this.config.clientUrl}/public/${this.chartId}`);
  },
  methods:{
    copyMmi() {
      this.mmiCopied = true;
      navigator.clipboard.writeText(this.mmiUrl);
      setTimeout(()=>{
        this.mmiCopied = false;
      }, 2000)
    },
    copy(){
      this.copied = true;
      navigator.clipboard.writeText(this.url);
      setTimeout(()=>{
        this.copied = false;
      }, 2000)
    }
  }

}
</script>