export default {
  RAVE_CHART: 'Personal Chart',
  TRANSIT_CHART: 'Transit Chart',
  CONNECTION_CHART: 'Connection Chart',
  PENTA_CHART: 'Penta Chart',
  CYCLE_CHART: 'Cycle Chart',
  plural: {
    RAVE_CHART: 'Personal Charts',
    TRANSIT_CHART: 'Transit Charts',
    CONNECTION_CHART: 'Connection Charts',
    CYCLE_CHART: 'Cycle Charts',
  }
}
