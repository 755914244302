<template>
    <dynamic-zone-renderer v-if="content" :title="content.title"
                           :components="content.components"
                           :add-paddings="false"
                           is-toggleable
                           @titleMouseover="highlightElement({
                                  type: types.gate,
                                  id: gate.gateId,
                                  includePlanets: true,
                           })"
                           @titleMouseleave="clearAllHighlights"
    >
      <div v-if="theme && hasThirdLine && theme.components.length > 0" class="bg-light casual-border-radius p-4 mb-4 position-relative">
        <dynamic-zone-renderer v-if="theme" :add-paddings="false" :components="theme.components" />
        <span class="icon-v2-profit-centers position-absolute" style="bottom:5px;right:5px;font-size:100px;opacity:0.1" />
      </div>
    </dynamic-zone-renderer>

</template>

<script>
import AnalysisQueryMixin from "@/mixins/AnalysisQueryMixin";
import WorkSuccessMixin from "@/mixins/WorkSuccessMixin";
import DynamicZoneRenderer from "@/components/renderer/DynamicZoneRenderer.vue";
import HighlightingMixin from "@/mixins/HighlightingMixin";

export default {
  components: {DynamicZoneRenderer},
  mixins: [AnalysisQueryMixin, WorkSuccessMixin, HighlightingMixin],
  props: {
    chart: Object,
    gate: Object,
  },

  data(){
    return {
      content: null,
      theme: null
    }
  },
  computed: {
    hasThirdLine() {
      const profile = this.chart.chart.profile;
      return profile.toString().includes('3');
    }
  },
  created() {
    this.getMoneyLine(this.chart, this.gate.gateId, 'content');
    this.getTheme(this.chart, this.gate.line, 'theme');
  },
}
</script>