<template>

  <div class="py-5 my-5 text-center px-3">
    <div class="mb-4">
      <svg width="92" height="92" viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M87.25 0.166672H4.74996C1.99996 0.166672 0.166626 2 0.166626 4.75V87.25C0.166626 90 1.99996 91.8333 4.74996 91.8333H87.25C90 91.8333 91.8333 90 91.8333 87.25V4.75C91.8333 2 90 0.166672 87.25 0.166672ZM72.125 33.625L40.9583 64.7917C39.125 66.625 36.375 66.625 34.5416 64.7917L19.875 50.125C18.0416 48.2917 18.0416 45.5417 19.875 43.7083C21.7083 41.875 24.4583 41.875 26.2916 43.7083L37.75 55.1667L65.7083 27.2083C67.5416 25.375 70.2916 25.375 72.125 27.2083C73.9583 29.0417 73.9583 31.7917 72.125 33.625Z" fill="#AF0201"/>
        <path d="M87.25 0.166672H4.74996C1.99996 0.166672 0.166626 2 0.166626 4.75V87.25C0.166626 90 1.99996 91.8333 4.74996 91.8333H87.25C90 91.8333 91.8333 90 91.8333 87.25V4.75C91.8333 2 90 0.166672 87.25 0.166672ZM72.125 33.625L40.9583 64.7917C39.125 66.625 36.375 66.625 34.5416 64.7917L19.875 50.125C18.0416 48.2917 18.0416 45.5417 19.875 43.7083C21.7083 41.875 24.4583 41.875 26.2916 43.7083L37.75 55.1667L65.7083 27.2083C67.5416 25.375 70.2916 25.375 72.125 27.2083C73.9583 29.0417 73.9583 31.7917 72.125 33.625Z" fill="#D04948"/>
        <path d="M87.25 0.166672H4.74996C1.99996 0.166672 0.166626 2 0.166626 4.75V87.25C0.166626 90 1.99996 91.8333 4.74996 91.8333H87.25C90 91.8333 91.8333 90 91.8333 87.25V4.75C91.8333 2 90 0.166672 87.25 0.166672ZM72.125 33.625L40.9583 64.7917C39.125 66.625 36.375 66.625 34.5416 64.7917L19.875 50.125C18.0416 48.2917 18.0416 45.5417 19.875 43.7083C21.7083 41.875 24.4583 41.875 26.2916 43.7083L37.75 55.1667L65.7083 27.2083C67.5416 25.375 70.2916 25.375 72.125 27.2083C73.9583 29.0417 73.9583 31.7917 72.125 33.625Z" fill="#D14B49"/>
        <path d="M87.25 0.166672H4.74996C1.99996 0.166672 0.166626 2 0.166626 4.75V87.25C0.166626 90 1.99996 91.8333 4.74996 91.8333H87.25C90 91.8333 91.8333 90 91.8333 87.25V4.75C91.8333 2 90 0.166672 87.25 0.166672ZM72.125 33.625L40.9583 64.7917C39.125 66.625 36.375 66.625 34.5416 64.7917L19.875 50.125C18.0416 48.2917 18.0416 45.5417 19.875 43.7083C21.7083 41.875 24.4583 41.875 26.2916 43.7083L37.75 55.1667L65.7083 27.2083C67.5416 25.375 70.2916 25.375 72.125 27.2083C73.9583 29.0417 73.9583 31.7917 72.125 33.625Z" fill="#C25354"/>
      </svg>
    </div>

    <h1 class="mb-4">{{$t('billing.checkout.thanks.title')}}, <span class="text-primary">{{ user.fullName}}!</span></h1>

    <div v-html="$t('billing.checkout.thanks.text')"/>

    <div class="row mt-5 thank-you-buttons mx-0">
      <div class="col-md-6 text-md-right">
        <MbgIconButton
          variant="dark"
          icon="icon-bodygraph-outline"
          :title="$t('billing.checkout.thanks.lastChart.title')"
          :subtitle="$t('billing.checkout.thanks.lastChart.subtitle')"
          @click="visitLastChart"
          :disabled="!lastVisitedChart"
        />
      </div>
      <div class="col-md-6 text-md-left mt-3 mt-md-0">
        <MbgIconButton
            variant="primary"
            icon="icon-bodygraph-outline"
            :title="$t('billing.checkout.thanks.library.title')"
            :subtitle="$t('billing.checkout.thanks.library.subtitle')"
            @click="visitLibrary"
        />
      </div>
    </div>

    <div class="mt-5 text-center">
      <p>
        {{$t('billing.checkout.thanks.gift')}}
      </p>
      <b-button variant="outline-white" @click="$router.push({
        name: 'account',
        query: {
          tab: 'billing',
          category: 'gifts'
        }
      })">{{$t('gifts.title')}}</b-button>
    </div>

  </div>
</template>


<script>

import {mapState} from "vuex";
import MbgIconButton from "../elements/MbgIconButton.vue";
import AnalyticsMixin from "@/mixins/AnalyticsMixin";

export default{
  mixins: [AnalyticsMixin],
  components: {MbgIconButton},

  computed:{
    ...mapState({
      lastVisitedChart: state => state.ui.lastVisitedChart,
      lastTransactionValue: state => state.ui.lastTransactionValue,
      user: state => state.account.user
    })
  },
  created(){
    this.facebookEvent('Purchase', {
      value: this.lastTransactionValue,
      currency: 'USD'
    });
  },
  methods:{
    visitLastChart(){
      this.$router.push('/charts/' + this.lastVisitedChart);
    },
    visitLibrary(){
      this.$router.push({
        name: 'library'
      })
    }
  }
}
</script>
<style lang="scss">
.thank-you-buttons{
  @media(max-width:540px){
    button {
      width: 65%;
    }
  }
}
</style>