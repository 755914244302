import zodiacs from '../../../../helpers/rave/constants/zodiacs'

export default {
  [zodiacs.GEMINI.id]: 'Gemini',
  [zodiacs.CANCER.id]: 'Cancer',
  [zodiacs.LEO.id]: 'Leo',
  [zodiacs.VIRGO.id]: 'Virgo',
  [zodiacs.LIBRA.id]: 'Libra',
  [zodiacs.SCORPIO.id]: 'Scorpio',
  [zodiacs.SAGGITARIUS.id]: 'Saggitarius',
  [zodiacs.CAPRICORN.id]: 'Capricorn',
  [zodiacs.AQUARIUS.id]: 'Aquarius',
  [zodiacs.PISECS.id]: 'Pisces',
  [zodiacs.ARIES.id]: 'Aries',
  [zodiacs.TAURUS.id]: 'Taurus'
}
