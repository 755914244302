import * as R from 'ramda'
import { propIn } from '../utils/misc'
import planets from './constants/planets'
import activationModes from './constants/activationModes'
import alignments from './constants/binaryAlignments'

const planetIds = [planets.SUN, planets.EARTH, planets.NORTH_NODE, planets.SOUTH_NODE]

const setAlignment = R.ifElse(
  R.propEq('activation', activationModes.DESIGN),
  p => R.assoc('baseAlignment', alignments.fromDesignBase(p.base), p),
  p => R.assoc('baseAlignment', alignments.fromPersonalityBase(p.base), p)
)

const calculateBaseAlignment = R.ifElse(
  propIn('id', planetIds),
  setAlignment,
  R.identity
)

const calculateBaseAlignments = R.map(calculateBaseAlignment)

export {
  calculateBaseAlignments
}
