export default{
    title: 'New Chart',
    personal: 'Personal',
    connection: 'Connection',
    cycle: 'Cycle',
    transit: 'Transit',
    justNow: 'Just Now',
    selectFirstChart: 'Select First Chart',
    selectSecondChart: 'Select Second Chart',
    selectChart: 'Select Chart',
    selectBirthChart: 'Select Main Chart',
    optional: 'optional',
    create: 'Create Chart',
    update: 'Update Chart',
    customDate: 'Custom date',
    transitDate: 'Date',
    transitTime: 'Time (UTC)',
    transitTimeLocal: 'Time (Local)',
    useLocal: 'Use Local Time',
    useLocalExplain: '(Deselect this only if you are converting the time into UTC yourself)'
}