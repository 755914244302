export default {
  CENTER: 'center',
  GATE: 'gate',
  GATE_PENTA: 'gatepenta',
  PENTA_GATE: 'pentagate',
  PENTA_LINE: 'pentaline',
  LINE: 'line',
  CHANNEL: 'channel',
  CIRCUIT: 'circuit',
  PLANET: 'planet',
  HEXAGRAM: 'hexagram',
  COLOR: 'color',
  TONE: 'tone',
  BASE: 'base'
}
