<template>
  <div>
    <dynamic-zone-renderer v-if="profileIntro" :is-toggleable="true" :title="profileIntro.name" :components="profileIntro.components" :add-paddings="false"/>
    <dynamic-zone-renderer v-if="profile" :type="$t('hd.properties.profile')" :title="profile.name" :components="profile.components" :add-paddings="false"/>

    <dynamic-zone-renderer v-if="profileTypeCombo"

                           :is-toggleable="true"
                           :title="$t('charts.analysis.typeProfileSynthesis')"
                           :components="profileTypeCombo.components" :add-paddings="false"/>
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../../../renderer/DynamicZoneRenderer.vue";
import AnalysisQueryMixin from "../../../../../mixins/AnalysisQueryMixin";

export default {
  components: {DynamicZoneRenderer},

  props:{
    chart: Object
  },
  mixins: [AnalysisQueryMixin],

  data(){
    return {
      profile: null,
      profileIntro: null,
      profileTypeCombo: null,
    }
  },

  created(){
    this.getProfile();
    this.getProfileIntroduction();
    this.getProfileTypeCombo();

  },
  methods:{
    getProfileTypeCombo(){
      let url = '/profile-type-combos/' + this.chart.chart.profile + '.' + this.chart.chart.type;
      this.getContent('profileTypeCombo', url);
    },
    getProfile(){
      let url = '/profiles/' +  this.chart.chart.profile
      this.getContent('profile', url);
      // console.log(this.getContent());
    },

    getProfileIntroduction(){
      let url = '/analysis/introductions/introduction.profile';
      this.getContent('profileIntro', url);
    },
  }
}
</script>