//  2022 , 10 => 2022, 9 => 2022, 0 => 2021, 11
const getPreviousYearMonth = (year, month) => {
    const object = {
        month: month > 0 ? month -1 : 11,
        year: month > 0 ? year : year - 1
    }
    object.value = object.year + '_'+ object.month // 2022_9 2022_10 2022_11
    return object
}

//  2022 , 10 => 2022, 11  /// 2022, 11 => 2023, 0
const getNextYearMonth = (year, month) => {
    const object = {
        month: month  < 11 ? month + 1 : 0,
        year: month < 11 ? year : year + 1,
    }
    object.value = object.year + '_'+ object.month
    return object
}

module.exports={
    getPreviousYearMonth,
    getNextYearMonth
}