import * as R from 'ramda'
import { isEq } from '@/helpers/utils/misc'

const SURVIVAL = 1
const POSSIBILITY = 2
const POWER = 3
const WANTING = 4
const PROBABILITY = 5
const PERSONAL = 6

const fromColor = R.cond([
  [isEq(1), R.always(SURVIVAL)],
  [isEq(2), R.always(POSSIBILITY)],
  [isEq(3), R.always(POWER)],
  [isEq(4), R.always(WANTING)],
  [isEq(5), R.always(PROBABILITY)],
  [isEq(6), R.always(PERSONAL)]
])

export default { SURVIVAL, POSSIBILITY, POWER, WANTING, PROBABILITY, PERSONAL, fromColor }
