<template>
  <div v-if="version === 'card'" class="text-center">
    <span class="icon-small-lock" style="font-size:30px;"></span>
    <h4 class="mt-3">
      <span v-if="title">
        {{title}}
      </span>
      <span v-else>
              {{$t('billing.unlock')}}
      </span>

    </h4>
    <p class="mbg-text-muted">
      {{subtitle}}
    </p>

    <b-button variant="unlock-chart" @click="$emit('clickedUnlock')">{{buttonText}} </b-button>
  </div>
  <div v-else>
    <div class="d-flex gap-5 align-items-center justify-content-center">
      <span class="icon-small-lock mr-4 flex-shrink-0" style="font-size:30px;"></span>
      <div style="max-width:60%;">
        <h6>
          {{title}}
        </h6>
        <span class="text-sm">
          {{subtitle}}
        </span>
      </div>
      <b-button size="sm" class="btn-unlock-chart ml-5 flex-shrink-0" @click="$emit('clickedUnlock')">{{buttonText}} </b-button>

    </div>
  </div>

</template>


<script>
export default{

  props: {
    title: String,
    subtitle: String,
    buttonText: String,
    version: {
      type: String,
      default: 'card'
    }
  },

}

</script>