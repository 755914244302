<template>

  <div class="timer-clock">


<!--    We are reversing the percentage num to make our watch work correctyly as it works on reversed mode. -->
    <div :id="id" class="pie-wrapper pie-wrapper--solid  " :class="`progress-${getPercentageProgress}`" :style="`width:${width};height:${height};`">
    </div>

    <b-tooltip :target="id" variant="primary">{{ getPercentageNum }}%</b-tooltip>


  </div>

</template>

<style lang="scss">

// -- vars
$bg-color: #34495e;
$default-size: 1em;

// -- mixins
@mixin size($width, $height) {
  height: $height;
  width: $width;
}


@mixin draw-progress--solid($progress, $color, $bg-color) {
  background: linear-gradient(to right, $color 50%, $bg-color 50%);

  &:before {
    @if $progress <= 50 {
      background: $bg-color;
      transform: rotate(calc((100 - (50 - $progress)) / 100) * 360deg * -1);
    } @else {
      background: $color;
      transform: rotate(calc((100 - $progress) / 100) * 360deg);
    }
  }
}

.pie-wrapper {
  @include size($default-size, $default-size);
  float: left;
  position: relative;
  width:150px;
  height:150px;

  &:nth-child(3n + 1) {
    clear: both;
  }

  .pie {
    @include size(100%, 100%);
    clip: rect(0, $default-size, $default-size, calc($default-size / 2));
    left: 0;
    position: absolute;
    top: 0;
  }

}

.pie-wrapper--solid {
  border-radius: 50%;
  overflow: hidden;


  &:before {
    border-radius: 0 100% 100% 0 / 50%;
    content: '';
    display: block;
    height: 100%;
    margin-left: 50%;
    transform-origin: left;
  }

  @mixin progresses {
    @for $i from 0 through 100 {
      &.progress-#{$i} {
        @include draw-progress--solid($i, #f3f3f3, $transit-color );
      }
    }
  }

  @include progresses;

}

</style>
<script>

export default{

  props:{
    id: String,
    reversePercentageNum: {
      type: Boolean,
      default: false
    },
    percentage: Number,
    width:{
      type: String,
      default: "35px"
    },
    height:{
      type: String,
      default: "35px"
    }
  },
  computed:{

    getPercentageNum(){
      try{
        return this.percentage.toFixed(1)
      }catch(e){
        return '-'
      }

    },

    getPercentageProgress(){
      try{
        if(this.reversePercentageNum) return Math.round(100 - this.percentage)
        return Math.round(this.percentage);
      }catch(e){
        return '-'
      }
    }
  },
  methods:{
    calculateCircle(){


    }
  }

}
</script>