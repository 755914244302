<template>
  <side-extended-panel id="edit-chart"
                       :close-btn-text="$t('owl.close')"
                       :title="`${$t('charts.properties.edit')} ${chart?.meta?.name}`"
                       :visible="show"
                       @close="close">
    <div v-if="chart?.meta" :key="key" class="pb-5 pt-3">
      <!-- Name editing for non-personal chart. Personal chart has its own editor -->
      <div v-if="!isPersonalChart(chart)" class="px-3">
        <mbg-text-input
            v-model="name"
            :placeholder="$t('general.enterName')"
            :title="$t('properties.chartName')"
            input-classes="new-chart-input"
            type="text"
            @blur="updateName"
        />
      </div>

      <Personal v-if="isPersonalChart(chart)" :key="`data-${chart?.id}`" :existing-chart="chart"/>
      <TagEditor :key="`tags-${chart?.id}`" :chart="chart"/>

      <div v-if="!isUserMainChart" class="mt-5 text-right">
        <b-button
            :disabled="chart?.meta?.is_locked && !hasAdminImpersonationToken"
                  variant="primary"
                  @click="deleteChart">
          Delete Chart
        </b-button>
      </div>
      <admin-edit-chart v-if="hasAdminImpersonationToken"
                        :chart="chart"
                        :is-main-chart="isUserMainChart"
                        :validatedAdminData="validatedAdminData"/>
    </div>
  </side-extended-panel>

</template>

<script>

import SideExtendedPanel from "../SideExtendedPanel.vue";
import {mapState} from "vuex";

import TagEditor from "./partials/TagEditor.vue";
import ChartPageMixin from "../../../mixins/ChartPageMixin";
import Personal from "../../new-chart/Personal.vue";
import chartUtil from "../../../mixins/ChartUtil";
import MbgTextInput from "../../elements/MbgTextInput.vue";
import AdminEditChart from "@/components/structure/chart-sidebars/AdminEditChart.vue";
import {SidebarVariants} from "@/utils/localStorage";

export default {
  components: {AdminEditChart, MbgTextInput, Personal, SideExtendedPanel, TagEditor},
  mixins: [ChartPageMixin, chartUtil],
  watch: {
    show(val) {
      if (val) this.key++;
    },
    chart(val){
      this.name = val.meta?.name;
    }
  },
  data() {
    return {
      key: 0,
      name: null,
    }
  },

  mounted(){
    this.name = this.chart.meta?.name;
  },
  computed: {
    ...mapState({
      chart: state => state.charts.chart
    }),

    show(){
      return this.$store.state.ui.sideSheet.type === SidebarVariants.EditChart;
    },

    isUserMainChart() {
      return (!this.$store.state.account.user.chart_id) || this.chart.id === this.$store.state.account.user.chart_id
    },

    hasAdminImpersonationToken() {
      return !!this.$store.state.ui.impersonationData?.adminToken;
    }
  },
  methods: {
    // Automatically triggered when changing the chart's name.
    updateName() {
      if (this.name === this.chart.meta?.name) return;
      this.updateChartField(this.chart.id, {name: this.name});
    },
    close() {
      this.$store.commit('ui/CLOSE_SIDE_SHEET');
      this.$emit('closed');
    },
    validatedAdminData(chartId) {
      const data = this.$store.state.ui.impersonationData;
      if (!data?.adminToken || !data?.user?.id) return Promise.reject("No admin token or user ID provided");
      if (!chartId) return Promise.reject("No chart ID provided");
      return data;
    },

    async deleteChart() {
      try {
        const result = await this.$confirmDialog({
          okTitle: 'Yes, delete chart'
        });

        if (!result) return;
        // if we are an admin, we can force-delete using our Admin API endpoint.
        (this.hasAdminImpersonationToken ?
            this.adminDeleteChart(this.chart.id) :
            this.deleteDoc(this.chart.id)).then(() => {
          this.$notify({type: 'success', title: this.$t('success.title'), text: this.$t('success.deleted')});
          this.$emit('deleted', this.chart.id);
          this.$router.push({
            name: 'library'
          });
        }).catch(() => {
          this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
        })

      }catch {
        // no action. it was no!
      }

    },
    adminDeleteChart(chartId) {
      const data = this.validatedAdminData(chartId);
      // special admin token.
      return this.axios.delete(`/admin/users/${data.user.id}/delete-user-chart/${chartId}`, {
        headers: {
          'x-access-token': data.adminToken.replace('#', ''),
          'skip-interceptors': true,
        },
      });
    },
  }

}
</script>