<template>

  <div id="library-filters" class="position-relative">
    <div v-if="!selectedFilter"  class="text-right">
      <b-button size="xs" variant="link" class="d-flex align-items-center gap-3 ml-auto"
                :class="activeFilters.length > 0 && !isCelebrityLibrary ? '' : 'opacity-0'"
      @click="resetFilters">
        <span class="icon-close"/> Clear all</b-button>
    </div>
    <div v-if="selectedFilter" class="filter-box">
      <div class="row mb-4 align-items-center">
        <div class="col-7 cursor-pointer"  @click="selectedFilter = null">
          <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" style="height:12px;">
             <path d="M8.11108 0.888916L0.999973 8.00003L8.11108 15.1111" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <span class="pl-3 font-weight-bold">{{$t(`hd.properties.${filters[selectedFilter].i18nKey}`)}}</span>
        </div>
        <div class="col-5 text-right" :class="filters[selectedFilter].selected.length > 0 ? '' : 'opacity-0' ">
          <b-button variant="link" class="btn-xs m-0 p-0" @click="filters[selectedFilter].selected = [];">
            {{ $t('library.clear') }}
            <span class="icon-close text-sm"></span> </b-button>
        </div>

      </div>

      <div class="filters-checkbox-list ">
        <mbg-text-input v-model="search" :show-label="false" :title="$t('library.filters.search')"  icon="icon-search"/>
        <b-form-checkbox-group :id="'filter-'+filters[selectedFilter].prop"
                               class="checkbox-filters px-3" v-model="filters[selectedFilter].selected"
                               :options="getOptions"
                               :name="'filter-'+filters[selectedFilter].prop">
        </b-form-checkbox-group>
      </div>


    </div>

    <div v-else class="list-box">
      <div v-if="isCelebrityLibrary"
           class="cursor-pointer d-flex gap-5 align-items-center text-lg font-500"
                @click="celebrityOption.selected = []"
      ><span class="icon-arrow-left"/> Back to your library</div>

      <div v-if="celebrityOption.selected.length === 0">
        <label class="mb-3">{{$t('library.show.title')}}</label>
        <b-form-checkbox-group :id="'filter-'+chartTypeOption.prop" class="checkbox-filters mb-3"
                               v-model="chartTypeOption.selected"
                               :options="chartTypeOption.options.filter(x => {
                               if(!(user?.cycle_charts)) {
                                 return x.value !== docTypes.CYCLE_CHART
                               }
                               return true
                             })" :name="'filter-'+chartTypeOption.prop">
        </b-form-checkbox-group>
      </div>
      <div v-if="celebrityOption.selected.length === 0">
        <label class="mb-3">{{  $t('library.lockedStatus.title') }}</label>
        <b-form-checkbox-group :id="'filter-'+lockedOption.prop" class="checkbox-filters mb-3"
                               v-model="lockedOption.selected" :options="lockedOption.options"
                               :name="'filter-'+lockedOption.prop"/>
      </div>


      <div class="chart-attributes mt-4">
        <label class="mb-3">{{$t('library.attributes.title')}}</label>
        <filter-item-box v-for="(filter) in attributeFilters.filter(x => !(x.prop === 'tags' && isCelebrityLibrary))"
                         :key="filter.prop"
                         :filter="filter"
                         @selectedFilter="selectFilter"
                         @emptyFilter="filter => filter.selected = []"
        />

        <div v-if="!isCelebrityLibrary">
          <label class="my-2">{{  $t('library.celebrity.more') }}</label>
          <select-celebrity-box :is-selected="celebrityOption.selected[0]"
                                @changed="val => celebrityOption.selected = [val]"
                                :filter="celebrityOption"/>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import documentTypes from "../../helpers/docTypes";
import {mapState} from "vuex";
import authorities from '../../helpers/rave/constants/authorities'
import types from '../../helpers/rave/constants/types'
import definitions from '../../helpers/rave/constants/definitions'
import profiles from '../../helpers/rave/constants/profiles'
import {crosses} from '../../helpers/rave/constants/crosses'
import MbgTextInput from "../elements/MbgTextInput";
import docTypes from "../../helpers/docTypes";
import channels from "../../helpers/rave/constants/channels";
import gates from "../../helpers/rave/constants/gates";
import FilterItemBox from "./FilterItemBox.vue";
import SelectCelebrityBox from "./SelectCelebrityBox.vue";

export default{
  components: {SelectCelebrityBox, FilterItemBox, MbgTextInput},
  computed:{
    docTypes() {
      return docTypes
    },
    ...mapState({
      user: state => state.account.user,
      activeFilters: state => state.charts.activeFilters,
      tags: state => state.account.tags
    }),

    filterChanges() {
      return JSON.stringify(this.filters)
    },
    attributeFilters(){
      return this.filters.filter(x=> x.prop !== 'docType' && x.prop !== 'isLocked' && x.prop !== 'celebrity');
    },
    getOptions(){
      return this.filters[this.selectedFilter].options.filter(x => x.text.toLowerCase().includes(this.search.toLowerCase()))
    },

    chartTypeOption(){
      return this.filters[0]
    },
    lockedOption(){
      return this.filters[1]
    },
    celebrityOption(){
      return this.filters[2]
    },
    isCelebrityLibrary(){
      return this.celebrityOption.selected[0]
    }
  },

  watch:{
    tags(){
      this.formatFilters();
    },
    filterChanges: {
      handler(valString, oldValString) {
        let totalUpdates = 0;
        const val = JSON.parse(valString);
        const oldVal = JSON.parse(oldValString ?? "[]");

        for (let filter of val) {
          const selectedChanged = filter.selected.join(',') !== oldVal.find(x => x.prop === filter.prop).selected.join(',');
          if (!selectedChanged) continue;
          totalUpdates++;
          this.$store.dispatch('charts/updateFilter', {
            prop: filter.prop,
            value: filter.selected
          })
        }

        if (totalUpdates > 0) this.$emit('fetch');
      },
      deep: true
    }
  },
  methods:{
    async resetFilters() {
      this.$store.commit('charts/SET_ACTIVE_FILTERS', []);
      this.formatFilters(true);
    },
    formatFilters(reset = false){
      for(const [index, filter] of this.filters.entries()){

        // Accept this['...string'] format,
        // Array format
        // Object format.
        let iteration;
        if(typeof filter.values === 'string') iteration = this[filter.values];
        else {
          iteration = Array.isArray(filter.values) ? filter.values : Object.values(filter.values);
        }
        filter.options = []; // reset before looping every time this is called.
        for(let value of iteration){
          if(filter.prop === 'cross'){
            let filterValue = value.id;
            let filterText = this.$t('hd.constants.crosses.'+value.id)
            filter.options.push({
              value: filterValue,
              text: filterText
            })
          }else if(filter.prop === 'tags'){
            // const total = value.total ? ` (${value.total})` : '';
            filter.options.push({
              text: `${value.text}`,
              value: value.id,
              isTag: true
            })
          }else if(filter.prop === 'gates'){
            filter.options.push({
              text: this.$t(`hd.gates.name.one`) + ` ${value.id} - ` + this.$t(`hd.gates.${value.id}.name`),
              value: value.id
            })
          }else if(filter.prop === 'channels'){
            filter.options.push({
              text: this.$t(`hd.channels.${value.id}.name`),
              value: value.id
            })
          }else{
            filter.options.push({
              value: value,
              text: filter.prop === 'docType' ? this.$t('documentTypes.plural.' + value) : this.$t(`hd.constants.${filter.i18nKey}.${value}`)
            })
          }

        }
        let selectedDocType = this.activeFilters.find(x => x.prop === filter.prop);
        if (selectedDocType) this.filters[index].selected = [...selectedDocType.value]
        if (reset) this.filters[index].selected = [];
      }
    },
    selectFilter(filter){
      this.selectedFilter = this.filters.findIndex(x => x.prop === filter.prop);
    },
  },

  created(){
    this.formatFilters()
    if (this.$route.query.celebrity === 'true') {
      this.celebrityOption.selected = [true];
    }
  },
  data() {
    return {
      crosses,
      showAllDocTypes: true,
      search: '',
      docTypesSelected: [],
      docTypeOptions: [],
      selectedFilter: null,
      filters: [
        {prop: 'docType', i18nKey: 'docTypes', box: false, values: documentTypes, selected: [], options: []},
        {prop: 'isLocked', i18nKey: 'isLocked', box: false, values: [true], selected: [], options: []},
        {prop: 'celebrity', i18nKey: 'celebrity', box: false, values: [true], selected: [], options: []},
        {prop: 'type', i18nKey: 'types', box: {show: false, icon: 'icon-direction'}, values: types, selected: [], options: []},
        {prop: 'authority', i18nKey: 'authorities',box: {show: false,icon: 'icon-authority'}, values: authorities, selected: [], options: []},
        {prop: 'definition', i18nKey: 'definitions',box: {show: false, icon: 'icon-electromagnetic-connections'}, values: definitions, selected: [], options: []},
        {prop: 'profile', i18nKey: 'profiles',box: {show: false,icon: 'icon-circles'}, values: profiles, selected: [], options: []},
        {prop: 'cross', i18nKey: 'crosses',box: {show: false, icon: 'icon-cross'}, values: crosses, selected: [], options: []},
        {prop: 'tags', i18nKey: 'tags', isTags: true, box: { show: false, icon: 'icon-v2-tag'}, values: 'tags', selected: [], options: []},
        {prop: 'gates', i18nKey: 'gates', box: {show: false, icon: 'icon-gate'}, values: gates, selected: [], options:[]},
        {prop: 'channels', i18nKey: 'channels', box: {show: false, icon: 'icon-channels'}, values: channels, selected: [], options:[]},
      ]
    }
  },

}
</script>

<style lang="scss">
#library-filters{

  .filter-box{
    height:90vh;
  }
  .filters-checkbox-list{
    padding:5px 0;
    max-height:75vh;
    overflow-y:auto;
    border-bottom:.5px solid rgba(0,0,0,0.1);
  }
  .filter-button{
    border-radius: 20px 10px;
    padding:5px 15px;
    margin:10px 0;
    cursor:pointer;
  }
}
.theme--light{
  #library-filters{
    .filter-button{
      background: #EFEFEF;

    }
  }
}
.theme--dark{
  #library-filters{
    .filter-button{
      background: rgba(255, 255, 255, 0.05);
      box-shadow: 0 10px 25px rgba(24, 28, 36, 0.05);
    }
  }
}
</style>