const types = {
    RAVE_CHART: 'RAVE_CHART',
    CONNECTION_CHART: 'CONNECTION_CHART',
    TRANSIT_CHART: 'TRANSIT_CHART',
    CYCLE_CHART: 'CYCLE_CHART',
}

const isCompositeDocumentType = type => type === types.TRANSIT_CHART || type === types.CONNECTION_CHART || type === types.CYCLE_CHART

export { isCompositeDocumentType }
export default types
