<template>

  <div>
    <b-button variant="link" @click="$bvToast.show('color-explanation-toast')"><span class="icon-help"/></b-button>

    <b-toast id="color-explanation-toast" title="Ephemeris color key" class="casual-border-radius" static no-auto-hide>
      <div class="grid gap-10">
        <div class="d-flex align-items-center gap-10">
          <span class="explanation-ball flex-shrink-0"/>
          <p class="mb-0">
            Grey: Transit activation only.
          </p>

        </div>
        <div v-if="chart.meta.person" class="d-flex align-items-center gap-10">
          <span class="explanation-ball blue flex-shrink-0"/>
          <p class="mb-0">
            Blue: Transit and  <strong>{{ chart.meta.person.name }}</strong> activate the same gate. Transit brings emphasis.
          </p>

        </div>
        <div  v-if="chart.meta.person" class="d-flex align-items-center gap-10">
          <span class="explanation-ball green flex-shrink-0"/>
          <p class="mb-0">
            Green: Transit activates the harmonic Gate to <strong>{{ chart.meta.person.name }}'s</strong> activation, temporarily defining the Channel.
          </p>

        </div>
        <div v-if="chart.meta.person" class="d-flex align-items-center gap-10">
          <span class="explanation-ball blue green flex-shrink-0"/>
          <p class="mb-0">
            Blue & Green: Transit activates a Gate that is already activated in <strong>{{ chart.meta.person.name }}'s</strong> natal Channel.

          </p>
        </div>

        <div>
          <p>
            <strong>Tip: You can scroll through the ephemeris indefinitely in any direction! To do this on different devices:</strong>
          </p>
          <ul class="ml-0 pl-3">
            <li>
              <strong>Mobile</strong> — Press, hold and drag to the left or right
            </li>
            <li>
              <strong>Trackpad</strong> — Position your mouse anywhere within the ephemeris, place two fingers on the trackpad and drag them left or right

            </li>
            <li>
              <strong>Mouse</strong> — Position your mouse just above the Sun row, over the Date and Day/Hour text, then click and drag left or right

            </li>

           </ul>
        </div>
      </div>
    </b-toast>
  </div>

</template>

<script>
export default{

  props:{
    chart: Object
  }
}
</script>