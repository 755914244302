import * as R from 'ramda'
import { isEq } from '@/helpers/utils/misc'

const COMMUNALIST = 0
const THEIST = 1
const LEADER = 2
const MASTER = 3
const CONDITIONER = 4
const OBSERVER = 5
const SEPARATIST = 6
const ANTI_THEIST = 7
const FOLLOWER = 8
const NOVICE = 9
const CONDITIONED = 10
const OBSERVED = 11

const SELECTIVE = 12
const INTERNAL = 13
const WET = 14
const ACTIVE = 15
const NARROW = 16
const NATURAL = 17
const BLENDING = 18
const EXTERNAL = 19
const DRY = 20
const PASSIVE = 21
const WIDE = 22
const ARTIFICIAL = 23

const designLeftTone = R.cond([
  [isEq(1), R.always(SELECTIVE)],
  [isEq(2), R.always(INTERNAL)],
  [isEq(3), R.always(WET)],
  [isEq(4), R.always(ACTIVE)],
  [isEq(5), R.always(NARROW)],
  [isEq(6), R.always(NATURAL)]
])

const designRightTone = R.cond([
  [isEq(1), R.always(BLENDING)],
  [isEq(2), R.always(EXTERNAL)],
  [isEq(3), R.always(DRY)],
  [isEq(4), R.always(PASSIVE)],
  [isEq(5), R.always(WIDE)],
  [isEq(6), R.always(ARTIFICIAL)]
])

const fromDesignToneAndColor = (tone, color) =>
{
  return R.gt(4, tone) ? designLeftTone(color) :
  R.T() ? designRightTone(color) :
    null
}

const personalityLeftTone = R.cond([
  [isEq(1), R.always(COMMUNALIST)],
  [isEq(2), R.always(THEIST)],
  [isEq(3), R.always(LEADER)],
  [isEq(4), R.always(MASTER)],
  [isEq(5), R.always(CONDITIONER)],
  [isEq(6), R.always(OBSERVER)]
])

const personalityRightTone = R.cond([
  [isEq(1), R.always(SEPARATIST)],
  [isEq(2), R.always(ANTI_THEIST)],
  [isEq(3), R.always(FOLLOWER)],
  [isEq(4), R.always(NOVICE)],
  [isEq(5), R.always(CONDITIONED)],
  [isEq(6), R.always(OBSERVED)]
])

const fromPersonalityToneAndColor = (tone, color) =>
{
  return R.gt(4, tone) ? personalityLeftTone(color) :
    R.T() ? personalityRightTone(color) :
      null
}

export default {
  COMMUNALIST,
  THEIST,
  LEADER,
  MASTER,
  CONDITIONER,
  OBSERVER,
  SEPARATIST,
  ANTI_THEIST,
  FOLLOWER,
  NOVICE,
  CONDITIONED,
  OBSERVED,
  SELECTIVE,
  INTERNAL,
  WET,
  ACTIVE,
  NARROW,
  NATURAL,
  BLENDING,
  EXTERNAL,
  DRY,
  PASSIVE,
  WIDE,
  ARTIFICIAL,
  fromDesignToneAndColor,
  fromPersonalityToneAndColor
}
