<template>
  <svg class="advanced-box-wrapper" width="75" height="59" viewBox="0 0 75 59" fill="none"
       xmlns="http://www.w3.org/2000/svg"
       :class="`${isMoon ? 'moon' : ''}`"
  >
    <!-- The background! -->
    <rect y="0.5" x="0.5" width="74" height="58" rx="5"
          class="advanced-box-fill"
          :class="`${isMoon ? 'moon' : ''} ${order < 2 ? 'first-planets' : (order < 4 ? 'second-planets' : 'last-planets') }`"
          />
    
    <planet-icons :index="planet.planet" x="4" y="27" font-size="22" />

    <g :transform="order < 4 ? '' : `translate(20, 0)`">
      <advanced-fixing x="31" y="6.5" :is-detriment="planet.isDetriment" :is-exalted="planet.isExalted" :is-star="planet.isStar"/>
    </g>

    <!--  The grid of 4 boxes, if we need this.   -->
    <rect v-if="showBaseAlignment" width="18" height="18" transform="translate(51 6.5)" fill="white"/>
    <path v-if="planet.baseAlignmentSymbols.topLeft" d="M60 15.5L52 15.5L52 7.5L60 7.5L60 15.5Z" fill="#BE1111"/>
    <path v-if="planet.baseAlignmentSymbols.topRight" d="M68 15.5L60 15.5L60 7.5L68 7.5L68 15.5Z" fill="#BE1111"/>
    <path v-if="planet.baseAlignmentSymbols.bottomLeft" d="M60 23.5L52 23.5L52 15.5L60 15.5L60 23.5Z" fill="#BE1111"/>
    <path v-if="planet.baseAlignmentSymbols.bottomRight" d="M68 23.5L60 23.5L60 15.5L68 15.5L68 23.5Z" fill="#BE1111"/>
    <!--  the number!   -->
    <text v-if="showBaseAlignment" x="60" y="20.5" fill="#121212" stroke="white" text-anchor="middle" font-weight="800" stroke-width="0.8" font-size="15">
      {{planet.base}}
    </text>


    <text x="68" y="52.206" fill="currentColor" font-size="18" text-anchor="end" font-weight="700" letter-spacing="0em">
      {{ planet.gate }}.<tspan v-if="order < 4" fill="#ACFF30" font-size="15">{{ planet.line }}</tspan>
      <tspan v-else font-size="14">{{planet.line}}.{{planet.color}}</tspan>
    </text>
  </svg>
</template>

<script>

import PlanetIcons from "../partials/PlanetIcons.vue";
import AdvancedFixing from "./AdvancedFixing.vue";

export default {
  components: {AdvancedFixing, PlanetIcons},

  computed:{
    showBaseAlignment(){
      return this.planet.baseAlignmentSymbols.topLeft || this.planet.baseAlignmentSymbols.topRight || this.planet.baseAlignmentSymbols.bottomLeft || this.planet.baseAlignmentSymbols.bottomRight;
    },
  },

  props: {
    order: Number,
    stroke: String,
    planet: Object,
    isMoon: Boolean
  },
}
</script>

<style lang="scss">
.advanced-box-wrapper{
  color:white;
  &.moon{
    color: $personality-bg;
  }
}
.advanced-box-fill{
  fill: $advanced-tables-blue;
  stroke: $advanced-tables-blue;
  &.second-planets {
    fill: $advanced-tables-red;
    stroke: $advanced-tables-red;
  }
  &.first-planets {
    fill: $advanced-tables-purple;
    stroke: $advanced-tables-purple;
  }
  &.moon{
    fill: white;
    stroke: white;
  }
}
.theme--dark {
  .advanced-box-fill{
    fill: $advanced-tables-blue-dark;
    stroke: $advanced-tables-blue-dark;
    &.second-planets {
      fill: $advanced-tables-red-dark;
      stroke: $advanced-tables-red-dark;
    }
    &.first-planets {
      fill: $advanced-tables-purple-dark;
      stroke: $advanced-tables-purple-dark;
    }
    &.moon{
      fill: $personality-bg;
      stroke: $personality-bg;
    }
  }
  .advanced-box-wrapper{
    &.moon{
      color:white;
    }
  }
}
</style>