<template>
  <div>
    <dynamic-zone-renderer v-if="profitCenterDetails.centers.length && thirdLineProfileContent && !activeCenter"
                           :title="thirdLineProfileContent.title"
                           :components="thirdLineProfileContent.components"
                           :add-paddings="false" />

    <work-success-center v-for="center in data"
                         :key="center.key"
                         :center-content="center"
                         :chart="chart"
                         :center-details="profitCenterDetails.centers.find(x => +x.centerId === +center.key)"
                         :show="activeCenter === null"
                         :selected="activeCenter === center.key"
                         @select="activeCenter = center.key"
                         @deselect="activeCenter = null"
    />
    <dynamic-zone-renderer v-if="noContent && !profitCenterDetails.centers.length"
                           :components="noContent.components"
                           :add-paddings="false" />

  </div>
</template>

<script>
import WorkSuccessMixin from "@/mixins/WorkSuccessMixin";
import AnalysisQueryMixin from "@/mixins/AnalysisQueryMixin";
import WorkSuccessCenter from "@/components/charts/analysis/work_success/partials/WorkSuccessCenter.vue";
import DynamicZoneRenderer from "@/components/renderer/DynamicZoneRenderer.vue";

export default {
  components: {DynamicZoneRenderer, WorkSuccessCenter},
  mixins: [WorkSuccessMixin, AnalysisQueryMixin],
  props: {
    chart: Object
  },

  data(){
    return {
      thirdLineProfileContent: null,
      data: [],
      noContent: null,
      activeCenter: null,
    }
  },

  computed: {
    profitCenterDetails() {
      return this.getProfitCenterDetails(this.chart)
    },
  },

  mounted() {
    this.getProfitCenters(this.chart, 'data');

    if (this.profitCenterDetails?.hasThirdLine) {
      this.getContent('thirdLineProfileContent', '/analysis/introductions/introduction.work-success-third-line-profile')
    } else {
      this.getContent('thirdLineProfileContent', '/analysis/introductions/introduction.work-success.your-profit-potential');
    }

    if (this.profitCenterDetails.centers.length === 0) {
      this.getGeneralContent(this.chart, 'work-success.no-profit-centers-or-money-lines', 'noContent');
    }
  },
}
</script>