import determination from '@/helpers/rave/constants/determinations'

export default {
  [determination.CONSECUTIVE]: 'Consecutive',
  [determination.OPEN]: 'Open',
  [determination.HOT]: 'Hot',
  [determination.CALM]: 'Calm',
  [determination.HIGH]: 'High',
  [determination.DIRECT]: 'Direct',
  [determination.ALTERNATING]: 'Alternating',
  [determination.CLOSED]: 'Closed',
  [determination.COLD]: 'Cold',
  [determination.NERVOUS]: 'Nervous',
  [determination.LOW]: 'Low',
  [determination.INDIRECT]: 'Indirect'
}
