var render = function render(){var _vm=this,_c=_vm._self._c;return _c('side-extended-panel',{key:_vm.chartId,attrs:{"id":"download-image-chart","close-btn-text":_vm.$t('owl.close'),"title":`${_vm.$t('charts.properties.image')} - ${_vm.chartName}`,"visible":_vm.show,"size":"small"},on:{"close":() => _vm.$store.commit('ui/CLOSE_SIDE_SHEET')}},[_c('div',{staticClass:"py-5"},[_c('div',{staticClass:"grid gap-10"},[_c('mbg-text-input',{attrs:{"title":_vm.$t('charts.properties.imageName'),"show-label":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('mbg-select',{attrs:{"options":[
                       {
                        text: this.$t('charts.properties.regular'),
                        value: 'regular'
                      },
                      {
                        text: this.$t('charts.properties.transparent'),
                        value: 'transparent'
                      },

                  ],"title":_vm.$t('charts.properties.imageBackground')},model:{value:(_vm.background),callback:function ($$v) {_vm.background=$$v},expression:"background"}})],1),_c('div',{staticClass:"mt-4"},[_c('b-button',{attrs:{"disabled":_vm.isSaving,"variant":"primary"},on:{"click":_vm.print}},[_vm._v("Download")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }