<template>
  <svg
      v-if="chart && chart.chart"
      id="complete-chart-canvas"
      :width="getSvgProperties.width"
      :height="getSvgProperties.height"
      :viewBox="getSvgProperties.viewBox"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      :class="classes"
  >

    <connection-planet-tables
          v-if="isConnectionChartStyle"
          x="0"
          :y="viewMode === 'cross' ? -230 : 0"
          width="130"
          :chart="chart" :orientation="0"
    />

    <regular-planet-table
                  v-else-if="showLeftSide && !aiEnabled"
                  :x="0"
                  :y="viewMode === 'cross' ? -230 : 0"
                  :width="91"
                  :chart="chart"
                  :orientation="0"
                  :interactive="interactive"
    />

    <advanced-planet-table
        :x="-13"
        y="5"
        v-if="isPersonalChart(chart) && aiEnabled && showLeftSide"
        :interactive="interactive"
        :chart="chart"
        :orientation="0"
    />

    <personal-variable-arrow v-if="showLeftSide && isPersonalChart(chart)"
                             :view-mode="viewMode"
                             :is-ai="aiEnabled"
                             :total-width="getSvgProperties.width"
                             :planets="chart.chart.planets" :orientation="0" />

    <personal-variable-arrow v-if="showRightSide && isPersonalChart(chart)"
                             :is-ai="aiEnabled"
                             :view-mode="viewMode"
                             :total-width="getSvgProperties.width" :planets="chart.chart.planets" :orientation="1" />

    <bodygraph id="bodygraph" ref="bodyGraph" :chart="chart"
               :width="getBodyGraphProperties.width"
               :x="getBodyGraphProperties.x"
               :y="getBodyGraphProperties.y"
               dom-ref-id="bodygraph"
               :interactive="interactive"
               class="bodygraph-sizing-tool bodygraph-figure" />


    <connection-planet-tables
        v-if="isConnectionChartStyle && showRightSide"
        :x="getSvgProperties.width - 130"
        :y="viewMode === 'cross' ? -230 : 0"
        width="130"
        :chart="chart"
        :orientation="1"
    />

    <regular-planet-table
        v-else-if="showRightSide && (!aiEnabled)"
        :x="getSvgProperties.width - 91"
        :y="viewMode === 'cross' ? -230 : 0"
        :width="91"
        :chart="chart"
        :orientation="1"
        :interactive="interactive"
    />

    <advanced-planet-table
        v-if="isPersonalChart(chart) && aiEnabled && showRightSide"
        :x="getSvgProperties.width - 205"
        y="5"
        :interactive="interactive"
        :chart="chart"
        :orientation="1"
    />

    <!-- We only show this on the right side and only on `person transit charts` -->
    <!-- For plain transit charts or personal charts, we render the regular planet table -->
    <!-- The only reason for this is that we wanna maintain the proportions.   -->
    <transit-planet-table v-if="isPersonTransitChart(chart) && isFullView"
                          :x="getSvgProperties.width - 130"
                          :y="viewMode === 'cross' ? -230 : 0"
                          :width="130"
                          :chart="chart"
                          :orientation="1"
                          :interactive="interactive" />

  </svg>
</template>

<script>
import Bodygraph from "@/components/charts/bodygraph";
import ChartUtil from "@/mixins/ChartUtil";
import {mapState} from "vuex";
import ConnectionPlanetTables from "@/components/charts/planets/ConnectionPlanetTables.vue";
import TransitPlanetTable from "@/components/charts/planets/TransitPlanetTable.vue";
import RegularPlanetTable from "@/components/charts/planets/RegularPlanetTable.vue";
import PersonalVariableArrow from "@/components/charts/planets/partials/PersonalVariableArrow.vue";
import AdvancedPlanetTable from "@/components/charts/planets/AdvancedPlanetTable.vue";
import ScreenSizeMixin from "@/mixins/ScreenSizeMixin";
export default {
  mixins: [ChartUtil, ScreenSizeMixin],
  props: {
    chart: Object,
    interactive: Boolean,
    viewMode: String,
    hidePlanetTables: Boolean
  },

  components: {
    AdvancedPlanetTable,
    PersonalVariableArrow, RegularPlanetTable, TransitPlanetTable, ConnectionPlanetTables, Bodygraph },

  computed: {
    ...mapState({
      viewModes: state => state.ui.viewModes,
      aiEnabled: state => state.ui.singleChart.aiEnabled,
    }),

    isSinglePlainPlanetTable(){
      return !this.isFullView && !this.isComposite || this.isTransitOnlyChart(this.chart);
    },

    getSvgProperties() {
      if (this.isConnectionChartStyle) {
        return {
          width: 670,
          height: 597,
          viewBox: `0 0 670 597`,
        }
      }

      if (this.isSinglePlainPlanetTable) {
        return {
          width: this.isMobile ? 525 : 635,
          height: 597,
          viewBox: `0 0 ${this.isMobile ? 525 : 635} 597`,
        }
      }

      return {
        width: 670,
        height: 597,
        viewBox: `0 0 670 597`,
      }
    },

    getBodyGraphProperties() {
      if (this.isConnectionChartStyle) {
        return {
          width: this.isPersonTransitChart(this.chart) ? 660 : 630,
          x: this.isPersonTransitChart(this.chart) ? 25 : 20,
          y: 0
        }
      }
      if (this.isSinglePlainPlanetTable || this.isTransitOnlyChart(this.chart)) {
        return {
          width: 700,
          x: this.showRightSide ? (this.isMobile ? -130 : -75) : (this.isMobile ? -45 : 25),
          y: 10
        }
      }

      return {
        width: 650,
        x: 10,
        y: 10
      }
    },

    isFullView(){
      return this.viewMode === this.viewModes.normal || this.viewMode === this.viewModes.cross;
    },

    showLeftSide(){
      if (this.hidePlanetTables) return false;
      return ((this.isFullView || this.viewMode === this.viewModes.design)
          && !this.isTransitOnlyChart(this.chart)) || (this.isComposite && !this.isPersonTransitChart(this.chart))
    },

    // TODO: Add special case when viewing "transit-only" chart.
    showRightSide(){
      if (this.hidePlanetTables) return false;
      return (this.isFullView || this.viewMode === this.viewModes.personality)
          || (this.isComposite);
    },

    isComposite(){
      return (this.isConnectionChart(this.chart) || this.isCycleChart(this.chart) || this.isPersonTransitChart(this.chart))
    },


    isConnectionChartStyle() {
      return this.isFullView && this.isComposite
    },

    showLeftConnectionPlanetTable(){
      return false
    },

    showRightConnectionPlanetTable() {
      return false
    },

    showRightTransitPlanetTable() {
      return this.isPersonTransitChart(this.chart);
    },

    classes(){
      const classes = [];
      if(this.showLeftSide && this.showRightSide) classes.push('show-both-sides');
      else if(this.showLeftSide) classes.push('show-left-side');
      else if(this.showRightSide) classes.push('show-right-side');

      if (this.isConnectionChartStyle) classes.push('connection-chart-style');
      else classes.push('normal-chart-style');

      // Now let's add classes for other things.
      if(this.showLeftConnectionPlanetTable) classes.push('show-left-connection-planet-table');
      if(this.showRightConnectionPlanetTable) classes.push('show-right-connection-planet-table');
      if(this.showRightTransitPlanetTable) classes.push('show-right-transit-planet-table');
      return classes.join(' ');
    }
  },
}
</script>

<style lang="scss">
#complete-chart-canvas {
  width:100%;
  height:auto;

  .planet-table-icon-renderer{
    pointer-events: none;
    font-family: 'MyBodyGraph'!important;
  }
}
</style>