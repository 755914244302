export default {
  0: 'Juxtaposition Cross of Alertness (44/24 | 7/13)',
  1: 'Juxtaposition Cross of Ambition (54/53 | 32/42)',
  2: 'Juxtaposition Cross of Articulation (12/11 | 25/46)',
  3: 'Juxtaposition Cross of Assimilation (23/43 | 30/29)',
  4: 'Juxtaposition Cross of Bargains (37/40 | 5/35)',
  5: 'Juxtaposition Cross of Beginnings (53/54 | 42/32)',
  6: 'Juxtaposition Cross of Behavior (10/15 | 18/17)',
  7: 'Juxtaposition Cross of Caring (27/28 | 19/33)',
  8: 'Juxtaposition Cross of Commitment (29/30 | 20/34)',
  9: 'Juxtaposition Cross of Completion (42/32 | 60/56)',
  10: 'Juxtaposition Cross of Conflict (6/36 | 15/10)',
  11: 'Juxtaposition Cross of Confusion (64/63 | 45/26)',
  12: 'Juxtaposition Cross of Conservation (32/42 | 56/60)',
  13: 'Juxtaposition Cross of Contribution (8/14 | 55/59)',
  14: 'Juxtaposition Cross of Control (21/48 | 54/53)',
  15: 'Juxtaposition Cross of Correction (18/17 | 39/38)',
  16: 'Juxtaposition Cross of Crisis (36/6 | 10/15)',
  17: 'Juxtaposition Cross of Denial (40/37 | 35/5)',
  18: 'Juxtaposition Cross of Depth (48/21 | 53/54)',
  19: 'Juxtaposition Cross of Detail (62/61 | 3/50)',
  20: 'Juxtaposition Cross of Doubts (63/64 | 26/45)',
  21: 'Juxtaposition Cross of The Driver (2/1 | 49/4)',
  22: 'Juxtaposition Cross of Empowering (14/8 | 59/55)',
  23: 'Juxtaposition Cross of Experience (35/5 | 22/47)',
  24: 'Juxtaposition Cross of Experimentation (16/9 | 63/64)',
  25: 'Juxtaposition Cross of Extremes (15/10 | 17/18)',
  26: 'Juxtaposition Cross of Fantasy (41/31 | 44/24)',
  27: 'Juxtaposition Cross of Fates (30/29 | 34/20)',
  28: 'Juxtaposition Cross of Focus (9/16 | 64/63)',
  29: 'Juxtaposition Cross of Formulization (4/49 | 8/14)',
  30: 'Juxtaposition Cross of Grace (22/47 | 11/12)',
  31: 'Juxtaposition Cross of Habits (5/35 | 47/22)',
  32: 'Juxtaposition Cross of Ideas (11/12 | 46/25)',
  33: 'Juxtaposition Cross of Influence (31/41 | 24/44)',
  34: 'Juxtaposition Cross of Innocence (25/46 | 58/52)',
  35: 'Juxtaposition Cross of Insight (43/23 | 29/30)',
  36: 'Juxtaposition Cross of Interaction (7/13 | 23/43)',
  37: 'Juxtaposition Cross of Intuition (57/51 | 62/61)',
  38: 'Juxtaposition Cross of Limitation (60/56 | 28/27)',
  39: 'Juxtaposition Cross of Listening (13/7 | 43/23)',
  40: 'Juxtaposition Cross of Moods (55/59 | 9/16)',
  41: 'Juxtaposition Cross of Mutation (3/50 | 41/31)',
  42: 'Juxtaposition Cross of Need (19/33 | 1/2)',
  43: 'Juxtaposition Cross of The Now (20/34 | 37/40)',
  44: 'Juxtaposition Cross of Opinions (17/18 | 38/39)',
  45: 'Juxtaposition Cross of Opposition (38/39 | 57/51)',
  46: 'Juxtaposition Cross of Oppression (47/22 | 12/11)',
  47: 'Juxtaposition Cross of Possession (45/26 | 36/6)',
  48: 'Juxtaposition Cross of Power (34/20 | 40/37)',
  49: 'Juxtaposition Cross of Principles (49/4 | 14/8)',
  50: 'Juxtaposition Cross of Provocation (39/38 | 51/57)',
  51: 'Juxtaposition Cross of Rationalization (24/44 | 13/7)',
  52: 'Juxtaposition Cross of Retreat (33/19 | 2/1)',
  53: 'Juxtaposition Cross of Risks (28/27 | 33/19)',
  54: 'Juxtaposition Cross of Self-expression (1/2 | 4/49)',
  55: 'Juxtaposition Cross of Serendipity  (46/25 | 52/58)',
  56: 'Juxtaposition Cross of Shock (51/57 | 61/62)',
  57: 'Juxtaposition Cross of Stillness (52/58 | 21/48)',
  58: 'Juxtaposition Cross of Stimulation (56/60 | 27/28)',
  59: 'Juxtaposition Cross of Strategy (59/55 | 16/9)',
  60: 'Juxtaposition Cross of Thinking (61/62 | 50/3)',
  61: 'Juxtaposition Cross of The Trickster (26/45 | 6/36)',
  62: 'Juxtaposition Cross of Values (50/3 | 31/41)',
  63: 'Juxtaposition Cross of Vitality (58/52 | 48/21)',
  64: 'Left Angle Cross of Alignment (27/28 | 19/33)',
  65: 'Left Angle Cross of Alignment (28/27 | 33/19)',
  66: 'Left Angle Cross of The Alpha (31/41 | 24/44)',
  67: 'Left Angle Cross of The Alpha (41/31 | 44/24)',
  68: 'Left Angle Cross of The Clarion (51/57 | 61/62)',
  69: 'Left Angle Cross of The Clarion (57/51 | 62/61)',
  70: 'Left Angle Cross of Confrontation (45/26 | 36/6)',
  71: 'Left Angle Cross of Confrontation (26/45 | 6/36)',
  72: 'Left Angle Cross of Cycles (53/54 | 42/32)',
  73: 'Left Angle Cross of Cycles (54/53 | 32/42)',
  74: 'Left Angle Cross of Dedication (23/43 | 30/29)',
  75: 'Left Angle Cross of Dedication (43/23 | 29/30)',
  76: 'Left Angle Cross of Defiance (2/1 | 49/4)',
  77: 'Left Angle Cross of Defiance (1/2 | 4/49)',
  78: 'Left Angle Cross of Demands (52/58 | 21/48)',
  79: 'Left Angle Cross of Demands (58/52 | 48/21)',
  80: 'Left Angle Cross of Distraction (56/60 | 27/28)',
  81: 'Left Angle Cross of Distraction (60/56 | 28/27)',
  82: 'Left Angle Cross of Dominion (63/64 | 26/45)',
  83: 'Left Angle Cross of Dominion (64/63 | 45/26)',
  84: 'Left Angle Cross of Duality (20/34 | 37/40)',
  85: 'Left Angle Cross of Duality (34/20 | 40/37)',
  86: 'Left Angle Cross of Education (12/11 | 25/46)',
  87: 'Left Angle Cross of Education (11/12 | 46/25)',
  88: 'Left Angle Cross of Endeavor (21/48 | 54/53)',
  89: 'Left Angle Cross of Endeavor (48/21 | 53/54)',
  90: 'Left Angle Cross of Healing (25/46 | 58/52)',
  91: 'Left Angle Cross of Healing (46/25 | 52/58)',
  92: 'Left Angle Cross of Identification (16/9 | 63/64)',
  93: 'Left Angle Cross of Identification (9/16 | 64/63)',
  94: 'Left Angle Cross of Incarnation (24/44 | 13/7)',
  95: 'Left Angle Cross of Incarnation (44/24 | 7/13)',
  96: 'Left Angle Cross of Individualism (39/38 | 51/57)',
  97: 'Left Angle Cross of Individualism (38/39 | 57/51)',
  98: 'Left Angle Cross of Industry (30/29 | 34/20)',
  99: 'Left Angle Cross of Industry (29/30 | 20/34)',
  100: 'Left Angle Cross of Informing (22/47 | 11/12)',
  101: 'Left Angle Cross of Informing (47/22 | 12/11)',
  102: 'Left Angle Cross of Limitation (42/32 | 60/56)',
  103: 'Left Angle Cross of Limitation (32/42 | 56/60)',
  104: 'Left Angle Cross of Masks (13/7 | 43/23)',
  105: 'Left Angle Cross of Masks (7/13 | 23/43)',
  106: 'Left Angle Cross of Migration (37/40 | 5/35)',
  107: 'Left Angle Cross of Migration (40/37 | 35/5)',
  108: 'Left Angle Cross of Obscuration (62/61 | 3/50)',
  109: 'Left Angle Cross of Obscuration (61/62 | 50/3)',
  110: 'Left Angle Cross of The Plane (36/6 | 10/15)',
  111: 'Left Angle Cross of The Plane (6/36 | 15/10)',
  112: 'Left Angle Cross of Prevention  (15/10 | 17/18)',
  113: 'Left Angle Cross of Prevention (10/15 | 18/17)',
  114: 'Left Angle Cross of Refinement  (33/19 | 2/1)',
  115: 'Left Angle Cross of Refinement  (19/33 | 1/2)',
  116: 'Left Angle Cross of Revolution (49/4 | 14/8)',
  117: 'Left Angle Cross of Revolution (4/49 | 8/14)',
  118: 'Left Angle Cross of Separation (35/5 | 22/47)',
  119: 'Left Angle Cross of Separation (5/35 | 47/22)',
  120: 'Left Angle Cross of Spirit  (55/59 | 9/16)',
  121: 'Left Angle Cross of Spirit  (59/55 | 16/9)',
  122: 'Left Angle Cross of Uncertainty  (8/14 | 55/59)',
  123: 'Left Angle Cross of Uncertainty  (14/8 | 59/55)',
  124: 'Left Angle Cross of Upheaval  (17/18 | 38/39)',
  125: 'Left Angle Cross of Upheaval  (18/17 | 39/38)',
  126: 'Left Angle Cross of Wishes (3/50 | 41/31)',
  127: 'Left Angle Cross of Wishes (50/3 | 31/41)',
  128: 'Right Angle Cross of Consciousness (63/64 | 5/35)',
  129: 'Right Angle Cross of Consciousness (35/5 | 63/64)',
  130: 'Right Angle Cross of Consciousness (64/63 | 35/5)',
  131: 'Right Angle Cross of Consciousness (5/35 | 64/63)',
  132: 'Right Angle Cross of Contagion (30/29 | 14/8)',
  133: 'Right Angle Cross of Contagion (8/14 | 30/29)',
  134: 'Right Angle Cross of Contagion (29/30 | 8/14)',
  135: 'Right Angle Cross of Contagion (14/8 | 29/30)',
  136: 'Right Angle Cross of Eden (36/6 | 11/12)',
  137: 'Right Angle Cross of Eden (12/11 | 36/6)',
  138: 'Right Angle Cross of Eden (6/36 | 12/11)',
  139: 'Right Angle Cross of Eden (11/12 | 6/36)',
  140: 'Right Angle Cross of Explanation (49/4 | 43/23)',
  141: 'Right Angle Cross of Explanation (23/43 | 49/4)',
  142: 'Right Angle Cross of Explanation (4/49 | 23/43)',
  143: 'Right Angle Cross of Explanation (43/23 | 4/49)',
  144: 'Right Angle Cross of The Four Ways (24/44 | 19/33)',
  145: 'Right Angle Cross of The Four Ways (33/19 | 24/44)',
  146: 'Right Angle Cross of The Four Ways (44/24 | 33/19)',
  147: 'Right Angle Cross of The Four Ways (19/33 | 44/24)',
  148: 'Right Angle Cross of Laws (3/50 | 60/56)',
  149: 'Right Angle Cross of Laws  (56/60 | 3/50)',
  150: 'Right Angle Cross of Laws (50/3 | 56/60)',
  151: 'Right Angle Cross of Laws (60/56 | 50/3)',
  152: 'Right Angle Cross of Maya (42/32 | 61/62)',
  153: 'Right Angle Cross of Maya (62/61 | 42/32)',
  154: 'Right Angle Cross of Maya (32/42 | 62/61)',
  155: 'Right Angle Cross of Maya (61/62 | 32/42)',
  156: 'Right Angle Cross of Penetration (51/57 | 54/53)',
  157: 'Right Angle Cross of Penetration (53/54 | 51/57)',
  158: 'Right Angle Cross of Penetration (57/51 | 53/54)',
  159: 'Right Angle Cross of Penetration (54/53 | 57/51)',
  160: 'Right Angle Cross of Planning (37/40 | 9/16)',
  161: 'Right Angle Cross of Planning (16/9 | 37/40)',
  162: 'Right Angle Cross of Planning (40/37 | 16/9)',
  163: 'Right Angle Cross of Planning (9/16 | 40/37)',
  164: 'Right Angle Cross of Rulership (22/47 | 26/45)',
  165: 'Right Angle Cross of Rulership (45/26 | 22/47)',
  166: 'Right Angle Cross of Rulership (47/22 | 45/26)',
  167: 'Right Angle Cross of Rulership (26/45 | 47/22)',
  168: 'Right Angle Cross of Service (17/18 | 58/52)',
  169: 'Right Angle Cross of Service (52/58 | 17/18)',
  170: 'Right Angle Cross of Service (18/17 | 52/58)',
  171: 'Right Angle Cross of Service (58/52 | 18/17)',
  172: 'Right Angle Cross of The Sleeping Phoenix (55/59 | 34/20)',
  173: 'Right Angle Cross of The Sleeping Phoenix (20/34 | 55/59)',
  174: 'Right Angle Cross of The Sleeping Phoenix (59/55 | 20/34)',
  175: 'Right Angle Cross of The Sleeping Phoenix  (34/20 | 59/55)',
  176: 'Right Angle Cross of The Sphinx  (13/7 | 1/2)',
  177: 'Right Angle Cross of The Sphinx  (2/1 | 13/7)',
  178: 'Right Angle Cross of The Sphinx  (7/13 | 2/1)',
  179: 'Right Angle Cross of The Sphinx  (1/2 | 7/13)',
  180: 'Right Angle Cross of Tension (21/48 | 38/39)',
  181: 'Right Angle Cross of Tension (39/38 | 21/48)',
  182: 'Right Angle Cross of Tension (48/21 | 39/38)',
  183: 'Right Angle Cross of Tension (38/39 | 48/21)',
  184: 'Right Angle Cross of The Unexpected (27/28 | 41/31)',
  185: 'Right Angle Cross of The Unexpected  (31/41 | 27/28)',
  186: 'Right Angle Cross of The Unexpected (28/27 | 31/41)',
  187: 'Right Angle Cross of The Unexpected  (41/31 | 28/27)',
  188: 'Right Angle Cross of The Vessel of Love (25/46 | 10/15)',
  189: 'Right Angle Cross of The Vessel of Love(15/10 | 25/46)',
  190: 'Right Angle Cross of The Vessel of Love (46/25 | 15/10)',
  191: 'Right Angle Cross of The Vessel of Love (10/15 | 46/25)'
}
