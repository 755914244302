export default {
  gate: 'Gate',
  line: 'Line',
  lines: 'Lines',
  tone: 'Tone',
  color: 'Color',
  base: 'Base',
  channel: 'Channel',
  channels: 'Channels',
  center: 'Center',
  planet: 'Planet',
  circuit: 'Circuit'
}
