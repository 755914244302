<template>

  <div>
    <div class="col-12">

      <mbg-chart-picker v-model="chart" :title="`${$t('pages.newChart.selectBirthChart')}`">
        <router-link v-if="chart && initialChartId === chart.id" :to="`/charts/${chart.id}?edit_chart=true`" class="pt-2 mx-auto" variant="black">Edit Chart</router-link>
      </mbg-chart-picker>



      <div class="mt-4">
        <b-button variant="primary" :disabled="initialChartId === chart.id || !chart || !chart.id" @click="updateMainChart">{{$t('general.saveChanges')}}</b-button>
      </div>
      </div>
  </div>

</template>

<script>

import MbgChartPicker from "../../components/elements/MbgChartPicker.vue";
import {mapState} from "vuex";
import docTypes from "../../helpers/docTypes";

export default{
  components: {MbgChartPicker},

  computed:{

    ...mapState({
      user: state => state.account.user,
      mainChart: state => state.charts.personalChart
    })
  },

  data(){
    return {
      chart: null,
      initialChartId: null
    }
  },

  watch:{
    mainChart(){
      this.setMainChart();
    }
  },
  mounted(){
    this.setMainChart();
  },
  methods:{
    setMainChart(){
      this.chart = {
        id: this.mainChart.id,
        name: this.mainChart.meta.name,
        docType: docTypes.RAVE_CHART,
        type: this.mainChart.chart.type,
        profile: this.mainChart.chart.profile
      }
      this.initialChartId = this.mainChart.id
    },

    updateMainChart(){
      if(!this.chart.id) return;
      this.axios.post('/users/set-chart', {
        chart_id: this.chart.id
      }).then(() =>{
        this.$notify({
          type: 'success',
          title:this.$t('header.accountChart.chartPairedTitle'),
          text: this.$t('header.accountChart.chartPairedText'),
          position: 'bottom center'}
        );
        this.$store.dispatch('account/setChartId', this.chart.id);
      }).catch(()=>{
        this.$notify({type: 'error', title:'Something went wrong', text: 'Please fill all the fields with their specifications.', position: 'bottom center'});

      });
    },
  }

}
</script>