export default `<svg viewBox="0 0 511.713 432.621" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
 <path d="M427.321,282.259c-5.824,0-10.551,4.717-10.551,10.552c0,37.817-30.77,68.586-68.586,68.586l-1.141-0.011
    c-20.691-0.337-38.238-11.966-46.922-31.106c-9.086-20.006-5.973-42.798,8.135-59.479c15.658-18.519,29.449-41.247,40.973-67.542
    c14.455-32.963,12.061-71.118-6.406-102.066c-17.609-29.503-46.965-47.979-80.541-50.712c-2.996-0.232-6.014-0.358-9.064-0.358
    c-63.162,0-110.792,45.361-110.792,105.517v64.103c-38.651,5.18-68.586,38.292-68.586,78.346
    c0,43.643,35.496,79.139,79.138,79.139c43.642,0,79.138-35.496,79.138-79.139c0-40.054-29.935-73.166-68.586-78.346v-64.103
    c0-48.126,38.556-84.413,89.689-84.413c2.48,0,4.949,0.105,7.365,0.295c26.676,2.163,50.047,16.925,64.113,40.487
    c14.982,25.092,16.926,56.04,5.201,82.778c-10.709,24.427-23.414,45.425-37.764,62.392c-19.426,22.972-23.73,54.32-11.238,81.829
    c11.967,26.358,37.186,43.02,65.727,43.483l1.563,0.011c49.455,0,89.689-40.233,89.689-89.689
    C437.874,286.976,433.157,282.259,427.321,282.259z M211.012,298.086c0,31.993-26.031,58.035-58.034,58.035
    s-58.035-26.042-58.035-58.035c0-31.992,26.031-58.034,58.035-58.034S211.012,266.094,211.012,298.086z"/>
</svg>`