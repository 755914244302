export default `<svg viewBox="0 0 612 612" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <path d="M306,0C136.994,0,0,137.003,0,306c0,169.007,136.994,306,306,306c169.007,0,306-136.993,306-306
    C612,137.003,475.007,0,306,0z M504.348,504.348c-25.774,25.756-55.769,46.001-89.163,60.121
    c-34.57,14.623-71.308,22.039-109.185,22.039s-74.614-7.415-109.185-22.039c-33.395-14.12-63.389-34.365-89.163-60.121
    c-25.774-25.774-46-55.769-60.121-89.181C32.908,380.614,25.494,343.877,25.494,306s7.415-74.614,22.039-109.175
    c14.12-33.403,34.346-63.398,60.121-89.173c25.774-25.765,55.769-45.992,89.163-60.121C231.386,32.908,268.124,25.494,306,25.494
    s74.614,7.415,109.185,22.039c33.395,14.128,63.389,34.356,89.163,60.121c25.774,25.774,46,55.769,60.121,89.173
    c14.623,34.561,22.039,71.298,22.039,109.175s-7.415,74.614-22.039,109.166C550.348,448.578,530.121,478.573,504.348,504.348z"/>
  <path d="M306,216.819c-49.25,0-89.181,39.921-89.181,89.181c0,49.25,39.931,89.181,89.181,89.181
    c49.25,0,89.181-39.931,89.181-89.181C395.181,256.74,355.25,216.819,306,216.819z M306,369.688
    c-35.112,0-63.688-28.575-63.688-63.688s28.575-63.688,63.688-63.688s63.688,28.575,63.688,63.688S341.112,369.688,306,369.688z"/>
</svg>`