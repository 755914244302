<template>

  <div @click="$emit('selected')"
       class="checkbox-checkout gap-10 align-items-center py-3 px-3 d-flex mb-2 casual-border-radius "
       :class="isSelected ? 'selected border border-primary': ''"
  >
    <div class="circle-check" style="width:22px;height:22px"
         :class="isSelected ? 'bg-primary ': 'border-primary border'">
      <span v-if="isSelected" class="icon-check text-white"/>
    </div>
    <div class="d-flex align-items-center">
      <slot v-if="!text"></slot>
      <span v-else>{{text }}</span>
    </div>
  </div>

</template>

<style lang="scss">
.theme--dark{
  .circle-check{
    background: $bg-contrast-dark;
  }
}
.theme--light{
  .circle-check{
    background: #fff;
  }
}
.checkbox-checkout{
  cursor:pointer;
  &.selected{
    border: $primary;
  }

}
.circle-check{
  display:flex;
  align-items:center;
  justify-content: center;
  border-radius:100%;
}
</style>
<script>


export default{

  props:{
    isSelected: Boolean,
    item: Object,
    text: String,
    showLogo: Boolean
  }
}
</script>