<template>
  <div v-if="show" @click="select"
       @mouseover="highlightElement({type: types.center, id: center.name.toLowerCase()})"
       @mouseleave="clearAllHighlights" class="highlight-trigger">
    <b-card class="my-2 cursor-pointer border-primary">
      <div class="d-flex align-items-center">

        <div class="mr-3">
          <span :class="`icon-center-${center.icon}`"></span>
        </div>
        <div>
          <label class="font-weight-light">{{ $t('hd.constants.centerDefinitionStates.1') }}</label>
          <h6>
            {{centerContent.title}}
          </h6>
        </div>
        <div class="ml-auto">
          <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>
    </b-card>
  </div>
  <div v-else-if="selected">
    <div class="d-flex gap-5 align-items-center mb-4">
      <b-button size="xs" variant="primary" class="m-0 mr-3 " @click="$emit('deselect')">
        <span class="icon-arrow-left"></span>
      </b-button>
      <p class="mb-0 font-500">
        {{centerContent.title}}
      </p>
    </div>

    <dynamic-zone-renderer v-if="data"
                           :components="data.components"
                           :add-paddings="false" />

    <work-success-line v-for="gate in centerDetails.gates"
                       :key="gate.gateId"
                       :gate="gate"
                       :chart="chart"
    />

    <dynamic-zone-renderer v-if="noContentData && centerDetails.gates.length === 0"
                           :components="noContentData.components"
                           :add-paddings="false" />

  </div>
</template>

<script>
import WorkSuccessLine from "@/components/charts/analysis/work_success/partials/WorkSuccessLine.vue";
import HighlightingMixin from "@/mixins/HighlightingMixin";
import centers from "@/helpers/rave/constants/centers";
import WorkSuccessMixin from "@/mixins/WorkSuccessMixin";
import DynamicZoneRenderer from "@/components/renderer/DynamicZoneRenderer.vue";
import AnalysisQueryMixin from "@/mixins/AnalysisQueryMixin";

export default {
  mixins: [HighlightingMixin, WorkSuccessMixin, AnalysisQueryMixin],
  components: {DynamicZoneRenderer, WorkSuccessLine},
  props: {
    chart: Object,
    centerContent: Object,
    centerDetails: Object,
    show: Boolean,
    selected: Boolean
  },
  data(){
    return {
      data: null,
      noContentData: null,
    }
  },
  computed: {
    center(){
      return centers.centersList.find(x => x.id ===  this.centerDetails.centerId)
    }
  },
  created(){
    this.getCenter(this.chart, this.centerDetails.centerId);
    if (this.centerDetails.gates.length === 0) {
      this.getGeneralContent(this.chart, 'work-success.no-money-lines', 'noContentData');
    }

  },
  methods: {
    select(){
      this.$emit('select');
      this.clearAllHighlights();
    }
  }
}
</script>