import header from "./structure/header";
import general from "./general";
import errors from "./structure/errors";
import success from "./structure/success";
import pages from "./pages/index";
import library from "./library/index";
import countries from "./countries";
import properties from "./properties";
import hd from "./hd/index"
import documentTypes from "./documentTypes";
import charts from "./charts";
import owl from "./owl";
import billing from "./billing";
import shared from "./shared";
import tags from "./tags";
import dates from "./dates";
import gifts from "@/lang/en/gifts";
import imports from "@/lang/en/imports";
export default{
    general,
    header,
    pages,
    errors,
    success,
    library,
    countries,
    properties,
    hd,
    documentTypes,
    charts,
    owl,
    billing,
    public: shared,
    tags,
    dates,
    gifts,
    imports
}