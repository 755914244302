<template>
  <div>
    <div v-for="gate in gates" :key="gate.gate" @click="selectGateFromList(gate)">
      <b-card class="my-2 cursor-pointer border-primary bg-light mb-2 shadow-none hover-brightness">

        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center gap-10">
            <div v-html="getIchingIcon(gate.gate_id)"/>

            <div>
              <h6>{{gate.gate_id}}. {{gate.name }}</h6>
              <label class="font-weight-light cursor-pointer"> {{gate.keynote }}</label>
            </div>

          </div>
          <div class="ml-auto">
            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13L7 7L0.999999 1" :stroke="darkMode ? '#fff' : '#191c23'" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </div>
        </div>

      </b-card>

    </div>
  </div>


</template>

<script>
import {getIchingIcon} from "../../../../utils/iching";
import {localStorageKeys} from "../../../../utils/localStorage";

export default{
  methods: {
    getIchingIcon,
    selectGateFromList(gate){
      const scrolledPos = document.getElementById('dictionary-side-panel-content').scrollTop;
      sessionStorage.setItem(localStorageKeys.ICHING_SCROLL_POSITION, scrolledPos.toString());
      this.$emit('gateClicked', gate)
    }
  },

  props:{
    gates: Array
  },
}
</script>