<template>

  <b-card class="checkout-product mb-2 py-2">

    <h5 class="d-flex align-items-center gap-5 mb-3">
      <product-icon :product-key="product.key"/>
      {{product.name}}</h5>

    <div class="mbg-text-muted product-short-desc" v-html="$t(`billing.products.${isSubscription ? 'subscriptions' : 'unlocks'}.${product.key}`)"/>

    <div class="d-flex align-items-center">
      <div class="checkbox-filters">
        <b-button variant="primary"
                  size="xs"
                  :value="false"
                  class="mx-0 mr-3"
                  :disabled="disabled"
                  @click="$emit('addNewProduct', product)">{{$t('billing.checkout.addToOrder')}}</b-button>

        <b-button variant="link"
                  size="xs"
                  :value="false"
                  class="mx-0"
                  @click="showSidebar(product)">{{$t('billing.checkout.learnMore')}}</b-button>
      </div>
      <p class="ml-auto mb-0">${{product.price.toFixed(2)}}

        <span v-if="isSubscription" class=""> {{$t('billing.intervals.'+product.interval)}}</span>
      </p>
    </div>
  </b-card>
</template>

<script>
import ProductIcon from "../ProductIcon.vue";

export default{
  components: {ProductIcon},

  props:{
    product: Object,
    disabled: Boolean
  },
  computed:{
    isSubscription(){
      return Object.hasOwn(this.product, 'interval');
    }
  },
  methods:{
    showSidebar(product){
      this.$store.commit('ui/SET_CHECKOUT_SIDEBAR_ACTIVE_TAB', product.key);
      this.$store.commit('ui/SET_SHOW_CHECKOUT_SIDEBAR', true);
    }
  }
}
</script>
<style lang="scss">
.product-short-desc{
  p{
    font-size:12px;
  }
}
</style>