<template>
  <g v-if="!isAi">
    <!-- Top one -->
    <text v-if="getPlanets.top === 'left'"
          :x="orientation === 0 ? 125 : (totalWidth - 125)"
          y="93"
          :text-anchor="orientation === 0 ? 'start' : 'end'"
          :class="orientation === 1 ? 'color-personality' : 'color-design'"
          font-size="12" class="variable-arrow-renderer" font-family="MyBodyGraph" fill="currentColor">
      &#xe997;
    </text>
    <text v-else-if="getPlanets.top === 'right' "
          :x="orientation === 0 ? 125 : (totalWidth - 125)"
          :text-anchor="orientation === 0 ? 'start' : 'end'"
          y="93"
          :class="orientation === 1 ? 'color-personality' : 'color-design'"
          font-size="12" class="variable-arrow-renderer" font-family="MyBodyGraph" fill="currentColor">
      &#xe998;
    </text>


    <!--  Bottom one  -->
    <text v-if="getPlanets.bottom === 'left' && viewMode !== 'cross'"
          :x="orientation === 0 ? 125 : (totalWidth - 125)"
          y="175"
          :text-anchor="orientation === 0 ? 'start' : 'end'"
          :class="orientation === 1 ? 'color-personality' : 'color-design'"
          font-size="12" class="variable-arrow-renderer" font-family="MyBodyGraph" fill="currentColor">
      &#xe997;
    </text>
    <text v-else-if="getPlanets.bottom === 'right' && viewMode !== 'cross'"
          :x="orientation === 0 ? 125 : (totalWidth - 125)"
          y="175"
          :text-anchor="orientation === 0 ? 'start' : 'end'"
          :class="orientation === 1 ? 'color-personality' : 'color-design'"
          font-size="12" class="variable-arrow-renderer" font-family="MyBodyGraph" fill="currentColor">
      &#xe998;
    </text>
  </g>
  <g v-else>
    <svg
        :x="orientation === 0 ? 195 : (totalWidth - 220)"
        y="36"
        width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect class="advanced-arrow-bg" width="28" height="30" rx="5"/>
      <path v-if="getPlanets.top === 'left'"
            :class="`advanced-arrow-fill ${orientation === 0 ? 'design' : 'personality'}`"
            d="M21.4428 11.7493H13.9142V9.04618C13.9152 8.94335 13.8861 8.84237 13.8302 8.75507C13.7743 8.66777 13.6939 8.59777 13.5986 8.55328C13.5032 8.50878 13.3968 8.49162 13.2918 8.50382C13.1868 8.51602 13.0876 8.55706 13.0057 8.62215L5.20555 14.576C5.14126 14.6275 5.0895 14.6923 5.05396 14.7656C5.01843 14.8389 5 14.919 5 15C5 15.081 5.01843 15.1611 5.05396 15.2344C5.0895 15.3077 5.14126 15.3725 5.20555 15.424L13.0057 21.3779C13.0876 21.4429 13.1868 21.484 13.2918 21.4962C13.3968 21.5084 13.5032 21.4912 13.5986 21.4467C13.6939 21.4022 13.7743 21.3322 13.8302 21.2449C13.8861 21.1576 13.9152 21.0567 13.9142 20.9538V18.2507H21.4428C21.5163 18.2504 21.589 18.2361 21.6567 18.2085C21.7245 18.1809 21.786 18.1406 21.8377 18.0898C21.8895 18.0391 21.9304 17.979 21.9583 17.9129C21.9861 17.8468 22.0003 17.776 22 17.7046V12.2954C22.0003 12.224 21.9861 12.1532 21.9583 12.0871C21.9304 12.021 21.8895 11.9609 21.8377 11.9102C21.786 11.8594 21.7245 11.8191 21.6567 11.7915C21.589 11.7639 21.5163 11.7496 21.4428 11.7493Z"
            fill="#BE1211"/>
      <path v-else
            :class="`advanced-arrow-fill ${orientation === 0 ? 'design' : 'personality'}`"
            d="M6.55716 18.2507L14.0858 18.2507L14.0858 20.9538C14.0848 21.0566 14.1139 21.1576 14.1698 21.2449C14.2257 21.3322 14.3061 21.4022 14.4014 21.4467C14.4968 21.4912 14.6032 21.5084 14.7082 21.4962C14.8132 21.484 14.9124 21.4429 14.9943 21.3779L22.7945 15.424C22.8587 15.3725 22.9105 15.3077 22.946 15.2344C22.9816 15.1611 23 15.081 23 15C23 14.919 22.9816 14.8389 22.946 14.7656C22.9105 14.6923 22.8587 14.6275 22.7945 14.576L14.9943 8.62215C14.9124 8.55706 14.8132 8.51602 14.7082 8.50382C14.6032 8.49162 14.4968 8.50878 14.4014 8.55328C14.3061 8.59777 14.2257 8.66777 14.1698 8.75507C14.1139 8.84237 14.0848 8.94335 14.0858 9.04618L14.0858 11.7493L6.55716 11.7493C6.4837 11.7496 6.41102 11.7639 6.34326 11.7915C6.27551 11.8191 6.214 11.8594 6.16227 11.9102C6.11053 11.9609 6.06958 12.021 6.04173 12.0871C6.01389 12.1532 5.99971 12.224 6 12.2954L6 17.7046C5.99971 17.776 6.01389 17.8468 6.04173 17.9129C6.06957 17.979 6.11053 18.0391 6.16227 18.0898C6.214 18.1405 6.27551 18.1809 6.34326 18.2085C6.41102 18.2361 6.4837 18.2504 6.55716 18.2507Z"
            fill="white"/>
    </svg>

    <svg
        v-if="viewMode !== 'cross'"
        :x="orientation === 0 ? 195 : (totalWidth - 220)"
        y="83"
        width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect class="advanced-arrow-bg"  width="28" height="30" rx="5"/>
      <path v-if="getPlanets.bottom === 'left'"
            :class="`advanced-arrow-fill ${orientation === 0 ? 'design' : 'personality'}`"
            d="M21.4428 11.7493H13.9142V9.04618C13.9152 8.94335 13.8861 8.84237 13.8302 8.75507C13.7743 8.66777 13.6939 8.59777 13.5986 8.55328C13.5032 8.50878 13.3968 8.49162 13.2918 8.50382C13.1868 8.51602 13.0876 8.55706 13.0057 8.62215L5.20555 14.576C5.14126 14.6275 5.0895 14.6923 5.05396 14.7656C5.01843 14.8389 5 14.919 5 15C5 15.081 5.01843 15.1611 5.05396 15.2344C5.0895 15.3077 5.14126 15.3725 5.20555 15.424L13.0057 21.3779C13.0876 21.4429 13.1868 21.484 13.2918 21.4962C13.3968 21.5084 13.5032 21.4912 13.5986 21.4467C13.6939 21.4022 13.7743 21.3322 13.8302 21.2449C13.8861 21.1576 13.9152 21.0567 13.9142 20.9538V18.2507H21.4428C21.5163 18.2504 21.589 18.2361 21.6567 18.2085C21.7245 18.1809 21.786 18.1406 21.8377 18.0898C21.8895 18.0391 21.9304 17.979 21.9583 17.9129C21.9861 17.8468 22.0003 17.776 22 17.7046V12.2954C22.0003 12.224 21.9861 12.1532 21.9583 12.0871C21.9304 12.021 21.8895 11.9609 21.8377 11.9102C21.786 11.8594 21.7245 11.8191 21.6567 11.7915C21.589 11.7639 21.5163 11.7496 21.4428 11.7493Z"
          fill="#BE1211"/>
      <path v-if="getPlanets.bottom === 'right'"
            :class="`advanced-arrow-fill ${orientation === 0 ? 'design' : 'personality'}`"
            d="M6.55716 18.2507L14.0858 18.2507L14.0858 20.9538C14.0848 21.0566 14.1139 21.1576 14.1698 21.2449C14.2257 21.3322 14.3061 21.4022 14.4014 21.4467C14.4968 21.4912 14.6032 21.5084 14.7082 21.4962C14.8132 21.484 14.9124 21.4429 14.9943 21.3779L22.7945 15.424C22.8587 15.3725 22.9105 15.3077 22.946 15.2344C22.9816 15.1611 23 15.081 23 15C23 14.919 22.9816 14.8389 22.946 14.7656C22.9105 14.6923 22.8587 14.6275 22.7945 14.576L14.9943 8.62215C14.9124 8.55706 14.8132 8.51602 14.7082 8.50382C14.6032 8.49162 14.4968 8.50878 14.4014 8.55328C14.3061 8.59777 14.2257 8.66777 14.1698 8.75507C14.1139 8.84237 14.0848 8.94335 14.0858 9.04618L14.0858 11.7493L6.55716 11.7493C6.4837 11.7496 6.41102 11.7639 6.34326 11.7915C6.27551 11.8191 6.214 11.8594 6.16227 11.9102C6.11053 11.9609 6.06958 12.021 6.04173 12.0871C6.01389 12.1532 5.99971 12.224 6 12.2954L6 17.7046C5.99971 17.776 6.01389 17.8468 6.04173 17.9129C6.06957 17.979 6.11053 18.0391 6.16227 18.0898C6.214 18.1405 6.27551 18.1809 6.34326 18.2085C6.41102 18.2361 6.4837 18.2504 6.55716 18.2507Z"
             fill="white"/>

    </svg>

  </g>
</template>

<script>
export default {
  props:{
    planets: Array,
    orientation: Number,
    totalWidth: Number,
    viewMode: String,
    isAi: Boolean
  },

  computed: {
    getPlanets() {
      const planets = this.planets.filter(x => x.activation === this.orientation && [1,4].includes(x.id));
      let top, bottom;
      const topPlanet = planets.find(x => x.id === 1);
      const bottomPlanet = planets.find(x => x.id === 4);

      if (topPlanet) {
        top = topPlanet.tone < 4 ? 'left' : 'right';
      }

      if (bottomPlanet) {
        bottom = bottomPlanet.tone < 4 ? 'left' : 'right';
      }

      return {
        top,
        bottom,
      }
    }
  },
}
</script>
<style lang="scss">
.variable-arrow-renderer{
  pointer-events: none;
  font-family: 'MyBodyGraph'!important;
}
.advanced-arrow-bg{
  fill: white;
}
.advanced-arrow-fill {
  &.design {
    fill: #BE1211;
  }

  &.personality {
    fill: $personality-bg
  }
}

.theme--dark{
  .advanced-arrow-bg{
    fill: $personality-bg;
  }
  .advanced-arrow-fill {
    &.personality {
      fill: white
    }
  }
}
</style>