export const DISCOUNTS_ENABLED = {
    foundational: false,
    advanced: false,
    companionship: false,
    transit: false
}

export const DISCOUNT_PERCENTAGE = {
    foundational: 30,
    advanced: 30,
    companionship: 30,
    transit: 30
}


