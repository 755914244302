export default `<svg viewBox="0 0 511.713 432.621" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <path d="M421.938,32.038L399.114,38.2c-6.352,1.72-14.72,3.756-23.108,4.357
    c-29.545,2.079-57.369,13.802-78.336,32.996c-1.583,1.456-3.049,2.849-4.379,4.178c-18.571,18.561-29.069,25.799-37.427,25.799
    c-8.357,0-18.856-7.239-37.859-26.232C196.5,57.974,167.44,44.953,136.154,42.6c-9-0.665-17.917-2.859-25.461-4.896L89.79,32.038
    c-5.656-1.541-11.417,1.804-12.947,7.417c-1.53,5.624,1.794,11.417,7.428,12.947l20.903,5.666
    c8.504,2.311,18.645,4.78,29.407,5.571c26.274,1.973,50.627,12.852,68.935,31.001c5.941,5.941,11.396,11.037,16.619,15.427
    C155.918,126.117,108.14,184.13,108.14,253.254c0,81.459,66.265,147.725,147.724,147.725s147.725-66.266,147.725-147.725
    c0-69.113-47.778-127.127-111.996-143.176c5.223-4.389,10.678-9.496,16.618-15.437c1.119-1.119,2.364-2.311,3.704-3.535
    c17.463-15.997,40.751-25.757,65.568-27.508c10.214-0.707,19.869-3.06,27.139-5.033l22.824-6.173
    c5.624-1.52,8.947-7.302,7.428-12.937C433.354,33.831,427.562,30.444,421.938,32.038z M382.485,253.254
    c0,69.82-56.801,126.621-126.622,126.621s-126.621-56.801-126.621-126.621s56.8-126.621,126.621-126.621
    S382.485,183.434,382.485,253.254z"/>
</svg>`