<template>

  <side-extended-panel id="share-chart"
                       :close-btn-text="$t('owl.close')"
                       :visible="show"
                       from-left
                       hide-backdrop
                       @close="close">

    <template #title>
      <div>
        <div class="d-flex align-items-center gap-10">
          <span :class="`icon-${icon} h4 mb-0`"/>
          <div>
            <h4 class="mb-0">
              {{$t('hd.planets.' + planetId + '.name')}}
            </h4>
            <label class="font-weight-normal mb-0">
              {{$t('hd.planets.' + planetId + '.keynote')}}
            </label>
          </div>
        </div>

      </div>


    </template>


    <div v-if="data" class="py-5 px-4">
      <div class="mb-5">
        <div class="row align-items-center">
          <div class="col-12 col-md-7">
            <label>Selected Date</label>
            <h2>
              {{formatDate(timestampUtc, false, 'D MMM YYYY HH:mm')}}
            </h2>
          </div>
          <div class="col-md-5 text-right">
            <b-button variant="primary" @click="() => {
                changeTransitTimestamp(timestampUtc, false);
                close();
            }">View Chart on this date</b-button>
          </div>
        </div>

      </div>



      <div>
        <h3 class="d-flex align-items-center gap-5 mb-4">
          <span class="icon-gate"></span> {{ $t('hd.gates.name.one') }}
        </h3>

        <planet-next-prev-box :current="data['currentData']"
                              :next="data['nextGate']"
                              :prev="data['prevGate']"
                              @change="val => $emit('selected', {
                                planetId: this.planetId,
                                timestampUtc: val})"/>

        <gate-content :gate-id="data['currentData'].gate" class="py-2" hide-content show-title />

        <transit-planet-timeline :data="data" :type="dataTypes.nextGate" :planet-id="planetId"/>

      </div>

      <div class="line-transit-tool mt-5">
        <h3 class="d-flex align-items-center gap-5 mb-4">
          <span class="icon-lines"></span> {{ $t('hd.objects.line') }}
        </h3>

        <planet-next-prev-box type="line" :current="data['currentData']" :next="data['nextLine']" :prev="data['prevLine']"
                              @change="val => $emit('selected', {
                                planetId: this.planetId,
                                timestampUtc: val})" />

        <line-content :gate-id="data['currentData'].gate" :line-id="data['currentData'].line"
                      @loading="()=>{}"
                      :get-icon="()=>{}" />

        <transit-planet-timeline :data="data" :type="dataTypes.nextLine" :planet-id="planetId"/>
      </div>
    </div>
  </side-extended-panel>
</template>

<script>

import SideExtendedPanel from "../SideExtendedPanel.vue";
import {mapState} from "vuex";
import planets from "../../../helpers/rave/constants/planets";
import TransitToolMixin from "../../../mixins/TransitToolMixin";
import GateContent from "../../charts/properties/content/GateContent.vue";
import PlanetNextPrevBox from "../../charts/transit-tool/elements/planet-next-prev-box.vue";
import LineContent from "../../charts/properties/content/LineContent.vue";
import {getIchingIcon} from "../../../utils/iching";
import TransitPlanetTimeline from "./partials/TransitPlanetTimeline.vue";

export default {
  props:{
    planetId: [Number, String],
    timestampUtc: [Number, String]
  },

  mixins: [TransitToolMixin],

  components: {
    TransitPlanetTimeline,
    LineContent,  PlanetNextPrevBox, GateContent, SideExtendedPanel},

  computed: {
    ...mapState({
      show: state => state.ui.showTransitSidebar
    }),
    icon(){
      return planets.icons[this.planetId]
    },

    changes(){
      if(!this.data) return null;
      return {
        [this.planetId]: this.data
      }
    }
  },
  created(){
    this.getData();
  },
  watch:{
    planetId(val){
      if(val !== null) this.getData();
    },

    timestampUtc(){
      this.getData();
    }
  },
  data(){
    return {
      queried: null,
      data: null,
    }
  },
  methods:{
    getIchingIcon,
    close(){
      this.$store.commit('ui/SET_SHOW_TRANSIT_SIDEBAR', false);
    },

    getData() {
      if(this.planetId === null) return;
      if(this.timestampUtc === null) return;
      const key = this.planetId + '.' + this.timestampUtc;
      // prevent duplicate fetch here.
      if(this.queried === key) return;

      this.queried = key;
      this.axios.get('/transit/transit-data/planet/'+ this.planetId, {params:{ transit_date: this.timestampUtc }}).then(res=>{
        this.data = res.data;
      }).catch(()=>{
        this.$notify({type: 'error', title: this.$t('errors.general'), text: this.$t('errors.retry')});
      });
    }
  }
}
</script>