const GEMINI = {
  id: 0,
  name: 'Gemini',
  gates: [15, 12, 45, 35, 16, 20],
  startArc: 60,
  endArc: 90
}

const CANCER = {
  id: 1,
  name: 'Cancer',
  gates: [56, 62, 53, 39, 52, 15],
  startArc: 90,
  endArc: 120
}

const LEO = {
  id: 2,
  name: 'Leo',
  gates: [29, 4, 7, 33, 31, 56, 7],
  startArc: 120,
  endArc: 150
}

const VIRGO = {
  id: 3,
  name: 'Virgo',
  gates: [46, 6, 47, 64, 40, 59],
  startArc: 150,
  endArc: 180
}

const LIBRA = {
  id: 4,
  name: 'Libra',
  gates: [46, 50, 32, 57, 48, 18],
  startArc: 180,
  endArc: 210
}

const SCORPIO = {
  id: 5,
  name: 'Scorpio',
  gates: [14, 43, 1, 44, 28, 50],
  startArc: 210,
  endArc: 240
}

const SAGGITARIUS = {
  id: 6,
  name: 'Sagittarius',
  gates: [10, 11, 26, 5, 9, 34],
  startArc: 240,
  endArc: 270
}

const CAPRICORN = {
  id: 7,
  name: 'Capricorn',
  gates: [60, 61, 54, 38, 58, 10],
  startArc: 270,
  endArc: 300
}

const AQUARIUS = {
  id: 8,
  name: 'Aquarius',
  gates: [30, 49, 13, 19, 41, 60],
  startArc: 300,
  endArc: 330
}

const PISECS = {
  id: 9,
  name: 'Pisces',
  gates: [25, 36, 22, 63, 37, 55],
  startArc: 330,
  endArc: 360
}

const ARIES = {
  id: 10,
  name: 'Aries',
  gates: [3, 42, 51, 21, 17, 25],
  startArc: 0,
  endArc: 30
}

const TAURUS = {
  id: 11,
  name: 'Taurus',
  gates: [8, 23, 2, 24, 27, 3],
  startArc: 30,
  endArc: 60
}

const getById = (id) => {
  switch (id) {
    case 0:
      return GEMINI
    case 1:
      return CANCER
    case 2:
      return LEO
    case 3:
      return VIRGO
    case 4:
      return LIBRA
    case 5:
      return SCORPIO
    case 6:
      return SAGGITARIUS
    case 7:
      return CAPRICORN
    case 8:
      return AQUARIUS
    case 9:
      return PISECS
    case 10:
      return ARIES
    case 11:
      return TAURUS
    default:
      return null
  }
}

const getAll = {
  GEMINI, CANCER, LEO, VIRGO, LIBRA, SCORPIO, SAGGITARIUS, CAPRICORN, AQUARIUS, PISECS, ARIES, TAURUS
}

export default { getById, getAll, GEMINI, CANCER, LEO, VIRGO, LIBRA, SCORPIO, SAGGITARIUS, CAPRICORN, AQUARIUS, PISECS, ARIES, TAURUS }
