import types from '@/helpers/rave/constants/types'

export default {
  13: {
    name: '1/3',
    title: 'The 1/3 Profile',
    keynote: 'Investigator Martyr',
    desc: {
      [types.MANIFESTOR]: `You need to be in a place of authority, which naturally suits the capacity of the Manifestor who likes to act and be in control.
        Manifestors generally have a closed-off aura, but what supports the Manifestor with a 1/3 profile is that you are basically empathic and open to being approached by the other. At the same time you can let others know when not to disturb you, for example while you are spending time studying or engaged in research, or when you simply feel the need to withdraw. As a 1/3 you are self-absorbed and busy with your own process, which is natural and healthy for you.
        You need to go deeply into things, and once you are ready the way to live out your authority is to inform others before taking action. You need to be certain that your foundation is strong and well researched so that it can support your endeavors. Whatever a Manifestor undertakes generally has a strong impact on others. Informing before you act is the only way to avoid others’ resistance, and doing so will ensure that they are ready to receive the powerful impact that your actions can create.`,
      base: `The 1/3 profile is about investigating and getting to the bottom of things. You need to deeply understand how something works in order to have a solid foundation and feel secure in your process. It is important that you consciously focus on any area you take an interest in and gather as much information as possible in order to be well prepared to meet the outside world. You like to spend much of your time engaged in introspection, absorbed in your own process.  By studying deeply you will ensure that you are your own authority and can move through life independently. Otherwise you may look for others to provide you with the foundation you need in order to feel secure.
        A fundamental aspect of your process is to discover what is correct for you through the process of trial and error. This means that you often bump into things, such as unexpected events or other people, or that life bumps into you, which can help you to accidentally discover something important. It is natural for you to make mistakes and uncover what does not work as part of your learning process; as a 1/3 you come to discover what works by first learning what does not. This manner of learning builds the strength and resilience that are characteristic of your profile and which sustain you in your process. You have the capacity to tear things down that do not work, along with a tenacity to try again and again. Ultimately there is no failure for the 1/3, only discovery.
        This profile is about personal destiny. You are self-absorbed yet adaptable, and you like to be independent and in charge of your own life. You have a strong need to express your creativity, and you need time to withdraw and be alone in order to focus on your creative process.
        Relationships are also a part of your trial and error process: you know quickly when something doesn’t work, so naturally you make and break many bonds along the way until you find you are comfortable within a relationship.`
    }
  },
  14: {
    name: '1/4',
    title: 'The 1/4 Profile',
    keynote: 'Investigator Opportunist',
    desc: {
      [types.MANIFESTOR]: `The power of the Manifestor comes from informing before taking action, because when a Manifestor does so resistance is eliminated. The Manifestor’s inherent tendency is simply to take what it wants without informing, which more often than not gets it into trouble and creates all kinds of resistance from others. 
        Using your strategy can prevent this trouble: as a 1/4 Manifestor, the key for you is to wait for the right opportunity to inform others before you act. These opportunities are brought to you through your network, and it is within your own network that you can be of great benefit to others. Even though the network of your community plays an important role in your life and every 1/4 needs strong and comforting relationships, you also need plenty of time to yourself so that you can build a secure foundation before sharing your knowledge base with others. It is also crucial that those people you invest time and energy in are part of your network and trusted allies. 
        Not waiting for the right opportunity and externalizing your knowledge base to the wrong people as a result will bring you only resistance, and you may end up feeling inadequate and angry. As a Manifestor you can always act, but the theme for the 1/4 is to wait for the perfect moment or the right opportunity, and then to let others know what you are ready to share. This way you will have the strongest impact.`,
      [types.GENERATOR]: `A 1/4 profile builds a solid foundation and then waits for the opportunity to externalize and share its knowledge base within a personal network. You have considerable social capacity and can easily bring friendships into your life, but it is crucial that you connect with the right people and enter into situations correctly through response.  
        Throughout your life, there will be a myriad of opportunities brought to you by your network of friends and people you know. Therefore it is essential that you use your strategy to respond to opportunities that are correct for you, which will always be brought to you by your personal network. This way you can realize your potential and externalize your foundation. If you do not follow your strategy you can easily get lost in activities and situations that are not right for you, and that drain your energy and leave you frustrated. However, when you respond to the correct opportunities you can be deeply influential within your community, and others can benefit from the discoveries you are ready to share.`,
      [types.PROJECTOR]: `This profile carries the theme of waiting for the right opportunity, which in many ways suits the Projector, who needs to wait for an invitation. Once you are secure in your foundation and answer the correct invitation, you will be brought the opportunities you are looking for. With your considerable social skills you can easily attract those people to your network who present you with the right circumstances to express your knowledge base.
        As a non-energy type, be aware that you also need plenty of time to yourself in order to avoid burnout, as too much interaction leaves your energy field depleted.
        The key is to wait for the invitation, which will offer you the right opportunities when it is time for change. Do not leave something you are closely involved in, for example a job or a relationship, until there is something or someone to replace it with.  This is the essence of your process: to wait for the opportunity to have access to the energy field around you and then to express what you have discovered.  In this way you can impact those who benefit from your insights and receive the recognition every Projector looks for.`,
      [types.REFLECTOR]: `With a 1/4 Profile you need time to investigate and get to the bottom of things. This process will provide you with the information you need in order to establish a secure base from which to operate. Your social skills support you in finding opportunities to express your foundation within a network of friends and associates. As a Reflector, you are very open to any kind of conditioning and at the same time dependent on the feedback of others, which naturally leaves you vulnerable to the energy fields you encounter on a daily basis. Therefore you need to find trusted allies through the social circles you are connected to, which bring you the opportunities that are correct for you. It is also important to spend enough time alone in order to stay balanced.
        As a non-energy being without inner authority, in order to make a decision it is essential that you wait out an entire lunar cycle during which you encounter the transits of the Moon through all the gates in your BodyGraph. This time frame provides you with a variety of experiences and allows you to get to a place of clarity where you can recognize the correct opportunity when it presents itself.
        The 1/4 profile has a fixed way about it, and you experience an intense focus on those things you believe are important to know and that in time you are ready to externalize within your community. You choose your friends in the same fixed manner which is simply natural for you, and as a Reflector it is paramount to have consistent allies in your life who can function as a sounding board. You have the capacity to recognize who is valuable for your process and can help you find the clarity to make a decision. Once a decision is made, it is better to stay with it until another opportunity presents itself.
        Through your openness and capacity to be transpersonal you can ultimately grasp the nature of others -- as well as whatever you investigate -- at a very deep level, and therefore have valuable insights to share within your community.  `,
      base: `With a first line personality of being an investigator, you are always going to be testing, re-checking, and looking into the very foundation of things. You need to get to the bottom of how things work. Once you grasp the foundation of whatever you are interested in at a very deep level, this will give you the inner security you seek.
        Once you have established a solid foundation, you will look to externalize your knowledge base and share it within a community of like-minded people. This process of investigating and sharing what you have discovered within a community is a central theme in your life. You are not interested in influencing strangers but only those that are part of your established network.
        Being part of a network offers you the opportunities you are looking for throughout your life. As a 1/4 profile, you have a natural gift to connect with others due to your tendency to be empathic and open, and because building strong and comforting relationships and friendships is important to you. The people in your network are the contacts that will connect you to the correct opportunities to externalize your knowledge base. But keep in mind that this need to relate is not a conscious drive within you, and that while cultivating a network is central to your life you may at times find yourself exhausted by the many associations and interactions that are part of your networking process.
        In order to balance this tendency towards burnout it is important that you spend time alone so you can immerse yourself in the process of quiet introspection. Doing so will also enhance your creative potential, as you need time alone in order to develop your skills. Cultivating your skills is a very nourishing process for you, and when you have built a solid foundation you can go out and find others to share and commune with.
        For the 1/4 profile it is essential to remain independent, to master your foundation, and to be able to provide for yourself.`
    }
  },
  24: {
    name: '2/4',
    title: 'The 2/4 Profile',
    desc: {
      [types.MANIFESTOR]: `As a 2/4 Profile Manifestor, going about your own business is essential. While you are open to cultivating friendships and dependent on others to bring you the opportunities you seek, you have a strong need to be left to yourself and not be subject to others’ demands. Your Manifestor aura supplies you with a built-in barrier, which protects you from outside interference. 
        Waiting to be called out does not correspond to the essential nature of the Manifestor, who is always ready to act. You can be extremely beneficial to those in your network, but only when the right opportunity to externalize your gifts presents itself. If you can accept that you need to wait for the call, and through strategy and authority know which call to answer, then you can initiate without resistance and your actions can have true impact. The key to making the right decision will always be to follow your strategy and authority. If you decide to seize an opportunity and it turns out to be the wrong call, you will end up dealing with anger, burnout and fatigue.
        Remember, informing others of your intent to act at the right moment—and through the correct opportunity—will assure that you can manifest your skills, align yourself with your life's purpose, and have a tremendous impact without resistance.`,
      [types.GENERATOR]: `As a Generator, you need people in your life, and as a 2/4 Profile, attracting others is one of your natural talents. Friendships are very important to you because it is through them that new opportunities arise. At the same time, you need to be left alone, absorbed in your own process so you can nourish your talents. It is also essential that you do not initiate; you are here to respond to life and let opportunities come to you. They always will. By following your strategy and authority you will receive the correct call through the right people within your unique network.
        By nature, you are always ready to respond to an opportunity to engage with others and share your gifts. But if you respond to the wrong call, you will not be able to share your skills. You will squander your talent, and find yourself engaged in situations and relationships that are not correct for you. This can leave you frustrated and unfulfilled, typical for a Generator who does not follow their strategy. If you apply your gifts when the moment is right, then you can have considerable influence and others will truly benefit from your capacities.`,
      [types.PROJECTOR]: `As a 2/4 Projector it is essential that you wait for the correct invitations. Waiting will ensure your skills and talents are recognized by the right people, who will then give you the opportunity to be deeply influential. The 2/4’s strategy of waiting for the correct opportunity is very much in harmony with the projector’s general strategy of waiting for the invitation, and by experimenting with these you will be able to enter into any situation in the right way.
        You naturally prefer to be left to yourself to nourish your skills, immersed in your own process and, once you receive the right call in the form of an invitation that is correct, then you can share your skills.
        While you enjoy friendship and the company of others, you find harmony in solitude, as you have a deep need to be left to yourself and do not like to be subject to others’ demands if they are not aligned to your purpose. This inner harmony also sets the basis from which to operate so you can recognize the correct invitation when it comes your way.
        To answer the correct invitation is essential to your process, offering you the chance to share your gifts with the world, and thereby be recognized for your outstanding contributions.`,
      [types.REFLECTOR]: `This profile naturally attracts projections as you have talents recognized within your community, and which others expect you to share. Others can see your gifts. At times, this can feel like a burden and cause you to withdraw, as you would much rather be alone, immersed in your own process.
        As a Reflector, you have a completely open aura and generally need to be in the company of others who can function as sounding boards to help you find clarity. Even more so for a 2/4 Reflector who depends on a network of like-minded people to encounter the opportunities you seek.
        You need to surround yourself with a circle of trusted friends and allies who can spot the talents you are ready to share, and who can offer you the right feedback. You can be friends with very different people and naturally attract others through your social skills, but you need to be aware that people can exhaust and overwhelm you, and that it is essential to discriminate who you let into your aura. For example, you need to find the right work environment where you have a community that is consistent and that feels comfortable, but that allows you to maintain your separate space.
        It is very important that you keep a balance between your need for aloneness, which helps you relieve pressure and preserve inner harmony, and your need to respond to the call and step back into the world when the time is right.
        The key here is to honor your lunar cycle during which you gather the experiences and feedback that help you gain clarity. In this way you can make the right decisions, share your talents and be a source of nourishment for others.`,
      base: `As a 2/4 Profile, you are here to share your innate talents when others call upon you. This happens often in your life; you are a naturally gifted person, possessing talents and skills that you are not necessarily aware of, and that you often can’t explain. This is perfectly natural for a 2/4 and nothing to be concerned about.
        You prefer to be left absorbed in your own process to nourish your talents, while an inherent barrier protects and shields you from unwanted outside interference. During this process, you await recognition; you will be called upon once you are ready to share your gifts with the world. Once your gifts are truly recognized and you find the right opportunity, your way of externalizing your talents can be extremely impressive.
        To ensure you recognize the correct “calls,” you need to make decisions based on your strategy and authority. You have an instinct for networking, but making decisions with your mind will not align you with the correct network. By following your strategy and authority, you will find the community where you belong. You are not designed to influence strangers. You are here to be called out through your network, a community of like-minded allies who are open to receiving your influence. This is when you will meet opportunities that are right for you—ones that may bring profound changes to your life.
        By engaging and applying your gifts in this way, you will make a lasting contribution and achieve considerable success in your life.`
    }
  },
  25: {
    name: '2/5',
    title: 'The 2/5 Profile',
    desc: {
      [types.MANIFESTOR]: `As a 2/5 Manifestor, it is essential that you enter into a process correctly by informing others of your intentions before you act. You are under quite some pressure to respond to the demands and needs of the world around you, as others tend to see you as someone with talents. They expect you to bring your manifesting capacity to work, and use your talent to offer practical solutions. If you prefer to go about your own business without acting in support of others, this can create a lot of resistance and negative projection.
        It is essential that you wait for the right call before you take action, otherwise the projection on you is that of not having fulfilled your purpose or offered your talent in the right way, which can be quite damaging for the Manifestor.
        Ultimately, you can be as selfless in your capacity to contribute as you are selfish in your need for total withdrawal. One depends upon the other, and to bring these two qualities into balance is crucial to your success.`,
      [types.GENERATOR]: `You can put your talent to use and offer practical and even radical solutions, but to be effective, your response to being called out needs to be based on your strategy.
        Others tend to place demands on you, as your talent is apparent, and expect you to be available and share your skills. It is essential that you use your strategy and respond only to those calls that are correct for you, otherwise you risk being entangled in all kinds of situations where you are not able to use your talent correctly, meaning what you offer will not be truly practical. This can cause negative projections and leave you frustrated, with a depleted energy field.
        The same holds if you are not willing to contribute for others’ benefit, but generally prefer to go about your own business, leaving the responsibility for finding solutions to others. You are a productive worker, and you naturally attract people to you. You can be of tremendous benefit to others if you put your considerable energy to use in the right way, which in turn can leave you feeling fulfilled and satisfied.`,
      [types.PROJECTOR]: `With your self-absorbed personality, it is natural for you to wait until others call you out, as you generally prefer to stay with your own process. You are the recipient of plenty of projections; together with the demands others place on you, the expectation that you can offer solutions can cause you to retreat rather than share your skills.
        To wait for the formal invitation is the way of the Projector, and it is essential that you are clear which invitations to follow to be effective. After all, your process is about being recognized, which can only happen if your involvement is correct and you answer the right call. As a non-energy type, you need to protect your aura; to engage in a process that does not offer potential for recognition can cause you to feel deeply misunderstood and drained of energy. Once you are clear about which invitations to accept, your potential to guide others with your skills and practical advice can be of tremendous benefit.`,
      [types.REFLECTOR]: `This profile receives plenty of projections that can be rather testing and uncomfortable for a Reflector. You can use your talent to offer practical advice for handling situations that ask for solutions, and this is what others expect of you. A Reflector continuously has to cope with the impact of the energy fields surrounding them, whether from people, environments or transits. Without a direct inner authority, you need others’ feedback to find clarity and make correct decisions, so it is important to engage with the world to find your allies.
        Still, the essential nature of this profile is a deep need to be left alone, to withdraw and wait for the right timing to fulfill the expectations that are placed on you. Keep in mind that this timing is extremely important; you can only respond to being called out and bring what is truly valuable when you have gone through the lunar cycle and have solid feedback to make a decision. Without these tools, you may answer calls that are not correct, finding yourself unable to apply your talents, offering solutions that do not apply, and ending up deeply disappointed—the signature of an unfulfilled Reflector.
        Waiting out your cycle, surrounded by others who offer reliable feedback, ensures you have the clarity you need to deal with the projection field. Over time, you gather wisdom to recognize what is truly valuable and what can be shared with others.`,
      base: `The 2/5 profile is a self-absorbed personality, generally very much involved in your own process. You have a strong need for harmony and to maintain this, you need to withdraw regularly, not always being comfortable with the ways of the world and often at odds with what is generally accepted. You have an unconscious need to transform what is not practical, and possess the talent to bring change that impacts society.
        Others recognize your skills and talents, which they like to call out, projecting on you the ability to offer practical solutions in times of crisis and need.
        Fifth lines essentially have a transpersonal quality, which means they can view and understand things from a wider perspective and are therefore able to provide practical solutions when needed. These are often far-reaching formulas that can find universal application among the collective to initiate social change. At times, you can be seen as quite a revolutionary at work.
        Others look to you for leadership, and the projection field created around you carries the expectation that you can provide solutions. Over time, this projection builds up and can prove to be rather challenging. You may feel considerable pressure and to find balance, it is important that you embrace your need to be a hermit and reestablish the sense of harmony that nurtures you.
        This will also offer you the clarity you need to recognize the right call without wasting energy on calls that are not correct for you. You need to be certain your suggestions are indeed practical and can be manifested, otherwise the result is a negative projection and you are left to deal with the consequences. If you are aligned to your strategy and authority, this will ensure that you answer the correct call, and that way offer your talent to manifest change in the correct way.`
    }
  },
  35: {
    name: '3/5',
    title: 'The 3/5 Profile',
    desc: {
      [types.MANIFESTOR]: `As a 3/5 Manifestor, you do not externalize practical solutions, but are always ready to act and jump into any experience, to engage in building and breaking bonds, whether in your personal relationships or career. This will not necessarily be without difficulties; the road can be quite bumpy at times, but your strength and determination will always see you through.
        As a Manifestor, if you inform others before you act, the consequences of trial and error will be positive rather than negative. Otherwise, if things go wrong, your reputation can suffer considerably.
        This profile can have a very dynamic influence on our civilization, because it appears that you are always ready to take a risk through your ability to accept mistakes as a natural part of the process, and to sustain yourself throughout. This is what many fear, but what is natural for you.`,
      [types.GENERATOR]: `Throughout your process of discovery and finding out what works and what does not, it is essential that you use your strategy to enter into situations correctly through response. As you encounter myriad experiences along the way, bumping into all kinds of things and forces, you need to develop discernment whether to engage in a situation. 
        The only way to know if something is worthwhile and indeed right for you is through your sacral response. That way you can be assured that the experience is correct for you, regardless of the outcome. Otherwise the accumulation of experiences that turn out to be impractical and not to work leaves you depleted and feeling frustrated, with a sense that nothing truly works. 
        Your sacral response will also let you know when it´s time to break away because an experience you entered into – whether a relationship or in your work environment – is no longer correct for you. 
        This profile is about discovery and being able to offer solutions. Your authority is your true guide in all the encounters you have along your way, and will ensure that you apply your tremendous sacral energy in the right way.`,
      [types.PROJECTOR]: `Throughout your discovery, you will receive a variety of invitations, often out of the blue. It is essential to be clear in your response and only accept invitations that are valuable and right for you. Only when you are properly invited and ready to accept the invitation will the experience be valuable for you, regardless of the outcome. After all, this is your process of discovery, and learning through mistakes is an essential part – but only if the mistake was valuable and contributes to your overall experience of learning. Others will project that you are able to offer practical solutions, and for a projector to be recognized is essential. Otherwise, the accumulation of experiences entered into through following invitations that were not correct for you can leave you feeling bitter, with a sense that nothing truly works.
        In relationships and within your work environment, it is important that others recognize your need for space, to give you the freedom to move through a variety of experiences so there is a continuum in your experience instead of the feeling that you are simply changing and moving on. This way you will receive the recognition that allows you to fulfill your purpose.`,
      [types.REFLECTOR]: `As a 3/5 Reflector, it’s important to take your time to find out what does not work.
        As you go through new experiences, you recognize when they work and when they don’t, and move on to further experiences in the continuum.  The 3/5 is a risk-taking profile; throughout your life you will be unable to avoid risk-taking as a theme. You have the capacity and power to move through things, gain wisdom and to deal with failure if necessary.
        As a Reflector, it is essential to wait out your cycle and give yourself time to make a decision to be clear. During this time, the 28-day cycle of the Moon, you need to seek feedback and guidance from trusted allies—people who are reliable auras in your life and who give you a sense of stability. During any given cycle, you can gather all the information you need and enter into relationships, jobs and anything of interest knowing that your choice is correct. Even if things turn out not to work, this will add to the wisdom you gather and are able to share with others.`,
      base: `You are resilient, adaptable and an agent for change. A main theme in your life is the trial-and-error process, discovering what does and does not work on the material plane. Your life is about experimentation and experiential investigation, adapting and refining as you go through a variety of experiences.
        As a third-line personality, your learning entails bumping into things and people as if by chance. These so-called chance encounters provide you with the opportunities needed for your discovery process. You face personal and career challenges, because it’s natural for you to make and break bonds. But no matter what transpires, your secret in life is to stay with your process; you are not here to quit and give up because life is full of trial and error.
        To be able to stay with the same thing instead of jumping from job to job, it is necessary to do work that is stimulating, and that continuously offers new things to do. In your personal life, you need relationships in which you have the opportunity to withdraw and have your own space, yet still feel comfortable in being able to re-engage and re-make the bond.
        Once you apply strategy and authority and engage in what for you is a correct process, you show considerable resilience, determination and adaptability to move through the challenges life brings.  Often, you may be surprised to encounter a situation that opens up a whole new way. Ultimately, you are here to master the material plane and come up with truly innovative concepts.
        Inside a 3/5 lives an anarchist willing to tear things down and fix them. Through a practical, experiential  process of learning from mistakes, a 3/5 either moves on or re-engages to find a way of making things work. Others project this capacity on you, and it takes quite some determination not to be deterred even when things go wrong, but to have the resolve to continue your explorations and fix things. You can then provide innovative solutions and guidance to create foundations for others to build on. Over time, your trial-and-error process will enhance the qualities of adaptation and resilience, and through your experiences you ultimately gather a wealth of experiential wisdom.`
    }
  },
  36: {
    name: '3/6',
    title: 'The 3/6 Profile',
    desc: {
      [types.MANIFESTOR]: `As a Manifestor, it is essential to inform others of your intention to act, creating the basis to enter into experiences correctly. You have a tendency to rush headlong into things and to simply act out, which suits the capacity of a Manifestor. Without an understanding of your mechanics, this can create considerable chaos and result in failure, particularly during the first phase in life. If you take action without informing those around you, then the impact can be disastrous and engender enormous resistance among others. In turn, this resistance leads you to experience a lot of anger, typical of the Manifestor who does not follow his strategy.
        Once you inform others of your intentions, then your experiences will support your process of discovery and even if things “go wrong” or turn out other than expected, you will ultimately discover what is viable. The wisdom you acquire through developing an objective perspective enables you to have a powerful impact as a role model later in life.`,
      [types.GENERATOR]: `This profile is rooted in the trial and error process and, as a Generator, it is paramount that you enter into any activity based on response. By engaging based on your sacral response, even if things turn out wrong or not the way you envisioned, which may happen frequently, these experiences will be right for you, and critical to your process of discovery.
        The frustration of a Generator who enters into situations incorrectly and not based on response, and experiencing a lot of failure and broken bonds during this process, can cause you to run away from experiences that could otherwise be meaningful. This will also most certainly distort your perspective, and cause you to quit and withdraw if you find yourself in situations that don’t work out, over and over.
        Once you are firmly based in response, over time you will develop objectivity, trust and even wisdom, and later in life emerge as the role model sought out by others for the wise guidance you have to offer.`,
      [types.PROJECTOR]: `This profile is rooted in the trial and error process, which brings with it a multitude of experiences that are part of your journey of discovery. As a Projector, your strategy is to wait for the invitation. Carrying this profile, it is ever more important to wait for the right invitation, to chose wisely which invitation to answer to. You are a non-energy type of being and if you find yourself pulled into experiences and confronted with people and environments that are not right for you, you find yourself drained of vital energy, pessimistic about life, and embittered by lack of recognition, being unable to offer the guidance that is natural for the Projector.
        When you engage based on answering to the correct invitation, your process takes you on a journey of discovery—one that, even when things do not work out as envisioned, particularly during the first part of life with all its turmoil, and despite the bonds broken in your relationship and work environment—brings you closer to developing objectivity during your second phase of life from thirty to fifty.
        Your search for perfection and for the ideal can flower during the latter phase of life when you realize your potential as a true role model, here to encourage others through your insights and wise guidance.`,
      [types.REFLECTOR]: `The Reflector is consistently subject to the conditioning field of others. Due to your openness, your actions are mirrored and consequently, all the mistakes that are naturally part of your process of trial and error will be projected on and judged by others. You can be very uncomfortable with others’ opinions and also with your own way of perceiving life. The challenge is not to succumb to pessimism but to trust that things can work out, even though so-called mistakes and failures are inherent in and critical to your process of discovery. 
        During the first phase of life, with all its trial and error emphasized for the Reflector, this presents a considerable challenge to the underlying optimism fundamental to this profile. During this phase, you establish a strong foundation to carry you through the latter phases in life.
        The Reflector’s way is to live through an entire cycle of the Moon and during this time to seek others’ feedback before making a decision. In addition to influences of the transit field, you are exposed to the conditioning field of others and therefore need the correct feedback of trusted allies. Once you engage in an experience, you need to be certain that it is right for you, independently of whether things ultimately work out. This is critical to your process of discovery, enabling you to develop objectivity during the second phase, age 30 to 50. Otherwise, you may end up simply aloof and disengaged in order to avoid the inevitable disappointments of the earlier part of life.
        Engaged in the right kind of experience and with the appropriate feedback, you have the opportunity to develop true objectivity and, during your later phase in life, find profound wisdom.`,
      base: `With a 3/6 profile, a main theme in your life is the trial and error process. This means you are drawn to experience and experiment, and to find out what works and what does not. Along the way, you bump into many things and if you see that they do not work, you tend to simply quit and move on to the next situation instead of trying to fix what does not seem to work
        You are generally optimistic by nature and are always seeking the ideal and perfected. Your process of discovery takes you through many diverse experiences to meet this perfection in life, whether in your work environment, your relationships or any other endeavour you take an interest in. This can be challenging and even chaotic during the first thirty years of life and, at times, make you feel rather pessimistic about life. But if you understand and accept that for you, there are no mistakes but only discoveries, this will greatly support your search for what is viable.
        All sixth lines go through a three-stage process in life, and it is during the first thirty years that the trial and error process is the most challenging.
        During this period, there is a tendency to rush headlong into things and experience quite a few setbacks, so it is essential to understand this as a natural part of your life´s process in order to maintain an optimistic outlook, without being overcome by a sense of pessimism and futility.  Understanding your mechanics and using strategy and authority will bring you into alignment with your process.
        At the next stage in life, between 30 and 50, with many experiences and difficulties encountered during the first 30 years behind you, you will feel the need to withdraw. Rather than being disillusioned and pessimistic about life, the direction at this time is to retreat and simply observe what goes on around you. This way, you can develop an objective perspective about life, find out what is viable, what really does work, and how it can work.
        While you may experience frequent break-ups in your relationships and changes in your work and other situations during the first part of life, during this later phase you can finally find stable relationships and a viable career.
        Your search for the ideal involves having to trust in your process, and once you move past 50, you have the opportunity to find out who and what you can really trust.
        At the unconscious level, this profile is a role model. The accumulation of experiences through trial and error can lead to great wisdom later in life, when others seek out the guidance you can offer.`
    }
  },
  41: {
    name: '4/1',
    title: 'The 4/1 Profile',
    desc: {
      [types.MANIFESTOR]: `As a 4/1 Manifestor, you travel your own way in life in a very determined and fixed way. You are looking to externalize your influence and share your investigations within your network, and for this reason are generally open to meeting others. As a Manifestor, it is essential to inform those around you when you are ready to initiate and communicate your knowledge. This can have a powerful impact on others, and letting them know prior to taking action will ensure that you have the necessary authority and they are open to your impact without feeling resistance, or even rejecting your efforts.
        Even though you need your network of allies to encounter the opportunities you seek, at heart you are quite a loner who needs to do things your way, and not submit to the pressure others exert. The time spent alone is essential for researching and investigating, and for a Manifestor, it is quite natural to seek solitude and autonomy. At times, you may experience your inflexibility as leaving you vulnerable, as you cannot help but stay with your unique and fixed ways, even if things seem to fall apart. After all, this is correct for you; you are not made to adapt to the expectations of others. Honouring your strategy is of major importance to ensure a healthy process. Your great strength lies in your ability to share your foundation, to influence those around you with your strength, understanding and purpose.`,
      [types.GENERATOR]: `You are fixed in your ways and determined to keep to your path, which is natural for this profile. You have a very specific geometry and even though you have considerable social skills and easily connect with others, you inherently seek aloneness to stay with your own unique process without being subjected to the pressures others exert. You seek to connect with others mainly to communicate your well-researched knowledge, to share your fixed concepts within your personal network of trusted allies.
        For this reason, you are always ready to meet others, meaning it is essential to listen to your sacral voice to recognize and respond to the right allies. Your inflexibility can leave you vulnerable, particularly when things do not work out as expected. You need to feel secure about who you are, and when aligned, your sacral voice will respond to those associations that are supportive and neither challenge your foundation, nor doubt you.
        As a Generator, ready to apply energy and with the myriad opportunities that come your way through your social network, it’s important to pay attention to your authority and respond only when circumstances are right. This way, you can share your foundation and exert considerable influence within your network.`,
      [types.PROJECTOR]: `As a 4/1 Projector, you are fixed in your ways and determined to keep to your path, and therefore need to pay particular attention to the invitations that come your way. While you have considerable social skills and seek others among whom you can communicate your knowledge base, you inherently seek aloneness to stay with your unique process without being subject to the pressure others exert.
        For this profile, only certain invitations are right for you, being aligned to your very specific geometry. These invitations need to come through your network and in that way, offer the right opportunities to share your knowledge base. The Projector needs to be recognized for their skills, and for this, you need to be surrounded by a trusted network of allies who do not challenge your foundation. In this way, you can share concepts and thereby influence and even guide others correctly, in tune with your unique way.
        At times, your inflexibility can leave you quite vulnerable when things do not work out or even fall apart, but staying with your own process is essential. Waiting for the right invitation will ensure that what you offer is recognized, and that it is always an invitation in alignment with your fixed direction. This way, you can have considerable influence and others will recognize your ability to guide them from a place of authority.`,
      [types.REFLECTOR]: `As a 4/1 Reflector, you are fixed in your ways and determined to keep to your path, and it is essential that you have the right feedback in your life to make the right decisions.
        You have considerable social skills and easily connect with others, and in this way, find opportunities to communicate your knowledge within your network. At the same time, as a Reflector carrying this particular profile, you truly need aloneness to nurture and stay with your unique process. You are set in your ways and do not submit to the pressures of others. Only those who offer the right kind of feedback and who do not challenge your foundation can be seen as part of your network that is open to your influence.
        Your inflexibility can leave you quite vulnerable and things can fall apart; nevertheless, you always need to stay with your process and remain secure within your own knowing. Waiting out a lunar cycle is essential before you decide to share your foundation and ensures it is only with those associations who offer the right feedback and acknowledge what you have to transmit. In this way, you can externalize your truth and be seen as an authority in your own right.`,
      base: `You are a rather sociable person with good people skills, and you’re looking to establish friendships within a network of like-minded people where you can reach out and influence others. To develop the authority to extend your influence, you need to study, learn and establish a secure foundation. Being educated will help the underlying insecurity of the unconscious first line, which requires you to thoroughly investigate and immerse yourself in any topic of interest and acquire a solid knowledge base before acting. Once you feel secure within your area of expertise, this will transform your inherent insecurity, giving you the authority to externalize your influence.
        You seek opportunities within the network that surrounds you and where you focus on establishing friendships to share your knowledge base with others. The quality of your network is very important to your life and overall stability, as your purpose is to influence and share within an intimate community, rather than with strangers. While it is easy with this profile to align and make friends with anyone, underneath, you are also quite a loner focused on your own direction. Therefore, you do not submit to others’ pressure, but always remain your own authority.
        You need to get to the essence of things on your own, and the time you spend alone can be deeply creative. This way, you can overcome this profile’s underlying insecurity and develop confidence through the knowledge base you have acquired, becoming an authority.
        The theme of friendship dominates, even in your intimate relationships, and the key to finding the right relationship is a partner who is a friend who also remains independent. This way, you maintain your own fixed path in life.
        This is a rare profile that carries the theme of a “fixed fate.” This means your life has a fixed direction and you move along a very specific and unique line of geometry. You need to maintain your vision and adhere to your fixed nature, which is your role and purpose in this life. 4/1’s are the pillars of this world; your strength of purpose offers courage to everyone. At the same time, while you may seem very solid, underneath, you are also quite vulnerable and need to be careful not to become too fixed or rigid in your attitude, as this can lead to breakdown.`
    }
  },
  46: {
    name: '4/6',
    title: 'The 4/6 Profile',
    desc: {
      [types.MANIFESTOR]: `For a Manifestor who wants to act immediately, it is a challenge to wait for the right opportunity. But you need to inform others of your plans, and the correct timing is essential for a Manifestor with this profile. Keep in mind that your actions are much enhanced if you wait for the right moment to inform, so there is no resistance at the other end. That way you can manifest your capacities with true impact and be a role model and guide for others.
        You are gifted with social skills, and you can always manifest social connections and extend your influence within your network if you wait for the right opportunity. This is a main aspect of your profile as you are dynamically involved in finding friendship for the sole purpose of externalizing your truth, and you can be very fixed in this way. 
        Your strongest time is during the second phase from age 30 to 50, when you find stability after the initial trial and error phase, and when your natural opportunism combines with a more aloof perspective. Now you can have true impact on others, extend your influence and, over time, mature into a role model, offering guidance to others based on your unique perspectives.`,
      [types.GENERATOR]: `You have a gift for friendship and easily engage socially with others. This is how you meet your opportunities in life; this profile suits the Generator who has to wait for the opportunity to respond. Keep in mind that your response does not need to be formal but can happen in many different ways throughout your day. You need to wait and see what is correct, to find the right opportunity to respond to, to externalize your gifts and share your insights.
        During your first thirty years, you will have many encounters and relationships. If you engage through response, this does not have to be a chaotic process, even if things don’t turn out as expected. You are on your path to finding what truly works and to developing your own unique insights, and with this profile you are quite fixed within your direction.
        Once you have found stability during the second phase, age 30 to 50, and are able to develop more objective perspectives about life, you’ll be able to extend your influence within your network if you respond to opportunities that come through the right connections. Over time, with insights gained along the way and ready to re-engage during the last phase, you will be seen as a role model, ready to respond to those within your network looking for guidance.`,
      [types.PROJECTOR]: `As a Projector, your strategy is to wait for the invitation. Therefore, it naturally suits you to wait for the invitation to engage with others and answer to the social call that is correct for you. With this profile you have considerable social skills and connect easily with others; therefore, it is essential to use your strategy as a way of ensuring you meet allies who can support you in your process. These connections will provide you with the opportunities to be recognized for your skills, and to be influential and guide others. In essence, this is how you can externalize your influence without meeting resistance, by having the right relationships and a network of trusted allies.  Also pay attention to the fact that you need plenty of time to yourself, to have time alone to establish objectivity about the nature of life; as a Projector, your energy field can easily be depleted.  Too much social activity and connections that are not supportive of your process can leave you feeling embittered and unrecognized. To wait for and recognize the opportunities that are correct will serve you to fulfill your purpose and ensure the underlying optimism that sustains you in your search for the perfected, the ideal.`,
      [types.REFLECTOR]: `As a Reflector, you need to have consistent and reliable auras in your life—trusted friends and allies to give you the feedback essential for your decision-making process.
        Your openness as a Reflector invites people in and with this profile you have a natural capacity to interact and to make friends easily. In this way, you will find the opportunities you are looking for to communicate your insights and perspectives, but they can only come through people whose feedback is beneficial to your process.
        While you go through your 3-stage process, during each phase it is vital that you honor your strategy to safely navigate through trial and error the challenges that present themselves.
        In addition to the feedback of trusted allies, only once you have gone through a full lunar cycle collecting experiences will you find the clarity you need. Over time, as your search for the ideal and perfected helps you to find your own truth, you develop objectivity and wisdom about the nature of life, which you can share with others. This way you exert influence and can be a role model and guide for others.`,
      base: `You carry a strong social potential, and community and friendships are very important to you. You know how to meet and integrate with people, and over time learn to observe and evaluate others. This is an important aspect of your process, as these friendships and personal networks will provide you with the opportunities to extend your influence, and share your unique insights with others.
        The unconscious 6th line will take you through all kinds of experiences during the first 30 years of life. You need to involve yourself and experiment to discover how things work on the material plane. This can be challenging and you may experience quite some discomfort because, at the same time, you are consciously seeking to build a solid foundation.
        Between the ages of 30 and 50 there is a natural tendency to withdraw to assess what you have learned; you have the opportunity to move away from subjective experiences and develop a more objective and transpersonal perspective about life and its processes.  You discover your own unique perspective and what is essentially your own truth. This is the time to find stability through a career, family and trusted network of allies. During the last phase, beyond 50, you are ready to re-engage and share your insights with the world.
        Despite appearances, you are a rather self-absorbed personality and often prefer to stand apart to observe your surroundings. You are here to share your wisdom; when the opportunity presents itself at the right moment, you can communicate your perspectives and thereby influence and guide others. In essence, this is the purpose of this profile.
        While friendships and social connections are extremely important to distinguish, as these allow you to exert your influence, you do not like to be dependent in any way. Throughout your life, in each experience you look for an objective approach to back up your optimism about human beings and life´s process. You are on a constant search for what is perfect, for the best possible way. Once you have found your own unique insights, you can be a true role model and guide for others.`
    }
  },
  51: {
    name: '5/1',
    title: 'The 5/1 Profile',
    desc: {
      [types.MANIFESTOR]: `You need plenty of time to develop a solid foundation to stand on. This profile demands to be in control, to be an authority, and without this foundation and practical knowledge to back it up, you are going to meet considerable resistance from the world around. In turn, this leads to the typical anger that Manifestors experience when they meet resistance. You need to be well prepared before you offer practical solutions valuable to others. No other Manifestor has to pay more attention to their actions than the 5/1; you are always in the spotlight. You greatly benefit from withdrawal, away from the projection field, a natural behavior for a 5/1. If you stay with the Manifestor strategy of informing others before you take action, and if it is backed up by a solid foundation, then you can avoid the strong resistance that you would otherwise meet and affect society with your creative and expert problem-solving skills.`,
      [types.GENERATOR]: `As a 5/1 Generator you need to wait before you respond to fulfill the expectations that others place on you. The most practical thing you can do for yourself is to be clear in your response. If you commit to anything that your sacral voice is not ready for, and if you are not prepared, then you waste time and energy and end up deeply frustrated. Your timing needs to be right, and if you are not sure of your response, it is better to wait for clarity instead of jumping into things you are not ready for. Doing so can be destabilizing and bring on considerable discomfort as you deal with the consequences of a negative projection, which may cause you to withdraw to protect yourself. Wait until your foundation is secure and your response is certain. In this way others can greatly benefit from your creative problem-solving skills.`,
      [types.PROJECTOR]: `As a 5/1 Projector, you may find others want to invite you to take part in situations where you are expected to guide and offer solutions, anticipating that you will solve problems. If you engage without having the capacity to provide a solution and satisfy expectations, then rejection will follow and your reputation will suffer. This can be extremely difficult and lead to bitterness for a 5/1 Projector who is not recognized for their skill. It is important to accept only invitations that are right for you, for which you have the necessary foundation, and where you can guide with skill.`,
      [types.REFLECTOR]: `As a 5/1 Reflector, being so open and constantly exposed to the projection field can be extremely challenging. It is natural to feel uncomfortable with the pressure and demands that surround you. At the same time, you have a skill to draw attention and, because you mirror everyone’s expectations, this may be misconstrued as implying you have the right answers.
        Right timing is the key for any fifth line, and for a Reflector this means waiting out a lunar cycle to experience all the possible activations in your chart that take place during this time. This will allow you to receive others’ feedback and be grounded in your process.
        Once there is clarity within your foundation, you can then meet the projection field. You can recognize and understand the other, and contribute the wisdom you have gathered.`,
      base: `The fifth line carries the theme of projection. This profile is inherently seductive, and you naturally attract plenty of projection from others. You have considerable problem-solving skills, as you are capable of approaching situations with an innovative perspective; therefore, a main projection is that you can offer practical solutions in times of need.
        Throughout your life you may experience considerable pressure to fulfill the expectations that are placed on you; people have the notion that that you can save the day. Essential to this profile is that you immerse yourself in study and extensive exploration of any subject that interests you. The unconscious first line needs a strong foundation, and through education, you ensure that over time, you develop the needed inner security and depth.
        Once you have acquired a foundation, you can then universalize it, meaning you can bring ideas, insights and concepts into the world and potentially affect a large number of people. You will find that you affect strangers more than those in your close circle of family and friends. This is a transpersonal gift that you need to cultivate to bring change and even mutation. You are a good observer and your unusual perspective can revolutionize outdated concepts and clear away what no longer serves.
        You are designed to have encounters that are potentially mutative and can change your own life as much as others’. Throughout your life you will meet allies who will help you fulfill your purpose – encounters that take you along a certain path. These encounters can be either personal or impersonal. Your ally may be a stranger you momentarily cross path with, or someone within your close circle.
        You generally need considerable time alone, out of the projection field, to find balance and peace, which prepares you to make the right decisions and answer only to expectations that are right for you. If your solutions are not practical or not perceived to be, then the negative projection can present quite a challenge. Your reputation is always in the foreground, and this is something you learn to handle as you move along your path. Others expect you to lead and guide them and you most likely experience an inherent need to be of value to others – a value always conditioned by what is practical. This is part of the transpersonal karma you carry; over time, you will learn to understand your transpersonal role and the responsibility it brings, and develop the correct strategies to fulfill your unique purpose.`
    }
  },
  52: {
    name: '5/2',
    title: 'The 5/2 Profile',
    desc: {
      [types.MANIFESTOR]: `As a 5/2 Manifestor, you are naturally driven to spend much time alone, which is very healthy for your own process. Your desire for solitude can meet with resistance and be interpreted as a refusal to share your gifts, which is a pressure you need to live with. Even if your reputation suffers from this misperception, with your inherently hermetic nature, you are better off withdrawing and refusing to prove yourself if it is not correct for your own process.
        The way for the Manifestor to avoid pressure is to simply inform others of your need to be alone. You need time for creative withdrawal to nurture your talents, and to find the strength to go out and deal with the projection field. When you are ready to come out and share, let others know.`,
      [types.GENERATOR]: `The 5/2 is not necessarily ready to accept others’ projections and you may not feel that you can universalize what you know to share it with others. Your direction in life is to find harmony and this drive for harmony brings you into contact with the projection field of others. As a Generator, you always have to wait to respond to use your energy in the right way. Harmony can only be found through responding to the right relationships.
        Throughout their lives, 5/2's are called out to share their talent. As a Generator, unless you are clear in your response you will endlessly involve yourself in what is not practical and then face the consequences. You can become deeply frustrated with the demands that are made on you as others project on the 5/2 that they have something valuable to offer. You will naturally seek withdrawal, arousing the suspicion that you are holding your talent back, unwilling to share and help others in times of need. But it is not up to the other to pull the 5/2 out. The 5/2 Generator has to call itself out through responding to the correct call. This is the only right way to use your energy—to step out at the right moment to support and nourish others with your unique gifts.`,
      [types.PROJECTOR]: `Throughout their lives, 5/2's are called out to share their talent. Others project on you that you can be of value and this brings out deep uncertainty if you don’t feel ready to go out and answer these projections and expectations.
        For the 5/2 Projector, it’s important to wait for the invitation before you can share your unique gifts. This ensures that what you are offering is indeed valuable and practical for others. Even if there are very few invitations you will be ready to accept, these are the only ones that are going to make a real difference in your life. It is not about how many invitations one receives, but the quality of the invitation, especially those having to do with the larger aspects of life such as love, career, friendship and environment. Using your strategy and authority will ensure that you use your talent to offer guidance through the practical solutions you can provide.`,
      [types.REFLECTOR]: `The Reflector is always open to projection being, after all, a mirror for others. For the 5/2 Reflector, this creates even more pressure as you are deeply susceptible to the conditioning of others, and being exposed to this strong projection field can be quite unsettling.
        The 5/2 profile is simply living out its nature and does not feel it has anything to prove. You are not introspective about your process and feel no need to offer any explanation; you simply live your talents. It is not easy to universalize what is so natural for you so that others can grasp it. Being such a Natural makes you very attractive to others and can leave you quite vulnerable to their projections. You learn quickly in life how disappointing interaction with others can be if you don’t live up to the expectations they place on you. You are not here to be conditioned and identify with others’ projections; if you’re unable to provide practical solutions, this can only lead to failed expectations and cause you to withdraw.
        Everything for the Reflector is about timing, and about who is in your life. It is essential to follow your strategy and authority and live through a lunar cycle before you can be clear about what is a correct call for you to answer. You need to experience others’ feedback during an entire cycle, so that you can arrive at a clear decision by the end of your process.`,
      base: `As a 5/2, you carry innate talents and can be very gifted in an unusual way that you may not be able to explain. You simply live it.
        Innate talents and unusual gifts that are can not always be explained can exert pressure as others project the ability to bring practical solutions
        At the same time, you need to engage with life and once you are ready, you can universalize your talent and truly offer solutions to others. A basic aspect of your life is the need for harmony, so to establish harmony, be aware not to answer to the pressure others exert to fulfil their projections. Exteriorizing something that is not practical or does not fulfil expectations placed on you can make you uncomfortable and leave you vulnerable. Your reputation can suffer and you end up uncertain about your own talents.
        Your unique qualities are not something you can explain – you simply use them. This is why this profile is also referred to as the 'Natural'. Even if you can universalize your gifts, at some level, you may always feel uncomfortable that you can't necessarily explain the How and Why. When you do understand how something works, there is a possibility that you can provide support and guide others in a practical direction.
        A keyword for you is self-motivation. The outside world will call you out but it is important not to react to the pressure of projection and to stay with your process until you know you are answering the right call through using your strategy and authority. Only then can you be certain that you indeed provide the practical answers others are looking for.`
    }
  },
  62: {
    name: '6/2',
    title: 'The 6/2 Profile',
    desc: {
      [types.GENERATOR]: `The first thirty years of a 6/2 Generator’s life can be truly chaotic. There are many experiences that pull you in, and unless you are settled in your strategy and authority, you may respond to all the wrong calls and end up completely frustrated, having used your energy getting involved in experiences that were not right for you. You find over and over that things don’t work out, which can make you rather pessimistic. 
        The Generator has its strategy of learning to listen to its sacral response. Once you are settled in your strategy, you will know for certain when an experience is right, and when it is one you need to engage in on your way to becoming an objective observer. Ultimately, knowing exactly what works through all the experiences showing you what does not is great observational wisdom that you can offer others. You need to learn to trust and be clear in your response, and thereby trust in yourself and your own natural abilities. There are no role models out there for you to follow; you are a role model unto yourself.`,
      [types.MANIFESTOR]: `As a 6/2 Manifestor, you can avoid meeting resistance that is so often part of the Manifestor’s way if you follow the strategy of informing others before you act. As you move impulsively from one experience to the next, the chaos of the first thirty years can be quite challenging and unsettling. The resistance you experience builds up anger, which in turn you use to force through the resistance you encounter. This can easily get you caught in a loop but it will subside once you enter into the second phase—a period of aloofness where you have the opportunity to develop the kind of wisdom that can be shared with others who see in you a potential guide. This period also allows you to build a more harmonious foundation and develop the trust you need. Building trust is an essential part of your process while you are looking for the perfection of things, whether the perfect relationship or perfect job, as you are inherently idealistic.
        In the final period, beyond 50, you will find your greatest opportunity to re-engage with the world around you. This will make you feel as though you have come full circle, with a sense of completion. This is when you find you can be of great value to the community around you, sharing your wise and objective observations. As a Manifestor, let others know that you are available to offer guidance and share your insights when you know the time is right.`,
      [types.PROJECTOR]: `During the first thirty years of a 6/2 Projector’s life, there can be considerable discomfort, as many demands will be placed on you to enter into all kinds of experiences, some of which may not be right for you. This profile carries the theme of being a role model and it is part of your identity to be an example for others. Engaging in experiences that are not correct for you will lead you to break bonds with others and cause you to feel many disappointments with the world at large, and with your role in it. Over time, you will feel an increasing need to isolate yourself from the world to stay with your own process. While it is natural to withdraw during the second phase to watch the world and gain a perspective, you also need to be available to share your insights. If you follow your strategy of waiting for the invitation, then you will know which experiences to engage in and have the opportunity to live out your role in the right way.`,
      [types.REFLECTOR]: `The 6/2 Reflector needs to be particularly careful during the first thirty years to avoid being overwhelmed by the chaos they experience during this phase. Reflectors are by definition very open and are constantly dealing with the conditioning field that exposes them to influences from peers, family, society and the transit field. Reflectors can easily be disappointed with life’s inconsistencies, watching how everything shifts and changes constantly and without any particular structure to identify with. Patience is paramount for a Reflector; this means being willing to wait out an entire lunar cycle during which you will seek others’ feedback to find clarity. Only through waiting can you find the objectivity that this profile aspires to, and learn to trust your own decision-making process and ultimately become an important authority within your community. You act as a sounding board for others as you reflect the nature of anyone around you, over time learning to read and therefore be of immense value to them. `,
      base: `With a sixth line personality, you move through three distinct phases in life. During the first thirty years, you are deeply engaged in the process of experimenting and gathering valuable experience. You need to find out what works and what does not, largely through the so-called trial and error process, which is all about discovery. This can be a difficult time as things often do not feel quite right, and you are pulled from one experience to the next. But you also have the ability to sustain yourself throughout your process, instead of succumbing to the chaos and giving up.
        During the second phase up to around 50, you feel the need to withdraw and detach yourself from the ongoings of the world around you, retreating to a place where you can be removed from life’s drama and simply observe, rather than immerse yourself. During this phase, you have the opportunity to develop an objective view by drawing on your many experiences, and you simply watch for the things that work. This retreat phase will feel very natural to you. In the final phase, from age fifty onwards, you can then apply the insights gained, and re-engage with life,
        It is important to go consciously through each of these phases, as this will prepare you to become a true role model or even leader, which is the purpose of a sixth line personality. Others recognize this in you—the wisdom and the natural ability to offer guidance. You are seeking perfection, depth and meaning, and ultimately your own truth. You carry the potential to develop great observational wisdom, to find your own truth, and trust in yourself.
        You can feel disconnected from others, as your perspective tends to be objective and less personal; you don’t like to concern yourself with trivia but to probe deeply into things to find meaning. You can find substantial wisdom about the nature of life through your tendency to observe, and over time, others will seek you out for the guidance you can offer. You have a natural talent that others want to call out. It is paramount that you use your strategy to know when it is right to step out and engage yourself.
        The early years of life can be difficult; there are many experiences to go through and many mistakes may arise during this trial and error process, whether in your relationships, your professional life, or wherever you focus attention. During this time, it is natural for a sixth line profile to be pulled from one thing to the next and even feel disillusioned because many things don’t seem to work. If you consciously live through and embrace these first two phases, then this will be apparent during the latter phase of your life when you can finally re-engage and apply the wisdom you have found, and discover the underlying optimism that has sustained you throughout.
        While you are fundamentally optimistic, the experiences of the first thirty years can make you rather disillusioned with the way the world operates in general, and with human beings in particular. You realize that there are no examples to follow and that you need to find your own inner wisdom to understand the greater picture.`
    }
  },
  63: {
    name: '6/3',
    title: 'The 6/3 Profile',
    desc: {
      [types.MANIFESTOR]: `As a 6/3 Manifestor, it is important that you follow your strategy and inform others before you act, to avoid the resistance that will inevitably force you to fight it. You are compelled to find out what works, and find yourself on a continuous search for what is possible and what can be done. Your life is in constant transition and even though you may suffer when you encounter failure, you are by nature rather resilient and prefer to tear things down repeatedly and then build something else.
        This profile carries leadership as a potential, and those who carry it can lead us away from the old and into the new. Your whole life is about gaining wisdom through the detached perspective you develop over time, based on the discovery of what truly works. The greatest impact you can have is to let others know when you are ready to offer guidance and share your wisdom, and that way avoid the resistance that could otherwise hinder you in fulfilling your purpose.`,
      [types.GENERATOR]: `As a 6/3 Generator, finding your sacral voice is paramount in avoiding disillusionment and pessimism during the first thirty years of life, as you will be going through a multitude of experiences. There is a constant drive to act out and discover what works through trial and error, which means many things will not turn out as expected; this means you can have the perception that nothing really works, and become very frustrated in the long run.
        The truth is, any experience you respond to with your sacral authority is the right one for you, and even if things don’t work out, this is your way of discovering what does work. You can look at this process as research for which the outcome is never sure, but that will ultimately give you another piece of the puzzle on your way to discovery. There is no failure for a Generator who uses his strategy, as each experience is of value to you. You do not give up easily and there is an optimism about you that keeps you looking for perfection and truth throughout your life, and that over time leads you to acquire substantial wisdom. Once you are past the early phase of trial and error, your discoveries will serve you in developing an objective perspective and ultimately a fundamental wisdom about life. Others will seek you out for advice and guidance, which is your purpose to fulfil.`,
      [types.PROJECTOR]: `For the 6/3 Projector, there can be quite a bit of turmoil during the first thirty years. You may be driven to enter into all kinds of experiences, and there will be many opportunities to engage. This profile shows a considerable dynamic force as you go through the process of discovering what works and what does not. This is your learning curve through which you can ultimately find tremendous wisdom. Through experience and observation over time, you will develop a detached and objective point of view.
        By following your strategy and waiting for the invitation, you know which experiences are right to engage in. You need to have clarity about where and when to get involved; otherwise, if you enter into experiences that are not correct for you, this considerably weakens or even depletes your energy field, and you will not find the recognition you seek. In turn, this may cause you to repeatedly withdraw and disengage, to stay aloof and not be involved in the daily conundrums of life. But you will always feel the urge to reconnect and re-engage as you are looking for perfection and wisdom, and for trust in life.
        There is an optimist in you that keeps you undeterred and engaged in your process of discovery. Once you have clarity, and over time find out which invitations are right for you, then you can experience the flowering stage of being a role model for others, able to offer much sought-after guidance and advice.`,
      [types.REFLECTOR]: `For the 6/3 Reflector, it is extremely important not to jump into experiences during the first thirty years, nor be involved in a diversity of experiences that may not work out and instill a sense of failure. Reflectors are extremely sensitive to environments and conditioning from others due to their openness, and finding yourself in a process that does not reflect an experience that is right for you can cause you to become quite pessimistic about the nature of the world. This will also cause you to try to escape from life, but while withdrawal is a natural phenomenon during the second phase of life, after the age of 30, you need to stay with the experiences life brings you to discover what works and what does not to gain wisdom. 
        Reflectors are under substantial pressure, as they always have to deal with others’ energy fields and reflect back what is taking place in their environment. Going through an entire lunar cycle (approximately 28 days) is essential for gathering experience through the process of reflection and seeking feedback. This is a mechanical process, and once you have reached clarity through the correct feedback from others and from life experiences, it allows you to find wisdom and perspective.`,
      base: `The 6/3 profile is fundamentally about transition and change and brings with it three distinct phases in life, referred to as the three-part-life process. The first thirty years can be particularly challenging, as life can be chaotic and lack stability. You find yourself pulled into all kinds of experiences that don’t turn out well, or not the way you envision them, and this may cause you to become disillusioned and undermine the trust you need to feel for life. But keep in mind that this is your way to learn and gain wisdom in life, and that what are commonly referred to as mistakes are necessary; they are experiences and opportunities life brings to you. You need to engage in all kinds of subjective experiences; this is your particular process of discovery, so that ultimately you find out what works by learning what does not work. This is your path to experience deep recognition of how things work.
        It’s important for you to find balance between being alternately pessimistic and disillusioned about life on one hand, and optimistic and trusting on the other. You are looking for perfection, and for this you may break bonds and walk away from people and situations over and over if they don’t fit your ideal.
        In the second phase, until the age of 50 or so, you will experience a distinct need to detach yourself and withdraw to observe rather than continuously be involved in the experiences and dramas of life. This retreat phase will offer you a more objective point of view, but at the same time you still feel the need to look for situations to engage in and things to explore, in the everlasting search for the ideal and perfect.
        Beyond 50, there is potential to gather all you’ve learned and present yourself as a leader and guide who can impact others through wisdom and objectivity. This phase of your life can be seen as the flowering stage where you reach fulfilment as a unique being, becoming for others a true role model with substantial wisdom to share.`
    }
  }
}
