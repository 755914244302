<template>
  <div :key="item.label"
       class="col-6 col-lg-4 d-flex align-items-center my-2"
       :class="item.doubleSpan ? 'col-lg-6' : item.tripleSpan ? 'col-lg-12' : ''">

    <div v-if="item.icon" class="mr-3 mb-3">
      <span :class="item.icon" :style="`font-size:${item.iconFontSize || 26}px;`"></span>
    </div>
    <div>
      <label class="font-weight-light" style="opacity:0.7">{{$t(item.label)}}</label>
      <p class="font-500">{{item.value}}</p>
    </div>

  </div>
</template>

<script>
export default{
  props:{
    item: Object,
  }
}
</script>