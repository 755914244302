export default {
    title: 'Import from MMI',
    completedTitle: 'Import Completed',
    description: 'Drop your .mmi or .s3db file here to import your MMI charts or entire library.',
    convertFolders:{
        title:'Convert folders to tags',
        paragraph: 'Create tags corresponding to the MMI folder structure and assign these to the imported charts.'
    },
    importing: 'Import is in progress... Check back here to see the results of the import!',
    completed: 'Import Completed: See results below.',
    statuses: {
        success: 'Successfully imported',
        fail: 'Failed to import',
        duplicates: 'Duplicates found',
        flaggedCharts: 'Flagged charts',
        lostAndFoundCharts: 'Lost and found charts',
        cycleCharts: 'Cycle charts',
    },
    category: 'Type',
    total: 'Total Charts',
    startOver: 'Close & Start Over',
    tooltip: {
        inProgress: 'There is an import in progress. The status here will change once it is completed.',
        completed: 'Your import has been completed.',
        completedCta: 'Click here to see the results',
    },
    tags: 'The selected tags will be applied to all charts in the file'
}