export default `<svg viewBox="0 0 612 612" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <path d="M306,0C136.976,0,0,136.994,0,306c0,168.987,136.976,306,306,306c168.987,0,306-137.013,306-306
    C612,136.994,474.987,0,306,0z M504.348,107.653c25.774,25.774,45.982,55.769,60.102,89.163
    c12.999,30.667,20.283,63.053,21.74,96.428H318.738V25.792c33.356,1.475,65.742,8.759,96.447,21.74
    C448.578,61.652,478.573,81.879,504.348,107.653z M107.653,107.653c25.774-25.774,55.769-46,89.163-60.121
    c30.667-12.98,63.053-20.264,96.409-21.74v267.451H25.774c1.494-33.375,8.778-65.761,21.74-96.428
    C61.634,163.422,81.879,133.427,107.653,107.653z M107.653,504.329c-25.774-25.756-46.02-55.769-60.14-89.164
    c-12.962-30.666-20.246-63.053-21.74-96.428h267.451v267.471c-33.356-1.494-65.742-8.779-96.409-21.741
    C163.422,550.329,133.427,530.102,107.653,504.329z M504.348,504.329c-25.774,25.774-55.769,46-89.2,60.14
    c-30.667,12.962-63.053,20.246-96.409,21.74V318.737h267.451c-1.457,33.375-8.741,65.762-21.74,96.428
    C550.329,448.561,530.121,478.573,504.348,504.329z"/>
</svg>`
