var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"mb-4 reverse-bg-mode"},[_c('b-card-body',[_c('div',{staticClass:"row"},[_c('properties-item',{attrs:{"item":{
          label: _vm.$t('properties.chartName'),
          value: _vm.chart.meta.name,
        }}}),_vm._l((_vm.getData),function(value,key){return _c('properties-item',{key:key + value,attrs:{"item":{
                            label: _vm.$t(`properties.${key}`),
                            value: value.data,
                            icon: value.icon,
                            iconFontSize: 18
                         }}})})],2)])],1),(_vm.chart)?_c('b-tabs',{staticClass:"tabs-primary-underlined"},[_c('b-tab',{attrs:{"title":_vm.chart.meta.person1.name}},[_c('div',{staticClass:"row mt-3 px-3 px-md-2"},[(_vm.chart.meta.person1.birthData.time?.utc)?_c('summary-box',{attrs:{"icon":"icon-time-ticking","title":_vm.$t('properties.birthDateUTC'),"value":_vm.formatDate(_vm.chart.meta.person1.birthData.time.utc, true) || _vm.chart.meta.person1.birthData.time.timezone.id}}):_vm._e(),_c('personal-chart-summary',{attrs:{"chart":_vm.getChart(0),"show-advanced":""}})],1)]),_c('b-tab',{attrs:{"title":_vm.chart.meta.person2.name}},[_c('div',{staticClass:"row mt-3 px-3 px-md-2"},[(_vm.chart.meta.person2.birthData.time?.utc)?_c('summary-box',{attrs:{"icon":"icon-time-ticking","title":_vm.$t('properties.birthDateUTC'),"value":_vm.formatDate(_vm.chart.meta.person2.birthData.time.utc, true) || _vm.chart.meta.person2.birthData.time.timezone.id}}):_vm._e(),_c('personal-chart-summary',{attrs:{"chart":_vm.getChart(1),"show-advanced":""}})],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }