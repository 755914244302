<template>
  <div id="variable" class="px-3">
    <inner-tabs-renderer :id="5"
                         type="advanced"
                         :tabs="tabs"
                         :total-outer-tabs="totalTabs"
                         @prevSection="$emit('prevSection')"
                         @nextSection="$emit('nextSection')"
                         @innerTabChanged="(val) => $emit('innerTabChanged', val)">
      <template #unlock-prompt> <slot name="unlock-prompt"/> </template>
    </inner-tabs-renderer>
  </div>
</template>

<script>

import DynamicZoneRenderer from "../../../renderer/DynamicZoneRenderer";
import AnalysisQueryMixin from "../../../../mixins/AnalysisQueryMixin";
import {getPlanet} from "../../../../helpers/rave/queries";
import {mapState} from "vuex";
import * as R from "ramda";
import planet from "../../../../helpers/rave/constants/planets";
import activationModes from "../../../../helpers/rave/constants/activationModes";
import variables from "../../../../helpers/rave/constants/variables";
import InnerTabsRenderer from "../../../renderer/InnerTabsRenderer.vue";
export default{
  components: {InnerTabsRenderer},
  mixins: [AnalysisQueryMixin],
  computed:{
    ...mapState({
      chart: state => state.charts.chart
    }),
    tabs(){
      return [
        {
          id: 0,
          title: this.$t('hd.properties.introduction'),
          shouldRender: !!this.content,
          component: DynamicZoneRenderer,
          props: {
            components: this.content ? this.content.components : [],
            addPaddings: false,
          }
        },
        {
          id: 1,
          title: 'Variable',
          shouldRender: !!this.variableVariable,
          component: DynamicZoneRenderer,
          props: {
            components: this.variableVariable ? this.variableVariable.components : [],
            addPaddings: false,
          }
        },
        {
          id: 2,
          title: 'Integration',
          component: DynamicZoneRenderer,
          shouldRender: !!this.variableIntegration,
          props: {
            components: this.variableIntegration ? this.variableIntegration.components : [],
            addPaddings: false,
          }
        }
      ]
    }
  },
  data(){
    return{
      tab: 0,
      audioKey: 0,
      content: null,
      variableVariable: null,
      variableIntegration: null
    }
  },
   created(){
    this.getIntroduction();
    this.getVariable()
   },
  methods:{
    getIntroduction(){
      let url = '/analysis/introductions/introduction.advanced.6';
      this.getContent('content', url);
    },

    getVariable(){
      let pred = (id, activation) => R.both(R.propEq('id', id), R.propEq('activation', activation))

      let pSun = getPlanet(pred(planet.SUN, activationModes.PERSONALITY), this.chart.chart.planets)
      let pNode = getPlanet(pred(planet.SOUTH_NODE, activationModes.PERSONALITY), this.chart.chart.planets)
      let dSun = getPlanet(pred(planet.SUN, activationModes.DESIGN), this.chart.chart.planets)
      let dNode = getPlanet(pred(planet.SOUTH_NODE, activationModes.DESIGN), this.chart.chart.planets)

      let url = '/variables/' +  variables.fromTones(pSun.tone, pNode.tone, dSun.tone, dNode.tone);
      this.getContent('variableVariable', url + '.' + 'variable');
      this.getContent('variableIntegration', url + '.' + 'integration');
    },
  }
}
</script>