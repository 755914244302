import planet from '@/helpers/rave/constants/planets'

export default {
  [planet.SUN]: 'Sun',
  [planet.EARTH]: 'Earth',
  [planet.MOON]: 'Moon',
  [planet.NORTH_NODE]: 'North Node',
  [planet.SOUTH_NODE]: 'South Node',
  [planet.MERCURY]: 'Mercury',
  [planet.VENUS]: 'Venus',
  [planet.MARS]: 'Mars',
  [planet.JUPITER]: 'Jupiter',
  [planet.SATURN]: 'Saturn',
  [planet.URANUS]: 'Uranus',
  [planet.NEPTUNE]: 'Neptune',
  [planet.PLUTO]: 'Pluto',
  [planet.CHIRON]: 'Chiron'
}
