export default `<svg viewBox="0 0 612 612" preserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <path d="M522.744,216.753C522.744,97.045,425.7,0,306,0S89.256,97.045,89.256,216.753
    c0,115.414,90.228,209.768,203.987,216.361v76.893h-76.5c-7.041,0-12.737,5.696-12.737,12.736c0,7.06,5.697,12.757,12.737,12.757
    h76.5v63.743c0,7.06,5.715,12.757,12.757,12.757s12.757-5.697,12.757-12.757V535.5h76.5c7.041,0,12.736-5.697,12.736-12.757
    c0-7.041-5.696-12.736-12.736-12.736h-76.5v-76.893C432.517,426.521,522.744,332.167,522.744,216.753z M170.761,351.982
    c-17.574-17.574-31.357-38.026-40.995-60.792c-9.955-23.57-15.016-48.607-15.016-74.437c0-25.829,5.061-50.876,15.017-74.436
    c9.638-22.776,23.421-43.227,40.995-60.802c17.575-17.575,38.027-31.368,60.812-40.996c23.552-9.964,48.597-15.017,74.428-15.017
    s50.876,5.053,74.428,15.017c22.786,9.628,43.236,23.421,60.812,40.996c17.574,17.575,31.357,38.026,40.995,60.802
    c9.954,23.56,15.015,48.606,15.015,74.436c0,25.83-5.061,50.867-15.017,74.436c-9.638,22.767-23.421,43.217-40.996,60.792
    c-17.574,17.574-38.026,31.357-60.812,40.995c-23.551,9.974-48.597,15.017-74.427,15.017c-25.831,0-50.877-5.043-74.428-15.017
    C208.787,383.34,188.337,369.558,170.761,351.982z"/>
</svg>`